import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepGuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom, Subject } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";
import { ProcessMapUiService } from "../process-map-ui.service";

@Component({
    selector: "adapt-role-task-content",
    templateUrl: "./role-task-content.component.html",
    styleUrls: ["./role-task-content.component.scss"],
})
export class RoleTaskContentComponent extends BaseComponent implements OnChanges {
    @Input() public roleTask!: ProcessStep;
    @Input() public isEditing = false;

    public guidanceMaterials: GuidanceMaterial[] = [];

    private triggerGuidanceMaterialsUpdate$ = new Subject<void>();

    public constructor(
        private processMapService: ProcessMapService,
        private processMapUiService: ProcessMapUiService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.triggerGuidanceMaterialsUpdate$.pipe(
            filter(() => !!this.roleTask),
            debounceTime(100),
            switchMap(() => this.processMapService.getProcessStepGuidanceMaterialsByProcessStepId(this.roleTask.processStepId)),
            this.takeUntilDestroyed(),
        ).subscribe((processStepGuidanceMaterials) => {
            this.guidanceMaterials = processStepGuidanceMaterials.filter((psgm) => !!psgm.guidanceMaterial)
                .map((psgm) => psgm.guidanceMaterial as GuidanceMaterial);
        });

        rxjsBreezeService.entityTypeChangedInSave(ProcessStepGuidanceMaterial).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerGuidanceMaterialsUpdate$.next());
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.roleTask && this.roleTask) {
            // prime supplementary data
            await lastValueFrom(this.processMapService.getProcessStepSupplementaryData(this.roleTask));
            this.triggerGuidanceMaterialsUpdate$.next();
        }
    }

    @Autobind
    public editRoleTask() {
        return this.processMapUiService.editProcessStep(this.roleTask);
    }
}
