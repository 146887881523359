<div class="d-flex align-items-center">
    <span class="fas fa-circle me-1"
          [ngClass]="ratingClass"
          [adaptTooltip]="tooltipText"></span>
    <button adaptButton="iconlessLink"
            (click)="showCatchup()">View latest catch-up</button>
    <button adaptButtonIcon="fal fa-history"
            class="ms-4 btn btn-link"
            (click)="showHistory()">View history</button>
</div>
