import { AdaptError } from "@common/lib/error-handler/adapt-error";

export class DialogDismissedError extends AdaptError {
    public constructor(dialogController: string, public readonly dismissArgs?: any[]) {
        super(`${dialogController} Dialog was dismissed`);
        this.name = "DialogDismissedError";
    }

    public get shouldBeLogged() {
        return false;
    }
}
