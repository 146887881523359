import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { CulturalLeadershipFrameworkPageComponent } from "./clf-page.component";

export const culturalLeadershipFrameworkPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-clf-page", CulturalLeadershipFrameworkPageComponent)
    .atOrganisationUrl("/culture/cultural-leadership-framework")
    .redirectToThisRouteFromOrganisationUrl("/culture/culturalLeadershipFramework")
    .verifyingAccess(CulturalLeadershipFrameworkAuthService.ReadCohorts)
    .verifyingFeatures(FeatureName.CulturalNetworkCulturalLeadershipCohort)
    .withTitle("Cultural Leadership Framework")
    .withHelp(StandardIntercomSearchTerm.CulturalLeadership)
    .withSearchKeyword("Cultural Cohort")
    .build();
