<adapt-dashboard-element name="Cultural Index"
                         [url]="dashboardUrl$ | async">
    <ng-container *adaptLoadingUntilFirstEmit="let culturalIndex of culturalIndex$">
        <div *ngIf="culturalIndex; else NoCulturalIndexTemplate">
            <adapt-ci-range [culturalIndex]="culturalIndex"
                            [isDisabled]="true">
            </adapt-ci-range>

            <p><small>Recorded on {{culturalIndex.creationDate | adaptDate}}</small></p>
        </div>

        <ng-template #NoCulturalIndexTemplate>
            You have not yet recorded a cultural index
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
