import { Injectable, Injector } from "@angular/core";
import { GuidanceMaterial, GuidanceMaterialBreezeModel } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessStepGuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { BreezeEntityUtilities } from "@common/lib/data/breeze-entity-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { defer, lastValueFrom } from "rxjs";
import { ProcessMapService } from "../process-map/process-map.service";

@Injectable({
    providedIn: "root",
})
export class IntegratedArchitectureService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private organisationService: OrganisationService,
        private processMapService: ProcessMapService,
    ) {
        super(injector);
    }

    public getGuidanceMaterials() {
        return this.commonDataService.getAll(GuidanceMaterialBreezeModel);
    }

    public getGuidanceMaterialByName(name: string, existingId?: number) {
        const predicate = new MethodologyPredicate<GuidanceMaterial>("name", "==", name);
        if (existingId) {
            predicate.and(new MethodologyPredicate<GuidanceMaterial>("guidanceMaterialId", "!=", existingId));
        }
        return this.commonDataService.getByPredicate(GuidanceMaterialBreezeModel, predicate);
    }

    public createGuidanceMaterial() {
        const organisationId = this.organisationService.getOrganisationId();
        const defaults: Partial<GuidanceMaterial> = { organisationId };
        return this.commonDataService.create(GuidanceMaterialBreezeModel, defaults);
    }

    public deleteGuidanceMaterial(guidanceMaterial: GuidanceMaterial) {
        return defer(async () => {
            // make sure we remove all process step material that references this guidance material
            const processStepGuidanceMaterials = await lastValueFrom(this.processMapService.getProcessStepGuidanceMaterialsByGuidanceMaterialId(guidanceMaterial.guidanceMaterialId));
            await Promise.all(processStepGuidanceMaterials.map((psgm) => lastValueFrom(this.commonDataService.remove(psgm))));
            await lastValueFrom(this.commonDataService.remove(guidanceMaterial));
            return lastValueFrom(this.commonDataService.saveEntities([...processStepGuidanceMaterials, guidanceMaterial]));
        });
    }

    public mergeGuidanceMaterialsForProcessSteps(
        guidanceMaterialsToMerge: GuidanceMaterial[],
        guidanceMaterialToMergeInto: GuidanceMaterial,
        affectedProcessStepGuidanceMaterials: ProcessStepGuidanceMaterial[],
    ) {
        return BreezeEntityUtilities.mergeEntities(
            this.commonDataService.remove.bind(this.commonDataService),
            guidanceMaterialsToMerge,
            guidanceMaterialToMergeInto,
            affectedProcessStepGuidanceMaterials,
            (i) => i.guidanceMaterialId,
            (i) => i.processStepId,
            (i, value) => i.guidanceMaterialId = value,
        );
    }
}
