<ng-container *adaptLoadingUntilFirstEmit="let person of person$">

    <adapt-toolbar-inactive [entity]="person"
                            type="account">
    </adapt-toolbar-inactive>

    <div *ngIf="person"
         adaptToolbarContent>
        <ng-container *ngIf="canEditSomethingOnPage$ | async">
            <button *ngIf="isEditing"
                    adaptButton="view"
                    data-test="view-mode-button"
                    (click)="setViewMode()"></button>
            <button *ngIf="!isEditing"
                    adaptButton="edit"
                    data-test="edit-mode-button"
                    (click)="setEditMode()"></button>
        </ng-container>
        <button *adaptIfAuthorised="ChangePasswordForPerson;Entity:person"
                adaptButtonIcon="fal fa-key"
                (click)="changePassword(person)"
                data-test="change-password-button">Change password
        </button>
        <ng-container *adaptIfAuthorised="ManagePositionAndRoles;Entity:person">
            <adapt-user-role-actions [connection]="latestConnection$ | async"
                                     (connectionChanged)="triggerConnectionUpdate($event)"></adapt-user-role-actions>
        </ng-container>
    </div>

    <adapt-two-column-page *ngIf="person">
        <div left-column>

            <adapt-dashboard-element>
                <div adapt-dashboard-title-buttons>
                    <button *ngIf="isEditing"
                            adaptButton="editBorderless"
                            (click)="editName()"></button>
                </div>
                <div adapt-dashboard-title
                     class="profile-header pt-3">
                    <adapt-person-default-image *ngIf="!isEditing && !person.imageIdentifier"
                                                class="me-3"
                                                imageSize="lg"
                                                [person]="person"></adapt-person-default-image>

                    <adapt-select-image *ngIf="person.imageIdentifier || isEditing"
                                        [imageIdentifier]="person.imageIdentifier"
                                        (imageIdentifierChange)="updateImageIdentifier(person, $event)"
                                        [imageWidth]="100"
                                        [defaultImageSrc]="defaultImageSrc"
                                        [disabled]="!isEditing"
                                        [inline]="true"
                                        class="profile-image-container profile-image-container-lg me-3"></adapt-select-image>

                    <div class="profile-name">
                        <span>{{person.firstName}} {{person.lastName}}</span>
                    </div>
                </div>
            </adapt-dashboard-element>

            <adapt-person-profile-contacts [person]="person"
                                           [editMode]="isEditing">
            </adapt-person-profile-contacts>

            <adapt-dashboard-element name="Links"
                                     *ngIf="canReadLinks$ | async">
                <a *adaptIfAuthorised="ReadCareerValuationForPerson;Entity:person"
                   [routerLink]="cvtDashboardUrl$ | async"
                   data-test="career-valuation-pill-button"
                   class="me-2 mb-1 btn btn-primary btn-pill">
                    Career Valuation</a>
                <a *adaptIfAuthorised="ReadCulturalIndexForPerson;Entity:person"
                   [routerLink]="ciDashboardUrl$ | async"
                   class="me-2 mb-1 btn btn-primary btn-pill">
                    Cultural Index</a>
                <a *adaptIfAuthorised="ReadPeerCatchUpForPerson;Entity:person"
                   [routerLink]="pcuDashboardUrl$ | async"
                   data-test="peer-catch-up-pill-button"
                   class="me-2 mb-1 btn btn-primary btn-pill">
                    Peer Catch-ups</a>
                <a *adaptIfAuthorised="ViewBoardForPerson;Entity:person"
                   [routerLink]="kanbanDashboardUrl$ | async"
                   class="me-2 mb-1 btn btn-primary btn-pill">
                    Work</a>
            </adapt-dashboard-element>

            <adapt-person-profile-access *ngIf="latestConnection$ | async"
                                         [person]="person"></adapt-person-profile-access>

            <adapt-person-profile-connection *ngIf="latestConnection$ | async"
                                             [person]="person"
                                             [editMode]="isEditing">
            </adapt-person-profile-connection>

            <ng-container *ngIf="(latestConnection$ | async) as latestConnection">
                <adapt-person-profile-position *ngIf="latestConnection.isEmployeeConnection()"
                                               [person]="person"
                                               [editMode]="isEditing">
                </adapt-person-profile-position>
            </ng-container>

            <adapt-person-profile-cultural-leader *adaptIfAuthorised="ReadCulturalLeadership"
                                                  [person]="person"
                                                  data-test="profile-cultural-leadership">
            </adapt-person-profile-cultural-leader>

            <adapt-person-profile-roles [person]="person"
                                        [editMode]="isEditing"></adapt-person-profile-roles>
            <adapt-person-profile-teams [person]="person"></adapt-person-profile-teams>

            <adapt-person-profile-systems *adaptIfAuthorised="ReadTier2"
                                          [person]="person"></adapt-person-profile-systems>
        </div>

        <div right-column>
            <adapt-person-profile-items [person]="person"
                                        [editMode]="isEditing">
            </adapt-person-profile-items>
        </div>
    </adapt-two-column-page>
</ng-container>
