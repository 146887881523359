import { Injectable } from "@angular/core";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { rolesPageRoute } from "app/features/architecture/integrated-architecture/roles-page/roles-page.route";
import { roleDescriptionRoute } from "app/features/architecture/role/role-description-page/role-description-page.route";
import { valueStreamBusinessModelPageRoute } from "app/features/architecture/tier1-architecture/value-stream-business-model-page/value-stream-business-model-page.route";
import { valueStreamDashboardPageRoute } from "app/features/architecture/value-streams/value-stream-dashboard-page/value-stream-dashboard-page.route";
import { Observable } from "rxjs";

@Injectable()
export class IntegratedArchitectureDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown>;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        this.activeNodeShouldBeRebuilt$ = rxjsBreezeService.entityTypeChangedInSave(Role);
    }

    public buildDynamicNodes() {
        return [
            this.buildDynamicBusinessModelNode(),
            this.buildDynamicRoleNode(),
        ];
    }

    private buildDynamicBusinessModelNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(valueStreamBusinessModelPageRoute.id)
            .setDynamicParentController(valueStreamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicRoleNode() {
        const dynamicTitleCallback = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Role>({
            entityIdParamName: "roleId",
            getEntityByParamCallback: (id) => this.commonDataService.getById(RoleBreezeModel, id),
            getValue: (role) => role.label,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(roleDescriptionRoute.id)
            .setDynamicParentController(rolesPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleCallback)
            .build();
    }
}
