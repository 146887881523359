<dx-chart [dataSource]="data"
          [pathModified]="true"
          title="Cultural Indexes"
          [customizePoint]="culturalIndexUiService.customiseChartPoint"
          (onPointClick)="onPointClick($event)"
          (onInitialized)="onInitialized($event)"
          *ngIf="data.length">
    <dxo-argument-axis>
        <dxo-grid [opacity]="0.2"
                  [visible]="true">
        </dxo-grid>
        <dxo-minor-grid [visible]="false">
        </dxo-minor-grid>
        <dxo-label alignment="right"
                   format="monthAndYear"
                   overlappingBehavior="rotate"
                   [rotationAngle]="-45">
        </dxo-label>
    </dxo-argument-axis>
    <dxo-value-axis visualRangeUpdateMode="keep"
                    [maxValueMargin]="0.0001"
                    [tickInterval]="0.5"
                    [minorTickInterval]="0.1"
                    [visible]="true"
                    [visualRange]="[0, 2]">
        <dxo-minor-tick [visible]="false">
        </dxo-minor-tick>
    </dxo-value-axis>
    <dxi-series type="scatter"
                valueField="value"
                argumentField="argument"
                tagField="culturalIndex">
    </dxi-series>
    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customiseTooltip">
    </dxo-tooltip>
    <dxo-loading-indicator>
        <dxo-font [size]="30">
        </dxo-font>
    </dxo-loading-indicator>
    <dxo-size [height]="responsiveService.currentBreakpoint.isMobileSize ? 300 : 600">
    </dxo-size>
    <dxo-margin [left]="20"
                [right]="20">
    </dxo-margin>
    <dxo-legend [visible]="false">
    </dxo-legend>
</dx-chart>


<adapt-no-cultural-measurement *ngIf="!data.length"
                               label="Cultural Indexes"></adapt-no-cultural-measurement>
