import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import DataSource from "devextreme/data/data_source";
import { DxListComponent } from "devextreme-angular";
import { BehaviorSubject, forkJoin } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { SystemisationService } from "../systemisation.service";

export interface IGroupKey {
    toString: () => string; // NOTE: This is important for DX to be able to group
    label: string; // this is used to search
    entity?: KeyFunction | Team | Role;
    zone?: Zone;
}

export interface ISystemGroupItem {
    key: IGroupKey;
    system: SystemEntity;
}

@Component({
    selector: "adapt-list-systems",
    templateUrl: "./list-systems.component.html",
})
export class ListSystemsComponent extends BaseComponent implements OnChanges {
    @Input() public selectedSystem?: ISystemGroupItem;
    @Input() public disabled = false;
    @Output() public selectedSystemChange = new EventEmitter<ISystemGroupItem>();
    @Output() public initialised = new EventEmitter<ListSystemsComponent>();

    @ViewChild(DxListComponent) private listComponent?: DxListComponent;

    public selectedSystems: ISystemGroupItem[] = [];

    public systemsDataSource?: DataSource<ISystemGroupItem>;

    private triggerUpdate$ = new BehaviorSubject<void>(undefined);
    private allCurrentSystems: SystemEntity[] = [];

    public constructor(
        systemisationService: SystemisationService,
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);
        this.triggerUpdate$.pipe(
            debounceTime(100),
            switchMap(() => forkJoin([
                systemisationService.getAllSystems(),
                systemisationService.getAllSystemLocations(),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([allSystems, systemLocations]) => {
            this.allCurrentSystems = allSystems;
            this.createDataSource(allSystems, systemLocations);
            this.setSelectedSystems(true);
            this.initialised.emit(this);
        });

        rxjsBreezeService.entityTypeChanged(SystemEntity).pipe(
            // only interested in system addition and deletion
            filter((changedSystem) => !this.allCurrentSystems.find((s) => s.systemEntityId === changedSystem.systemEntityId) ||
                changedSystem.entityAspect.entityState.isDetached()),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedSystem) {
            this.setSelectedSystems();
        }
    }

    public refresh() {
        this.triggerUpdate$.next();
    }

    public onSelectionChanged(e: IDxListSelectionChangedEvent<ISystemGroupItem>) {
        // single selection, selected item will just be the first element of addedItems
        if (e.addedItems?.length) {
            this.selectedSystemChange.emit(e.addedItems[0]);
            e.component?.scrollToItem(e.addedItems[0]);
        } else {
            this.selectedSystemChange.emit(undefined);
        }
    }

    private createDataSource(allSystems: SystemEntity[], systemLocations: SystemLocation[]) {
        const groupItems: ISystemGroupItem[] = [];
        const existingKeys: IGroupKey[] = [];

        systemLocations.forEach((systemLocation) => {
            const key = this.getGroupKey(systemLocation, existingKeys);
            if (key) {
                groupItems.push({
                    key,
                    system: systemLocation.system,
                });
            }
        });

        // unallocated system
        const unallocatedKey = {
            toString: () => "9_UnallocatedSystem",
            label: "Unallocated Systems",
        } as IGroupKey;
        groupItems.push(...allSystems
            .filter((s) => !systemLocations.find((i) => i.systemEntityId === s.systemEntityId))
            .map((i) => ({
                key: unallocatedKey,
                system: i,
            } as ISystemGroupItem)));

        this.systemsDataSource = new DataSource({
            store: groupItems,
            group: (item: ISystemGroupItem) => item.key,
            sort: [
                "system.name",
            ],
        });
    }

    private getGroupKey(systemLocation: SystemLocation, existingKeys: IGroupKey[]) {
        const entity = systemLocation.role || systemLocation.team || systemLocation.keyFunction; // only one of these will be defined
        if (entity) {
            let result = existingKeys.find((i) => i.entity === entity);
            if (!result) {
                result = {
                    entity,
                } as IGroupKey;

                if (entity instanceof KeyFunction) {
                    result.toString = () => `1_KeyFunction_name${entity.name}_id${entity.keyFunctionId}`;
                    result.label = entity.name;
                } else if (entity instanceof Team) {
                    result.toString = () => `2_Team_name${entity.name}_id${entity.teamId}`;
                    result.label = entity.name;
                } else if (entity instanceof Role) {
                    result.toString = () => `3_Role_name${entity.label}_id${entity.roleId}`;
                    result.label = entity.label;
                }

                existingKeys.push(result);
            }
            return result;
        } else if (systemLocation.zone) {
            let result = existingKeys.find((i) => i.zone === systemLocation.zone);
            if (!result) {
                result = {
                    zone: systemLocation.zone,
                    label: ZoneMetadata.Name[systemLocation.zone],
                };
                result.toString = () => `4_Zone_${systemLocation.zone}`;
                existingKeys.push(result);
            }
            return result;
        } else {
            throw new Error("Shouldn't happen - system location missing entity or zone!");
        }
    }

    private async setSelectedSystems(afterUpdate = false) {
        if (this.systemsDataSource) {
            const store = this.systemsDataSource.store();

            // In DX 22.2.4 & 22.2.5, the load method isn't typed correctly so we have to force the type
            const items = await store.load() as ISystemGroupItem[];

            if (this.selectedSystem) {
                const selectedStoreItem = items.find((i) => i.system === this.selectedSystem!.system &&
                    i.key.entity === this.selectedSystem!.key.entity && i.key.zone === this.selectedSystem!.key.zone);
                if (selectedStoreItem) {
                    this.selectedSystems = [selectedStoreItem];
                } else {
                    // no matching group -> find first matching system
                    const firstMatchSystem = items.find((i) => i.system === this.selectedSystem!.system);
                    if (firstMatchSystem) {
                        this.selectedSystems = [firstMatchSystem];
                    } else if (items.length > 0 && afterUpdate) {
                        this.listComponent?.instance?.selectItem({ group: 0, item: 0 });
                    }
                }
            } else if (items.length > 0) {
                // select first item (using items[0] for selection will result in selecting random item as data store is unsorted)
                this.listComponent?.instance?.selectItem({ group: 0, item: 0 });
            }
        }
    }
}
