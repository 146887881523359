import { AfterViewChecked, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject } from "rxjs";
import { catchError, filter, finalize, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { Options as SortablejsOptions, SortableEvent } from "sortablejs";
import { ProcessMapService } from "../process-map.service";

@Component({
    selector: "adapt-render-process-steps",
    templateUrl: "./render-process-steps.component.html",
    styleUrls: ["./render-process-steps.component.scss"],
})
export class RenderProcessStepsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
    @Input() public set processMap(value: ProcessMap | undefined) {
        if (value) {
            this.processMap$.next(value);
        }
    }
    @Input() public set parentStep(value: ProcessStep | undefined | null) {
        this.parentStep$.next(value ? value : null);
    }

    public get parentStep() {
        return this.parentStep$.value;
    }

    @Input() public isEditing = false;
    @Output() public initialised = new EventEmitter<void>();
    @Output() public stepClick = new EventEmitter<ProcessStep>();

    public childSteps: ProcessStep[] = [];
    private childStepsUpdater = this.createThrottledUpdater((steps: ProcessStep[]) => this.childSteps = steps);

    public stepsByParent?: Map<ProcessStep | null, ProcessStep[]>;
    public linkChildren = new Map<ProcessMap, Observable<ProcessStep[]>>();
    public childSortableOptions: SortablejsOptions = {};
    public grandchildSortableOptions: SortablejsOptions = {};
    public isSaving = false;
    public sortableJsInitCount = 0;
    public sortableJsAllInitialised = false;

    public processMap$ = new ReplaySubject<ProcessMap>(1);
    private parentStep$ = new BehaviorSubject<ProcessStep | null>(null);
    private reorderCompleted$ = new BehaviorSubject<void>(undefined);

    @HostBinding("style.min-height.px") public minHeight = 0;
    private lastParentHeight = 0;
    private lastComponentOffset = 0;

    private minHeightUpdater = this.createThrottledUpdater((minHeight: number) => this.minHeight = minHeight);
    private originalEditingState = this.isEditing;

    public constructor(
        private commonDataService: CommonDataService,
        private processMapService: ProcessMapService,
        private rxjsBreezeService: RxjsBreezeService,
        private commonDialogService: AdaptCommonDialogService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        elementRef: ElementRef,
    ) {
        super(elementRef);

        const baseSettings: SortablejsOptions = {
            // Animation is broken in prod build with 1.10 (should be fixed in 1.11)
            // See https://github.com/SortableJS/Sortable/issues/1658
            animation: 150,
            disabled: !this.isEditing,
            handle: "adapt-process-step-card",
            group: "render-process-steps",
            onAdd: this.moveEntityToNewLocationAndSave,
            onUpdate: this.moveEntityToNewLocationAndSave,
        };
        this.childSortableOptions = this.updateSortableSettings(baseSettings, {
            direction: "horizontal",
            swapThreshold: 0.80,
        });
        this.grandchildSortableOptions = this.updateSortableSettings(baseSettings, {
            direction: "vertical",
            emptyInsertThreshold: 0, // Fix swap glitching into empty sortable
        });

        const processMapFromParent$ = this.parentStep$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((step) => processMapService.getProcessMapForStep(step)),
            emptyIfUndefinedOrNull(),
            this.takeUntilDestroyed(),
        );
        processMapFromParent$.subscribe(this.processMap$);
    }

    public ngAfterViewChecked() {
        // set min height to fit parent container - having this component expand all to parent's full height
        if (this.elementRef?.nativeElement.parentElement) {
            const parentHeight = this.elementRef!.nativeElement.parentElement.offsetHeight;
            const componentTopOffset = this.elementRef!.nativeElement.offsetTop;
            if (parentHeight !== this.lastParentHeight || componentTopOffset !== this.lastComponentOffset) {
                this.lastParentHeight = parentHeight;
                this.lastComponentOffset = componentTopOffset;
                const parentTopOffset = this.elementRef!.nativeElement.parentElement.offsetTop;
                this.minHeightUpdater.next(parentHeight - (componentTopOffset - parentTopOffset) - 1); // 1 for rounding error when not using 100% zoom
            }
        }
    }

    public ngOnInit() {
        const processSteps$ = this.processMap$.pipe(
            tap(() => this.stepsByParent = undefined),
            switchMap((processMap) => this.processMapStepsShouldBeFetched(processMap)),
            switchMap(async (processMap: ProcessMap) => {
                this.originalEditingState = this.isEditing;
                await this.updateEditingFromPermissions(processMap);
                return processMap;
            }),
            switchMap((processMap) => this.processMapService.getProcessMapSteps(processMap)),
        );

        combineLatest([processSteps$, this.parentStep$]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(([steps, parentStep]) => {
            this.stepsByParent = new Map<ProcessStep | null, ProcessStep[]>();
            const groupedSteps = ArrayUtilities.groupArrayBy(steps, (step) => step.parentStep);
            for (const group of groupedSteps) {
                const sortedSteps = group.items.sort((a, b) => a.parentStepOrdinal - b.parentStepOrdinal);
                this.stepsByParent.set(group.key, sortedSteps);
            }

            this.childStepsUpdater.next(this.stepsByParent.get(parentStep) ?? []);
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isEditing && !changes.isEditing.isFirstChange()) {
            this.originalEditingState = this.isEditing;
            this.processMap$.pipe(
                take(1),
                switchMap((processMap) => this.updateEditingFromPermissions(processMap)),
            ).subscribe();
        }
    }

    public scrollElementIntoView(sourceElement: ElementRef) {
        sourceElement.nativeElement.scrollIntoView(false); // bottom aligned
    }

    public scrollViewToRight() {
        this.elementRef!.nativeElement.scrollLeft = this.elementRef!.nativeElement.scrollWidth;
    }

    public handleSortableJsInit() {
        this.sortableJsInitCount++;

        const grandchildSortableCount = this.childSteps
            .filter((i) => i.extensions.isProcessStep)
            .length;
        if (this.sortableJsInitCount === (grandchildSortableCount + 1)) {
            this.sortableJsAllInitialised = true;
            this.updateSortableEditingState();

            this.initialised.emit();
        }
    }

    public updateSortableEditingState() {
        this.childSortableOptions = this.updateSortableSettings(this.childSortableOptions, {
            disabled: !this.isEditing,
        });
        this.grandchildSortableOptions = this.updateSortableSettings(this.grandchildSortableOptions, {
            disabled: !this.isEditing,
        });
    }

    @Autobind
    private moveEntityToNewLocationAndSave(e: SortableEvent) {
        this.log.debug("sortable reordered", e);

        const target = this.determineParentAndChildrenFromElement(e.to);
        if (!target) {
            this.log.warn("Unable to determine target steps", e);
            return;
        }

        if (typeof e.newIndex === "undefined") {
            this.log.warn("newIndex should not be undefined", e);
            return;
        }

        // The backend handles updating any other entities as necessary
        const movedStep = target.children[e.newIndex];
        if (!movedStep || target.parent === movedStep) {
            this.log.error("Invalid movedStep or target.parent", movedStep, target.parent);
            // cancel move - if don't add it back to from, the item is gone and even refresh won't bring it back
            // - returning false doesn't work and same for preventDefault or e.returnValue
            // -- return false only works with onMove, which cannot be used here as that's trigger before drop
            // e.from.append(e.item); // sortable still corrupted
            this.stepsByParent = undefined;
            setTimeout(() => this.reorderCompleted$.next(), 100); // trigger a refresh, let's stepsByParent to be picked up by adaptLoading first to reload
            return;
        }

        movedStep.parentStep = target.parent;
        // target.children is already in the right order
        // - Stepmove from the server will take care of source -> new step will get max ordinal + 1 from server
        // - Left the stepmover from the server in place (which is currently updating the ordinals)
        //   so cannot use SortUtilities.sequenceNumberFieldInArray here as that will cause error the entities to be saved changed again by the stepmover
        if (e.newIndex > 0) {
            // set to previous + 1 and the server will take care of the ordinal >= parentStepOrdinal
            movedStep.parentStepOrdinal = target.children[e.newIndex - 1].parentStepOrdinal + 1;
        } else {
            // stepmover on server will push the ordinal along in the subsequent steps
            movedStep.parentStepOrdinal = 0;
        }

        movedStep.lastUpdated = new Date();
        this.isSaving = true;
        this.commonDataService.saveEntities(movedStep).pipe(
            catchError(() => this.commonDialogService.handleSaveFailure([movedStep])),
            finalize(() => {
                this.reorderCompleted$.next();
                this.isSaving = false;
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private determineParentAndChildrenFromElement(element: HTMLElement) {
        if (!this.stepsByParent) {
            this.log.warn("stepsByParent must be defined");
            return undefined;
        }

        const domStepId = Number(element.id.split("sortable-")[1]);
        if (isNaN(domStepId)) {
            this.log.warn("Invalid processStepId on DOM element", element);
            return undefined;
        }

        const parentStepId = domStepId > 0 ? domStepId : null;
        const parent = [...this.stepsByParent.keys()]
            .find((i) => (i === null && parentStepId === null) || i?.processStepId === parentStepId);
        if (typeof parent === "undefined") {
            this.log.warn(`Unable to find parent with id ${parentStepId}`);
            return undefined;
        }

        const children = this.stepsByParent.get(parent);
        return children ? { parent, children } : undefined;
    }

    private processMapStepsShouldBeFetched(processMap: ProcessMap) {
        return merge(
            this.rxjsBreezeService.entityTypeChanged(ProcessMap),
            this.rxjsBreezeService.entityTypeChanged(ProcessStep),
            // need to make sure the process map steps will be fetched when a step is copied/moved
            this.processMapService.stepCopiedOrMoved$.pipe(
                filter((systemComponent) => systemComponent.systemProcessMapId === processMap.processMapId),
            ),
            this.reorderCompleted$,
        ).pipe(
            map(() => processMap),
            filter(() => !processMap.entityAspect.entityState.isDetached()),
            startWith(processMap),
        );
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.processMap$.complete();
        this.parentStep$.complete();
    }

    public getSortableId(step: ProcessStep | null | undefined) {
        return step
            ? `sortable-${step.processStepId}`
            : `sortable-0`;
    }

    public getStepsByParent(parent: ProcessStep) {
        if (!this.stepsByParent) {
            return [];
        }

        if (!this.stepsByParent.has(parent)) {
            this.stepsByParent.set(parent, []);
        }

        return this.stepsByParent.get(parent)!;
    }

    public getLinkChildren(processMap: ProcessMap | null) {
        // When a process link is deleted the nav property becomes null
        if (!processMap) {
            return of([]);
        }

        if (!this.linkChildren.has(processMap)) {
            this.linkChildren.set(processMap, this.processMapService.getTopProcessMapSteps(processMap));
        }

        return this.linkChildren.get(processMap)!;
    }

    private updateSortableSettings(originalSettings: SortablejsOptions, updatedSettings: Partial<SortablejsOptions>) {
        return {
            ...originalSettings,
            ...updatedSettings,
        };
    }

    private async updateEditingFromPermissions(processMap: ProcessMap) {
        const hasPermission = await this.archAuthService.personCanEditProcessMap(processMap);
        this.isEditing = !hasPermission
            ? false
            : this.originalEditingState;

        this.updateSortableEditingState();
    }
}
