<div *adaptLoading="isLoading">
    <dx-chart [hidden]="data.length === 0"
              [dataSource]="data"
              title="Career Valuation timeline"
              [pathModified]="true"
              (onInitialized)="onInitialized($event)">
        <!-- Options -->
        <dxo-size [height]="height">
        </dxo-size>
        <dxo-argument-axis>
            <dxo-label alignment="right"
                       format="monthAndYear"
                       overlappingBehavior="rotate"
                       [rotationAngle]="-45">
            </dxo-label>
        </dxo-argument-axis>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip"></dxo-tooltip>
        <dxo-common-series-settings argumentField="date"
                                    type="stackedarea"></dxo-common-series-settings>
        <dxo-margin [bottom]="50"></dxo-margin>
        <dxo-legend [visible]="true"></dxo-legend>
        <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>

        <!-- Series -->
        <dxi-series *ngIf="this.filterParameters?.category1"
                    valueField="category1Count"
                    [name]="careerValuationUiService.valueStates[0].state"
                    [color]="careerValuationUiService.valueStates[0].fgColor"></dxi-series>

        <dxi-series *ngIf="this.filterParameters?.category2"
                    valueField="category2Count"
                    [name]="careerValuationUiService.valueStates[1].state"
                    [color]="careerValuationUiService.valueStates[1].fgColor"></dxi-series>

        <dxi-series *ngIf="this.filterParameters?.category3"
                    valueField="category3Count"
                    [name]="careerValuationUiService.valueStates[2].state"
                    [color]="careerValuationUiService.valueStates[2].fgColor"></dxi-series>

        <dxi-series *ngIf="this.filterParameters?.category4"
                    valueField="category4Count"
                    [name]="careerValuationUiService.valueStates[3].state"
                    [color]="careerValuationUiService.valueStates[3].fgColor"></dxi-series>
    </dx-chart>

    <div *ngIf="data.length === 0">
        <adapt-no-cultural-measurement label="Career Valuations"></adapt-no-cultural-measurement>
    </div>
</div>
