<adapt-dashboard-element *ngIf="hasActiveEmployeeConnection$ | async"
                         name="Cultural leadership">
    <ng-container *adaptLoadingUntilFirstEmit="let culturalRelationship of culturalRelationship$">
        <p *ngIf="culturalRelationship; else NoCulturalRelationshipTemplate">
            {{culturalLeaderName$ | async}}:
            <adapt-link-person [person]="culturalRelationship.culturalLeader"></adapt-link-person>
        </p>

        <ng-template #NoCulturalRelationshipTemplate>
            No {{(culturalLeaderName$ | async)?.toLowerCase()}} has been set
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
