import {Injectable, Injector} from "@angular/core";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "app/features/culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import { CulturalDataFilter } from "../../cultural-data-filter";
import { CulturalIndexAnalysisFilter } from "../cultural-index-analysis-filter";

@Injectable({
    providedIn: "root",
})
export class CulturalIndexFilterService extends CulturalDataFilter<ICulturalLeadershipMeasurementAnalysisFilter> {
    public constructor(injector: Injector) {
        super(CulturalIndexAnalysisFilter.getDefaultFilterParameters(), injector);
    }
}
