<div class="card-header">
    <h2 class="card-title">{{ categoryValue.category.name }}</h2>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-12 col-md-5 career-valuation-category-section career-valuation-gauge">
            <dx-linear-gauge [value]="categoryValue.actualValue">
                <dxo-geometry orientation="horizontal">
                </dxo-geometry>
                <dxo-scale [startValue]="0"
                           [endValue]="categoryValue.idealValue"
                           [allowDecimals]="false">
                </dxo-scale>
                <dxo-label position="bottom">
                </dxo-label>
                <dxo-value-indicator type="textCloud"
                                     [color]="indicatorColor"
                                     [arrowLength]="20">
                    <dxo-text [customizeText]="getCustomText">
                    </dxo-text>
                </dxo-value-indicator>
            </dx-linear-gauge>
        </div>
        <div class="col-12 col-md-7 career-valuation-category-section career-valuation-notes"
             [froalaView]="categoryValue.notes"></div>
    </div>
</div>
