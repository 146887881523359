<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ManageAccess"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            data-test="add-access-level"
            (click)="addAccessLevel()">Add access level</button>
    <span class="dx-datagrid-export-button ">
        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                adaptTooltip="Export all data"
                (click)="exportAllData!()"
                data-test="export-data-button"></button>
    </span>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetColumns!()"></button>
</div>

<dx-data-grid [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              [dataSource]="gridData"
              (onInitialized)="onInitialized($event)">
    <dxo-column-fixing [enabled]="true">
    </dxo-column-fixing>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-scrolling>
    </dxo-scrolling>

    <dxi-sort-by-group-summary-info summaryItem="custom"></dxi-sort-by-group-summary-info>
    <dxo-summary [calculateCustomSummary]="calcCustomSummary">
        <dxi-group-item summaryType="custom"
                        name="roleTypeCustomSummary"
                        showInColumn="Type"></dxi-group-item>
    </dxo-summary>

    <!-- Columns -->
    <dxi-column caption="Type"
                dataField="dummy_roleType"
                [calculateGroupValue]="calculateRoleTypeDisplayValue"
                groupCellTemplate="roleTypeCellTemplate"
                dataType="string"
                [groupIndex]="0">
    </dxi-column>

    <dxi-column caption="Access levels"
                dataType="string"
                dataField="role"
                sortOrder="asc"
                [allowSorting]="true"
                [visible]="true"
                cellTemplate="accessLevelCellTemplate"
                [calculateCellValue]="calculateRoleCellValue">
    </dxi-column>

    <dxi-column caption="People"
                dataType="string"
                dataField="people"
                [allowSorting]="false"
                [allowFiltering]="true"
                [allowHeaderFiltering]="false"
                [allowSearch]="true"
                [visible]="true"
                cellTemplate="peopleCellTemplate">
    </dxi-column>

    <dxi-column caption=""
                dataType="string"
                dataField="actions"
                cellTemplate="actionCellTemplate"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [fixed]="true"
                fixedPosition="right"
                [width]="50">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let item of 'roleTypeCellTemplate'">
        User type: <b>{{item.value}}</b>
    </div>
    <div *dxTemplate="let item of 'accessLevelCellTemplate'">
        <adapt-link-access-level [role]="item.data.role"></adapt-link-access-level>
    </div>

    <div *dxTemplate="let item of 'peopleCellTemplate'"
         class="d-flex flex-row align-items-center gap-1"
         [ngSwitch]="item.data.people.length > 10">
        <ng-container *ngSwitchCase="false">
            <adapt-link-person *ngFor="let person of item.data.people"
                               [person]="person"
                               [imageOnly]="true"></adapt-link-person>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <adapt-link-person *ngFor="let person of item.data.people.slice(0, 10)"
                               [person]="person"
                               [imageOnly]="true"></adapt-link-person>
            <a [routerLink]="managePeopleUrl"
               [queryParams]="item.data.managePeopleParams"> +{{item.data.people.length - 10}} more users</a>
        </ng-container>
    </div>

    <div *dxTemplate="let item of 'actionCellTemplate'">
        <adapt-access-level-actions [role]="item.data.role"></adapt-access-level-actions>
    </div>
</dx-data-grid>
