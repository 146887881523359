import { Component, Input, OnChanges } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IntegratedArchitectureFrameworkService } from "../../integrated-architecture/integrated-architecture-framework.service";

@Component({
    selector: "adapt-role-people-panel",
    templateUrl: "./role-people-panel.component.html",
    styleUrls: ["./role-people-panel.component.scss"],
})
export class RolePeoplePanelComponent extends BaseComponent implements OnChanges {
    @Input() public role?: Role;

    public guidance = this.integratedArchitectureFrameworkService.roleGuidance;
    public uniqueActiveConnections: Set<Connection> = new Set();
    public uniqueInactiveConnections: Set<Connection> = new Set();
    public showInactiveConnections = false;

    constructor(
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
    ) {
        super();

        this.integratedArchitectureFrameworkService.roleConnectionChange$
            .pipe(this.takeUntilDestroyed())
            .subscribe(() => this.updateUniqueConnections());
    }

    public ngOnChanges() {
        this.updateUniqueConnections();
    }

    private updateUniqueConnections() {
        this.uniqueActiveConnections.clear();
        this.uniqueInactiveConnections.clear();

        if (this.role) {
            if (this.role.roleConnections) {
                const [activeRoleConnections, inactiveRoleConnections] = ArrayUtilities.partition(
                    this.role.roleConnections,
                    (roleConnection) => roleConnection.isActive() && roleConnection.connection.isActive(),
                );

                this.uniqueActiveConnections = new Set<Connection>(activeRoleConnections
                    .map((rc) => rc.connection)
                    .sort(SortUtilities.getSortByFieldFunction<Connection>("fullName"))
                    .reverse());

                this.uniqueInactiveConnections = new Set<Connection>(inactiveRoleConnections
                    .map((rc) => rc.connection)
                    .sort(SortUtilities.getSortByFieldFunction<Connection>("fullName"))
                    .reverse());
            }

            this.showInactiveConnections = !this.role.isActive() && this.uniqueInactiveConnections.size > 0;
        }
    }
}
