import { CatchupRatingTypeName } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { GraphConstants } from "./graph-constants";
import { IGraphLink, IGraphNode } from "./linked-nodes-graph.component";

export class SVGUtils {
    public static initMarkerArrows(defs: d3.Selection<d3.BaseType, unknown, null, undefined>) {
        const arrows = [
            { id: "straightMarkerArrow20", refX: 20, refY: 0, path: "M0,-5L10,0L0,5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -5 10 10" },
            { id: "straightMarkerArrow30", refX: 25, refY: 0, path: "M0,-5L10,0L0,5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -5 10 10" },
            { id: "straightMarkerArrow40", refX: 30, refY: 0, path: "M0,-5L10,0L0,5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -5 10 10" },
            { id: "arcMarkerArrow20", refX: 11.5, refY: 0, path: "M0,-2.5L6,0L0,2.5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -3 6 6" },
            { id: "arcMarkerArrow30", refX: 15, refY: -0.2, path: "M0,-2.5L6,0L0,2.5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -3 6 6" },
            { id: "arcMarkerArrow40", refX: 18, refY: -0.8, path: "M0,-2.5L6,0L0,2.5", fill: GraphConstants.DefaultLinkColor, viewbox: "0 -3 6 6" },
            { id: "straightMarkerCircle20", refX: 13.5, refY: 0, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-5 -5 10 10" },
            { id: "straightMarkerCircle30", refX: 16, refY: 0, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-4 -4 8 8" },
            { id: "straightMarkerCircle40", refX: 20, refY: 0, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-4 -4 8 8" },
            { id: "arcMarkerCircle20", refX: 13.5, refY: 0, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-5 -5 10 10" },
            { id: "arcMarkerCircle30", refX: 16, refY: -0.2, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-4 -4 8 8" },
            { id: "arcMarkerCircle40", refX: 20, refY: -0.8, path: "M-3,0a3,3 0 1,0 6,0a3,3 0 1,0 -6,0", fill: GraphConstants.DefaultLinkColor, viewbox: "-4 -4 8 8" },
        ];
        const originalArrows = [...arrows];
        const colorKeys = Object.keys(GraphConstants.ColorMap);
        for (const key of colorKeys) {
            // there will be a set of marker corresponds to each color defined in the options.colorMap
            copyArrowSet(GraphConstants.ColorMap[key], key);
        }

        return defs.selectAll("marker")
            .data(arrows)
            .enter()
            .append("svg:marker")
            .attr("id", (arrow) => arrow.id)
            .attr("viewBox", (arrow) => arrow.viewbox)
            .attr("refX", (arrow) => arrow.refX)
            .attr("refY", (arrow) => arrow.refY)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("svg:path")
            .attr("d", (arrow) => arrow.path)
            .style("stroke-width", 1)
            .style("fill", (arrow) => arrow.fill);

        function copyArrowSet(colorValue: string, colorName: string) {
            originalArrows.forEach((arrow) => {
                const colorArrow = { ...arrow };
                colorArrow.id += colorName;
                colorArrow.fill = colorValue;
                arrows.push(colorArrow);
            });
        }
    }

    public static initClipPaths(defs: d3.Selection<d3.BaseType, unknown, null, undefined>) {
        // these clip paths will clip off the image to become a circle
        const clipCircles = [
            { id: "clipCircle10", radius: 10 },
            { id: "clipCircle15", radius: 15 },
            { id: "clipCircle20", radius: 20 },
            { id: "clipCircle30", radius: 30 },
            { id: "clipCircle40", radius: 40 },
        ];

        return defs.selectAll("clipPath")
            .data(clipCircles)
            .enter()
            .append("svg:clipPath")
            .attr("id", (circle) => circle.id)
            .append("circle")
            .attr("r", (circle) => circle.radius - ((circle.radius > 20) ? 3 : 2))
            .attr("cx", 0)
            .attr("cy", 0);
    }

    public static getHiddenLinkCircleX(link: IGraphLink) {
        const sourceNode = link.source as IGraphNode;
        const targetNode = link.target as IGraphNode;
        const dx = sourceNode.x - targetNode.x;
        const dy = sourceNode.y - targetNode.y;
        const angle = Math.atan(dy / dx);
        let offsetX = (targetNode.radius! + GraphConstants.HiddenLinkCircleRadius) * Math.cos(angle);

        if (sourceNode.x < targetNode.x) {
            // angle is only from -90 to 90, have to handle the other 2 quadrants separately
            offsetX = -offsetX;
        }

        return targetNode.x + offsetX;
    }

    public static getHiddenLinkCircleY(link: IGraphLink) {
        const sourceNode = link.source as IGraphNode;
        const targetNode = link.target as IGraphNode;
        const dx = sourceNode.x - targetNode.x;
        const dy = sourceNode.y - targetNode.y;
        const angle = Math.atan(dy / dx);
        let offsetY = (targetNode.radius! + GraphConstants.HiddenLinkCircleRadius) * Math.sin(angle);

        if (sourceNode.x < targetNode.x) {
            offsetY = -offsetY;
        }

        return targetNode.y + offsetY;
    }


    public static translateNode(node: IGraphNode) {
        return `translate(${node.x},${node.y})`;
    }

    public static getTargetCoordinates(link: IGraphLink, offset: number) {
        if (link.pseudo) {
            return { x: (link.target as IGraphNode).x, y: (link.target as IGraphNode).y };
        }

        return this.getNodeCoordinatesOffset(link.target as IGraphNode, link.source as IGraphNode, offset);
    }

    public static getSourceCoordinates(link: IGraphLink, offset: number) {
        if (link.pseudo || !link.rating) {
            return { x: (link.source as IGraphNode).x, y: (link.source as IGraphNode).y };
        }

        return this.getNodeCoordinatesOffset(link.source as IGraphNode, link.target as IGraphNode, offset);
    }

    public static getNodeCoordinatesOffset(node: IGraphNode, otherNode: IGraphNode, offset: number) {
        if (!node.x || !node.y || !otherNode.x || !otherNode.y) {
            return { x: 0, y: 0 };
        }

        const dx = otherNode.x - node.x;
        const dy = otherNode.y - node.y;
        const angle = Math.atan(dy / dx);
        const nodeRadius = node.radius ?? GraphConstants.NodeRadius;
        let offsetX = (nodeRadius + offset) * Math.cos(angle);
        let offsetY = (nodeRadius + offset) * Math.sin(angle);

        if (otherNode.x < node.x) {
            // angle is only from -90 to 90, have to handle the other 2 quadrants separately
            offsetX = -offsetX;
            offsetY = -offsetY;
        }

        return {
            x: node.x + offsetX,
            y: node.y + offsetY,
        };
    }


    public static getLinkClass(link: IGraphLink) {
        let linkClass = "link";
        if (link.pseudo) {
            linkClass += " pseudoLink";
        } else if (link.noLine) {
            linkClass += " noLine";
        } else {
            linkClass += " actualLink";
        }

        if (link.arrowEnd) {
            linkClass += " arrowEnd";
        }

        if (link.circleEnd) {
            linkClass += " circleEnd";
        }

        return linkClass;
    }

    public static getStrokeWidth(link: IGraphLink) {
        // fix this as the marker arrows will need to be re-aligned for different width
        if (link.noLine) {
            return 0;
        } else if (link.opacity < 0.05) {
            return 0.05;
        } else {
            return link.strokeWidth ?? 3; // use 3 as that's aligned for the marker arrows
        }
    }

    public static getStrokeColor(link: IGraphLink) {
        const strokeColor = GraphConstants.ColorMap[link.color!];
        if (strokeColor) {
            return strokeColor;
        } else {
            return link.color ?? GraphConstants.DefaultNodeColor;
        }
    }

    public static getLinkOpacity(link: IGraphLink) {
        if (link.opacity) {
            return link.opacity < 0.5 ? 0.5 : link.opacity;
        } else {
            return 1.0;
        }
    }

    public static getNodeColor(node: IGraphNode) {
        const nodeColor = node.color ? GraphConstants.ColorMap[node.color] : undefined;
        if (nodeColor) {
            return nodeColor;
        } else if (node.color) {
            return node.color;
        }

        return node.selected ? GraphConstants.DefaultSelectedNodeColor : GraphConstants.DefaultNodeColor;
    }

    public static getNodeRadius(node: IGraphNode) {
        return node.radius ?? GraphConstants.NodeRadius;
    }

    public static getImageOffset(node: IGraphNode) {
        return SVGUtils.getNodeRadius(node) * -1;
    }

    public static getClipPath(node: IGraphNode) {
        return SVGUtils.getClipPathForRadius(SVGUtils.getNodeRadius(node));
    }

    public static getClipPathForRadius(radius: number) {
        return `url(${window.location.href}#clipCircle${radius})`;
    }

    public static getArrowEndPointsWithOffset(linkData: IGraphLink, offset: number) {
        const sourceNode = linkData.source as IGraphNode;
        const targetNode = linkData.target as IGraphNode;
        const nodeRadius = targetNode.radius ?? GraphConstants.NodeRadius;
        const dx = sourceNode.x - targetNode.x;
        const dy = sourceNode.y - targetNode.y;
        const angle = Math.atan(dy / dx);
        let tipX = (nodeRadius + offset) * Math.cos(angle);
        let tipY = (nodeRadius + offset) * Math.sin(angle);
        const tiltedAngle = GraphConstants.HiddenLinkArrowTiltAngle * nodeRadius / (nodeRadius + offset);
        let base1X = (nodeRadius + offset + GraphConstants.HiddenLinkArrowHeight) * Math.cos(angle + tiltedAngle);
        let base1Y = (nodeRadius + offset + GraphConstants.HiddenLinkArrowHeight) * Math.sin(angle + tiltedAngle);
        let base2X = (nodeRadius + offset + GraphConstants.HiddenLinkArrowHeight) * Math.cos(angle - tiltedAngle);
        let base2Y = (nodeRadius + offset + GraphConstants.HiddenLinkArrowHeight) * Math.sin(angle - tiltedAngle);

        if (sourceNode.x < targetNode.x) {
            tipX = -tipX;
            tipY = -tipY;
            base1X = -base1X;
            base1Y = -base1Y;
            base2X = -base2X;
            base2Y = -base2Y;
        }

        tipX = targetNode.x + tipX;
        tipY = targetNode.y + tipY;
        base1X = targetNode.x + base1X;
        base1Y = targetNode.y + base1Y;
        base2X = targetNode.x + base2X;
        base2Y = targetNode.y + base2Y;

        return `${tipX} ${tipY} ${base1X} ${base1Y} ${base2X} ${base2Y}`;
    }

    public static getD3EventOffset() {
        // Have to implement this as event.offsetX/Y not working
        // on firefox and IE
        // This approach will be consistent on all browsers.
        const event = (d3 as any).event as MouseEvent;
        const target = event.target || event.srcElement as any;
        const svgElement = target.ownerSVGElement;
        let offsetX;
        let offsetY;

        if (svgElement) {
            const rect = svgElement.getBoundingClientRect();

            offsetX = event.clientX - rect.left;
            offsetY = event.clientY - rect.top;
        } else if (target.viewBox) {
            const svgRect = target.getBoundingClientRect();

            offsetX = event.clientX - svgRect.left;
            offsetY = event.clientY - svgRect.top;
        } else {
            offsetX = event.clientX;
            offsetY = event.clientY;
        }

        return {
            x: offsetX,
            y: offsetY,
        };
    }

    public static distance(a: { x: number, y: number }, b: { x: number, y: number }) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return Math.sqrt((dx * dx) + (dy * dy));
    }

    public static highlightLinkTipMouseOver(link: IGraphLink, linkSelector: d3.Selection<SVGElement, unknown, SVGSVGElement, unknown>) {
        link.isMouseOver = true;
        if (link.rating?.ratingType === CatchupRatingTypeName.Connection ||
            !link.rating) { // no rating -> missing link
            // link make the line thicker when mouseover
            linkSelector.style("stroke-width", (l: IGraphLink) => SVGUtils.getStrokeWidth(l) + (l.isMouseOver ? 1 : 0));
            linkSelector.style("stroke-opacity", (l: IGraphLink) => l.isMouseOver ? 1.0 : (l.opacity ?? 1.0));
        } else {
            // this is the tip only (circle or arrow)
            linkSelector.style("stroke-width", (l: IGraphLink) => l.isMouseOver ? 2 : 0);
            linkSelector.style("stroke", (l: IGraphLink) => l.isMouseOver ? SVGUtils.getStrokeColor(l) : null);
        }
    }

    public static resetLinkTipMouseOver(link: IGraphLink, linkSelector: d3.Selection<SVGElement, unknown, SVGSVGElement, unknown>) {
        link.isMouseOver = false;
        if (link.rating?.ratingType === CatchupRatingTypeName.Connection ||
            !link.rating) {
            linkSelector.style("stroke-width", SVGUtils.getStrokeWidth);
            linkSelector.style("stroke-opacity", (l: IGraphLink) => l.opacity ?? 1.0);
        } else {
            linkSelector.style("stroke-width", null);
            linkSelector.style("stroke", null);
        }
    }
}
