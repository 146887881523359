<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <h2>
            <adapt-link-person [personId]="person.personId"
                               [showImage]="true"></adapt-link-person>
        </h2>

        <adapt-ci-history [personId]="person.personId"
                          [options]="culturalIndexHistoryOptions"></adapt-ci-history>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButtonIcon="fal fa-download"
                (click)="exportGrid()">Export</button>
        <button adaptButton="ok"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>
