import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CareerValuationUiService } from "../career-valuation-ui.service";

@Component({
    selector: "adapt-cvt-category-value",
    templateUrl: "./cvt-category-value.component.html",
    styleUrls: ["./cvt-category-value.component.scss", "../career-valuation.scss"],
})
export class CareerValuationCategoryValueComponent extends BaseComponent implements OnChanges {
    @Input() public categoryValue!: CareerValuationCategoryValue;

    public indicatorColor?: string;

    public constructor(
        public careerValuationUiService: CareerValuationUiService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.categoryValue) {
            this.indicatorColor = this.careerValuationUiService.getCategoryColour(this.categoryValue.category);
        }
    }

    @Autobind
    public getCustomText() {
        return this.categoryValue.actualValue + " / " + this.categoryValue.idealValue;
    }
}
