import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IObjectiveGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-objectives-tree-dashboard-element",
    templateUrl: "./objectives-tree-dashboard-element.component.html",
})
export class ObjectivesTreeDashboardElementComponent extends BaseComponent {
    @Input() public set team(value: Team | undefined) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    @Input() public isCompactView = false;

    public teamObjectivesGroups$: Observable<IObjectiveGroup[]>;
    public objectivesHref$: Observable<string>;

    constructor(
        objectivesService: ObjectivesService,
        objectivesRouteService: ObjectivesRouteService,
        labellingService: LabellingService,
    ) {
        super();

        this.teamObjectivesGroups$ = this.team$.pipe(
            switchMap((team) => objectivesService.getInProgressObjectives(team, false)),
            switchMap((objectives) => labellingService.primeLabelLocationsForObjectives(objectives).pipe(
                map(() => objectives),
            )),
            map((objectives) => objectivesService.groupObjectives(objectives)),
        );
        this.objectivesHref$ = this.team$.pipe(
            switchMap((team) => {
                return objectivesRouteService.getObjectivePageRoute(team ? team.teamId : undefined);
            }),
            map((adaptRoute) => adaptRoute.path),
        );
    }
}
