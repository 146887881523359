<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>{{title}}</h3>

    <ng-container adapt-dialog-content>
        <div class="form-group">
            <label>Name</label>
            <dx-text-box [(value)]="product.name"
                         placeholder="Enter product or service name">
                <dx-validator>
                    <dxi-validation-rule type="custom"
                                         [validationCallback]="validateDuplicates"
                                         message="Product or service name already in use"></dxi-validation-rule>
                    <dxi-validation-rule [adaptEntity]="product"
                                         adaptEntityProperty="name"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <div class="form-group">
            <label>Description</label>
            <adapt-html-editor [(contents)]="product.description"
                               (isValidChange)="isEditorValid = $event"></adapt-html-editor>
        </div>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="saveError">
            {{saveError}}
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
