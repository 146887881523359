import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { cacheLatest } from "@common/lib/utilities/rxjs-utilities";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { TeamsService } from "app/features/people/teams/teams.service";
import { combineLatest, merge, Observable } from "rxjs";
import { debounceTime, map, startWith, switchMap, tap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

export interface IMapEntityTemplates {
    KeyFunction?: TemplateRef<unknown>;
    Team?: TemplateRef<unknown>;
}

@Component({
    selector: "adapt-render-organisation-map",
    templateUrl: "render-organisation-map.component.html",
    styleUrls: ["./render-organisation-map.component.scss"],
})
export class RenderOrganisationMapComponent {
    @Input() public entityTemplates?: IMapEntityTemplates;
    @Input() public globalZoneLocationTemplate?: TemplateRef<unknown>;
    @Input() public showGlobalZoneLocation = false;
    @Input() public globalZoneLocationLabel = "";
    @Input() public noKeyFunctionsTemplate?: TemplateRef<any>;
    @Input() public filterTeams?: (teams: Team[]) => Team[] | Observable<Team[]>;
    @Output() public loaded = new EventEmitter<KeyFunction[]>();

    public keyFunctions$: Observable<KeyFunction[]>;
    public teamLocations$: Observable<TeamLocation[]>;
    public showResearchAndDevelopment$: Observable<boolean>;
    public showEconomicEngine$: Observable<boolean>;
    public valueStreams$: Observable<ValueStream[]>;

    public constructor(
        private keyFunctionsService: KeyFunctionsService,
        private teamsService: TeamsService,
        rxjsBreezeService: RxjsBreezeService,
        labellingService: LabellingService,
        tier1Service: Tier1ArchitectureService,
    ) {
        this.valueStreams$ = rxjsBreezeService.entityTypeChangedInSave(ValueStream).pipe(
            startWith(undefined),
            switchMap(() => tier1Service.getActiveValueStreams()),
        );

        this.keyFunctions$ = rxjsBreezeService.entityTypeChanged(KeyFunction).pipe(
            startWith(undefined),
            debounceTime(100),
            // This will also preload all key functions so that we don't need to create so many requests to render the view
            switchMap(() => this.keyFunctionsService.getAllKeyFunctions()),
            // single prime labels query instead of 1 for each key function
            switchMap((keyFunctions) => labellingService.getLabelLocationsByPredicate(new MethodologyPredicate<LabelLocation>("keyFunctionId", "!=", null)).pipe(
                map(() => keyFunctions),
            )),
            tap((keyFunctions) => this.loaded.emit(keyFunctions)),
            cacheLatest(),
        );

        this.teamLocations$ = merge(
            rxjsBreezeService.entityTypeChanged(Team),
            rxjsBreezeService.entityTypeChanged(TeamLocation),
        ).pipe(
            startWith(undefined),
            debounceTime(100),
            switchMap(() => this.teamsService.getAllTeamLocations()),
            cacheLatest(),
        );

        this.showResearchAndDevelopment$ = this.shouldShowZone(Zone.ResearchAndDevelopment);
        this.showEconomicEngine$ = this.shouldShowZone(Zone.EconomicEngine);
    }

    private shouldShowZone(zone: Zone) {
        const zoneLocation = KeyFunctionLocation.fromZone(zone);
        return combineLatest([this.keyFunctions$, this.teamLocations$]).pipe(
            map(([allKeyFunctions, allTeamLocations]) => {
                return (allKeyFunctions.some((i) => i.isInLocation(zoneLocation)) && this.entityTemplates?.KeyFunction !== undefined)
                    || (allTeamLocations.some((i) => i.zone === zone) && this.entityTemplates?.Team !== undefined);
            }),
        );
    }
}
