import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";

export enum ExportType {
    Excel = "Microsoft Excel",
    EmbedBoard = "embedADAPT Board",
}

const ExportTypeDescription: { [type: string]: string } = {
    [ExportType.Excel]: "Export the tasks to a Microsoft Excel spreadsheet that the team can use to track progress.",
    [ExportType.EmbedBoard]: "Export role tasks to an embedADAPT board that the team can use to track progress.",
};

@Component({
    selector: "adapt-export-type-selector-step",
    templateUrl: "./export-type-selector-step.component.html",
    styleUrls: ["../exporter-style.scss"],
})
export class ExportTypeSelectorStepComponent extends BaseComponent implements OnInit {
    @Input() public exportType?: ExportType;
    @Output() public exportTypeChange = new EventEmitter<ExportType>();

    public readonly exportSelections = [
        ExportType.Excel,
        ExportType.EmbedBoard,
    ];

    public currentSelection?: ExportType;
    public selectionDescription = "";

    constructor() {
        super();
    }

    public ngOnInit() {
        this.currentSelection = this.exportType ?? this.exportSelections[0];

        // can only emit here - not constructor as emit won't get to subscriber
        // from container
        this.onSelectionChanged(this.currentSelection);
    }

    public onSelectionChanged(selection: ExportType) {
        this.selectionDescription = ExportTypeDescription[selection];
        this.exportTypeChange.emit(selection);
    }
}
