import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptValueStreamsSharedModule } from "app/features/architecture/value-streams/value-streams-shared.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { DeleteProductDialogComponent } from "./delete-product-dialog/delete-product-dialog.component";
import { DisplayProductUsageComponent } from "./display-product-usage/display-product-usage.component";
import { EditProductDialogComponent } from "./edit-product-dialog/edit-product-dialog.component";
import { MergeProductsDialogComponent } from "./merge-products-dialog/merge-products-dialog.component";
import { ProductCataloguePageComponent } from "./product-catalogue-page/product-catalogue-page.component";
import { productCataloguePageRoute } from "./product-catalogue-page/product-catalogue-page.route";
import { ShowProductUsageDialogComponent } from "./show-product-usage-dialog/show-product-usage-dialog.component";


@NgModule({
    imports: [
        CommonModule,
        DxTextBoxModule,
        DxListModule,
        AdaptCommonDialogModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptValueStreamsSharedModule,
        AdaptLoadingSpinnerModule,
        DxAccordionModule,
        DxSelectBoxModule,
        AdaptTwoColumnModule,
        AdaptEmbedShellModule,
        AdaptDashboardElementModule,
    ],
    declarations: [
        DeleteProductDialogComponent,
        EditProductDialogComponent,
        MergeProductsDialogComponent,
        ShowProductUsageDialogComponent,
        ProductCataloguePageComponent,
        DisplayProductUsageComponent,
    ],
})
export class AdaptProductCatalogueModule {
    public static readonly Routes = [
        ...productCataloguePageRoute.routes,
    ];
}
