import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexOrbitPageComponent } from "./ci-orbit-page.component";

const culturalIndexOrbitPageTitle = "Analyse Orbit";

export const culturalIndexOrbitPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-ci-orbit-page", CulturalIndexOrbitPageComponent)
    .atOrganisationUrl("/people/cultural-index/analyse-orbit")
    .redirectToThisRouteFromOrganisationUrl("/people/culturalIndex/analyseOrbit")
    .verifyingAccess(CulturalIndexAuthService.OpenCulturalIndexAnalysis)
    .verifyingFeatures(FeatureName.CulturalNetworkCulturalIndex)
    .withTitle(culturalIndexOrbitPageTitle)
    .build();
