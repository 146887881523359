import { Component, Input, OnInit } from "@angular/core";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { IRoleSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { roleDescriptionRoute } from "app/features/architecture/role/role-description-page/role-description-page.route";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-role",
    templateUrl: "./search-row-role.component.html",
    styleUrls: ["./search-row-role.component.scss"],
})
export class SearchRowRoleComponent extends SearchRowBaseComponent<IRoleSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    @Input() public showMatches = true;
    @Input() public showLabels = true;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = roleDescriptionRoute.getRouteObject(this.result.role).pipe(
            emptyIfUndefinedOrNull(),
        );

        this.matches = this.showMatches ? this.result.results : [];
    }
}
