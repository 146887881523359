import { Injectable, OnDestroy } from "@angular/core";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IFullscreenDialogConfig } from "@common/ux/adapt-common-dialog/fullscreen-dialog/fullscreen-dialog.component";
import { TutorialDialogComponent } from "@common/ux/adapt-common-dialog/tutorial-dialog/tutorial-dialog.component";
import { Subscription } from "rxjs";

export interface IHelpTourConfig extends IFullscreenDialogConfig {
    name: string;
    title?: string;
    content?: string;
    thumbnail?: string;
}

@Injectable({
    providedIn: "root",
})
export class HelpTourService implements OnDestroy {
    private tutorialDialogSubscription?: Subscription;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
    ) { }

    public ngOnDestroy() {
        if (this.tutorialDialogSubscription) {
            this.tutorialDialogSubscription.unsubscribe();
        }
    }

    public tourHasRun(tourData: IHelpTourConfig) {
        const localStorageName = this.getLocalStorageNameForTour(tourData.name);

        return LocalStorage.get(localStorageName) === true;
    }

    public runTour(tourData: IHelpTourConfig) {
        this.tutorialDialogSubscription = this.commonDialogService.open(TutorialDialogComponent, tourData)
            .subscribe(() => {
                this.setTourHasRun(tourData.name);
            });
    }

    private getLocalStorageNameForTour(tourName: string) {
        return "Tour_" + tourName;
    }

    private setTourHasRun(tourName: string) {
        const tourLocalStorageName = this.getLocalStorageNameForTour(tourName);

        LocalStorage.set(tourLocalStorageName, true);
    }
}
