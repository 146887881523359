import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DocumentSize, SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocumentType } from "@common/ADAPT.Common.Model/organisation/system-document";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { ProcessMapUiService } from "app/features/architecture/process-map/process-map-ui.service";
import { forkJoin } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { EditSystemDiagramDialogComponent } from "../edit-system-diagram-dialog/edit-system-diagram-dialog.component";
import { SystemisationService } from "../systemisation.service";
import { SystemisationUiService } from "../systemisation-ui.service";

@Component({
    selector: "adapt-system-component-add-menu",
    templateUrl: "./system-component-add-menu.component.html",
})
export class SystemComponentAddMenuComponent extends BaseComponent {
    @Input() public system?: SystemEntity;
    @Input() public disabled = false;
    @Output() public systemComponentAdded = new EventEmitter<SystemComponent>();

    public systemMenu: IAdaptMenuItem[] = [{
        text: "Add element",
        icon: MenuComponent.SmallAddMenu.icon,
        items: [
            {
                icon: "fal fa-fw fa-align-left",
                text: "Add text",
                onClick: this.addRichtextDocumentToSystem,
            },
            {
                icon: "fal fa-fw fa-project-diagram",
                text: "Add diagram",
                onClick: this.addDiagramDocumentToSystem,
            },
            {
                icon: "fal fa-fw fa-sitemap",
                text: "Add task map",
                onClick: this.addProcessMapToSystem,
            },
            {
                icon: "fal fa-fw fa-file",
                text: "Add document",
                onClick: this.addDocumentSelectorDocumentToSystem,
            },
            {
                text: "Add external page",
                icon: "fal fa-fw fa-external-link-alt",
                onClick: this.addExternalLinkToSystem,
            },
            {
                text: "Add video",
                icon: "fal fa-fw fa-video",
                onClick: this.addVideoToSystem,
            },
        ],
    }];

    public constructor(
        private processMapUiService: ProcessMapUiService,
        private systemisationService: SystemisationService,
        private systemisationUiService: SystemisationUiService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    @Autobind
    private addProcessMapToSystem() {
        return this.processMapUiService.addProcessMap(this.system!).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((systemComponent) => this.systemComponentAdded.emit(systemComponent));
    }

    @Autobind
    private addVideoToSystem() {
        forkJoin([
            this.systemisationService.createSystemDocument(SystemDocumentType.Video),
            this.systemisationService.createSystemComponent(this.system!),
        ]).pipe(
            switchMap(([systemDocument, systemComponent]) => {
                systemComponent.systemDocument = systemDocument;
                return this.systemisationUiService.openEditVideoDialog(systemDocument, systemComponent).pipe(
                    tap(() => this.systemComponentAdded.emit(systemComponent)),
                );
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private addExternalLinkToSystem() {
        forkJoin([
            this.systemisationService.createSystemDocument(SystemDocumentType.ExternalLink),
            this.systemisationService.createSystemComponent(this.system!),
        ]).pipe(
            switchMap(([systemDocument, systemComponent]) => {
                systemComponent.systemDocument = systemDocument;
                return this.systemisationUiService.openEditExternalLinkDialog(systemComponent).pipe(
                    tap(() => this.systemComponentAdded.emit(systemComponent)),
                );
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private addRichtextDocumentToSystem() {
        forkJoin([
            this.systemisationService.createSystemDocument(),
            this.systemisationService.createSystemComponent(this.system!),
        ]).pipe(
            switchMap(([systemDocument, systemComponent]) => {
                systemComponent.systemDocument = systemDocument;

                return this.systemisationUiService.openSystemDocumentDialog(systemComponent)
                    .pipe(tap(() => this.systemComponentAdded.emit(systemComponent))); // only emit here if saved
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private addDocumentSelectorDocumentToSystem() {
        forkJoin([
            this.systemisationService.createSystemDocument(SystemDocumentType.Document),
            this.systemisationService.createSystemComponent(this.system!),
        ]).pipe(
            switchMap(([systemDocument, systemComponent]) => {
                systemComponent.systemDocument = systemDocument;

                return this.systemisationUiService.openSystemDocumentDialog(systemComponent)
                    .pipe(tap(() => this.systemComponentAdded.emit(systemComponent))); // only emit here if saved
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }


    @Autobind
    private addDiagramDocumentToSystem() {
        forkJoin([
            this.systemisationService.createSystemDiagram(),
            this.systemisationService.createSystemComponent(this.system!),
        ]).pipe(
            switchMap(([systemDiagram, systemComponent]) => {
                systemComponent.systemDiagram = systemDiagram;
                systemComponent.size = DocumentSize.Medium;

                return this.dialogService.open(EditSystemDiagramDialogComponent, systemComponent)
                    .pipe(tap(() => this.systemComponentAdded.emit(systemComponent)));
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}
