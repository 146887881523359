<div class="speed-catchup-edit-people"
     *adaptLoading="!currentPerson || !catchup">
    <!-- participants -->
    <div>
        <h2>Select participants</h2>
        <form role="form"
              class="d-flex flex-column">
            <div class="row">
                <div class="form-group col-lg-6">
                    <label>Participant 1</label>
                    <div class="participant-selection">
                        <adapt-link-person *ngIf="catchup.person1"
                                           [person]="catchup.person1"
                                           [imageOnly]="true"
                                           imageSize="md"></adapt-link-person>
                        <adapt-select-person class="w-100"
                                             [(person)]="catchup.person1"
                                             (personChange)="validatePeople()"
                                             [filter]="isNotExistingParticipantAndIsTeamMember"></adapt-select-person>
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <label>Participant 2</label>
                    <div class="participant-selection">
                        <adapt-link-person *ngIf="catchup.person2"
                                           [person]="catchup.person2"
                                           [imageOnly]="true"
                                           imageSize="md"></adapt-link-person>
                        <adapt-select-person class="w-100"
                                             [(person)]="catchup.person2"
                                             (personChange)="validatePeople()"
                                             [filter]="isNotExistingParticipantAndIsTeamMember"
                                             data-test="select-person-2"></adapt-select-person>
                    </div>
                </div>
            </div>
            <adapt-select-catchup-team class="mb-4"
                                       [catchup]="catchup"
                                       (selectionChange)="updateSelectedTeam($event)"
                                       (selectionValidityChange)="setSelectedTeamIsValid($event)"
                                       data-test="select-catch-up-team"></adapt-select-catchup-team>

            <div *ngIf="!showFacilitatorEntry">
                <!-- Div for button to not span the entire w0199-->
                <button adaptButton="add"
                        class="btn-small"
                        (click)="showFacilitatorEntry = true"
                        data-test="add-facilitator-button">facilitator</button>
            </div>

            <div class="form-group"
                 *ngIf="showFacilitatorEntry">
                <label>
                    Facilitated by
                    <span class="fal fa-info-circle"
                          adaptTooltip="A facilitator is an optional part of a Peer Catch-up."></span>
                </label>

                <div class="participant-selection">
                    <adapt-link-person *ngIf="catchup.facilitatorPerson"
                                       [person]="catchup.facilitatorPerson"
                                       [imageOnly]="true"
                                       imageSize="md"></adapt-link-person>
                    <adapt-select-person class="w-100"
                                         [(person)]="catchup.facilitatorPerson"
                                         (personChange)="validatePeople()"
                                         data-test="select-facilitator">
                    </adapt-select-person>
                </div>
            </div>

            <div class="alert alert-info mt-3"
                 role="alert"
                 *ngIf="!isValid">
                {{invalidMessage}}
            </div>
        </form>
    </div>
</div>
