<div adaptToolbarContent
     *ngIf="allowEditing">
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="createProduct">Create product
    </button>
    <button adaptButton="merge"
            [disabled]="mergeDisabled"
            [adaptBlockingClick]="mergeProducts"></button>
</div>
<adapt-two-column-layout leftColumnWidthClasses="col-12 col-md-8 col-lg-8 col-xl-9"
                         rightColumnWidthClasses="col-12 col-md-4 col-lg-4 col-xl-3"
                         [fullPage]="true">
    <adapt-dashboard-element left-column
                             name="Product & service catalogue">
        <dx-list [dataSource]="products"
                 [(selectedItems)]="selectedItems"
                 [showSelectionControls]="true"
                 [activeStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [selectionMode]="allowEditing ? 'multiple' : 'none'"
                 [allowItemDeleting]="allowEditing"
                 (onSelectionChanged)="onSelectionChanged()"
                 (onItemDeleting)="onItemDeleting($event)"
                 (onItemDeleted)="updateData$.next()"
                 [searchEnabled]="true"
                 searchExpr="name">
            <dxo-item-dragging [allowReordering]="false"></dxo-item-dragging>
            <div class="architecture-element-list-item"
                 *dxTemplate="let item of 'item'">
                <div>
                    <div class="architecture-element-list-item-heading text-wrap">
                        <strong>{{item.name}}</strong>
                        <button *ngIf="allowEditing"
                                [adaptBlockingClick]="editProduct"
                                [adaptBlockingClickParam]="item"
                                [stopPropagation]="true"
                                adaptButtonIcon="edit"
                                class="btn btn-link ms-1"></button>
                    </div>
                </div>
                <div *ngIf="item.description"
                     class="architecture-element-list-item-text text-wrap">
                    <span [froalaView]="item.description"></span>
                </div>
                <button *ngIf="allowEditing"
                        [adaptBlockingClick]="viewUsage"
                        [adaptBlockingClickParam]="item"
                        [stopPropagation]="true"
                        adaptButtonIcon="fal fa-fw fa-link fa-flip-horizontal"
                        class="btn btn-link">View usage...
                </button>
            </div>
        </dx-list>
    </adapt-dashboard-element>
    <adapt-dashboard-element right-column>
        <div adapt-dashboard-title>
            <h2><i class="fal fa-fw fa-info-circle"></i>Information</h2>
        </div>

        Products are the goods or services that the organisation delivers to customers via the value streams.
    </adapt-dashboard-element>
</adapt-two-column-layout>
