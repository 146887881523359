import { Component } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable } from "rxjs";
import { SurveyService } from "../../survey.service";
import { SurveyUtils } from "../../survey-utils";

@Component({
    selector: "adapt-employee-engagement-dashboard-element",
    templateUrl: "./ee-dashboard-element.component.html",
})
export class EmployeeEngagementDashboardElementComponent extends BaseComponent {
    public surveys$: Observable<Survey[]>;
    public surveyPageUrl$?: Observable<string>;

    public constructor(
        private surveyService: SurveyService,
    ) {
        super();

        this.surveyPageUrl$ = SurveyUtils.forSurveyType(SurveyType.EmployeeEngagement).getManageSurveyPageRoute$();
        this.surveys$ = this.surveyService.getMostRecentEndedSurveys(1, SurveyType.EmployeeEngagement);
    }
}
