import { Injectable, Injector } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonProfileItem, PersonProfileItemBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DirectoryService extends BaseOrganisationService {
    constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private userService: UserService,
        private authService: AuthorisationService,
        private directorySharedService: DirectorySharedService,
    ) {
        super(injector);
    }

    public async promiseToGetAccessiblePersonProfileItems(forceRemote?: boolean) {
        const canAccessPrivate = await this.authService.promiseToGetHasAccess(DirectoryAuthService.ReadAllProfileItems);
        if (canAccessPrivate) {
            return this.directorySharedService.promiseToGetAllPersonProfileItems(forceRemote);
        }

        const predicate = new MethodologyPredicate<PersonProfileItem>("isPrivate", "==", false);
        return lastValueFrom(this.commonDataService.getByPredicate(PersonProfileItemBreezeModel, predicate, forceRemote));
    }

    // Role Connections
    // Person access

    public async promiseToRemoveAccess(connection: Connection) {
        const dialogData: IConfirmationDialogData = {
            title: "Disallow login for " + connection.person.fullName + "?",
            message: "Removing the ability to log in will suspend their ability to access embedADAPT for your organisation. Are you sure?",
            confirmButtonText: "Disallow log in",
            cancelButtonText: "Cancel",
        };

        const result = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean(dialogData));
        if (result) {
            connection.hasAccess = false;
            return lastValueFrom(this.commonDataService.saveEntities([connection]));
        }
    }


    // Contact Details


    // Verify Access

    public promiseToVerifyFeaturesToDisplayTeams() {
        return this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
    }

    public promiseToVerifyFeaturesToDisplayRole() {
        return this.authService.promiseToGetHasAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2);
    }

    public async promiseToVerifyRoleRemoval(role?: Role, person?: Person) {
        if (!role) {
            return true;
        }

        const currentPerson = await this.userService.getCurrentPerson();
        if (person && currentPerson !== person) {
            return true;
        }

        const accessManagementRoles = await this.directorySharedService.promiseToGetRolesForPersonWithPermission(currentPerson!, FeaturePermissionName.OrganisationAccessManagementConfigure);
        return accessManagementRoles.length > 1 || !accessManagementRoles.includes(role);
    }

    public promiseToVerifyPermissionRemovalFromRole(featurePermissionName: FeaturePermissionName, role?: Role) {
        if (featurePermissionName !== FeaturePermissionName.OrganisationAccessManagementConfigure) {
            return Promise.resolve(true);
        }

        return this.promiseToVerifyRoleRemoval(role, undefined);
    }
}
