<ng-container *adaptLoadingUntilFirstEmit="let keyFunctions of keyFunctions$">
    <ng-container *ngIf="keyFunctions.length > 0; then zoneMapTemplate; else noKeyFunctionsTemplate || zoneMapTemplate"></ng-container>
</ng-container>

<ng-template #zoneMapTemplate>
    <adapt-render-expanded-zone-map [expandedZoneLocationTemplate]="zoneContentTemplate"
                                    [globalZoneLocationTemplate]="globalZoneLocationTemplate"
                                    [showGlobalZoneLocation]="showGlobalZoneLocation"
                                    [globalZoneLocationLabel]="globalZoneLocationLabel"
                                    [showResearchAndDevelopment]="showResearchAndDevelopment$ | async"
                                    [showEconomicEngine]="showEconomicEngine$ | async"
                                    [valueStreams]="valueStreams$"
                                    [valueStreamTitleTemplate]="valueStreamTitle"></adapt-render-expanded-zone-map>
</ng-template>

<ng-template #zoneContentTemplate
             let-zone="zone"
             let-valueStream="valueStream">
    <adapt-list-entities-in-location [zone]="zone"
                                     [valueStream]="valueStream"
                                     [filterTeams]="filterTeams"
                                     [entityTemplates]="entityTemplates"></adapt-list-entities-in-location>
</ng-template>

<ng-template #valueStreamTitle
             let-valueStream="valueStream">
    <div class="value-stream-title">
        <adapt-link-value-stream [valueStream]="valueStream"></adapt-link-value-stream>
    </div>
</ng-template>
