import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { PeerCatchupAuthService } from "../peer-catchup-auth.service";

@Component({
    selector: "adapt-analyse-catchups-page",
    templateUrl: "./analyse-catchups-page.component.html",
})
export class AnalyseCatchupsPageComponent extends BaseRoutedComponent implements OnInit {

    constructor(
        injector: Injector,
        private catchupAuthService: PeerCatchupAuthService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.verifyHasAccessToRoute(this.catchupAuthService.canReadAllCatchups);

        super.notifyActivated();
    }
}
