import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamBusinessModel } from "@common/ADAPT.Common.Model/organisation/value-stream-business-model";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CallToActionText } from "@org-common/lib/call-to-action/call-to-action-text";
import { from, lastValueFrom, Observable, of, throwError } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { Tier1ArchitectureUiService } from "../../tier1-architecture/tier1-architecture-ui.service";
import { valueStreamDashboardPageRoute } from "../value-stream-dashboard-page/value-stream-dashboard-page.route";

@Component({
    selector: "adapt-display-point-of-difference",
    styleUrls: ["../value-stream-common-styles.scss"],
    templateUrl: "./display-point-of-difference.component.html",
})
export class DisplayPointOfDifferenceComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public valueStream!: ValueStream;
    @Input() public readOnly = false;

    public canEditTier1Architecture$?: Observable<boolean>;
    public featureUrl$ = of("");
    public businessModel?: ValueStreamBusinessModel;
    public CallToActionText = CallToActionText;

    public constructor(
        private authService: AuthorisationService,
        private tier1Service: Tier1ArchitectureService,
        private tier1UiService: Tier1ArchitectureUiService,
    ) {
        super();
    }

    public ngOnInit(): void {
        if (!this.readOnly) {
            this.canEditTier1Architecture$ = from(this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.EditTier1));
        }
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.valueStream && this.valueStream) {
            this.businessModel = await lastValueFrom(this.tier1Service.getValueStreamBusinessModelForValueStreamId(this.valueStream.valueStreamId));
        }

        this.featureUrl$ = this.readOnly && this.valueStream
            ? valueStreamDashboardPageRoute.getRoute(this.valueStream)
            : of("");
    }

    @Autobind
    public promiseToEditPointOfDifference() {
        if (!this.businessModel) {
            return throwError("Not supposed to get here without business model");
        }

        return this.tier1UiService.editValueStreamBusinessModelElement({
            title: "Point of difference",
            tooltip: "Describes how your offering is unique in the market.",
            entities: [{
                label: "Point of difference",
                entity: this.businessModel,
                fieldName: "pointOfDifference",
                type: SimpleValueType.RichText,
            }],
            saveOnClose: true,
        });
    }
}
