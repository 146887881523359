import { Component, Inject } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { UserManagementService } from "../../user-management/user-management.service";

@Component({
    selector: "adapt-edit-start-date-dialog",
    templateUrl: "./edit-start-date-dialog.component.html",
})
export class EditStartDateDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Connection> {
    public readonly dialogName = "EditPersonContacts";
    public autoResolveData = this.connection;

    public entitiesToConfirm: IBreezeEntity[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public connection: Connection,
        private userManagementService: UserManagementService,
    ) {
        super();
    }

    public get dateIsFuture() {
        return this.connection.startDate.getTime() > new Date().getTime();
    }

    public get nonAccessRoleConnectionsChanged() {
        return this.entitiesToConfirm
            .filter((ent) => {
                return ent instanceof RoleConnection
                    // we only want to warn the user if an endDate is changing
                    && ent.entityAspect.originalValues.endDate
                    && ent.role
                    && DirectorySharedService.isNotAccessLevelRole(ent.role);
            }) as RoleConnection[];
    }

    public dateChanged(date: Date) {
        if (!date) {
            return;
        }

        // clear previous changes
        this.entitiesToConfirm.forEach((e) => e.entityAspect.rejectChanges());
        this.entitiesToConfirm = this.userManagementService.changeConnectionStartDate(this.connection, date);
    }
}
