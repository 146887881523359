import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { InitializedEvent } from "devextreme/viz/chart";
import { merge } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { CareerValuationService, ICareerValuationStatistics } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";

@Component({
    selector: "adapt-cvt-organisation-actuals-chart",
    templateUrl: "./analyse-cvt-organisation-actuals-chart.component.html",
})
export class CareerValuationOrganisationActualsChartComponent extends BaseComponent implements OnChanges {
    @Input() public date?: Date;

    public isLoading = true;

    public filterParameters?: ICareerValuationAnalysisFilter;

    public data?: ICareerValuationStatistics;

    public constructor(
        elementRef: ElementRef,
        private careerValuationService: CareerValuationService,
        private filterService: CareerValuationFilterService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        this.filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((filter) => {
            this.filterParameters = filter;
            this.updateDataAndChart();
        });

        merge(
            rxjsBreezeService.entityTypeChangedInSave(CareerValuation),
            rxjsBreezeService.entityTypeChangedInSave(CareerValuationCategoryValue),
        ).pipe(
            debounceTime(50),
            switchMap(() => this.updateDataAndChart()),
            this.takeUntilDestroyed(),
        )
            .subscribe();
    }

    public onInitialized(e: InitializedEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    public ngOnChanges() {
        this.updateDataAndChart();
    }

    @Autobind
    private async updateDataAndChart() {
        const filterParameters = { ...this.filterParameters!, date: this.date };
        const data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(filterParameters);
        this.data = this.careerValuationService.generateCareerValuationStatistics(data);
        this.isLoading = false;
    }

    public customizeTooltip(info: IDxChartTooltipPointInfo) {
        const result = {
            html: `${info.argumentText}<br/>${info.seriesName}: ${info.valueText} / ${info.totalText} (${info.percentText})`,
        };

        return result;
    }
}
