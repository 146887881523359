import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone } from "../methodology/zone";
import { Organisation } from "./organisation";

export class ResilientBusinessGoal extends BaseEntity<ResilientBusinessGoal> {
    public resilientBusinessGoalId!: number;
    public organisationId!: number;
    public zone!: Zone;
    public content?: string;

    public organisation!: Organisation;
}

export const ResilientBusinessGoalBreezeModel = new BreezeModelBuilder("ResilientBusinessGoal", ResilientBusinessGoal)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();
