import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermission } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { RoleTypeCodeLabel } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { RouteService } from "@common/route/route.service";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { ManagePeopleGridComponent } from "@org-common/lib/user-management/manage-people-grid/manage-people-grid.component";
import { managePeoplePageRoute } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { IntegratedArchitectureFrameworkUiService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-ui.service";
import { IRolePeopleGridRow } from "app/features/architecture/integrated-architecture/roles-page/roles-page.component";
import dxDataGrid, { CustomSummaryInfo, InitializedEvent } from "devextreme/ui/data_grid";
import { lastValueFrom, merge } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { UserManagementUiService } from "../user-management/user-management-ui.service";

@Component({
    selector: "adapt-manage-access",
    templateUrl: "./manage-access-page.component.html",
})
export class ManageAccessPageComponent extends BaseRoutedComponent implements OnInit {
    public gridData: IRolePeopleGridRow[] = [];
    public managePeopleUrl: string = "";

    private roles: Role[] = [];
    private roleConnections: RoleConnection[] = [];

    public dxGridWrapperHelper: DxGridWrapperHelper;

    private gridInstance?: dxDataGrid;

    public constructor(
        injector: Injector,
        protected elementRef: ElementRef,
        private userManagementService: UserManagementService,
        private directorySharedService: DirectorySharedService,
        protected routeService: RouteService,
        private archUiService: IntegratedArchitectureFrameworkUiService,
        private orgService: OrganisationService,
        private userManagementUiService: UserManagementUiService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        merge([
            rxjsBreezeService.entityTypeChanged(Role),
            rxjsBreezeService.entityTypeChanged(RoleConnection),
            rxjsBreezeService.entityTypeChanged(RoleFeaturePermission),
        ]).pipe(
            debounceTime(1000),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.archUiService.emitRefetchRequired());

        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-manage-access-grid", jQuery(this.elementRef.nativeElement));
        this.dxGridWrapperHelper.saveGridState(String(this.orgService.getOrganisationId()));
    }

    public async ngOnInit() {
        const updateGridDimensions = () => {
            this.gridInstance?.updateDimensions();
        };

        this.managePeopleUrl = await this.routeService.getControllerRoute(managePeoplePageRoute.id);

        await this.promiseToFetchData();

        this.archUiService.gridDimensionsUpdated$
            .pipe(
                this.takeUntilDestroyed(),
            )
            .subscribe(() => updateGridDimensions());

        this.archUiService.refetchRequired$
            .subscribe(async () => {
                await this.promiseToFetchData();
                updateGridDimensions();
            });

        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());

        this.notifyActivated();
    }

    public addAccessLevel() {
        this.userManagementUiService.addAccessLevel()
            .subscribe(() => this.archUiService.emitRefetchRequired());
    }

    public onInitialized(e: InitializedEvent) {
        this.gridInstance = e.component!.instance();

        this.dxGridWrapperHelper.initialiseGrid(e);
    }

    @Autobind
    public exportAllData() {
        this.dxGridWrapperHelper.callGrid((grid) => DxUtilities.exportGridToExcel("Access", grid.component));
    }

    public resetColumns() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    public calculateRoleTypeDisplayValue(row: IRolePeopleGridRow) {
        if (!row.role) {
            return undefined;
        }

        return RoleTypeCodeLabel.label(row.role.roleType?.code);
    }

    @Autobind
    public calcCustomSummary(info: CustomSummaryInfo) {
        if (info.name === "roleTypeCustomSummary") {
            if (info.summaryProcess === "calculate") {
                const row: IRolePeopleGridRow = info.value;
                info.totalValue = RoleTypeCodeLabel.ordinal(row.role.roleType?.code);
            }
        }
    }

    private async promiseToFetchData() {
        this.roles = await lastValueFrom(this.userManagementService.getAccessLevels());
        this.roleConnections = await this.userManagementService.promiseToGetActiveRoleConnections();
        this.directorySharedService.promiseToGetAllPeople();  // priming data only

        this.gridData = [];
        for (const role of this.roles) {
            const gridRow: IRolePeopleGridRow = {
                role,
                people: this.getPeopleWithRole(role),
                managePeopleParams: {
                    [ManagePeopleGridComponent.AccessLevelLabelFilterSearchParam]: role.label,
                },
            };

            this.gridData.push(gridRow);
        }
    }

    private getPeopleWithRole(role: Role): Person[] {
        const peopleWithRole = this.roleConnections
            .filter((roleConnection) => roleConnection.roleId === role.roleId)
            .map((roleConnection) => roleConnection.connection.person);

        // with distinct against resultant person, it will make sure the grid won't break
        // even if the same person holds multiple connections and duplicate role connections
        return ArrayUtilities.distinct(peopleWithRole);
    }

    public calculateRoleCellValue(row: IRolePeopleGridRow) {
        return row.role && row.role.label;
    }
}
