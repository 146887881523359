import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EMPTY, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ProcessMapService } from "../../process-map/process-map.service";

@Component({
    selector: "adapt-display-guidance-material-usage",
    templateUrl: "./display-guidance-material-usage.component.html",
    styleUrls: ["./display-guidance-material-usage.component.scss"],
})
export class DisplayGuidanceMaterialUsageComponent extends BaseComponent implements OnChanges {
    @Input() public guidanceMaterial?: GuidanceMaterial;
    @Output() public isUsed = new EventEmitter<boolean>();

    public processSteps$: Observable<ProcessStep[]> = EMPTY;

    constructor(
        private processMapService: ProcessMapService,
    ) {
        super();
    }

    public ngOnChanges() {
        if (this.guidanceMaterial) {
            this.processSteps$ = this.processMapService.getProcessStepGuidanceMaterialsByGuidanceMaterialId(this.guidanceMaterial.guidanceMaterialId).pipe(
                map((processStepGuidanceMaterials) => processStepGuidanceMaterials
                    .filter((i) => !!i.processStep)
                    .map((i) => i.processStep as ProcessStep)), // can't be null as previous filter will filter it off
                tap((processSteps) => this.isUsed.emit(processSteps.length > 0)),
            );
        }
    }
}
