<dx-list *ngIf="showAsList; else selectTemplate"
         [selectedItems]="selectedLocations"
         (onSelectionChanged)="listLocationChange($event)"
         [items]="allLocations"
         height="auto"
         displayExpr="text"
         placeholder="Select Zone..."
         selectionMode="single">
    <span *dxTemplate="let area of 'item'">
        <adapt-zone-label [zone]="area.zone"
                          [valueStream]="area.valueStream"></adapt-zone-label>
    </span>
</dx-list>
<ng-template #selectTemplate>
    <dx-select-box [value]="selectedLocation"
                   (valueChange)="selectBoxLocationChange($event)"
                   [items]="allLocations"
                   fieldTemplate="field"
                   placeholder="Select location..."
                   [validationError]="validationError"
                   [validationStatus]="validationStatus">
        <span *dxTemplate="let area of 'item'">
            <adapt-zone-label [zone]="area.zone"
                              [valueStream]="area.valueStream"></adapt-zone-label>
        </span>
        <div *dxTemplate="let area of 'field'">
            <div *ngIf="!!area; else noValueTemplate"
                 class="zone-label-field-template">
                <adapt-zone-label [zone]="area.zone"
                                  [valueStream]="area.valueStream"></adapt-zone-label>
                <dx-text-box class="d-none"
                             value="hidden element required by dxTemplate"
                             [readOnly]="true"></dx-text-box>
            </div>
        </div>
        <ng-template #noValueTemplate>
            <dx-text-box value=""
                         [readOnly]="true"></dx-text-box>
        </ng-template>
    </dx-select-box>
</ng-template>
