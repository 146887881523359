import { Component, Inject, OnInit } from "@angular/core";
import { GuidanceMaterial, GuidanceMaterialBreezeModel } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { defer, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureService } from "../integrated-architecture.service";

export interface IEditGuidanceMaterialDialogData {
    guidanceMaterial: GuidanceMaterial;
    returnWithoutSaving: boolean;
}

@Component({
    selector: "adapt-edit-guidance-material-dialog",
    templateUrl: "./edit-guidance-material-dialog.component.html",
})
export class EditGuidanceMaterialDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditGuidanceMaterialDialogData, GuidanceMaterial> implements OnInit {
    public readonly dialogName = "EditGuidanceMaterialDialog";

    public title = "";
    public saveError?: string;
    public isEditorValid = true;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IEditGuidanceMaterialDialogData,
        private integratedArchitectureService: IntegratedArchitectureService,
    ) {
        super();
        this.autoResolveData = dialogData.guidanceMaterial;
    }

    public get entitiesToConfirm() {
        return [this.dialogData.guidanceMaterial];
    }

    public ngOnInit() {
        const addMode = this.dialogData.guidanceMaterial.entityAspect.entityState.isAdded();
        const actionText = addMode
            ? "Create "
            : "Edit ";
        this.title = actionText + GuidanceMaterialBreezeModel.singularName.toLowerCase();
    }

    @Autobind
    public saveAndClose() {
        return defer(() => {
            const name = this.dialogData.guidanceMaterial.name.trim();
            return this.integratedArchitectureService.getGuidanceMaterialByName(name, this.dialogData.guidanceMaterial.guidanceMaterialId);
        }).pipe(
            switchMap((existingGuidanceMaterial) => existingGuidanceMaterial.length
                ? this.commonDialogService.showErrorDialog("Duplicate guidance material", "A guidance material with the same name already exists.", "OK")
                : of({}),
            ),
            switchMap(() => {
                if (this.dialogData?.returnWithoutSaving) {
                    return of(this.dialogData.guidanceMaterial);
                }
                return this.commonDataService.saveEntities(this.dialogData.guidanceMaterial);
            }),
            tap(() => this.resolve(this.dialogData.guidanceMaterial)),
            catchError((e) => this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    public get isInvalid() {
        return this.dialogData.guidanceMaterial.entityAspect.hasValidationErrors || this.hasErrorMessage || !this.isEditorValid;
    }
}
