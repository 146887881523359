import { NgModule } from "@angular/core";
import { HideIfEmptyDirective } from "./hide-if-empty.directive";

@NgModule({
    exports: [
        HideIfEmptyDirective,
    ],
    declarations: [
        HideIfEmptyDirective,
    ],
})
export class AdaptHideIfEmptyModule { }
