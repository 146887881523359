<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element [info]="CulturalCohortTooltip"
                                 [name]="cohortName">
            <dx-list *adaptLoadingUntilFirstEmit="let culturalCohort of cohort$"
                     class="dx-list-boxed mb-3"
                     [dataSource]="culturalCohort"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     [selectedItems]="[selectedCohortMember]"
                     (onSelectionChanged)="onSelectionChanged($event)">
                <div *dxTemplate="let culturalRelationship of 'item'">
                    <ng-container *ngTemplateOutlet="culturalRelationshipPersonTemplate; context: {culturalRelationship: culturalRelationship}"></ng-container>
                </div>
            </dx-list>

            <adapt-collapsible-dashboard-element *ngIf="supportingMembers.length"
                                                 [info]="SupportingMemberTooltip"
                                                 [collapsed]="hasCohortMembers"
                                                 class="supporting"
                                                 name="Supporting members">
                <dx-list (onSelectionChanged)="onSelectionChanged($event)"
                         [dataSource]="supportingMembers"
                         [focusStateEnabled]="false"
                         [selectedItems]="[selectedCohortMember]"
                         class="dx-list-boxed"
                         selectionMode="single">
                    <div *dxTemplate="let culturalRelationship of 'item'">
                        <ng-container *ngTemplateOutlet="culturalRelationshipPersonTemplate; context: {culturalRelationship: culturalRelationship}"></ng-container>
                    </div>
                </dx-list>
            </adapt-collapsible-dashboard-element>
        </adapt-dashboard-element>
    </div>

    <div right-column>
        <adapt-dashboard-element *ngIf="selectedCohortMember"
                                 [name]="selectedCohortMember.relationship.person.fullName">

            <div adapt-dashboard-title-buttons>
                <button adaptButton="add"
                        (click)="recordCVT()">Career Valuation
                </button>
            </div>

            <div class="card-box-shadow">
                <ng-container *ngIf="selectedCohortMember.latestCatchup; else NoCvtRecordedTemplate">
                    <div class="mb-5"><b>Next Career Valuation Due</b>: {{selectedCohortMember.nextCatchup | adaptDate}}</div>
                    <adapt-cvt-history [personId]="selectedCohortMember.relationship.personId"
                                       [showGrid]="false"></adapt-cvt-history>
                </ng-container>
            </div>

            <ng-template #NoCvtRecordedTemplate>
                No Career Valuation has been recorded
            </ng-template>

        </adapt-dashboard-element>
    </div>

    <ng-template #culturalRelationshipPersonTemplate
                 let-culturalRelationship="culturalRelationship">
        <adapt-link-person [personId]="culturalRelationship.relationship.personId"
                           [showImage]="true"
                           [showText]="true"
                           [adaptLinkDisabled]="true"></adapt-link-person>
        <span class="badge float-end mt-2"
              [ngClass]="culturalRelationship.catchupStatus.badgeClass">{{culturalRelationship.catchupStatus.defaultLabel}}</span>
    </ng-template>
</adapt-two-column-page>
