<dx-pie-chart [pathModified]="true"
              type="donut"
              [innerRadius]="0.66"
              [customizePoint]="customizeRatingsGraphPoint"
              [dataSource]="catchupRatings">
    <dxo-size [height]="215"></dxo-size>
    <dxi-series argumentField="label"
                valueField="count"
                name="Rating"></dxi-series>
    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customizeTooltip"
                 [paddingLeftRight]="8"
                 [paddingTopBottom]="8"
                 color="black">
        <dxo-font color="white"
                  [weight]="500"></dxo-font>
        <dxo-border [visible]="false"></dxo-border>
    </dxo-tooltip>
    <dxo-legend horizontalAlignment="center"
                verticalAlignment="bottom"
                itemTextPosition="right"
                [customizeText]="customizeRatingsGraphLegend"
                [paddingLeftRight]="4"></dxo-legend>
</dx-pie-chart>
