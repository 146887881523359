import { Component, Input } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { ReplaySubject } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { PeerCatchupService } from "../peer-catchup.service";

// This is loaded dynamically in linked-nodes-graph and defined in catchup-relationships-graph
@Component({
    selector: "adapt-display-facilitator-comment",
    templateUrl: "./display-facilitator-comment.component.html",
    styleUrls: ["./display-facilitator-comment.component.scss"],
})
export class DisplayFacilitatorCommentComponent extends BaseComponent {
    @Input() public set catchupId(value: number) {
        this.catchupId$.next(value);
    }

    public catchup?: SpeedCatchup;

    private catchupId$ = new ReplaySubject<number>(1);

    public constructor(
        catchupService: PeerCatchupService,
    ) {
        super();

        this.catchupId$.pipe(
            filter((catchupId) => !!catchupId),
            switchMap((catchupId) => catchupService.getCatchupById(catchupId)),
            this.takeUntilDestroyed(),
        ).subscribe((catchup) => this.catchup = catchup);
    }

    public get catchupDate() {
        return moment(this.catchup?.creationDate).format(DateFormats.moment.long);
    }
}
