import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { ManageAccessPageComponent } from "./manage-access-page.component";

export const organisationManageAccessPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-access-page", ManageAccessPageComponent)
    .atOrganisationUrl("/manage/access")
    .withTitle("Manage Access")
    .withSearchKeyword("Configuration")
    .withSearchKeyword("Configure")
    .withHelp(StandardIntercomSearchTerm.ManageRoles)
    .verifyingAccess(ConfigurationAuthService.ManageAccess)
    .build();
