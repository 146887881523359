import { Component, Input, OnInit } from "@angular/core";
import { ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { lastValueFrom, Observable } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { valueStreamDashboardPageRoute } from "../value-stream-dashboard-page/value-stream-dashboard-page.route";

@Component({
    selector: "adapt-link-value-stream",
    templateUrl: "./link-value-stream.component.html",
    styleUrls: ["./link-value-stream.component.scss"],
})
export class LinkValueStreamComponent implements OnInit {
    @Input() public valueStream!: ValueStream;

    public valueStreamPageUrl$?: Observable<string>;
    public leaderPersonId?: number;
    private pageRoute = valueStreamDashboardPageRoute;

    public constructor(
        private commonDataService: CommonDataService,
        private tier1ArchitectureService: Tier1ArchitectureService) {
    }

    public async ngOnInit() {
        this.valueStreamPageUrl$ = this.pageRoute.getRoute(this.valueStream);

        const role = await lastValueFrom(this.tier1ArchitectureService.getLeaderRoleForValueStreamId(this.valueStream.valueStreamId));
        const predicate = new MethodologyPredicate<RoleConnection>("roleId", "==", role!.roleId)
            .and(BreezePredicateUtilities.getIsActivePredicate());

        const key = predicate.getKey(RoleConnectionBreezeModel.identifier);
        const roleConnections = await lastValueFrom(this.commonDataService.getWithOptions(RoleConnectionBreezeModel, key, {
            predicate,
            encompassingKey: ConnectionBreezeModel.identifier,
        }));
        this.leaderPersonId = ArrayUtilities.getSingleFromArray(roleConnections)?.connection.personId;
    }
}
