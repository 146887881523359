import { Injectable, Injector } from "@angular/core";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteService } from "@common/route/route.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AfterOrganisationInitialisationObservable } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import moment from "moment";
import { switchMap } from "rxjs/operators";
import { culturalLeadershipFrameworkPageRoute } from "./clf-page/clf-page.route";
import { CulturalLeadershipCatchupStatus } from "./cultural-leadership-catchup-status.enum";
import { CulturalLeadershipFrameworkService } from "./cultural-leadership-framework.service";
import { EditCulturalCohortDialogComponent, IEditCulturalCohortDialogDialogData } from "./edit-cultural-cohort-dialog/edit-cultural-cohort-dialog.component";

export interface ICulturalLeadershipCatchupStatus {
    id: CulturalLeadershipCatchupStatus;
    defaultLabel: string;
    class: string;
    badgeClass: string;
}

@Autobind
@Injectable({
    providedIn: "root",
})
export class CulturalLeadershipFrameworkUiService extends BaseOrganisationService {
    public static readonly CatchupStatusPaletteName = "culturalCatchUpStatus";

    private _configuration?: CulturalLeadership;

    public catchupStatuses: ICulturalLeadershipCatchupStatus[] = [];

    public constructor(
        injector: Injector,
        private routeService: RouteService,
        private commonDialogService: AdaptCommonDialogService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
    ) {
        super(injector);
        this.initialiseCatchupStatuses();
    }

    private get configuration() {
        if (!this._configuration) {
            throw new Error("Misconfigured cultural leadership framework ui service");
        }

        return this._configuration;
    }

    protected organisationInitialisationActions() {
        return [this.initialiseService()];
    }

    protected async initialiseService() {
        this._configuration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
    }

    @AfterOrganisationInitialisationObservable
    public addCohort() {
        return this.editCohortForCulturalLeader();
    }


    @AfterOrganisationInitialisationObservable
    public editCohortForCulturalLeaderId(culturalLeaderId: number) {
        return this.commonDataService.getById(PersonBreezeModel, culturalLeaderId).pipe(
            switchMap((person) => this.editCohortForCulturalLeader(person)),
        );
    }

    private editCohortForCulturalLeader(culturalLeader?: Person) {
        const data: IEditCulturalCohortDialogDialogData = {
            culturalLeader,
            configuration: this.configuration,
        };

        return this.commonDialogService.open(EditCulturalCohortDialogComponent, data);
    }

    public calculateStatusCellValue(rowData: any) {
        const today = moment().startOf("day");

        if (!rowData.latestCatchup) {
            return "Never caught up";
        }

        const status = this.getCatchupStatus(rowData.catchupStatus);

        if (!status) {
            let diff = today.diff(rowData.nextCatchup, "years");

            if (diff) {
                return getOverdueStatement(diff, "year");
            }

            diff = today.diff(rowData.nextCatchup, "months");
            if (diff) {
                return getOverdueStatement(diff, "month");
            }

            diff = today.diff(rowData.nextCatchup, "days");

            return getOverdueStatement(diff, "day");
        } else {
            return status.defaultLabel;
        }

        function getOverdueStatement(value: number, period: string) {
            return "Overdue by " + value + " " + period + (value > 1 ? "s" : ""); // eslint-disable-line multiline-ternary
        }
    }

    public getStatusCellTemplate(element: any, info: any) {
        const status = this.getCatchupStatus(info.data.catchupStatus);

        if (!status) {
            return;
        }

        element.addClass(status.class);
        element.html(info.text);
    }

    public getStatusHeaderOptions() {
        const result: any = this.catchupStatuses.map(createHeaderItem);

        result.unshift({
            text: "Never caught up",
            value: ["latestCatchup", "=", null],
        });

        return result;

        function createHeaderItem(catchupStatus: ICulturalLeadershipCatchupStatus) {
            return {
                text: catchupStatus.defaultLabel,
                value: [["catchupStatus", "=", catchupStatus.id], "and", ["latestCatchup", "<>", null]],
            };
        }
    }

    public gotoCulturalLeadershipCohortConfigure() {
        return this.routeService.gotoControllerRoute(culturalLeadershipFrameworkPageRoute.id);
    }

    public getCatchupStatus(statusId: CulturalLeadershipCatchupStatus) {
        return ArrayUtilities.getSingleFromArray(this.catchupStatuses.filter(matchId));

        function matchId(status: ICulturalLeadershipCatchupStatus) {
            return status.id === statusId;
        }
    }

    private initialiseCatchupStatuses() {
        this.catchupStatuses = [
            {
                id: CulturalLeadershipCatchupStatus.Overdue,
                defaultLabel: "Overdue",
                class: "catchup-status-overdue",
                badgeClass: "badge-overdue",
            },
            {
                id: CulturalLeadershipCatchupStatus.Due,
                defaultLabel: "Due this month",
                class: "catchup-status-due",
                badgeClass: "badge-due",
            },
            {
                id: CulturalLeadershipCatchupStatus.OnTime,
                defaultLabel: "On schedule",
                class: "catchup-status-on-schedule",
                badgeClass: "badge-on-schedule",
            },
        ];
    }
}
