import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { OrganisationConfigurationPageComponent } from "./organisation-configuration-page.component";

export const organisationConfigurationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-organisation-configuration-page", OrganisationConfigurationPageComponent)
    .atOrganisationUrl("/configuration")
    .withTitle("Organisation Configuration")
    .withSearchKeyword("configure organisation")
    .verifyingAccess(ConfigurationAuthService.ConfigureOrganisationOrBilling)
    .build();
