import { registerPalette } from "devextreme/viz/palette";
import { CulturalLeadershipFrameworkUiService } from "./cultural-leadership-framework-ui.service";

export function initialiseCulturalLeadershipFramework() {
    const palette = {
        // for dxChart, dxPieChart and dxBarGauge
        simpleSet: ["#c02e1d", "#4bb2c6", "#99b456", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],

        // for dxCircularGauge and dxLinearGauge
        indicatingSet: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],

        // for dxVectorMap
        gradientSet: ["#FFFFFF", "#FFFFFF"],
    };

    registerPalette(CulturalLeadershipFrameworkUiService.CatchupStatusPaletteName, palette);
}
