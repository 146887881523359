import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-edit-catchup-rating",
    templateUrl: "./edit-catchup-rating.component.html",
    styleUrls: ["./edit-catchup-rating.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditCatchupRatingComponent extends BaseComponent implements OnChanges {
    @Input() public rating?: SpeedCatchupRating;
    @Input() public isEditing = false;

    @Output() public ratingChange = new EventEmitter<SpeedCatchupRating>();

    public person2IsAuthor = false;
    public ratingCommentPlaceholder = "Add response here...";
    public ratingFeedbackPlaceholder = "Add response here...";
    public isChanging = false;
    private isChangingUpdater = this.createThrottledUpdater((changing: boolean) => this.isChanging = changing);

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.rating && this.rating) {
            this.isChanging = true;
            this.updateData();
        }
    }

    public get ratingType() {
        return this.rating?.extensions.getRatingType();
    }

    private updateData() {
        this.person2IsAuthor = this.rating?.authorPerson === this.rating?.speedCatchup.person2;
        this.ratingCommentPlaceholder = this.generateRatingCommentPlaceholderText();
        this.ratingFeedbackPlaceholder = this.generateRatingFeedbackPlaceholderText();
        this.isChangingUpdater.next(false);
    }

    private generateRatingCommentPlaceholderText(): string {
        let text = "I rate ";

        if (this.ratingType?.authorIsSubject
            || this.ratingType?.ratingIsMutual) {
            text += "my";
        } else {
            text += this.rating?.subjectPerson.firstName + "'s";
        }

        text += " level of "
            + this.ratingType?.name.toLowerCase();

        if (this.ratingType?.ratingIsMutual) {
            text += " with "
                + this.rating!.subjectPerson.firstName;
        }

        text += " as...";

        return text;
    }

    private generateRatingFeedbackPlaceholderText(): string {
        let text = "My feedback on ";

        if (this.rating?.authorPerson) {
            text += this.rating.authorPerson.firstName + "'s";
        } else {
            text += "your";
        }

        text += " assessment of ";

        if (this.ratingType?.authorIsSubject) {
            text += "their ";
        } else {
            text += "my ";
        }

        text += this.ratingType?.name.toLowerCase()
            + " is...";

        return text;
    }

    public validateEntity(): void {
        this.rating?.entityAspect.validateEntity(); // entity validation is not triggered on property change
        this.ratingChange.emit(this.rating);
    }
}
