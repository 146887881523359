import { Component, Injector } from "@angular/core";
import { Route } from "@angular/router";
import { AngularGlobals } from "@common/lib/angular-globals/angular-globals";

@Component({
    selector: "adapt-app",
    template: "<adapt-org-common-shell-wrapper></adapt-org-common-shell-wrapper>",
})
export class AppComponent {
    public static readonly DefaultOrganisationRoute: Route = { path: ":organisationUrlIdentifier", redirectTo: ":organisationUrlIdentifier/dashboard" };

    constructor(injector: Injector) {
        AngularGlobals.injector = injector;
    }
}
