<form>
    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="team">Team</label>
        <adapt-select-team id="team"
                           [team]="filterParameters!.team"
                           (teamChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.Team, $event, $event?.teamId)"
                           [required]="false"
                           [activeOnly]="true"
                           data-test="team-filter"></adapt-select-team>
    </div>

    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="culturalLeader">Cultural Leader</label>
        <adapt-select-person id="culturalLeader"
                             [person]="filterParameters!.culturalLeader"
                             (personChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.CulturalLeader, $event, $event?.personId)"
                             [activeOnly]="false"
                             [filter]="cultureLeaderFilter"
                             [userTypes]="allowedCLUserTypes"
                             data-test="cultural-leader-filter"></adapt-select-person>
    </div>

    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="profileItem">Profile Item</label>
        <adapt-select-profile-item [profileItem]="filterParameters!.profileItem"
                                   (profileItemChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.ProfileItem, $event, $event?.personProfileItemId)"
                                   data-test="profile-item-filter"></adapt-select-profile-item>
        <div class="profile-item-gap"></div>
        <adapt-select-profile-item-value *ngIf="filterParameters!.profileItem"
                                         [profileItem]="filterParameters!.profileItem"
                                         [value]="filterParameters!.profileItemValue"
                                         (valueChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.ProfileItemValue, $event, $event?.personProfileItemValueId)"
                                         data-test="profile-item-value-filter"></adapt-select-profile-item-value>
    </div>

    <fieldset>
        <legend>Differentials</legend>
        <div class="form-group">
            <label for="maxActualValue"
                   class="form-check-label">Actual value less than</label>
            <dx-select-box [items]="actualItems"
                           [value]="filterParameters!.maximumActualPercentage"
                           (onValueChanged)="onMaximumActualPercentageChanged($event)"
                           [showClearButton]="true"
                           displayExpr="string"
                           valueExpr="number"
                           data-test="actual-value-filter"></dx-select-box>
        </div>

        <div class="form-group"
             *ngIf="!!filterParameters!.maximumActualPercentage">
            <label for="minIdealValue"
                   class="form-check-label">and ideal value is greater than</label>
            <dx-select-box [items]="idealItems"
                           [value]="filterParameters!.minimumIdealValue"
                           (onValueChanged)="onMinimumIdealValueChanged($event)"
                           displayExpr="string"
                           valueExpr="number">
            </dx-select-box>
        </div>
    </fieldset>

    <fieldset>
        <legend>Rating</legend>
        <dx-check-box data-test="very-happy-checkbox"
                      class="d-block"
                      name="inputCategory1"
                      text="Very happy"
                      [ngModel]="filterParameters!.category1"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category1, $event, $event)"></dx-check-box>
        <dx-check-box data-test="happy-checkbox"
                      class="d-block"
                      name="inputCategory2"
                      text="Happy"
                      [ngModel]="filterParameters!.category2"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category2, $event, $event)"></dx-check-box>
        <dx-check-box class="d-block"
                      name="inputCategory3"
                      text="Unhappy"
                      [ngModel]="filterParameters!.category3"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category3, $event, $event)"></dx-check-box>
        <dx-check-box class="d-block"
                      name="inputCategory4"
                      text="Very unhappy"
                      [ngModel]="filterParameters!.category4"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category4, $event, $event)"></dx-check-box>
    </fieldset>

    <fieldset *ngIf="hasAtLeastQuantitativePermissions">
        <legend>People</legend>
        <div class="form-group">
            <label for="connectionStatus"
                   class="form-check-label">Connection Status</label>
            <dx-select-box [items]="connectionStatusOptions"
                           [value]="filterParameters!.connectionStatus"
                           (onValueChanged)="onConnectionStatusChanged($event)"
                           displayExpr="label"
                           valueExpr="value"
                           data-test="connection-status-filter"></dx-select-box>
        </div>

        <dx-check-box name="inputNoCL"
                      text="Show people with no Cultural Leader"
                      [ngModel]="filterParameters!.showPeopleWithoutCulturalLeader"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowPeopleWithoutCulturalLeader, $event, $event)"
                      data-test="cultural-leader-checkbox"></dx-check-box>
        <dx-check-box name="inputOverdueOnly"
                      text="Show overdue catch-ups only"
                      [ngModel]="filterParameters!.showOverdueOnly"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowOverdueOnly, $event, $event)"></dx-check-box>
        <dx-check-box name="inputTrendOutwardsOnly"
                      text="Show people trending outwards"
                      [ngModel]="filterParameters!.showTrendOutwardsOnly"
                      (ngModelChange)="careerValuationFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowTrendOutwardsOnly, $event, $event)"></dx-check-box>

    </fieldset>
</form>
