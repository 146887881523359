import { Injectable } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { EditPersonRolesDialogComponent } from "app/features/people/directory/edit-person-roles-dialog/edit-person-roles-dialog.component";
import { switchMap } from "rxjs/operators";
import { ConfigureAccessLevelDialogComponent, IConfigureAccessLevelDialogData } from "./configure-access-level-dialog/configure-access-level-dialog.component";

@Injectable({
    providedIn: "root",
})
export class UserManagementUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private userManagementService: UserManagementService,
    ) {
    }

    public editPersonRoles(connection: Connection) {
        return this.dialogService.open(EditPersonRolesDialogComponent, connection);
    }

    public addAccessLevel() {
        return this.userManagementService.createAccessLevel().pipe(
            switchMap((accessLevel) => this.dialogService.open(ConfigureAccessLevelDialogComponent, {
                role: accessLevel,
            } as IConfigureAccessLevelDialogData)),
        );
    }
}
