<ng-container *adaptLoadingUntilFirstEmit="let position of position$">
    <adapt-dashboard-element *ngIf="position"
                             name="Position">
        <div adapt-dashboard-title-buttons
             *adaptIfAuthorised="ManagePositionAndRoles;Entity:position.connection.person">
            <button adaptButton="editBorderless"
                    *ngIf="editMode"
                    (click)="editPosition(position)"></button>
        </div>

        <span>{{position.name}}</span>
    </adapt-dashboard-element>
</ng-container>
