<ng-container *ngIf="(role$ | async) as role">
    <adapt-link-role *ngIf="showLabel"
                     [role]="role"
                     [showIcon]="true"></adapt-link-role>

    <div *ngIf="(activeNonTeamBasedRoleConnections$ | async) as roleConnections"
         class="mt-1 ms-1 d-flex flex-wrap column-gap-3 row-gap-1">
        <ng-container *ngIf="roleConnections.length > 0; else unallocatedTemplate">
            <adapt-link-person *ngFor="let roleConnection of roleConnections"
                               [person]="roleConnection.connection.person"
                               [showImage]="true"
                               [nullValueText]="unallocatedLabel"
                               imageSize="sm"></adapt-link-person>
        </ng-container>
        <ng-template #unallocatedTemplate>{{unallocatedLabel}}</ng-template>
    </div>
</ng-container>
