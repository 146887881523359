import { Component, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { CareerValuationUiService } from "app/features/people/career-valuation/career-valuation-ui.service";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ICulturalCohortMemberData } from "../cultural-cohort-member-data.interface";
import { CulturalCohortTooltip, CulturalLeadershipFrameworkService, SupportingMemberTooltip } from "../cultural-leadership-framework.service";

@Component({
    selector: "adapt-cultural-cohort-personal-dashboard-element",
    templateUrl: "./cultural-cohort-personal-dashboard-element.component.html",
    styleUrls: ["./cultural-cohort-personal-dashboard-element.component.scss"],
})
export class CulturalCohortPersonalDashboardElementComponent extends BaseComponent implements OnInit {
    public readonly CulturalCohortTooltip = CulturalCohortTooltip;
    public readonly SupportingMemberTooltip = SupportingMemberTooltip;
    
    public cohortName?: string;
    public cohort$: Observable<ICulturalCohortMemberData[]>;
    public supportingMembers: ICulturalCohortMemberData[] = [];
    public refreshRequired$ = new BehaviorSubject<undefined>(undefined);
    public selectedCohortMember: ICulturalCohortMemberData | undefined;
    public hasCohortMembers = true;

    constructor(
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private careerValuationUiService: CareerValuationUiService,
        private userService: UserService,
        private commonDataService: CommonDataService,
    ) {
        super();

        // build the cohort, if previous selection was active attempt to restore it
        this.cohort$ = this.refreshRequired$.pipe(
            map(this.userService.getCurrentPersonId),
            emptyIfUndefinedOrNull(),
            switchMap((personId) => this.culturalLeadershipFrameworkService.getCulturalCohortMemberData(personId)),
            tap((data) => {
                this.supportingMembers = data.filter((p) => p.relationship.isSupporting);
            }),
            map((data) => data.filter((p) => !p.relationship.isSupporting)),
            tap((data) => this.hasCohortMembers = data.length > 0),
            tap((data) => {
                if (this.selectedCohortMember?.relationship) {
                    this.selectedCohortMember = data.find((element) => element.relationship.personId === this.selectedCohortMember?.relationship.personId);
                } else if (data.length) {
                    this.selectedCohortMember = data[0];
                } else {
                    this.selectedCohortMember = this.supportingMembers.length ? this.supportingMembers[0] : undefined;
                }
            }),
        );

        // if someone saves a career valuation, then refresh the cohort
        this.commonDataService.saveCompleted$.pipe(
            map((entities) => entities.filter((entity) => !!entity.careerValuationId)),
            filter((entitiesOfType) => entitiesOfType.length > 0),
            tap(() => this.refreshRequired$.next(undefined)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public async ngOnInit() {
        const clConfig = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
        this.cohortName = clConfig.secondaryCollectionName;
    }

    public async onSelectionChanged(e: IDxListSelectionChangedEvent<ICulturalCohortMemberData>) {
        if (e.addedItems.length) {
            this.selectedCohortMember = ArrayUtilities.getSingleFromArray(e.addedItems);
        }
    }

    @Autobind
    public async recordCVT() {
        const person = this.selectedCohortMember!.relationship.person;
        await this.careerValuationUiService.promiseToRecordCareerValuation(person);
    }
}
