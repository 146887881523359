import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { EMPTY, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
    selector: "adapt-display-product-usage",
    templateUrl: "./display-product-usage.component.html",
    styleUrls: ["./display-product-usage.component.scss"],
})
export class DisplayProductUsageComponent extends BaseComponent implements OnChanges {
    @Input() public product?: Product;
    @Output() public isUsed = new EventEmitter<boolean>();

    public valueStreams$: Observable<ValueStream[]> = EMPTY;

    constructor(
        private productCatalogueService: ProductCatalogueService,
    ) {
        super();
    }

    public ngOnChanges() {
        if (this.product) {
            this.valueStreams$ = this.productCatalogueService.getValueStreamProductsForProduct(this.product).pipe(
                map((vsp) => vsp
                    .filter((i) => !!i.valueStream)
                    .map((i) => i.valueStream)),
                tap((valueStream) => this.isUsed.emit(valueStream.length > 0)),
            );
        }
    }
}
