import { Component, Inject } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-delete-guidance-material-dialog",
    templateUrl: "./delete-guidance-material-dialog.component.html",
})
export class DeleteGuidanceMaterialDialogComponent extends BaseDialogComponent<GuidanceMaterial> {
    public readonly dialogName = "DeleteGuidanceMaterialDialog";

    public isUsed?: boolean;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public guidanceMaterial: GuidanceMaterial,
    ) {
        super();
    }
}
