<div class="display-career-valuation-organisation">
    <div class="header">
        <dx-date-box *ngIf="hasAtLeastQuantitativePermissions; else noPermissionTemplate"
                     (valueChange)="onDateChanged($any($event))"
                     [max]="today"
                     [showClearButton]="true"
                     placeholder="Today"
                     data-test="career-valuation-date">
        </dx-date-box>

        <ng-template #noPermissionTemplate>
            Analysis as at {{today | adaptDate}}
        </ng-template>

        <div class="ms-auto btn-group">
            <button [adaptButtonToggle]="viewType === careerValuationViewType.Statistics"
                    adaptButtonIcon="fal fa-fw fa-chart-bar"
                    (click)="setViewType(careerValuationViewType.Statistics)"
                    class="btn btn-blank"
                    title="Statistics"></button>

            <button [adaptButtonToggle]="viewType === careerValuationViewType.LatestChart"
                    adaptButtonIcon="fal fa-fw fa-chart-line"
                    (click)="setViewType(careerValuationViewType.LatestChart)"
                    class="btn btn-blank"
                    title="Time chart"></button>

            <button [adaptButtonToggle]="viewType === careerValuationViewType.Grid"
                    *ngIf="hasAtLeastQuantitativePermissions"
                    adaptButtonIcon="fal fa-fw fa-table"
                    (click)="setViewType(careerValuationViewType.Grid)"
                    class="btn btn-blank"
                    title="Grid"
                    data-test="career-valuation-grid-button"></button>
        </div>
    </div>
    <div class="body">
        <div [ngSwitch]="viewType">
            <adapt-cvt-organisation-statistics [date]="date"
                                               *ngSwitchCase="0">
            </adapt-cvt-organisation-statistics>

            <adapt-cvt-organisation-latest-chart [date]="date"
                                                 *ngSwitchCase="1">
            </adapt-cvt-organisation-latest-chart>

            <adapt-cvt-organisation-grid [date]="date"
                                         *ngSwitchCase="2">
            </adapt-cvt-organisation-grid>

        </div>
    </div>
</div>
