<div class="display-cultural-index-organisation-orbit">
    <div class="header">
        <div *ngIf="hasAtLeastQuantitativePermissions">
            <!-- TODO: Date change event -->
            <dx-date-box *ngIf="hasAtLeastQuantitativePermissions"
                         (valueChange)="onDateChanged($any($event))"
                         [max]="today"
                         [showClearButton]="true"
                         placeholder="Today"
                         data-test="cultural-index-date"></dx-date-box>
        </div>

        <div class="ms-auto btn-group">
            <button [adaptButtonToggle]="viewType === CulturalIndexViewType.Statistics"
                    adaptButtonIcon="fal fa-fw fa-chart-bar"
                    (click)="setViewType(CulturalIndexViewType.Statistics)"
                    class="btn btn-blank btn-force-text"
                    title="Statistics"></button>
            <button [adaptButtonToggle]="viewType === CulturalIndexViewType.OrbitChart"
                    adaptButtonIcon="fal fa-fw fa-chart-pie"
                    (click)="setViewType(CulturalIndexViewType.OrbitChart)"
                    class="btn btn-blank btn-force-text"
                    title="Orbit chart"></button>
            <button [adaptButtonToggle]="viewType === CulturalIndexViewType.LatestChart"
                    adaptButtonIcon="fal fa-fw fa-chart-line"
                    (click)="setViewType(CulturalIndexViewType.LatestChart)"
                    class="btn btn-blank btn-force-text"
                    title="Time chart"></button>
            <button *ngIf="hasAtLeastQuantitativePermissions"
                    [adaptButtonToggle]="viewType === CulturalIndexViewType.Grid"
                    adaptButtonIcon="fal fa-fw fa-table"
                    (click)="setViewType(CulturalIndexViewType.Grid)"
                    class="btn btn-blank btn-force-text"
                    title="Grid"></button>
        </div>
    </div>
    <div class="body">
        <div [ngSwitch]="viewType">
            <adapt-ci-orbit-chart *ngSwitchCase="0"
                                  [date]="date">
            </adapt-ci-orbit-chart>

            <adapt-ci-latest-chart *ngSwitchCase="1"
                                   [date]="date">
            </adapt-ci-latest-chart>

            <adapt-ci-orbit-grid *ngSwitchCase="2"
                                 [date]="date">
            </adapt-ci-orbit-grid>

            <adapt-ci-orbit-statistics *ngSwitchCase="3"
                                       [date]="date">
            </adapt-ci-orbit-statistics>
        </div>
    </div>
</div>
