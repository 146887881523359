import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";

export enum CulturalIndexViewType {
    OrbitChart,
    LatestChart,
    Grid,
    Statistics,
}

@Component({
    selector: "adapt-ci-orbit",
    templateUrl: "./ci-orbit.component.html",
    styleUrls: ["./ci-orbit.component.scss"],
})
export class CulturalIndexOrbitComponent extends BaseRoutedComponent implements OnInit {
    public date = new Date();

    public CulturalIndexViewType = CulturalIndexViewType;

    public viewType: CulturalIndexViewType = CulturalIndexViewType.Statistics;
    public hasAtLeastQuantitativePermissions: boolean;

    public today = new Date();

    public constructor(
        culturalIndexAuthService: CulturalIndexAuthService,
        injector: Injector,
    ) {
        super(injector);

        this.hasAtLeastQuantitativePermissions = culturalIndexAuthService.currentPersonHasAtLeastQuantitativeAccess();
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public setViewType(viewType: CulturalIndexViewType) {
        this.viewType = viewType;
    }

    @Autobind
    public onDateChanged(date: Date) {
        this.date = date;
    }
}
