import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { InitializedEvent } from "devextreme/ui/text_box";
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-select-system",
    templateUrl: "./select-system.component.html",
})
export class SelectSystemComponent extends BaseComponent {
    @Input() public system?: SystemEntity;
    @Input() public allowCreate = true;
    @Input() public onlyEditable = false;
    @Input() public filter?: (system: SystemEntity) => boolean;

    @Output() public systemChange = new EventEmitter<SystemEntity>();
    @Output() public isCreatingChange = new EventEmitter<boolean>();
    @Output() public newEntityChange = new EventEmitter<SystemEntity | undefined>();

    public allSystems$: Observable<SystemEntity[]>;
    public isCreateAndSelecting = false;
    public newSystem?: SystemEntity;

    private allSystems: SystemEntity[] = [];
    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private systemisationService: SystemisationService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        private authService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
        this.allSystems$ = this.triggerUpdate$.pipe(
            switchMap(() => this.systemisationService.getAllSystems()),
            tap((systems) => this.allSystems = systems),
            switchMap((systems) => this.onlyEditable
                ? this.filterEditableSystems(systems)
                : of(systems)),
            map((systems) => this.filter
                ? systems.filter((i) => this.filter!(i))
                : systems),
            map((systems) => systems.sort((a, b) => a.name < b.name ? -1 : 1)),
        );

        // detect system added/removed from another session - without this, won't pick up newly added systems
        rxjsBreezeService.entityTypeChanged(SystemEntity).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public filterEditableSystems(systems: SystemEntity[]) {
        if (systems.length > 0) {
            const permissionObservables = systems.map((sys) => this.archAuthService.personCanEditTier2(this.authService.currentPerson!, sys));
            return forkJoin(permissionObservables).pipe(
                map((results: boolean[]) => systems.filter((_, idx) => results[idx])),
            );
        }
        return of(systems);
    }

    public refresh() {
        this.triggerUpdate$.next();
    }

    public focus(e: InitializedEvent) {
        setTimeout(() => e.component?.focus());
    }

    @Autobind
    public createNewSystem() {
        return this.systemisationService.createSystem().pipe(
            tap((system) => {
                this.newSystem = system;
                this.isCreateAndSelecting = true;
                this.isCreatingChange.emit(true);
                this.newEntityChange.emit(system);
            }),
        );
    }

    public onNewSystemNameChange(name: string) {
        this.newSystem!.name = name;
        this.newSystem!.extensions.validateSystemName(this.allSystems);
    }

    @Autobind
    public addAndSelectNewSystem() {
        if (this.newSystem) {
            this.system = this.newSystem;
            this.newSystem = undefined;
            this.isCreateAndSelecting = false;
            this.isCreatingChange.emit(false);
            this.newEntityChange.emit(undefined);
            this.systemChange.emit(this.system);
            return of(this.system);
        }

        return EMPTY;
    }

    @Autobind
    public cancelNewSystem() {
        if (this.newSystem) {
            return this.systemisationService.remove(this.newSystem).pipe(
                tap(() => {
                    this.newSystem = undefined;
                    this.isCreateAndSelecting = false;
                    this.isCreatingChange.emit(false);
                    this.newEntityChange.emit(undefined);
                }),
            );
        }

        return EMPTY;
    }
}
