<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [ngClass]="{'no-container': !showContainer}">
    <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon role-icon"
                 adaptTooltip="Role">
                <i class="fa-inverse fal fa-user-tag"></i>
            </div>
        </div>

        <a *ngIf="href$ | async as link"
           [routerLink]="link.path">
            <div class="d-flex align-items-baseline">
                <adapt-highlighted-text [needle]="keyword"
                                        [haystack]="result.role.label"></adapt-highlighted-text>
                <adapt-display-labels *ngIf="showLabels"
                                      class="ms-2"
                                      [linkDisabled]="true"
                                      [labelLocations]="result.role.labelLocations"></adapt-display-labels>
                <ng-container *ngIf="!result.role.isActive()">
                    <span class="badge bg-danger ms-2"
                          adaptTooltip="This role is no longer active in the organisation.">
                        <i class="fal fa-exclamation-circle"></i> Inactive
                    </span>
                </ng-container>
            </div>
        </a>
    </div>
</adapt-collapsible-search-row>
