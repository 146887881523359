import { Injectable } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { ValueStream, ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { BullseyePageRoute } from "@org-common/lib/bullseye/bullseye-page/bullseye-page.component";
import { guidingPhilosophyRoute } from "@org-common/lib/guiding-philosophy/guiding-philosophy-page/guiding-philosophy-page.route";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrganisationAuthService } from "@org-common/lib/organisation/organisation-auth.service";
import { rbGoalsRoute } from "@org-common/lib/rb-goals/rb-goals-page/rb-goals-page.route";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { StrategicAnchorsPageRoute } from "@org-common/lib/strategic-anchors/strategic-anchors-page/strategic-anchors-page.component";
import { StrategyGoalsPageRoute } from "@org-common/lib/strategic-goals/strategic-goals-page/strategic-goals-page.component";
import { StrategyDashboardPageRoute } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.component";
import { analyseEngagementSurveyPageRoute, analyseResilientBusinessAssessmentSurveyPageRoute } from "@org-common/lib/survey/analyse-survey-page/analyse-survey-page.route";
import { manageEmployeeEngagementsPageRoute } from "@org-common/lib/survey/employee-engagement/manage-employee-engagements-page/manage-employee-engagements-page.route";
import { manageRBAssessmentsPageRoute } from "@org-common/lib/survey/resilient-business-assessment/manage-rb-assessments-page/manage-rb-assessments-page.route";
import { valuesConstitutionRoute } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.route";
import { guidanceMaterialsPageRoute } from "app/features/architecture/integrated-architecture/guidance-materials-page/guidance-materials-page.route";
import { rolesPageRoute } from "app/features/architecture/integrated-architecture/roles-page/roles-page.route";
import { valueStreamDashboardPageRoute } from "app/features/architecture/value-streams/value-stream-dashboard-page/value-stream-dashboard-page.route";
import { culturalLeadershipFrameworkPageRoute } from "app/features/culture/cultural-leadership/clf-page/clf-page.route";
import { analyseCareerValuationDifferentialsPageRoute } from "app/features/people/career-valuation/analyse-cvt-differentials-page/analyse-cvt-differentials-page.route";
import { analyseCareerValuationPageRoute } from "app/features/people/career-valuation/analyse-cvt-page/analyse-cvt-page.route";
import { analyseCareerValuationTimelinePageRoute } from "app/features/people/career-valuation/analyse-cvt-timeline-page/analyse-cvt-timeline-page.route";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { culturalIndexOrbitPageRoute } from "app/features/people/cultural-index/ci-orbit-page/ci-orbit-page.route";
import { culturalIndexTimelinePageRoute } from "app/features/people/cultural-index/ci-timeline-page/ci-timeline-page.route";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { employeeDirectoryPageRoute } from "app/features/people/directory/employee-directory-page/employee-directory-page.route";
import { analyseCatchupsPageRoute } from "app/features/people/peer-catchup/analyse-catchups-page/analyse-catchups-page.route";
import { productCataloguePageRoute } from "app/features/product/product-catalogue/product-catalogue-page/product-catalogue-page.route";
import { stakeholderDirectoryPageRoute } from "app/features/stakeholders/stakeholder-directory-page/stakeholder-directory-page.route";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { systemsPageRoute } from "app/features/systemisation/systems-page/systems-page.route";
import { organisationDashboardPageRoute } from "app/organisation/organisation-dashboard-page/organisation-dashboard-page.route";
import { organisationMapPageRoute } from "app/organisation/organisation-map-page/organisation-map-page.route";
import { lastValueFrom, merge } from "rxjs";

type ZoneChild = string | (() => Promise<INavigationNode>);

@Injectable()
export class OrganisationHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Organisation.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-cubes";

    public id = OrganisationHierarchyNavigationService.HierarchyId;
    private childrenByZone: { [zone in Zone]: ZoneChild[] };

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private orgService: OrganisationService,
        private commonDataService: CommonDataService,
        private authorisationFactory: AuthorisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        private systemisationService: SystemisationService,
    ) {
        super(orgService, authNotification);

        this.childrenByZone = this.buildChildrenByZone();
        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(Organisation),
            rxjsBreezeService.entityTypeChangedInSave(ValueStream),
            rxjsBreezeService.entityTypeChangedInSave(ExternalDashboard),
            rxjsBreezeService.entityTypeChanged(SystemLocation),
        );
    }

    protected async buildHierarchy() {
        const self = this;

        const hasTier1Access = await this.authorisationFactory.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
        let orgBuilder = hasTier1Access
            ? this.navUtilitiesFactory.nodeBuilderForControllerAndParams(organisationDashboardPageRoute.id)
            : this.navUtilitiesFactory.nodeBuilder();

        orgBuilder = orgBuilder.setIconClass(OrganisationHierarchyNavigationService.IconClass)
            .keepChildrenInAddedOrder()
            .setHideIconInBreadcrumb(true)
            .promiseToSetTitle(this.promiseToGetOrganisationName());

        const hasExternalDashboardAccess = await this.authorisationFactory.promiseToGetHasAccess(OrganisationAuthService.ViewExternalDashboard);
        if (hasExternalDashboardAccess) {
            const externalDashboards = (await this.orgService.promiseToGetExternalDashboards()).map(
                (externalDashboard) => this.promiseToBuildExternalDashboardNode(externalDashboard, this.navUtilitiesFactory));
            orgBuilder.promiseToAddChildren(externalDashboards);
        }

        orgBuilder.promiseToAddChild(this.promiseToBuildNavigateArchitecture({ isNavigatePage: true }))
            .promiseToAddChildController(employeeDirectoryPageRoute.id)
            .promiseToAddChild(promiseToBuildOrganisationNode());

        await this.promiseToBuildZoneNodes(orgBuilder);

        return orgBuilder.promiseToBuild();

        function promiseToBuildOrganisationNode() {
            return self.navUtilitiesFactory.nodeBuilder()
                .keepChildrenInAddedOrder()
                .setTitle("Organisation")
                .setCustomKeyValue("expandOnLoad", true)
                .setCustomKeyValue("isHiddenInBreadcrumbs", true)
                .setCustomKeyValue("displayAsAccordionInHierarchy", true)
                .promiseToAddChildController(guidingPhilosophyRoute.id)
                .promiseToAddChildController(StrategyDashboardPageRoute.id)
                .promiseToAddChildController(rbGoalsRoute.id)
                .promiseToAddChildController(organisationObjectivesPageRoute.id)
                .promiseToAddChildController(BullseyePageRoute.id)
                .promiseToAddChildController(StrategyGoalsPageRoute.id)
                .promiseToAddChildController(StrategicAnchorsPageRoute.id)
                .promiseToAddChild(self.promiseToBuildResilientBusinessAssessmentNode())
                .promiseToBuild();
        }
    }

    private async promiseToGetOrganisationName() {
        const organisation = await this.orgService.promiseToGetOrganisation();
        return organisation?.name ?? "";
    }

    @Autobind
    private promiseToBuildAnalyseCulturalIndex() {
        return this.navUtilitiesFactory.nodeBuilder()
            .ifAuthorised(CulturalIndexAuthService.OpenCulturalIndexAnalysis)
            .keepChildrenInAddedOrder()
            .setTitle("Cultural Index")
            .setCustomKeyValue("onClickGoToController", culturalIndexOrbitPageRoute.id)
            .promiseToAddChildController(culturalIndexOrbitPageRoute.id)
            .promiseToAddChildController(culturalIndexTimelinePageRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private promiseToBuildAnalyseCareerValuation() {
        return this.navUtilitiesFactory.nodeBuilder()
            .ifAuthorised(CareerValuationAuthService.OpenCareerValuationAnalysis)
            .keepChildrenInAddedOrder()
            .setTitle("Career Valuation")
            .setCustomKeyValue("onClickGoToController", analyseCareerValuationPageRoute.id)
            .promiseToAddChildController(analyseCareerValuationPageRoute.id)
            .promiseToAddChildController(analyseCareerValuationDifferentialsPageRoute.id)
            .promiseToAddChildController(analyseCareerValuationTimelinePageRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private promiseToBuildEngagementNode() {
        return this.navUtilitiesFactory.nodeBuilderForControllerAndParams(manageEmployeeEngagementsPageRoute.id)
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(analyseEngagementSurveyPageRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private promiseToBuildResilientBusinessAssessmentNode() {
        return this.navUtilitiesFactory.nodeBuilderForControllerAndParams(manageRBAssessmentsPageRoute.id)
            .promiseToAddChildController(analyseResilientBusinessAssessmentSurveyPageRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private async promiseToBuildArchitectureComponents() {
        const nodeBuilder = this.navUtilitiesFactory.nodeBuilder()
            .keepChildrenInAddedOrder()
            .setTitle("Architecture Components")
            .setCustomKeyValue("onClickGoToController", productCataloguePageRoute.id)
            .promiseToAddChildController(productCataloguePageRoute.id)
            .promiseToAddChildController(guidanceMaterialsPageRoute.id);

        return nodeBuilder.promiseToBuild();
    }

    @Autobind
    private async promiseToBuildNavigateArchitecture(params = { isNavigatePage: false }) {
        const builder = this.navUtilitiesFactory.nodeBuilderForControllerAndParams(organisationMapPageRoute.id)
            .ifAuthorised(Tier1ArchitectureAuthService.ReadTier1);

        if (params.isNavigatePage) {
            builder.setCustomKeyValue("urlPriority", 200);
        } else {
            builder.setTitle("Key Functions");
        }

        return builder.promiseToBuild();
    }

    private async promiseToBuildZoneNodes(builder: NavigationNodeBuilder) {
        const hasSystemPermissions = await this.authorisationFactory.promiseToGetHasAccess(CommonIntegratedArchitectureFrameworkAuthService.ReadTier2);
        if (hasSystemPermissions && ZoneMetadata.VisibleZones.some((zone) => this.childrenByZone[zone].length > 0 || (zone === Zone.EconomicEngine))) {
            await lastValueFrom(this.systemisationService.primeSystemLocationsForZones());
        }

        for (const zone of ZoneMetadata.VisibleZones) {
            if (this.childrenByZone[zone].length > 0 || (zone === Zone.EconomicEngine)) {
                // cannot have this await within the promiseToBuildZoneNode function (making it async)
                // as too many async-await within the call stack will mess up rejection handling (i.e. rejection not because caught by promiseToAddChild)
                const systemLocations = hasSystemPermissions
                    ? await lastValueFrom(this.systemisationService.getSystemLocationsForZone(zone))
                    : [];
                if (zone === Zone.EconomicEngine) {
                    const canReadValueStreamContents = await this.authorisationFactory.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadValueStreamContents);
                    if (canReadValueStreamContents) {
                        const valueStreams = await lastValueFrom(this.commonDataService.getActive(ValueStreamBreezeModel));
                        builder.promiseToAddChild(this.promiseToBuildZoneNode(zone, valueStreams, systemLocations));
                    }
                } else {
                    builder.promiseToAddChild(this.promiseToBuildZoneNode(zone, undefined, systemLocations));
                }
            }
        }
    }

    public promiseToBuildZoneNode(zone: Zone, valueStreams?: ValueStream[], systemLocations?: SystemLocation[]) {
        const self = this;

        const builder = this.navUtilitiesFactory.nodeBuilder()
            .keepChildrenInAddedOrder()
            .ifAuthorised(Tier1ArchitectureAuthService.ReadTier1)
            .setTitle(ZoneMetadata.Name[zone])
            .setCustomKeyValue("expandOnLoad", true)
            .setCustomKeyValue("isHiddenInBreadcrumbs", true)
            .setCustomKeyValue("displayAsAccordionInHierarchy", true);

        if (systemLocations) {
            for (const systemLocation of systemLocations) {
                builder.promiseToAddChild(this.navUtilitiesFactory
                    .nodeBuilderForControllerAndParams(systemPageRoute.id, { systemEntityId: systemLocation.systemEntityId })
                    .setTitle(systemLocation.system.name)
                    .promiseToBuild());
            }
        }

        if (valueStreams && valueStreams.length) {
            builder.promiseToAddChild(this.promiseToBuildValueStreamsNode(valueStreams));
        }

        const childrenControllerIds = this.childrenByZone[zone];
        for (const controllerId of childrenControllerIds) {
            builder.promiseToAddChild(buildZoneSpecificChildNode(controllerId));
        }

        return builder.promiseToBuild();

        async function buildZoneSpecificChildNode(controllerId: ZoneChild) {
            if (typeof controllerId === "function") {
                return controllerId();
            } else {
                return self.navUtilitiesFactory.nodeBuilderForControllerAndParams(controllerId)
                    .ifAuthorised()
                    .promiseToBuild();
            }
        }
    }

    private promiseToBuildValueStreamsNode(activeValueStreams: ValueStream[]) {
        const self = this;
        const valueStreamsNodeBuilder = this.navUtilitiesFactory.nodeBuilder()
            .keepChildrenInAddedOrder()
            .setTitle("Value Streams")
            .setCustomKeyValue("expandOnLoad", true);

        for (const valueStream of activeValueStreams) {
            valueStreamsNodeBuilder.promiseToAddChild(promiseToBuildValueStreamNode(valueStream));
        }

        return valueStreamsNodeBuilder.promiseToBuild();

        function promiseToBuildValueStreamNode(valueStream: any) {
            const vsParams = {
                valueStreamId: valueStream.valueStreamId,
            };

            const vsNodeBuilder = self.navUtilitiesFactory.nodeBuilderForControllerAndParams(valueStreamDashboardPageRoute.id, vsParams)
                .keepChildrenInAddedOrder()
                .setTitle(valueStream.name)
                .setOrdinal(valueStream.ordinal);

            return vsNodeBuilder.promiseToBuild();
        }
    }

    private buildChildrenByZone() {
        return {
            [Zone.EconomicEngine]: [],
            [Zone.HealthyCulture]: [
                valuesConstitutionRoute.id,
                this.promiseToBuildAnalyseCulturalIndex,
                this.promiseToBuildAnalyseCareerValuation,
                culturalLeadershipFrameworkPageRoute.id,
                analyseCatchupsPageRoute.id,
                this.promiseToBuildEngagementNode,
                stakeholderDirectoryPageRoute.id,
            ],
            [Zone.FinanceAndOwnership]: [],
            [Zone.OrganisationDesign]: [
                this.promiseToBuildNavigateArchitecture,
                systemsPageRoute.id,
                rolesPageRoute.id,
                this.promiseToBuildArchitectureComponents,
            ],
            [Zone.ResearchAndDevelopment]: [],
        };
    }
}
