import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLayoutModule } from "@common/ux/layout/layout.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxListModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptProcessMapSharedModule } from "../process-map/process-map-shared.module";
import { AdaptRoleModule } from "../role/role.module";
import { DisplayCustomerStoriesComponent } from "./display-customer-stories/display-customer-stories.component";
import { DisplayCustomerStoryComponent } from "./display-customer-story/display-customer-story.component";
import { DisplayMarketDescriptionComponent } from "./display-market-description/display-market-description.component";
import { DisplayPointOfDifferenceComponent } from "./display-point-of-difference/display-point-of-difference.component";
import { DisplayProductsServicesComponent } from "./display-products-services/display-products-services.component";
import { DisplayValueStreamSummariesDashboardElementComponent } from "./display-vs-summaries-dashboard-element/display-vs-summaries-dashboard-element.component";
import { DisplayValueStreamSummaryDashboardElementComponent } from "./display-vs-summary-dashboard-element/display-vs-summary-dashboard-element.component";
import { EditValueStreamDialogComponent } from "./edit-value-stream-dialog/edit-value-stream-dialog.component";
import { ManageValueStreamsDialogComponent } from "./manage-value-streams-dialog/manage-value-streams-dialog.component";
import { ValueStreamDashboardPageComponent } from "./value-stream-dashboard-page/value-stream-dashboard-page.component";
import { valueStreamDashboardPageRoute } from "./value-stream-dashboard-page/value-stream-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptShellModule,
        AdaptRoleModule,
        AdaptButtonModule,
        AdaptHtmlEditorModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonDialogModule,
        AdaptEntityValidatorModule,
        DxTextBoxModule,
        DxListModule,
        DxAccordionModule,
        AdaptKeyFunctionsCommonModule,
        AdaptProcessMapSharedModule,
        AdaptFeaturesModule,
        AdaptEmbedShellModule,
        AdaptTooltipModule,
        AdaptCallToActionModule,
        AdaptLayoutModule,
        AdaptOrganisationSharedModule,
        AdaptAuthorisationModule,
        AdaptDashboardElementModule,
        AdaptTeamDashboardSharedModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
    ],
    exports: [
        DisplayValueStreamSummariesDashboardElementComponent,
        EditValueStreamDialogComponent,
        ManageValueStreamsDialogComponent,
        DisplayCustomerStoryComponent,
    ],
    declarations: [
        ValueStreamDashboardPageComponent,
        DisplayValueStreamSummaryDashboardElementComponent,
        DisplayProductsServicesComponent,
        DisplayCustomerStoriesComponent,
        DisplayValueStreamSummariesDashboardElementComponent,
        EditValueStreamDialogComponent,
        ManageValueStreamsDialogComponent,
        DisplayCustomerStoryComponent,
        DisplayPointOfDifferenceComponent,
        DisplayMarketDescriptionComponent,
    ],
})
export class AdaptValueStreamsModule {
    public static readonly Routes = [
        ...valueStreamDashboardPageRoute.routes,
    ];
}
