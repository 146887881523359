import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ValueStream } from "./value-stream";

export class ValueStreamBusinessModel extends BaseEntity<ValueStreamBusinessModel> {
    public valueStreamBusinessModelId!: number;
    public valueStreamId!: number;
    public synopsis?: string;
    public pricing?: string;
    public pointOfDifference?: string;
    public customerValueProposition?: string;
    public organisationValueProposition?: string;
    public marketDescription?: string;
    public boundingBox?: string;

    public valueStream!: ValueStream;
}

export const ValueStreamBusinessModelBreezeModel = new BreezeModelBuilder("ValueStreamBusinessModel", ValueStreamBusinessModel)
    .hasSource()
    .isOrganisationEntity()
    .build();
