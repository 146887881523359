import { Component, Input } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { RoleService } from "../role.service";

@Component({
    selector: "adapt-related-process-maps",
    templateUrl: "./related-process-maps.component.html",
    styleUrls: ["./related-process-maps.component.scss"],
})
export class RelatedProcessMapsComponent extends BaseComponent {
    @Input() public set role(value: Role | undefined) {
        if (value) {
            this.role$.next(value);
        }
    }

    public role$ = new ReplaySubject<Role>(1);
    public relatedSystems$: Observable<SystemEntity[]>;

    private assignedSteps = new Map<ProcessMap, Observable<ProcessStep[]>>();
    private systemProcessMaps = new Map<SystemEntity, ProcessMap[]>();
    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private roleService: RoleService,
        systemisationService: SystemisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
        this.relatedSystems$ = combineLatest([this.role$, this.triggerUpdate$]).pipe(
            switchMap(([role]) => roleService.getRelatedProcessMaps(role)),
            tap(() => this.systemProcessMaps.clear()),
            switchMap((processMaps) => {
                if (processMaps.length === 0) {
                    return of([]);
                }

                return forkJoin(processMaps.map((processMap) => {
                    return systemisationService.getSystemComponentForProcessMap(processMap.processMapId).pipe(
                        tap((systemComponent) => {
                            const maps = this.systemProcessMaps.get(systemComponent.system) ?? [];
                            this.systemProcessMaps.set(systemComponent.system, maps);
                            maps.push(processMap);
                        }),
                    );
                })).pipe(
                    map(() => [...this.systemProcessMaps.keys()]),
                );
            }),
        );

        merge(
            rxjsBreezeService.entityTypeChanged(ProcessMap),
            rxjsBreezeService.entityTypeChanged(ProcessStep),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public assignedProcessStepsInMap(processMap: ProcessMap) {
        if (!this.assignedSteps.has(processMap)) {
            const assignedSteps$ = this.role$.pipe(
                switchMap((role) => this.roleService.getAssignedProcessStepsInMap(role, processMap)),
            );
            this.assignedSteps.set(processMap, assignedSteps$);
        }

        return this.assignedSteps.get(processMap);
    }

    public getProcessMapsForSystem(system: SystemEntity) {
        return this.systemProcessMaps.get(system) ?? [];
    }
}
