import { BoardExporter } from "./board-exporter";
import { ExcelExporter } from "./excel-exporter";
import { ExportType } from "./export-type-selector-step/export-type-selector-step.component";
import { Exporter } from "./exporter";

export class ExporterUtil {
    // tried to chuck these into Exporter but ended up with cyclic dependency as Board/Excel Exporter extends
    // Exporter. So exporter.ts file cannot import Board/Excel Exporter
    private static readonly Exporters: { [type in ExportType]: Exporter; } = {
        [ExportType.Excel]: new ExcelExporter(),
        [ExportType.EmbedBoard]: new BoardExporter(),
    };

    public static getExporterForType(exportType: ExportType) {
        return ExporterUtil.Exporters[exportType];
    }

    public static cleanExporterInstances() {
        Object.values(ExporterUtil.Exporters).forEach((e) => e.clean());
    }
}
