<h3>Export Summary</h3>
<p>
    Export the "{{processMap.name}}" task map to {{exportDestination}}
    <span *ngIf="board">"{{board.name}}"</span>
</p>

<h3>Role Allocations</h3>
<div *ngIf="!rolePersonAllocations.roles || rolePersonAllocations.roles.length < 1; else showRoleAllocations">
    No applicable roles
</div>
<ng-template #showRoleAllocations>
    <div *ngFor="let role of rolePersonAllocations?.roles"
         class="row mb-1">
        <div class="col-12 col-md-4">{{role.label}}:</div>
        <div class="col-12 col-md-8">{{rolePersonAllocations[role.roleId]?.fullName || "Unallocated"}}</div>
    </div>
</ng-template>

<ng-container *ngIf="prefix">
    <h3 class="mt-3">Prefix</h3>
    <p>
        You elected to use "<b>{{prefix}}</b>" as the prefix for the exported items
    </p>
</ng-container>

<br>
<p *ngIf="!started"><b>To continue, press the export button below.</b></p>
<div *ngIf="started && progressTotalCount > 0">
    <dx-progress-bar width="100%"
                     [min]="0"
                     [max]="progressTotalCount"
                     [value]="progressCurrentCount"
                     [showStatus]="true"></dx-progress-bar>
</div>
<p *ngIf="completed">
    The "{{processMap.name}}" task map has been exported to {{exportDestination}}
    <adapt-link-team-kanban *ngIf="!!board"
                            [team]="$any(board!.team)"
                            [board]="board!"
                            [buttonText]="board!.name"
                            data-test="process-map-exported-team-link"></adapt-link-team-kanban>
</p>
