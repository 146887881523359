<div class="row">
    <div class="col-12"
         [ngSwitch]="viewSelection">
        <div *ngSwitchCase="CatchupView.Graph">
            <adapt-catchup-relationships-graph [focusPerson]="focusPerson"
                                               [allowSelectPerson]="allowSelectPerson"
                                               [allowTeamSelection]="allowTeamSelection"
                                               [teamId]="teamId"
                                               [catchupPeople]="teamMembers"
                                               [showExternalStakeholder]="showExternalStakeholder"
                                               [showTeamRelationships]="showTeamRelationships"
                                               [getCatchups$]="getCatchups$"></adapt-catchup-relationships-graph>
        </div>
        <div *ngSwitchCase="CatchupView.Grid">
            <adapt-catchup-grid [filterForPersonId]="focusPerson?.personId"
                                [filterForTeamId]="teamId"></adapt-catchup-grid>
        </div>
    </div>
</div>
