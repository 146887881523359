import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingValue, RatingValues, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { PersonLinkContentsComponent } from "@org-common/lib/directory-shared/link-person-contents/link-person-contents.component";
import moment from "moment";
import { EMPTY, Observable, Subscription } from "rxjs";
import { delay, filter, finalize, map, startWith, switchMap, take } from "rxjs/operators";
import { CatchupRatingType, CatchupRelationshipsFilterComponent, ICatchupRelationshipsFilterOptions, PcuFilterParamKeys } from "../catchup-relationships-filter/catchup-relationships-filter.component";
import { DisplayCatchupRatingComponent } from "../display-catchup-rating/display-catchup-rating.component";
import { DisplayFacilitatorCommentComponent } from "../display-facilitator-comment/display-facilitator-comment.component";
import { DisplayMissingCatchupComponent } from "../display-missing-catchup/display-missing-catchup.component";
import { IGraphLink, IGraphNode, LinkedNodesGraphComponent } from "../linked-nodes-graph/linked-nodes-graph.component";
import { PeerCatchupService } from "../peer-catchup.service";
import { PeerCatchupUiService } from "../peer-catchup-ui.service";

// This component translate catchups into links and nodes whereas LinkedNodesGraph draws the stuffs (no template)
@Component({
    selector: "adapt-catchup-relationships-graph",
    templateUrl: "./catchup-relationships-graph.component.html",
    styleUrls: ["./catchup-relationships-graph.component.scss"],
})
export class CatchupRelationshipsGraphComponent extends BaseComponent implements OnChanges {
    public readonly PcuFilterParamKeys = Object.values(PcuFilterParamKeys);

    @Input() public allowSelectPerson = false; // allow select person for team and org view
    @Input() public allowTeamSelection = false;
    @Input() public showExternalStakeholder = false;
    @Input() public showTeamRelationships = false;
    @Input() public getCatchups$?: (fromDate?: Date, toDate?: Date, activeOnly?: boolean) => Observable<SpeedCatchup[]>;
    @Input() public teamId?: number;
    @Input() public catchupPeople?: Person[]; // to allow passing in team members from catchups-page-contents for team catchup
    @Input() public focusPerson?: Person; // when showing catchup for personal PCU graph

    public isXlWidth = true;
    public isMdWidth = true;

    public filterOptions: Partial<ICatchupRelationshipsFilterOptions> = {};
    public selectedPerson?: Person;
    public graphBusy = true;
    public displayNodes: IGraphNode[] = [];
    public displayLinks: IGraphLink[] = [];
    public showArrowHead = false;
    public useCircleArrowHead = false;
    public hideLinks = false;

    @ViewChild(LinkedNodesGraphComponent) public linkedNodesGraphComponent?: LinkedNodesGraphComponent;
    @ViewChild(CatchupRelationshipsFilterComponent) public filterComponent?: CatchupRelationshipsFilterComponent;
    public isDefault = true;

    private isFilterDefaultUpdater = this.createThrottledUpdater((isDefault: boolean) => this.isDefault = isDefault);
    private graphBusyUpdater = this.createThrottledUpdater((busy: boolean) => this.graphBusy = busy);
    private updateSubscription?: Subscription;

    constructor(
        elementRef: ElementRef,
        responsiveService: ResponsiveService,
        private commonDataService: CommonDataService,
        private catchupService: PeerCatchupService,
        private catchupUiService: PeerCatchupUiService,
        private userService: UserService,
    ) {
        super(elementRef);

        responsiveService.currentBreakpoint$.pipe(
            startWith(undefined), // on startup even without breakpoint change, will set the initial values
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.isXlWidth = responsiveService.currentBreakpoint.is(Breakpoint.XL);
            this.isMdWidth = responsiveService.currentBreakpoint.is(Breakpoint.MD);
        });

        this.catchupService.catchupChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onFilterOptionsChanged());
    }

    public get focusedPerson() {
        return this.selectedPerson || this.focusPerson;
    }

    public get showCombinedLegend() {
        return this.filterOptions.ratingType === CatchupRatingType.CombinedRating;
    }

    public get showMissingLinks() {
        return this.filterOptions.showTeamRelationships &&
            this.catchupPeople?.length &&
            this.filterOptions.showGood &&
            this.filterOptions.showMinor &&
            this.filterOptions.showMajor &&
            this.filterOptions.showOnTrack &&
            this.filterOptions.showOverdue &&
            this.filterOptions.showFacilitated &&
            this.filterOptions.showUnfacilitated &&
            !this.focusedPerson;
    }

    // these are passed into linked nodes group - for the case of combined rating, we want to show all
    // as that's the special case where catchup is filtered by worst rating, not by each individual rating
    public get showRed() {
        return this.filterOptions.showMajor || this.filterOptions.ratingType === CatchupRatingType.CombinedRating;
    }

    public get showOrange() {
        return this.filterOptions.showMinor || this.filterOptions.ratingType === CatchupRatingType.CombinedRating;
    }

    public get showGreen() {
        return this.filterOptions.showGood || this.filterOptions.ratingType === CatchupRatingType.CombinedRating;
    }

    public fitToView() {
        this.linkedNodesGraphComponent?.invokeFitToView();
    }

    public zoom(factor: number) {
        this.linkedNodesGraphComponent?.zoom(factor);
    }

    public ngOnChanges() {
        // if @Input changes after initialised, will need to refresh
        if (this.isInitialised) {
            this.onFilterOptionsChanged();
        }
    }

    public onFilterOptionsChanged(filterOptions?: ICatchupRelationshipsFilterOptions) {
        if (filterOptions) {
            this.filterOptions = filterOptions;
        }

        this.isFilterDefaultUpdater.next(!!this.filterComponent?.isDefault);

        // only start updating after graphBusy is set to true to prevent further ExpressionChangedAfterItHasBeenChecked error for the contents
        // this will be updating
        this.updateSubscription?.unsubscribe(); // cancel previous to remove overlapping updates
        this.updateSubscription = this.graphBusyUpdater.pipe(
            filter((busy) => busy),
            take(1),
            delay(0), // next digest cycle to make sure linked-nodes graph has been removed to avoid ExpressionCHangedAfterItHasBeenCheckedError
            switchMap(() => this.updateData()),
            finalize(() => {
                this.graphBusyUpdater.next(false);
                this.updateSubscription = undefined;
            }),
            this.takeUntilDestroyed(),
        ).subscribe(({ catchups, people }) => {
            this.displayNodes = people.map((person) => this.createNode(person));
            catchups.forEach((catchup) => catchup.ratings
                .filter((rating) => this.filterRating(rating))
                .forEach((rating) => this.addRatingAsLink(rating)));
            if (this.showMissingLinks) {
                this.addMissingLinks(people);
            }

            this.isInitialised = true;
        });
        this.graphBusyUpdater.next(true);
    }

    public onNodeSelected(node: IGraphNode) {
        if (this.allowSelectPerson) {
            this.commonDataService
                .getById(PersonBreezeModel, node.id)
                .subscribe((person) => {
                    this.selectedPerson = person;
                    this.onFilterOptionsChanged();
                });
        }
    }

    public onLinkSelected(link: IGraphLink) {
        if (link.catchupId) {
            this.catchupService.getCatchupById(link.catchupId).pipe(
                switchMap((catchup) => this.catchupUiService.showCatchup(catchup!, false, false, false, link.rating?.extensions.getRatingType())),
                this.takeUntilDestroyed(),
            ).subscribe();
        } else if ((link.source as IGraphNode).id && (link.target as IGraphNode).id) {
            // no catchup -> create new one
            this.userService.currentPerson$.pipe(
                emptyIfUndefinedOrNull(),
                switchMap(() => this.catchupUiService.recordNewCatchup((link.source as IGraphNode).id, (link.target as IGraphNode).id, link.teamId)),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    public resetPersonSelection() {
        this.selectedPerson = undefined;
        this.onFilterOptionsChanged();
    }

    private updateData() {
        this.displayNodes = [];
        this.displayLinks = [];
        this.useCircleArrowHead = this.filterOptions.ratingType === CatchupRatingType.Engagement;
        this.showArrowHead = this.useCircleArrowHead || this.filterOptions.ratingType === CatchupRatingType.Contribution;
        this.hideLinks = this.showArrowHead;

        if (!FunctionUtilities.isFunction(this.getCatchups$)) {
            return EMPTY;
        }

        const toDate = this.filterOptions.displayDate ?? moment().endOf("day").toDate();
        const fromDate = (this.filterOptions.excludeMonths ?? 12) <= 24
            ? moment(toDate)
                .subtract(this.filterOptions.excludeMonths, "M")
                .startOf("day")
                .toDate()
            : undefined;

        const getCatchups$ = this.filterOptions.teamId
            ? (from?: Date, to?: Date, activeOnly?: boolean) => this.catchupService.getCatchupsForTeamByDateRange(this.filterOptions.teamId!, from, to, activeOnly)
            : this.getCatchups$;
        return getCatchups$(fromDate, toDate, !this.filterOptions.showInactive).pipe(
            map((catchups) => catchups
                .filter((catchup) => this.showCatchup(catchup))
                .filter((catchup, _index, collection) => !this.hasNewerPair(catchup, collection))),
            map((catchups) => {
                if (this.catchupPeople) {
                    // people passed in -> show only team members from catchup-view query
                    return ({
                        catchups,
                        people: this.catchupPeople.filter((person) => this.showPerson(person)), // need showPerson filter here as catchupPeople are just team members
                    });
                } else {
                    const person1s = catchups.map((catchup) => catchup.person1!);
                    const person2s = catchups.map((catchup) => catchup.person2!);
                    const people = ArrayUtilities.distinct([...person1s, ...person2s]);
                    return ({
                        catchups,
                        people, // don't need showPerson filter here as catchup is already filtered
                    });
                }
            }),
        );
    }

    private addMissingLinks(people: Person[]) {
        for (let i = 0; i < people.length - 1; i++) {
            for (let j = i + 1; j < people.length; j++) {
                if (!this.peopleLinked(people[i], people[j])) {
                    // add grey link connecting people (for team's All Possible Links option)
                    const link: IGraphLink = {
                        type: CatchupRatingType.CombinedRating,
                        source: people[i].personId,
                        target: people[j].personId,
                        recordPerson1Id: people[i].personId,
                        recordPerson2Id: people[j].personId,
                        opacity: 0.2,
                        pseudo: true,
                        teamId: this.teamId,
                        tooltipComponent: {
                            componentType: DisplayMissingCatchupComponent,
                            linkInputMap: {
                                person1Id: "recordPerson1Id",
                                person2Id: "recordPerson2Id",
                                teamId: "teamId",
                            },
                        },
                    };

                    // these are straight from original code
                    // use default strokeWidth if member count <= 8 according to Tadhg
                    if (people.length > 25) {
                        link.strokeWidth = 1;
                    } else if (people.length > 12) {
                        link.strokeWidth = 1;
                    } else if (people.length > 8) {
                        link.strokeWidth = 2;
                    }

                    // add at the beginning so that others proper links will be drawn on top of this
                    this.displayLinks.unshift(link);
                }
            }
        }
    }

    private createNode(person: Person) {
        const node = {
            id: person.personId,
            fullName: person.fullName,
            personInitials: person.initials,
            imageId: person.imageIdentifier,
            x: 0,
            y: 0,
            selected: false,
            opacity: 1.0,
            tooltipComponent: {
                componentType: PersonLinkContentsComponent,
                nodeInputMap: { personId: "id" },
            },
        } as IGraphNode;

        if (this.focusedPerson) {
            node.selected = person.personId === this.focusedPerson.personId;
        }

        if (!this.personIsActive(person)) {
            node.opacity = 0.3;
            node.color = "#eeeeff";
        }

        return node;
    }

    private addRatingAsLink(rating: SpeedCatchupRating) {
        let link = this.createLinkFromRating(rating);
        if (link.type === CatchupRatingType.Connection) {
            const existingLink = this.displayLinks.find((l) =>
                l.type === link.type &&
                (l.source === link.source && l.target === link.target || l.source === link.target && l.target === link.source));
            if (existingLink) {
                if (RatingValues[link.color!] > RatingValues[existingLink.color!]) {
                    link = Object.assign(existingLink, link);
                }
            } else {
                this.displayLinks.push(link);
            }
        }

        if (this.filterOptions.ratingType === CatchupRatingType.CombinedRating) {
            if (link.type === CatchupRatingType.Connection) {
                link.noEndMarker = true;
                link.strokeWidth = 2;
            } else {
                if (link.type === CatchupRatingType.Engagement) {
                    link.circleEnd = true;
                } else if (link.type === CatchupRatingType.Contribution) {
                    link.arrowEnd = true;
                }

                // showing tooltip on the endpoint instead of the link
                link.endpointTooltipComponent = {
                    componentType: DisplayCatchupRatingComponent,
                    linkInputMap: { rating: "rating" },
                };
                // all other rating type - simply push it the relationshipLinks with no line flag
                link.noLine = true;
                this.displayLinks.push(link);
            }
        } else if (link.type !== CatchupRatingType.Connection) {
            link.endpointTooltipComponent = {
                componentType: DisplayCatchupRatingComponent,
                linkInputMap: { rating: "rating" },
            };
            link.strokeWidth = 1;
            this.displayLinks.push(link);
        }
    }

    private createLinkFromRating(rating: SpeedCatchupRating) {
        const link: IGraphLink = {
            // need to convert as the below CatchupRatingType has CombinedRating as well...
            type: rating.ratingType as string as CatchupRatingType,
            target: rating.subjectPersonId,
            color: rating.rating,
            ratingCreationDate: rating.speedCatchup.creationDate,
            catchupId: rating.speedCatchup.speedCatchupId,
            tooltipComponent: {
                componentType: DisplayCatchupRatingComponent,
                linkInputMap: {
                    rating: "rating",
                    teamId: "teamId",
                },
            },
            source: rating.subjectPersonId === rating.speedCatchup.person1Id
                ? rating.speedCatchup.person2Id
                : rating.speedCatchup.person1Id,
            opacity: this.hasExpired(rating.speedCatchup) ? 0.3 : 1.0,
            rating,
            teamId: this.teamId,
        };

        if (this.filterOptions.showFacilitator && rating.speedCatchup.facilitatorPerson) {
            link.personId = rating.speedCatchup.facilitatorPersonId;
            link.personImageId = rating.speedCatchup.facilitatorPerson.imageIdentifier;
            link.personInitials = rating.speedCatchup.facilitatorPerson.initials;
            link.personTooltipComponent = {
                componentType: DisplayFacilitatorCommentComponent,
                linkInputMap: { catchupId: "catchupId" },
            };
        }

        return link;
    }

    private peopleLinked(person1: Person, person2: Person) {
        return this.displayLinks.find((link) =>
            (link.source === person1.personId && link.target === person2.personId) ||
            (link.source === person2.personId && link.target === person1.personId));
    }

    private filterRating(rating: SpeedCatchupRating) {
        // combined rating is filtered at the catchup level (by worst rating) - so all ratings for combined rating will pass
        return this.filterOptions.ratingType === CatchupRatingType.CombinedRating ||
            (rating.ratingType as string) === this.filterOptions.ratingType;
    }

    private hasExpired(catchup: SpeedCatchup) {
        const toDate = moment().endOf("day").toDate();
        const expiryDate = moment(toDate)
            .subtract(this.filterOptions.expiryMonths, "M")
            .startOf("day")
            .toDate();
        return moment(catchup.creationDate).isBefore(expiryDate);
    }

    private showPerson(person: Person) {
        return (this.filterOptions.showInactive || this.personIsActive(person)) &&
            (this.filterOptions.showExternalStakeholders ||
                person.getLatestEmployeeConnection()?.isActiveAt(this.filterOptions.displayDate!) ||
                // previously has employee connection and showing inactive
                (this.filterOptions.showInactive && !!person.getLatestEmployeeConnection()));
    }

    private personIsActive(person: Person) {
        if (this.teamId) {
            // team catchup -> get the latest team role connection to check for active
            const teamRoleConnections: RoleConnection[] = [];
            person.connections.forEach((connection) => connection.roleConnections.forEach((roleConnection) => {
                // because of private teams, certain role won't be visible anymore. In that case, won't be this team as you have permission to this team
                if (roleConnection.role?.teamId === this.teamId) {
                    teamRoleConnections.push(roleConnection);
                }
            }));
            const latestTeamConnection = BreezeModelUtilities.getLatestOrActive(teamRoleConnections);
            return latestTeamConnection?.isActiveAt(this.filterOptions.displayDate!);
        } else {
            // latest connection to check for active
            return !!person.getLatestConnection()?.isActiveAt(this.filterOptions.displayDate!);
        }
    }

    private showCatchup(catchup: SpeedCatchup) {
        const isOverdue = this.hasExpired(catchup);
        return !catchup.entityAspect.entityState.isAdded() && // not showing uncommitted catchups
            // only show overdue if filter option is ticked
            ((this.filterOptions.showOverdue && isOverdue) || (this.filterOptions.showOnTrack && !isOverdue)) &&
            // only show catchup with inactive people if filter option defined
            this.showPerson(catchup.person1!) && this.showPerson(catchup.person2!) &&
            // only shows catchups related to the selected/focused person
            (!this.focusedPerson || catchup.person1Id === this.focusedPerson.personId || catchup.person2Id === this.focusedPerson.personId) &&
            // facilitated filter
            ((catchup.facilitatorPersonId && this.filterOptions.showFacilitated) || (!catchup.facilitatorPersonId && this.filterOptions.showUnfacilitated)) &&
            // only show team catchup filter
            (!this.filterOptions.onlyShowTeamCatchups || !!catchup.teamId) &&
            // for combined rating, worst rating must satisfy the filter
            (this.filterOptions.ratingType !== CatchupRatingType.CombinedRating || this.showCatchupInCombinedRating(catchup));
    }

    private showCatchupInCombinedRating(catchup: SpeedCatchup) {
        if (!this.filterOptions.showGood && !this.filterOptions.showMinor && !this.filterOptions.showMajor) {
            // all unticked - not doing filtering (what's the point of not showing any of the red/orange/green if looking at the graph??)
            return true;
        }

        const worstRating = catchup.extensions.worstRatingValue;
        switch (worstRating) {
            case CatchupRatingValue.Green:
                return this.filterOptions.showGood;
            case CatchupRatingValue.Orange:
                return this.filterOptions.showMinor;
            case CatchupRatingValue.Red:
                return this.filterOptions.showMajor;
            default: break;
        }

        return false;
    }

    // especially with get catchups by date range, there can be multiple instances of the same
    // pair - we are only interested in the latest pair here.
    private hasNewerPair(catchup: SpeedCatchup, allCatchups: SpeedCatchup[]) {
        const otherSamePairs = allCatchups.filter((otherCatchup) =>
            (otherCatchup.speedCatchupId !== catchup.speedCatchupId) &&
            (otherCatchup.person1Id === catchup.person1Id && otherCatchup.person2Id === catchup.person2Id) ||
            (otherCatchup.person1Id === catchup.person2Id && otherCatchup.person2Id === catchup.person1Id));
        const creationDate = moment(catchup.creationDate);
        return otherSamePairs.some((otherCatchup) => creationDate.isBefore(otherCatchup.creationDate));
    }
}
