<ng-container *adaptLoading="!keyFunctionRoles">
    <dx-list *ngIf="keyFunctionRoles!.length > 0; else noRolesTemplate"
             class="dx-list-boxed h-auto"
             [dataSource]="keyFunctionRoles"
             [hoverStateEnabled]="false"
             [focusStateEnabled]="false"
             [activeStateEnabled]="false"
             [allowItemDeleting]="true"
             (onItemReordered)="updateOrdinals(keyFunctionRoles!, $event)"
             (onItemDeleted)="removeKeyFunctionRole($event)">
        <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>

        <div *dxTemplate="let keyFunctionRole of 'item'"
             class="key-function-role">
            <adapt-link-role [role]="keyFunctionRole.role"
                             [showIcon]="true"></adapt-link-role>
        </div>
    </dx-list>

    <div *ngIf="!isCreatingAndAdding; else createAndAdd"
         class="mt-2">
        <adapt-select-role *adaptLoading="!!addedRole"
                           class="d-block mt-2"
                           [role]="addedRole"
                           (roleChange)="addRole($event)"
                           [filter]="roleFilter"
                           [required]="false"
                           data-test="select-key-function-role"></adapt-select-role>
        <button class="btn btn-link mt-3"
                adaptButtonIcon="add"
                [adaptBlockingClick]="createNewRole">
            Add a new role
        </button>
    </div>
    <ng-template #createAndAdd>
        <!-- mb-4 so invalid message from new role doesn't get cut off -->
        <div *ngIf="newRole"
             class="mt-2 mb-4 d-flex">
            <dx-text-box class="flex-grow-1 me-2"
                         [(value)]="newRole!.label"
                         placeholder="Type a new role label"
                         [adaptValidateEntity]="newRole"
                         adaptValidateEntityProperty="label"
                         (onInitialized)="onInitialized($event)"
                         data-test="role-name-input"></dx-text-box>
            <button class="me-2"
                    adaptButton="add"
                    [disabled]="newRole!.entityAspect.hasValidationErrors"
                    [adaptBlockingClick]="addNewRole"
                    data-test="add-role-button"></button>
            <button adaptButton="cancel"
                    [adaptBlockingClick]="cancelNewRole"></button>
        </div>
    </ng-template>

    <ng-template #noRolesTemplate>
        No key roles have been specified for this key function.
    </ng-template>
</ng-container>
