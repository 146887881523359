<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            *ngIf="allowEditing"
            [adaptBlockingClick]="createGuidanceMaterial"
            data-test="create-guidance-material-button">Create guidance material
    </button>
    <button adaptButton="merge"
            [disabled]="mergeGuidanceMaterialsDisabled"
            [adaptBlockingClick]="mergeGuidanceMaterials"
            data-test="merge-guidance-material-button"></button>
</div>
<adapt-two-column-layout leftColumnWidthClasses="col-12 col-md-8 col-lg-8 col-xl-9"
                         rightColumnWidthClasses="col-12 col-md-4 col-lg-4 col-xl-3"
                         [fullPage]="true">
    <adapt-dashboard-element left-column
                             name="Guidance materials">
        <dx-list [dataSource]="guidanceMaterials"
                 [selectedItems]="selectedItems"
                 (selectedItemsChange)="onSelectedItemsChange($event)"
                 [showSelectionControls]="true"
                 [activeStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [allowItemDeleting]="true"
                 (onSelectionChanged)="onSelectionChanged()"
                 (onItemRendered)="onItemRendered($event)"
                 (onItemDeleting)="onItemDeleting($event)"
                 (onItemDeleted)="onItemDeleted($event)"
                 [searchEnabled]="true"
                 searchExpr="guidanceMaterial.name"
                 selectionMode="multiple">
            <dxo-item-dragging [allowReordering]="false"></dxo-item-dragging>
            <div class="architecture-element-list-item"
                 *dxTemplate="let item of 'item'">
                <div>
                    <div class="architecture-element-list-item-heading text-wrap">
                        <strong>{{item.guidanceMaterial.name}}</strong>
                        <button *ngIf="item.editable"
                                [adaptBlockingClick]="editGuidanceMaterial"
                                [adaptBlockingClickParam]="item.guidanceMaterial"
                                [stopPropagation]="true"
                                adaptButtonIcon="edit"
                                data-test="edit-guidance"
                                class="btn btn-link ms-1"></button>
                    </div>
                </div>
                <div *ngIf="item.description"
                     class="architecture-element-list-item-text text-wrap">
                    <span [froalaView]="item.guidanceMaterial.description"></span>
                </div>
                <adapt-select-document *ngIf="item.guidanceMaterial.locationUrl"
                                       [url]="item.guidanceMaterial.locationUrl"
                                       [readOnly]="true"></adapt-select-document>
                <button *ngIf="item.editable"
                        [adaptBlockingClick]="viewUsage"
                        [adaptBlockingClickParam]="item.guidanceMaterial"
                        [stopPropagation]="true"
                        adaptButtonIcon="fal fa-fw fa-link fa-flip-horizontal"
                        class="btn btn-link">View usage...
                </button>
            </div>
        </dx-list>
    </adapt-dashboard-element>
    <adapt-dashboard-element right-column>
        <div adapt-dashboard-title>
            <h2><i class="fal fa-fw fa-info-circle"></i>Information</h2>
        </div>
        Guidance materials are documents, videos, wiki pages, etc that are stored externally to embedADAPT
        but may be useful to guide people on how to perform tasks in the organisation.
    </adapt-dashboard-element>
</adapt-two-column-layout>
