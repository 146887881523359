<adapt-dashboard-element name="Employee engagement"
                         [url]="surveyPageUrl$ | async">
    <div *adaptLoadingUntilFirstEmit="let surveys of surveys$">
        <div *ngIf="surveys.length ;else noSurveysCompletedTemplate">
            <adapt-display-survey-result [survey]="surveys[0]"></adapt-display-survey-result>
            <adapt-display-survey-category-chart [survey]="surveys[0]"
                                                 [displayCompact]="true"></adapt-display-survey-category-chart>
        </div>
        <ng-template #noSurveysCompletedTemplate>
            <adapt-call-to-action-inline [featureUrl]="surveyPageUrl$ | async">
                Engaged workers are far more productive. The adapt platform provides a way of taking
                regular pulse checks, via online surveys, so the leaders can measure how engagement
                in their business is tracking.
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</adapt-dashboard-element>
