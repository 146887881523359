import { Component, Input } from "@angular/core";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { OrganisationService } from "../../organisation/organisation.service";
import { organisationExternalDashboardPageRoute, teamExternalDashboardPageRoute } from "../external-dashboard-page/external-dashboard-page.route";

interface IDashboardWithUrl {
    dashboard: ExternalDashboard;
    url: IAdaptLinkObject;
}

@Component({
    selector: "adapt-external-dashboard-dashboard-element",
    templateUrl: "./external-dashboard-dashboard-element.component.html",
})
export class ExternalDashboardDashboardElementComponent extends BaseComponent {
    @Input()
    public set team(value: Team) {
        this.team$.next(value);
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public externalDashboards: IDashboardWithUrl[] = [];

    public constructor(
        authorisationService: AuthorisationService,
        orgService: OrganisationService,
    ) {
        super();

        this.team$.pipe(
            switchMap(async (team) => {
                const hasPermission = await authorisationService.promiseToGetHasAccess("viewExternalDashboard", team);
                const dashboards = hasPermission
                    ? await orgService.promiseToGetExternalDashboards(team?.teamId)
                    : [];

                const urls: IDashboardWithUrl[] = [];
                for (const dashboard of dashboards) {
                    const url = await this.getUrlForDashboard(dashboard);
                    urls.push(url);
                }
                return urls;
            }),
            this.takeUntilDestroyed(),
        ).subscribe((externalDashboards) => this.externalDashboards = externalDashboards);
    }

    private async getUrlForDashboard(dashboard: ExternalDashboard) {
        const params = { dashboard: dashboard.externalDashboardId };
        const route = dashboard.team
            ? teamExternalDashboardPageRoute.getRouteObject(dashboard.team, params)
            : organisationExternalDashboardPageRoute.getRouteObject(undefined, params);

        return { dashboard, url: await lastValueFrom(route) } as IDashboardWithUrl;
    }
}
