import { CulturalLeadershipConnectionStatusFilter } from "../../culture/cultural-leadership/cultural-leadership-connection-status-filter.enum";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "../../culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";

export class CulturalIndexAnalysisFilter {
    public static getDefaultFilterParameters() {
        return {
            category1: true,
            category2: true,
            category3: true,
            category4: true,
            showPeopleWithoutCulturalLeader: true,
            connectionStatus: CulturalLeadershipConnectionStatusFilter.ActiveOnly,
            showOverdueOnly: false,
            showTrendOutwardsOnly: false,
        } as ICulturalLeadershipMeasurementAnalysisFilter;
    }
}
