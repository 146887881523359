import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { provideMyProfileRoute, providePersonProfileRoute } from "@org-common/lib/directory-shared/person-profile-route-provider";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptPersonProfileModule } from "@org-common/lib/person-profile/person-profile.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptUserManagementSharedModule } from "@org-common/lib/user-management/user-management-shared.module";
import { AdaptRoleSharedModule } from "app/features/architecture/role/role-shared.module";
import { AdaptSystemisationSharedModule } from "app/features/systemisation/systemisation-shared.module";
import { AdaptActivityFeedModule } from "app/general/activity-feed/activity-feed.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxCheckBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { PersonalActivityFeedComponent } from "./personal-activity-feed/personal-activity-feed.component";
import { PersonProfileAccessComponent } from "./profile/person-profile-access/person-profile-access.component";
import { PersonProfileConnectionComponent } from "./profile/person-profile-connection/person-profile-connection.component";
import { PersonProfileCulturalLeaderComponent } from "./profile/person-profile-cultural-leader/person-profile-cultural-leader.component";
import { PersonProfilePageComponent } from "./profile/person-profile-page/person-profile-page.component";
import { myPersonProfilePageRoute, personProfilePageRoute } from "./profile/person-profile-page/person-profile-page.route";
import { PersonProfilePositionComponent } from "./profile/person-profile-position/person-profile-position.component";
import { PersonProfileRolesComponent } from "./profile/person-profile-roles/person-profile-roles.component";
import { PersonProfileSystemsComponent } from "./profile/person-profile-systems/person-profile-systems.component";
import { PersonProfileTeamsComponent } from "./profile/person-profile-teams/person-profile-teams.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptActivityFeedModule,
        AdaptTwoColumnModule,
        AdaptLoadingSpinnerModule,
        AdaptEmbedShellModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptAuthorisationModule,
        AdaptHtmlEditorModule,
        AdaptRoleSharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptShellModule,
        AdaptStorageModule,
        AdaptCommonUserModule,
        AdaptCommonDialogModule,
        AdaptTooltipModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        AdaptOrganisationSharedModule,
        AdaptUserManagementSharedModule,
        AdaptSystemisationSharedModule,
        AdaptDashboardElementModule,
        AdaptPersonProfileModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptLinkDisabledModule,
        AdaptDirectorySharedModule,
    ],
    exports: [
        PersonProfilePageComponent,
        PersonalActivityFeedComponent,
    ],
    declarations: [
        PersonalActivityFeedComponent,
        PersonProfilePageComponent,
        PersonProfilePositionComponent,
        PersonProfileRolesComponent,
        PersonProfileTeamsComponent,
        PersonProfileCulturalLeaderComponent,
        PersonProfileConnectionComponent,
        PersonProfileSystemsComponent,
        PersonProfileAccessComponent,
    ],
    providers: [
        provideMyProfileRoute(myPersonProfilePageRoute),
        providePersonProfileRoute(personProfilePageRoute),
    ],
})
export class AdaptPersonModule {
    public static readonly Routes = [
        ...personProfilePageRoute.routes,
        ...myPersonProfilePageRoute.routes,
    ];
}
