import { registerPalette } from "devextreme/viz/palette";
import { CareerValuationUiService } from "./career-valuation-ui.service";

export function initialiseCareerValuation() {
    const palette = {
        // for dxChart, dxPieChart and dxBarGauge
        simpleSet: ["#a2b86c", "#51afa7", "#1395ba", "#0f5b78", "#c02e1d", "#f16c20", "#ebc844", "#808080"],

        // for dxCircularGauge and dxLinearGauge
        indicatingSet: ["#ffffff", "#ffffff", "#ffffff"],

        // for dxVectorMap
        gradientSet: ["#ffffff", "#ffffff"],
    };

    registerPalette(CareerValuationUiService.CareerValuationPaletteName, palette);
}
