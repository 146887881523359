import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { LinkValueStreamComponent } from "./link-value-stream/link-value-stream.component";

@NgModule({
    declarations: [
        LinkValueStreamComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AdaptDirectorySharedModule,
    ],
    exports: [
        LinkValueStreamComponent,
    ],
})
export class AdaptValueStreamsSharedModule { }
