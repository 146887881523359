import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectoryModule } from "../people/directory/directory.module";
import { StakeholderAuthService } from "./stakeholder-auth.service";
import { StakeholderDirectoryPageComponent } from "./stakeholder-directory-page/stakeholder-directory-page.component";
import { stakeholderDirectoryPageRoute } from "./stakeholder-directory-page/stakeholder-directory-page.route";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptDirectoryModule,
        AdaptAuthorisationModule,
    ],
    declarations: [
        StakeholderDirectoryPageComponent,
    ],
})
export class AdaptStakeholderModule {
    public static readonly Routes = [
        ...stakeholderDirectoryPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        StakeholderAuthService.registerAccess(authorisationService);
    }
}
