import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxChartModule, DxDataGridModule, DxPolarChartModule } from "devextreme-angular";
import { CareerValuationHistoryComponent } from "./cvt-history/cvt-history.component";
import { CareerValuationPersonalDashboardElementComponent } from "./cvt-personal-dashboard-element/cvt-personal-dashboard-element.component";
import { CareerValuationPersonalDifferentialsChartComponent } from "./cvt-personal-differentials-chart/cvt-personal-differentials-chart.component";

@NgModule({
    imports: [
        CommonModule,
        DxPolarChartModule,
        DxChartModule,
        DxDataGridModule,
        AdaptLoadingSpinnerModule,
        AdaptEmbedShellModule,
        AdaptDateModule,
        AdaptHtmlEditorModule,
        AdaptDashboardElementModule,
    ],
    declarations: [
        CareerValuationHistoryComponent,
        CareerValuationPersonalDashboardElementComponent,
        CareerValuationPersonalDifferentialsChartComponent,
    ],
    exports: [
        CareerValuationHistoryComponent,
        CareerValuationPersonalDashboardElementComponent,
        CareerValuationPersonalDifferentialsChartComponent,
    ],
})
export class AdaptCareerValuationSharedModule { }
