import { CatchupRatingType, SpeedCatchupRating } from "./speed-catchup-rating";

export class SpeedCatchupRatingExtensions {
    public constructor(private speedCatchupRating: SpeedCatchupRating) {}

    public getRatingType() {
        return CatchupRatingType.ByStatus[this.speedCatchupRating.ratingType];
    }

    public getDisplayLabel(isPersonAuthor: boolean) {
        const ratingType = this.getRatingType();

        if (isPersonAuthor) {
            if (ratingType.ratingIsMutual) {
                return "How I rated our " + ratingType.name;
            } else if (ratingType.authorIsSubject) {
                return "How I rated my " + ratingType.name;
            } else {
                return "How I rated their " + ratingType.name;
            }
        } else if (ratingType.ratingIsMutual) {
            return "How they rated our " + ratingType.name;
        } else if (ratingType.authorIsSubject) {
            return "How they rated their " + ratingType.name;
        } else {
            return "How they rated my " + ratingType.name;
        }
    }
}
