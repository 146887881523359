import { Component, Inject, ViewChild } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IDxPieChartCustomizeText } from "@common/ux/dx.types";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import dxPieChart from "devextreme/viz/pie_chart";
import { tap } from "rxjs";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";

interface IIdealsChartData {
    argument: string;
    value: number;
}

@Component({
    selector: "adapt-view-cvt-dialog",
    templateUrl: "./view-cvt-dialog.component.html",
    styleUrls: ["./view-cvt-dialog.component.scss", "../career-valuation.scss"],
})
export class ViewCareerValuationDialogComponent extends BaseDialogComponent<CareerValuation> {
    public readonly dialogName = "DisplayCareerValuationDialog";
    public title: string;

    public idealValuesChartInstance?: dxPieChart;

    public idealsChartData: IIdealsChartData[];

    public actualTotal: number;

    public idealsChartPalette = CareerValuationUiService.CareerValuationPaletteName;

    @ViewChild("idealsChart") public idealsChart?: dxPieChart;

    public hasEditPermission = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public careerValuation: CareerValuation,
        public careerValuationUiService: CareerValuationUiService,
        private authorisationService: AuthorisationService,
    ) {
        super();

        this.actualTotal = this.careerValuation.extensions.actualTotal;

        this.title = "View Career Valuation for " + this.careerValuation.person.fullName;

        this.idealsChartData = this.careerValuation.categoryValues
            .sort(SortUtilities.getSortByFieldFunction(CareerValuationCategoryValueBreezeModel.sortField!))
            .map(this.extractIdealValue);

        this.setEditPermission();
    }

    public customiseLabelText(info: IDxPieChartCustomizeText) {
        return info.argument + "<br>(" + info.value + " %)";
    }

    public getCategoryHeadingClass(categoryValue: CareerValuationCategoryValue) {
        return "category-heading-" + categoryValue.category.ordinal;
    }

    @Autobind
    public editCareerValuation() {
        return this.careerValuationUiService.editCareerValuation(this.careerValuation).pipe(
            tap(() => this.onCareerValuationChanged()),
        );
    }

    @Autobind
    public async deleteCareerValuation() {
        await this.careerValuationUiService.promiseToDeleteCareerValuation(this.careerValuation, true, true);
        return this.resolve(this.careerValuation);
    }

    public closeDialog() {
        this.resolve(this.careerValuation);
    }

    private async setEditPermission() {
        this.hasEditPermission = await this.authorisationService.promiseToGetHasAccess(CareerValuationAuthService.EditCareerValuationForPerson, this.careerValuation.person);
    }

    private onCareerValuationChanged() {
        if (this.idealValuesChartInstance) {
            const extractedValues = this.careerValuation.categoryValues.map(this.extractIdealValue);

            this.idealValuesChartInstance.option("dataSource", extractedValues);
        }
    }

    private extractIdealValue(value: CareerValuationCategoryValue) {
        return {
            argument: value.category.name,
            value: value.idealValue,
        };
    }

}
