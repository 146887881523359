import { Injectable, Injector } from "@angular/core";
import { CareerValuation, CareerValuationBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalRelationship, CulturalRelationshipBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { PersonProfileItemValue, PersonProfileItemValueBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { CulturalLeadershipAccessLevels } from "@org-common/lib/cultural-leadership/cultural-leadership-access-levels.enum";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipAnalysisDataBaseService } from "../../culture/cultural-leadership/cultural-leadership-analysis-data-base.service";
import { CareerValuationAnalysisFilter, ICareerValuationAnalysisFilter } from "./career-valuation-analysis-filter";
import { CareerValuationDataService } from "./career-valuation-data.service";

@Injectable({
    providedIn: "root",
})
@Autobind
export class CareerValuationAnalysisDataService extends CulturalLeadershipAnalysisDataBaseService {
    public constructor(
        injector: Injector,
        private careerValuationDataService: CareerValuationDataService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
    ) {
        super(injector);
    }

    public async promiseToGetCareerValuationAnalysisData(
        initialFilter?: ICareerValuationAnalysisFilter,
        prefetchAllData: boolean = false,
    ) {
        const queryOptions = {
            namedParams: {
                latestOnly: true,
            },
        };

        const filter = initialFilter ? initialFilter : CareerValuationAnalysisFilter.getDefaultFilterParameters();
        const date = filter.date ? filter.date : new Date(); // data to filter/limit all records is now or the passed in date

        try {
            const accessLevel = await this.careerValuationDataService.promiseToCheckCareerValuationAccessLevel();
            const hasQuantitativeAccess = accessLevel === CulturalLeadershipAccessLevels.QuantitativeAccess || accessLevel === CulturalLeadershipAccessLevels.FullAccess;
            const [connections, culturalRelationships] = await this.getPrimeData(queryOptions, filter, accessLevel, prefetchAllData);
            const careerValuations = await this.getCareerValuationData(queryOptions, filter, hasQuantitativeAccess);
            await this.getCareerValuationValuesData(queryOptions, filter, hasQuantitativeAccess, prefetchAllData);
            careerValuations.forEach((x) => x.categoryValues.sort((a, b) => a.category.ordinal - b.category.ordinal));
            const culturalData = this.convertCareerValuationDataToAnalysisData(careerValuations, date);
            const processedCareerValuations = this.processCareerValuationAnalysisData(culturalData, connections, hasQuantitativeAccess, date, filter, culturalRelationships);
            return processedCareerValuations;
        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }
    }


    private async getPrimeData(queryOptions: IBreezeQueryOptions, filter: ICareerValuationAnalysisFilter, accessLevel: CulturalLeadershipAccessLevels, prefetchAllData: boolean) {
        new CulturalLeadershipQueryUtilities(this.commonDataService).setCulturalEntityOptions(queryOptions, accessLevel);

        // fetching all connections here since it is likely to be more efficient that getting connections piecemeal for every single/date/combination of filter
        const connections = await lastValueFrom(this.commonDataService.getAll(ConnectionBreezeModel));

        let culturalRelationships: CulturalRelationship[] = [];

        if (filter.team) {
            await lastValueFrom(this.commonDataService.getAll(TeamBreezeModel));
        }

        if (filter.profileItem && filter.profileItemValue) {
            const predicate = new MethodologyPredicate<PersonProfileItemValue>("personProfileItemId", "==", filter.profileItem.personProfileItemId);
            await lastValueFrom(this.commonDataService.getByPredicate(PersonProfileItemValueBreezeModel, predicate));
        }

        if (prefetchAllData || filter.showTrendOutwardsOnly) {
            await lastValueFrom(this.commonDataService.getAll(CareerValuationBreezeModel));
            await lastValueFrom(this.commonDataService.getAll(CareerValuationCategoryValueBreezeModel));
        }

        if (accessLevel !== CulturalLeadershipAccessLevels.AnonymousAccess) {
            culturalRelationships = await lastValueFrom(this.commonDataService.getAll(CulturalRelationshipBreezeModel));
        }

        return [connections, culturalRelationships] as [Connection[], CulturalRelationship[]];
    }

    private async getCareerValuationData(queryOptions: any, filter: ICareerValuationAnalysisFilter, hasQuantitativeAccess: boolean) {
        let key = "latestCareerValuationsByDate";

        if (!hasQuantitativeAccess) {
            queryOptions.namedParams.activeOnly = true;
        }

        if (filter.date) {
            queryOptions.namedParams.date = filter.date;
            key += filter.date.toDateString();
        } else {
            key += "Today";
        }

        return lastValueFrom(this.commonDataService.getWithOptions(CareerValuationBreezeModel, key, queryOptions));
    }

    private async getCareerValuationValuesData(queryOptions: any, filter: ICareerValuationAnalysisFilter, hasQuantitativeAccess: boolean, prefetchAllData: boolean) {
        if (!prefetchAllData && !filter.showTrendOutwardsOnly) {
            let key = "latestCareerValuationValuesByDate";

            if (!hasQuantitativeAccess) {
                queryOptions.namedParams.activeOnly = true;
            }

            if (filter.date) {
                queryOptions.namedParams.date = filter.date;
                key += filter.date.toDateString();
            } else {
                key += "Today";
            }

            await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationCategoryValueBreezeModel, key, queryOptions));
        }
    }

    private convertCareerValuationDataToAnalysisData(careerValuations: CareerValuation[], date: Date) {
        return careerValuations.map((cv) => this.mapCareerValuation(cv, date));
    }

    private mapCareerValuation(careerValuation: CareerValuation, date: Date) {
        const data = {
            person: careerValuation.person,
            careerValuation,
            latestCatchup: careerValuation.creationDate,
            nextCatchup: this.culturalLeadershipFrameworkService.getNextCatchupDate(careerValuation.creationDate),
        } as ICulturalLeadershipPersonalAnalysisData;

        data.employeeConnections = data.person
            ? data.person.connections.filter((connection: Connection) => connection.isEmployeeConnection())
            : [];
        data.catchupStatus = this.culturalLeadershipFrameworkService.getCatchupStatusFromDate(date, data.nextCatchup);

        return data;
    }

    private processCareerValuationAnalysisData(culturalData: ICulturalLeadershipPersonalAnalysisData[], connections: Connection[], hasQuantitativeAccess: boolean, date: Date, filter: ICareerValuationAnalysisFilter, culturalRelationships: CulturalRelationship[]) {
        culturalData = this.filterByCulturalMeasurement(culturalData, connections, hasQuantitativeAccess, date, filter, culturalRelationships);

        if (filter.showTrendOutwardsOnly) {
            culturalData = culturalData.filter((data) => this.filterByBadTrend(data, date));
        }

        if (filter.maximumActualPercentage && filter.minimumIdealValue) {
            culturalData = culturalData.filter((data) => this.filterByDifferential(data, filter));
        }

        return culturalData;
    }


    private filterByBadTrend(analysisData: ICulturalLeadershipPersonalAnalysisData, date: Date) {
        if (!analysisData.careerValuation || !analysisData.person) {
            return false;
        }

        // not enough data to work this out
        if (analysisData.person.careerValuations.length < 3) {
            return false;
        }

        const valueCareerValuations = analysisData.person.careerValuations
            .filter((cv: CareerValuation) => moment(cv.creationDate).isBefore(date))
            .sort((a: CareerValuation, b: CareerValuation) => moment(a.creationDate) < moment(b.creationDate) ? 1 : -1);

        // not enough data to work this out
        if (valueCareerValuations.length < 3) {
            return false;
        }

        return (valueCareerValuations[2].extensions.actualTotal - valueCareerValuations[0].extensions.actualTotal) > 15;
    }

    private filterByDifferential(analysisData: ICulturalLeadershipPersonalAnalysisData, filter: ICareerValuationAnalysisFilter) {
        if (!analysisData.careerValuation) {
            return false;
        }

        return analysisData.careerValuation.categoryValues.some((cv) => (cv.idealValue > filter.minimumIdealValue! && ((cv.actualValue / cv.idealValue * 100) < filter.maximumActualPercentage!)));
    }
}
