import { Component, Input } from "@angular/core";
import { SearchType } from "@org-common/lib/search/search.interface";
import { SearchService } from "@org-common/lib/search/search.service";
import { IPageSearchResult, IPersonTeamRoleResult } from "@org-common/lib/search/search-results.interface";

@Component({
    selector: "adapt-application-bar-search-row-elements",
    templateUrl: "./application-bar-search-row-elements.component.html",
})
export class ApplicationBarSearchRowElementsComponent {
    @Input() public type!: SearchType;
    @Input() public searchResults!: IPersonTeamRoleResult | IPageSearchResult;
    @Input() public keyword!: string;

    public readonly SearchType = SearchType;
    public readonly SearchService = SearchService;

    public isPage(result: IPersonTeamRoleResult | IPageSearchResult) : result is IPageSearchResult {
        return "node" in result;
    }
}
