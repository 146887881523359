import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { SelectKeyFunctionLocationComponent } from "./select-key-function-location/select-key-function-location.component";

@NgModule({
    imports: [
        CommonModule,
        DxSelectBoxModule,
        DxListModule,
        DxTextBoxModule,
        AdaptZoneMapModule,
    ],
    exports: [
        SelectKeyFunctionLocationComponent,
    ],
    declarations: [
        SelectKeyFunctionLocationComponent,
    ],
})
export class AdaptKeyFunctionsSharedModule { }
