import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxSelectBoxComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { KeyFunctionsService } from "../key-functions.service";

@Component({
    selector: "adapt-select-key-function",
    templateUrl: "./select-key-function.component.html",
})
export class SelectKeyFunctionComponent implements OnInit, IFocusable {
    @ViewChild("focus") public elementToFocus?: DxSelectBoxComponent;

    @Input() public keyFunction?: KeyFunction;
    @Output() public keyFunctionChange = new EventEmitter<KeyFunction>();

    @Input() public disabled = false;
    @Input() public filter?: (keyFunction: KeyFunction) => boolean;

    public dataSource?: DataSource;

    public constructor(
        private keyFunctionsService: KeyFunctionsService,
    ) { }

    public async ngOnInit() {
        const keyFunctions = await lastValueFrom(this.keyFunctionsService.getAllKeyFunctions());

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: keyFunctions,
                key: "keyFunctionId",
            }),
            filter: this.filter,
            group: this.getLocation,
            postProcess: this.sortGroups,
        });
    }

    public reload() {
        this.dataSource?.reload();
    }

    public getElementToFocus() {
        return this.elementToFocus;
    }

    private getLocation(keyFunction: KeyFunction) {
        return KeyFunctionLocation.fromLocation(keyFunction);
    }

    private sortGroups(data: any[]) {
        return data.sort((a, b) => KeyFunctionLocation.compare(a.items[0], b.items[0]));
    }
}
