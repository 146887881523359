import { Injectable } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { switchMap } from "rxjs/operators";
import { AddKeyFunctionDialogComponent } from "./add-key-function-dialog/add-key-function-dialog.component";
import { DeleteKeyFunctionDialogComponent } from "./delete-key-function-dialog/delete-key-function-dialog.component";
import { EditKeyFunctionDialogComponent } from "./edit-key-function-dialog/edit-key-function-dialog.component";

@Injectable({
    providedIn: "root",
})
export class KeyFunctionsUiService {
    public constructor(
        private keyFunctionService: KeyFunctionsService,
        private dialogService: AdaptCommonDialogService,
    ) { }

    public addKeyFunction() {
        return this.keyFunctionService.addKeyFunctionAndAssociatedEntities().pipe(
            switchMap((keyFunction) => this.dialogService.open(AddKeyFunctionDialogComponent, keyFunction)),
        );
    }

    public editKeyFunction(keyFunction: KeyFunction) {
        // Ensure this is primed before opening the dialog
        return this.keyFunctionService.getOrCreateSupplementaryData(keyFunction).pipe(
            switchMap(() => this.dialogService.open(EditKeyFunctionDialogComponent, keyFunction)),
        );
    }

    public deleteKeyFunction(keyFunction: KeyFunction) {
        return this.dialogService.open(DeleteKeyFunctionDialogComponent, keyFunction);
    }
}
