<div class="display-career-valuation-personal-summary">
    <div class="d-flex flex-column">
        <span class="career-valuation-total"
              data-test="career-valuation-total">
            <span>{{actualTotal}}</span>%
        </span>

        <adapt-cvt-personal-differentials-chart [careerValuation]="careerValuation"></adapt-cvt-personal-differentials-chart>

        <table class="mt-3 table table-striped"
               *ngIf="careerValuation">
            <tbody>
                <tr *ngFor="let categoryValue of careerValuation.categoryValues"
                    data-test="category-score-row">
                    <td>
                        <p>{{categoryValue.category.name}}</p>
                    </td>
                    <td style="text-align:right">
                        <span>{{categoryValue.extensions.score}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
