import { Component, Inject } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-show-product-usage-dialog",
    templateUrl: "./show-product-usage-dialog.component.html",
})
export class ShowProductUsageDialogComponent extends BaseDialogComponent<Product> {
    public readonly dialogName = "ShowProductUsageDialog";

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public product: Product,
    ) {
        super();
    }
}
