import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-tier2",
    templateUrl: "./configure-tier2.component.html",
})
export class ConfigureTier2Component extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team?: Team;

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        // nothing to do
    }
}
