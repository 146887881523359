<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.SystemiseYourBusiness"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-two-column-layout *ngIf="hasSystem; else callToAction"
                         [fullPage]="true"
                         [removeColumnPadding]="true"
                         leftColumnWidthClasses="col-12 col-lg-4 col-xl-3"
                         rightColumnWidthClasses="col-12 col-lg-8 col-xl-9">
    <div left-column>
        <div class="card mh-100 d-print-none">
            <div class="card-header">
                <h3>Systems</h3>
            </div>
            <div class="card-body overflow-auto">
                <adapt-list-systems [selectedSystem]="selectedSystem"
                                    (selectedSystemChange)="onSelectedSystemChanged($event)"
                                    [disabled]="isEditing"
                                    (initialised)="notifyActivated()"></adapt-list-systems>
            </div>
        </div>
    </div>
    <div right-column>
        <adapt-system-content [system]="selectedSystem?.system"
                              (systemDeleted)="onSystemDeleted()"
                              (isEditingChange)="isEditing = $event"
                              [processMapStates]="processMapStates"
                              (processMapStatesChange)="onProcessMapStatesChanged($event)"></adapt-system-content>
    </div>
</adapt-two-column-layout>

<div adaptToolbarContent
     *adaptIfAuthorised="EditTier2">
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="addSystem"
            data-test="add-system-button">Add system</button>
</div>

<ng-template #callToAction>
    <adapt-call-to-action *ngIf="isInitialised"
                          [learnMoreUrl]="supportUrl">
        <adapt-call-to-action-title>Systemisation</adapt-call-to-action-title>

        <div class="my-3">
            <p>
                A business will struggle to grow or successfully handle succession if they do not have clear guidelines or processes for how
                they operate. To replicate how things get done and get consistent, reliable results, you need to capture it all somewhere.
            </p>
            <p>
                Documenting and embedding how a business operates in its unique way is what we call ‘Systemise your Business’.
            </p>
            <p>
                Systemising your business provides:
            </p>
            <ul class="list-content">
                <li>Consistent, reliable results.</li>
                <li>Increased efficiencies as you stop reinventing the wheel over and over.</li>
                <li>Reduced waste and pain points in how things are currently done.</li>
                <li>The building of an ‘asset’ that is not reliant on the owner.</li>
                <li>Role clarity for everyone in the business including the owners and leaders.</li>
                <li>Support for growth by allowing for role handover and effective onboarding of new people.</li>
                <li>Business needs captured in one central place that is easy to find and easy to consume rather than scattered
                    through the business in different files and in people’s heads.</li>
            </ul>
        </div>

        <button *adaptIfAuthorised="EditTier2"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addSystem">
            Add system
        </button>
    </adapt-call-to-action>
</ng-template>
