import { Component, Input } from "@angular/core";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { CareerValuationService } from "app/features/people/career-valuation/career-valuation.service";
import { ContentReadyEvent } from "devextreme/ui/list";

@Component({
    selector: "adapt-configure-career-valuation",
    templateUrl: "./configure-cvt.component.html",
})
export class ConfigureCareerValuationComponent extends ConfigureFeatureBase {
    public careerValuationCategories!: CareerValuationCategory[];
    public selectedCategory?: CareerValuationCategory;
    @Input() public configItem?: IConfigItem;

    public constructor(
        configurationService: ConfigurationService,
        private careerValuationService: CareerValuationService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.careerValuationCategories = await this.careerValuationService.promiseToGetCareerValuationCategories();
        this.selectedCategory = this.careerValuationCategories && this.careerValuationCategories.length
            ? this.careerValuationCategories[0]
            : undefined;
    }

    public onContentReady(e: ContentReadyEvent) {
        e.component!.selectItem(this.selectedCategory);
    }

    public async onSelectionChanged(e: IDxListSelectionChangedEvent<CareerValuationCategory>) {
        this.selectedCategory = e.addedItems.length ? e.addedItems[0] : undefined;
    }
}
