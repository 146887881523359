import { Component, Injector, OnInit } from "@angular/core";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { lastValueFrom } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

@Component({
    selector: "adapt-manage-value-streams-dialog",
    templateUrl: "./manage-value-streams-dialog.component.html",
})
export class ManageValueStreamsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<void> implements OnInit {
    public readonly dialogName = "ManageValueStreams";

    public entitiesToConfirm: IBreezeEntity[] = [];

    public valueStreams?: ValueStream[];
    public newValueStreamName?: string;

    public constructor(
        injector: Injector,
        private tier1Service: Tier1ArchitectureService,
    ) {
        super(injector, DialogResolveData.NotRequired);
    }

    public async ngOnInit() {
        this.valueStreams = await lastValueFrom(this.tier1Service.getActiveValueStreams());
    }

    @Autobind
    public async createAndAddValueStream() {
        if (!this.newValueStreamName || !this.valueStreams) {
            return;
        }

        const newValueStreamEntities = await lastValueFrom(this.tier1Service.createValueStream(this.newValueStreamName));
        this.valueStreams.push(newValueStreamEntities.valueStream);
        this.entitiesToConfirm.push(...Object.values(newValueStreamEntities));

        this.newValueStreamName = undefined;
    }

    public updateOrdinals(e: IDxListItemReorderedEvent<ValueStream>) {
        if (!this.valueStreams) {
            return;
        }

        SortUtilities.reorderItemInIntegerSortedArray(this.valueStreams, "ordinal", e.fromIndex, e.toIndex);
        this.entitiesToConfirm = ArrayUtilities.distinct([...this.entitiesToConfirm, ...this.valueStreams]);
    }
}
