import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptIncompleteWorkModule } from "@common/ux/incomplete-work/incomplete-work.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { AdaptOrganisationMapModule } from "app/features/architecture/organisation-map/organisation-map.module";
import { AdaptProcessMapSharedModule } from "app/features/architecture/process-map/process-map-shared.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { AdaptActivityFeedModule } from "app/general/activity-feed/activity-feed.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxCheckBoxModule, DxListModule, DxSelectBoxModule, DxSortableModule, DxTextBoxModule } from "devextreme-angular";
import { AddTeamDialogComponent } from "./add-team-dialog/add-team-dialog.component";
import { EditTeamLocationsComponent } from "./edit-team-locations/edit-team-locations.component";
import { EditTeamLocationsDialogComponent } from "./edit-team-locations-dialog/edit-team-locations-dialog.component";
import { ListTeamLocationsComponent } from "./list-team-locations/list-team-locations.component";
import { NavigateTeamsPageComponent } from "./navigate-teams-page/navigate-teams-page.component";
import { navigateTeamsPageRoute } from "./navigate-teams-page/navigate-teams-page.route";
import { TeamActivityFeedComponent } from "./team-activity-feed/team-activity-feed.component";
import { TeamHierarchyDashboardElementComponent } from "./team-hierarchy-dashboard-element/team-hierarchy-dashboard-element.component";
import { TeamLocationDashboardElementComponent } from "./team-location-dashboard-element/team-location-dashboard-element.component";
import { TeamSystemsDashboardElementComponent } from "./team-systems-dashboard-element/team-systems-dashboard-element.component";
import { TeamsAuthService } from "./teams-auth.service";
import { AdaptTeamsSharedModule } from "./teams-shared.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonDialogModule,
        AdaptDirectorySharedModule,
        AdaptActivityFeedModule,
        AdaptIncompleteWorkModule,
        AdaptKeyFunctionsCommonModule,
        AdaptFeaturesModule,
        AdaptLoadingSpinnerModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptProcessMapSharedModule,
        AdaptTeamDashboardSharedModule,
        DxSortableModule,
        DxSelectBoxModule,
        DxListModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        AdaptOrganisationMapModule,
        AdaptGuidingPhilosophyModule,
        AdaptValuesConstitutionModule,
        AdaptEmbedShellModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptSystemisationModule,
        AdaptTeamsSharedModule,
        AdaptZoneMapModule,
        AdaptMenuModule,
        AdaptDashboardElementModule,
        AdaptLinkDisabledModule,
    ],
    declarations: [
        ListTeamLocationsComponent,
        TeamActivityFeedComponent,
        EditTeamLocationsComponent,
        NavigateTeamsPageComponent,
        EditTeamLocationsDialogComponent,
        TeamLocationDashboardElementComponent,
        TeamSystemsDashboardElementComponent,
        TeamHierarchyDashboardElementComponent,
        AddTeamDialogComponent,
    ],
    exports: [
        TeamActivityFeedComponent,
        ListTeamLocationsComponent,
        TeamLocationDashboardElementComponent,
        TeamSystemsDashboardElementComponent,
        TeamHierarchyDashboardElementComponent,
    ],
})

export class AdaptTeamsModule {
    public static readonly Routes = [
        ...navigateTeamsPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        TeamsAuthService.registerAccess(authorisationService);
    }
}

