<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content
         *ngIf="culturalIndex">
        <adapt-ci-range [culturalIndex]="culturalIndex"
                        [isDisabled]="true">
        </adapt-ci-range>

        <div class="cultural-index-comment">
            <p>
                <strong>My orbit is {{getDirectionState(culturalIndex.direction)}}. </strong>
            </p>
            <div *ngIf="culturalIndex.notes"
                 [froalaView]="culturalIndex.notes"></div>
        </div>

        <adapt-cultural-measurement-meta [culturalEntity]="culturalIndex"
                                         [showCulturalLeaderData]="true">
        </adapt-cultural-measurement-meta>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="edit"
                (click)="editCulturalIndex()"
                *ngIf="hasEditPermission"></button>
        <button adaptButton="delete"
                (click)="deleteCulturalIndex()"
                *ngIf="hasEditPermission"></button>
        <button class="btn btn-primary"
                adaptButtonIcon="fa"
                (click)="resolve(culturalIndex!)">OK</button>
    </div>
</adapt-dialog>
