import { Component, Inject } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-show-guidance-material-usage-dialog",
    templateUrl: "./show-guidance-material-usage-dialog.component.html",
})
export class ShowGuidanceMaterialUsageDialogComponent extends BaseDialogComponent<GuidanceMaterial> {
    public readonly dialogName = "ShowGuidanceMaterialUsageDialog";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public guidanceMaterial: GuidanceMaterial,
    ) {
        super();
    }
}
