import { Injectable } from "@angular/core";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { myOrganisationsPageRoute } from "@org-common/lib/my-organisations/my-organisations-page/my-organisations-page.route";
import { subscribeOrganisationPageRoute } from "app/organisation/subscribe-organisation/subscribe-organisation-page/subscribe-organisation-page.route";
import { EMPTY } from "rxjs";

/** Dynamic nodes that aren't really tied to any application feature */
@Injectable()
export class GeneralDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilitiesService: NavigationUtilitiesService,
    ) {
    }

    public buildDynamicNodes() {
        return [
            this.buildDynamicSubscribeOrganisationNode(),
        ];
    }

    private buildDynamicSubscribeOrganisationNode() {
        return this.navUtilitiesService.nodeBuilderForController(subscribeOrganisationPageRoute.id)
            .setDynamicParentController(myOrganisationsPageRoute.id)
            .build();
    }
}
