import { ModuleWithProviders, NgModule } from "@angular/core";
import { provideDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { provideNavigationHierarchy } from "@common/route/navigation-hierarchy";
import { GeneralDynamicNavigationService } from "./general-dynamic-navigation.service";
import { IntegratedArchitectureDynamicNavigationService } from "./integrated-architecture-dynamic-navigation.service";
import { ItemsDynamicNavigationService } from "./items-dynamic-navigation.service";
import { KeyFunctionsDynamicNavigationService } from "./key-functions-dynamic-navigation.service";
import { OrganisationDynamicNavigationService } from "./organisation-dynamic-navigation.service";
import { OrganisationHierarchyNavigationService } from "./organisation-hierarchy-navigation.service";
import { PersonNavigationService } from "./person-navigation.service";
import { TeamDynamicNavigationService } from "./team-dynamic-navigation.service";
import { TeamHierarchyNavigationService } from "./team-hierarchy-navigation.service";

@NgModule()
export class AdaptNavigationModule {
    public static forRoot(): ModuleWithProviders<AdaptNavigationModule> {
        return {
            ngModule: AdaptNavigationModule,
            providers: [
                provideNavigationHierarchy(PersonNavigationService),
                provideNavigationHierarchy(TeamHierarchyNavigationService),
                provideNavigationHierarchy(OrganisationHierarchyNavigationService),

                provideDynamicNodeBuilder(PersonNavigationService),
                provideDynamicNodeBuilder(TeamDynamicNavigationService),
                provideDynamicNodeBuilder(OrganisationDynamicNavigationService),

                provideDynamicNodeBuilder(IntegratedArchitectureDynamicNavigationService),
                provideDynamicNodeBuilder(KeyFunctionsDynamicNavigationService),
                provideDynamicNodeBuilder(ItemsDynamicNavigationService),
                provideDynamicNodeBuilder(GeneralDynamicNavigationService),
            ],
        };
    }
}
