import { Component, Inject, ViewChild } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { SelectSystemComponent } from "../select-system/select-system.component";

@Component({
    selector: "adapt-move-system-component-dialog",
    templateUrl: "./move-system-component-dialog.component.html",
})
export class MoveSystemComponentDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemComponent> {
    public readonly dialogName = "MoveSystemComponent";

    public entitiesToConfirm: IBreezeEntity[];
    public documentType: string;
    public canCreate = false;

    @ViewChild(SelectSystemComponent) private selectSystemComponent?: SelectSystemComponent;

    private initialSystem: SystemEntity;
    private newSystemEntity?: SystemEntity;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public systemComponent: SystemComponent,
        private commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
    ) {
        super();
        this.initialSystem = systemComponent.system;
        this.autoResolveData = systemComponent;
        this.entitiesToConfirm = [systemComponent];

        this.documentType = systemComponent.extensions.componentLabel;

        // only allow creation here if user has tier2 configure permission
        this.canCreate = this.commonArchAuthService.currentPersonCan(this.commonArchAuthService.personCanConfigureTier2);
    }

    public get isCreatingSystem() {
        return !!this.selectSystemComponent?.isCreateAndSelecting;
    }

    @Autobind
    public filterSystems(system: SystemEntity) {
        return system !== this.initialSystem;
    }

    // this is called after confirm add or existing system selected
    public onSystemChange(system: SystemEntity) {
        this.systemComponent.system = system;
        if (system.entityAspect.entityState.isAdded() && !this.entitiesToConfirm.includes(system)) {
            this.entitiesToConfirm.push(system); // make sure this will be saved (from CM-5395 where system may not necessarily be saved)
        }
    }

    // this is called when new system entity created - or add cancelled
    public onNewSystemChange(system?: SystemEntity) {
        if (this.newSystemEntity) {
            ArrayUtilities.removeElementFromArray<IBreezeEntity>(this.newSystemEntity, this.entitiesToConfirm);
        }

        this.newSystemEntity = system;
        if (system) {
            this.entitiesToConfirm.push(system);
        }
    }
}
