<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ElaborateKeyFunctions"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title>
    <div class="d-flex align-items-center">
        <h2>{{(keyFunction$ | async)?.name}}</h2>
        <adapt-display-labels class="ms-1"
                              [labelLocations]="currentKeyFunction?.labelLocations"></adapt-display-labels>
    </div>
</adapt-toolbar-title>
<div adaptToolbarContent
     *ngIf="isActivated && !notFound && canEditTier1 && (keyFunction$ | async) as keyFunction">
    <button adaptButton="edit"
            [adaptBlockingClick]="editKeyFunction"
            [adaptBlockingClickParam]="keyFunction"
            data-test="edit-key-function-button"></button>
    <button adaptButton="delete"
            [adaptBlockingClick]="deleteKeyFunction"
            [adaptBlockingClickParam]="keyFunction"
            data-test="delete-key-function-button"></button>
</div>

<div class="row"
     *ngIf="(keyFunction$ | async) as keyFunction">
    <div class="col-xl-9">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Purpose</h2>
                    </div>
                    <div class="card-body"
                         *adaptLoadingUntilFirstEmit="let supplementaryData of supplementaryData$"
                         data-test="key-function-purpose">
                        <div *ngIf="supplementaryData?.purpose as purpose; else noPurposeTemplate"
                             [froalaView]="purpose"></div>

                        <ng-template #noPurposeTemplate>
                            <adapt-call-to-action-inline>
                                {{CallToActionText.keyFunction.purpose}}

                                <!-- TODO go directly to correct tab on edit-->
                                <button *ngIf="canEditTier1"
                                        adaptCallToActionButton
                                        adaptButtonIcon="edit"
                                        [adaptBlockingClick]="editKeyFunction"
                                        [adaptBlockingClickParam]="keyFunction$ | async">
                                    Define your purpose
                                </button>
                            </adapt-call-to-action-inline>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="card"
                     *ngIf="(supplementaryData$ | async)?.accountabilities as accountabilities">
                    <div class="card-header">
                        <h2 class="card-title">Accountabilities</h2>
                    </div>
                    <div class="card-body"
                         data-test="key-function-accountabilities">
                        <div [froalaView]="accountabilities"></div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="card"
                     *ngIf="(supplementaryData$ | async)?.systems as systems">
                    <div class="card-header">
                        <h2 class="card-title">IT Systems</h2>
                    </div>
                    <div class="card-body"
                         data-test="key-function-systems">
                        <div [froalaView]="systems"></div>
                    </div>
                </div>
            </div>

            <ng-container *adaptIfAuthorised="ReadTier2AccessVerifier">
                <adapt-display-systems class="mt-3 col-12"
                                       [fullPageCTA]="false"
                                       [keyFunction]="keyFunction"
                                       (initialised)="onSystemsInitialised()"
                                       data-test="key-function-systems"></adapt-display-systems>
            </ng-container>
        </div>
    </div>

    <div class="col-xl-3">
        <div class="row">
            <div class="col-12 col-md-6 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            Key Roles & People
                        </h2>
                    </div>
                    <div class="card-body"
                         *adaptLoadingUntilFirstEmit="let keyFunctionRoles of keyFunctionRoles$"
                         data-test="key-function-people">
                        <adapt-display-role-allocation #leaderRoleAllocation
                                                       [role]="keyFunction.leaderRole"
                                                       class="mb-1"></adapt-display-role-allocation>

                        <adapt-display-role-allocation *ngFor="let keyFunctionRole of keyFunctionRoles"
                                                       [role]="keyFunctionRole.role"></adapt-display-role-allocation>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            Teams
                        </h2>
                    </div>
                    <div class="card-body"
                         data-test="key-function-teams">
                        <adapt-list-key-function-teams [keyFunction]="keyFunction"
                                                       [noTeamsTemplate]="noTeamsTemplate"></adapt-list-key-function-teams>
                        <ng-template #noTeamsTemplate>
                            <adapt-call-to-action-inline>
                                {{CallToActionText.keyFunction.teams}}

                                <!-- TODO go directly to correct tab on edit-->
                                <button *ngIf="canEditTier1"
                                        adaptCallToActionButton
                                        adaptButtonIcon="edit"
                                        [adaptBlockingClick]="editKeyFunction"
                                        [adaptBlockingClickParam]="keyFunction$ | async">
                                    Assign some teams
                                </button>
                            </adapt-call-to-action-inline>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row"
     *ngIf="notFound">
    <div class="alert alert-info col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        Unable to find that key function. Has it been deleted?
    </div>
</div>
