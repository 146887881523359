import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { SelectTeamComponent } from "@org-common/lib/teams/team-dashboard-shared/select-team/select-team.component";
import { EMPTY, lastValueFrom, merge, of, ReplaySubject } from "rxjs";
import { filter, first, map, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-list-key-function-teams",
    templateUrl: "./list-key-function-teams.component.html",
})
export class ListKeyFunctionTeamsComponent extends BaseComponent {
    @Input() public set keyFunction(value: KeyFunction | undefined) {
        this.keyFunction$.next(value);
    }

    private keyFunction$ = new ReplaySubject<KeyFunction | undefined>(1);

    @Input() public allowEditing = false;
    @Input() public showTeamMembers = true;
    @Input() public focusPersonId?: number;
    @Input() public noTeamsTemplate?: TemplateRef<any>;
    @Output() public populated = new EventEmitter<TeamLocation[]>();
    @Output() public added = new EventEmitter<TeamLocation>();

    @ViewChild(SelectTeamComponent)
    public selectTeamComponent?: SelectTeamComponent;

    public activeTeamLocations?: TeamLocation[];
    public inactiveTeamLocations?: TeamLocation[];
    public addedTeam?: Team;

    private removedTeamLocations: TeamLocation[] = [];

    public constructor(
        private commonDataService: CommonDataService,
        private keyFunctionsService: KeyFunctionsService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const keyFunctionTeamLocationsUpdated$ = this.keyFunction$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((keyFunction) => this.applicableTeamLocationChangesCommitted(keyFunction)),
        );
        merge(this.keyFunction$, keyFunctionTeamLocationsUpdated$).pipe(
            switchMap((keyFunction) => keyFunction
                ? this.getTeamLocations(keyFunction)
                : of([])),
            this.takeUntilDestroyed(),
        ).subscribe((teamLocations) => {
            this.populated.emit(teamLocations);
            [this.activeTeamLocations, this.inactiveTeamLocations] = ArrayUtilities.partition(
                teamLocations,
                (tl) => tl.team.isActive(),
            );
        });
    }

    private applicableTeamLocationChangesCommitted(keyFunction: KeyFunction) {
        return this.rxjsBreezeService.entityTypeChanged(TeamLocation).pipe(
            filter((tl) => tl.keyFunctionId === keyFunction.keyFunctionId),
            map(() => keyFunction),
        );
    }

    private getTeamLocations(keyFunction: KeyFunction) {
        return this.keyFunctionsService.getKeyFunctionTeamLocations(keyFunction);
    }

    public updateOrdinals(teamLocations: TeamLocation[], e: IDxListItemReorderedEvent<TeamLocation>) {
        SortUtilities.reorderItemInIntegerSortedArray(teamLocations, "ordinal", e.fromIndex, e.toIndex);
    }

    public async removeTeamLocation(e: IDxListItemDeletedEvent<TeamLocation>) {
        if (!this.activeTeamLocations) {
            return;
        }

        await lastValueFrom(this.commonDataService.remove(e.itemData));
        this.removedTeamLocations.push(e.itemData!);
        this.selectTeamComponent?.reload();
    }

    @Autobind
    public notCurrentTeamFilter(team: Team) {
        if (!this.activeTeamLocations) {
            return true;
        }

        return this.activeTeamLocations.every((i) => i.team !== team);
    }

    public async addTeam(team?: Team) {
        if (!team) {
            return;
        }

        // We have to set this so we can clear it after the add
        this.addedTeam = team;

        const teamLocation = await lastValueFrom(this.keyFunction$.pipe(
            first(),
            emptyIfUndefinedOrNull(),
            switchMap((kf) => team
                ? this.keyFunctionsService.addTeamToKeyFunction(team, kf)
                : EMPTY),
        ));

        this.activeTeamLocations?.push(teamLocation);
        this.added.emit(teamLocation);
        await this.selectTeamComponent!.reload();
        // only clear this after reload to not leave behind the text of the previously selected role
        this.addedTeam = undefined;
    }
}
