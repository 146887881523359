import { Component, Inject, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { EditSystemLocationsComponent } from "../edit-system-locations/edit-system-locations.component";

export interface IEditSystemLocationDialogData {
    keyFunction?: KeyFunction;
    role?: Role;
    team?: Team;
}

@Component({
    selector: "adapt-edit-system-locations-dialog",
    templateUrl: "./edit-system-locations-dialog.component.html",
})
export class EditSystemLocationsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditSystemLocationDialogData> {
    public readonly dialogName = "EditSystemLocations";
    public entitiesToConfirm: IBreezeEntity[] = [];
    public title: string;

    @ViewChild(EditSystemLocationsComponent) private editSystemLocationComponent?: EditSystemLocationsComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditSystemLocationDialogData,
    ) {
        super();
        this.autoResolveData = data;

        if (data.keyFunction) {
            this.title = "Add/Remove Systems for Key Function";
        } else if (data.team) {
            this.title = "Add/Remove Systems for Team";
        } else if (data.role) {
            this.title = "Add/Remove Systems for Role";
        } else {
            this.title = "Add/Remove Systems";
        }
    }

    public get saveIsDisabled() {
        return this.entitiesAreUnmodifiedOrInvalid || this.editSystemLocationComponent?.isCreatingAndAdding;
    }

    public setEntitiesToConfirm(entities: IBreezeEntity[]) {
        this.entitiesToConfirm = entities;
    }
}
