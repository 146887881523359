import { Component, EventEmitter, Input, OnChanges, Optional, Output, SimpleChanges } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { HideIfEmptyDirective } from "@common/ux/hide-if-empty/hide-if-empty.directive";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { Observable, Subject } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { systemPageRoute } from "../system-page/system-page.route";
import { SystemisationService } from "../systemisation.service";
import { SystemisationUiService } from "../systemisation-ui.service";

@Component({
    selector: "adapt-display-systems",
    templateUrl: "./display-systems.component.html",
    styleUrls: ["./display-systems.component.scss"],
})
export class DisplaySystemsComponent extends BaseComponent implements OnChanges {
    private static readonly SystemSearchParam = "selectedSystem";
    public readonly ConfigureTier2 = IntegratedArchitectureFrameworkAuthService.ConfigureTier2;

    @Input() public fullPageCTA = true;
    @Input() public hideCTA = false;
    @Input() public keyFunction?: KeyFunction;
    @Input() public team?: Team;
    @Input() public role?: Role;
    @Input() public selectedSystemId?: number;
    @Output() public selectedSystemIdChange = new EventEmitter<number>();
    @Output() public initialised = new EventEmitter<undefined>();

    public selectedSystem?: SystemEntity;
    public systems: SystemEntity[] = [];

    public systemsIcon = SystemEntity.IconClass;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.ImplementationKitArticle.SystemiseYourBusiness);

    private triggerUpdate$ = new Subject<void>();
    // makes this a function that returns the Observable so that it will issue new query (even if is cached)
    // instead of returning result from previous query (which was the case before converting this to a function that returns observable)
    private getSystems?: () => Observable<SystemEntity[]>;
    // cache the observable for the system page route to avoid this being evaluated every digest cycle
    private systemPageRouteCache: { [systemId: number]: Observable<string> } = {};

    public constructor(
        private systemisationService: SystemisationService,
        private systemisationUiService: SystemisationUiService,
        private routeService: RouteService,
        rxjsBreezeService: RxjsBreezeService,
        @Optional() hideIfEmpty: HideIfEmptyDirective | null,
    ) {
        super();

        this.triggerUpdate$.pipe(
            debounceTime(100), // need debounce as this can triggered from entityTypeChanged which will triggered per entity
            filter(() => !!this.getSystems),
            switchMap(() => this.getSystems!()),
            this.takeUntilDestroyed(),
        ).subscribe((systems) => {
            if (hideIfEmpty) {
                hideIfEmpty.setIsDisplayedFromArray(systems);
            }

            this.systems = systems;
            // clear selectedSystem if it is no longer linked
            if (this.selectedSystem && this.systems.indexOf(this.selectedSystem) < 0) {
                this.selectedSystem = undefined;
            }

            const systemId = this.routeService.getSearchParameterIntValue(DisplaySystemsComponent.SystemSearchParam);
            if (systemId) {
                const system = systems.find((s) => s.systemEntityId === systemId);
                if (system) {
                    this.selectedSystem = system;
                } else {
                    this.routeService.deleteSearchParameter(DisplaySystemsComponent.SystemSearchParam);
                }
            }

            if (!this.selectedSystem && systems.length > 0) {
                // can also be selected system deleted
                this.selectedSystem = systems[0];
            }

            this.isInitialised = true;
        });

        rxjsBreezeService.entityTypeChanged(SystemLocation).pipe(
            filter((changedLinkingEntity) => this.isLinkingSystemToCurrentEntity(changedLinkingEntity)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.keyFunction && this.keyFunction) {
            this.getSystems = () => this.systemisationService.getSystemsForKeyFunction(this.keyFunction!);
            this.triggerUpdate$.next();
        } else if (changes.team && this.team) {
            this.getSystems = () => this.systemisationService.getSystemsForTeam(this.team!);
            this.triggerUpdate$.next();
        } else if (changes.role && this.role) {
            this.getSystems = () => this.systemisationService.getSystemsForRole(this.role!);
            this.triggerUpdate$.next();
        }
    }

    public onSystemContentInitialised() {
        this.initialised.emit();
    }

    public gotoSystemPage(system: SystemEntity) {
        systemPageRoute.gotoRoute({ systemEntityId: system.systemEntityId }).subscribe();
    }

    public getSystemPageRoute$(system: SystemEntity) {
        let result = this.systemPageRouteCache[system.systemEntityId];
        if (!result) {
            result = systemPageRoute.getRoute({ systemEntityId: system.systemEntityId });
            this.systemPageRouteCache[system.systemEntityId] = result;
        }

        return result;
    }

    public onToggleChanged(toggleOn: boolean, system: SystemEntity) {
        if (toggleOn) {
            this.selectedSystem = system;
            this.routeService.updateSearchParameterValue(DisplaySystemsComponent.SystemSearchParam, this.selectedSystem.systemEntityId);
        }
    }

    public editSystemLocations() {
        this.systemisationUiService.openEditSystemLocationsDialog(this.keyFunction, this.team, this.role).pipe(
            tap(() => this.triggerUpdate$.next()),
        ).subscribe();
    }

    private isLinkingSystemToCurrentEntity(systemLocation: SystemLocation) {
        if (this.keyFunction) {
            return systemLocation.keyFunctionId === this.keyFunction.keyFunctionId;
        } else if (this.team) {
            return systemLocation.teamId === this.team.teamId;
        } else if (this.role) {
            return systemLocation.roleId === this.role.roleId;
        }

        return false;
    }
}
