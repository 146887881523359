<adapt-dashboard-element name="Resilient business assessment"
                         [url]="analyseAllSurveysPageUrl$ | async">
    <div *adaptLoadingUntilFirstEmit="let survey of survey$">
        <div *ngIf="survey ;else noSurveysCompletedTemplate">
            <div class="survey-name">
                <a [routerLink]="analyseSurveyPageObject?.path"
                   [queryParams]="analyseSurveyPageObject?.queryParams"
                   class="align-self-baseline">
                    <h3 class="mt-1">{{survey.name}}</h3>
                </a>
                <span class="align-self-baseline text-nowrap ms-2">Closed: {{survey.endTime | adaptDate}}</span>
            </div>

            <adapt-display-multi-group-survey-result [survey]="survey"
                                                     [displayCompact]="true"></adapt-display-multi-group-survey-result>
        </div>
        <ng-template #noSurveysCompletedTemplate>
            <adapt-call-to-action-inline [featureUrl]="analyseAllSurveysPageUrl$ | async">
                The resilient business assessment collects quantitative data from everyone in the
                business. The results are divided into Leader and Employee results, allowing you
                to compare the two.
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</adapt-dashboard-element>
