import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StakeholderAuthService } from "../stakeholder-auth.service";
import { StakeholderDirectoryPageComponent } from "./stakeholder-directory-page.component";

const stakeholderDirectoryPageTitle = "External Stakeholder Directory";

export const stakeholderDirectoryPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-stakeholder-directory-page", StakeholderDirectoryPageComponent)
    .atOrganisationUrl("/stakeholders/directory")
    .verifyingAccess(StakeholderAuthService.ReadStakeholders)
    .withTitle(stakeholderDirectoryPageTitle)
    .validatesOrganisation()
    .build();
