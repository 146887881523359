import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-edit-external-link-dialog",
    templateUrl: "./edit-external-link-dialog.component.html",
    styles: [`.system-external-link { width: 100%; height: 100%; }`],
    encapsulation: ViewEncapsulation.None,
})
@PersistableDialog("EditExternalLinkDialog")
export class EditExternalLinkDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemComponent> {
    public readonly dialogName = "EditExternalLink";

    public title: string;
    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public systemComponent: SystemComponent,
    ) {
        super();
        this.autoResolveData = this.systemComponent;
        this.title = this.systemComponent.systemDocument!.entityAspect.entityState.isAdded()
            ? "Add external page"
            : "Edit external page";

        this.entitiesToConfirm = [
            this.systemComponent.systemDocument!,
            this.systemComponent,
        ];
    }

    public get saveIsDisabled() {
        return this.entitiesAreUnmodifiedOrInvalid
            || !this.systemComponent.systemDocument!.content;
    }

    public onNameChange(name: string) {
        this.systemComponent.name = name;
        this.systemComponent.extensions.validateComponentName();
    }

    public onUrlChanged(url: string) {
        if (!url) {
            this.systemComponent.systemDocument!.content = "";
        } else {
            if (UrlUtilities.isValidHttpUrl(url)) {
                this.systemComponent.systemDocument!.content = `<iframe class="system-external-link" src="${url}"></iframe>`;
            } else {
                this.systemComponent.systemDocument!.content = "";
            }
        }
    }
}
