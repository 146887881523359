<adapt-feature-guidance [configItem]="configItem">
    <p>Our teams are built on trust and relationships with our peers. The Peer Catch-up is a tool which provides peers with insights and an
        opportunity to give feedback to each other. It enables peer to peer accountability and a framework upon which to resolve issues.</p>
    <p>Enabling this feature will allow you to record Peer Catch-ups at an organisation or team level.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <ng-container *ngIf="configuration">
        <div class="form-group">
            <label for="catchupFrequency">Catch-up frequency <small>(in months) </small>
                <span class="fal fa-info-circle"
                      adaptTooltip="The catchup frequency determines how long is allowed between Peer Catch-ups before a catchup is considered 'overdue'"></span></label>
            <dx-number-box id="catchupFrequency"
                           [adaptValidateEntity]="configuration"
                           adaptValidateEntityProperty="catchupFrequency"
                           [min]="1"
                           [max]="12"
                           [showSpinButtons]="true"
                           [(value)]="configuration.catchupFrequency"
                           width="200px"></dx-number-box>
        </div>

        <h3>Guidance</h3>
        <div class="row gx-3 gy-2">
            <div class="col-12 col-md-3">
                <dx-list [dataSource]="pcuCategories"
                         [disabled]="restoringGuidance"
                         selectionMode="single"
                         [focusStateEnabled]="false"
                         (onSelectionChanged)="onSelectionChanged($event)"
                         (onContentReady)="onContentReady($event)">
                    <div *dxTemplate="let data of 'item'">
                        <div class="list-item">{{data.name}}</div>
                    </div>
                </dx-list>
            </div>

            <div *ngIf="selectedCategory"
                 class="col-12 col-md-9">
                <div class="form-group">
                    <div class="d-lg-flex align-items-center justify-content-between">
                        <div>
                            <label>{{selectedCategory.name}} guidance</label>
                            <small class="d-block text-muted mb-1">This is shown when in the {{selectedCategory.name}}
                                step while recording a Peer Catch-up.</small>
                        </div>

                        <button class="btn btn-link"
                                adaptButtonIcon="refresh"
                                [adaptBlockingClick]="restoreDefaultGuidance"
                                [adaptBlockingClickParam]="selectedCategory">Restore default guidance</button>
                    </div>

                    <adapt-html-editor [contents]="configuration[selectedCategory.field]"
                                       (contentsChange)="contentChanged(selectedCategory.field, $event)"
                                       [readonly]="restoringGuidance"
                                       (isValidChange)="isEditorValid = $event"
                                       [required]="true"></adapt-html-editor>
                </div>
            </div>
        </div>
    </ng-container>
</adapt-feature-configuration>
