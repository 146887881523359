import { Component, Input, OnChanges } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IKeyFunctionLocation, KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { OrganisationMapService } from "../organisation-map.service";
import { IMapEntityTemplates } from "../render-organisation-map/render-organisation-map.component";

type LocationItem = KeyFunction | TeamLocation;

@Component({
    selector: "adapt-list-entities-in-location",
    templateUrl: "./list-entities-in-location.component.html",
    styleUrls: ["./list-entities-in-location.component.scss"],
})
export class ListEntitiesInLocationComponent implements OnChanges {
    public readonly Zone = Zone;
    public readonly ZoneClass = ZoneMetadata.BackgroundStyleClass;

    @Input() public zone?: Zone;
    @Input() public valueStream?: ValueStream;
    @Input() public entityTemplates?: IMapEntityTemplates;
    @Input() public filterTeams?: (teams: Team[]) => Team[] | Observable<Team[]>;

    public locationItems$: Observable<LocationItem[]>;
    private location$ = new ReplaySubject<IKeyFunctionLocation>(1);

    public getAsTeamLocation = OrganisationMapService.getAsTeamLocation;
    public getAsKeyFunction = OrganisationMapService.getAsKeyFunction;

    public constructor(
        private orgMapService: OrganisationMapService,
    ) {
        this.locationItems$ = this.location$.pipe(
            switchMap((loc) => this.orgMapService.emitWhenLocationShouldBeFetched(loc)),
            switchMap((loc) => this.orgMapService.getLocationItems(loc)),
            switchMap((locationItems) => this.applyTeamFilter(locationItems)),
        );
    }

    public ngOnChanges() {
        this.location$.next(KeyFunctionLocation.fromLocation({
            zone: this.zone ?? null,
            valueStreamId: this.valueStream?.valueStreamId ?? null,
            valueStream: this.valueStream ?? null,
        }));
    }

    private applyTeamFilter(locationItems: LocationItem[]) {
        const teams = locationItems
            .filter((i) => this.getAsTeamLocation(i))
            .map((i: TeamLocation) => i.team);

        let filteredTeams: Observable<Team[]>;
        if (!teams) {
            filteredTeams = of([]);
        } else if (!this.filterTeams) {
            filteredTeams = of(teams);
        } else {
            const filtered = this.filterTeams(teams);
            filteredTeams = Array.isArray(filtered) ? of(filtered) : filtered;
        }

        return filteredTeams.pipe(
            map((showTeams) => locationItems.filter((l) => {
                const teamLocation = this.getAsTeamLocation(l);
                return teamLocation
                    ? showTeams.includes(teamLocation.team)
                    : true;
            })),
        );
    }
}
