import { Component, Injector, OnInit } from "@angular/core";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { IMapStackFramesChangedEvent } from "app/features/architecture/process-map/process-steps-card/process-steps-card.component";
import { Observable, ReplaySubject } from "rxjs";
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { SystemisationService } from "../systemisation.service";
import { systemsPageRoute } from "../systems-page/systems-page.route";

export const SystemPageSelector = "adapt-system-page";
export const ProcessMapStatesKey = "ProcessMapStates";

const AddedComponentKey = "addedComponentId";

@Component({
    selector: SystemPageSelector,
    templateUrl: "./system-page.component.html",
})
export class SystemPageComponent extends BaseRoutedComponent implements OnInit {
    public system$: Observable<SystemEntity | undefined>;

    public processMapStates: IMapStackFramesChangedEvent[] = [];
    public systemNotFound = false;
    public addedComponentId?: number;
    private systemEntityId$ = new ReplaySubject<number | undefined>(1);

    public constructor(
        injector: Injector,
        systemisationService: SystemisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        this.system$ = this.systemEntityId$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((systemEntityId) => systemisationService.getSystemByIdWithLayout(systemEntityId)),
            tap((system) => {
                if (!system) {
                    this.systemNotFound = true;
                }

                this.notifyActivated();
            }),
            catchError(() => ErrorPageRoute.gotoRoute().pipe(map(() => undefined))),
        );

        rxjsBreezeService.entityTypeChanged(SystemEntity).pipe(
            withLatestFrom(this.system$),
            filter(([changedSystem, currentSystem]) => changedSystem.systemEntityId === currentSystem?.systemEntityId &&
                changedSystem.entityAspect.entityState.isDetached()),
            switchMap(() => systemsPageRoute.gotoRoute()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public static encodeProcessMapStates(states: IMapStackFramesChangedEvent[]) {
        // can be called from link-process-step/map too
        return encodeURI(JSON.stringify(states));
    }

    public ngOnInit() {
        this.updateDataFromPageParams();
        this.navigationEnd.subscribe(() => this.updateDataFromPageParams());
    }

    private updateDataFromPageParams() {
        this.systemEntityId$.next(this.getRouteParamInt("systemEntityId"));

        const savedStates = this.getSearchParameterValue(ProcessMapStatesKey);
        if (savedStates) {
            this.processMapStates = JSON.parse(decodeURI(savedStates));
        }

        const addedComponentIdString = this.getSearchParameterValue(AddedComponentKey);
        if (addedComponentIdString) {
            this.addedComponentId = Number(addedComponentIdString);
            this.deleteSearchParameter(AddedComponentKey);
        }

        this.removeDefaultShellPadding();
    }

    public onProcessMapStatesChanged(processMapStates: IMapStackFramesChangedEvent[]) {
        this.processMapStates = processMapStates;
        this.deleteSearchParameter(ProcessMapStatesKey);
        if (this.processMapStates.length > 0) {
            this.setSearchParameterValue(ProcessMapStatesKey, SystemPageComponent.encodeProcessMapStates(this.processMapStates));
        }
    }
}
