import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { merge } from "rxjs";
import { take } from "rxjs/operators";
import { LayoutService } from "./layout.service";
import { LayoutColumnComponent } from "./layout-column/layout-column.component";
import { LayoutComponentComponent } from "./layout-component/layout-component.component";
import { LayoutManagerComponent } from "./layout-manager/layout-manager.component";

@Directive({
    selector: "[adaptLayoutComponentEnabled]",
})
export class LayoutComponentEnabledDirective<T = unknown> {
    public static ngTemplateGuard_adaptLayoutComponentEnabled: "binding";

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private layoutManager: LayoutManagerComponent,
        private layoutColumn: LayoutColumnComponent,
        private layoutComponent: LayoutComponentComponent,
    ) {
    }

    @Input()
    public set adaptLayoutComponentEnabled(condition: T) {
        this.layoutComponent.enabled = !!condition;

        if (condition && !this.hasView) {
            // pass thru input to context so you can do "(async$) as something", {{something}}
            this.viewContainer.createEmbeddedView(this.templateRef, {
                $implicit: condition,
                adaptLayoutComponentEnabled: condition,
            });
            this.hasView = true;

            this.layoutComponent.enabled = true;

            // make sure columns have been enabled/disabled before we check if we need to move the component
            // include component update in this in case update doesn't cause column update.
            merge(
                this.layoutColumn.updated$,
                this.layoutComponent.updated$,
            ).pipe(
                take(1),
            ).subscribe(() => {
                const desiredLayout = this.layoutManager.cloneLayout(true);
                const { component, column } = LayoutService.findComponentInLayout(desiredLayout.columns, this.layoutComponent.layoutEntity.id);

                // column is not enabled, we should move it to an enabled column instead
                if (column && (!column.options.enabled && !column.options.manuallyEnabled)) {
                    const enabledColumns = desiredLayout.columns.filter((col) => col.options.enabled);
                    const destColumn = enabledColumns[enabledColumns.length - 1];

                    // move within cloned layout, then reconcile
                    if (component && column) {
                        this.layoutManager.moveComponent(component, column, destColumn, 0);
                        this.layoutManager.reconcileAndUpdateLayout(desiredLayout).subscribe();
                    }
                }
            });
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
            this.layoutComponent.enabled = false;
        }
    }
}
