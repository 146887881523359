<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <adapt-edit-role-connections *ngIf="!!connection"
                                     [connection]="connection"
                                     (roleConnectionDeletion)="onDeletion($event)"></adapt-edit-role-connections>
        <div *ngIf="tier1RoleType || culturalLeadershipConfiguration"
             role="alert"
             class="alert alert-info mt-3">
            <span *ngIf="tier1RoleType">
                {{tier1RoleType.defaultLabel}} roles can only be updated in relevant Value Stream or Key Function.
            </span>
            <span *ngIf="culturalLeadershipConfiguration">
                {{culturalLeadershipConfiguration.primaryName}} roles can only be updated in Cultural Leadership Framework.
            </span>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
