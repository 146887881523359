import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap } from "rxjs/operators";
import { EditResilientBusinessGoalsDialogComponent } from "./edit-rb-goals-dialog/edit-rb-goals-dialog.component";
import { ResilientBusinessGoalsService } from "./rb-goals.service";

@Injectable({
    providedIn: "root",
})
export class ResilientBusinessGoalsUiService {
    public constructor(
        private resilientBusinessGoalsService: ResilientBusinessGoalsService,
        private dialogService: AdaptCommonDialogService,
    ) {
    }

    @Autobind
    public editResilientBusinessGoals() {
        return this.resilientBusinessGoalsService.getOrCreateResilientBusinessGoals()
            .pipe(
                switchMap((data) => this.dialogService.open(EditResilientBusinessGoalsDialogComponent, data)),
            );
    }
}
