import { Component, Injector, OnInit } from "@angular/core";
import { ValueStreamBusinessModel } from "@common/ADAPT.Common.Model/organisation/value-stream-business-model";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { lastValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../tier1-architecture.service";

@Component({
    selector: "adapt-value-stream-business-model-page",
    templateUrl: "./value-stream-business-model-page.component.html",
})
export class ValueStreamBusinessModelPageComponent extends BaseRoutedComponent implements OnInit {
    /* Public Properties */
    public canEdit = false;
    public businessModel?: ValueStreamBusinessModel;
    
    public isSynopsisEditorValid = true;
    public isPricingEditorValid = true;
    public isCustomerValueEditorValid = true;
    public isOrganisationValueEditorValid = true;

    public readonly editTier1Architecture = Tier1ArchitectureAuthService.EditTier1;

    /* Private Properties */
    private valueStreamId?: number;

    /* Sections */
    public synopsis = {
        editing: false,
        toggleEditing: this.synopsisToggleEditing,
        saveChanges: this.saveChanges(this.synopsisToggleEditing),
        discardChanges: this.discardChanges(this.synopsisToggleEditing),
    };

    public pricing = {
        editing: false,
        toggleEditing: this.pricingToggleEditing,
        saveChanges: this.saveChanges(this.pricingToggleEditing),
        discardChanges: this.discardChanges(this.pricingToggleEditing),
    };

    public customerValueProposition = {
        editing: false,
        toggleEditing: this.customerValuePropositionToggleEditing,
        saveChanges: this.saveChanges(this.customerValuePropositionToggleEditing),
        discardChanges: this.discardChanges(this.customerValuePropositionToggleEditing),
    };

    public organisationValueProposition = {
        editing: false,
        toggleEditing: this.organisationValuePropositionToggleEditing,
        saveChanges: this.saveChanges(this.organisationValuePropositionToggleEditing),
        discardChanges: this.discardChanges(this.organisationValuePropositionToggleEditing),
    };

    /* Constructor */
    constructor(
        private commonDataService: CommonDataService,
        private tier1ArchitectureService: Tier1ArchitectureService,
        injector: Injector,
    ) {
        super(injector);

        this.valueStreamId = this.getRouteParamInt("valueStreamId");
        if (!this.valueStreamId) {
            this.log.warn("Invalid Value Stream");
            this.tier1ArchitectureService.gotoDefaultRoute();
        }
    }

    public ngOnInit() {
        this.notifyActivated();

        this.setValueStreamBusinessModel();
    }

    /* Editing Methods */
    @Autobind
    public synopsisToggleEditing() {
        this.synopsis.editing = !this.synopsis.editing;
        this.canEdit = !this.canEdit;
    }

    @Autobind
    public pricingToggleEditing() {
        this.pricing.editing = !this.pricing.editing;
        this.canEdit = !this.canEdit;
    }

    @Autobind
    public customerValuePropositionToggleEditing() {
        this.customerValueProposition.editing = !this.customerValueProposition.editing;
        this.canEdit = !this.canEdit;
    }

    @Autobind
    public organisationValuePropositionToggleEditing() {
        this.organisationValueProposition.editing = !this.organisationValueProposition.editing;
        this.canEdit = !this.canEdit;
    }

    /* Public Methods */
    public hasChanges() {
        return this.commonDataService.hasChanges();
    }

    /* Private Methods */
    private async setValueStreamBusinessModel() {
        try {
            this.businessModel = await lastValueFrom(this.tier1ArchitectureService.getValueStreamBusinessModelForValueStreamId(this.valueStreamId!));
        } catch {
            this.log.warn("Invalid Value Stream Commercial Model");
            this.tier1ArchitectureService.gotoDefaultRoute();
        }
    }

    private saveChanges(callback: () => unknown) {
        return () => {
            return this.commonDataService.saveEntities(this.businessModel!)
                .pipe(
                    tap(() => callback()),
                );
        };
    }

    private discardChanges(callback: () => unknown) {
        return () => {
            return this.commonDataService.rejectChanges(this.businessModel!)
                .pipe(
                    tap(() => callback()),
                );
        };
    }
}
