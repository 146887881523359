import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexTimelinePageComponent } from "./ci-timeline-page.component";

const culturalIndexTimelinePageTitle = "Analyse Timeline";

export const culturalIndexTimelinePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-ci-timeline-page", CulturalIndexTimelinePageComponent)
    .atOrganisationUrl("/people/cultural-index/analyse-timeline")
    .redirectToThisRouteFromOrganisationUrl("/people/culturalIndex/analyseTimeline")
    .verifyingAccess(CulturalIndexAuthService.ReadQuantitativeCulturalIndex)
    .verifyingFeatures(FeatureName.CulturalNetworkCulturalIndex)
    .withTitle(culturalIndexTimelinePageTitle)
    .build();
