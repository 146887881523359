import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IDxListItemDeletedEvent, IDxListItemDeletingEvent } from "@common/ux/dx.types";
import { ItemRenderedEvent } from "devextreme/ui/list";
import { BehaviorSubject, forkJoin, lastValueFrom, of } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureService } from "../integrated-architecture.service";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkAuthService } from "../integrated-architecture-framework-auth.service";
import { IntegratedArchitectureUiService } from "../integrated-architecture-ui.service";

interface IGuidanceMaterialListEntry {
    guidanceMaterial: GuidanceMaterial;
    editable: boolean;
}

@Component({
    selector: "adapt-guidance-materials-page",
    templateUrl: "./guidance-materials-page.component.html",
    styleUrls: ["./guidance-materials-page.component.scss"],
})
export class GuidanceMaterialsPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public guidanceMaterials: IGuidanceMaterialListEntry[] = [];
    public selectedItems: IGuidanceMaterialListEntry[] = [];
    public updateData$ = new BehaviorSubject<void>(undefined);
    public allowEditing = false;
    public mergeGuidanceMaterialsDisabled = true;

    private mergeDisabledUpdater = this.createThrottledUpdater((disabled: boolean) => this.mergeGuidanceMaterialsDisabled = disabled);

    constructor(
        private architectureService: IntegratedArchitectureFrameworkService,
        private integratedArchUiService: IntegratedArchitectureUiService,
        private integratedArchService: IntegratedArchitectureService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.allowEditing = await this.architectureService.promiseToVerifyTier2EditAccess();

        this.updateData$.pipe(
            debounceTime(10),
            switchMap(() => this.archAuthService.primeAuthDataForGuidanceMaterials()),
            switchMap(() => this.integratedArchService.getGuidanceMaterials()),
            switchMap((guidanceMaterials) => {
                if (guidanceMaterials.length === 0) {
                    return of([] as IGuidanceMaterialListEntry[]);
                }
                return forkJoin(guidanceMaterials.map((gm) => this.canEditGuidanceMaterial(gm))).pipe(
                    map((canEdit) => guidanceMaterials.map((gm, idx) => {
                        return { guidanceMaterial: gm, editable: canEdit[idx] } as IGuidanceMaterialListEntry;
                    })),
                );
            }),
            this.takeUntilDestroyed(),
        ).subscribe((guidanceMaterials) => {
            this.guidanceMaterials = guidanceMaterials;
            this.selectedItems = [];
            this.notifyActivated();
        });
    }

    public onSelectionChanged() {
        this.mergeDisabledUpdater.next(this.selectedItems.length < 2);
    }

    public onSelectedItemsChange(items: IGuidanceMaterialListEntry[]) {
        // only add editable items to the selection
        this.selectedItems = items.filter((itm) => itm.editable);
    }

    public onItemRendered(item: ItemRenderedEvent) {
        if (!item.itemData.editable) {
            jQuery(item.itemElement).addClass("disable-list-item");
        }
    }

    public onItemDeleting(item: IDxListItemDeletingEvent<IGuidanceMaterialListEntry>) {
        const promise = lastValueFrom(this.integratedArchUiService.showDeleteGuidanceMaterialDialog(item.itemData!.guidanceMaterial));
        item.cancel = DxUtilities.isCancelledFromPromise(promise);
    }

    public onItemDeleted(item: IDxListItemDeletedEvent<IGuidanceMaterialListEntry>) {
        this.deleteGuidanceMaterial(item.itemData!.guidanceMaterial)
            .pipe(this.takeUntilDestroyed())
            .subscribe();
    }

    public canEditGuidanceMaterial(guidanceMaterial: GuidanceMaterial) {
        return this.archAuthService.personCanEditGuidanceMaterial(guidanceMaterial);
    }

    @Autobind
    public createGuidanceMaterial() {
        return this.integratedArchUiService.createGuidanceMaterial().pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public editGuidanceMaterial(guidanceMaterial: GuidanceMaterial) {
        return this.integratedArchUiService.editGuidanceMaterial(guidanceMaterial).pipe(
            tap(() => this.updateData$.next()),
        );
    }

    public deleteGuidanceMaterial(guidanceMaterial: GuidanceMaterial) {
        return this.integratedArchService.deleteGuidanceMaterial(guidanceMaterial).pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public mergeGuidanceMaterials() {
        return this.integratedArchUiService.mergeGuidanceMaterials(this.selectedItems.map(({ guidanceMaterial }) => guidanceMaterial)).pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public viewUsage(guidanceMaterial: GuidanceMaterial) {
        return this.integratedArchUiService.viewGuidanceMaterialUsage(guidanceMaterial);
    }
}
