import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { SystemsPageComponent, SystemsPageSelector } from "./systems-page.component";

export const systemsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent(SystemsPageSelector, SystemsPageComponent)
    .atOrganisationUrl("/systems")
    .redirectToThisRouteFromOrganisationUrl("/process-maps")
    .verifyingAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2)
    .withTitle("Systems")
    .withSearchKeyword("Task Map")
    .withSearchKeyword("Process Map")
    .withSearchKeyword("Diagram")
    .withSearchKeyword("Document")
    .withSearchKeyword("Text")
    .withSearchKeyword("Video")
    .reloadOnSearch(false)
    .build();
