import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { ICulturalMeasurementEntity } from "@common/ADAPT.Common.Model/organisation/culturalMeasurementEntity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { CulturalLeadershipFrameworkService } from "../cultural-leadership-framework.service";

@Component({
    selector: "adapt-cultural-measurement-meta",
    templateUrl: "./cultural-measurement-meta.component.html",
    styleUrls: ["./cultural-measurement-meta.component.scss"],
})
export class CulturalMeasurementMetaComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public culturalEntity!: ICulturalMeasurementEntity;
    @Input() public showCulturalLeaderData?: boolean;

    public culturalLeaderRelationships: CulturalRelationship[] = [];
    public configuration?: CulturalLeadership;

    constructor(
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.configuration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.culturalEntity) {
            const newValue = changes.culturalEntity.currentValue;
            const culturalLeaderRelationships = await new CulturalLeadershipQueryUtilities(this.commonDataService).promiseToGetCulturalRelationshipsForPersonId(newValue.person.personId, newValue.creationDate);
            this.culturalLeaderRelationships = culturalLeaderRelationships
                .filter((relationship) => relationship.isActiveAt(this.culturalEntity.creationDate) && !relationship.isSupporting);
        }
    }
}
