import { Injectable, Injector } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap, tap } from "rxjs";
import { ConfigureRoleDialogComponent, IConfigureRoleDialogData } from "../integrated-architecture/configure-role-dialog/configure-role-dialog.component";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture/integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkUiService } from "../integrated-architecture/integrated-architecture-framework-ui.service";

@Injectable({
    providedIn: "root",
})
export class RoleUiService extends BaseService {
    constructor(
        injector: Injector,
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
        private commonDialogService: AdaptCommonDialogService,
        public integratedArchitectureFrameworkUiService: IntegratedArchitectureFrameworkUiService,
    ) {
        super(injector);
    }

    public addRole(roleValues: Partial<Role> = {}) {
        return this.integratedArchitectureFrameworkService.createRole(roleValues).pipe(
            switchMap((role) => {
                const config: IConfigureRoleDialogData = {
                    role,
                    allowRoleConnectionTypeSelection: !roleValues.connectionType,
                };
                return this.editRoleForConfig(config);
            }),
            tap(() => this.integratedArchitectureFrameworkUiService.emitRefetchRequired()),
        );

    }

    public editRole(role: Role) {
        return this.editRoleForConfig({ role });
    }

    private editRoleForConfig(config: IConfigureRoleDialogData) {
        return this.commonDialogService.open(ConfigureRoleDialogComponent, config);
    }
}
