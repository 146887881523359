import { Component, ElementRef, OnInit } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ISelectPersonFilter } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { CulturalLeadershipConnectionStatusFilter } from "app/features/culture/cultural-leadership/cultural-leadership-connection-status-filter.enum";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CareerValuationService } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { CareerValuationFilterService } from "./career-valuation-filter.service";

@Component({
    selector: "adapt-career-valuation-filter",
    templateUrl: "./career-valuation-filter.component.html",
    styleUrls: ["./career-valuation-filter.component.scss"],
})
export class CareerValuationFilterComponent extends BaseComponent implements OnInit {
    public readonly allowedCLUserTypes = [UserType.Leader, UserType.Collaborator];
    public readonly CareerValuationFilterParamKeys = CulturalDataFilterParamKeys;

    public filterParameters: ICareerValuationAnalysisFilter;
    public hasAtLeastQuantitativePermissions: boolean;

    public cultureLeaderFilter?: ISelectPersonFilter;
    private culturalLeaders: Person[] = [];

    public actualItems = [
        { string: "25 %", number: 25 },
        { string: "50 %", number: 50 },
        { string: "60 %", number: 60 },
        { string: "70 %", number: 70 },
        { string: "80 %", number: 80 },
        { string: "90 %", number: 90 },
        { string: "100 %", number: 100 },
    ];

    public idealItems = [
        { string: "0", number: 0 },
        { string: "5", number: 5 },
        { string: "10", number: 10 },
        { string: "15", number: 15 },
        { string: "20", number: 20 },
        { string: "25", number: 25 },
        { string: "30", number: 30 },
        { string: "35", number: 35 },
    ];

    public connectionStatusOptions = [
        {
            label: "Inactive only",
            value: CulturalLeadershipConnectionStatusFilter.InactiveOnly,
        },
        {
            label: "Active only",
            value: CulturalLeadershipConnectionStatusFilter.ActiveOnly,
        },
        {
            label: "All",
            value: CulturalLeadershipConnectionStatusFilter.All,
        },
    ];

    public constructor(
        public elementRef: ElementRef,
        public careerValuationAuthService: CareerValuationAuthService,
        private careerValuationService: CareerValuationService,
        private responsiveService: ResponsiveService,
        public careerValuationFilterService: CareerValuationFilterService,
    ) {
        super(elementRef);
        this.hasAtLeastQuantitativePermissions = careerValuationAuthService.currentPersonHasAtLeastQuantitativeAccess();

        this.filterParameters = this.careerValuationFilterService.filter;

        this.careerValuationFilterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((filter) => {
            this.filterParameters = filter;
        });
    }

    public async ngOnInit() {
        const culturalRelationships = await this.careerValuationService.promiseToGetAllCulturalRelationships();
        this.setCulturalLeaders(culturalRelationships);

        await this.careerValuationFilterService.setFilterFromUrl();
    }

    public async onConnectionStatusChanged(e: IDxSelectBoxValueChangedEvent<CulturalLeadershipConnectionStatusFilter>) {
        if (e.value) {
            await this.careerValuationFilterService.emitFilterChange(CulturalDataFilterParamKeys.ConnectionStatus, e.value, e.value);
        }
    }

    public async onMaximumActualPercentageChanged(e: ValueChangedEvent) {
        const value = NumberUtilities.parseNumber(e.value);
        await this.careerValuationFilterService.emitFilterChange(CulturalDataFilterParamKeys.MaximumActualPercentage, value, value);
    }

    public async onMinimumIdealValueChanged(e: ValueChangedEvent) {
        const value = NumberUtilities.parseNumber(e.value);
        await this.careerValuationFilterService.emitFilterChange(CulturalDataFilterParamKeys.MinimumIdealValue, value, value);
    }

    @Autobind
    public isMobileSize() {
        return this.responsiveService.currentBreakpoint.isMobileSize;
    }

    private setCulturalLeaders(culturalRelationships: CulturalRelationship[]) {
        culturalRelationships.forEach((cr) => this.setCulturalLeader(cr));
        this.cultureLeaderFilter = this.filterCulturalLeader;
    }

    private setCulturalLeader(culturalRelationship: CulturalRelationship) {
        if (this.culturalLeaders.indexOf(culturalRelationship.culturalLeader) < 0) {
            this.culturalLeaders.push(culturalRelationship.culturalLeader);
        }
    }

    @Autobind
    private filterCulturalLeader(person: Person) {
        return this.culturalLeaders.some((cl: Person) => cl.personId === person.personId);
    }
}
