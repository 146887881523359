<adapt-dialog [maxWidth]="850"
              [minimal]="true">
    <ng-container adapt-dialog-content>
        <div class="row flex-lg-nowrap m-0 h-100">
            <div class="column left-column d-flex h-100 col-md-8 col-lg-6 col p-5">
                <div class="w-100">
                    <h2 data-test="tour-title">{{ dialogData.title }}</h2>
                    <div class="mt-4"
                         [froalaView]="dialogData.content"></div>
                    <div class="mt-4 d-flex justify-content-between">
                        <button adaptButtonIcon="fal fa-play-circle"
                                class="btn btn-primary"
                                data-test="tour-watch"
                                (click)="watchTutorial()">
                            Watch video
                        </button>
                    </div>
                </div>
            </div>
            <div class="column right-column col-md-4 col-lg-6 d-none d-md-flex"
                 [ngStyle]="imageStyle">
            </div>
            <button class="btn-close floating-close"
                    data-test="tour-close"
                    (click)="close()"></button>
        </div>
    </ng-container>
</adapt-dialog>
