import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { provideOrganisationConfigurationPageRoute, providePersonalConfigurationPageRoute, provideTeamConfigurationPageRoute } from "@common/page-route-providers";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptIconModule } from "@common/ux/icon/icon.module";
import { AdaptIncompleteWorkModule } from "@common/ux/incomplete-work/incomplete-work.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptCommonConfigurationModule } from "@org-common/lib/configuration/configuration.module";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptEmailModule } from "@org-common/lib/email/email.module";
import { AdaptEulaModule } from "@org-common/lib/eula/eula.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptUserManagementSharedModule } from "@org-common/lib/user-management/user-management-shared.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptAppOrganisationModule } from "app/organisation/app.organisation.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxListModule, DxNumberBoxModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from "devextreme-angular";
import { ApiTokenDialogComponent } from "./organisation/api-token-dialog/api-token-dialog.component";
import { ConfigureApiComponent } from "./organisation/configure-api/configure-api.component";
import { ConfigureCulturalIndexComponent } from "./organisation/configure-ci/configure-ci.component";
import { ConfigureCulturalLeadershipComponent } from "./organisation/configure-cultural-leadership/configure-cultural-leadership.component";
import { ConfigureCustomWelcomeEmailComponent } from "./organisation/configure-custom-welcome-email/configure-custom-welcome-email.component";
import { ConfigureCareerValuationComponent } from "./organisation/configure-cvt/configure-cvt.component";
import { ConfigurePeerCatchUpComponent } from "./organisation/configure-peer-catch-up/configure-peer-catch-up.component";
import { ConfigureStakeholdersComponent } from "./organisation/configure-stakeholders/configure-stakeholders.component";
import { ConfigureTier1Component } from "./organisation/configure-tier1/configure-tier1.component";
import { ConfigureTier2Component } from "./organisation/configure-tier2/configure-tier2.component";
import { OrganisationConfigurationPageComponent } from "./organisation/organisation-configuration-page/organisation-configuration-page.component";
import { organisationConfigurationPageRoute } from "./organisation/organisation-configuration-page/organisation-configuration-page.route";
import { PersonalConfigurationPageComponent } from "./personal/personal-configuration-page/personal-configuration-page.component";
import { personalConfigurationPageRoute } from "./personal/personal-configuration-page/personal-configuration-page.route";
import { ConfigureTeamPeerCatchUpComponent } from "./team/configure-team-peer-catch-up.component/configure-team-peer-catch-up.component";
import { TeamConfigurationPageComponent } from "./team/team-configuration-page/team-configuration-page.component";
import { teamConfigurationPageRoute } from "./team/team-configuration-page/team-configuration-page.route";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DxListModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxScrollViewModule,
        DxTextAreaModule,
        DxRadioGroupModule,
        DxDateBoxModule,
        DxAccordionModule,
        DxDataGridModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptTooltipModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptStorageModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonDialogModule,
        AdaptSurveyModule,
        AdaptTeamsModule,
        AdaptFeaturesModule,
        AdaptDirectorySharedModule,
        AdaptIncompleteWorkModule,
        AdaptAppOrganisationModule,
        AdaptIconModule,
        AdaptTwoColumnModule,
        AdaptEmbedShellModule,
        AdaptAuthorisationModule,
        AdaptOrganisationSharedModule,
        AdaptCommonDialogModule,
        AdaptPaymentProcessingModule,
        AdaptEulaModule,
        AdaptCallToActionModule,
        AdaptLabellingModule,
        AdaptTeamDashboardSharedModule,
        AdaptEmailModule,
        AdaptCommonConfigurationModule,
        AdaptDashboardElementModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptLinkDisabledModule,
        AdaptUserManagementSharedModule,
        AdaptToolbarModule,
    ],
    declarations: [
        OrganisationConfigurationPageComponent,
        ConfigureCulturalIndexComponent,
        ConfigureCareerValuationComponent,
        ConfigurePeerCatchUpComponent,
        ConfigureCulturalLeadershipComponent,
        ConfigureStakeholdersComponent,
        ConfigureTier1Component,
        ConfigureTier2Component,
        TeamConfigurationPageComponent,
        ConfigureTeamPeerCatchUpComponent,
        PersonalConfigurationPageComponent,
        ConfigureCustomWelcomeEmailComponent,
        ConfigureApiComponent,
        ApiTokenDialogComponent,
    ],
    exports: [
        ConfigureTeamPeerCatchUpComponent,
        ConfigureTier2Component,
    ],
    providers: [
        provideTeamConfigurationPageRoute(teamConfigurationPageRoute),
        providePersonalConfigurationPageRoute(personalConfigurationPageRoute),
        provideOrganisationConfigurationPageRoute(organisationConfigurationPageRoute),
    ],
})
export class AdaptConfigurationModule {
    public static readonly Routes = [
        ...organisationConfigurationPageRoute.routes,
        ...personalConfigurationPageRoute.routes,
        ...teamConfigurationPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        ConfigurationAuthService.registerAccess(authorisationService);
    }
}
