<div *adaptLoading="isBusy">
    <dx-chart class="chart-wrapper mb-3"
              *ngIf="culturalIndicesChartData.length"
              [dataSource]="culturalIndicesChartData"
              [pathModified]="true"
              (onInitialized)="onChartInitialized($event)"
              [customizePoint]="customiseChartPoint">
        <dxo-argument-axis type="discrete">
            <dxo-grid [opacity]="0.2"
                      [visible]="true">
            </dxo-grid>
            <dxo-minor-grid [visible]="false">
            </dxo-minor-grid>
            <dxo-label [visible]="false">
            </dxo-label>
        </dxo-argument-axis>
        <dxo-value-axis [maxValueMargin]="0.001"
                        [tickInterval]="0.5"
                        visualRangeUpdateMode="keep"
                        [minorTickInterval]="0.1"
                        [visible]="true"
                        [visualRange]="[0,2]">
            <dxo-minor-tick [visible]="true">
            </dxo-minor-tick>
            <dxo-label [visible]="false">
            </dxo-label>
        </dxo-value-axis>
        <dxi-series type="line"
                    valueField="value"
                    argumentField="creationDate"
                    tagField="culturalIndex"
                    [width]="0.5">
        </dxi-series>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip">
        </dxo-tooltip>
        <dxo-legend [visible]="false">
        </dxo-legend>
        <dxo-zoom-and-pan argumentAxis="both">
        </dxo-zoom-and-pan>
    </dx-chart>

    <dx-data-grid [dataSource]="culturalIndices"
                  [wordWrapEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  [hoverStateEnabled]="true"
                  noDataText="No Cultural Index recorded"
                  [showRowLines]="true"
                  [showBorders]="true"
                  itemTemplate="notesTemplate"
                  (onInitialized)="onGridInitialized($event)">
        <dxo-pager [showPageSizeSelector]="true">
        </dxo-pager>

        <dxo-paging [pageSize]="5">
        </dxo-paging>

        <dxo-export [enabled]="true"
                    fileName="Cultural Index history">
        </dxo-export>

        <div *dxTemplate="let item of 'notesTemplate'">
            <div [froalaView]="item.data.notes"></div>
        </div>

        <dxi-column caption="Date"
                    dataField="creationDate"
                    dataType="date"
                    [format]="DateFormats.globalize.short"
                    sortOrder="desc"
                    [sortIndex]="0"
                    [width]="100">
        </dxi-column>

        <dxi-column caption="Cultural Index"
                    dataField="value"
                    dataType="number"
                    [customizeText]="customiseColumnIndexGridText"
                    [cellTemplate]="culturalIndexCellTemplate"
                    [width]="145">
        </dxi-column>

        <dxi-column dataField="direction"
                    dataType="string"
                    [width]="110">
            <dxo-lookup [dataSource]="culturalIndexService.directions"
                        valueExpr="id"
                        displayExpr="label"></dxo-lookup>
        </dxi-column>

        <dxi-column *ngIf="showNotes"
                    dataField="notes"
                    dataType="string"
                    cellTemplate="notesTemplate">
        </dxi-column>
    </dx-data-grid>
</div>
