import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { SystemPageComponent, SystemPageSelector } from "./system-page.component";

export const systemPageRoute = new OrganisationPageRouteBuilder<{ systemEntityId: number }>()
    .usingNgComponent(SystemPageSelector, SystemPageComponent)
    .atOrganisationUrl("/systems/:systemEntityId")
    .verifyingFeatures(FeatureName.ArchitectureTier2)
    .verifyingAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2)
    .withTitle("System")
    .reloadOnSearch(false)
    .build();
