import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { PersonProfilePageComponent } from "./person-profile-page.component";

export const myPersonProfilePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-person-profile-page", PersonProfilePageComponent)
    .atOrganisationUrl("/people/person/profile")
    .redirectToThisRouteFromOrganisationUrl("/people/person")
    .reloadOnSearch(false)
    .withTitle("Profile")
    .verifyingAccess("readAtLeastOneProfile")
    .withHelp(StandardIntercomSearchTerm.PersonalProfile)
    .build();

export const personProfilePageRoute = new OrganisationPageRouteBuilder<{ personId: number }>()
    .usingNgComponent("adapt-person-profile-page", PersonProfilePageComponent)
    .atOrganisationUrl("/people/person/profile/:personId")
    // cannot redirect here anymore as that will match /people/person/profile
    //    .redirectToThisRouteFromOrganisationUrl("/people/person/:personId")
    .reloadOnSearch(false)
    .withTitle("Profile")
    .verifyingAccess("readAtLeastOneProfile")
    .withHelp(StandardIntercomSearchTerm.PersonalProfile)
    .build();
