import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { lastValueFrom } from "rxjs";
import { IRolePermissionChanges } from "../configure-role-access/role-permission-changes.interface";

export interface IConfigureAccessLevelDialogData {
    role: Role;
    changedEntities?: IBreezeEntity[];
}

interface ITabItem {
    title: string;
    template: string;
    key?: string;
}

@Component({
    selector: "adapt-configure-access-level-dialog",
    templateUrl: "./configure-access-level-dialog.component.html",
    styleUrls: ["./configure-access-level-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigureAccessLevelDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IConfigureAccessLevelDialogData> implements OnInit {
    public readonly dialogName = "ConfigureAccessLevelDialog";
    public title!: string;

    public readonly items: ITabItem[] = [];

    public roleLabelChanged = false;

    public activeRoleConnections: RoleConnection[] = [];

    public isSystemAllocatedRole = false;
    public isRoleAccessReadOnly = false;

    public potentialPerson?: Person;

    public changes: IRolePermissionChanges = {
        addedPermissions: [],
        deletedPermissions: [],
    };

    private roleConnectionsChanged: RoleConnection[] = [];

    @ViewChild(SelectPersonComponent) public selectPersonComponent?: SelectPersonComponent;

    public get entitiesToConfirm() {
        return this.getChangedEntities();
    }

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IConfigureAccessLevelDialogData,
        protected commonDataService: CommonDataService,
        private authorisationService: AuthorisationService,
        private userManagementService: UserManagementService,
        private navigationHierarchyService: NavigationHierarchyService,
    ) {
        super();
    }

    public async ngOnInit() {
        const isEditableCoachAccessRole = this.data.role.extensions.isCoachAccessRole()
            && (await this.authorisationService.promiseToCheckIsStakeholderManager());

        this.isSystemAllocatedRole = this.data.role.extensions.isSystemAllocatedRole();
        this.isRoleAccessReadOnly = this.data.role.extensions.isViewerAccessRole()
            || this.data.role.extensions.isCollaboratorAccessRole()
            || (this.isSystemAllocatedRole && !isEditableCoachAccessRole);

        this.activeRoleConnections = this.data.role.roleConnections.filter((rc) => rc.isActive());

        this.updateTitle();
        await this.setupTabs();
    }

    public updateTitle() {
        const isAdding = this.data.role.roleId < 0 && !this.data.changedEntities;
        if (isAdding) {
            this.title = "Add ";
        } else {
            this.title = "Configure ";
        }

        this.title += "access level: ";
        this.title += this.data.role.label;
    }

    private async setupTabs() {
        const canManageRoles = await this.authorisationService.promiseToGetHasAccess(DirectoryAuthService.ManageRoles);
        const canManageAccess = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ManageAccess);

        this.items.push({
            title: "Name",
            template: "detailTemplate",
        });

        if (this.isSystemAllocatedRole || this.isRoleAccessReadOnly || !canManageRoles) {
            this.items.push({
                title: "People",
                template: "peopleTemplate",
            });
        } else {
            this.items.push({
                title: "People",
                template: "editPeopleTemplate",
            });
        }

        if (canManageAccess && (!this.isSystemAllocatedRole || this.data.role.extensions.isCoachAccessRole() || this.data.role.extensions.isCulturalLeaderRole())) {
            this.items.push({
                title: "Permissions",
                template: "permissionsTemplate",
            });
        }
    }

    @Autobind
    public personFilter(person: Person) {
        const activeLeaderConnection = person.getActiveConnections().find((c) => c.userType === UserType.Leader);
        if (!activeLeaderConnection) {
            return false;
        }

        return !this.data.role.roleConnections
            .map((rc) => rc.connection.person)
            .includes(person);
    }

    public async newPersonChanged(person?: Person) {
        if (!person) {
            return;
        }

        // if this is just a re-add of an existing person (e.g. delete 'bill', add 'bill'), then we can simply revert the first delete
        const personConnection = person.getLatestConnection()!;
        let roleConnection = this.roleConnectionsChanged.find((rc) => rc.connectionId === personConnection.connectionId);
        if (roleConnection) {
            roleConnection.entityAspect.rejectChanges();
            ArrayUtilities.removeElementFromArray(roleConnection, this.roleConnectionsChanged);
        } else {
            const rcData: Partial<RoleConnection> = {
                role: this.data.role,
                startDate: new Date(),
                connection: person?.getActiveConnections()[0],
            };

            roleConnection = await lastValueFrom(this.commonDataService.create(RoleConnectionBreezeModel, rcData));
            this.roleConnectionsChanged.push(roleConnection);
        }

        ArrayUtilities.addElementIfNotAlreadyExists(this.activeRoleConnections, roleConnection);

        this.resetSelectPerson();
    }

    public onRoleLabelChanged() {
        this.roleLabelChanged = true;
        this.updateTitle();
    }

    public permissionRemoved(roleConnection: RoleConnection) {
        this.commonDataService.remove(roleConnection).subscribe(() => {
            ArrayUtilities.removeElementFromArray(roleConnection, this.activeRoleConnections);

            if (roleConnection.entityAspect.entityState.isDetached()) {
                ArrayUtilities.removeElementFromArray(roleConnection, this.roleConnectionsChanged);
            } else {
                this.roleConnectionsChanged.push(roleConnection);
            }

            this.resetSelectPerson();
        });
    }

    public async ok() {
        try {
            const changedEntities = this.getChangedEntities();

            for (const addedPermission of this.changes.addedPermissions) {
                const entity = await this.promiseToCreateRoleFeaturePermission(this.data.role, addedPermission);
                if (entity) {
                    changedEntities.push(entity);
                }
            }

            for (const deletedPermission of this.changes.deletedPermissions) {
                const entity = await lastValueFrom(this.commonDataService.remove(
                    this.userManagementService.getRoleFeaturePermission(this.data.role, deletedPermission)!));
                if (entity) {
                    changedEntities.push(entity);
                }
            }

            await lastValueFrom(this.commonDataService.saveEntities(changedEntities));

            if (this.roleLabelChanged) {
                this.navigationHierarchyService.updateActiveNodeFromUrlIfRouteParamMatchesValue("roleId", this.data.role.roleId);
            }

            super.resolve(this.data);
        } catch (e) {
            this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
        }
    }

    private getChangedEntities(): IBreezeEntity[] {
        let changedEntities: IBreezeEntity[] = [this.data.role];

        changedEntities = changedEntities.filter((e) => !!e);

        // can also be additional role permissions copied but unsaved, passed into this dialog for further editing before saving
        if (Array.isArray(this.data.changedEntities)) {
            changedEntities = changedEntities.concat(this.data.changedEntities);
        }

        // role connection changes (edit people tab)
        this.roleConnectionsChanged = this.roleConnectionsChanged.filter((roleConnection) => {
            if (!roleConnection.roleId || !roleConnection.connectionId) {
                // incomplete - discard
                if (!roleConnection.entityAspect.entityState.isDetached()) {
                    // check to ensure previously rejected entity is not rejected again
                    roleConnection.entityAspect.rejectChanges();
                }
                return false;
            } else {
                return true;
            }
        });
        changedEntities = changedEntities.concat(this.roleConnectionsChanged);

        changedEntities = ArrayUtilities.distinct(changedEntities);

        return changedEntities;
    }

    private promiseToCreateRoleFeaturePermission(role: Role, featurePermission: FeaturePermission) {
        const values = {
            roleId: role.roleId,
            featurePermissionId: featurePermission.featurePermissionId,
        };

        return lastValueFrom(this.commonDataService.create(RoleFeaturePermissionBreezeModel, values));
    }

    private resetSelectPerson() {
        this.potentialPerson = undefined;
        this.selectPersonComponent?.reset();
    }
}
