import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { AnalyseCareerValuationPageComponent } from "./analyse-cvt-page.component";

const AnalyseCareerValuationDifferentialsPageTitle = "Analyse Valuations";

export const analyseCareerValuationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-cvt-page", AnalyseCareerValuationPageComponent)
    .atOrganisationUrl("/people/career-valuation/analyse")
    .redirectToThisRouteFromOrganisationUrl("/people/careerValuation/analyse")
    .verifyingAccess(CareerValuationAuthService.OpenCareerValuationAnalysis)
    .verifyingFeatures(FeatureName.CulturalNetworkCareerValuation)
    .validatesOrganisation()
    .withTitle(AnalyseCareerValuationDifferentialsPageTitle)
    .requiresLogin(true)
    .build();
