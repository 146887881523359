<adapt-dialog [maxWidth]="640">
    <h3 adapt-dialog-title>Copying {{processStep.extensions.isRoleTask ? 'role task' : 'process step'}}...</h3>

    <div adapt-dialog-content>
        <div class="mb-3">
            Copy <adapt-link-process-step [processStep]="processStep"></adapt-link-process-step> to:
        </div>

        <adapt-select-process-map [(processMap)]="selectedProcessMap"
                                  [disabled]="!isSelecting || isSaving"
                                  [allowCreate]="true"
                                  [onlyEditableSystems]="true"
                                  (entitiesChange)="changedEntities = $event"
                                  [selectedSystem]="existingSystemComponent?.system"
                                  [defaultNewMapName]="processStep.name"
                                  data-test="select-process-map-input"></adapt-select-process-map>

        <div *ngIf="selectedProcessMap && !isCreatingProcessMap"
             class="mt-3">
            <ng-container *adaptLoadingUntilFirstEmit="let childSteps of childSteps$">
                <p>
                    <adapt-link-process-step [processStep]="processStep"></adapt-link-process-step> will be copied
                    to <adapt-link-process-map [processMap]="selectedProcessMap"></adapt-link-process-map>.
                </p>

                <div *ngIf="childSteps.length > 0"
                     class="child-steps-list-container">
                    By copying this step, you will also be copying the following children (and any further descendants):
                    <ul>
                        <li *ngFor="let step of childSteps">
                            <adapt-link-process-step [processStep]="step"></adapt-link-process-step>
                        </li>
                    </ul>
                </div>
            </ng-container>
            <div *adaptLoading="isSaving"></div>
        </div>
    </div>

    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="ms-auto">
        <ng-container *ngIf="isSelecting; else postMoveTemplate">
            <button adaptButton="copy"
                    [disabled]="!selectedProcessMap || isCreatingProcessMap"
                    [adaptBlockingClick]="copyAndSave"
                    data-test="copy-process-map-button"></button>
            <button adaptButton="cancel"
                    (click)="cancel()"></button>
        </ng-container>
        <ng-template #postMoveTemplate>
            <button adaptButtonIcon="fal fa-sign-out-alt"
                    class="btn btn-success"
                    [adaptBlockingClick]="postCopy"
                    [adaptBlockingClickParam]="PostCopyAction.GoToNewLocation"
                    [unsubscribeOnDestroy]="false"
                    data-test="new-location-button">Go to new location</button>
            <button adaptButtonIcon="fal fa-door-closed"
                    [adaptBlockingClick]="postCopy"
                    [adaptBlockingClickParam]="PostCopyAction.StayHere">Stay here</button>
        </ng-template>
    </div>
</adapt-dialog>
