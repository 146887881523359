import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptProcessMapSharedModule } from "app/features/architecture/process-map/process-map-shared.module";
import { AdaptCulturalLeadershipModule } from "app/features/culture/cultural-leadership/cultural-leadership.module";
import { AdaptCareerValuationSharedModule } from "app/features/people/career-valuation/career-valuation-shared.module";
import { AdaptCulturalIndexModule } from "app/features/people/cultural-index/cultural-index.module";
import { AdaptPeerCatchupModule } from "app/features/people/peer-catchup/peer-catchup.module";
import { AdaptPersonModule } from "app/features/people/person/person.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptActivityFeedModule } from "app/general/activity-feed/activity-feed.module";
import { AdaptAppOrganisationModule } from "app/organisation/app.organisation.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxListModule } from "devextreme-angular";
import { AdaptOrganisationMapModule } from "../../features/architecture/organisation-map/organisation-map.module";
import { PersonalDashboardPageComponent } from "./personal-dashboard-page/personal-dashboard-page.component";
import { personalDashboardPageRoute } from "./personal-dashboard-page/personal-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptDirectorySharedModule,
        AdaptActivityFeedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptFeaturesModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptProcessMapSharedModule,
        AdaptOrganisationMapModule,
        AdaptGuidingPhilosophyModule,
        AdaptValuesConstitutionModule,
        AdaptEmbedShellModule,
        AdaptPeerCatchupModule,
        AdaptSurveyModule,
        AdaptTeamsModule,
        AdaptObjectivesModule,
        AdaptCommonMeetingsModule,
        AdaptAppOrganisationModule,
        DxListModule,
        AdaptDateModule,
        AdaptCulturalLeadershipModule,
        AdaptTwoColumnModule,
        AdaptPersonModule,
        AdaptCareerValuationSharedModule,
        AdaptCulturalIndexModule,
        AdaptKanbanModule,
        AdaptDashboardElementModule,
        AdaptTooltipModule,
    ],
    declarations: [
        PersonalDashboardPageComponent,
    ],
})

export class AdaptPersonalDashboardModule {
    public static readonly Routes = [
        ...personalDashboardPageRoute.routes,
    ];
}
