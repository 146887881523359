import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IActivityItem } from "../activity-item.interface";
import { ActivityElement } from "./activity-element";

export class ActivityInstance extends ActivityElement {
    public readonly dateTime: Date;

    public constructor(
        private activityItem: IActivityItem,
        public readonly relatedPerson?: Person,
    ) {
        super();

        const relatedPersonId = relatedPerson && relatedPerson.personId;
        if ((activityItem.relatedPersonId || relatedPersonId) && (activityItem.relatedPersonId !== relatedPersonId)) {
            throw new Error(`RelatedPersonIds do not match`);
        }

        this.dateTime = new Date(activityItem.dateTime);
    }

    public get text() {
        return this.activityItem.text;
    }

    public get subtext() {
        return this.activityItem.subtext;
    }

    public get launchPath() {
        return this.activityItem.launchPath;
    }
}
