import { Component, Inject } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocument } from "@common/ADAPT.Common.Model/organisation/system-document";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

export interface IEditVideoDocumentDialogData {
    systemDocument: SystemDocument,
    systemComponent: SystemComponent,
}

@Component({
    selector: "adapt-edit-video-document-dialog",
    templateUrl: "./edit-video-document-dialog.component.html",
})
@PersistableDialog("EditVideoDocumentDialog")
export class EditVideoDocumentDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditVideoDocumentDialogData> {
    public readonly dialogName = "EditVideoDocument";

    public title: string;
    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditVideoDocumentDialogData,
    ) {
        super();
        this.autoResolveData = this.data;
        this.title = this.data.systemDocument.entityAspect.entityState.isAdded()
            ? "Add video document"
            : "Edit video document";

        this.entitiesToConfirm = [
            this.data.systemDocument,
            this.data.systemComponent,
        ];
    }

    public get saveIsDisabled() {
        return this.entitiesAreUnmodifiedOrInvalid
            || !this.data.systemDocument.content;
    }

    public onNameChange(name: string) {
        this.data.systemComponent.name = name;
        this.data.systemComponent.extensions.validateComponentName();
    }

    public onUrlChanged(url: string) {
        if (!url) {
            this.data.systemDocument.content = "";
        } else {
            const video = UrlUtilities.getVideoEmbedDetails(url);
            if (video) {
                this.data.systemDocument.content = `<span class="fr-video fr-deletable fr-rv fr-dvb fr-draggable" contenteditable="false" draggable="true">${video.frame_html}</span>`;
            } else {
                this.data.systemDocument.content = "";
            }
        }
    }
}
