import { Component, ElementRef, Injector, OnInit, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionRole } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { KeyFunctionSupplementaryData } from "@common/ADAPT.Common.Model/organisation/key-function-supplementary-data";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { cacheLatest, emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseScrollPersistingRoutedComponent } from "@common/ux/base-scroll-persisting-routed.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CallToActionText } from "@org-common/lib/call-to-action/call-to-action-text";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { organisationMapPageRoute } from "app/organisation/organisation-map-page/organisation-map-page.route";
import { Observable, ReplaySubject } from "rxjs";
import { filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkAuthService } from "../../integrated-architecture/integrated-architecture-framework-auth.service";
import { DisplayRoleAllocationComponent } from "../../role/display-role-allocation/display-role-allocation.component";
import { KeyFunctionsUiService } from "../key-functions-ui.service";

@Component({
    selector: "adapt-key-function-page",
    templateUrl: "./key-function-page.component.html",
    styleUrls: ["./key-function-page.component.scss"],
})
export class KeyFunctionPageComponent extends BaseScrollPersistingRoutedComponent implements OnInit {
    public keyFunctionId$ = new ReplaySubject<number | undefined>(1);

    public keyFunction$: Observable<KeyFunction>;
    public supplementaryData$: Observable<KeyFunctionSupplementaryData | undefined>;
    public keyFunctionRoles$: Observable<KeyFunctionRole[]>;
    public currentKeyFunction?: KeyFunction;

    public ReadTier2AccessVerifier = IntegratedArchitectureFrameworkAuthService.ReadTier2;

    public canEditTier1 = false;
    public notFound = false;
    public CallToActionText = CallToActionText;

    @ViewChild("leaderRoleAllocation") private leaderRoleAllocation?: DisplayRoleAllocationComponent;

    public constructor(
        elementRef: ElementRef,
        injector: Injector,
        keyFunctionsService: KeyFunctionsService,
        private keyFunctionsUiService: KeyFunctionsUiService,
        private rxjsBreezeService: RxjsBreezeService,
        private authService: AuthorisationService,
        labellingService: LabellingService,
    ) {
        super(injector, elementRef);

        this.keyFunction$ = this.keyFunctionId$.pipe(
            emptyIfUndefinedOrNull(() => this.setKeyFunctionNotFound()),
            // prime label
            switchMap((keyFunctionId) => labellingService.getLabelLocationsForKeyFunction(keyFunctionId).pipe(
                map(() => keyFunctionId),
            )),
            switchMap((keyFunction) => keyFunctionsService.getPrimedKeyFunction(keyFunction)),
            tap((keyFunction) => this.currentKeyFunction = keyFunction),
            switchMap((keyFunction) => this.keyFunctionLeaderRoleShouldBeUpdated(keyFunction!)),
            emptyIfUndefinedOrNull(() => this.setKeyFunctionNotFound()),
            tap(() => this.notifyActivated()),
            cacheLatest(),
        );
        this.supplementaryData$ = this.keyFunction$.pipe(
            switchMap((keyFunction) => this.keyFunctionSupplementaryDataShouldBeFetched(keyFunction)),
            switchMap((keyFunction) => keyFunctionsService.getSupplementaryData(keyFunction)),
            cacheLatest(),
        );

        this.keyFunctionRoles$ = this.keyFunction$.pipe(
            switchMap((keyFunction) => this.keyFunctionRolesShouldBeFetched(keyFunction)),
            switchMap((keyFunction) => keyFunctionsService.getKeyFunctionRoles(keyFunction)),
        );
    }

    public async ngOnInit() {
        const keyFunctionId = this.getRouteParamInt("keyFunctionId");
        this.rememberScrollPosition(`KeyFunctionPage-${keyFunctionId}`);
        this.blockScrollPositionRestore();  // need to wait for the system to load first before changing the scroll position

        this.keyFunctionId$.next(keyFunctionId);

        this.canEditTier1 = await this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.EditTier1);
    }

    @Autobind
    public editKeyFunction(keyFunction: KeyFunction) {
        return this.keyFunctionsUiService.editKeyFunction(keyFunction)
            .pipe(tap(() => this.leaderRoleAllocation?.roleConnectionsUpdated()));
    }

    @Autobind
    public deleteKeyFunction(keyFunction: KeyFunction) {
        return this.keyFunctionsUiService.deleteKeyFunction(keyFunction).pipe(
            switchMap(() => organisationMapPageRoute.gotoRoute()),
        );
    }

    public onSystemsInitialised() {
        this.unblockScrollPositionRestore();
    }

    private setKeyFunctionNotFound() {
        this.notFound = true;
        this.notifyActivated();
    }

    private keyFunctionLeaderRoleShouldBeUpdated(keyFunction: KeyFunction) {
        return this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            filter((rc) => rc.roleId === keyFunction.leaderRoleId),
            map(() => keyFunction),
            startWith(keyFunction),
        );
    }

    private keyFunctionRolesShouldBeFetched(keyFunction: KeyFunction) {
        return this.rxjsBreezeService.entityTypeChanged(KeyFunctionRole).pipe(
            filter((kfr) => kfr.keyFunctionId === keyFunction.keyFunctionId),
            map(() => keyFunction),
            startWith(keyFunction),
        );
    }

    private keyFunctionSupplementaryDataShouldBeFetched(keyFunction: KeyFunction) {
        return this.rxjsBreezeService.entityTypeChanged(KeyFunctionSupplementaryData).pipe(
            filter((suppData) => suppData.keyFunctionId === keyFunction.keyFunctionId),
            map(() => keyFunction),
            startWith(keyFunction),
        );
    }
}
