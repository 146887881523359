import { Component, ElementRef, Injector, OnInit, ViewChild } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseScrollPersistingRoutedComponent } from "@common/ux/base-scroll-persisting-routed.component";
import { LayoutManagerComponent } from "@common/ux/layout/layout-manager/layout-manager.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { BehaviorSubject, lastValueFrom, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkService } from "../../integrated-architecture/integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkAuthService } from "../../integrated-architecture/integrated-architecture-framework-auth.service";
import { IntegratedArchitectureFrameworkUiService } from "../../integrated-architecture/integrated-architecture-framework-ui.service";
import { RoleActions } from "../../integrated-architecture/role-actions/role-actions.enum";
import { rolesPageRoute } from "../../integrated-architecture/roles-page/roles-page.route";
import { RoleUiService } from "../role-ui.service";

@Component({
    selector: "adapt-role-description-page",
    styleUrls: ["./role-description-page.component.scss"],
    templateUrl: "./role-description-page.component.html",
})
export class RoleDescriptionPageComponent extends BaseScrollPersistingRoutedComponent implements OnInit {
    public role?: Role;

    public guidance = this.integratedArchitectureFrameworkService.roleGuidance;

    public roleActions = RoleActions;
    public canEdit = false;
    public canDelete = false;
    public canDeactivate = false;
    public canReactivate = false;

    // when this value is set/changed, the layout will refresh.
    // we need this so the layout can update when the supp data changes.
    public layoutRefresh = new BehaviorSubject<void>(void 0);

    // view child component is set after template check causing ExpressionChangedAfterItHasBeenCheckedError
    //@ViewChild(LayoutManagerComponent) public lm?: LayoutManagerComponent;
    public lm?: LayoutManagerComponent;
    private layoutManagerUpdater = this.createThrottledUpdater((value?: LayoutManagerComponent) => this.lm = value);

    @ViewChild(LayoutManagerComponent) public set layoutManager(value: LayoutManagerComponent | undefined) {
        this.layoutManagerUpdater.next(value);
    }

    private roleLabelLocationSubscription?: Subscription;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
        private integratedArchitectureFrameworkUiService: IntegratedArchitectureFrameworkUiService,
        private integratedArchAuthService: IntegratedArchitectureFrameworkAuthService,
        private roleUiService: RoleUiService,
        private authService: AuthorisationService,
        private labellingService: LabellingService,
    ) {
        super(injector, elementRef);
    }

    public async ngOnInit() {
        this.navigationEnd.subscribe(() => this.updateData());
        await this.updateData();
    }

    private async updateData() {
        const roleId = this.getRouteParamInt("roleId");
        if (!roleId) {
            this.log.warn("Invalid Role");
            await this.integratedArchitectureFrameworkUiService.promiseToGotoRoleExplorer();
            return;
        }

        this.rememberScrollPosition(`RoleDescriptionPage-${roleId}`);
        this.blockScrollPositionRestore();  // need to wait for the system to load first before changing the scroll position

        await Promise.all([
            lastValueFrom(this.integratedArchitectureFrameworkService.getRoleConnectionsForRoleId(roleId)),
            this.promiseToGetRoleById(roleId),
        ]);

        await this.getPermissions();

        if (this.role) {
            if (this.role.extensions.isLeaderAccessRole() || this.role.extensions.isCollaboratorAccessRole() || this.role.extensions.isViewerAccessRole()) {
                this.log.warn("Access levels are no longer supported on the role page. Redirecting...");
                await this.integratedArchitectureFrameworkUiService.promiseToGotoRoleExplorer();
                return;
            }

            this.roleLabelLocationSubscription?.unsubscribe();
            this.roleLabelLocationSubscription = this.labellingService.getLabelLocationsForRole(roleId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        }

        this.notifyActivated();
    }

    public onSystemsInitialised() {
        this.unblockScrollPositionRestore();
    }

    public editRole() {
        this.roleUiService.editRole(this.role!).pipe(
            switchMap(() => this.getPermissions()),
        ).subscribe();
    }

    @Autobind
    public deleteRole() {
        return this.integratedArchitectureFrameworkUiService.promptToDeleteRole(this.role!)
            .pipe(switchMap(() => rolesPageRoute.gotoRoute()));
    }

    @Autobind
    public deactivateRole() {
        return this.integratedArchitectureFrameworkUiService.promptToDeactivateRole(this.role!)
            .pipe(switchMap(() => this.getPermissions()));
    }

    @Autobind
    public reactivateRole() {
        return this.integratedArchitectureFrameworkUiService.promptToReactivateRole(this.role!)
            .pipe(switchMap(() => this.getPermissions()));
    }

    private async getPermissions() {
        if (!this.role) {
            return;
        }

        this.layoutRefresh.next();

        const [canEditTier2, canManageAccess, canEditRoleContents] = await Promise.all([
            this.integratedArchitectureFrameworkService.promiseToVerifyTier2EditAccess(),
            this.authService.promiseToGetHasAccess(ConfigurationAuthService.ManageAccess),
            this.integratedArchAuthService.canEditRoleContents(this.role),
        ]);

        this.canEdit = this.role.isActive() && (canEditTier2 || canManageAccess || canEditRoleContents);
        this.canDelete = this.role.isActive() && canEditTier2 && canManageAccess
            && (!this.role.roleType && this.role.roleConnections.length === 0);
        this.canDeactivate = this.role.isActive() && canEditTier2 && canManageAccess
            && !this.role.roleType && !this.canDelete;
        this.canReactivate = !this.role.isActive() && !this.role.roleType;
    }

    private async promiseToGetRoleById(roleId: number) {
        this.role = await lastValueFrom(this.integratedArchitectureFrameworkService.getRoleWithLayoutById(roleId));

        if (!this.role) {
            this.log.warn("Role not found");
            return this.integratedArchitectureFrameworkUiService.promiseToGotoRoleExplorer();
        }

        this.layoutRefresh.next();
    }
}
