import { Component, Input } from "@angular/core";
import { CulturalIndexConfiguration } from "@common/ADAPT.Common.Model/organisation/cultural-index-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { CulturalIndexService } from "app/features/people/cultural-index/cultural-index.service";

@Component({
    selector: "adapt-configure-cultural-index",
    templateUrl: "./configure-ci.component.html",
})
export class ConfigureCulturalIndexComponent extends ConfigureFeatureBase {
    public configuration!: CulturalIndexConfiguration;
    @Input() public configItem?: IConfigItem;

    public constructor(
        configurationService: ConfigurationService,
        private culturalIndexService: CulturalIndexService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.configuration = await this.culturalIndexService.promiseToGetCulturalIndexConfiguration();
    }
}
