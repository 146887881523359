<div class="row">
    <div class="col-12 col-md-6 form-group">
        <label>Name</label>
        <dx-text-box [(value)]="keyFunction.name"
                     [adaptValidateEntity]="keyFunction"
                     adaptValidateEntityProperty="name"
                     data-test="key-function-name-input"></dx-text-box>
    </div>
</div>

<div class="row">
    <div class="col-12 col-md-6 form-group">
        <label>Location</label>
        <adapt-select-key-function-location [location]="keyFunction"
                                            (locationChange)="moveKeyLocation($event)"
                                            data-test="key-function-location-input"></adapt-select-key-function-location>
    </div>

    <div class="col-12 col-md-6 form-group">
        <label>{{keyFunction.leaderRole?.label}}</label>
        <adapt-allocate-tier1-role [role]="keyFunction.leaderRole"
                                   (populated)="entityModified.emit($event)"
                                   (added)="entityModified.emit($event)"
                                   data-test="key-function-leader-input"></adapt-allocate-tier1-role>
    </div>
</div>

<div class="row">
    <div class="col-12 col-md-6 form-group">
        <label>Labels</label>
        <adapt-select-label-for-related-entity [keyFunction]="keyFunction"
                                               (entitiesChange)="entityModified.emit($event)"></adapt-select-label-for-related-entity>
    </div>
</div>
