import { Component, ViewChild } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ActivityFeedService } from "app/general/activity-feed/activity-feed.service";
import { ActivityFeedComponent } from "app/general/activity-feed/activity-feed/activity-feed.component";
import { ActivityFeedSignalRHub } from "app/general/activity-feed/activity-feed-signalr-hub";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-organisation-activity-feed",
    templateUrl: "./organisation-activity-feed.component.html",
})
export class OrganisationActivityFeedComponent extends BaseComponent {
    @ViewChild(ActivityFeedComponent) public activityFeedComponent!: ActivityFeedComponent;

    public constructor(
        private activityFeedService: ActivityFeedService,
        private activityFeedHub: ActivityFeedSignalRHub,
    ) {
        super();

        this.activityFeedHub.onOrganisationActivity().pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.activityFeedComponent.promiseToFetchLatestActivity();
        });
    }

    @Autobind
    public promiseToGetInitialOrganisationActivity() {
        return lastValueFrom(this.activityFeedService.getInitialOrganisationActivity());
    }

    @Autobind
    public promiseToGetOlderOrganisationActivity(olderThan: Date) {
        return lastValueFrom(this.activityFeedService.getOlderOrganisationActivity(olderThan));
    }

    @Autobind
    public promiseToGetNewerOrganisationActivity(newerThan: Date) {
        return lastValueFrom(this.activityFeedService.getNewerOrganisationActivity(newerThan));
    }
}
