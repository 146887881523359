import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IDxListItemDeletingEvent } from "@common/ux/dx.types";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { ProductCatalogueService } from "../product-catalogue.service";
import { ProductCatalogueUiService } from "../product-catalogue-ui.service";

@Component({
    selector: "adapt-product-catalogue-page",
    templateUrl: "./product-catalogue-page.component.html",
    styleUrls: ["./product-catalogue-page.component.scss"],
})
export class ProductCataloguePageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public products: Product[] = [];
    public selectedItems: Product[] = [];
    public updateData$ = new BehaviorSubject<void>(undefined);
    public allowEditing = false;
    public mergeDisabled = true;

    constructor(
        private authService: AuthorisationService,
        private productCatalogueService: ProductCatalogueService,
        private productCatalogueUiService: ProductCatalogueUiService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.allowEditing = await this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.EditTier1);

        this.updateData$.pipe(
            debounceTime(10),
            switchMap(() => this.productCatalogueService.getProducts()),
            this.takeUntilDestroyed(),
        ).subscribe((products) => {
            this.products = products;
            this.selectedItems = [];
            this.notifyActivated();
        });
    }

    @Autobind
    public createProduct() {
        return this.productCatalogueUiService.createProduct().pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public editProduct(product: Product) {
        return this.productCatalogueUiService.editProduct(product).pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public mergeProducts() {
        return this.productCatalogueUiService.mergeProducts(this.selectedItems).pipe(
            tap(() => this.updateData$.next()),
        );
    }

    @Autobind
    public viewUsage(product: Product) {
        return this.productCatalogueUiService.viewProductUsage(product);
    }

    public onSelectionChanged() {
        this.mergeDisabled = this.selectedItems.length < 2;
    }

    public onItemDeleting(item: IDxListItemDeletingEvent<Product>) {
        if (!item.itemData) {
            return;
        }

        const promise = lastValueFrom(this.productCatalogueUiService.deleteProduct(item.itemData));
        item.cancel = DxUtilities.isCancelledFromPromise(promise);
    }
}
