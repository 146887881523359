<dx-list *ngIf="existingSystemLocations.length > 0"
         class="dx-list-boxed mb-2 h-auto"
         [dataSource]="existingSystemLocations"
         [hoverStateEnabled]="false"
         [activeStateEnabled]="false"
         [focusStateEnabled]="false"
         [allowItemDeleting]="true"
         (onItemReordered)="updateOrdinals($event)"
         (onItemDeleted)="removeSystemLocation($event)">
    <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
    <div *dxTemplate="let systemLocation of 'item'">
        <span>{{systemLocation.system?.name}}</span>
    </div>
</dx-list>

<adapt-select-system [system]="selectedSystem"
                     (systemChange)="addSystemLocation($event).subscribe()"
                     (isCreatingChange)="creatingAndAddingChange.emit($event)"
                     (newEntityChange)="onNewSystemChange($event)"
                     [filter]="excludeSelectedSystemsFilter"></adapt-select-system>
