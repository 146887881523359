import { Component, Input } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";

@Component({
    selector: "adapt-display-guidance-material",
    templateUrl: "./display-guidance-material.component.html",
})
export class DisplayGuidanceMaterialComponent {
    @Input() public guidanceMaterial!: GuidanceMaterial;
    @Input() public showDescription = true;

    public getAbsoluteUrl(url: string) {
        return UrlUtilities.getAbsoluteUrl(url);
    }
}
