import { Injectable } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { DynamicCallbackFn } from "@common/route/navigation-node.interface";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { UserService } from "@common/user/user.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { myKanbanPageRoute, personalKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { careerValuationDashboardPageRoute, myCareerValuationDashboardPageRoute } from "app/features/people/career-valuation/cvt-dashboard-page/cvt-dashboard-page.route";
import { culturalIndexDashboardRoute, myCulturalIndexDashboardRoute } from "app/features/people/cultural-index/ci-dashboard-page/ci-dashboard-page.route";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { myCatchupsPageRoute, personalCatchupsPageRoute } from "app/features/people/peer-catchup/my-catchups-page/my-catchups-page.route";
import { PeerCatchupAuthService } from "app/features/people/peer-catchup/peer-catchup-auth.service";
import { myPersonProfilePageRoute, personProfilePageRoute } from "app/features/people/person/profile/person-profile-page/person-profile-page.route";
import { EMPTY, merge, of } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { personalConfigurationPageRoute } from "../configuration/personal/personal-configuration-page/personal-configuration-page.route";
import { personalDashboardPageRoute } from "../personal-dashboard/personal-dashboard-page/personal-dashboard-page.route";

@Injectable()
export class PersonNavigationService extends BaseOrgCommonNavigationHierarchyService implements IDynamicNodeBuilder {
    public static readonly HierarchyId = "ADAPT.Person.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-user";

    public id = PersonNavigationService.HierarchyId;
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilities: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
        private userService: UserService,
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(orgService, authNotification);
        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(Person),
            rxjsBreezeService.entityTypeChangedInSave(Board), // board changes will change permission for work node
        ).pipe(
            debounceTime(100),
        ); // rebuild on board change (not just personal boards as accessible team boards affect personal hierarchy too)
    }

    public buildDynamicNodes() {
        const dynamicTitleFunction = this.navUtilities.createDynamicValueFunctionForOptions({
            entityIdParamName: "personId",
            getEntityByParamCallback: (id: number) => isNaN(id)
                ? of(undefined)
                : this.commonDataService.getById(PersonBreezeModel, id),
            getValue: (person) => person.fullName,
        });

        return [
            this.buildDynamicNode(myPersonProfilePageRoute.id, dynamicTitleFunction),
            this.buildDynamicNode(personProfilePageRoute.id, dynamicTitleFunction),
            this.buildDynamicNode(myCareerValuationDashboardPageRoute.id),
            this.buildDynamicNode(myCulturalIndexDashboardRoute.id),
            this.buildDynamicNode(myCatchupsPageRoute.id),
            this.buildDynamicNode(personalConfigurationPageRoute.id),
            ...this.buildDynamicOtherPersonNodes(),
        ];
    }

    public buildDynamicOtherPersonNodes() {
        return [
            this.buildDynamicNodeForOtherPerson(personalKanbanPageRoute.id),
            this.buildDynamicNodeForOtherPerson(careerValuationDashboardPageRoute.id),
            this.buildDynamicNodeForOtherPerson(culturalIndexDashboardRoute.id),
            this.buildDynamicNodeForOtherPerson(personalCatchupsPageRoute.id),
        ];
    }

    public async buildHierarchy() {
        const person = await this.userService.getCurrentPerson();
        if (!person || !person.isActive()) {
            return new NavigationNodeBuilder()
                .setTitle(person?.fullName ?? "No Person")
                .build();
        }

        return this.navUtilities.nodeBuilderForControllerAndParams(personalDashboardPageRoute.id)
            .setIconClass(PersonNavigationService.IconClass)
            .setHideIconInBreadcrumb(true)
            .keepChildrenInAddedOrder()
            .promiseToSetTitle(this.promiseToGetLoggedInPersonName())
            .promiseToAddChildControllerIfAuthorised(KanbanAuthService.ViewAnyBoard, myKanbanPageRoute.id)
            .promiseToAddChildControllerIfAuthorised(CareerValuationAuthService.ReadMyCareerValuation, myCareerValuationDashboardPageRoute.id)
            .promiseToAddChildControllerIfAuthorised(CulturalIndexAuthService.ReadMyCulturalIndex, myCulturalIndexDashboardRoute.id)
            .promiseToAddChildControllerIfAuthorised(PeerCatchupAuthService.ReadMyPeerCatchup, myCatchupsPageRoute.id)
            .promiseToAddChildControllerIfAuthorised(DirectoryAuthService.ReadMyProfile, myPersonProfilePageRoute.id)
            .promiseToBuild();
    }

    private async promiseToGetLoggedInPersonName() {
        const person = await this.userService.getCurrentPerson();
        return person?.fullName ?? "";
    }

    private buildDynamicNode(controllerId: string, titleCallback?: DynamicCallbackFn<"title">) {
        const builder = this.navUtilities.nodeBuilderForController(controllerId);

        if (controllerId !== personProfilePageRoute.id && controllerId !== myPersonProfilePageRoute.id) {
            builder.setDynamicParentController(myPersonProfilePageRoute.id);
        }

        if (titleCallback) {
            builder.setTitleDynamicValueCallback(titleCallback);
        }

        return builder.build();
    }

    private buildDynamicNodeForOtherPerson(controllerId: string, titleCallback?: DynamicCallbackFn<"title">) {
        const builder = this.navUtilities.nodeBuilderForController(controllerId)
            .setDynamicParentController(personProfilePageRoute.id);

        if (titleCallback) {
            builder.setTitleDynamicValueCallback(titleCallback);
        }

        return builder.build();
    }
}
