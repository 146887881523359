import { Component, Input, ViewChild } from "@angular/core";
import { ResilientBusinessSurveyConfiguration } from "@common/ADAPT.Common.Model/organisation/resilient-business-survey-configuration";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { DisplayImpactSurveysComponent } from "../display-impact-surveys/display-impact-surveys.component";

@Component({
    selector: "adapt-configure-resilient-business-assessment",
    templateUrl: "./configure-resilient-business-assessment.component.html",
})
export class ConfigureResilientBusinessAssessmentComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public currentStatus?: boolean;
    public surveyType = SurveyType.ResilientBusinessAssessment;

    public resilientBusinessSurveyConfiguration?: ResilientBusinessSurveyConfiguration;

    @ViewChild(DisplayImpactSurveysComponent) private impactedSurveyInstance?: DisplayImpactSurveysComponent;

    public constructor(
        configurationService: ConfigurationService,
        private surveyService: SurveyService,
        private teamsService: CommonTeamsService,
    ) {
        super(configurationService);
    }

    public initialiseData(): Promise<void> {
        return Promise.resolve();
    }

    public onFeatureStatusChanged(newStatus: boolean) {
        this.currentStatus = newStatus;

        if (newStatus) {
            this.surveyService.getResilientBusinessSurveyConfigurations().pipe(
                map((entities) => (entities && entities.length === 1) ? entities[0] : undefined),
                tap((rbConfig) => this.resilientBusinessSurveyConfiguration = rbConfig),
                filter((rbConfig) => !rbConfig),
                switchMap(() => this.surveyService.createResilientBusinessSurveyConfigurationForCurrentOrganisation()),
                this.takeUntilDestroyed(),
            ).subscribe(async (rbConfig) => {
                // just switched on the feature and there is no survey configuration entity just yet
                const teams: Team[] = await this.teamsService.promiseToGetAllActiveTeams();
                let leaderTeam: Team | undefined;
                for (const team of teams) {
                    if (team.name === "Navigation") {
                        leaderTeam = team;
                        break;
                    }
                }

                if (!leaderTeam && teams.length > 0) {
                    // no nav team - simply select the first one
                    leaderTeam = teams[0];
                }

                rbConfig.leaderTeam = leaderTeam;
                this.resilientBusinessSurveyConfiguration = rbConfig;
            });
        }
    }

    public onSave() {
        if (this.impactedSurveyInstance) {
            this.impactedSurveyInstance.onSave();
        }

        return super.onSave();
    }
}
