<div *adaptLoading="!stepsByParent"
     class="child-sortable-container m-3"
     [ngClass]="{'is-editing': isEditing, 'is-saving': isSaving}">
    <div [id]="getSortableId(parentStep)"
         class="child-sortable"
         [nxtSortablejs]="childSteps"
         [config]="childSortableOptions"
         (init)="handleSortableJsInit()">
        <div *ngFor="let child of childSteps"
             class="child-step">
            <adapt-process-step-card class="child-card"
                                     [processStep]="child"
                                     [isEditing]="isEditing"
                                     (stepClick)="stepClick.emit($event)"></adapt-process-step-card>
            <div *ngIf="child?.extensions?.isProcessStep"
                 class="children-list step-children"
                 [ngClass]="{'children-list-empty': !isEditing && child.childSteps.length === 0}">
                <div *ngIf="getStepsByParent(child) as grandchildren"
                     [id]="getSortableId(child)"
                     class="grandchild-sortable"
                     [nxtSortablejs]="grandchildren"
                     [config]="grandchildSortableOptions"
                     (init)="handleSortableJsInit()">
                    <div *ngFor="let grandchildStep of grandchildren"
                         class="grandchild-step">
                        <adapt-process-step-card [processStep]="grandchildStep"
                                                 [isEditing]="isEditing"
                                                 (stepClick)="stepClick.emit($event)"></adapt-process-step-card>
                    </div>
                </div>
                <adapt-add-child-step *ngIf="isEditing"
                                      [processMap]="processMap$ | async"
                                      [parentStep]="child"
                                      (stepAdded)="scrollElementIntoView($event)"></adapt-add-child-step>
            </div>
            <ng-container *ngIf="child?.extensions?.isProcessMapLink">
                <!-- This slightly awkward usage here is to get the loading spinner inside the list border, but hide it if
                     there aren't any children. Something like let-linkChildren="getLinkChildren(child-linkedProcessMap) | async"
                     at the top level and ngClass => linkChildren.length and *adaptLoading="!linkChildren" would be
                     better but I haven't come across a way to do something like that -->
                <div [ngClass]="{'children-list-empty': (getLinkChildren(child.linkedProcessMap) | async)?.length === 0}"
                     class="children-list link-children">
                    <ng-container *adaptLoadingUntilFirstEmit="let linkChildren of getLinkChildren(child.linkedProcessMap)">
                        <!-- We don't allow editing of link children from directly -->
                        <adapt-process-step-card *ngFor="let linkChild of linkChildren"
                                                 class="grandchild-step"
                                                 [processStep]="linkChild"
                                                 (stepClick)="stepClick.emit($event)"></adapt-process-step-card>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!isEditing && childSteps.length === 0"
         class="row flex-fill m-0">
        No process steps or role tasks to display.
    </div>

    <adapt-add-child-step *ngIf="isEditing"
                          [processMap]="processMap$ | async"
                          [parentStep]="parentStep"
                          (stepAdded)="scrollViewToRight()"
                          data-test="add-child-step"></adapt-add-child-step>
</div>
