import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { DocumentSize, SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { LayoutManagerComponent } from "@common/ux/layout/layout-manager/layout-manager.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { DiagramComponent } from "app/general/diagram/diagram.component/diagram.component";
import { EMPTY } from "rxjs";
import { catchError } from "rxjs/operators";
import { EditSystemDiagramDialogComponent } from "../edit-system-diagram-dialog/edit-system-diagram-dialog.component";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-system-diagram",
    templateUrl: "./system-diagram.component.html",
})
export class SystemDiagramComponent extends BaseComponent implements OnInit {
    @Input() public isEditing?: boolean;
    @Input() public component?: SystemComponent;
    @Output() public diagramDeleted = new EventEmitter<SystemComponent>();
    @Output() public moveComponentClick = new EventEmitter<SystemComponent>();
    @Output() public copyComponentClick = new EventEmitter<SystemComponent>();

    @ViewChild(DiagramComponent) public diagram?: DiagramComponent;

    public editMenu: IAdaptMenuItem[] = [];

    public getComponentSize = this.systemisationService.getComponentSize;

    constructor(
        private dialogService: AdaptCommonDialogService,
        private systemisationService: SystemisationService,
        @Optional() layoutManager: LayoutManagerComponent,
    ) {
        super();

        // reload the diagram when the layout updates
        layoutManager?.layoutUpdated$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.diagram?.loadDiagram());
    }

    public ngOnInit() {
        this.updateMenu();
    }

    public updateMenu() {
        this.editMenu = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [{
                text: "Edit diagram",
                icon: "fal fa-fw fa-edit",
                onClick: () => this.editDiagramComponent(this.component!),
            }, {
                text: "Copy diagram...",
                icon: "fal fa-fw fa-copy",
                onClick: () => this.copyComponentClick.emit(this.component),
                separator: true,
            }, {
                text: "Move diagram to another system",
                icon: "fal fa-fw fa-arrows-alt",
                onClick: () => this.moveComponentClick.emit(this.component),
            }, {
                text: "Set display height",
                icon: "fal fa-fw fa-line-height",
                separator: true,
                items: Object.keys(DocumentSize).map((key) => {
                    return {
                        text: StringUtilities.camelToSentenceCase(key),
                        onClick: () => this.setSize(key as DocumentSize),
                        isChecked: this.component?.size === key,
                    };
                }),
            }, {
                text: "Delete diagram",
                icon: "fal fa-fw fa-trash-alt",
                onClick: this.deleteSystemDiagram,
                separator: true,
            }],
        }];
    }

    public setSize(newSize: DocumentSize) {
        const originalSize = this.component!.size;

        this.component!.size = newSize;
        this.updateMenu();
        this.diagram?.loadDiagram();

        this.systemisationService.saveEntities([this.component!]).pipe(
            catchError(() => {
                this.component!.size = originalSize;
                this.updateMenu();
                this.diagram?.loadDiagram();
                return EMPTY;
            }),
        ).subscribe();
    }

    @Autobind
    public deleteSystemDiagram() {
        this.diagramDeleted.emit(this.component);
    }

    @Autobind
    public editDiagramComponent(systemComponent: SystemComponent) {
        return this.dialogService.open(EditSystemDiagramDialogComponent, systemComponent)
            .subscribe();
    }
}
