import { Component, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { ResilientBusinessGoalsAuthService } from "@org-common/lib/rb-goals/rb-goals-auth.service";
import { EmployeeEngagementAuthService } from "@org-common/lib/survey/employee-engagement/employee-engagement-auth.service";
import { ResilientBusinessAssessmentAuthService } from "@org-common/lib/survey/resilient-business-assessment/resilient-business-assessment-auth.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { combineLatest, Observable } from "rxjs";
import { first } from "rxjs/operators";

enum DashboardPage {
    Guidance = "guidance",
    EconomicEngine = "economic-engine",
    Metrics = "metrics",
    Activity = "activity",
}

@Component({
    selector: "adapt-organisation-dashboard-page",
    templateUrl: "./organisation-dashboard-page.component.html",
})
export class OrganisationDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly PageSearchParam = "page";
    public page = DashboardPage.Guidance;
    public dashboardPage = DashboardPage;
    public hasTier1$: Observable<boolean>;
    public hasGuidance$: Observable<boolean>;
    public hasMetrics$: Observable<boolean>;

    public FeatureName = FeatureName;

    // permissions for each of the dashboard elements
    public valueStreamRead = Tier1ArchitectureAuthService.ReadValueStreamContents;
    public tier1Read = Tier1ArchitectureAuthService.ReadTier1;
    public purposeVisionRead = CommonPurposeVisionAuthService.ReadPurposeVision;
    public valuesRead = ValuesConstitutionAuthService.ReadValuesConstitution;
    public objectivesRead = ObjectivesAuthService.ReadObjectives;
    public rbGoalsRead = ResilientBusinessGoalsAuthService.ReadResilientBusinessGoals;
    public employeeEngagementRead = EmployeeEngagementAuthService.ReadEmployeeEngagement;
    public resilientBusinessAssessmentRead = ResilientBusinessAssessmentAuthService.ReadResilientBusinessAssessment;
    public careerValuationRead = CareerValuationAuthService.ReadAnonymousCareerValuation;
    public culturalIndexRead = CulturalIndexAuthService.ReadAnonymousCulturalIndex;

    constructor(
        authorisationService: AuthorisationService,
        injector: Injector,
    ) {
        super(injector);
        this.hasTier1$ = authorisationService.hasAtLeastOnePermission([
            this.valueStreamRead,
        ]);
        this.hasGuidance$ = authorisationService.hasAtLeastOnePermission([
            this.purposeVisionRead,
            this.valuesRead,
        ]);
        this.hasMetrics$ = authorisationService.hasAtLeastOnePermission([
            this.employeeEngagementRead,
            this.resilientBusinessAssessmentRead,
            this.careerValuationRead,
            this.culturalIndexRead,
        ]);
    }

    public async ngOnInit() {
        combineLatest([this.hasGuidance$, this.hasTier1$]).pipe(
            first(),
            this.takeUntilDestroyed(),
        ).subscribe(([hasGuidance, hasEconEngine]) => {
            const page = this.getSearchParameterValue(OrganisationDashboardPageComponent.PageSearchParam) as DashboardPage;
            const defaultInitialPage = hasGuidance
                ? DashboardPage.Guidance
                : hasEconEngine ? DashboardPage.EconomicEngine : DashboardPage.Activity;

            this.updateUrl(Object.values(DashboardPage).includes(page) ? page : defaultInitialPage);

            this.notifyActivated();
        });
    }

    public updateUrl(newValueIdentifier: DashboardPage): void {
        this.page = newValueIdentifier;
        this.setSearchParameterValue(OrganisationDashboardPageComponent.PageSearchParam, this.page);
    }
}
