import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CulturalLeadershipFrameworkUiService } from "app/features/culture/cultural-leadership/cultural-leadership-framework-ui.service";
import { ChartSeries } from "devextreme/viz/common";
import { BaseGaugeRangeContainer } from "devextreme/viz/gauges/base_gauge";
import { switchMap } from "rxjs/operators";
import { CulturalIndexService, ICulturalIndexStatistics } from "../cultural-index.service";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";

@Component({
    selector: "adapt-ci-orbit-statistics",
    templateUrl: "./ci-orbit-statistics.component.html",
    styleUrls: ["./ci-orbit-statistics.component.scss"],
})
export class CulturalIndexOrbitStatisticsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;

    public hasAtLeastQuantitativePermissions: boolean;

    public statisticsData?: ICulturalIndexStatistics;

    public isLoading = true;

    public rangeContainer: BaseGaugeRangeContainer = {
        ranges: [],
    };

    public series: ChartSeries[] = [];

    public CatchupStatusPaletteName = CulturalLeadershipFrameworkUiService.CatchupStatusPaletteName;

    public constructor(
        public culturalIndexUiService: CulturalIndexUiService,
        private culturalIndexService: CulturalIndexService,
        private culturalIndexFilterService: CulturalIndexFilterService,
        culturalIndexAuthService: CulturalIndexAuthService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.hasAtLeastQuantitativePermissions = culturalIndexAuthService.currentPersonHasAtLeastQuantitativeAccess();

        rxjsBreezeService.entityTypeChangedInSave(CulturalIndex)
            .pipe(
                switchMap(() => this.getDataAndRefreshCharts()),
                this.takeUntilDestroyed(),
            )
            .subscribe();

        this.culturalIndexFilterService.filterListener
            .pipe(
                switchMap(() => this.getDataAndRefreshCharts()),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }


    public async ngOnInit() {
        await this.getDataAndRefreshCharts();
        this.setupRangeContainer();
        this.setupSeries();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.date) {
            this.getDataAndRefreshCharts();
        }
    }

    public get culturalIndexesHaveBeenRecorded() {
        return this.statisticsData && this.statisticsData.averageCulturalIndex > 0;
    }

    @Autobind
    private async getDataAndRefreshCharts() {
        const filterParameters = { ...this.culturalIndexFilterService.filter, date: this.date };
        const data = await this.culturalIndexService.promiseToGetCulturalIndexAnalysisData(filterParameters);
        this.statisticsData = this.culturalIndexService.generateCulturalIndexStatistics(data);
        this.isLoading = false;
    }

    @Autobind
    public setupRangeContainer() {
        for (const valueState of this.culturalIndexUiService.valueStates) {
            this.rangeContainer.ranges?.push({
                startValue: valueState.lower,
                endValue: valueState.upper,
                color: valueState.fgColor,
            });
        }
    }

    @Autobind
    private setupSeries() {
        for (const valueState of this.culturalIndexUiService.valueStates) {
            this.series.push({
                valueField: valueState.name + "Count",
                name: valueState.state,
                color: valueState.fgColor,
            });
        }
    }
}
