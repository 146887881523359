import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { provideUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { provideSidebarPopover } from "@common/shell/common-sidebar/sidebar-popover";
import { provideSidebarTab } from "@common/shell/common-sidebar/sidebar-tab";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { ProfileUserMenuItem } from "@org-common/lib/person-profile/profile-user-menu-item";
import { MeetingTab } from "@org-common/lib/shell/meeting-tab-content/meeting-tab";
import { AdaptOrgCommonShellModule } from "@org-common/lib/shell/org-common-shell.module";
import { PersonalTabContentComponent } from "app/services/layout/personal-tab-content/personal-tab-content.component";
import { TeamTabContentComponent } from "app/services/layout/team-tab-content/team-tab-content.component";
import { DxScrollViewModule } from "devextreme-angular";
import { OrganisationTab } from "./organisation-tab-content/organisation-tab";
import { OrganisationTabContentComponent } from "./organisation-tab-content/organisation-tab-content.component";
import { PersonalTab } from "./personal-tab-content/personal-tab";
import { SupportApplicationBarItem } from "./support-application-bar-item";
import { TeamTab } from "./team-tab-content/team-tab";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DxScrollViewModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonRouteModule,
        AdaptButtonModule,
        AdaptFeaturesModule,
        AdaptShellModule,
        AdaptOrgCommonShellModule,
        AdaptAuthorisationModule,
        AdaptMeetingsSharedModule,
        AdaptTooltipModule,
        AdaptDateModule,
        AdaptMenuModule,
        AdaptFeatureModule,
    ],
    declarations: [
        OrganisationTabContentComponent,
        TeamTabContentComponent,
        PersonalTabContentComponent,
    ],
})
export class AdaptEmbedShellModule {
    public static forRoot(): ModuleWithProviders<AdaptEmbedShellModule> {
        return {
            ngModule: AdaptEmbedShellModule,
            providers: [
                provideSidebarTab(PersonalTab),
                provideSidebarTab(TeamTab),
                provideSidebarTab(OrganisationTab),
                provideSidebarTab(MeetingTab),

                provideSidebarPopover(SupportApplicationBarItem),

                provideUserMenuItem(ProfileUserMenuItem),
            ],
        };
    }

    public static forChild(): ModuleWithProviders<AdaptEmbedShellModule> {
        return {
            ngModule: AdaptEmbedShellModule,
        };
    }
}
