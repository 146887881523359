import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";

export const allTeamConfigGroups = (): IConfigGroup[] => [
    {
        key: "Team",
        items: [
            {
                name: "Profile",
                area: ConfigArea.TeamProfile,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            },
            {
                name: "People",
                area: ConfigArea.People,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            },
            {
                name: "External links",
                area: ConfigArea.ExternalLink,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.OrganisationExternalDashboard,
                enableFeatureText: "external links",
            },
            {
                name: "Objectives & key results",
                area: ConfigArea.ObjectivesAndKeyResults,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.StewardshipObjectives,
                enableFeatureText: "objectives & key results",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.IntroducingObjectivesAndKeyResults),
                supportText: "Find out more about objectives in the ADAPT Method",
            },
            {
                name: "Values",
                area: ConfigArea.ValuesConstitution,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.CompassValuesConstitution,
                enableFeatureText: "values",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.ValuePurposeVision),
                supportText: "Find out more about values and value constitutions inside the ADAPT Method",
            },
            {
                name: "Actions",
                area: ConfigArea.Kanban,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.StewardshipWorkKanban,
                enableFeatureText: "actions",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.TeamStewardship),
                supportText: "Find out more about effective team stewardship in the ADAPT Method",
            },
            {
                name: "Meetings",
                area: ConfigArea.Meetings,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.StewardshipWorkMeetings,
                enableFeatureText: "meetings",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.TeamStewardship),
                supportText: "Find out more about effective team stewardship in the ADAPT Method",
            },
            {
                name: "Peer Catch-up",
                area: ConfigArea.PeerCatchUp,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.PeoplePeerCatchUp,
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.PeerCatchUp),
                supportText: "Find out more about Peer Catch-ups in the ADAPT Method",
            },
            {
                name: "Team assessment",
                area: ConfigArea.TeamAssessment,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.CulturalNetworkTeamAssessment,
                enableFeatureText: "team assessment",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingTheAdaptTeamModel),
                supportText: "Find out more about team assessment in the ADAPT Method",
            },
            {
                name: "Systems",
                area: ConfigArea.Tier2,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.ArchitectureTier2,
                enableFeatureText: "systems",
                supportUrl: ImplementationKitService.GetArticleLink(ImplementationKitArticle.SystemiseYourBusiness),
                supportText: "Find out more about systems & processes (systems, roles & guidance materials) in the ADAPT Method",
            },
            {
                name: "Access permissions",
                area: ConfigArea.AccessPermissions,
                accessVerifier: CommonTeamsAuthService.ViewTeamAccessPermissions,
            },
        ],
    },
];
