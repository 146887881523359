<adapt-dialog [maxWidth]="800"
              [padding]="false">
    <h3 adapt-dialog-title>{{title}}</h3>

    <dx-tab-panel adapt-dialog-content
                  [items]="items"
                  tabsPosition="left"
                  class="edit-system-dialog">
        <div *dxTemplate="let item of 'detailsTemplate'">
            <div class="alert alert-info">Define the system details to be used by roles, teams and key functions</div>
            <div class="mb-4">
                <h3>System name</h3>
                <dx-text-box #focus
                             [value]="system.name"
                             (valueChange)="onNameChange($event)"
                             [adaptValidateEntity]="system"
                             adaptValidateEntityProperty="name"
                             placeholder="Enter system name">
                </dx-text-box>
            </div>
            <div class="mb-4">
                <h3>Labels</h3>
                <adapt-select-label-for-related-entity [system]="system"
                                                       (entitiesChange)="onLabelLocationsChanged($event)">
                </adapt-select-label-for-related-entity>
            </div>
            <div class="mb-4 row">
                <div class="col-12 col-md-6 d-flex flex-column mb-4">
                    <h3>System owner</h3>
                    <adapt-select-person [required]="false"
                                         [(person)]="system.owner"
                                         [userTypes]="allowedOwnerUserTypes"
                                         data-test="select-system-owner"></adapt-select-person>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column">
                    <h3>Knowledge experts</h3>
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-between align-items-center"
                             *ngFor="let systemKnowledgeExpert of system.systemKnowledgeExperts">
                            <adapt-link-person [personId]="systemKnowledgeExpert.knowledgeExpertId"></adapt-link-person>
                            <button adaptButton="deleteBorderless"
                                    adaptTooltip="Delete knowledge expert"
                                    data-test="delete-knowledge-expert-button"
                                    (click)="onKnowledgeExpertDeleted(systemKnowledgeExpert)"></button>
                        </div>
                        <adapt-select-person #selectKnowledgeExpert
                                             (personChange)="onKnowledgeExpertAdded($event)"
                                             [filter]="notKnowledgeExpert"
                                             data-test="select-system-knowledge-expert"></adapt-select-person>
                    </div>
                </div>
            </div>
        </div>
        <div *dxTemplate="let item of 'locationsTemplate'">
            <adapt-configure-system-locations [system]="system"
                                              (systemLocationChange)="systemLocationChanged($event)"></adapt-configure-system-locations>
        </div>
    </dx-tab-panel>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
