import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";

@Component({
    selector: "adapt-analyse-cvt-page",
    templateUrl: "./analyse-cvt-page.component.html",
})
export class AnalyseCareerValuationPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly CareerValuationFilterParamKeys = Object.values(CulturalDataFilterParamKeys);

    public hasAtLeastQuantitativePermissions: boolean;
    public showComparison = false;

    public constructor(
        careerValuationAuthService: CareerValuationAuthService,
        public filterService: CareerValuationFilterService,
        injector: Injector,
    ) {
        super(injector);

        this.hasAtLeastQuantitativePermissions = careerValuationAuthService.currentPersonHasAtLeastQuantitativeAccess();

        this.filterService.reset();
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}
