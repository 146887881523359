import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CulturalIndexDashboardPageComponent } from "./ci-dashboard-page.component";

export const myCulturalIndexDashboardRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-ci-dashboard-page", CulturalIndexDashboardPageComponent)
    .atOrganisationUrl("/people/cultural-index/dashboard")
    .redirectToThisRouteFromOrganisationUrl("/people/culturalIndex/dashboard")
    .verifyingFeatures(FeatureName.CulturalNetworkCulturalIndex)
    .withTitle("Cultural Index")
    .build();

export const culturalIndexDashboardRoute = new OrganisationPageRouteBuilder<{ personId: number }>()
    .usingNgComponent("adapt-ci-dashboard-page", CulturalIndexDashboardPageComponent)
    .atOrganisationUrl("/people/cultural-index/:personId/dashboard")
    .verifyingFeatures(FeatureName.CulturalNetworkCulturalIndex)
    .withTitle("Cultural Index")
    .build();
