import { Injectable } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocument } from "@common/ADAPT.Common.Model/organisation/system-document";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { EMPTY } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CopySystemComponentDialogComponent } from "./copy-system-component-dialog/copy-system-component-dialog.component";
import { EditExternalLinkDialogComponent } from "./edit-external-link-dialog/edit-external-link-dialog.component";
import { EditSystemDialogComponent } from "./edit-system-dialog/edit-system-dialog.component";
import { EditSystemDocumentDialogComponent } from "./edit-system-document-dialog/edit-system-document-dialog.component";
import { EditSystemLocationsDialogComponent, IEditSystemLocationDialogData } from "./edit-system-locations-dialog/edit-system-locations-dialog.component";
import { EditVideoDocumentDialogComponent, IEditVideoDocumentDialogData } from "./edit-video-document-dialog/edit-video-document-dialog.component";
import { MoveSystemComponentDialogComponent } from "./move-system-component-dialog/move-system-component-dialog.component";
import { SystemisationService } from "./systemisation.service";

@Injectable({
    providedIn: "root",
})
export class SystemisationUiService {

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private systemisationService: SystemisationService,
        private commonDataService: CommonDataService,
    ) { }

    public openEditSystemLocationsDialog(keyFunction?: KeyFunction, team?: Team, role?: Role) {
        const dialogData: IEditSystemLocationDialogData = {
            keyFunction,
            team,
            role,
        };

        return this.dialogService.open(EditSystemLocationsDialogComponent, dialogData);
    }

    public openCreateSystemDialog() {
        return this.systemisationService.createSystem().pipe(
            switchMap((system) => this.dialogService.open(EditSystemDialogComponent, system)),
        );
    }

    public openEditSystemDialog(system: SystemEntity) {
        return this.dialogService.open(EditSystemDialogComponent, system);
    }

    public openSystemDocumentDialog(systemComponent: SystemComponent) {
        if (systemComponent.systemDocument) {
            return this.dialogService.open(EditSystemDocumentDialogComponent, {
                systemComponent,
                systemDocument: systemComponent.systemDocument,
            });
        } else {
            return EMPTY;
        }
    }

    public moveSystemComponent(systemComponent: SystemComponent) {
        return this.dialogService.open(MoveSystemComponentDialogComponent, systemComponent);
    }

    public copySystemComponent(systemComponent: SystemComponent) {
        return this.dialogService.open(CopySystemComponentDialogComponent, systemComponent);
    }

    public deleteSystem(system: SystemEntity) {
        const dialogData: IConfirmationDialogData = {
            title: `Delete system: ${system.name}`,
            message: `<p>Are you sure you want to delete system together with its attached documents?</p>
                <p>Once the system and attached documents are deleted, they can no longer be retrieved.</p>`,
            checkboxMessage: "Confirm that you really want to delete the system",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.commonDataService.remove(system)),
            switchMap(() => this.commonDataService.saveEntities(system)),
        );
    }

    public openEditVideoDialog(systemDocument: SystemDocument, systemComponent: SystemComponent) {
        const dialogData: IEditVideoDocumentDialogData = {
            systemDocument,
            systemComponent,
        };

        return this.dialogService.open(EditVideoDocumentDialogComponent, dialogData);
    }

    public openEditExternalLinkDialog(systemComponent: SystemComponent) {
        return this.dialogService.open(EditExternalLinkDialogComponent, systemComponent);
    }

}
