<adapt-dashboard-element name="Status"
                         *ngIf="(latestConnection$ | async) as latestConnection">
    <div adapt-dashboard-title-buttons
         *adaptIfAuthorised="EditAllPersonalProfiles">
        <button adaptButton="editBorderless"
                *ngIf="editMode"
                data-test="edit-start-date-button"
                [adaptBlockingClick]="editStartDate"
                [adaptBlockingClickParam]="latestConnection"></button>
    </div>

    <span>{{isPendingActive ? "Starting": "Started"}} {{connectionTypeLabel(latestConnection)}} {{latestConnection.startDate |
        adaptDate}}</span>
    <span *ngIf="latestConnection.endDate">, ended {{latestConnection.endDate | adaptDate}}</span>
</adapt-dashboard-element>
