<dx-data-grid *adaptLoading="!catchups"
              [dataSource]="catchups"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true">
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-paging [pageSize]="20"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[20, 35, 50, 100]"
               [showInfo]="true"></dxo-pager>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-export [enabled]="true"
                fileName="Peer Catch-ups"></dxo-export>

    <dxi-column caption="Team"
                groupCellTemplate="teamCatchupGroupTemplate"
                dataField="teamId"
                [calculateCellValue]="calculateTeamCellValue"
                [calculateGroupValue]="calculateTeamGroupValue"
                [allowSorting]="true"
                [allowFiltering]="true"
                [allowGrouping]="true"
                [groupIndex]="0"></dxi-column>
    <dxi-column caption="Participants"
                [allowSorting]="true"
                [minWidth]="filterForPersonId ? 160 : 320"
                [calculateCellValue]="calculateParticipantsCellValue"
                dataField="dummy_participants"
                cellTemplate="participantsCellTemplate"></dxi-column>
    <dxi-column caption="Status"
                [width]="160"
                cellTemplate="statusCellTemplate"
                [calculateCellValue]="calculateStatusCellValue"
                [allowSorting]="false"
                [allowGrouping]="true"
                [allowFiltering]="true"></dxi-column>
    <dxi-column caption="Next catch-up"
                [width]="130"
                [calculateDisplayValue]="calculateNextCatchupDisplayValue"
                [calculateCellValue]="calculateNextCatchupCellValue"
                [calculateSortValue]="calculateNextCatchupCellValue"
                dataField="dummy_next_catchup"
                [allowSorting]="true"
                [sortIndex]="0"
                sortOrder="asc"
                [allowFiltering]="true"></dxi-column>
    <dxi-column caption="Latest combined rating"
                dataField="dummy_latestCombinedRating"
                cellTemplate="ratingCellTemplate"
                [minWidth]="275"
                [allowSorting]="true"
                [calculateSortValue]="calculateLatestCombinedRatingCellValue"
                [calculateCellValue]="calculateLatestCombinedRatingDisplayValue"
                [allowFiltering]="true"></dxi-column>
    <dxi-column caption="Date recorded"
                [width]="120"
                [calculateDisplayValue]="calculateCreationDateDisplayValue"
                [calculateSortValue]="calculateCreationDateCellValue"
                dataField="creationDate"></dxi-column>

    <div *dxTemplate="let item of 'ratingCellTemplate'">
        <adapt-catchup-rating-cell [catchup]="item.data"></adapt-catchup-rating-cell>
    </div>

    <div *dxTemplate="let item of 'statusCellTemplate'"
         [ngClass]="getStatusClass(item.data.creationDate)">
        <span>{{item.value}}</span>
    </div>

    <div *dxTemplate="let groupItem of 'teamCatchupGroupTemplate'">
        <ng-container *ngIf="groupItem.value as teamId; else allTeams">
            <adapt-link-team [teamId]="teamId"></adapt-link-team>
            team
        </ng-container>
    </div>
    <ng-template #allTeams>
        <span>All teams</span>
    </ng-template>

    <div *dxTemplate="let item of 'participantsCellTemplate'"
         class="participants">
        <ng-container *ngIf="!filterForPersonId; else showOtherPerson">
            <adapt-link-person class="participant"
                               [person]="item.data.person1"
                               [preventWrap]="true"></adapt-link-person>
            <adapt-link-person class="participant"
                               [person]="item.data.person2"
                               [preventWrap]="true"></adapt-link-person>
        </ng-container>
        <ng-template #showOtherPerson>
            <adapt-link-person [person]="otherPerson(item.data)"
                               [preventWrap]="true"></adapt-link-person>
        </ng-template>
    </div>
</dx-data-grid>
