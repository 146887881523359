import { Component, Input } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonDetailNames } from "@common/ADAPT.Common.Model/person/person-detail";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { BehaviorSubject, combineLatest, merge, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

interface IGroupedRoleConnections {
    organisation: RoleConnection[];
    team: RoleConnection[];
}

@Component({
    selector: "adapt-person-profile-access",
    templateUrl: "./person-profile-access.component.html",
})
export class PersonProfileAccessComponent extends BaseComponent {
    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;

    public latestConnection$: Observable<Connection | undefined>;
    public showDetails = false;
    public person$ = new BehaviorSubject<Person | undefined>(undefined);
    public roleConnections$: Observable<IGroupedRoleConnections>;
    public inviteDate$: Observable<Date | undefined>;
    public readonly today = new Date();

    constructor(
        rxjsBreezeService: RxjsBreezeService,
        private personService: PersonService,
        private userManagementService: UserManagementService,
    ) {
        super();

        const latestConnection$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person: Person) => person.getLatestConnection()),
        );

        const setPerson$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
        );

        this.latestConnection$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(RoleConnection),
            rxjsBreezeService.entityTypeChangedInSave(Connection),
        ).pipe(
            startWith(undefined),
            switchMap(() => latestConnection$),
            this.takeUntilDestroyed(),
        );

        this.roleConnections$ = combineLatest([this.latestConnection$, setPerson$]).pipe(
            switchMap(([_change, person]) => this.personService.getActiveRoleConnections(person.personId)),
            map((roleConnections) => {
                const rc = roleConnections
                    // filter out null roles, can happen when member of private team
                    .filter(({ role }) => !!role)
                    .filter(DirectorySharedService.isAccessLevelRoleConnection)
                    // role can be null when adding a role to a person
                    // where the role is created within that dialog, and the role being created has been given another person
                    .sort((a, b) => a.role && b.role ? a.role.label.localeCompare(b.role.label) : 0);

                const [organisationRoleConnections, teamRoleConnections] = ArrayUtilities.partition(rc, DirectorySharedService.isNotTeamBasedRoleConnection);
                return {
                    organisation: organisationRoleConnections,
                    team: teamRoleConnections,
                };
            }),
        );

        this.inviteDate$ = setPerson$.pipe(
            switchMap(async (person) => {
                await this.userManagementService.promiseToGetPersonDetailsByPersonId(person.personId);
                return person;
            }),
            map((person) => {
                const emailDate = person.getDetailValue(PersonDetailNames.WelcomeEmailDate);
                return emailDate ? new Date(emailDate) : undefined;
            }),
        );
    }

    public get chevronIconClass() {
        return this.showDetails
            ? "fal fa-chevron-up"
            : "fal fa-chevron-down";
    }

    public get userTypeInfo$() {
        return this.latestConnection$.pipe(
            emptyIfUndefinedOrNull(),
            map((conn) => {
                switch (conn.userType) {
                    case UserType.Leader:
                        return "Write access to embed, write access to teams, complete, customisable access";
                    case UserType.Collaborator:
                        return "View access to embed, write access to their teams, can participate in surveys";
                    case UserType.Viewer:
                        return "View access to embed, view access to their teams, can participate in surveys";
                    case UserType.None:
                        return "No access allowed";
                    case UserType.Coach:
                        return "Full access to embed, does not participate in surveys";
                    default:
                        throw new Error("Unknown user type");
                }
            }),
        );
    }

    public get userTypeLabel$() {
        return this.latestConnection$.pipe(
            emptyIfUndefinedOrNull(),
            map((conn) => UserTypeExtensions.singularLabel(conn.userType)),
        );
    }

    public connectionTypeLabel(connection: Connection) {
        return ConnectionTypeLabel.singular(connection.connectionType);
    }
}
