import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Person } from "../person/person";
import { CareerValuationCategoryValue } from "./career-valuation-category-value";
import { CareerValuationExtensions } from "./career-valuation-extensions";
import { ICulturalMeasurementEntity } from "./culturalMeasurementEntity.interface";
import { Organisation } from "./organisation";

export class CareerValuation extends BaseEntity<CareerValuation> implements ICulturalMeasurementEntity {
    public careerValuationId!: number;
    public organisationId!: number;
    public personId!: number;
    public authorId!: number;
    public creationDate!: Date;
    public notes!: string;
    public author!: Person;
    public categoryValues!: CareerValuationCategoryValue[];
    public organisation!: Organisation;
    public person!: Person;

    @EntityExtensions(CareerValuationExtensions)
    public extensions!: CareerValuationExtensions;
}

export const CareerValuationBreezeModel = new BreezeModelBuilder("CareerValuation", CareerValuation)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .orderByDesc("creationDate")
    .withActivePath("person.connections")
    .persistChangedEntity()
    .withGroupByField("personId")
    .withDateField("creationDate")
    .build();
