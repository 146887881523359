<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Products & services</h3>

    <div adapt-dialog-content>
        <div class="alert alert-guidance">
            <p>
                Describe the primary products or services for this value stream.
            </p>
        </div>

        <dx-list class="h-auto"
                 [items]="valueStream.products"
                 [allowItemDeleting]="true"
                 (onItemDeleted)="onItemDeleted($event)"
                 (onItemReordered)="onItemReordered($event)">
            <dxo-item-dragging [allowReordering]="true">
            </dxo-item-dragging>

            <div *dxTemplate="let item of 'item'">
                <dx-select-box [dataSource]="availableProductsDataSource"
                               [value]="item.product"
                               (onSelectionChanged)="onSelectionChanged($event, item.product)"
                               displayExpr="name">
                </dx-select-box>
            </div>
        </dx-list>

        <div class="mx-2">
            <!-- Add Product or Service to List -->
            <dx-select-box #selectBoxAddition
                           [dataSource]="availableProductsDataSource"
                           (onSelectionChanged)="onItemAdded($event)"
                           [value]="temporaryProduct"
                           displayExpr="name"
                           placeholder="Add a product or service...">
            </dx-select-box>

            <hr>

            <button adaptButtonIcon="add"
                    class="btn btn-primary"
                    [adaptBlockingClick]="newProduct">Create new product or service</button>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="ok"
                                   (saveClick)="ok()"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
