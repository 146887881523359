import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptHideIfEmptyModule } from "@common/ux/hide-if-empty/hide-if-empty.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLayoutModule } from "@common/ux/layout/layout.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptRerenderModule } from "@common/ux/rerender/rerender.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { provideRolePageRoute } from "@org-common/lib/architecture/link-role/link-role.component";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxDataGridModule, DxSelectBoxModule } from "devextreme-angular";
import { AdaptIntegratedArchitectureSharedModule } from "../integrated-architecture/integrated-architecture-shared.module";
import { AdaptProcessMapSharedModule } from "../process-map/process-map-shared.module";
import { AllocateTier1RoleComponent } from "./allocate-tier1-role/allocate-tier1-role.component";
import { DisplayRoleAllocationComponent } from "./display-role-allocation/display-role-allocation.component";
import { RelatedProcessMapsComponent } from "./related-process-maps/related-process-maps.component";
import { RoleDescriptionPageComponent } from "./role-description-page/role-description-page.component";
import { roleDescriptionRoute } from "./role-description-page/role-description-page.route";
import { RolePeoplePanelComponent } from "./role-people-panel/role-people-panel.component";
import { AdaptRoleSharedModule } from "./role-shared.module";

@NgModule({
    imports: [
        CommonModule,
        DxDataGridModule,
        DxAccordionModule,
        AdaptDirectorySharedModule,
        AdaptTooltipModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptButtonModule,
        AdaptFeaturesModule,
        AdaptLoadingSpinnerModule,
        AdaptIntegratedArchitectureSharedModule,
        AdaptProcessMapSharedModule,
        AdaptRoleSharedModule,
        AdaptOrganisationSharedModule,
        AdaptTwoColumnModule,
        AdaptEmbedShellModule,
        AdaptLayoutModule,
        DxSelectBoxModule,
        AdaptSystemisationModule,
        AdaptHideIfEmptyModule,
        AdaptRerenderModule,
        AdaptLabellingModule,
    ],
    exports: [
        DisplayRoleAllocationComponent,
        AllocateTier1RoleComponent,
        AdaptRoleSharedModule,
        RelatedProcessMapsComponent,
        RolePeoplePanelComponent,
    ],
    declarations: [
        DisplayRoleAllocationComponent,
        AllocateTier1RoleComponent,
        RoleDescriptionPageComponent,
        RelatedProcessMapsComponent,
        RolePeoplePanelComponent,
    ],
    providers: [
        provideRolePageRoute(roleDescriptionRoute),
    ],
})
export class AdaptRoleModule {
    public static readonly Routes = [
        ...roleDescriptionRoute.routes,
    ];
}
