import { Component, Input } from "@angular/core";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-stakeholders",
    templateUrl: "./configure-stakeholders.component.html",
})
export class ConfigureStakeholdersComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async initialiseData(): Promise<void> {
        return Promise.resolve();
    }
}
