import { Injectable, Injector } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { KeyFunction, KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { LocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/location";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleLocation, RoleLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-location";
import { RoleType } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Stakeholder, StakeholderBreezeModel } from "@common/ADAPT.Common.Model/organisation/stakeholder";
import { StakeholderStoryBreezeModel } from "@common/ADAPT.Common.Model/organisation/stakeholder-story";
import { ValueStream, ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamBusinessModel, ValueStreamBusinessModelBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-business-model";
import { ValueStreamProduct, ValueStreamProductBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-product";
import { ValueStreamStakeholder, ValueStreamStakeholderBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-stakeholder";
import { ValueStreamStakeholderStory, ValueStreamStakeholderStoryBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-stakeholder-story";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { RouteService } from "@common/route/route.service";
import { CommonTier1ArchitectureService } from "@org-common/lib/architecture/common-tier1-architecture.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { forkJoin, lastValueFrom, of } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class Tier1ArchitectureService extends BaseOrganisationService {
    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private routeService: RouteService,
        private organisationService: OrganisationService,
        private commonTier1ArchService: CommonTier1ArchitectureService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            this.commonTier1ArchService.getActiveValueStreams(),
            this.archData.getActiveRolesByPredicate(),
        ];
    }

    public getTier1RoleType() {
        return this.archData.getRoleTypeByCode(RoleTypeCode.Tier1);
    }

    // Value Streams

    public getValueStreamById(valueStreamId: number) {
        return this.commonDataService.getById(ValueStreamBreezeModel, valueStreamId);
    }

    /**
     * Get a value stream (other than the optionally provided existing id) with the provided name.
     * @param name The name of the value stream.
     * @param existingId The identifier of the existing value stream to exclude.
     * @returns An observable that resolves with the valueStream.
     */
    public getValueStreamByName(name: string, existingId?: number) {
        const predicate = new MethodologyPredicate<ValueStream>("name", "==", name);
        if (existingId) {
            predicate.and(new MethodologyPredicate<ValueStream>("valueStreamId", "!=", existingId));
        }

        return this.commonDataService.getByPredicate(ValueStreamBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    // Value Stream Business Models

    /**
     * Get the value stream business model for a specified value stream id.
     * @param valueStreamId The identifier of the value stream.
     * @returns An observable that resolve with an individual business model
     */
    public getValueStreamBusinessModelForValueStreamId(valueStreamId: number) {
        const predicate = new MethodologyPredicate<ValueStreamBusinessModel>("valueStreamId", "==", valueStreamId);

        return this.commonDataService.getByPredicate(ValueStreamBusinessModelBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    // Value Stream Products

    /**
     * Create a value stream product for a value stream.
     * @param valueStream The value stream for which to create the value stream product.
     * @returns An observable that resolves with the newly created valueStreamProduct.
     */
    public createValueStreamProductForValueStream(valueStream: ValueStream) {
        const defaults: Partial<ValueStream> = {
            valueStreamId: valueStream.valueStreamId,
            ordinal: valueStream.products.length,
        };

        return this.commonDataService.create(ValueStreamProductBreezeModel, defaults);
    }

    /**
     * Get the value stream products for a specified value stream.
     * @param valueStream The value stream for which to get value stream products.
     * @returns An observable that resolves with an array of valueStreamProducts.
     */
    public getValueStreamProductsForValueStream(valueStream: ValueStream) {
        const predicate = new MethodologyPredicate<ValueStreamProduct>("valueStreamId", "==", valueStream.valueStreamId);

        return this.commonDataService.getByPredicate(ValueStreamProductBreezeModel, predicate);
    }

    /**
     * Remove the valueStreamProduct and update the ordinals for the valueStream.
     * @param valueStreamProduct The valueStreamProduct to delete.
     * @returns An observable that resolves with the modified entities.
     */
    public removeValueStreamProduct(valueStreamProduct: ValueStreamProduct) {
        const valueStream = valueStreamProduct.valueStream;
        const ordinal = valueStreamProduct.ordinal;

        return this.commonDataService.remove(valueStreamProduct).pipe(
            tap(() => SortUtilities.updateIntegerSortedArrayAfterItemRemoval(valueStream.products, "ordinal", ordinal)),
            map(() => [...valueStream.products, valueStreamProduct]),
        );
    }

    // Stakeholders

    public getAllStakeholders() {
        return this.commonDataService.getAll(StakeholderBreezeModel);
    }

    public createStakeholder() {
        return this.commonDataService.create(StakeholderBreezeModel, {
            name: "",
            organisationId: this.organisationService.getOrganisationId(),
        });
    }

    // Stakeholder Stories

    public getAllStakeholderStories() {
        return this.commonDataService.getAll(StakeholderStoryBreezeModel);
    }

    public createStakeholderStoryForStakeholder(stakeholder: Stakeholder | ValueStreamStakeholder) {
        return this.commonDataService.create(StakeholderStoryBreezeModel, {
            stakeholderId: stakeholder.stakeholderId,
        });
    }

    // Value Stream Stakeholders

    /**
     * Create a value stream stakeholder for a value stream.
     * @param valueStream The value stream for which to create the value stream stakeholder.
     * @returns An observable that resolves with the newly created valueStreamStakeholder.
     */
    public createValueStreamStakeholderForValueStream(valueStream: ValueStream) {
        const defaults: Partial<ValueStream> = {
            valueStreamId: valueStream.valueStreamId,
            ordinal: valueStream.stakeholders.length,
        };

        return this.commonDataService.create(ValueStreamStakeholderBreezeModel, defaults);
    }

    /**
     * Get the value stream stakeholders for a specified value stream.
     * @param valueStream The value stream for which to get value stream stakeholders.
     * @returns An observable that resolves with an array of valueStreamStakeholders.
     */
    public getValueStreamCustomers(valueStream: ValueStream) {
        const predicate = new MethodologyPredicate<ValueStreamStakeholder>("valueStreamId", "==", valueStream.valueStreamId);

        return this.commonDataService.getByPredicate(ValueStreamStakeholderBreezeModel, predicate);
    }

    /**
     * Remove the valueStreamStakeholder and update the ordinals for the valueStream.
     * @param valueStreamStakeholder The valueStreamStakeholder to delete.
     * @returns An observable that resolves with the modified entities.
     */
    public removeValueStreamStakeholder(valueStreamStakeholder: ValueStreamStakeholder) {
        const valueStream = valueStreamStakeholder.valueStream;
        const ordinal = valueStreamStakeholder.ordinal;
        const entities = [
            ...valueStream.stakeholders,
            ...valueStreamStakeholder.stakeholderStories,
        ];

        const removeStories = valueStreamStakeholder.stakeholderStories.map((ent) => this.commonDataService.remove(ent));
        removeStories.push(of(undefined)); // otherwise forkJoin won't emit if there is no story
        return forkJoin(removeStories).pipe(
            switchMap(() => this.commonDataService.remove(valueStreamStakeholder)),
            tap(() => SortUtilities.updateIntegerSortedArrayAfterItemRemoval(valueStream.stakeholders, "ordinal", ordinal)),
            map(() => entities),
        );
    }

    // Value Stream Stakeholder Stories

    /**
     * Create a value stream stakeholder story for a value stream stakeholder.
     * @param valueStreamStakeholder The value stream stakeholder for which to create the value stream stakeholder story.
     * @returns An observable that resolves with the newly created valueStreamStakeholderStory.
     */
    public createValueStreamStakeholderStoryForValueStreamStakeholder(valueStreamStakeholder: ValueStreamStakeholder) {
        const defaults = {
            valueStreamStakeholderId: valueStreamStakeholder.valueStreamStakeholderId,
            ordinal: valueStreamStakeholder.stakeholderStories.length,
        };

        return this.commonDataService.create(ValueStreamStakeholderStoryBreezeModel, defaults);
    }

    /**
     * Remove the valueStreamStakeholderStory and update the ordinals for the valueStreamStakeholder.
     * @param valueStreamStakeholderStory The valueStreamStakeholderStory to delete.
     * @returns An observable that resolves with the modified entities.
     */
    public removeValueStreamStakeholderStory(valueStreamStakeholderStory: ValueStreamStakeholderStory) {
        const valueStreamStakeholder = valueStreamStakeholderStory.valueStreamStakeholder;
        const ordinal = valueStreamStakeholderStory.ordinal;

        return this.commonDataService.remove(valueStreamStakeholderStory).pipe(
            tap(() => SortUtilities.updateIntegerSortedArrayAfterItemRemoval(
                valueStreamStakeholder.stakeholderStories,
                "ordinal",
                ordinal,
            )),
            map(() => [...valueStreamStakeholder.stakeholderStories, valueStreamStakeholderStory]),
        );
    }

    /**
     *  Get the Leader role for the valueStream with the specified node identifier.
     * @param valueStreamId The identifier of the valueStream.
     * @returns An observable that resolves with the leader role.
     */
    public getLeaderRoleForValueStreamId(valueStreamId: number) {
        // this is from integrated-architecture-framework-data.service.ts - do we need this prime?
        // leave it here for now - may have to do with role in cache without role locations which will issue with the subsequence
        // predicate processing
        return this.commonDataService.getAll(RoleLocationBreezeModel).pipe(
            switchMap(() => this.getTier1RoleType()),
            switchMap((tier1RoleType) => {
                const valueStreamPredicate = new MethodologyPredicate<RoleLocation>("location.valueStreamId", "==", valueStreamId)
                    .and(new MethodologyPredicate<RoleLocation>("isSystemRoleLocation", "==", true));

                const predicate = new MethodologyPredicate<Role>("roleTypeId", "==", tier1RoleType!.roleTypeId)
                    .and(new MethodologyPredicate<Role>("roleLocations", "any", valueStreamPredicate));

                return this.archData.getActiveRolesByPredicate(predicate);
            }),
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getActiveValueStreams() {
        return this.commonTier1ArchService.getActiveValueStreams();
    }

    public createValueStream(name?: string) {
        // TODO Move this logic to the back end once we no longer have the Architecture V1
        // Add VS dialog (Architecture V2 will only allow name to be set initially)

        return this.organisationService.currentOrganisation$.pipe(
            take(1),
            switchMap(async (organisation) => {
                const maxOrdinal = Math.max(...(organisation?.valueStreams ?? [])
                    .filter((v) => v.isActive())
                    .map((v) => v.ordinal), -1);

                const valueStream = await lastValueFrom(this.commonDataService.create(ValueStreamBreezeModel, {
                    organisation,
                    name,
                    ordinal: maxOrdinal + 1,
                    startDate: moment().toDate(),
                }));

                const tier1RoleType = await lastValueFrom(this.getTier1RoleType());
                const role = await lastValueFrom(this.commonDataService.create(RoleBreezeModel, {
                    organisation,
                    roleType: tier1RoleType,
                    startDate: moment().toDate(),
                    connectionType: ConnectionType.Employee,
                    label: this.getRoleLabelForValueStream(tier1RoleType!, valueStream),
                }));

                const location = await lastValueFrom(this.commonDataService.create(LocationBreezeModel, {
                    organisationId: organisation?.organisationId,
                    valueStream,
                }));

                const roleLocation = await lastValueFrom(this.commonDataService.create(RoleLocationBreezeModel, {
                    role,
                    location,
                    isSystemRoleLocation: true,
                }));

                const businessModel = await lastValueFrom(this.commonDataService.create(ValueStreamBusinessModelBreezeModel, {
                    valueStream,
                }));

                return {
                    valueStream,
                    role,
                    location,
                    roleLocation,
                    businessModel,
                };
            }),
        );
    }

    public getRoleLabelForValueStream(roleType: RoleType, valueStream: ValueStream) {
        return `${roleType.defaultLabel} - ${valueStream.name}`;
    }

    public gotoDefaultRoute() {
        return this.routeService.gotoHome();
    }

    public getPrimedValueStreamKeyFunctions(valueStream: ValueStream) {
        const key = `primedValueStreamKeyFunctionLocations${valueStream.valueStreamId}`;
        const predicate = new MethodologyPredicate<KeyFunction>("valueStreamId", "==", valueStream.valueStreamId);
        return this.commonDataService.getWithOptions(KeyFunctionBreezeModel, key, {
            predicate,
        }).pipe(
            map((keyFunctions) => keyFunctions.sort((a, b) => a.ordinal - b.ordinal)),
        );
    }
}
