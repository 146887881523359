/* eslint-disable max-classes-per-file */
import { Component, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionRole } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { KeyFunctionSupplementaryData } from "@common/ADAPT.Common.Model/organisation/key-function-supplementary-data";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { EditSystemLocationsComponent } from "app/features/systemisation/edit-system-locations/edit-system-locations.component";
import { dxTabPanelItem } from "devextreme/ui/tab_panel";
import { EditKeyFunctionDetailsComponent } from "../edit-key-function-details/edit-key-function-details.component";
import { EditKeyFunctionRolesComponent } from "../edit-key-function-roles/edit-key-function-roles.component";
import { ListKeyFunctionTeamsComponent } from "../list-key-function-teams/list-key-function-teams.component";

interface IKeyFunctionTab extends dxTabPanelItem {
    guidance?: string;
    isEditorValid?: boolean,
}

@Component({
    selector: "adapt-edit-key-function-dialog",
    templateUrl: "./edit-key-function-dialog.component.html",
    styleUrls: ["./edit-key-function-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditKeyFunctionDialogComponent extends BaseDialogWithDiscardConfirmationComponent<KeyFunction> {
    public readonly dialogName = "EditKeyFunction";
    protected readonly autoResolveData = this.keyFunction;

    @ViewChild(EditKeyFunctionDetailsComponent)
    public keyFunctionDetailsComponent?: EditKeyFunctionDetailsComponent;
    @ViewChild(ListKeyFunctionTeamsComponent)
    public keyFunctionTeamsComponent?: ListKeyFunctionTeamsComponent;
    @ViewChild(EditKeyFunctionRolesComponent)
    public keyFunctionRolesComponent?: EditKeyFunctionRolesComponent;
    @ViewChild(EditSystemLocationsComponent)
    private editSystemLocationsComponent?: EditSystemLocationsComponent;

    public keyFunctionTabs = this.generateKeyFunctionTabs();

    public entitiesToConfirm: IBreezeEntity[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public keyFunction: KeyFunction,
        private commonIntArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
    ) {
        super();

        if (!keyFunction.supplementaryData) {
            throw new Error("Key Function Supplementary data should be created before editing");
        }
        if (!keyFunction.leaderRole) {
            throw new Error("Key Function Leader role should have been primed before editing");
        }

        this.entitiesToConfirm = [
            keyFunction,
            keyFunction.leaderRole,
            keyFunction.supplementaryData,
        ];
    }

    public get entitiesAreUnmodifiedOrInvalid() {
        let result = super.entitiesAreUnmodifiedOrInvalid;
        if (!result) {
            // for each new role, there must be corresponding KeyFunctionRole
            result = this.hasUnusedAddedType(Role, KeyFunctionRole, "roleId");
        }

        return result || !!this.editSystemLocationsComponent?.isCreatingAndAdding;
    }

    public get isInvalid() {
        return this.keyFunctionTabs.some((tab) => !tab.isEditorValid);
    }

    public addEntityToConfirm(entity: IBreezeEntity | IBreezeEntity[]) {
        if (!Array.isArray(entity)) {
            entity = [entity];
        }

        this.entitiesToConfirm.push(...entity);
        this.entitiesToConfirm = ArrayUtilities.distinct(this.entitiesToConfirm);
    }

    private generateKeyFunctionTabs(): IKeyFunctionTab[] {
        const suppData = this.keyFunction.supplementaryData!;
        const tabs = [
            { title: "Details", template: "detailsTemplate", isEditorValid: true },
            new SupplementaryDataTab("Purpose", "The purpose of this key function.", suppData, "purpose", true),
            new SupplementaryDataTab("Accountabilities", "What needs to be delivered from this function, should be limited to 3-4 accountabilities.", suppData, "accountabilities", true),
            new SupplementaryDataTab("IT Systems", "Identify IT systems that can play an important role in managing the work of the function. e.g. Customer Relationship Management system.", suppData, "systems", true),
            { title: "Key Roles", guidance: "What are the key roles that are essential to do the work this function.", template: "keyRolesTemplate", isEditorValid: true },
            { title: "Teams", template: "teamsTemplate", isEditorValid: true },
        ];
        if (this.commonIntArchAuthService.currentPersonCanReadTier2()) {
            tabs.push({ title: "Systems", template: "systemsTemplate", isEditorValid: true });
        }

        return tabs;
    }
}

class SupplementaryDataTab implements IKeyFunctionTab {
    public template = "richTextTemplate";

    public constructor(
        public title: string,
        public guidance: string,
        private supplementaryData: KeyFunctionSupplementaryData,
        private field: "purpose" | "accountabilities" | "systems",
        public isEditorValid: boolean,
    ) {
    }

    public get text() {
        return this.supplementaryData[this.field];
    }

    public set text(value: string | undefined) {
        this.supplementaryData[this.field] = value;
    }
}
