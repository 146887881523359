import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { switchMap } from "rxjs/operators";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";

@Component({
    selector: "adapt-ci-orbit-grid",
    templateUrl: "./ci-orbit-grid.component.html",
})
export class CulturalIndexOrbitGridComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;

    public DateFormats = DateFormats;
    public culturalLeadershipConfiguration?: CulturalLeadership;
    public dxGridHelper: DxGridWrapperHelper;

    private culturalLeaderNameMap: any = {}; // to lookup customizeText as suggested by https://www.devexpress.com/Support/Center/Question/Details/T403719/dxdatagrid-how-to-enable-custom-group-order
    public data: ICulturalLeadershipPersonalAnalysisData[] = [];

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        public culturalIndexService: CulturalIndexService,
        public culturalIndexUiService: CulturalIndexUiService,
        private culturalLeadershipService: CulturalLeadershipFrameworkService,
        private culturalIndexFilterService: CulturalIndexFilterService,
        private orgService: OrganisationService,
    ) {
        super();

        this.culturalIndexFilterService.filterListener
            .pipe(
                switchMap(() => this.updateData()),
                this.takeUntilDestroyed(),
            )
            .subscribe();

        this.dxGridHelper = new DxGridWrapperHelper("adapt-ci-orbit-grid", jQuery(elementRef.nativeElement));
        this.dxGridHelper.saveGridState(`${this.orgService.getOrganisationId()}`);
        this.dxGridHelper.callGrid((grid) => grid.setColumnsReady());
    }

    public async ngOnInit() {
        this.culturalLeadershipConfiguration = await this.culturalLeadershipService.promiseToGetCulturalLeadershipConfiguration();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.date) {
            this.updateData();
        }
    }

    private async updateData() {
        const filterParameters = { ...this.culturalIndexFilterService.filter, date: this.date };
        this.data = await this.culturalIndexService.promiseToGetCulturalIndexAnalysisData(filterParameters);
    }


    @Autobind
    public calculateCulturalLeadersCellValue(data: ICulturalLeadershipPersonalAnalysisData) {
        if (data.culturalLeader) {
            return data.culturalLeader.fullName;
        }

        return undefined;
    }

    @Autobind
    public calculateCulturalLeadersGroupValue(data: ICulturalLeadershipPersonalAnalysisData) {
        if (data.culturalLeader) {
            this.culturalLeaderNameMap[data.culturalLeader.personId] = data.culturalLeader.fullName;

            return data.culturalLeader.personId;
        } else {
            return undefined;
        }
    }

    @Autobind
    public culturalLeadersCustomizeText(e: any) {
        if (!e.valueText) {
            return "None allocated";
        } else {
            return this.culturalLeaderNameMap[e.valueText] || e.valueText;
        }
    }

    @Autobind
    public onGridInitialized(event: InitializedEvent) {
        this.dxGridHelper.initialiseGrid(event);

        event.component!.option().onToolbarPreparing = this.dxGridHelper.addResetToolbarButton;
    }
}
