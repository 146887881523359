<div class="form edit-role-connections">
    <div class="role-connections">
        <div class="row role-connections-header"
             [ngSwitch]="model?.identifier">
            <div *ngSwitchCase="'person'"
                 class="col-lg-3 column-person">
                People
            </div>
            <div *ngSwitchCase="'role'"
                 class="col-lg-3 column-role">
                Roles
            </div>
            <div class="col-lg-3 column-start-date">
                Start date
            </div>
            <div class="col-lg-3 column-role-end-date">
                End date
            </div>
        </div>
        <adapt-edit-role-connection *ngFor="let roleConnection of includedAndNotHistoric"
                                    [minStartDate]="connection?.startDate"
                                    [roleConnection]="roleConnection"
                                    [model]="model"
                                    (roleConnectionChanged)="onRoleConnectionChanged($event)"
                                    (roleConnectionDeleted)="onRoleConnectionDeletion($event)">
        </adapt-edit-role-connection>

        <div class="py-3 px-1">
            <button adaptButton="addLink"
                    [disabled]="hasIncomplete"
                    [title]="hasIncomplete ? 'Please select a ' + model?.singularName + ' above before adding another new one' : ''"
                    (click)="promiseToAddRoleConnection()">Add {{model?.singularName?.toLowerCase()}}</button>
        </div>

        <div class="px-1">
            <button *ngIf="hasHistoric"
                    class="btn btn-link toggle-show-historic"
                    adaptButtonIcon="fa {{showHistoric ? 'fa-chevron-up' : 'fa-chevron-down'}}"
                    (click)="toggleShowHistoric()">Previous {{model?.pluralName?.toLowerCase()}}</button>
        </div>

        <div *ngIf="hasHistoric && showHistoric">
            <adapt-edit-role-connection *ngFor="let roleConnection of includedAndHistoric"
                                        [minStartDate]="connection?.startDate"
                                        [roleConnection]="roleConnection"
                                        [model]="model"
                                        (roleConnectionChanged)="onRoleConnectionChanged($event)"
                                        (roleConnectionDeleted)="onRoleConnectionDeletion($event)">
            </adapt-edit-role-connection>
        </div>
    </div>
</div>
