<adapt-dialog [maxWidth]="800"
              [padding]="false">
    <h3 adapt-dialog-title>Edit Key Function</h3>

    <dx-tab-panel adapt-dialog-content
                  class="edit-key-function-dialog"
                  tabsPosition="left"
                  [items]="keyFunctionTabs"
                  [swipeEnabled]="false">
        <div *dxTemplate="let item of 'title'">
            {{item.title}}
        </div>

        <div *dxTemplate="let item of 'detailsTemplate'">
            <adapt-edit-key-function-details #focus
                                             [keyFunction]="keyFunction"
                                             (entityModified)="addEntityToConfirm($event)"></adapt-edit-key-function-details>
        </div>

        <div *dxTemplate="let item of 'richTextTemplate'">
            <div class="alert alert-info">
                <p>{{item.guidance}}</p>
            </div>
            <adapt-html-editor [(contents)]="item.text"
                               data-test="key-function-property-input"
                               (isValidChange)="item.isEditorValid = $event"></adapt-html-editor>
        </div>

        <div *dxTemplate="let item of 'keyRolesTemplate'">
            <div class="alert alert-info">
                <p>{{item.guidance}}</p>
            </div>
            <adapt-edit-key-function-roles [keyFunction]="keyFunction"
                                           (populated)="addEntityToConfirm($event)"
                                           (added)="addEntityToConfirm($event)"
                                           data-test="edit-key-function-roles"></adapt-edit-key-function-roles>
        </div>

        <div *dxTemplate="let item of 'teamsTemplate'">
            <adapt-list-key-function-teams [keyFunction]="keyFunction"
                                           [allowEditing]="true"
                                           [showTeamMembers]="false"
                                           (populated)="addEntityToConfirm($event)"
                                           (added)="addEntityToConfirm($event)"
                                           data-test="list-key-function-teams"></adapt-list-key-function-teams>
        </div>

        <div *dxTemplate="let item of 'systemsTemplate'">
            <adapt-edit-system-locations [keyFunction]="keyFunction"
                                         (entitiesChange)="addEntityToConfirm($event)"
                                         data-test="edit-key-function-systems"></adapt-edit-system-locations>
        </div>
    </dx-tab-panel>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="isInvalid"
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
