<div class="edit-role-connection row"
     [ngSwitch]="model.identifier">
    <div *ngSwitchCase="'role'"
         class="column-role col-lg-3">
        <adapt-select-role [role]="roleConnection?.role"
                           [readonlyModeOnSelection]="true"
                           [filter]="roleFilter"
                           (roleChange)="roleConnection!.role = $event; onChanged()"></adapt-select-role>
    </div>
    <div *ngSwitchCase="'person'"
         [ngSwitch]="!!roleConnection?.connection"
         class="column-role col-lg-3">
        <adapt-link-person *ngSwitchCase="true"
                           [person]="roleConnection?.connection?.person"></adapt-link-person>
        <adapt-select-person *ngSwitchCase="false"
                             data-test="select-role-person"
                             [person]="roleConnection?.connection?.person"
                             (personChange)="onPersonChanged($event)"
                             [filter]="connectionFilter"></adapt-select-person>
    </div>

    <div class="column-start-date col-lg-3">
        <dx-date-box class="date-cell-content"
                     [min]="minStartDate"
                     [max]="maxStartDate"
                     [value]="roleConnection?.startDate"
                     (valueChange)="onStartDateChanged($any($event))"></dx-date-box>
    </div>

    <div [ngSwitch]="showEndDate"
         class="column-end-date col-lg-3">
        <dx-date-box #endDateBox
                     *ngSwitchCase="true"
                     class="date-cell-clear-content"
                     [value]="endDate"
                     (valueChange)="onEndDateChanged($any($event))"
                     [showClearButton]="true"
                     [min]="minEndDate"
                     [max]="maxEndDate"
                     [showClearButton]="!endDateRequired"
                     [opened]="endDateOpened"
                     (onClosed)="onEndDateClosed()"></dx-date-box>

        <button *ngSwitchCase="false"
                adaptButtonIcon="clear"
                class="btn btn-link"
                [disabled]="!roleConnection?.role || !roleConnection?.connection || isEmployeeRole || !canEndConnection || startDateInFuture"
                [title]="endConnectionDisableMessage"
                (click)="endRoleConnection()">End role connection</button>
    </div>

    <div class="col-lg-3">
        <div class="role-connection-action">
            <ng-container *ngIf="roleConnection?.isHistoric()">
                <button adaptButtonIcon="fal fa-fw fa-arrow-circle-left"
                        class="btn btn-link me-3"
                        [disabled]="!canReactivate"
                        [title]="cannotReactivateMessage"
                        (click)="promiseToReactivate()">Reactivate</button>
                <button adaptButtonIcon="delete"
                        class="btn btn-link me-3"
                        *ngIf="!roleConnection?.entityAspect?.entityState?.isAdded()"
                        (click)="promptToDeleteRoleConnection()"> Delete</button>
            </ng-container>
            <button adaptButtonIcon="cancel"
                    class="btn btn-link"
                    *ngIf="roleConnection?.entityAspect?.entityState?.isAdded()"
                    (click)="onDeletion()">Cancel</button>
            <button adaptButtonIcon="delete"
                    class="btn btn-link me-3"
                    *ngIf="!roleConnection?.entityAspect?.entityState?.isAdded() && startDateInFuture"
                    (click)="promptToDeleteRoleConnection()"> Delete</button>
        </div>
    </div>
</div>
