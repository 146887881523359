<ng-container *adaptLoading="!activeTeamLocations">
    <dx-list *ngIf="activeTeamLocations!.length > 0; else noTeamsTemplate || defaultNoTeamsTemplate"
             class="dx-list-boxed h-auto"
             [dataSource]="activeTeamLocations"
             [hoverStateEnabled]="false"
             [focusStateEnabled]="false"
             [activeStateEnabled]="false"
             [allowItemDeleting]="allowEditing"
             (onItemReordered)="updateOrdinals(activeTeamLocations!, $event)"
             (onItemDeleted)="removeTeamLocation($event)">
        <dxo-item-dragging [allowReordering]="allowEditing"></dxo-item-dragging>

        <div *dxTemplate="let teamLocation of 'item'"
             class="key-function-team"
             [ngClass]="allowEditing ? 'key-function-team-editing' : ''">
            <adapt-link-team [team]="teamLocation.team"
                             [showIcon]="true"
                             [showLabels]="!allowEditing"
                             [focusPersonId]="focusPersonId"
                             class="ms-n2 mb-1"></adapt-link-team>
            <adapt-team-membership *ngIf="showTeamMembers"
                                   class="d-block mt-1"
                                   [team]="teamLocation.team"></adapt-team-membership>
        </div>
    </dx-list>

    <adapt-select-team *ngIf="allowEditing"
                       class="d-block mt-2"
                       [team]="addedTeam"
                       (teamChange)="addTeam($event)"
                       [disabled]="!!addedTeam"
                       [filter]="notCurrentTeamFilter"
                       data-test="select-key-function-team"></adapt-select-team>

    <ng-template #defaultNoTeamsTemplate>
        No teams have been assigned to this key function.
    </ng-template>
</ng-container>
