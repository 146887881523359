import { Injectable } from "@angular/core";
import { KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { keyFunctionPageRoute } from "app/features/architecture/key-functions/key-function-page/key-function-page.route";
import { organisationMapPageRoute } from "app/organisation/organisation-map-page/organisation-map-page.route";
import { EMPTY } from "rxjs";

@Injectable()
export class KeyFunctionsDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilitiesService: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
    ) {
    }

    public buildDynamicNodes() {
        return [
            this.buildDynamicKeyFunctionNode(),
        ];
    }

    private buildDynamicKeyFunctionNode() {
        const dynamicTitleFunction = this.navUtilitiesService.createDynamicValueFunctionForOptions({
            entityIdParamName: "keyFunctionId",
            getEntityByParamCallback: (id: number) => this.commonDataService.getById(KeyFunctionBreezeModel, id),
            getValue: (keyFunction) => keyFunction.name,
        });

        return this.navUtilitiesService.nodeBuilderForController(keyFunctionPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(organisationMapPageRoute.id)
            .build();
    }
}
