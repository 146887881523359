import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";

@Component({
    selector: "adapt-team-card",
    templateUrl: "./team-card.component.html",
    styleUrls: ["./team-card.component.scss"],
})
export class TeamCardComponent extends BaseComponent {
    @Input() public set team(value: Team | undefined) {
        if (value) {
            this.team$.next(value);
        }
    }
    public team$ = new ReplaySubject<Team>(1);

    @Input() public showChildren = false;
    @Input() public childrenExpanded = false;
    @Input() public filterTeams?: (teams: Team[]) => Team[] | Observable<Team[]>;

    public childTeams$: Observable<Team[]>;

    private triggerChildrenUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        teamService: CommonTeamsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
        this.childTeams$ = this.triggerChildrenUpdate$.pipe(
            switchMap(() => this.team$),
            switchMap((team) => teamService.getActiveChildTeams(team)),
            switchMap((teams) => TeamCardComponent.applyTeamsFilter(teams, this.filterTeams)),
        );

        rxjsBreezeService.entityTypeChanged(Team).pipe(
            withLatestFrom(this.team$),
            filter(([changedTeam, currentTeam]) => changedTeam.teamId === currentTeam.teamId || changedTeam.parentTeamId === currentTeam.teamId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerChildrenUpdate$.next());
    }

    public static applyTeamsFilter(teams: Team[], filterFunction?: (teams: Team[]) => Team[] | Observable<Team[]>) {
        if (!teams) {
            return of([]);
        }

        if (!filterFunction) {
            return of(teams);
        }

        const filtered = filterFunction(teams);
        return Array.isArray(filtered)
            ? of(filtered)
            : filtered;
    }
}
