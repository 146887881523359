<adapt-dashboard-element name="Peer Catch-ups"
                         [url]="url$ | async"
                         *ngIf="showElement">
    <adapt-catchup-ratings-graph class="catchup-ratings-graph"
                                 *ngIf="teamMembers.length > 1"
                                 [catchups]="peerCatchUps"
                                 [totalRelationshipCount]="totalRelationshipCount"></adapt-catchup-ratings-graph>

    <ol class="catchup-counts">
        <li *ngFor="let catchupCount of catchupCounts">
            <span class="catchup-count">{{catchupCount.count}}</span>
            <span>{{catchupCount.label}}</span>
        </li>
    </ol>

    <button *adaptIfAuthorised="EditMyPeerCatchup;Entity:team"
            adaptButtonIcon="add"
            (click)="newCatchup()">Start a new catch-up</button>
</adapt-dashboard-element>
