import { Component, Inject, Injector } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ExportType } from "../export-type-selector-step/export-type-selector-step.component";
import { Exporter, StepName } from "../exporter";
import { ExporterUtil } from "../exporter-util";

@Component({
    selector: "adapt-process-map-exporter-dialog",
    templateUrl: "./process-map-exporter-dialog.component.html",
    styleUrls: ["./process-map-exporter-dialog.component.scss"],
})
export class ProcessMapExporterDialogComponent extends BaseDialogComponent<ProcessMap> {
    public readonly dialogName = "ProcessStepExporter";

    public stepNames: StepName[];
    public currentStepIndex = 0;
    public exportType?: ExportType;
    public exporter?: Exporter;

    public readonly StepName = StepName;

    public started = false;

    private exportTypeUpdater = this.createThrottledUpdater((exportType: ExportType) => this.processExportType(exportType));

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public processMap: ProcessMap,
        private injector: Injector,
    ) {
        super();

        // this will change corresponds to export type selected
        this.stepNames = [StepName.SelectExportType];
        ExporterUtil.cleanExporterInstances();
    }

    public onExportTypeChanged(exportType: ExportType) {
        this.exportTypeUpdater.next(exportType);
    }

    private processExportType(exportType: ExportType) {
        this.exportType = exportType;
        const previousExporter = this.exporter;
        this.exporter = ExporterUtil.getExporterForType(exportType);
        if (previousExporter && previousExporter !== this.exporter) {
            // for case where we switch export type after selecting people - don't have to choose again
            this.exporter.rolePersonAllocations = previousExporter.rolePersonAllocations;
        }

        this.stepNames = this.exporter.steps;
    }

    public onExportPrefixChanged(prefix?: string) {
        if (this.exporter) {
            this.exporter.prefix = prefix;
        }
    }

    public get canGoNext() {
        return this.exporter!.canGoNext(this.stepNames[this.currentStepIndex]);
    }

    public next() {
        this.currentStepIndex++;
    }

    public previous() {
        this.currentStepIndex--;
    }

    public isCurrentStep(stepName: StepName) {
        return this.stepNames[this.currentStepIndex] === stepName;
    }

    @Autobind
    public export() {
        this.started = true;
        return this.exporter!.export(this.injector, this.processMap);
    }
}
