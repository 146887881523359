<div class="chart-wrapper"
     *adaptLoading="isLoading">
    <dx-chart (onInitialized)="onInitialized($event)"
              [dataSource]="timelineData"
              [series]="series"
              [pathModified]="true"
              title="Cultural Index timeline">
        <dxo-common-series-settings argumentField="date"
                                    type="stackedarea">
        </dxo-common-series-settings>
        <dxo-argument-axis>
            <dxo-label alignment="right"
                       format="monthAndYear"
                       overlappingBehavior="rotate"
                       [rotationAngle]="-45">
            </dxo-label>
        </dxo-argument-axis>
        <dxo-legend [visible]="true">
        </dxo-legend>
        <dxo-loading-indicator>
            <dxo-font [size]="30">
            </dxo-font>
        </dxo-loading-indicator>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip">
        </dxo-tooltip>
        <dxo-margin [bottom]="50">
        </dxo-margin>
        <dxo-size [height]="responsiveService.currentBreakpoint.isMobileSize ? 300 : 600">
        </dxo-size>
    </dx-chart>

    <adapt-no-cultural-measurement *ngIf="!timelineData.length"
                                   label="Cultural Indexes"></adapt-no-cultural-measurement>
</div>
