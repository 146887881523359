import { Component, Injector, OnInit } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { systemsPageRoute } from "app/features/systemisation/systems-page/systems-page.route";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-process-map-page",
    templateUrl: "./process-map-page.component.html",
})
export class ProcessMapPageComponent extends BaseRoutedComponent implements OnInit {
    public constructor(
        private systemisationService: SystemisationService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        const processMapIdRouteParam = this.getRouteParam("processMapId");
        const processMapId = Number(processMapIdRouteParam);
        if (isNaN(processMapId)) {
            systemsPageRoute.gotoRoute()
                .subscribe(() => this.notifyActivated());
            return;
        }

        this.systemisationService.getSystemComponentForProcessMapRedirect(processMapId).pipe(
            switchMap((systemComponent?: SystemComponent) => {
                return systemComponent
                    ? systemPageRoute.gotoRoute({ systemEntityId: systemComponent.systemEntityId })
                    : systemsPageRoute.gotoRoute();
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.notifyActivated());
    }
}
