import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ServiceUri } from "@common/configuration/service-uri";
import saveAs from "file-saver";
import { finalize, tap } from "rxjs/operators";
import { Exporter, StepName } from "./exporter";

interface IRolePersonAllocationBindingModel {
    roleId: number;
    personId: number | undefined;
}

interface IExcelExporterBindingModel {
    processMapId: number;
    rolePersonAllocations: IRolePersonAllocationBindingModel[];
}

export class ExcelExporter extends Exporter {
    public steps = [StepName.SelectExportType, StepName.AllocateRoles, StepName.ExportConfirmation];

    public export(
        injector: Injector,
        processMap: ProcessMap,
    ) {
        const httpClient = injector.get(HttpClient);
        const rolePersonAllocationsModel: IRolePersonAllocationBindingModel[] = [];
        for (const role of this.rolePersonAllocations.roles) {
            const person = this.rolePersonAllocations[role.roleId];
            if (person) {
                rolePersonAllocationsModel.push({
                    roleId: role.roleId,
                    personId: person.personId,
                });
            }
        }

        const bindingModel: IExcelExporterBindingModel = {
            rolePersonAllocations: rolePersonAllocationsModel,
            processMapId: processMap.processMapId,
        };

        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/ProcessMapExport`;
        return httpClient.post(
            uri,
            bindingModel,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob", // makes response.body a Blob object
                observe: "response", // without this, response.body will be unknown
            },
        ).pipe(
            tap((response) => saveAs(response.body!, `${processMap.name}.xlsx`)),
            finalize(() => this.completed = true),
        );
    }
}
