import { Component, Inject, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { CareerValuationHistoryComponent } from "../cvt-history/cvt-history.component";

@Component({
    selector: "adapt-cvt-history-dialog",
    templateUrl: "./cvt-history-dialog.component.html",
})
export class CareerValuationHistoryDialogComponent extends BaseDialogComponent<Person> {
    @ViewChild(CareerValuationHistoryComponent) public cvtHistoryComponent?: CareerValuationHistoryComponent;

    public readonly title = "Career Valuation history";

    public readonly dialogName = "CareerValuationHistory";
    public autoResolveData = this.person;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
    ) {
        super();
    }

    @Autobind
    public exportGrid() {
        if (this.cvtHistoryComponent) {
            this.cvtHistoryComponent.exportData();
        }
    }
}
