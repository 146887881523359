import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { ResilientBusinessGoal } from "@common/ADAPT.Common.Model/organisation/resilient-business-goal";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { ResilientBusinessGoalsService } from "../rb-goals.service";

@Component({
    selector: "adapt-resilient-business-goals-map",
    templateUrl: "./rb-goals-map.component.html",
    styleUrls: ["./rb-goals-map.component.scss"],
})
export class ResilientBusinessGoalsMapComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input() public verticalLayout = false;
    @Input() public noGoalsTemplate?: TemplateRef<any>;
    @ViewChild("zoneMapTemplate") public zoneMapTemplate?: TemplateRef<any>;

    public goals?: ResilientBusinessGoal[];
    public hasGoals = false;

    public constructor(
        private resilientBusinessGoalsService: ResilientBusinessGoalsService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.loadGoals();

        this.rxjsBreezeService.entityTypeChangedInSave(ResilientBusinessGoal)
            .pipe(
                map(this.loadGoals),
                this.takeUntilDestroyed(),
            ).subscribe();
    }

    public ngAfterViewInit() {
        if (!this.noGoalsTemplate) {
            this.noGoalsTemplate = this.zoneMapTemplate;
        }
    }

    public contentByZone(zone: Zone) {
        const goal = this.goals?.find((goalUnderTest) => goalUnderTest.zone === zone);
        return goal && goal.content && goal.content !== ""
            ? goal.content
            : "No resilient business goals have been defined for this zone";
    }

    @Autobind
    private async loadGoals() {
        this.goals = await lastValueFrom(this.resilientBusinessGoalsService.getResilientBusinessGoals());
        this.hasGoals = this.goals.some((i) => !!i.content);
    }
}
