import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { DxTabPanelModule } from "devextreme-angular";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { EditResilientBusinessGoalsDialogComponent } from "./edit-rb-goals-dialog/edit-rb-goals-dialog.component";
import { ResilientBusinessGoalsAuthService } from "./rb-goals-auth.service";
import { ResilientBusinessGoalsMapComponent } from "./rb-goals-map/rb-goals-map.component";
import { ResilientBusinessGoalsPageComponent } from "./rb-goals-page/rb-goals-page.component";
import { rbGoalsRoute } from "./rb-goals-page/rb-goals-page.route";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptSeeMoreModule,
        AdaptTooltipModule,
        DxTabPanelModule,
        AdaptZoneMapModule,
        AdaptCallToActionModule,
        AdaptAuthorisationModule,
        AdaptContextSidebarModule,
        AdaptDashboardElementModule,
    ],
    exports: [
        ResilientBusinessGoalsMapComponent,
    ],
    declarations: [
        ResilientBusinessGoalsPageComponent,
        EditResilientBusinessGoalsDialogComponent,
        ResilientBusinessGoalsMapComponent,
    ],
})
export class AdaptResilientBusinessGoalsModule {
    public static readonly Routes = [
        ...rbGoalsRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        ResilientBusinessGoalsAuthService.registerAccess(authorisationService);
    }
}
