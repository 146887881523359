import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { AnalyseCareerValuationDifferentialsPageComponent } from "./analyse-cvt-differentials-page.component";

const AnalyseCareerValuationDifferentialsPageTitle = "Analyse Differentials";

export const analyseCareerValuationDifferentialsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-career-valuation-differentials-page", AnalyseCareerValuationDifferentialsPageComponent)
    .atOrganisationUrl("/people/career-valuation/analyse-differentials")
    .redirectToThisRouteFromOrganisationUrl("/people/careerValuation/analyseDifferential")
    .verifyingAccess(CareerValuationAuthService.ReadQuantitativeCareerValuation)
    .verifyingFeatures(FeatureName.CulturalNetworkCareerValuation)
    .withTitle(AnalyseCareerValuationDifferentialsPageTitle)
    .requiresLogin(true)
    .build();
