<adapt-dashboard-element name="Team assessment"
                         [url]="url$ | async">
    <ng-container *adaptLoadingUntilFirstEmit="let latestSurvey of latestSurvey$">
        <div *ngIf="latestSurvey; else noSurveyTemplate"
             class="d-flex flex-column">
            <adapt-display-categories-summary [survey]="latestSurvey"
                                              [surveyQuestions]="surveyQuestions"></adapt-display-categories-summary>
            <span class="mt-2 ms-auto small">Survey closed: {{latestSurvey.endTime | adaptDateTime}}</span>
        </div>
        <ng-template #noSurveyTemplate>
            <adapt-call-to-action-inline [featureUrl]="url$ | async">
                Use the team assessment to measure where the team is currently at, and to allow you to work towards moving each attribute to
                a healthy level.
            </adapt-call-to-action-inline>
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
