<adapt-dialog class="add-people-dialog"
              [maxWidth]="850">
    <h3 adapt-dialog-title>Add {{dialogTitleSuffix.toLowerCase()}}</h3>

    <ng-container adapt-dialog-content>
        <div class="card people-area">
            <div class="card-header">
                <h2>Set the people you wish to add to the organisation</h2>
                <button adaptButton="addLink"
                        class="ms-auto me-5"
                        (click)="addAnotherPerson()">Add another person</button>
            </div>
            <div class="card-body">
                <dx-data-grid [dataSource]="addPeopleGridData"
                              [showRowLines]="true"
                              [showBorders]="true"
                              [allowColumnResizing]="true"
                              (onCellPrepared)="onCellPrepared($event)"
                              (onInitNewRow)="onInitNewRow($event)"
                              (onToolbarPreparing)="customiseToolbar($event.toolbarOptions)"
                              [wordWrapEnabled]="true"
                              (paste)="handlePaste($event)"
                              (onInitialized)="onInitialised($event)">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-editing mode="cell"
                                 [allowDeleting]="true"
                                 [allowUpdating]="true"
                                 [useIcons]="true"
                                 [confirmDelete]="false">
                    </dxo-editing>

                    <dxi-column dataField="addedTime"
                                dataType="date"
                                [visible]="false"
                                sortOrder="desc">
                    </dxi-column>
                    <dxi-column dataField="firstName"
                                caption="First name"
                                [width]="195">
                    </dxi-column>
                    <dxi-column dataField="lastName"
                                caption="Last name"
                                [width]="195">
                    </dxi-column>
                    <dxi-column dataField="email"
                                [width]="285">
                    </dxi-column>
                </dx-data-grid>
                <small>(Note: values can be pasted {{pasteCommand}} from an Excel spreadsheet or any comma-separated source)</small>
            </div>
        </div>

        <div *ngIf="!config?.connectionType"
             class="card">
            <div class="card-header">
                <h2>How are these people connected to your organisation</h2>
            </div>
            <div class="card-body">
                <div class="alert alert-warning mt-2">
                    Employee are those people who are generally employed by your organisation. Depending on how you structure your
                    organisation
                    these people may by full-time, part-time or even contractors. Employee's will be included in surveys, cultural
                    measurements,
                    etc.
                    <br /><br />
                    Stakeholders are those people connected to your organisation in other ways - they might be external consultants,
                    customers,
                    investors, potential employees, etc.
                </div>
                <div class="limited-width-area">
                    <adapt-select-connection-type [(connectionType)]="connectionType"
                                                  (connectionTypeChange)="connectionTypeChange()"
                                                  data-test="select-connection-type"></adapt-select-connection-type>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2>Set user type and access level for these people</h2>
            </div>
            <div class="card-body limited-width-area">
                <label>User type</label>
                <adapt-select-user-type [(userType)]="userType"
                                        [connectionType]="connectionType"
                                        (userTypeChange)="userTypeChange()"
                                        data-test="select-user-type"></adapt-select-user-type>

                <div *ngIf="userType !== UserType.None"
                     class="mt-2">
                    <label>Access level</label>
                    <adapt-select-access-level [userType]="userType"
                                               [(accessLevel)]="selectedAccessLevel"
                                               data-test="select-access-level"></adapt-select-access-level>
                </div>
            </div>
        </div>

        <div *ngIf="userType !== UserType.None"
             class="card">
            <div class="card-header">
                <h2>Set whether they should be able to log into embedADAPT</h2>
            </div>
            <div class="card-body">
                <dx-check-box [value]="activateAccounts"
                              (valueChange)="activateAccounts = $any($event)"
                              [disabled]="eulaUnaccepted$ | async"
                              text="Allow these people to log in to embedADAPT"
                              data-test="activate-accounts-checkbox"></dx-check-box>
                <div class="alert alert-warning mt-2"
                     role="alert"
                     *ngIf="!activateAccounts">
                    Note: As the above option is unticked, these people will be visible inside embedADAPT but they will NOT be able to log
                    in.

                    <ng-container *ngIf="eulaUnaccepted$ | async">
                        <br /><br />
                        <b>As the customer license agreement has not been accepted, no users are allowed to log into embedADAPT and
                            therefore
                            this option has been disabled.</b>
                    </ng-container>

                    <br /><br />
                    At a later date you can allow them to log in to embedADAPT via the 'Manage People' page
                    (accessible from the configuration menu at the bottom left of the embedADAPT interface).
                </div>
            </div>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="OK"
                                   [saveBlockingClick]="addAndSave"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
