import { Injectable, Injector } from "@angular/core";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseService } from "@common/service/base.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { lastValueFrom } from "rxjs";
import { startWith } from "rxjs/operators";
import { AddTeamDialogComponent } from "./add-team-dialog/add-team-dialog.component";
import { EditTeamLocationsDialogComponent } from "./edit-team-locations-dialog/edit-team-locations-dialog.component";

@Injectable({
    providedIn: "root",
})
export class TeamsUiService extends BaseService {
    public constructor(
        injector: Injector,
        private commonDialogService: AdaptCommonDialogService,
        private teamsService: CommonTeamsService,
        private userService: UserService,
        private directorySharedService: DirectorySharedService,
    ) {
        super(injector);
    }

    public async promiseToCreateTeam() {
        const team = await this.teamsService.promiseToCreateTeam();

        try {
            const person = (await this.userService.getCurrentPerson())!;

            // dont add the current person to the team leader role (i.e. if an ADAPT person creates a team in an organisation, they do not have a connection)
            if (person.getLatestConnection()) {
                this.log.info("Setting current person as team leader", person);
                team.teamLeaderPerson = person;
            } else {
                this.log.info("Current person has no connection in organisation - leaving blank", person);
            }

            try {
                // startWith needed else we get EmptyError from lastValueFrom
                const dialogResult = await lastValueFrom(this.commonDialogService.open(AddTeamDialogComponent, team).pipe(startWith(undefined)));
                if (dialogResult) {
                    if (!team.teamLeaderPersonId) {
                        this.log.error("Team should have a team leader after creation!", team);
                    }

                    const teamLeaderRoleConnections = team.roleConnections.filter((rc: RoleConnection) => rc.connection.personId === team.teamLeaderPersonId);
                    if (teamLeaderRoleConnections.length !== 1) {
                        this.log.error("There should be exactly 1 team leader role connection after creation", teamLeaderRoleConnections);
                    }

                    return team;
                }
            } finally {
                await this.directorySharedService.cleanupUncommittedRoleConnections();
            }
        } catch (e) {
            await lastValueFrom(this.commonDataService.cancel());
            return Promise.reject(e);
        }
    }

    public editTeamLocations(team: Team) {
        return this.commonDialogService.open(EditTeamLocationsDialogComponent, team);
    }
}
