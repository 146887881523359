<adapt-toolbar-inactive [entity]="person"
                        type="person">
</adapt-toolbar-inactive>

<div adaptToolbarContent
     *ngIf="hasEditPermission">
    <button adaptButtonIcon="add"
            (click)="recordCulturalIndex()"
            data-test="record-new-button">Record new</button>
    <button adaptButtonIcon="edit"
            *ngIf="currentIndex"
            (click)="editCulturalIndex()"
            data-test="edit-latest-button">Edit latest</button>
</div>

<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element name="{{fullName}} Cultural Index"
                                 #currentCIDashboardIndex>
            <div *adaptLoading="loading">
                <div *ngIf="!currentIndex">
                    No cultural indexes have been recorded.
                </div>
                <div *ngIf="currentIndex">
                    <adapt-ci-range [culturalIndex]="currentIndex"
                                    [isDisabled]="true">
                    </adapt-ci-range>

                    <div class="cultural-index-comment">
                        <p>
                            <strong>
                                <span data-test="orbit-direction">{{fullName}} orbit is
                                    {{getDirectionState(currentIndex.direction)}}.</span>
                            </strong>
                        </p>
                        <div *ngIf="currentIndex.notes"
                             data-test="cultural-index-notes"
                             [froalaView]="currentIndex.notes"></div>
                    </div>

                    <adapt-cultural-measurement-meta [culturalEntity]="currentIndex"
                                                     [showCulturalLeaderData]="true">
                    </adapt-cultural-measurement-meta>
                </div>
            </div>
        </adapt-dashboard-element>
    </div>
    <div right-column
         *adaptLoading="loading">
        <adapt-dashboard-element name="{{fullName}} Cultural Index history"
                                 *ngIf="currentIndex">
            <ng-container adapt-dashboard-title-buttons>
                <button class="btn btn-primary btn-pill"
                        (click)="exportData()"
                        adaptButtonIcon="fal fa-download"
                        data-test="export-data-button">Export data</button>
            </ng-container>

            <adapt-ci-history [personId]="person?.personId"
                              [options]="culturalIndexHistoryOptions"></adapt-ci-history>
        </adapt-dashboard-element>
    </div>
</adapt-two-column-page>
