<div class="value-stream-dashboard-element-title">
    <span adaptTooltip="These are the primary products or services for this value stream.">
        <i class="fal fa-fw fa-cubes"></i>
        Products & services</span>
    <button *ngIf="canEditTier1Architecture$ | async"
            class="btn btn-link"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editValueStreamProducts"></button>
</div>

<div *adaptLoading="!valueStreamProducts">
    <dx-accordion *ngIf="valueStreamProducts!.length > 0; else noProductsTemplate"
                  [collapsible]="true"
                  [multiple]="true"
                  [selectedIndex]="-1"
                  [dataSource]="valueStreamProducts"
                  [deferRendering]="false">
        <div *dxTemplate="let valueStreamProduct of 'title'">
            {{valueStreamProduct?.product?.name}}
        </div>
        <div *dxTemplate="let valueStreamProduct of 'item'">
            <div *ngIf="valueStreamProduct?.product?.description; else noDescriptionTemplate"
                 [froalaView]="valueStreamProduct?.product?.description">
            </div>
            <ng-template #noDescriptionTemplate>
                <p>No description.</p>
            </ng-template>
        </div>
    </dx-accordion>
    <ng-template #noProductsTemplate>
        <adapt-call-to-action-inline [featureUrl]="featureUrl$ | async">
            {{CallToActionText.valueStream.productsAndServices}}
            <button *ngIf="canEditTier1Architecture$ | async"
                    adaptCallToActionButton
                    adaptButtonIcon="edit"
                    [adaptBlockingClick]="editValueStreamProducts">
                Define a product or service
            </button>
        </adapt-call-to-action-inline>
    </ng-template>
</div>
