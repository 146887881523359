<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ExternalStakeholderDirectory"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent>
    <button *adaptIfAuthorised="'manageAccess'"
            adaptButton="add"
            data-test="add-stakeholder-button"
            [adaptBlockingClick]="addStakeholder"> stakeholder</button>
</div>

<adapt-people-grid [filterByConnectionTypes]="filterByConnectionTypes"></adapt-people-grid>
