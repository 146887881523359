<dx-chart class="catchup-chart-style"
          [dataSource]="chartData"
          [series]="series"
          [pathModified]="true"
          [customizePoint]="customiseChartPoint"
          (onPointClick)="onPointClicked($event)">
    <!-- zIndex value from old ajs chart - tried taking it away and tooltip will be hiding behind the dialog -->
    <dxo-tooltip [enabled]="true"
                 [zIndex]="99999999"
                 contentTemplate="tooltipContent"></dxo-tooltip>
    <dxo-title [text]="title"></dxo-title>
    <dxi-value-axis [visualRange]="[-0.5, 2.5]"
                    visualRangeUpdateMode="keep"
                    [maxValueMargin]="0.001"
                    [tickInterval]="0.5"
                    [visible]="true">
        <dxo-label [visible]="true"
                   [customizeText]="customiseLabel"></dxo-label>
        <dxo-minor-tick [visible]="false"></dxo-minor-tick>
        <dxo-tick [visible]="false"></dxo-tick>
        <dxo-grid [visible]="false"></dxo-grid>
        <dxo-minor-grid [visible]="false"></dxo-minor-grid>
        <dxi-constant-line dashStyle="solid"
                           [width]="1"
                           color="#ababab"
                           [value]="-0.5">
        </dxi-constant-line>
        <dxi-constant-line dashStyle="dash"
                           [width]="1"
                           color="#ababab"
                           [value]="0.5">
        </dxi-constant-line>
        <dxi-constant-line dashStyle="dash"
                           [width]="1"
                           color="#ababab"
                           [value]="1.5">
        </dxi-constant-line>
        <dxi-constant-line dashStyle="solid"
                           [width]="1"
                           color="#ababab"
                           [value]="2.5">
        </dxi-constant-line>
    </dxi-value-axis>
    <dxo-argument-axis type="discrete">
        <dxo-label overlappingBehavior="rotate"
                   [rotationAngle]="-45"
                   [format]="DateFormats.globalize.short"
                   [visible]="!hideDates">
        </dxo-label>
    </dxo-argument-axis>
    <dxo-legend [visible]="false"></dxo-legend>

    <div *dxTemplate="let info of 'tooltipContent'"
         class="catchup-chart-tooltip">
        <adapt-display-catchup-rating [rating]="info.point.tag"
                                      [showCommentDetails]="true"
                                      [showButtons]="false"></adapt-display-catchup-rating>
    </div>
</dx-chart>
