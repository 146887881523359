import { Component, Inject } from "@angular/core";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { EMPTY, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Component({
    templateUrl: "./add-team-dialog.component.html",
})
export class AddTeamDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Team> {
    public readonly dialogName = "AddTeam";
    public entitiesToConfirm: IBreezeEntity[] = [];

    public teamLeaderLabel = this.teamsService.teamLeaderLabel;
    public readonly allowedUserTypes = NonParticipantUserTypes;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public team: Team,
        protected commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        private userService: UserService,
        private authService: AuthorisationService,
    ) {
        super();

        this.autoResolveData = this.team;
        this.entitiesToConfirm = [this.team];
    }

    public get entitiesAreUnmodifiedOrInvalid() {
        return this.team.name === ""
            || this.team.teamLeaderPerson === null
            || super.entitiesAreUnmodifiedOrInvalid;
    }

    public get hasUnsavedEntity() {
        return !this.entitiesAreUnmodifiedOrInvalid;
    }

    public get isPrivateNonStakeholderManager() {
        return this.team.isPrivate && !(this.authService.isStakeholderManager() && !this.userService.currentPerson?.getActiveConnections().length);
    }

    public addEntitiesToConfirm(entities: IBreezeEntity[]) {
        for (const entity of entities) {
            if (!this.entitiesToConfirm.includes(entity)) {
                this.entitiesToConfirm.push(entity);
            }
        }
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.saveEntities(this.team).pipe(
            switchMap(() => {
                const teamLeaderConnection = this.team.teamLeaderPerson.getLatestConnection();
                if (teamLeaderConnection) {
                    return this.teamsService.promiseToUpdateRoleConnectionsForTeamLeaderChange(this.team, teamLeaderConnection);
                }
                return EMPTY;
            }),
            // cannot have roleConnections and teamLocations entiities to be in a single save as teamLocation entities will need
            // to figure out if the person has the permission to save, which requires the role connections to do that.
            switchMap((roleConnections) => {
                const changedRoleConnections = roleConnections.filter((rc) => !!rc) as RoleConnection[];
                if (changedRoleConnections.length > 0) {
                    return this.commonDataService.saveEntities(changedRoleConnections);
                } else {
                    return of(undefined);
                }
            }),
            // this will save team locations if there is any
            switchMap(() => super.saveAndClose()),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }

    public onPrivateTeamChanged(isPrivate: boolean) {
        this.team.isPrivate = isPrivate;
        if (this.isPrivateNonStakeholderManager) {
            // only set teamLeaderPerson if the current user is not a stakeholder (if stakeholder, he can still access the team even if he is not in there)
            this.team.teamLeaderPersonId = this.userService.getCurrentPersonId();
        }
    }
}
