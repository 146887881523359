<div class="card p-3"
     *adaptLoading="isLoading">
    <dx-chart (onInitialized)="onInitialized($event)"
              [hidden]="data.length === 0"
              [dataSource]="data"
              title="Career Valuations"
              [pathModified]="true"
              [customizePoint]="careerValuationUiService.customiseChartPoint"
              (onPointClick)="onPointClick($event)">
        <dxo-size [height]="height">
        </dxo-size>
        <dxo-margin [left]="20"
                    [right]="20"></dxo-margin>
        <dxi-series type="scatter"
                    valueField="score"
                    argumentField="argument"
                    tagField="careerValuation"></dxi-series>
        <dxo-argument-axis>
            <dxo-grid [visible]="true"
                      [opacity]="0.2">
            </dxo-grid>
            <dxo-label alignment="right"
                       format="monthAndYear"
                       overlappingBehavior="rotate"
                       [rotationAngle]="-45"
                       [visible]="showDates"></dxo-label>
            <dxo-minor-grid [visible]="false">
            </dxo-minor-grid>
        </dxo-argument-axis>
        <dxo-legend [visible]="false"></dxo-legend>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip"></dxo-tooltip>
        <dxi-value-axis [tickInterval]="25"
                        [visualRange]="[0, 100]"
                        visualRangeUpdateMode="keep"
                        [valueMarginsEnabled]="false"
                        [visible]="true"></dxi-value-axis>
        <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>
    </dx-chart>

    <div *ngIf="data.length === 0">
        <adapt-no-cultural-measurement label="Career Valuations"></adapt-no-cultural-measurement>
    </div>
</div>
