<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content
         class="delete-process-map-dialog-content"
         *adaptLoadingUntilFirstEmit="let childSteps of childSteps$">
        <ng-container *ngIf="processStep.extensions.isProcessMapLink; else notLinkText">
            Are you sure you want to delete this link to the
            <adapt-link-process-map class="ms-1"
                                    [processMap]="processStep.linkedProcessMap"></adapt-link-process-map>
            task map?
        </ng-container>
        <ng-template #notLinkText>
            Are you sure you want to delete the
            <adapt-link-process-step [processStep]="processStep"
                                     [openOnNewPage]="true"></adapt-link-process-step>
            {{processStep.extensions.isRoleTask ? 'role task' : 'process step'}}?
        </ng-template>

        <div *ngIf="processStep.role"
             class="mt-3">
            The <adapt-link-role [role]="processStep.role"></adapt-link-role>
            role will no longer be assigned to the role task.
        </div>

        <div *ngIf="childSteps.length > 0"
             class="mt-3 child-steps-container">
            You will also delete the following children (and any further descendants):
            <ul>
                <li *ngFor="let childStep of childSteps">
                    <adapt-link-process-step [processStep]="childStep"
                                             [openOnNewPage]="true"></adapt-link-process-step>
                </li>
            </ul>
        </div>
        <div class="mt-3">
            <dx-check-box [value]="confirmed"
                          (valueChange)="confirmed = $any($event)"
                          text="Check to confirm that you understand that this action cannot be undone"></dx-check-box>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="deleteAndClose"
                                   [saveIsDisabled]="!confirmed"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
