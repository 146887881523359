import { Component, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemKnowledgeExpert } from "@common/ADAPT.Common.Model/organisation/system-knowledge-expert";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-edit-system-dialog",
    templateUrl: "./edit-system-dialog.component.html",
    styleUrls: ["./edit-system-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
@PersistableDialog("EditSystemDialog")
export class EditSystemDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemEntity> {
    public readonly dialogName = "EditSystem";
    public readonly items = [
        {
            title: "Details",
            template: "detailsTemplate",
        },
        {
            title: "Locations",
            template: "locationsTemplate",
        },
    ];
    public readonly allowedOwnerUserTypes = NonParticipantUserTypes;


    public title: string;
    public changedLabelLocations: LabelLocation[] = [];
    private deletedEntities: IBreezeEntity[] = [];
    protected entitiesToConfirm: IBreezeEntity[] = [];

    private systems: SystemEntity[] = [];
    private changedSystemLocations: SystemLocation[] = [];

    @ViewChild("selectKnowledgeExpert") private selectKnowledgeExpertInstance?: SelectPersonComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public system: SystemEntity,
        private systemisationService: SystemisationService,
    ) {
        super();
        this.autoResolveData = this.system;
        this.title = this.system.entityAspect.entityState.isAdded()
            ? "Create system"
            : "Edit system";

        this.systemisationService.getAllSystems().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((systems) => this.systems = systems);

        this.updateEntitiesToConfirm();
        this.sortSystemKnowledgeExperts();
    }

    public systemLocationChanged(systemLocation: SystemLocation) {
        if (this.changedSystemLocations.indexOf(systemLocation) < 0) {
            this.changedSystemLocations.push(systemLocation);

            this.updateEntitiesToConfirm();
        }
    }

    public updateEntitiesToConfirm() {
        const layout = [];
        if (this.system.layout) {
            layout.push(this.system.layout);
        }

        this.entitiesToConfirm = [
            this.system,
            ...layout,
            ...this.system.systemKnowledgeExperts,
            ...this.deletedEntities,
            ...this.changedLabelLocations,
            ...this.changedSystemLocations,
        ];
    }

    public onLabelLocationsChanged(labelLocations: LabelLocation[]) {
        this.changedLabelLocations = labelLocations;
        this.updateEntitiesToConfirm();
    }

    public get isValid() {
        return this.system.name
            && !this.entitiesAreUnmodifiedOrInvalid;
    }

    public onNameChange(name: string) {
        this.system.name = name;
        this.system.extensions.validateSystemName(this.systems);
    }

    public onKnowledgeExpertAdded(person?: Person) {
        if (person && !this.system.systemKnowledgeExperts.find((i) => i.knowledgeExpertId === person.personId)) {
            this.systemisationService.createSystemKnowledgeExpert(this.system, person).subscribe(() => {
                this.sortSystemKnowledgeExperts();
                this.selectKnowledgeExpertInstance?.reset();
                this.updateEntitiesToConfirm();
            });
        }
    }

    public onKnowledgeExpertDeleted(systemKnowledgeExpert: SystemKnowledgeExpert) {
        this.systemisationService.remove(systemKnowledgeExpert).subscribe(() => {
            this.deletedEntities.push(systemKnowledgeExpert);
            this.updateEntitiesToConfirm();
        });
    }

    @Autobind
    public notKnowledgeExpert(person: Person) {
        return !this.system.systemKnowledgeExperts.find((i) => i.knowledgeExpertId === person.personId);
    }

    private sortSystemKnowledgeExperts() {
        return this.system.systemKnowledgeExperts.sort(SortUtilities.getSortByFieldFunction((i) => i.knowledgeExpert.fullName));
    }
}
