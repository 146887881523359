<adapt-feature-guidance [configItem]="configItem">
    <p>
        Our teams are built on trust and relationships with our peers. The Peer Catch-up is a tool which provides peers with insights and an
        opportunity to give feedback to each other. It enables peer to peer accountability and a framework upon which to resolve issues.
    </p>
    <p>
        Enabling this feature will allow you to record Peer Catch-ups at the team level.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <h4>Who can view Peer Catch-ups</h4>
                Configure whether all team members can view the team Peer Catch-ups or only the team leader. If only
                the team leader, team members will only see their Peer Catch-ups with others.

                <dx-radio-group class="pt-2"
                                [items]="ReadAccessSelections"
                                [(value)]="viewAllSpeedCatchups"
                                (valueChange)="valueChanged()"
                                valueExpr="value"
                                displayExpr="text"></dx-radio-group>
            </div>
        </div>
    </div>
</adapt-feature-configuration>
