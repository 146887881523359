<div class="value-stream-dashboard-element-title">
    <span adaptTooltip="Describes how your offering is unique in the market.">
        <i class="fal fa-fw fa-map-pin"></i>
        Point of difference</span>
    <button *ngIf="canEditTier1Architecture$ | async"
            class="btn btn-link"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="promiseToEditPointOfDifference"></button>
</div>

<div>
    <div *adaptLoading="!businessModel">
        <div *ngIf="businessModel!.pointOfDifference else noPointOfDifferenceTemplate"
             [froalaView]="businessModel!.pointOfDifference"></div>
        <ng-template #noPointOfDifferenceTemplate>
            <adapt-call-to-action-inline [featureUrl]="featureUrl$ | async">
                {{CallToActionText.valueStream.pointOfDifference}}
                <button *ngIf="canEditTier1Architecture$ | async"
                        adaptCallToActionButton
                        adaptButtonIcon="edit"
                        [adaptBlockingClick]="promiseToEditPointOfDifference">
                    Define the point of difference
                </button>
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</div>
