import { Component, OnInit } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IAdaptLinkObject } from "@common/route/route.service";
import { rbGoalsRoute } from "@org-common/lib/rb-goals/rb-goals-page/rb-goals-page.route";
import { SearchService } from "@org-common/lib/search/search.service";
import { IResilientBusinessGoalSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-resilient-business-goal",
    templateUrl: "./search-row-resilient-business-goal.component.html",
})
export class SearchRowResilientBusinessGoalComponent extends SearchRowBaseComponent<IResilientBusinessGoalSearchResult> implements OnInit {
    public readonly ZoneMetadata = ZoneMetadata;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public async ngOnInit() {
        super.ngOnInit();

        this.href$ = rbGoalsRoute.getRouteObject();
        this.matches = this.result.results;
    }
}
