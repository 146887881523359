import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IKeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxTextBoxComponent } from "devextreme-angular";
import { OrganisationMapService } from "../../organisation-map/organisation-map.service";

@Component({
    selector: "adapt-edit-key-function-details",
    templateUrl: "./edit-key-function-details.component.html",
})
export class EditKeyFunctionDetailsComponent extends BaseComponent implements IFocusable {
    @Input() public keyFunction!: KeyFunction;
    @Output() public entityModified = new EventEmitter<IBreezeEntity | IBreezeEntity[]>();

    @ViewChild(DxTextBoxComponent)
    public nameTextBox!: DxTextBoxComponent;

    public constructor(
        private orgMapService: OrganisationMapService,
    ) {
        super();
    }

    public async moveKeyLocation(location: IKeyFunctionLocation) {
        const modifiedKeyFunctions = await this.orgMapService.promiseToMoveKeyFunction(this.keyFunction, location);
        this.entityModified.emit(modifiedKeyFunctions);
    }

    public getElementToFocus() {
        return this.nameTextBox;
    }
}
