import { Component, Input, OnInit } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";

enum GroupType {
    KeyFunctionGroup,
    TeamGroup,
    RoleGroup,
    ZoneGroup,
    UnknownGroup,
}

@Component({
    selector: "adapt-link-system-group",
    templateUrl: "./link-system-group.component.html",
})
export class LinkSystemGroupComponent implements OnInit {
    @Input() public groupKey?: KeyFunction | Team | Role | Zone;

    public readonly GroupType = GroupType;
    public groupType = GroupType.UnknownGroup;

    public ngOnInit() {
        if (this.groupKey instanceof KeyFunction) {
            this.groupType = GroupType.KeyFunctionGroup;
        } else if (this.groupKey instanceof Team) {
            this.groupType = GroupType.TeamGroup;
        } else if (this.groupKey instanceof Role) {
            this.groupType = GroupType.RoleGroup;
        } else if (this.groupKey) {
            this.groupType = GroupType.ZoneGroup;
        }
    }
}
