/* eslint-disable max-classes-per-file */
import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Person } from "../person/person";
import { SpeedCatchup } from "./speed-catchup";
import { SpeedCatchupRatingExtensions } from "./speed-catchup-rating-extensions";

export enum CatchupRatingValue {
    Green = "Green",
    Orange = "Orange",
    Red = "Red",
}

export enum CatchupRatingGuidance {
    Green = "All good",
    Orange = "Minor issues, address soon",
    Red = "Major issues, address now!",
}

export const RatingValues: { [key in CatchupRatingValue]: number } = {
    Green: 0,
    Orange: 1,
    Red: 2,
};

export enum CatchupRatingTypeName {
    Engagement = "Engagement",
    Connection = "Connection",
    Contribution = "Contribution",
}

export enum CatchupRatingTypeGuidance {
    Engagement = "How I rate my own engagement",
    Connection = "How I rate our connection",
    Contribution = "How you rate my contribution",
}

export class CatchupRatingType {
    public static readonly Engagement = new CatchupRatingType(CatchupRatingTypeName.Engagement, true, false, CatchupRatingTypeGuidance.Engagement, 0);
    public static readonly Connection = new CatchupRatingType(CatchupRatingTypeName.Connection, false, true, CatchupRatingTypeGuidance.Connection, 1);
    public static readonly Contribution = new CatchupRatingType(CatchupRatingTypeName.Contribution, false, false, CatchupRatingTypeGuidance.Contribution, 2);

    public static readonly All = [
        CatchupRatingType.Engagement,
        CatchupRatingType.Connection,
        CatchupRatingType.Contribution,
    ];

    public static readonly ByStatus: { [status in CatchupRatingTypeName]: CatchupRatingType } = {
        [CatchupRatingTypeName.Engagement]: CatchupRatingType.Engagement,
        [CatchupRatingTypeName.Connection]: CatchupRatingType.Connection,
        [CatchupRatingTypeName.Contribution]: CatchupRatingType.Contribution,
    };

    public static readonly OrdinalFromStatus: { [status in CatchupRatingTypeName]: number } = {
        [CatchupRatingTypeName.Engagement]: CatchupRatingType.Engagement.ordinal,
        [CatchupRatingTypeName.Connection]: CatchupRatingType.Connection.ordinal,
        [CatchupRatingTypeName.Contribution]: CatchupRatingType.Contribution.ordinal,
    };

    private constructor(
        public readonly name: CatchupRatingTypeName,
        public readonly authorIsSubject: boolean,
        public readonly ratingIsMutual: boolean,
        public readonly shortGuidance: string,
        public readonly ordinal: number,
    ) {
    }
}

export class SpeedCatchupRating extends BaseEntity<SpeedCatchupRating> {
    public speedCatchupRatingId!: number;
    public speedCatchupId!: number;
    public authorPersonId!: number;
    public subjectPersonId!: number;
    public rating!: CatchupRatingValue;
    public ratingType!: CatchupRatingTypeName;
    public comment!: string;
    public feedbackPersonId?: number;
    public feedbackComment?: string;
    public authorPerson!: Person;
    public feedbackPerson!: Person;
    public speedCatchup!: SpeedCatchup;
    public subjectPerson!: Person;

    @EntityExtensions(SpeedCatchupRatingExtensions)
    public extensions!: SpeedCatchupRatingExtensions;
}

export const SpeedCatchupRatingBreezeModel = new BreezeModelBuilder("SpeedCatchupRating", SpeedCatchupRating)
    .persistChangedEntity()
    .withSingularName("Peer Catch-up Rating")
    .withPluralName("Peer Catch-up Ratings")
    .setPrimeSource("speedCatchup", "speedCatchupId") // cannot use SpeedCatchupBreezeModel here - circular ref disallowed by runtime
    .withEntityValidator(new Validator(
        "feedbackRequiredValidator",
        (entity: SpeedCatchupRating) => {
            const type = entity.extensions.getRatingType();
            return !entity.ratingType || !!type.ratingIsMutual || !!entity.feedbackComment;
        },
        {
            messageTemplate: "Feedback is required",
        }))
    .build();
