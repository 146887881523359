<adapt-dashboard-element name="Systems"
                         [url]="teamSystemsHref$ | async">

    <ng-container *adaptIfAuthorised="readTier2Architecture; Entity:(team$ | async)">
        <ng-container *adaptLoadingUntilFirstEmit="let systems of systems$">
            <ng-container *ngIf="systems.length > 0; else noSystemsTemplate">
                <adapt-link-system *ngFor="let system of systems"
                                   class="mb-2"
                                   [system]="system">
                </adapt-link-system>
            </ng-container>
            <ng-template #noSystemsTemplate>
                No systems have been assigned to this team.
            </ng-template>
        </ng-container>
    </ng-container>
</adapt-dashboard-element>
