<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.AnalyseCvtTimeline"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title>
    <h2>
        Career Valuation timeline
        <i class="fal fa-fw fa-info-circle"
           adaptTooltip="This page shows a chart of the aggregated career valuation values (broken down by category) over time.">
        </i>
    </h2>
</adapt-toolbar-title>

<adapt-filter-content (reset)="filterService.reset()"
                      [isDefault]="filterService.isDefault"
                      [urlParamNames]="CareerValuationFilterParamKeys">
    <adapt-career-valuation-filter>
    </adapt-career-valuation-filter>
</adapt-filter-content>

<div class="row">
    <adapt-cvt-timeline-chart class="col-12">
    </adapt-cvt-timeline-chart>
</div>
