import { Component, Inject, Input, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { EMPTY, Observable } from "rxjs";
import { ConfigArea } from "../../configuration/configuration-types";
import { CommonTeamsAuthService } from "../common-teams-auth.service";

@Component({
    selector: "adapt-team-people-dashboard-element",
    templateUrl: "./team-people-dashboard-element.component.html",
})
export class TeamPeopleDashboardElementComponent implements OnInit {
    @Input() public team!: Team;

    public url$?: Observable<string>;

    public constructor(
        private teamAuthService: CommonTeamsAuthService,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{teamId: number}>,
    ) {}

    public ngOnInit() {
        this.url$ = this.teamAuthService.currentPersonCanEditTeam(this.team)
            ? this.teamConfigurationPageRoute.getRoute({ teamId: this.team.teamId }, { section: ConfigArea[ConfigArea.People] })
            : EMPTY;
    }
}
