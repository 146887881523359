<dx-diagram *adaptLoading="!isInitialised"
            #diagram
            [style.min-height.px]="height"
            [height]="height"
            [showGrid]="false"
            [simpleView]="true"
            units="px"
            viewUnits="px"
            (onDisposing)="onDiagramDisposing()"
            (onOptionChanged)="optionChanged($event)"
            (onContentReady)="onContentReady($event)"
            (onCustomCommand)="onCustomCommand($event)">

    <dxo-main-toolbar [visible]="isEditing"
                      [commands]="['toolbox','separator','undo','redo','separator','fontName','fontSize','separator','bold','italic','underline','separator','textAlignLeft','textAlignCenter', 'textAlignRight','separator','fontColor','lineColor','fillColor']">
    </dxo-main-toolbar>

    <dxo-history-toolbar [visible]="false"></dxo-history-toolbar>

    <dxo-grid-size [value]="6">
    </dxo-grid-size>

    <dxo-view-toolbar>
        <dxi-command name="zoomLevel"></dxi-command>
        <dxi-command name="fullScreen"></dxi-command>
        <dxi-command name="separator"></dxi-command>
        <dxi-command name="toolbox"></dxi-command>
    </dxo-view-toolbar>

    <dxo-properties-panel visibility="disabled"></dxo-properties-panel>

    <dxo-context-menu
                      [commands]="['cut','copy','paste','delete','separator','lock','unlock','separator','sendToBack','bringToFront','separator','connectorLineType','connectorLineStart','connectorLineEnd',{name:'onHover', text:'Align Shapes',icon:'contentlayout',items: [{name: 'alignTop', text: 'Top'},{name: 'alignBottom',text:'Bottom'},{name: 'alignLeft', text: 'Left'},{name: 'alignRight', text: 'Right'}]}]">
    </dxo-context-menu>

    <dxo-toolbox [width]="200"
                 [showSearch]="false"
                 visibility="collapsed">
        <dxi-group category="teams"
                   title="Teams"
                   displayMode="texts"
                   [expanded]="false"></dxi-group>
        <dxi-group category="keyfunctions"
                   title="Key Functions"
                   displayMode="texts"
                   [expanded]="false"></dxi-group>
        <dxi-group category="people"
                   title="People"
                   displayMode="texts"
                   [expanded]="false"></dxi-group>
        <dxi-group category="roles"
                   title="Roles"
                   displayMode="texts"
                   [expanded]="false"></dxi-group>
        <!-- <dxi-group category="custom"
                   title="Shapes"
                   [expanded]="false"></dxi-group>
        <dxi-group category="arrows"
                   title="Arrows"
                   [expanded]="false"></dxi-group> -->
        <dxi-group category="general"
                   title="General"></dxi-group>
        <dxi-group category="flowchart"
                   title="Flow Chart"></dxi-group>
        <!-- <dxi-group category="containers"
                   title="Containers"></dxi-group> -->
    </dxo-toolbox>

    <dxo-nodes [dataSource]="nodesDataSource"
               containerKeyExpr="containerKey"
               topExpr="position.y"
               leftExpr="position.x"></dxo-nodes>
    <dxo-edges [dataSource]="edgesDataSource"></dxo-edges>

    <dxi-custom-shape *ngFor="let team of teams"
                      category="teams"
                      [type]='"team" + team.teamId'
                      baseType="rectangle"
                      [title]="team.name"
                      template="teamTemplate"
                      [defaultHeight]="35"
                      [defaultWidth]="150"
                      [minHeight]="35"
                      [minWidth]="150"
                      [allowEditText]="false">
    </dxi-custom-shape>

    <dxi-custom-shape *ngFor="let keyFunction of keyFunctions"
                      category="keyfunctions"
                      [type]='"keyFunction" + keyFunction.keyFunctionId'
                      baseType="rectangle"
                      template="keyFunctionTemplate"
                      [defaultHeight]="47"
                      [defaultWidth]="200"
                      [minHeight]="35"
                      [minWidth]="150"
                      [title]="keyFunction.name"
                      [allowEditText]="false">
    </dxi-custom-shape>

    <dxi-custom-shape *ngFor="let person of people"
                      category="people"
                      [type]='"person" + person.personId'
                      baseType="rectangle"
                      template="personTemplate"
                      [defaultHeight]="45"
                      [defaultWidth]="200"
                      [minHeight]="35"
                      [minWidth]="150"
                      [title]="person.fullName"
                      [allowEditText]="false">
    </dxi-custom-shape>

    <dxi-custom-shape *ngFor="let role of roles"
                      category="roles"
                      [type]='"role" + role.roleId'
                      baseType="rectangle"
                      template="roleTemplate"
                      [defaultHeight]="35"
                      [defaultWidth]="180"
                      [minHeight]="25"
                      [minWidth]="150"
                      [title]="role.label"
                      [allowEditText]="false">
    </dxi-custom-shape>

    <!-- <dxi-custom-shape category="custom"
                      title="Organic Circle"
                      type="organicCircle"
                      backgroundImageUrl="/content/images/diagram/organic-circle.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Dotted Circle"
                      type="dottedCircle"
                      backgroundImageUrl="/content/images/diagram/dotted-circle.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Organic Rectangle"
                      type="organicRectangle"
                      backgroundImageUrl="/content/images/diagram/rectangle.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Cloud"
                      type="cloud"
                      backgroundImageUrl="/content/images/diagram/cloud.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Speech Bubble"
                      type="speechBubble"
                      backgroundImageUrl="/content/images/diagram/speech-bubble.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Number 1"
                      type="number1"
                      backgroundImageUrl="/content/images/diagram/number1.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Number 2"
                      type="number2"
                      backgroundImageUrl="/content/images/diagram/number2.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="custom"
                      title="Number 3"
                      type="number3"
                      backgroundImageUrl="/content/images/diagram/number3.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thin Arrow Left"
                      type="arrow1-1"
                      backgroundImageUrl="/content/images/diagram/arrow-1-left.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thin Arrow Right"
                      type="arrow1-2"
                      backgroundImageUrl="/content/images/diagram/arrow-1-right.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thin Arrow Bottom Left"
                      type="arrow1-3"
                      backgroundImageUrl="/content/images/diagram/arrow-1-left-bottom.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thin Arrow Bottom Right"
                      type="arrow1-4"
                      backgroundImageUrl="/content/images/diagram/arrow-1-right-bottom.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thick Arrow Left"
                      type="arrow2-1"
                      backgroundImageUrl="/content/images/diagram/arrow-2-left.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thick Arrow Right"
                      type="arrow2-2"
                      backgroundImageUrl="/content/images/diagram/arrow-2-right.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thick Arrow Bottom Right"
                      type="arrow2-3"
                      backgroundImageUrl="/content/images/diagram/arrow-2-bottom-right.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Thick Arrow Bottom Left"
                      type="arrow2-4"
                      backgroundImageUrl="/content/images/diagram/arrow-2-bottom-left.svg">
    </dxi-custom-shape>

    <dxi-custom-shape category="arrows"
                      title="Dotted Loop"
                      type="dottedLoop"
                      backgroundImageUrl="/content/images/diagram/dotted-loop.svg">
    </dxi-custom-shape> -->

    <svg *dxTemplate="let item of 'keyFunctionTemplate'">
        <foreignObject x="1"
                       y="1"
                       width="100%"
                       height="100%">
            <adapt-key-function-card class="ms-n1 me-n1"
                                     [keyFunction]="item.dataItem?.entity"
                                     [adaptLinkDisabled]="isEditing"></adapt-key-function-card>
        </foreignObject>
    </svg>

    <svg *dxTemplate="let item of 'teamTemplate'">
        <foreignObject y="5"
                       width="100%"
                       height="100%">
            <adapt-link-team [team]="item.dataItem?.entity"
                             [showIcon]="true"
                             [showLabels]="false"
                             [adaptLinkDisabled]="isEditing"></adapt-link-team>
        </foreignObject>
    </svg>

    <svg *dxTemplate="let item of 'personTemplate'">
        <foreignObject x="5"
                       y="5"
                       width="100%"
                       height="100%">
            <adapt-link-person [person]="item.dataItem?.entity"
                               [showImage]="true"
                               [adaptLinkDisabled]="isEditing"></adapt-link-person>
        </foreignObject>
    </svg>

    <svg *dxTemplate="let item of 'roleTemplate'">
        <foreignObject x="5"
                       y="5"
                       width="100%"
                       height="100%">
            <adapt-link-role class="ms-n1"
                             [role]="item.dataItem?.entity"
                             [showIcon]="true"
                             [adaptLinkDisabled]="isEditing"></adapt-link-role>
        </foreignObject>
    </svg>
</dx-diagram>
