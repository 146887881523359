import { Component, OnInit } from "@angular/core";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { ISearchResultMatch, IValueStreamSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { valueStreamDashboardPageRoute } from "app/features/architecture/value-streams/value-stream-dashboard-page/value-stream-dashboard-page.route";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-value-stream",
    templateUrl: "./search-row-value-stream.component.html",
    styleUrls: ["./search-row-value-stream.component.scss"],
})
export class SearchRowValueStreamComponent extends SearchRowBaseComponent<IValueStreamSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = valueStreamDashboardPageRoute.getRouteObject({ valueStreamId: this.result.valueStreamId });

        this.matches = this.result.results
            .reduce((acc, result) => {
                const removeEndBreadcrumbs = [SearchService.NameBreadcrumb];
                acc.push({
                    // remove the last breadcrumb if more than 1
                    // (it's the field e.g. Name or Comment, normally redundant)
                    // however, keep the final breadcrumb if
                    ...result,
                    field: result.field.length > 1 && removeEndBreadcrumbs.includes(result.field[result.field.length - 1])
                        // we want to remove the Product name from the breadcrumb as well
                        ? result.field.slice(0, result.field.length - (result.field.includes(SearchService.ProductServicesBreadcrumb) ? 2 : 1))
                        : result.field,
                });
                return acc;
            }, [] as ISearchResultMatch[])
            .filter((m) => m.field[0] !== SearchService.NameBreadcrumb);
    }
}
