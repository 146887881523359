<adapt-dialog [maxWidth]="dialogWidth"
              [allowMouseWheelContentScroll]="false">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <label>Name</label>
        <dx-text-box #focus
                     class="mb-3"
                     [value]="systemComponent.name"
                     (valueChange)="onSystemComponentNameChange($event)"
                     [width]="300"
                     [adaptValidateEntity]="systemComponent"
                     adaptValidateEntityProperty="name"
                     data-test="system-diagram-name"></dx-text-box>

        <adapt-diagram [isEditing]="true"
                       [diagramEntity]="systemComponent.systemDiagram"
                       (diagramModified)="onDiagramChanged($event)"
                       [height]="maxDiagramHeight">
        </adapt-diagram>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="isSaveDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
