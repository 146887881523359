<adapt-theme-test-container label="Components"
                            *ngIf="isActivated">
    <div class="section">
        <h4>Expanded Zone Map</h4>
        <adapt-render-expanded-zone-map [valueStreams]="valueStreams$"
                                        [valueStreamTitleTemplate]="vsTitle"></adapt-render-expanded-zone-map>
        <ng-template #vsTitle
                     let-valueStream="valueStream">
            <adapt-link-value-stream [valueStream]="valueStream"></adapt-link-value-stream>
        </ng-template>
    </div>

    <div class="section">
        <h4>Process Step Card</h4>
        <adapt-process-step-card [processStep]="processStepRole"></adapt-process-step-card>
        <adapt-process-step-card [processStep]="processStepGroup"></adapt-process-step-card>
        <adapt-process-step-card [processStep]="processStepLink"></adapt-process-step-card>
    </div>

    <div class="section">
        <h4>Task Map/Step Link</h4>
        <div>
            <adapt-link-process-map [processMap]="processMap"></adapt-link-process-map>
        </div>
        <!-- disabled as we can't mock this easily -->
        <!-- <div>
            <adapt-link-process-step [processStep]="processStepRole"></adapt-link-process-step>
        </div> -->
    </div>

    <div class="section">
        <h4>Entity Links</h4>
        <div>
            <adapt-link-role [role]="role"
                             [showIcon]="true"></adapt-link-role>
        </div>
    </div>

    <div class="section">
        <h4>Value Stream Zone Label</h4>
        <div>
            <adapt-zone-label [valueStream]="valueStream"></adapt-zone-label>
        </div>
    </div>
</adapt-theme-test-container>
