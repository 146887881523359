import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Diagram } from "@common/ADAPT.Common.Model/organisation/diagram";
import { KeyFunction, KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { ContentReadyEvent, CustomCommandEvent, dxDiagramItem, OptionChangedEvent } from "devextreme/ui/diagram";
import { DxDiagramComponent } from "devextreme-angular";
import { lastValueFrom, of } from "rxjs";
import { debounceTime, filter, switchMap, withLatestFrom } from "rxjs/operators";

@Component({
    selector: "adapt-diagram",
    templateUrl: "./diagram.component.html",
})
export class DiagramComponent extends BaseComponent implements OnInit {
    @Input() public isEditing: boolean = false;
    @Input() public diagramEntity?: Diagram;
    @Input() public height: number = 500;
    @Output() public diagramModified = new EventEmitter<boolean>();

    @ViewChild(DxDiagramComponent) public diagram?: DxDiagramComponent;

    public keyFunctions: KeyFunction[] = [];
    public teams: Team[] = [];
    public people: Person[] = [];
    public roles: Role[] = [];
    private initialLoadDone = false;

    public nodesDataSource: DataSource;
    public nodesStore: ArrayStore;
    public edgesDataSource: DataSource;

    private readonly Align = { Top: "alignTop", Bottom: "alignBottom", Left: "alignLeft", Right: "alignRight" };

    public constructor(
        private authorisationService: AuthorisationService,
        private commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        rxjsBreezeService: RxjsBreezeService,
        dialogService: AdaptCommonDialogService,
    ) {
        super();

        this.nodesStore = new ArrayStore({
            key: "id",
            data: [],
            onInserted: this.onItemAddedToDataSource,
        });

        this.nodesDataSource = new DataSource({
            store: this.nodesStore,
        });

        this.edgesDataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: [],
            }),
        });

        rxjsBreezeService.entityTypeChanged(Diagram).pipe(
            filter((changedDiagram) => changedDiagram.diagramId === this.diagramEntity?.diagramId),
            debounceTime(100),
            withLatestFrom(this.diagramModified),
            switchMap(([changedDiagram, changedLocally]) => {
                if (!this.isEditing || !changedLocally) {
                    return of(changedDiagram);
                } else {
                    // changed locally -> prompt to whether to keep or override
                    const dialogData: IConfirmationDialogData = {
                        title: "Incoming Conflicts With Local Changes",
                        message: `<p>You have already made changes to the diagram which has also been changed from another session
                            and saved.</p><p>Do you want to discard your local changes and accept the changes saved by another session
                            or continue working and ignore incoming changes from another session?</p>`,
                        confirmButtonText: "Accept Incoming & Discard Local Changes",
                        cancelButtonText: "Ignore Incoming Changes",
                    };
                    return dialogService.openConfirmationDialog(dialogData);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.loadDiagram());
    }

    public async ngOnInit() {
        const hasReadTier1 = await this.authorisationService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
        if (hasReadTier1) {
            this.keyFunctions = await lastValueFrom(this.commonDataService.getAll(KeyFunctionBreezeModel));
            this.keyFunctions = this.keyFunctions.sort((k1, k2) => k1.name < k2.name ? -1 : 1);
        }

        this.teams = await this.teamsService.promiseToGetAllActiveTeams();
        this.teams = this.teams
            .filter((t) => !t.isPrivate)
            .sort((t1, t2) => t1.name < t2.name ? -1 : 1);

        this.people = await new PeopleQueryUtilities(this.commonDataService).promiseToGetActivePeople();
        this.people = this.people.sort((person1, person2) => person1.fullName < person2.fullName ? -1 : 1);

        this.roles = await lastValueFrom(this.commonDataService.getAll(RoleBreezeModel));
        this.roles = this.roles
            .filter((role) => role.isActive()
                && !role.teamId
                && (role.extensions.isCulturalLeaderRole() || DirectorySharedService.isNotAccessLevelRole(role)))
            .sort((r1, r2) => r1.label < r2.label ? -1 : 1);

        this.isInitialised = true;
    }

    public optionChanged(e: OptionChangedEvent) {
        // how we detect that the diagram has changed:
        // https://supportcenter.devexpress.com/ticket/details/t1028365/diagram-for-angular-event-to-detect-a-diagram-has-changed
        if (e.name === "hasChanges" && e.value) {
            e.component.option("hasChanges", false);
            this.diagramModified.emit(true);
        }
    }

    public onContentReady(_e: ContentReadyEvent) {
        // we want to load the diagram only after the diagram says its ready (onInitialized is too early)
        // ...and we must use the setTimeout here as well to get around another bug in the diagram control
        if (!this.initialLoadDone) {
            this.initialLoadDone = true;
            setTimeout(() => {
                this.loadDiagram();

                // I'd like to set these in the template, but there is a bug in the diagram control where it would not show
                // our foreign object shapes properly if the diagram was set to readOnly in the template
                this.diagram!.readOnly = !this.isEditing;

                // can't set this directly like the option above, because... diagram is what it is
                this.diagram!.instance.option("toolbox.visibility", this.isEditing ? "visible" : "collapsed");
            });
        }
    }

    public onDiagramDisposing() {
        // dxDiagram has registered handlers to these datasources. need to dispose them first to remove
        // the handlers to prevent data source changes triggering update to dispose diagram
        this.nodesDataSource.dispose();
        this.edgesDataSource.dispose();
    }

    @Autobind
    public loadDiagram() {
        if (!this.diagram || !this.isValidInstance() || !this.diagramEntity || this.diagramEntity.content === "") {
            return;
        }

        // because diagram is stupid, we must set the readOnly flag to false before importing, otherwise the DataSource ArrayStore events don't fire reliably
        // see https://supportcenter.devexpress.com/ticket/details/t1029074 for a simple example of this failing (slightly different implementation, but same effect)
        const initialReadOnly = this.diagram.readOnly;
        this.diagram.readOnly = false;
        this.diagram.instance.import(this.diagramEntity.content);
        this.diagram.readOnly = initialReadOnly;

        // no way to set the zoom to be fitContent, so use this hack
        // https://supportcenter.devexpress.com/ticket/details/t1028407
        this.diagram.autoZoomMode = "fitContent";
        setTimeout(() => this.diagram!.autoZoomMode = "disabled");

        // added a crazy timeout here, because I keep getting onChanges events from the DxDiagram way after I expect to receive them
        setTimeout(() => this.diagramModified.emit(false), 1000);
    }

    public exportDiagramToEntity() {
        this.diagramEntity!.content = this.diagram!.instance.export();
    }

    @Autobind
    public saveDiagram() {
        this.exportDiagramToEntity();
        this.diagramModified.emit(false);
    }

    @Autobind
    private onItemAddedToDataSource(item: any) {
        // if this stops firing reliably, then look at implementing dataSource.push as discussed here: https://supportcenter.devexpress.com/ticket/details/t1029074
        if (!item.entity) {
            const teamKey = "team";
            const keyFunctionKey = "keyFunction";
            const personKey = "person";
            const roleKey = "role";

            if (item.type.startsWith(teamKey)) {
                const id = Number(item.type.substring(teamKey.length));
                const selectedTeam = this.teams.find((team) => team.teamId === id);
                item.entity = selectedTeam;
            } else if (item.type.startsWith(keyFunctionKey)) {
                const id = Number(item.type.substring(keyFunctionKey.length));
                const selectedKeyFunction = this.keyFunctions.find((kf) => kf.keyFunctionId === id);
                item.entity = selectedKeyFunction;
            } else if (item.type.startsWith(personKey)) {
                const id = Number(item.type.substring(personKey.length));
                const selectedPerson = this.people.find((p) => p.personId === id);
                item.entity = selectedPerson;
            } else if (item.type.startsWith(roleKey)) {
                const id = Number(item.type.substring(roleKey.length));
                const selectedRole = this.roles.find((r) => r.roleId === id);
                item.entity = selectedRole;
            }
        }
    }

    private isValidInstance() {
        if (!this.diagram || !this.diagram.instance) {
            return false;
        }

        // - NOT ideal using _disposed but there is no other way to determine if it has been disposed
        // - calling diagram methods when disposed results in errors
        return !(this.diagram as any).instance._disposed;
    }

    public onCustomCommand(event: CustomCommandEvent) {
        if (event.name === this.Align.Top || event.name === this.Align.Bottom || event.name === this.Align.Left || event.name === this.Align.Right) {
            const diagramItems = this.diagram?.instance
                .getSelectedItems()
                .filter((item) => item.itemType !== "connector") as IDiagramItem[];

            if (diagramItems.length > 1) {
                let alignY: number;
                let alignX: number;

                switch (event.name) {
                    case this.Align.Top:
                        alignY = diagramItems[0].position.y;
                        break;
                    case this.Align.Bottom:
                        alignY = diagramItems[0].position.y + diagramItems[0].size.height;
                        break;
                    case this.Align.Left:
                        alignX = diagramItems[0].position.x;
                        break;
                    case this.Align.Right:
                        alignX = diagramItems[0].position.x + diagramItems[0].size.width;
                        break;
                    default:
                        throw new Error("Dev Error: Node Position not set");
                }
                diagramItems.shift();

                this.diagram?.instance.beginUpdate();
                diagramItems.forEach((item: IDiagramItem) => {
                    let data;
                    if (event.name === this.Align.Right || event.name === this.Align.Bottom) {
                        data = {
                            position: {
                                y: alignY ? alignY - item.size.height : item.position.y,
                                x: alignX ? alignX - item.size.width : item.position.x,
                            },
                        };
                    } else {
                        data = {
                            position: {
                                y: alignY ?? item.position.y,
                                x: alignX ?? item.position.x,
                            },
                        };
                    }

                    this.nodesStore.push([
                        {
                            type: "update",
                            data,
                            key: item.key,
                        },
                    ]);
                });
                this.diagram?.instance.endUpdate();

            }
        }
    }
}

interface IDiagramItem extends dxDiagramItem {
    position: {
        x: number,
        y: number,
    }
    size: {
        width: number,
        height: number,
    }
}
