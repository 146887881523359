import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { IntegratedArchitectureFrameworkAuthService } from "../integrated-architecture-framework-auth.service";
import { RolesPageComponent } from "./roles-page.component";

const RolesPageTitle = "Roles";

export const rolesPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-roles-page", RolesPageComponent)
    .atOrganisationUrl("/architecture/roles")
    .verifyingAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2)
    .verifyingFeatures(FeatureName.ArchitectureTier2)
    .withTitle(RolesPageTitle)
    .reloadOnSearch(false)
    .withHelp(StandardIntercomSearchTerm.ManageRoles)
    .build();
