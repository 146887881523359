import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { OrganisationMapPageComponent } from "./organisation-map-page.component";

export const organisationMapPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-organisation-map-page", OrganisationMapPageComponent)
    .atOrganisationUrl("/organisation-map")
    .withTitle("Organisation Map")
    .withSearchKeyword("Key Functions")
    .withSearchKeyword("Teams")
    .withSearchKeyword("Systems")
    .withSearchKeyword("Manage Value Streams")
    .withSearchKeyword("Add Value Stream")
    .build();
