<adapt-dialog [maxWidth]="1000">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content
         class="d-flex flex-column flex-lg-row">

        <div *ngIf="ImplementationKitArticle.CulturalFrameworkCohort"
             [adaptCollapsible]="ImplementationKitArticle.CulturalFrameworkCohort"
             class="left-column d-block pe-3 me-lg-4 mb-4 mb-lg-0">
            <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.CulturalFrameworkCohort"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </div>

        <div class="d-flex flex-column mt-4 mt-lg-0 right-column">
            <!-- Cultural Leader -->
            <div>
                <h3>{{configuration.primaryName}}</h3>

                <div>
                    <adapt-select-person *ngIf="!culturalLeader"
                                         [person]="culturalLeaderConnection?.person"
                                         [activeOnly]="true"
                                         [filter]="isPossibleCulturalLeader"
                                         (personChange)="culturalLeaderSelected($event)"
                                         [userTypes]="allowedUserTypes"
                                         data-test="select-cultural-leader"></adapt-select-person>

                    <adapt-link-person *ngIf="culturalLeader"
                                       [person]="culturalLeader"
                                       [linkDisabled]="true"
                                       [showImage]="true"></adapt-link-person>
                </div>
            </div>
            <!-- Cohort -->
            <div class="d-flex flex-column mt-2">
                <adapt-dashboard-element *ngIf="culturalLeader; else noCulturalLeaderTemplate"
                                         [nested]="true"
                                         [hideHeader]="true"
                                         class="cohort-list-container">
                    <div class="d-flex align-items-center">
                        <h3 class="d-block my-2">{{configuration.secondaryCollectionName}}</h3>
                        <i [adaptTooltip]="CulturalCohortTooltip"
                           class="ms-1 fal fa-circle-info"></i>
                    </div>
                    <dx-list class="dx-list-boxed h-auto"
                             *ngIf="culturalRelationships?.length"
                             [dataSource]="culturalRelationships"
                             [hoverStateEnabled]="false"
                             [focusStateEnabled]="false"
                             [activeStateEnabled]="false"
                             [allowItemDeleting]="true"
                             (onItemDeleted)="removeCulturalRelationship($event)"
                             data-test="cohort-primary-members-list">
                        <div *dxTemplate="let culturalRelationship of 'item'">
                            <adapt-link-person *ngIf="culturalRelationship?.person"
                                               [person]="culturalRelationship.person"
                                               [showImage]="true"
                                               [linkDisabled]="true"
                                               imageSize="sm"></adapt-link-person>
                        </div>
                    </dx-list>

                    <adapt-select-person #selectCohortPersonComponent
                                         [filter]="filterPossiblePrimaryCohortMember"
                                         (personChange)="onPersonSelected($event)"
                                         data-test="select-person-primary-cohort"></adapt-select-person>
                </adapt-dashboard-element>

                <adapt-collapsible-dashboard-element *ngIf="culturalLeader"
                                                     [collapsed]="true"
                                                     [info]="SupportingMemberTooltip"
                                                     class="cohort-list-container supporting"
                                                     name="Supporting members">
                    <dx-list class="dx-list-boxed h-auto"
                             *ngIf="supportingCulturalRelationships?.length"
                             [dataSource]="supportingCulturalRelationships"
                             [hoverStateEnabled]="false"
                             [focusStateEnabled]="false"
                             [activeStateEnabled]="false"
                             [allowItemDeleting]="true"
                             (onItemDeleted)="removeCulturalRelationship($event, true)"
                             data-test="cohort-secondary-members-list">
                        <div *dxTemplate="let culturalRelationship of 'item'">
                            <adapt-link-person *ngIf="culturalRelationship?.person"
                                               [person]="culturalRelationship.person"
                                               [showImage]="true"
                                               [linkDisabled]="true"
                                               imageSize="sm"></adapt-link-person>
                        </div>
                    </dx-list>

                    <adapt-select-person #selectSupportingPersonComponent
                                         [filter]="filterPossibleCohortMember"
                                         (personChange)="onPersonSelected($event, true)"
                                         data-test="select-person-secondary-cohort"></adapt-select-person>
                    <div adapt-collapsible-header-action-right>
                        <span class="badge bg-primary supporting-badge">{{supportingCulturalRelationships.length}}</span>
                    </div>
                </adapt-collapsible-dashboard-element>
            </div>
        </div>
    </div>


    <ng-template #noCulturalLeaderTemplate>
        <div role="alert"
             class="alert alert-info mb-0">
            Select a {{this.configuration.primaryName}} before defining the
            {{this.configuration.secondaryCollectionName}}
        </div>
    </ng-template>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
