import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { SearchParamValue } from "@common/route/route.service";
import { UrlFilterService } from "@common/shell/filter/url-filter.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import isEqual from "lodash.isequal";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { PeerCatchupService } from "../peer-catchup.service";

export enum CatchupRatingType {
    CombinedRating = "CombinedRating",
    Engagement = "Engagement",
    Connection = "Connection",
    Contribution = "Contribution",
}

export interface ICatchupRelationshipsFilterOptions {
    showName: boolean;
    showGood: boolean;
    showMinor: boolean;
    showMajor: boolean;
    showFacilitated: boolean;
    showUnfacilitated: boolean;
    showFacilitator: boolean;
    showInactive: boolean;
    showExternalStakeholders: boolean;
    showOnTrack: boolean;
    showOverdue: boolean;
    showTeamRelationships: boolean;
    ratingType: CatchupRatingType;
    onlyShowTeamCatchups: boolean;
    displayDate: Date;
    excludeMonths: number;
    expiryMonths: number;
    teamId?: number;
}

export enum PcuFilterParamKeys {
    RatingType = "ratingType",
    RatingGood = "showGood",
    RatingMinor = "showMinor",
    RatingMajor = "showMajor",
    TeamNetworkHealth = "teamId",
    StatusOntrack = "showOnTrack",
    StatusOverdue = "showOverdue",
    PeopleInactive = "showInactive",
    PeopleExtStakeholders = "showExternalStakeholders",
    FacilitationFacilitated = "showFacilitated",
    FacilitationUnfacilitated = "showUnfacilitated",
    OnlyShowTeamPcu = "onlyShowTeamCatchups",
    ShowNetworkAtGivenDate = "displayDate",
    ExpiryMonths = "expiryMonths",
    ExcludeMonths = "excludeMonths",
    ViewName = "showName",
    ViewFacilitator = "showFacilitator",
}

@Component({
    selector: "adapt-catchup-relationships-filter",
    templateUrl: "./catchup-relationships-filter.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class CatchupRelationshipsFilterComponent extends BaseComponent implements OnInit {
    public readonly DateFormats = DateFormats;
    public readonly MinDate = moment("20000101", "YYYYMMDD").toDate();

    @Input() public allowTeamSelection = false;
    @Input() public focusOnInactivePerson = false;
    @Input() public isTeamView = false;
    @Input() public showTeamRelationships = false;
    @Output() public optionsChange = new EventEmitter<ICatchupRelationshipsFilterOptions>();

    public ratingTypeOptions = [{
        label: "Combined Rating",
        value: CatchupRatingType.CombinedRating,
        desc: "Showing Engagement, Contribution and the lowest Connection rating",
    }, {
        label: "Engagement",
        value: CatchupRatingType.Engagement,
    }, {
        label: "Connection",
        value: CatchupRatingType.Connection,
    }, {
        label: "Contribution",
        value: CatchupRatingType.Contribution,
    }];
    public currentOptions: ICatchupRelationshipsFilterOptions;
    private defaultOptions: ICatchupRelationshipsFilterOptions;

    public constructor(
        elementRef: ElementRef,
        private catchupService: PeerCatchupService,
        private urlFilterService: UrlFilterService,
    ) {
        super(elementRef);

        this.defaultOptions = {
            showName: false,
            showGood: true,
            showMinor: true,
            showMajor: true,
            showFacilitated: true,
            showUnfacilitated: true,
            showFacilitator: false,
            showInactive: false,
            showExternalStakeholders: false,
            showOnTrack: true,
            showOverdue: true,
            showTeamRelationships: false,
            ratingType: CatchupRatingType.Connection,
            onlyShowTeamCatchups: false,
            displayDate: moment().endOf("day").toDate(),
            excludeMonths: 12,
            expiryMonths: 6,
        } as ICatchupRelationshipsFilterOptions;
        this.currentOptions = { ...this.defaultOptions };
    }

    public async ngOnInit() {
        this.defaultOptions.showInactive = this.focusOnInactivePerson;
        this.defaultOptions.showTeamRelationships = this.showTeamRelationships;
        this.defaultOptions.expiryMonths = await lastValueFrom(this.catchupService.getCatchupFrequency());
        if (this.focusOnInactivePerson) {
            this.defaultOptions.excludeMonths = 25;
        }

        this.currentOptions = { ...this.defaultOptions };
        this.setCurrentOptionsFromUrl();

        this.isInitialised = true; // only emit after init (so that subscriber won't get so many emits on startup and overcrowded refreshes)
        await this.emitOptionsChanged();
    }

    public get isDefault() {
        return isEqual(this.defaultOptions, this.currentOptions);
    }

    public get ratingTypeDescription() {
        return this.ratingTypeOptions.find((i) => i.value === this.currentOptions.ratingType)?.desc;
    }

    public get expiryMonthsDisplay() {
        let result = `${this.currentOptions.expiryMonths} months`;
        if (this.currentOptions.expiryMonths === this.defaultOptions.expiryMonths) {
            result += " (System Default)";
        }

        return result;
    }

    public get excludeMonthsDisplay() {
        if (this.currentOptions.excludeMonths > 24) {
            return "never";
        } else {
            return `${this.currentOptions.excludeMonths} months`;
        }
    }

    public get canShowTeamRelationships() {
        // Needs all the followings to show the missing catchups (otherwise catchup may be hidden from missing view options)
        return this.currentOptions.showGood &&
            this.currentOptions.showMinor &&
            this.currentOptions.showMajor &&
            this.currentOptions.showOnTrack &&
            this.currentOptions.showOverdue &&
            this.currentOptions.showFacilitated &&
            this.currentOptions.showUnfacilitated;
    }

    public async displayDateChanged(date: Date) {
        this.currentOptions.displayDate = moment(date).endOf("day").toDate();
        await this.emitOptionsChanged();
    }

    public async emitOptionsChanged() {
        if (this.isInitialised) {
            await this.setUrlParams();
            this.optionsChange.emit(this.currentOptions);
        }
    }

    public async onTeamChanged(team?: Team) {
        this.currentOptions.teamId = team?.teamId;
        await this.emitOptionsChanged();
    }

    public async resetOptions() {
        this.currentOptions = { ...this.defaultOptions };
        await this.emitOptionsChanged();
    }

    private async setUrlParams() {
        const fil: Record<PcuFilterParamKeys, SearchParamValue> = {
            [PcuFilterParamKeys.RatingType]: !isEqual(this.defaultOptions.ratingType, this.currentOptions.ratingType) ? this.currentOptions.ratingType : undefined,
            [PcuFilterParamKeys.RatingGood]: !isEqual(this.defaultOptions.showGood, this.currentOptions.showGood) ? this.currentOptions.showGood : undefined,
            [PcuFilterParamKeys.RatingMinor]: !isEqual(this.defaultOptions.showMinor, this.currentOptions.showMinor) ? this.currentOptions.showMinor : undefined,
            [PcuFilterParamKeys.RatingMajor]: !isEqual(this.defaultOptions.showMajor, this.currentOptions.showMajor) ? this.currentOptions.showMajor : undefined,
            [PcuFilterParamKeys.TeamNetworkHealth]: !isEqual(this.defaultOptions.teamId, this.currentOptions.teamId) ? this.currentOptions.teamId : undefined,
            [PcuFilterParamKeys.StatusOntrack]: !isEqual(this.defaultOptions.showOnTrack, this.currentOptions.showOnTrack) ? this.currentOptions.showOnTrack : undefined,
            [PcuFilterParamKeys.StatusOverdue]: !isEqual(this.defaultOptions.showOverdue, this.currentOptions.showOverdue) ? this.currentOptions.showOverdue : undefined,
            [PcuFilterParamKeys.PeopleInactive]: !isEqual(this.defaultOptions.showInactive, this.currentOptions.showInactive) ? this.currentOptions.showInactive : undefined,
            [PcuFilterParamKeys.PeopleExtStakeholders]: !isEqual(this.defaultOptions.showExternalStakeholders, this.currentOptions.showExternalStakeholders) ? this.currentOptions.showExternalStakeholders : undefined,
            [PcuFilterParamKeys.FacilitationFacilitated]: !isEqual(this.defaultOptions.showFacilitated, this.currentOptions.showFacilitated) ? this.currentOptions.showFacilitated : undefined,
            [PcuFilterParamKeys.FacilitationUnfacilitated]: !isEqual(this.defaultOptions.showUnfacilitated, this.currentOptions.showUnfacilitated) ? this.currentOptions.showUnfacilitated : undefined,
            [PcuFilterParamKeys.OnlyShowTeamPcu]: !isEqual(this.defaultOptions.onlyShowTeamCatchups, this.currentOptions.onlyShowTeamCatchups) ? this.currentOptions.onlyShowTeamCatchups : undefined,
            [PcuFilterParamKeys.ShowNetworkAtGivenDate]: !isEqual(this.defaultOptions.displayDate, this.currentOptions.displayDate) ? moment(this.currentOptions.displayDate).format(DateFormats.moment.short) : undefined,
            [PcuFilterParamKeys.ExpiryMonths]: !isEqual(this.defaultOptions.expiryMonths, this.currentOptions.expiryMonths) ? this.currentOptions.expiryMonths : undefined,
            [PcuFilterParamKeys.ExcludeMonths]: !isEqual(this.defaultOptions.excludeMonths, this.currentOptions.excludeMonths) ? this.currentOptions.excludeMonths : undefined,
            [PcuFilterParamKeys.ViewName]: !isEqual(this.defaultOptions.showName, this.currentOptions.showName) ? this.currentOptions.showName : undefined,
            [PcuFilterParamKeys.ViewFacilitator]: !isEqual(this.defaultOptions.showFacilitator, this.currentOptions.showFacilitator) ? this.currentOptions.showFacilitator : undefined,
        };

        await this.urlFilterService.setFilter(fil);
    }

    private setCurrentOptionsFromUrl() {
        const paramValues = this.urlFilterService.getFilters(Object.values(PcuFilterParamKeys));

        paramValues.forEach((param) => {
            if (param.value) {
                switch (param.name) {
                    case PcuFilterParamKeys.RatingType:
                        this.currentOptions.ratingType = CatchupRatingType[param.value as keyof typeof CatchupRatingType];
                        break;
                    case PcuFilterParamKeys.TeamNetworkHealth:
                        this.currentOptions.teamId = NumberUtilities.parseNumber(param.value);
                        break;
                    case PcuFilterParamKeys.ShowNetworkAtGivenDate:
                        this.currentOptions.displayDate = moment(param.value, DateFormats.moment.short).toDate();
                        break;
                    case PcuFilterParamKeys.ExpiryMonths:
                        this.currentOptions.expiryMonths = NumberUtilities.parseNumber(param.value) || this.defaultOptions.expiryMonths;
                        break;
                    case PcuFilterParamKeys.ExcludeMonths:
                        this.currentOptions.excludeMonths = NumberUtilities.parseNumber(param.value) || this.defaultOptions.excludeMonths;
                        break;
                    case PcuFilterParamKeys.ViewName:
                    case PcuFilterParamKeys.ViewFacilitator:
                    case PcuFilterParamKeys.OnlyShowTeamPcu:
                    case PcuFilterParamKeys.FacilitationFacilitated:
                    case PcuFilterParamKeys.FacilitationUnfacilitated:
                    case PcuFilterParamKeys.PeopleInactive:
                    case PcuFilterParamKeys.PeopleExtStakeholders:
                    case PcuFilterParamKeys.StatusOntrack:
                    case PcuFilterParamKeys.StatusOverdue:
                    case PcuFilterParamKeys.RatingGood:
                    case PcuFilterParamKeys.RatingMinor:
                    case PcuFilterParamKeys.RatingMajor:
                        this.currentOptions[param.name] = param.value === "true";
                        break;
                }
            }
        });
    }
}
