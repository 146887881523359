import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IConfigGroup, IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { allTeamConfigGroups } from "./team-configuration-page-elements";

@Component({
    selector: "adapt-team-configuration",
    templateUrl: "./team-configuration-page.component.html",
})
export class TeamConfigurationPageComponent extends BaseRoutedComponent implements OnInit {
    public teamId$ = new ReplaySubject<number | undefined>(1);
    public team$: Observable<Team | undefined>;
    public teamDashboardLink$: Observable<string>;

    public ConfigArea = ConfigArea;
    public configGroups: IConfigGroup[] = [];
    public selectedItem?: IConfigItem;

    public constructor(
        teamsService: CommonTeamsService,
        injector: Injector,
        @Inject(TEAM_DASHBOARD_PAGE) teamDashboardPageRoute: IAdaptRoute<{teamId: number}>,
    ) {
        super(injector);

        this.team$ = this.teamId$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => teamsService.getTeamById(team)),
            tap((team) => {
                if (team) {
                    this.notifyActivated();
                } else {
                    this.routeService.gotoHome();
                }
            }),
        );

        this.teamDashboardLink$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap(({ teamId }) => teamDashboardPageRoute.getRoute({ teamId })),
            this.takeUntilDestroyed(),
        );
    }

    public ngOnInit() {
        this.updateDataFromPageParams();
        this.navigationEnd.subscribe(() => this.updateDataFromPageParams());
    }

    private updateDataFromPageParams() {
        this.configGroups = allTeamConfigGroups();

        const teamId = this.getRouteParamInt("teamId");
        this.teamId$.next(teamId);
    }
}
