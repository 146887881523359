import { Component, Inject, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionRole } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { FocusableElement } from "@common/ux/adapt-common-dialog/focusable";
import { StandardDialogButtonsComponent } from "@common/ux/adapt-common-dialog/standard-dialog-buttons/standard-dialog-buttons.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { Observable, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { RoleService } from "../../role/role.service";

@Component({
    selector: "adapt-delete-key-function-dialog",
    templateUrl: "./delete-key-function-dialog.component.html",
})
export class DeleteKeyFunctionDialogComponent extends BaseDialogWithDiscardConfirmationComponent<KeyFunction> {
    public readonly dialogName = "DeleteKeyFunction";
    public readonly entitiesToConfirm = [this.keyFunction];
    public readonly autoResolveData = this.keyFunction;

    public teams$: Observable<Team[]>;
    public keyFunctionRoles$: Observable<KeyFunctionRole[]>;
    public keyFunctionTeams$: Observable<Team[]>;
    public confirmed = false;
    public hasRelatedProcessMap = false;

    @ViewChild(StandardDialogButtonsComponent) public elementToFocus: FocusableElement;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public keyFunction: KeyFunction,
        keyFunctionsService: KeyFunctionsService,
        roleService: RoleService,
    ) {
        super();

        this.teams$ = keyFunctionsService.getTeamsWithKeyFunctionAsSoleLocation(keyFunction);
        this.keyFunctionRoles$ = keyFunctionsService.getKeyFunctionRoles(keyFunction);
        this.keyFunctionTeams$ = keyFunctionsService.getKeyFunctionTeamLocations(keyFunction).pipe(
            map((teamLocations) => teamLocations.map((i) => i.team)));
        if (keyFunction.leaderRole) {
            roleService.getRelatedProcessMaps(keyFunction.leaderRole).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((processMaps) => this.hasRelatedProcessMap = processMaps.length > 0);
        }
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.remove(this.keyFunction).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.keyFunction)),
            tap(() => this.resolve(this.keyFunction)),
        );
    }
}
