import { Injectable } from "@angular/core";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { OrgCommonNavigationTab } from "@org-common/lib/sidebar/org-common-navigation-tab";
import { OrganisationHierarchyNavigationService } from "app/platform/navigation/organisation-hierarchy-navigation.service";
import { OrganisationTabContentComponent } from "./organisation-tab-content.component";

@Injectable()
export class OrganisationTab extends OrgCommonNavigationTab {
    public id = "sidebar-organisation";
    public label = "Organisation";
    public ordinal = 2;

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: OrganisationHierarchyNavigationService.IconClass,
            iconText: "Org",
        },
    };

    public content: IComponentRender<OrganisationTabContentComponent> = {
        component: OrganisationTabContentComponent,
    };

    protected getHierarchyId() {
        return OrganisationHierarchyNavigationService.HierarchyId;
    }
}
