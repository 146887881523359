"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PairingHeap = function () {
  function PairingHeap(elem) {
    this.elem = elem;
    this.subheaps = [];
  }
  PairingHeap.prototype.toString = function (selector) {
    var str = "",
      needComma = false;
    for (var i = 0; i < this.subheaps.length; ++i) {
      var subheap = this.subheaps[i];
      if (!subheap.elem) {
        needComma = false;
        continue;
      }
      if (needComma) {
        str = str + ",";
      }
      str = str + subheap.toString(selector);
      needComma = true;
    }
    if (str !== "") {
      str = "(" + str + ")";
    }
    return (this.elem ? selector(this.elem) : "") + str;
  };
  PairingHeap.prototype.forEach = function (f) {
    if (!this.empty()) {
      f(this.elem, this);
      this.subheaps.forEach(function (s) {
        return s.forEach(f);
      });
    }
  };
  PairingHeap.prototype.count = function () {
    return this.empty() ? 0 : 1 + this.subheaps.reduce(function (n, h) {
      return n + h.count();
    }, 0);
  };
  PairingHeap.prototype.min = function () {
    return this.elem;
  };
  PairingHeap.prototype.empty = function () {
    return this.elem == null;
  };
  PairingHeap.prototype.contains = function (h) {
    if (this === h) return true;
    for (var i = 0; i < this.subheaps.length; i++) {
      if (this.subheaps[i].contains(h)) return true;
    }
    return false;
  };
  PairingHeap.prototype.isHeap = function (lessThan) {
    var _this = this;
    return this.subheaps.every(function (h) {
      return lessThan(_this.elem, h.elem) && h.isHeap(lessThan);
    });
  };
  PairingHeap.prototype.insert = function (obj, lessThan) {
    return this.merge(new PairingHeap(obj), lessThan);
  };
  PairingHeap.prototype.merge = function (heap2, lessThan) {
    if (this.empty()) return heap2;else if (heap2.empty()) return this;else if (lessThan(this.elem, heap2.elem)) {
      this.subheaps.push(heap2);
      return this;
    } else {
      heap2.subheaps.push(this);
      return heap2;
    }
  };
  PairingHeap.prototype.removeMin = function (lessThan) {
    if (this.empty()) return null;else return this.mergePairs(lessThan);
  };
  PairingHeap.prototype.mergePairs = function (lessThan) {
    if (this.subheaps.length == 0) return new PairingHeap(null);else if (this.subheaps.length == 1) {
      return this.subheaps[0];
    } else {
      var firstPair = this.subheaps.pop().merge(this.subheaps.pop(), lessThan);
      var remaining = this.mergePairs(lessThan);
      return firstPair.merge(remaining, lessThan);
    }
  };
  PairingHeap.prototype.decreaseKey = function (subheap, newValue, setHeapNode, lessThan) {
    var newHeap = subheap.removeMin(lessThan);
    subheap.elem = newHeap.elem;
    subheap.subheaps = newHeap.subheaps;
    if (setHeapNode !== null && newHeap.elem !== null) {
      setHeapNode(subheap.elem, subheap);
    }
    var pairingNode = new PairingHeap(newValue);
    if (setHeapNode !== null) {
      setHeapNode(newValue, pairingNode);
    }
    return this.merge(pairingNode, lessThan);
  };
  return PairingHeap;
}();
exports.PairingHeap = PairingHeap;
var PriorityQueue = function () {
  function PriorityQueue(lessThan) {
    this.lessThan = lessThan;
  }
  PriorityQueue.prototype.top = function () {
    if (this.empty()) {
      return null;
    }
    return this.root.elem;
  };
  PriorityQueue.prototype.push = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    var pairingNode;
    for (var i = 0, arg; arg = args[i]; ++i) {
      pairingNode = new PairingHeap(arg);
      this.root = this.empty() ? pairingNode : this.root.merge(pairingNode, this.lessThan);
    }
    return pairingNode;
  };
  PriorityQueue.prototype.empty = function () {
    return !this.root || !this.root.elem;
  };
  PriorityQueue.prototype.isHeap = function () {
    return this.root.isHeap(this.lessThan);
  };
  PriorityQueue.prototype.forEach = function (f) {
    this.root.forEach(f);
  };
  PriorityQueue.prototype.pop = function () {
    if (this.empty()) {
      return null;
    }
    var obj = this.root.min();
    this.root = this.root.removeMin(this.lessThan);
    return obj;
  };
  PriorityQueue.prototype.reduceKey = function (heapNode, newKey, setHeapNode) {
    if (setHeapNode === void 0) {
      setHeapNode = null;
    }
    this.root = this.root.decreaseKey(heapNode, newKey, setHeapNode, this.lessThan);
  };
  PriorityQueue.prototype.toString = function (selector) {
    return this.root.toString(selector);
  };
  PriorityQueue.prototype.count = function () {
    return this.root.count();
  };
  return PriorityQueue;
}();
exports.PriorityQueue = PriorityQueue;
