import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptExternalDashboardModule } from "@org-common/lib/external-dashboard/external-dashboard.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { TeamDashboardSharedService } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.service";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptProcessMapSharedModule } from "app/features/architecture/process-map/process-map-shared.module";
import { AdaptPeerCatchupModule } from "app/features/people/peer-catchup/peer-catchup.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { AdaptActivityFeedModule } from "app/general/activity-feed/activity-feed.module";
import { AdaptAppOrganisationModule } from "app/organisation/app.organisation.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { AdaptOrganisationMapModule } from "../../features/architecture/organisation-map/organisation-map.module";
import { TeamDashboardPageComponent } from "./team-dashboard-page/team-dashboard-page.component";
import { teamDashboardPageRoute } from "./team-dashboard-page/team-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptDirectorySharedModule,
        AdaptActivityFeedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptFeaturesModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptProcessMapSharedModule,
        AdaptOrganisationMapModule,
        AdaptGuidingPhilosophyModule,
        AdaptValuesConstitutionModule,
        AdaptEmbedShellModule,
        AdaptPeerCatchupModule,
        AdaptSurveyModule,
        AdaptTeamsModule,
        AdaptObjectivesModule,
        AdaptCommonMeetingsModule,
        AdaptExternalDashboardModule,
        AdaptAppOrganisationModule,
        AdaptOrganisationSharedModule,
        AdaptTwoColumnModule,
        AdaptKanbanModule,
        AdaptSystemisationModule,
        AdaptLabellingModule,
        AdaptTeamDashboardSharedModule,
        AdaptDashboardElementModule,
        AdaptCommonMeetingsModule,
        AdaptCommonTeamsModule,
        AdaptTooltipModule,
        AdaptToolbarModule,
    ],
    declarations: [
        TeamDashboardPageComponent,
    ],
})

export class AdaptTeamDashboardModule {
    public static readonly Routes = [
        ...teamDashboardPageRoute.routes,
    ];

    public constructor(teamDashboardSharedService: TeamDashboardSharedService) {
        teamDashboardSharedService.registerTeamDashboardLinkProvider((team) => teamDashboardPageRoute.getRoute({ teamId: team.teamId }));
    }
}
