<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>Edit customer stories</h3>

    <div adapt-dialog-content>
        <div class="alert alert-guidance">
            Identify the customer roles and their stories or expectations for each role.
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="card card-borderless">
                    <div class="card-header">
                        <h2 class="card-title">Customer roles</h2>
                    </div>

                    <div class="card-body">
                        <dx-list [items]="valueStream.stakeholders"
                                 selectionMode="single"
                                 [allowItemDeleting]="true"
                                 (onSelectionChanged)="onStakeholderSelectionChanged($event)"
                                 (onItemReordered)="onStakeholderItemReordered($event)"
                                 (onItemDeleting)="onStakeholderItemDeleting($event)"
                                 (onItemDeleted)="onStakeholderItemDeleted($event)"
                                 (onInitialized)="onStakeholderListInitialised($event)"
                                 noDataText="No customer roles defined.">
                            <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
                            <div *dxTemplate="let item of 'item'">{{item?.stakeholder?.name}}</div>
                        </dx-list>

                        <dx-select-box class="mt-2"
                                       [dataSource]="dataSource"
                                       displayExpr="name"
                                       (onInitialized)="onStakeholderSelectInitialised($event)"
                                       (onSelectionChanged)="onStakeholderSelected($event)">
                            <div *dxTemplate="let item of 'item'">{{item.name}}</div>
                        </dx-select-box>

                        <button class="mt-3"
                                adaptButton="addLink"
                                [adaptBlockingClick]="newStakeholder">Add a new customer role</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6"
                 [ngSwitch]="!!selectedStakeholder?.stakeholder">
                <div class="card card-borderless">
                    <div class="card-header">
                        <h2 *ngSwitchCase="true"
                            class="card-title">
                            As a {{selectedStakeholder?.stakeholder?.name}}
                        </h2>

                        <h2 *ngSwitchCase="false"
                            class="card-title">
                            No Customer Role Selected
                        </h2>
                    </div>

                    <div class="card-body">
                        <dx-list *ngSwitchCase="true"
                                 [items]="valueStreamStakeholderStories"
                                 noDataText="No customer stories selected."
                                 [allowItemDeleting]="true"
                                 (onItemReordered)="onStakeholderStoryItemReordered($event)"
                                 (onItemDeleting)="onStakeholderStoryItemDeleting($event)"
                                 (onItemDeleted)="onStakeholderStoryItemDeleted($event)"
                                 (onInitialized)="onStoriesListInitialised($event)">
                            <!-- Options -->
                            <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>

                            <!-- Templates -->
                            <div *dxTemplate="let item of 'item'">
                                <adapt-display-customer-story [customerStory]="item.stakeholderStory"
                                                              class="dx-list-item-content-text"></adapt-display-customer-story>
                            </div>
                        </dx-list>

                        <dx-select-box [items]="stakeholderStories"
                                       (onInitialized)="onStakeholderStorySelectInitialised($event)"
                                       (onSelectionChanged)="onStakeholderStorySelected($event)">
                            <div *dxTemplate="let item of 'item'">
                                <adapt-display-customer-story [customerStory]="item">
                                </adapt-display-customer-story>
                            </div>
                        </dx-select-box>

                        <button *ngSwitchCase="true"
                                class="mt-3"
                                adaptButton="addLink"
                                [adaptBlockingClick]="addStakeholderStory">Add a new customer story</button>

                        <p *ngSwitchCase="false">Please select a customer role on the left.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="ok"
                                   (saveClick)="ok()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
