import { Component, Injector, OnInit } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { KeyFunctionsUiService } from "app/features/architecture/key-functions/key-functions-ui.service";
import { ViewOption } from "app/features/architecture/organisation-map/organisation-explorer/organisation-explorer.component";
import { OrganisationMapUiService } from "app/features/architecture/organisation-map/organisation-map-ui.service";
import { Tier1ArchitectureUiService } from "app/features/architecture/tier1-architecture/tier1-architecture-ui.service";
import { BehaviorSubject, map } from "rxjs";

interface IViewOption {
    option: ViewOption;
    iconClass: string;
    text: string;
}

@Component({
    templateUrl: "./organisation-map-page.component.html",
})
export class OrganisationMapPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly PageParam = "page";
    public static readonly DefaultViewOption = ViewOption.OrganisationMap;

    public tier1EditAccessVerifierId = Tier1ArchitectureAuthService.EditTier1;
    public selectedViewOption: ViewOption;
    public availableViewOptions: IViewOption[];

    public manageValueStreams = this.tier1UiService.manageValueStreams;
    public addKeyFunction = this.keyFunctionsUiService.addKeyFunction.bind(this.keyFunctionsUiService);
    public editOrganisationMap = this.orgMapUiService.editOrganisationMap;

    public includePrivateTeams$ = new BehaviorSubject(false);
    private includePrivateTeamsMenuItem: IAdaptMenuItem = {
        text: "Show private teams",
        isChecked: this.includePrivateTeams$.value,
        onIsCheckedChange: (isChecked: boolean) => this.includePrivateTeams$.next(isChecked),
    };
    private editMapMenuItem: IAdaptMenuItem = {
        text: "Edit map",
        icon: "fal fa-fw fa-edit",
        onClick: () => this.editOrganisationMap().pipe(
            this.takeUntilDestroyed(),
        ).subscribe(),
    };
    private addKeyFunctionMenuItem: IAdaptMenuItem = {
        text: "Add key function",
        icon: "fal fa-fw fa-plus-square",
        onClick: () => this.addKeyFunction().pipe(
            this.takeUntilDestroyed(),
        ).subscribe(),
    };
    private manageValueStreamsMenuItem: IAdaptMenuItem = {
        text: "Manage value streams",
        icon: "fal fa-fw fa-cube",
        onClick: () => this.manageValueStreams().pipe(
            this.takeUntilDestroyed(),
        ).subscribe(),
    };
    public pageMenuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            this.includePrivateTeamsMenuItem,
        ],
    }];

    public constructor(
        private keyFunctionsUiService: KeyFunctionsUiService,
        private orgMapUiService: OrganisationMapUiService,
        private tier1UiService: Tier1ArchitectureUiService,
        commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
        tier1AuthService: Tier1ArchitectureAuthService,
        injector: Injector,
        teamsService: CommonTeamsService,
    ) {
        super(injector);

        this.selectedViewOption = ViewOption.OrganisationMap;
        this.availableViewOptions = [
            {
                option: ViewOption.OrganisationMap,
                iconClass: KeyFunction.IconClass,
                text: "Organisation map",
            },
            {
                option: ViewOption.OrganisationMapTeams,
                iconClass: "fal fa-users",
                text: "Organisation map & teams",
            },
        ];

        if (commonArchAuthService.currentPersonCan(commonArchAuthService.personCanReadTier2)) {
            this.availableViewOptions.push({
                option: ViewOption.OrganisationMapSystems,
                iconClass: SystemEntity.IconClass,
                text: "Organisation map & systems",
            });
        }

        if (tier1AuthService.currentPersonCanEditTier1) {
            this.pageMenuItems[0].items!.unshift(
                this.editMapMenuItem,
                this.addKeyFunctionMenuItem,
                this.manageValueStreamsMenuItem,
            );
            this.includePrivateTeamsMenuItem.separator = true;
        }

        this.includePrivateTeamsMenuItem.isChecked = teamsService.isShowingPrivateTeams;
        this.includePrivateTeams$.next(this.includePrivateTeamsMenuItem.isChecked);
        this.includePrivateTeams$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((showPrivateTeams) => teamsService.isShowingPrivateTeams = showPrivateTeams);
    }

    public ngOnInit() {
        const page = this.getSearchParameterValue(OrganisationMapPageComponent.PageParam) as ViewOption;

        this.updateUrl(Object.values(ViewOption).includes(page) ? page : OrganisationMapPageComponent.DefaultViewOption);
        this.notifyActivated();
    }

    public updateUrl(updatedOption: ViewOption) {
        this.selectedViewOption = updatedOption;
        this.setSearchParameterValue(OrganisationMapPageComponent.PageParam, this.selectedViewOption);
    }

    @Autobind
    public filterForTeams(allTeams: Team[]) {
        return this.includePrivateTeams$.pipe(
            map((includePrivateTeams) => allTeams.filter((team) => includePrivateTeams || !team.isPrivate)),
        );
    }
}
