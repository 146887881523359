import { Component, Input, OnInit } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { DirectoryService } from "app/features/people/directory/directory.service";
import { EMPTY, from } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ConfigureRoleDialogComponent, IConfigureRoleDialogData } from "../configure-role-dialog/configure-role-dialog.component";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkUiService } from "../integrated-architecture-framework-ui.service";
import { RoleActions } from "./role-actions.enum";

@Component({
    selector: "adapt-role-actions",
    template: `<adapt-menu [items]="actionMenu" data-test="role-actions"></adapt-menu>`,
})
export class RoleActionsComponent extends BaseComponent implements OnInit {
    @Input() public role!: Role;

    public actionMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];

    private readonly configureRoleItem: IAdaptMenuItem;
    private readonly copyRoleItem: IAdaptMenuItem;
    private readonly deleteRoleItem: IAdaptMenuItem;
    private readonly deactivateRoleItem: IAdaptMenuItem;
    private readonly reactivateRoleItem: IAdaptMenuItem;

    @Input() public hasTier2Edit = false;
    private canRemoveRole = false;

    public constructor(
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
        private commonDialogService: AdaptCommonDialogService,
        private integratedArchitectureFrameworkUiService: IntegratedArchitectureFrameworkUiService,
        private directoryService: DirectoryService,
    ) {
        super();

        this.configureRoleItem = {
            text: RoleActions.ConfigureRole,
            icon: "fal fa-fw fa-cog",
            onClick: this.configureRoleAction,
        };
        this.copyRoleItem = {
            text: RoleActions.CopyRole,
            icon: "fal fa-fw fa-copy",
            onClick: this.copyRoleAction,
        };
        this.deleteRoleItem = {
            text: RoleActions.DeleteRole,
            icon: "fal fa-fw fa-trash-alt",
            onClick: this.deleteRoleAction,
            tooltip: "This non-system role may be deleted as there are no historic references to it",
        };
        this.deactivateRoleItem = {
            text: RoleActions.DeactivateRole,
            icon: "fal fa-fw fa-archive",
            onClick: this.deactivateRoleAction,
            tooltip: "This non-system role may only be deactivated as there are historic references to it",
        };
        this.reactivateRoleItem = {
            text: RoleActions.ReactivateRole,
            icon: "fal fa-fw fa-undo",
            onClick: this.reactivateRoleAction,
        };

        this.actionMenu[0].items = [
            this.configureRoleItem,
            this.copyRoleItem,
            this.deleteRoleItem,
            this.deactivateRoleItem,
            this.reactivateRoleItem,
        ];
    }

    public async ngOnInit() {
        this.canRemoveRole = await this.directoryService.promiseToVerifyRoleRemoval(this.role);
        this.updateItemsState();
    }

    private updateItemsState() {
        // there is an odd occasion where the role has not been set (prob due a delete happening, so guard against this scenario)
        if (!this.role) {
            this.configureRoleItem.visible = false;
            this.deleteRoleItem.visible = false;
            this.deactivateRoleItem.visible = false;
            this.reactivateRoleItem.visible = false;
            this.copyRoleItem.visible = false;
            return;
        }

        this.configureRoleItem.visible = this.hasTier2Edit && this.role.isActive();
        this.deleteRoleItem.visible = this.hasTier2Edit
            && this.role.roleConnections.length === 0
            && (this.role.extensions.isLeaderAccessRole() || !this.role.extensions.hasAccessPermissions())
            && !this.role.extensions.isCoachAccessRole()
            && !this.role.extensions.isSystemAllocatedRole();
        this.deactivateRoleItem.visible = this.hasTier2Edit
            && this.canRemoveRole   // won't allow archiving of role that gives you access management
            && !this.deleteRoleItem.visible
            && this.role.isActive()
            && (this.role.extensions.isLeaderAccessRole() || !this.role.extensions.hasAccessPermissions())
            && !this.role.extensions.isCoachAccessRole()
            && !this.role.extensions.isSystemAllocatedRole();
        this.reactivateRoleItem.visible = this.hasTier2Edit && !this.role.isActive();

        this.copyRoleItem.visible = this.hasTier2Edit
            && !this.role.extensions.isSystemAllocatedRole();
        if (!this.deleteRoleItem.visible && !this.deactivateRoleItem.visible && this.hasTier2Edit && this.role.isActive()) {
            if (this.role.extensions?.isSystemAllocatedRole()) {
                this.deleteRoleItem.text = "Delete / Deactivate";
                this.deleteRoleItem.visible = true;
                this.deleteRoleItem.disabled = true;
                (this.deleteRoleItem as any).tooltip = "This is a system role that cannot be deleted or deactivated";
            } else if (!this.canRemoveRole) {
                this.deactivateRoleItem.visible = true;
                this.deactivateRoleItem.disabled = true;
                (this.deactivateRoleItem as any).tooltip = "Cannot deactivate this role as the current user as it will remove your access management permission";
            }
        }
    }

    @Autobind
    private configureRoleAction() {
        const data: IConfigureRoleDialogData = {
            role: this.role,
        };

        this.commonDialogService.open(ConfigureRoleDialogComponent, data).pipe(
            tap(() => {
                this.updateItemsState();
                this.broadcastRefetchDataEvent();
            }),
            catchError(() => {
                this.broadcastUpdateDimensionsEvent();
                return EMPTY;
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private copyRoleAction() {
        from(this.integratedArchitectureFrameworkService.promiseToCopyRole(this.role)).pipe(
            switchMap((copyResult) => this.editCopiedRole(copyResult)),
            tap(() => {
                this.updateItemsState();
                this.broadcastRefetchDataEvent();
            }),
            catchError(() => {
                this.broadcastUpdateDimensionsEvent();
                return EMPTY;
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private deleteRoleAction() {
        this.integratedArchitectureFrameworkUiService.promptToDeleteRole(this.role)
            .subscribe(() => this.broadcastRefetchDataEvent());
    }

    @Autobind
    private deactivateRoleAction() {
        this.integratedArchitectureFrameworkUiService.promptToDeactivateRole(this.role)
            .subscribe(() => this.broadcastRefetchDataEvent());
    }

    @Autobind
    private reactivateRoleAction() {
        this.integratedArchitectureFrameworkUiService.promptToReactivateRole(this.role)
            .subscribe(() => this.broadcastRefetchDataEvent());
    }

    private editCopiedRole(copyResult: { role: Role, changedEntities: IBreezeEntity[] }) {
        const rdata: IConfigureRoleDialogData = {
            role: copyResult.role,
            changedEntities: copyResult.changedEntities,
        };

        return this.commonDialogService.open(ConfigureRoleDialogComponent, rdata);
    }

    private broadcastRefetchDataEvent() {
        this.integratedArchitectureFrameworkUiService.emitRefetchRequired();
    }

    private broadcastUpdateDimensionsEvent() {
        // need this to realign fixed and non-fixed columns (see user-role-actions.component.ts)
        // - especially when a dialog is popped up, the background curtain will remove the scrollbar
        //   causing the grid to not redrawn correctly.
        this.integratedArchitectureFrameworkUiService.emitGridDimensionsUpdated();
    }
}
