<div *adaptLoading="isLoading">
    <dx-chart *ngIf="!!data?.averageCareerValuation"
              [pathModified]="true"
              [dataSource]="data?.categoryStats || []"
              (onInitialized)="onInitialized($event)">
        <dxo-size [height]=" 300">
        </dxo-size>
        <dxi-series valueField="averageActual"
                    name="Actual">
        </dxi-series>
        <dxi-series valueField="averageDifferential"
                    name="Differential">
        </dxi-series>
        <dxo-legend [visible]="false"></dxo-legend>
        <dxo-common-series-settings argumentField="name"
                                    type="stackedbar"
                                    tagField="id">
            <dxo-label [visible]="false"></dxo-label>
        </dxo-common-series-settings>
        <dxo-value-axis visualRangeUpdateMode="keep"
                        [visualRange]="[0, null]">
        </dxo-value-axis>
        <dxo-argument-axis>
            <dxo-label overlappingBehavior="stagger"
                       wordWrap="none"></dxo-label>
        </dxo-argument-axis>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customizeTooltip">
            <dxo-format type="fixedPoint"
                        [precision]="1"></dxo-format>
        </dxo-tooltip>
    </dx-chart>

    <div *ngIf="!data?.averageCareerValuation">
        <adapt-no-cultural-measurement label="Career Valuations"></adapt-no-cultural-measurement>
    </div>
</div>
