import { Component, ElementRef } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "app/features/culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import dxChart, { InitializedEvent } from "devextreme/viz/chart";
import { ChartSeries } from "devextreme/viz/common";
import moment from "moment";
import { defer, switchMap } from "rxjs";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";

interface ICulturalIndexTimelineData {
    date: Date;
    category1Count: number;
    category2Count: number;
    category3Count: number;
    category4Count: number;
}

@Component({
    selector: "adapt-ci-timeline",
    templateUrl: "./ci-timeline.component.html",
})
export class CulturalIndexTimelineComponent extends BaseComponent {
    public static readonly Id = "adaptCulturalIndexTimeline";

    public chart?: dxChart;

    public timelineData: ICulturalIndexTimelineData[] = [];

    public filterParameters?: ICulturalLeadershipMeasurementAnalysisFilter;

    public series: ChartSeries[] = [];

    public isLoading = true;

    public constructor(
        public elementRef: ElementRef,
        public responsiveService: ResponsiveService,
        private culturalIndexService: CulturalIndexService,
        private culturalIndexUiService: CulturalIndexUiService,
        filterService: CulturalIndexFilterService,
    ) {
        super(elementRef);

        filterService.filterListener.pipe(
            // make sure we wait for culturalIndexUiService to be initialised before updating the chart
            // else culturalIndexUiService.valueStates can be undefined
            (source) => defer(() => this.culturalIndexUiService.waitUntilInitialised().pipe(
                switchMap(() => source),
            )),
            this.takeUntilDestroyed(),
        ).subscribe(async (value) => {
            this.filterParameters = { ...value };
            await this.updateDataAndChart();
        });
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        const data: ICulturalIndexTimelineData = info.point.data;

        let html = "";
        html += "<b>Date: </b>" + moment(data.date).format(DateFormats.moment.long);
        html += "<br /><br /><b>" + this.culturalIndexUiService.valueStates[0].state + ": </b>" + data.category1Count;
        html += "<br /><b>" + this.culturalIndexUiService.valueStates[1].state + ": </b>" + data.category2Count;
        html += "<br /><b>" + this.culturalIndexUiService.valueStates[2].state + ": </b>" + data.category3Count;
        html += "<br /><b>" + this.culturalIndexUiService.valueStates[3].state + ": </b>" + data.category4Count;

        return { html };
    }

    @Autobind
    public onInitialized(e: InitializedEvent) {
        this.chart = e.component;

        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));

        this.setSeries();
    }

    @Autobind
    private async updateDataAndChart() {
        if (!ChartUtils.isChartDisposed(this.chart)) {
            this.chart?.showLoadingIndicator();
        }

        const parameters: ICulturalLeadershipMeasurementAnalysisFilter = { ...this.filterParameters! };
        parameters.date = new Date();
        this.timelineData = await this.fetchTimelineData([], parameters, true);

        this.setSeries();

        this.isLoading = false;
    }

    @Autobind
    private async fetchTimelineData(timelineData: ICulturalIndexTimelineData[], parameters: ICulturalLeadershipMeasurementAnalysisFilter, prefetchAllData = false): Promise<ICulturalIndexTimelineData[]> {
        const entries = await this.culturalIndexService.promiseToGetCulturalIndexAnalysisData(parameters, prefetchAllData);

        const periodData: ICulturalIndexTimelineData = {
            date: parameters.date!,
            category1Count: 0,
            category2Count: 0,
            category3Count: 0,
            category4Count: 0,
        };

        for (const entry of entries) {
            if (!entry.culturalIndex) {
                continue;
            }

            if (entry.culturalIndex.extensions.isCategory1()) {
                periodData.category1Count++;
            } else if (entry.culturalIndex.extensions.isCategory2()) {
                periodData.category2Count++;
            } else if (entry.culturalIndex.extensions.isCategory3()) {
                periodData.category3Count++;
            } else {
                periodData.category4Count++;
            }
        }

        // if no category has any count, then there is no more data to process
        if (!periodData.category1Count && !periodData.category2Count && !periodData.category3Count && !periodData.category4Count) {
            return timelineData;
        }

        timelineData.push(periodData);

        parameters.date = moment(parameters.date)
            .add(-3, "months")
            .toDate();

        return await this.fetchTimelineData(timelineData, parameters, false);
    }

    @Autobind
    private setSeries() {
        this.series = [];

        if (!this.filterParameters || this.filterParameters!.category1) {
            this.series.push({
                valueField: "category1Count",
                name: this.culturalIndexUiService.valueStates[0].state,
                color: this.culturalIndexUiService.valueStates[0].fgColor,
            });
        }

        if (!this.filterParameters || this.filterParameters!.category2) {
            this.series.push({
                valueField: "category2Count",
                name: this.culturalIndexUiService.valueStates[1].state,
                color: this.culturalIndexUiService.valueStates[1].fgColor,
            });
        }

        if (!this.filterParameters || this.filterParameters!.category3) {
            this.series.push({
                valueField: "category3Count",
                name: this.culturalIndexUiService.valueStates[2].state,
                color: this.culturalIndexUiService.valueStates[2].fgColor,
            });
        }

        if (!this.filterParameters || this.filterParameters!.category4) {
            this.series.push({
                valueField: "category4Count",
                name: this.culturalIndexUiService.valueStates[3].state,
                color: this.culturalIndexUiService.valueStates[3].fgColor,
            });
        }
    }
}
