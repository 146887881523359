import { Component, Inject, ViewChild } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DiagramComponent } from "app/general/diagram/diagram.component/diagram.component";

@Component({
    selector: "adapt-edit-system-diagram-dialog",
    templateUrl: "./edit-system-diagram-dialog.component.html",
})
@PersistableDialog("EditSystemDiagramDialog")
export class EditSystemDiagramDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemComponent> {
    public readonly dialogName = "EditSystemDiagram";
    public entitiesToConfirm: IBreezeEntity[] = [];
    public title: string;
    protected readonly autoResolveData = this.systemComponent;

    @ViewChild(DiagramComponent) public diagram?: DiagramComponent;
    private diagramChanged = false;

    // just trying to make the diagram & width as big as possible allowing for the dialog border, header, footer, etc (this should give them as much available space as possible)
    public maxDiagramHeight = window.innerHeight - 270;
    public dialogWidth = window.innerWidth * 0.98;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public systemComponent: SystemComponent,
    ) {
        super();
        this.title = this.systemComponent.entityAspect.entityState.isAdded()
            ? "Add diagram"
            : "Edit diagram";

        this.entitiesToConfirm = [this.systemComponent, this.systemComponent.systemDiagram!];
    }

    @Autobind
    public saveAndClose() {
        this.diagram!.saveDiagram();
        return super.saveAndClose();
    }

    @Autobind
    public cancel() {
        return super.cancel();
    }

    public get isSaveDisabled() {
        return !this.hasUnsavedEntity
            || this.systemComponent.entityAspect.hasValidationErrors;
    }

    public get hasUnsavedEntity() {
        return this.diagramChanged || super.hasUnsavedEntity;
    }

    public onSystemComponentNameChange(name: string) {
        this.systemComponent.name = name;
        this.systemComponent.extensions.validateComponentName();
    }

    @Autobind
    public onDiagramChanged(isModified: boolean) {
        this.diagramChanged = isModified;
        this.diagram!.exportDiagramToEntity();
    }
}
