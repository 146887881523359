<div class="activity-feed-instance"
     *ngIf="activityElement"
     [ngClass]="{
        'activity-feed-child': !activityElement!.isTopLevelActivity,
        'activity-feed-instance-just-added': activityElement!.isNewActivity
     }">
    <div class="activity-container">
        <adapt-person-image *ngIf="activityElement!.isTopLevelActivity"
                            [person]="activityElement!.relatedPerson"></adapt-person-image>
        <div class="activity-content">
            <ng-container *ngIf="activityInstance; else activityGroupTemplate">
                <div class="activity-text"
                     [innerHTML]="activityInstance!.text"></div>
                <div *ngIf="activityInstance!.subtext"
                     class="activity-subtext"
                     [innerHTML]="activityInstance!.subtext"></div>
            </ng-container>
            <ng-template #activityGroupTemplate>
                <div>
                    {{activityElement!.relatedPerson?.firstName || 'Someone'}} has made some changes.
                </div>
            </ng-template>

            <div class="activity-date"
                 [adaptTooltip]="activityElement!.dateTime | adaptMoment:'h:mm:ss a'">
                <ng-container *ngIf="occurredLessThanAnHourAgo; else timeStampTemplate">
                    {{activityElement!.dateTime | timeAgo}}
                </ng-container>
                <ng-template #timeStampTemplate>
                    {{activityElement!.dateTime | adaptMoment:'h:mm a'}}
                </ng-template>
            </div>
        </div>
        <a *ngIf="launchPathObject"
           class="activity-href"
           [routerLink]="launchPathObject!.path"
           [queryParams]="launchPathObject!.queryParams"
           data-test="activity-launcher"
           adaptTooltip="Click to open the source of this activity">
            <i class="fal fa-external-link-alt"></i>
        </a>
    </div>
</div>
