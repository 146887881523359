<div class="intro">
    <p>
        Select an embedADAPT board where the role tasks will be exported to.
    </p>
</div>

<div class="mt-3">
    <div class="d-flex">
        <label class="align-self-center me-3">Destination Board</label>
        <adapt-select-board class="align-self-center flex-grow-1"
                            [boards]="board && [board]"
                            (boardsChange)="onBoardsChange($event)"
                            [required]="true"
                            [showEditableBoardsOnly]="true"
                            [showColouredIcon]="true"
                            [showTeamBoards]="true"
                            data-test="destination-board-selection"></adapt-select-board>
    </div>
</div>
