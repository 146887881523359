import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionRoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { KeyFunctionSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function-supplementary-data";
import { provideKeyFunctionPageRoute } from "@common/page-route-providers";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHideIfEmptyModule } from "@common/ux/hide-if-empty/hide-if-empty.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { EntityAutoUpdaterTemplateBuilder } from "@org-common/lib/entity-sync/entity-auto-updater-template-builder";
import { EntitySignalRHub } from "@org-common/lib/entity-sync/entity-signalr-hub.service";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { DxCheckBoxModule, DxListModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptOrganisationMapModule } from "../organisation-map/organisation-map.module";
import { AdaptProcessMapSharedModule } from "../process-map/process-map-shared.module";
import { AdaptRoleModule } from "../role/role.module";
import { AddKeyFunctionDialogComponent } from "./add-key-function-dialog/add-key-function-dialog.component";
import { DeleteKeyFunctionDialogComponent } from "./delete-key-function-dialog/delete-key-function-dialog.component";
import { EditKeyFunctionDetailsComponent } from "./edit-key-function-details/edit-key-function-details.component";
import { EditKeyFunctionDialogComponent } from "./edit-key-function-dialog/edit-key-function-dialog.component";
import { EditKeyFunctionRolesComponent } from "./edit-key-function-roles/edit-key-function-roles.component";
import { KeyFunctionPageComponent } from "./key-function-page/key-function-page.component";
import { keyFunctionPageRoute } from "./key-function-page/key-function-page.route";
import { AdaptKeyFunctionsSharedModule } from "./key-functions.shared.module";
import { ListKeyFunctionTeamsComponent } from "./list-key-function-teams/list-key-function-teams.component";

@NgModule({
    imports: [
        CommonModule,
        DxListModule,
        DxTabPanelModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonDialogModule,
        AdaptButtonModule,
        AdaptEntityValidatorModule,
        AdaptRoleModule,
        AdaptTeamsModule,
        AdaptKeyFunctionsCommonModule,
        AdaptDirectorySharedModule,
        AdaptProcessMapSharedModule,
        AdaptZoneMapModule,
        AdaptCallToActionModule,
        AdaptTeamDashboardSharedModule,
        AdaptHideIfEmptyModule,
        AdaptAuthorisationModule,
        AdaptSystemisationModule,
        AdaptLabellingModule,
        AdaptOrganisationMapModule,
        AdaptTeamDashboardSharedModule,
        AdaptCommonTeamsModule,
        AdaptKeyFunctionsSharedModule,
    ],
    exports: [
        ListKeyFunctionTeamsComponent,
    ],
    declarations: [
        KeyFunctionPageComponent,
        ListKeyFunctionTeamsComponent,
        EditKeyFunctionRolesComponent,
        EditKeyFunctionDialogComponent,
        EditKeyFunctionDetailsComponent,
        AddKeyFunctionDialogComponent,
        DeleteKeyFunctionDialogComponent,
    ],
    providers: [
        provideKeyFunctionPageRoute(keyFunctionPageRoute),
    ],

})
export class AdaptKeyFunctionsModule {
    public static readonly Routes = [
        ...keyFunctionPageRoute.routes,
    ];

    constructor() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(KeyFunctionBreezeModel)
                .withSummaryHtml((i) => `<strong>${i.name}</strong>`)
                .fetchingAddedEntityWhen(() => true)
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(KeyFunctionSupplementaryDataBreezeModel)
                .withSummaryHtml((i) => `Supplementary data for key function <strong>${i.keyFunction?.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(KeyFunctionRoleBreezeModel)
                .withSummaryHtml((i) => `Role <strong>${i.role?.label}</strong> for key function <strong>${i.keyFunction?.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }
}
