import { BreezeModelUtilities } from "../../lib/data/breeze-model-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Organisation } from "./organisation";

export class Api extends BaseEntity<Api> {
    public apiId!: number;
    public organisationId!: number;
    public token!: string;
    public personId!: number;
    public createdDateTime!: Date;
    public lastUsedDateTime?: Date;

    public organisation!: Organisation;
    public person!: Person;
}

export const ApiBreezeModel = new BreezeModelBuilder("Api", Api)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .withPropertyValidator("personId", BreezeModelUtilities.EntityIdValidator)
    .build();
