import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-team-hierarchy-dashboard-element",
    templateUrl: "./team-hierarchy-dashboard-element.component.html",
    styleUrls: ["./team-hierarchy-dashboard-element.component.scss"],
})
export class TeamHierarchyDashboardElementComponent {
    @Input()
    public set team(value: Team) {
        if (value) {
            this.team$.next(value);
        }
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);

    public childTeams$: Observable<Team[]>;

    constructor(teamsService: CommonTeamsService) {
        this.childTeams$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => teamsService.getActiveChildTeams(team)),
            map((teams) => teams.sort(SortUtilities.getSortByFieldFunction("name"))),
        );
    }
}
