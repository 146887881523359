import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture-framework.service";

@Component({
    selector: "adapt-edit-role-connections",
    templateUrl: "./edit-role-connections.component.html",
    styleUrls: ["./edit-role-connections.component.scss"],
})
export class EditRoleConnectionsComponent extends BaseComponent implements OnInit {
    @Output() public roleConnectionCreated = new EventEmitter<RoleConnection>();
    @Output() public roleConnectionChanged = new EventEmitter<RoleConnection>();
    @Output() public roleConnectionDeletion = new EventEmitter<RoleConnection>();

    @Input() public role?: Role;
    @Input() public connection?: Connection;

    public hasIncomplete = false;
    public hasHistoric = false;
    public showHistoric = false;
    public model?: IBreezeModel;
    public team?: Team;
    public entity?: Connection | Role;

    constructor(
        private commonDataService: CommonDataService,
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
    ) {
        super();
    }

    public ngOnInit() {
        this.entity = this.connection || this.role;

        this.setAdditionalSettings();
    }

    private setAdditionalSettings() {
        this.model = this.connection
            ? RoleBreezeModel
            : PersonBreezeModel;

        this.hasIncomplete = false;
        this.hasHistoric = false;
        this.showHistoric = false;

        this.updateSettingsFromRoleConnections();

        if (!this.entity!.roleConnections.some((rc) => this.isIncluded(rc))) {
            this.promiseToAddRoleConnection();
        } else if (!this.entity!.roleConnections.some((rc) => this.isIncludedAndNotHistoric(rc))) {
            // no current role but there are some historic ones - show the historic one instead
            this.showHistoric = true;
        }
    }

    private updateSettingsFromRoleConnections() {
        this.hasIncomplete = this.entity!.roleConnections.some((i: RoleConnection) => this.isIncludedAndIncomplete(i));
        this.hasHistoric = this.entity!.roleConnections.some((i: RoleConnection) => this.isIncludedAndHistoric(i));
    }

    public async onRoleConnectionDeletion(roleConnection: RoleConnection) {
        // perform deletion here instead of within edit-role-connection.component as that component will go away
        // causing unfinished emit chain based on timings
        await lastValueFrom(this.commonDataService.remove(roleConnection));
        this.roleConnectionDeletion.emit(roleConnection);
        this.updateSettingsFromRoleConnections();
    }

    public onRoleConnectionChanged(roleConnection: RoleConnection) {
        if (roleConnection && roleConnection.endDate && moment.utc().isAfter(roleConnection.endDate)) {
            // most probably from just ending a role -> should expand the previous roles to indicate
            // where that has gone to
            this.showHistoric = true;
        }

        this.updateSettingsFromRoleConnections();
        this.roleConnectionChanged.emit(roleConnection);
    }

    public isIncludedAndNotHistoric(roleConnection: RoleConnection) {
        return this.filterTeam(roleConnection)
            && !this.isSystemRoleConnection(roleConnection)
            && roleConnection.isNotHistoric();
    }

    public isIncludedAndIncomplete(roleConnection: RoleConnection) {
        return this.filterTeam(roleConnection)
            && !this.isSystemRoleConnection(roleConnection)
            && (ObjectUtilities.isNullOrUndefined(roleConnection.role)
                || ObjectUtilities.isNullOrUndefined(roleConnection.connection));

    }

    public isIncludedAndHistoric(roleConnection: RoleConnection) {
        return this.filterTeam(roleConnection)
            && !this.isSystemRoleConnection(roleConnection)
            && roleConnection.isHistoric();
    }

    public isIncluded(roleConnection: RoleConnection) {
        return this.filterTeam(roleConnection)
            && !this.isSystemRoleConnection(roleConnection);
    }

    private filterTeam(roleConnection: RoleConnection) {
        if (this.team) {
            return roleConnection.teamId === this.team.teamId;
        } else {
            return !roleConnection.teamId;
        }
    }

    private isSystemRoleConnection(roleConnection: RoleConnection) {
        return roleConnection.role
            && (roleConnection.role.extensions.isSystemAllocatedRole()
                || roleConnection.role.extensions.hasAccessPermissions());
    }

    public async promiseToAddRoleConnection() {
        const defaults: Partial<RoleConnection> = {
            role: this.role,
            connection: this.connection,
            team: this.team,
            startDate: this.connection?.startDate ?? new Date(),
        };

        const roleConnection = await lastValueFrom(this.integratedArchitectureFrameworkService.createRoleConnection(defaults));

        this.hasIncomplete = true;
        this.roleConnectionCreated.emit(roleConnection);
    }

    public toggleShowHistoric() {
        this.showHistoric = !this.showHistoric;
    }

    public get includedAndNotHistoric() {
        return (this.entity?.roleConnections as RoleConnection[])
            .filter((rc) => this.isIncludedAndNotHistoric(rc))
            .sort(SortUtilities.getSortByFieldFunction<RoleConnection>("startDate"));
    }

    public get includedAndHistoric() {
        return (this.entity?.roleConnections as RoleConnection[])
            .filter((rc) => this.isIncludedAndHistoric(rc))
            .sort(SortUtilities.getSortByFieldFunction<RoleConnection>("endDate"))
            .sort(SortUtilities.getSortByFieldFunction<RoleConnection>("startDate"));
    }
}
