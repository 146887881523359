import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { BaseComponent } from "@common/ux/base.component/base.component";

export interface IProcessStepStackFrame {
    step: ProcessMap | ProcessStep;
    stackFrame: number;
}

@Component({
    selector: "adapt-display-process-step-hierarchy",
    templateUrl: "./display-process-step-hierarchy.component.html",
    styleUrls: ["./display-process-step-hierarchy.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayProcessStepHierarchyComponent extends BaseComponent {
    @Input() public processStep?: ProcessStep | ProcessMap;
    @Input() public processStepStackFrames?: IProcessStepStackFrame[];
    @Output() public processStepClick = new EventEmitter<IProcessStepStackFrame>();
}
