import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemValue } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";

@Component({
    selector: "adapt-select-profile-item-value",
    templateUrl: "./select-profile-item-value.component.html",
})
export class SelectProfileItemValueComponent {
    @Input() public set profileItem(item: PersonProfileItem | undefined) {
        this._profileItem = item;
        this.onProfileItemChanged(item);
    }

    public get profileItem() {
        return this._profileItem;
    }

    @Input() public value?: PersonProfileItemValue;
    @Output() public valueChange = new EventEmitter<PersonProfileItemValue | undefined>();

    public profileItemValues: PersonProfileItemValue[] = [];
    private _profileItem?: PersonProfileItem;

    public constructor(
        private directorySharedService: DirectorySharedService,
    ) { }

    public getDisplayName(item?: PersonProfileItemValue) {
        return item && (item.text || "<blank>");
    }

    @Autobind
    private async onProfileItemChanged(item?: PersonProfileItem) {
        this.profileItemValues = [];
        this.value = undefined;
        this.valueChange.emit(undefined);

        if (item) {
            // reduce to unique item values
            const values: PersonProfileItemValue[] = await this.directorySharedService.promiseToGetPersonProfileItemValuesByItemId(item.personProfileItemId);
            this.profileItemValues = values
                .reduce((items, val) => !items.find((v) => v.text === val.text) ? [...items, val] : items, this.profileItemValues)
                .sort((a, b) => a.text!.localeCompare(b.text!));
        }
    }
}
