"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function unionCount(a, b) {
  var u = {};
  for (var i in a) u[i] = {};
  for (var i in b) u[i] = {};
  return Object.keys(u).length;
}
function intersectionCount(a, b) {
  var n = 0;
  for (var i in a) if (typeof b[i] !== 'undefined') ++n;
  return n;
}
function getNeighbours(links, la) {
  var neighbours = {};
  var addNeighbours = function (u, v) {
    if (typeof neighbours[u] === 'undefined') neighbours[u] = {};
    neighbours[u][v] = {};
  };
  links.forEach(function (e) {
    var u = la.getSourceIndex(e),
      v = la.getTargetIndex(e);
    addNeighbours(u, v);
    addNeighbours(v, u);
  });
  return neighbours;
}
function computeLinkLengths(links, w, f, la) {
  var neighbours = getNeighbours(links, la);
  links.forEach(function (l) {
    var a = neighbours[la.getSourceIndex(l)];
    var b = neighbours[la.getTargetIndex(l)];
    la.setLength(l, 1 + w * f(a, b));
  });
}
function symmetricDiffLinkLengths(links, la, w) {
  if (w === void 0) {
    w = 1;
  }
  computeLinkLengths(links, w, function (a, b) {
    return Math.sqrt(unionCount(a, b) - intersectionCount(a, b));
  }, la);
}
exports.symmetricDiffLinkLengths = symmetricDiffLinkLengths;
function jaccardLinkLengths(links, la, w) {
  if (w === void 0) {
    w = 1;
  }
  computeLinkLengths(links, w, function (a, b) {
    return Math.min(Object.keys(a).length, Object.keys(b).length) < 1.1 ? 0 : intersectionCount(a, b) / unionCount(a, b);
  }, la);
}
exports.jaccardLinkLengths = jaccardLinkLengths;
function generateDirectedEdgeConstraints(n, links, axis, la) {
  var components = stronglyConnectedComponents(n, links, la);
  var nodes = {};
  components.forEach(function (c, i) {
    return c.forEach(function (v) {
      return nodes[v] = i;
    });
  });
  var constraints = [];
  links.forEach(function (l) {
    var ui = la.getSourceIndex(l),
      vi = la.getTargetIndex(l),
      u = nodes[ui],
      v = nodes[vi];
    if (u !== v) {
      constraints.push({
        axis: axis,
        left: ui,
        right: vi,
        gap: la.getMinSeparation(l)
      });
    }
  });
  return constraints;
}
exports.generateDirectedEdgeConstraints = generateDirectedEdgeConstraints;
function stronglyConnectedComponents(numVertices, edges, la) {
  var nodes = [];
  var index = 0;
  var stack = [];
  var components = [];
  function strongConnect(v) {
    v.index = v.lowlink = index++;
    stack.push(v);
    v.onStack = true;
    for (var _i = 0, _a = v.out; _i < _a.length; _i++) {
      var w = _a[_i];
      if (typeof w.index === 'undefined') {
        strongConnect(w);
        v.lowlink = Math.min(v.lowlink, w.lowlink);
      } else if (w.onStack) {
        v.lowlink = Math.min(v.lowlink, w.index);
      }
    }
    if (v.lowlink === v.index) {
      var component = [];
      while (stack.length) {
        w = stack.pop();
        w.onStack = false;
        component.push(w);
        if (w === v) break;
      }
      components.push(component.map(function (v) {
        return v.id;
      }));
    }
  }
  for (var i = 0; i < numVertices; i++) {
    nodes.push({
      id: i,
      out: []
    });
  }
  for (var _i = 0, edges_1 = edges; _i < edges_1.length; _i++) {
    var e = edges_1[_i];
    var v_1 = nodes[la.getSourceIndex(e)],
      w = nodes[la.getTargetIndex(e)];
    v_1.out.push(w);
  }
  for (var _a = 0, nodes_1 = nodes; _a < nodes_1.length; _a++) {
    var v = nodes_1[_a];
    if (typeof v.index === 'undefined') strongConnect(v);
  }
  return components;
}
exports.stronglyConnectedComponents = stronglyConnectedComponents;
