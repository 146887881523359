import { CareerValuation } from "./career-valuation";
import { ICulturalMeasurementEntityExtensions } from "./cultural-measurement-entity-extensions.interface";

export class CareerValuationExtensions implements ICulturalMeasurementEntityExtensions {
    public isEditorValid = true;
    public constructor(private careerValuation: CareerValuation) { }

    public get actualTotal() {
        let totalActual = 0;

        for (const cv of this.careerValuation.categoryValues) {
            totalActual += cv.actualValue;
        }

        return totalActual;
    }

    public get idealTotal() {
        let total = 0;

        for (const categoryValue of this.careerValuation.categoryValues) {
            total += categoryValue.idealValue;
        }

        return total;
    }

    public isValid() {
        return this.actualTotal > 0 && this.idealTotal === 100 && this.isEditorValid;
    }

    public isCategory1() {
        return this.actualTotal > 75;
    }

    public isCategory2() {
        const actualTotal = this.actualTotal;
        return actualTotal > 60 && actualTotal <= 75;
    }

    public isCategory3() {
        const actualTotal = this.actualTotal;
        return actualTotal > 50 && actualTotal <= 60;
    }

    public isCategory4() {
        return this.actualTotal <= 50;
    }
}
