import { Component, OnInit } from "@angular/core";
import { RouteService } from "@common/route/route.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { culturalLeadershipFrameworkPageRoute } from "app/features/culture/cultural-leadership/clf-page/clf-page.route";
import { from, Observable } from "rxjs";
import { analyseCareerValuationPageRoute } from "../analyse-cvt-page/analyse-cvt-page.route";
import { CareerValuationService } from "../career-valuation.service";

@Component({
    selector: "adapt-cvt-organisation-dashboard-element",
    templateUrl: "./cvt-organisation-dashboard-element.component.html",
})
export class CareerValuationOrganisationDashboardElementComponent implements OnInit {
    public url$: Observable<string>;
    public clfHref$: Observable<string>;
    public canViewClf$: Observable<boolean>;
    public hasData = false;
    public averageCareerValuation = -1;

    public constructor(
        private careerValuationService: CareerValuationService,
        authorisationService: AuthorisationService,
        routeService: RouteService,
    ) {
        this.url$ = analyseCareerValuationPageRoute.getRoute();
        this.clfHref$ = from(routeService.getControllerRoute(culturalLeadershipFrameworkPageRoute.id));
        this.canViewClf$ = authorisationService.getHasAccess(CulturalLeadershipFrameworkAuthService.ReadCohorts);
    }

    public async ngOnInit() {
        const data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData();
        const stats = this.careerValuationService.generateCareerValuationStatistics(data);
        this.hasData = (stats.totalPeople - stats.missingCareerValuations.length) > 0;
        this.averageCareerValuation = stats.averageCareerValuation;
    }
}
