import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepType } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { BehaviorSubject, merge, Observable } from "rxjs";
import { filter, first, map, switchMap, take, tap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";
import { ProcessMapUiService } from "../process-map-ui.service";

const stepTypeClass: { [type in ProcessStepType]: string } = {
    [ProcessStepType.ProcessStep]: "step-type-process-step",
    [ProcessStepType.RoleTask]: "step-type-role-task",
    [ProcessStepType.ProcessMapLink]: "step-type-process-map-link",
};

@Component({
    selector: "adapt-process-step-card",
    templateUrl: "./process-step-card.component.html",
    styleUrls: ["./process-step-card.component.scss"],
})
export class ProcessStepCardComponent extends BaseComponent {
    @Input() public set processStep(value: ProcessStep | undefined) {
        this.processStep$.next(value);
    }
    @Input() public isEditing = false;
    @Output() public stepClick = new EventEmitter<ProcessStep>();

    public processStep$ = new BehaviorSubject<ProcessStep | undefined>(undefined);
    public processStepLinkClass$: Observable<string>;
    public isLink$: Observable<boolean>;

    public editMenuItem: IAdaptMenuItem = {
        icon: "fal fa-fw fa-edit",
        text: "Edit",
        onClick: () => this.onEditClicked().subscribe(),
    };
    public editMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [this.editMenuItem, {
            icon: "fal fa-fw fa-copy",
            text: "Copy to another task map",
            onClick: () => this.copy(),
            separator: true,
        }, {
            icon: "fal fa-fw fa-arrows-alt",
            text: "Move to another task map",
            onClick: () => this.move(),
        }, {
            icon: "fal fa-fw fa-trash-alt",
            text: "Delete",
            onClick: () => this.delete(),
            separator: true,
        }],
    }];

    public constructor(
        private processMapUiService: ProcessMapUiService,
        private processMapService: ProcessMapService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.processStepLinkClass$ = this.processStep$.pipe(
            map((step) => step ? stepTypeClass[step.type] : ""),
        );

        this.isLink$ = this.processStep$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((i) => rxjsBreezeService.entityPropertyChangedWithInitialValue(i, "type")),
            map((t) => t === ProcessStepType.ProcessMapLink),
        );

        this.processStep$.pipe(
            emptyIfUndefinedOrNull(),
            // prime child steps - e.g. needed after copying
            switchMap((processStep) => this.processMapService.getChildSteps(processStep).pipe(map(() => processStep))),
            this.takeUntilDestroyed(),
        ).subscribe((processStep) => {
            this.editMenuItem.text = processStep.extensions.isRoleTask
                ? "Edit role task"
                : "Edit process step";
        });

        merge(
            processMapService.stepCopiedOrMoved$.pipe(
                filter((systemComponent) => !!systemComponent.systemProcessMapId && systemComponent.systemProcessMapId === this.processStep$.value?.processMapId),
                // the child steps won't be fetched due to the encompassingKey for the process map, so clear the cache for the target process map.
                tap((systemComponent) => processMapService.clearChildStepsCacheForProcessMap(systemComponent.systemProcessMapId!)),
                map(() => this.processStep$.value),
            ),
            rxjsBreezeService.entityTypeChanged(ProcessStep).pipe(
                filter((ps) => ps.processStepId === this.processStep$.value?.processStepId),
            ),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(this.processStep$);
    }

    public onEditClicked() {
        return this.processStep$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(ProcessStep)),
            first(),
            switchMap((processStep) => this.processMapUiService.editProcessStep(processStep)),
        );
    }

    public copy() {
        this.processStep$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(ProcessStep)),
            first(),
            switchMap((processStep) => this.processMapUiService.copyProcessStep(processStep)),
        ).subscribe((systemComponent) =>
            setTimeout(() => this.processMapService.emitComponentForStepCopiedOrMoved(systemComponent)));
    }

    public move() {
        this.processStep$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(ProcessStep)),
            first(),
            switchMap((processStep) => this.processMapUiService.moveProcessStep(processStep)),
        ).subscribe((systemComponent) =>
            // note that this emit has to go through process map service as the process-step-card instance will be destroyed
            // during a move and any subscription to @Output of this instance will be gone. Can't get event through the
            // dom chain anymore - might as well use this for both copy and move
            setTimeout(() => this.processMapService.emitComponentForStepCopiedOrMoved(systemComponent)));
    }

    public delete() {
        this.processStep$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(ProcessStep)),
            take(1),
            switchMap((processStep) => this.processMapUiService.deleteProcessStep(processStep)),
        ).subscribe();
    }
}
