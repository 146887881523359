<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Created API token</h3>
    <div adapt-dialog-content>
        <p>You have created an API token for the following user: {{newApi.person.fullName}}.</p>

        <h3>Your API token is:</h3>
        <div class="d-flex form-group">
            <dx-text-box #tokenInput
                         class="flex-grow-1"
                         [value]="newApi.token"
                         [readOnly]="true"></dx-text-box>
            <button type="button"
                    class="ms-2 btn border border-primary"
                    [ngClass]="{'btn-outline-primary': copiedToken, 'btn-primary': !copiedToken}"
                    [adaptTooltip]="copiedToken ? 'Copied' : 'Copy to clipboard'"
                    [adaptButtonIcon]="'fal fa-fw ' + (copiedToken ? 'fa-check' : 'fa-copy')"
                    (click)="copyToClipboard(tokenInput.value)"></button>
        </div>

        <p>
            <b>This will not be shown again for security reasons, so make sure to save it somewhere you can access it.</b>
            A token for this user can be recreated at any time, for example if you misplace it.
        </p>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                class="btn btn-default"
                (click)="resolve(newApi)">OK
        </button>
    </div>
</adapt-dialog>
