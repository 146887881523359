import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DisplayGuidanceMaterialComponent } from "./display-guidance-material/display-guidance-material.component";
import { RoleActionsComponent } from "./role-actions/role-actions.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptTooltipModule,
        AdaptMenuModule,
        AdaptEditorViewModule,
    ],
    exports: [
        DisplayGuidanceMaterialComponent,
        RoleActionsComponent,
    ],
    declarations: [
        DisplayGuidanceMaterialComponent,
        RoleActionsComponent,
    ],
})
export class AdaptIntegratedArchitectureSharedModule { }
