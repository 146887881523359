import { Component, Input } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { merge, Observable, ReplaySubject } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { TeamsService } from "../teams.service";

@Component({
    selector: "adapt-list-team-locations",
    templateUrl: "./list-team-locations.component.html",
})
export class ListTeamLocationsComponent {
    @Input()
    public set team(value: Team | undefined) {
        if (value) {
            this.team$.next(value);
        }
    }

    public locations$: Observable<TeamLocation[]>;
    public zones$: Observable<Zone[]>;
    public keyFunctions$: Observable<KeyFunction[]>;

    private team$ = new ReplaySubject<Team>(1);

    public constructor(
        commonTeamsService: CommonTeamsService,
        teamsService: TeamsService,
        private rxjsBreezeService: RxjsBreezeService,
        labellingService: LabellingService,
    ) {
        this.locations$ = this.team$.pipe(
            switchMap(this.teamLocationsShouldBeUpdated),
            // make sure all teams are primed so nested parents should be available
            switchMap(async (team) => {
                try {
                    return team.rootTeam;
                }
                catch {
                    await commonTeamsService.promiseToGetAllTeams();
                    return team.rootTeam;
                }
            }),
            switchMap((team) => teamsService.getTeamLocations(team)),
        );

        this.zones$ = this.locations$.pipe(
            map((loc) => loc.map((l) => TeamLocation.getAsZoneLocation(l)!)
                .filter((zone) => !!zone)),
        );

        this.keyFunctions$ = this.locations$.pipe(
            map((loc) => loc.map((l) => TeamLocation.getAsKeyFunctionLocation(l)!)
                .filter((kf) => !!kf)),
            // prime key function label locations
            switchMap((keyFunctions) => labellingService.primeLabelLocationsForKeyFunctions(keyFunctions).pipe(
                map(() => keyFunctions),
            )),
        );
    }

    @Autobind
    private teamLocationsShouldBeUpdated(team: Team) {
        return merge(
            this.rxjsBreezeService.entityTypeChanged(TeamLocation),
            this.rxjsBreezeService.entityTypeChanged(Team),
        ).pipe(
            startWith(team),
            filter((ent) => [
                ent?.teamId,
                ent instanceof Team ? ent.parentTeamId : undefined,
                team.teamId,
                team.parentTeamId,
            ].includes(team.teamId)),
            map(() => team),
        );
    }
}
