import { Component, Input, OnChanges } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-link-process-map",
    templateUrl: "./link-process-map.component.html",
    styleUrls: ["./link-process-map.component.scss"],
})
export class LinkProcessMapComponent implements OnChanges {
    @Input() public processMap?: ProcessMap;

    public icon = ProcessMap.IconClass;
    public href$: Observable<string | null> = of(null);

    public constructor(
        private systemisationService: SystemisationService,
    ) { }

    public ngOnChanges() {
        // cannot navigate to newly added / unsaved task map as it does not exist
        this.href$ = this.processMap && !this.processMap.entityAspect.entityState.isAdded()
            ? this.systemisationService.getSystemComponentForProcessMap(this.processMap.processMapId).pipe(
                switchMap((systemComponent) => systemPageRoute.getRoute({ systemEntityId: systemComponent.systemEntityId })))
            : of(null);
    }
}
