import { Injectable, Injector } from "@angular/core";
import { CulturalIndex, CulturalIndexBreezeModel, CulturalIndexDirection } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { CulturalIndexConfiguration } from "@common/ADAPT.Common.Model/organisation/cultural-index-configuration";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartCustomizePoint, IDxGridColumnCellInfo, IDxGridColumnTemplateCellInfo } from "@common/ux/dx.types";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AfterOrganisationInitialisation } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { dxElement } from "devextreme/core/element";
import { dxChartSeriesTypesCommonSeriesPoint } from "devextreme/viz/chart";
import { BaseChart } from "devextreme/viz/chart_components/base_chart";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { CulturalIndexService } from "./cultural-index.service";
import { CulturalIndexAuthService } from "./cultural-index-auth.service";
import { EditCulturalIndexDialogComponent } from "./edit-ci-dialog/edit-ci-dialog.component";

export interface ICulturalIndexValueState {
    lower: number;
    upper: number;
    state: string;
    bgColor: string;
    fgColor: string;
    textColor: string;
    class: string;
    name: string;
}

export interface ICulturalIndexChartData {
    value: number;
    creationDate: Date;
    culturalIndex: CulturalIndex;
    min: number;
    max: number;
    index: number;
}

@Injectable({
    providedIn: "root",
})
@Autobind
export class CulturalIndexUiService extends BaseOrganisationService {
    private culturalLeadershipConfiguration?: CulturalLeadership;
    private culturalIndexConfiguration?: CulturalIndexConfiguration;
    public valueStates: ICulturalIndexValueState[] = [];

    public constructor(
        injector: Injector,
        private userService: UserService,
        private authorisationService: AuthorisationService,
        private commonDialogService: AdaptCommonDialogService,
        private culturalIndexService: CulturalIndexService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
    ) {
        super(injector);

        // set up valueStates using the default values (this must be called so that the array is filled and calling code will not fall over referencing an empty array)
        this.initialiseValueStates();
    }

    protected organisationInitialisationActions() {
        return [this.requiredActions()];
    }

    public async requiredActions() {
        try {
            await this.authorisationService.promiseToVerifyAccess(CulturalIndexAuthService.ReadAnyCulturalIndex);
            this.culturalLeadershipConfiguration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
            this.culturalIndexConfiguration = await this.culturalIndexService.promiseToGetCulturalIndexConfiguration();

            // set up valueStates based on the configuration just fetched
            this.initialiseValueStates();
        } catch {
            // swallow the exception - this can happen for people with no permissions
        }
    }

    // TODO: Remove when DX allows access to complex properties for dxChart T199005
    public createCulturalIndexChartObject(culturalIndex: CulturalIndex, arrayIndex: number) {
        const chartObject: ICulturalIndexChartData = {
            value: culturalIndex.value,
            creationDate: culturalIndex.creationDate,
            culturalIndex,
            max: 2,
            min: 0,

            // we need a normalised argument to avoid groupings of data in the polarChart
            index: arrayIndex,
        };

        return chartObject;
    }

    public getChartDataTooltip(culturalIndex: CulturalIndex, showName: boolean) {
        let result = "";

        if (showName && culturalIndex.person) {
            result += "<h3>" + culturalIndex.person.fullName + "</h3>";
        }

        result += "<b>Cultural Index: </b>" + culturalIndex.value;

        result += "<br /><b>Direction: </b>";
        switch (culturalIndex.direction) {
            case CulturalIndexDirection.Outwards:
                result += "Outward";
                break;
            case CulturalIndexDirection.Stable:
                result += "Stable";
                break;
            case CulturalIndexDirection.Inwards:
                result += "Inward";
                break;
        }

        result += "<br />";

        if (!DateUtilities.isOutOfRange(culturalIndex.creationDate)) {
            result += "<br /><b>Recorded on: </b>" + moment(culturalIndex.creationDate).format(DateFormats.moment.long);
        }

        if (culturalIndex.author) {
            result += "<br /><b>Recorded by: </b>" + culturalIndex.author.fullName;
        }

        if (culturalIndex.person && culturalIndex.person.culturalLeaderRelationships.length) {
            const relationships = culturalIndex.person.culturalLeaderRelationships.filter((relationship: CulturalRelationship) => relationship.isActiveAt(culturalIndex.creationDate));
            if (relationships.length && this.culturalLeadershipConfiguration) {
                if (relationships.length === 1) {
                    result += "<br /><b>" + this.culturalLeadershipConfiguration.primaryName + ": </b>";
                } else {
                    result += "<br /><b>" + this.culturalLeadershipConfiguration.primaryCollectionName + ": </b>";
                }

                for (const relationship of relationships) {
                    if (relationships.indexOf(relationship) > 0 || relationships.length > 1) {
                        result += "<br />";
                    }

                    result += relationship.culturalLeader.fullName;
                }
            }
        }

        return result;
    }

    @AfterOrganisationInitialisation
    public async promiseToSetChartStrips(chart: BaseChart<any>) { // purposely leave async here even with await to get the function to return the right type
        const strips = this.valueStates.map((state) => ({
            startValue: state.lower,
            endValue: state.upper,
            color: "#EAEAEA",
            label: {
                font: {
                    size: 20,
                    color: "#565757",
                },
                text: state.state,
            },
        }));

        chart.option("valueAxis.strips", strips);
    }

    public customiseValueCellText(cell: IDxGridColumnCellInfo) {
        const states = this.valueStates.filter((state) => (cell.value! as number) >= state.lower && (cell.value! as number) <= state.upper);

        if (!states || !states[0]) {
            return "";
        }

        return cell.valueText + " (" + states[0].state + ")";
    }

    // CM-5081 DX 21.1 has screwed up dxElement, remove the prefix once they fix it
    public getValueCellTemplate(element: dxElement, info: IDxGridColumnTemplateCellInfo) {
        const states = this.valueStates.filter((state) => info.value >= state.lower && info.value <= state.upper);

        if (!states || !states[0]) {
            return;
        }

        element.addClass(states[0].class);
        element.html(info.text);
    }

    public getValueHeaderOptions() {
        const result = this.valueStates.map((state) => ({
            text: state.state + " (" + state.lower + " - " + state.upper + ")",
            value: [["culturalIndex.value", ">", state.lower], "and", ["culturalIndex.value", "<=", state.upper]] as string | any[] | null,
        }));

        result.unshift({
            text: "(Blanks)",
            value: ["culturalIndex.value", "=", null],
        });

        return result;
    }

    public customiseChartPoint(info: IDxChartCustomizePoint): dxChartSeriesTypesCommonSeriesPoint {
        if (!info.tag) {
            return {};
        }

        for (const valueState of this.valueStates) {
            if (info.tag.value >= valueState.lower
                && info.tag.value <= valueState.upper) {
                return {
                    color: valueState.fgColor,
                };
            }
        }

        return {};
    }

    public async promiseToRecordCulturalIndex(person: Person) {
        const currentPerson = await this.userService.getCurrentPerson();
        const latestCulturalIndex = await this.culturalIndexService.promiseToGetLatestCulturalIndexForPerson(person.personId);

        const culturalIndex = await this.promiseToCreateCulturalIndex(person, currentPerson, latestCulturalIndex);
        this.commonDialogService.open(EditCulturalIndexDialogComponent, culturalIndex).subscribe();
    }

    private promiseToCreateCulturalIndex(person: Person, author?: Person, latestCulturalIndex?: CulturalIndex) {
        const defaults: any = {
            authorId: author?.personId,
            personId: person.personId,
            creationDate: moment.utc(),
        };
        if (this.culturalLeadershipConfiguration) {
            defaults.organisationId = this.culturalLeadershipConfiguration.organisationId;
        }

        if (latestCulturalIndex) {
            defaults.value = latestCulturalIndex.value;
            defaults.direction = latestCulturalIndex.direction;
        } else {
            defaults.value = 1;
            defaults.direction = CulturalIndexDirection.Stable;
        }

        return lastValueFrom(this.commonDataService.create(CulturalIndexBreezeModel, defaults));
    }

    /**
     * Deletes a cultural index.
     *
     * @param {CulturalIndex} culturalIndex - The cultural index to delete
     * @param {Boolean} confirm - Whether to prompt for confirmation
     * @param {Boolean} save - Whether to save immediately
     * @returns {Promise} A promise to delete the cultural index
     */
    public async promiseToDeleteCulturalIndex(culturalIndex: CulturalIndex, confirm: boolean, save: boolean) {
        if (confirm) {
            const data = {
                title: "Delete cultural index",
                message: "Are you sure you wish to delete this cultural index?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            };

            const remove = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(data));
            if (remove) {
                await this.removeItem(culturalIndex, save);
            }
        } else {
            await this.removeItem(culturalIndex, save);
        }
    }

    private async removeItem(culturalIndex: CulturalIndex, save: boolean) {
        await lastValueFrom(this.commonDataService.remove(culturalIndex));

        return save
            ? lastValueFrom(this.commonDataService.save())
            : Promise.resolve();

    }

    private initialiseValueStates() {
        this.valueStates = [
            {
                lower: 0,
                upper: 0.5,
                state: this.culturalIndexConfiguration
                    ? this.culturalIndexConfiguration.category1Label
                    : "Very happy",
                bgColor: "rgba(153, 180, 86, 1)",
                fgColor: "rgba(153, 180, 86, 1)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-very-happy",
                name: "category1",
            },
            {
                lower: 0.5,
                upper: 1,
                state: this.culturalIndexConfiguration
                    ? this.culturalIndexConfiguration.category2Label
                    : "Happy",
                bgColor: "rgba(19, 149, 186, 1)",
                fgColor: "rgba(19, 149, 186, 1)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-happy",
                name: "category2",
            },
            {
                lower: 1,
                upper: 1.5,
                state: this.culturalIndexConfiguration
                    ? this.culturalIndexConfiguration.category3Label
                    : "Unhappy",
                bgColor: "rgba(239, 139, 44, 1)",
                fgColor: "rgba(239, 139, 44, 1)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-unhappy",
                name: "category3",
            },
            {
                lower: 1.5,
                upper: 2,
                state: this.culturalIndexConfiguration
                    ? this.culturalIndexConfiguration.category4Label
                    : "Very unhappy",
                bgColor: "rgba(192, 46, 29, 1)",
                fgColor: "rgba(192, 46, 29, 1)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-very-unhappy",
                name: "category4",
            },
        ];
    }
}
