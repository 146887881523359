<adapt-feature-guidance [configItem]="configItem">
    <p>The Cultural Index forms part of the adapt culture framework, and it is attempting to measure connectedness of an employee to your
        organisation.</p>
    <p>Enabling this feature will allow you to record Cultural Index measurements for employees in your organisation.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div *ngIf="configuration">
        <h3>Set the labels used for each of the four cultural index categories.</h3>
        <div class="row">
            <div class="col-md-4">
                <!-- Category 1 -->
                <div class="form-group">
                    <label for="category1Label">Category 1</label>
                    <dx-text-box id="category1Label"
                                 [adaptValidateEntity]="configuration"
                                 adaptValidateEntityProperty="category1Label"
                                 [(value)]="configuration!.category1Label"
                                 data-test="cultural-category-one"></dx-text-box>
                    <small class="form-text text-muted">Examples: Very happy, Highly connected.</small>
                </div>
                <!-- Category 2 -->
                <div class="form-group">
                    <label for="category2Label">Category 2</label>
                    <dx-text-box id="category2Label"
                                 [adaptValidateEntity]="configuration"
                                 adaptValidateEntityProperty="category2Label"
                                 [(value)]="configuration!.category2Label"></dx-text-box>
                    <small class="form-text text-muted">Examples: Happy, Connected.</small>
                </div>
                <!-- Category 3 -->
                <div class="form-group">
                    <label for="category3Label">Category 3</label>
                    <dx-text-box id="category3Label"
                                 [adaptValidateEntity]="configuration"
                                 adaptValidateEntityProperty="category3Label"
                                 [(value)]="configuration!.category3Label"></dx-text-box>
                    <small class="form-text text-muted">Examples: Unhappy, Disconnected.</small>
                </div>
                <!-- Category 4 -->
                <div class="form-group">
                    <label for="category4Label">Category 4</label>
                    <dx-text-box id="category4Label"
                                 [adaptValidateEntity]="configuration"
                                 adaptValidateEntityProperty="category4Label"
                                 [(value)]="configuration!.category4Label"></dx-text-box>
                    <small class="form-text text-muted">Examples: Very unhappy, Highly disconnected.</small>
                </div>
            </div>
        </div>
    </div>
</adapt-feature-configuration>
