<div *adaptLoading="isBusy">
    <div class="chart-wrapper mb-3">
        <dx-chart *ngIf="careerValuationsChartData.length > 0"
                  [dataSource]="careerValuationsChartData"
                  [pathModified]="true"
                  [palette]="palette"
                  (onPointClick)="onPointClick($event)">
            <!-- Options -->
            <dxo-argument-axis type="discrete">
                <dxo-grid [opacity]="0.2"
                          [visible]="true"></dxo-grid>
                <dxo-minor-grid [visible]="false"></dxo-minor-grid>
                <dxo-label [visible]="false"></dxo-label>
                <dxi-strip *ngFor="let range of stakeholderRange"
                           [startValue]="range.start"
                           [endValue]="range.end"
                           color="#f2f2f2">
                    <dxo-label text="Recorded as Stakeholder">
                        <dxo-font color="gray"></dxo-font>
                    </dxo-label>
                </dxi-strip>
                <dxo-strip-style>
                    <dxo-label>
                        <dxo-font size="12"></dxo-font>
                    </dxo-label>
                </dxo-strip-style>
            </dxo-argument-axis>
            <dxo-value-axis visualRangeUpdateMode="keep"
                            [maxValueMargin]="0.001"
                            [tickInterval]="25"
                            [minorTickInterval]="12.5"
                            [visualRange]="[0, 100]">
                <dxo-minor-tick [visible]="true"></dxo-minor-tick>
                <dxo-label [visible]="true"></dxo-label>
            </dxo-value-axis>
            <dxo-tooltip [enabled]="true"
                         [customizeTooltip]="customiseTooltip"></dxo-tooltip>
            <dxo-legend [visible]="responsiveService.currentBreakpoint.isDesktopSize"></dxo-legend>
            <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>

            <!-- Series -->
            <dxi-series *ngFor="let category of categories; let i = index"
                        [name]="category.name"
                        type="stackedbar"
                        [valueField]="'cat' + i"
                        argumentField="creationDate"
                        tagField="careerValuation">
            </dxi-series>

            <dxi-series name="Overall"
                        type="line"
                        valueField="value"
                        argumentField="creationDate"
                        tagField="careerValuation"
                        [width]="0.5">
            </dxi-series>
        </dx-chart>
    </div>

    <dx-data-grid *ngIf="showGrid"
                  [dataSource]="careerValuations"
                  [wordWrapEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  [hoverStateEnabled]="true"
                  noDataText="No Career Valuations recorded"
                  [showRowLines]="true"
                  [showBorders]="true"
                  (onInitialized)="onGridInitialized($event)">
        <!-- Options -->
        <dxo-pager [showPageSizeSelector]="true"></dxo-pager>
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-export [enabled]="false"
                    fileName="Career Valuation history"></dxo-export>

        <!-- Columns -->
        <dxi-column caption="Date"
                    dataField="creationDate"
                    dataType="date"
                    [format]="shortDateFormat"
                    sortOrder="desc"
                    [sortIndex]="0"
                    [width]="100">
        </dxi-column>

        <dxi-column caption="Value"
                    dataField="extensions.actualTotal"
                    dataType="number"
                    [customizeText]="careerValuationUiService.customiseValueCellText"
                    [cellTemplate]="careerValuationUiService.getValueCellTemplate"
                    [width]="120"></dxi-column>


        <dxi-column caption="Notes"
                    dataType="string"
                    [encodeHtml]="false"
                    cellTemplate="richTextTemplate"
                    [calculateCellValue]="calculateCellValue"
                    [visible]="notesColumnVisible || allColumnsVisible">
        </dxi-column>

        <ng-container *ngFor="let category of categories; let i = index">
            <dxi-column [name]="category.name + ' Score'"
                        [caption]="category.name + ' Score'"
                        [dataField]="'categoryValues[' + i + '].extensions.score'"
                        dataType="string"
                        [visible]="allColumnsVisible"></dxi-column>

            <dxi-column [name]="category.name"
                        [caption]="category.name"
                        [dataField]="'categoryValues[' + i + '].notes'"
                        dataType="string"
                        [encodeHtml]="false"
                        cellTemplate="richTextTemplate"
                        [visible]="allColumnsVisible">
            </dxi-column>
        </ng-container>

        <!-- Templates -->
        <div *dxTemplate="let item of 'richTextTemplate'">
            <div [froalaView]="item.value"></div>
        </div>
    </dx-data-grid>
</div>
