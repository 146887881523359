"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var powergraph = require("./powergraph");
var linklengths_1 = require("./linklengths");
var descent_1 = require("./descent");
var rectangle_1 = require("./rectangle");
var shortestpaths_1 = require("./shortestpaths");
var geom_1 = require("./geom");
var handledisconnected_1 = require("./handledisconnected");
var EventType;
(function (EventType) {
  EventType[EventType["start"] = 0] = "start";
  EventType[EventType["tick"] = 1] = "tick";
  EventType[EventType["end"] = 2] = "end";
})(EventType = exports.EventType || (exports.EventType = {}));
;
function isGroup(g) {
  return typeof g.leaves !== 'undefined' || typeof g.groups !== 'undefined';
}
var Layout = function () {
  function Layout() {
    var _this = this;
    this._canvasSize = [1, 1];
    this._linkDistance = 20;
    this._defaultNodeSize = 10;
    this._linkLengthCalculator = null;
    this._linkType = null;
    this._avoidOverlaps = false;
    this._handleDisconnected = true;
    this._running = false;
    this._nodes = [];
    this._groups = [];
    this._rootGroup = null;
    this._links = [];
    this._constraints = [];
    this._distanceMatrix = null;
    this._descent = null;
    this._directedLinkConstraints = null;
    this._threshold = 0.01;
    this._visibilityGraph = null;
    this._groupCompactness = 1e-6;
    this.event = null;
    this.linkAccessor = {
      getSourceIndex: Layout.getSourceIndex,
      getTargetIndex: Layout.getTargetIndex,
      setLength: Layout.setLinkLength,
      getType: function (l) {
        return typeof _this._linkType === "function" ? _this._linkType(l) : 0;
      }
    };
  }
  Layout.prototype.on = function (e, listener) {
    if (!this.event) this.event = {};
    if (typeof e === 'string') {
      this.event[EventType[e]] = listener;
    } else {
      this.event[e] = listener;
    }
    return this;
  };
  Layout.prototype.trigger = function (e) {
    if (this.event && typeof this.event[e.type] !== 'undefined') {
      this.event[e.type](e);
    }
  };
  Layout.prototype.kick = function () {
    while (!this.tick());
  };
  Layout.prototype.tick = function () {
    if (this._alpha < this._threshold) {
      this._running = false;
      this.trigger({
        type: EventType.end,
        alpha: this._alpha = 0,
        stress: this._lastStress
      });
      return true;
    }
    var n = this._nodes.length,
      m = this._links.length;
    var o, i;
    this._descent.locks.clear();
    for (i = 0; i < n; ++i) {
      o = this._nodes[i];
      if (o.fixed) {
        if (typeof o.px === 'undefined' || typeof o.py === 'undefined') {
          o.px = o.x;
          o.py = o.y;
        }
        var p = [o.px, o.py];
        this._descent.locks.add(i, p);
      }
    }
    var s1 = this._descent.rungeKutta();
    if (s1 === 0) {
      this._alpha = 0;
    } else if (typeof this._lastStress !== 'undefined') {
      this._alpha = s1;
    }
    this._lastStress = s1;
    this.updateNodePositions();
    this.trigger({
      type: EventType.tick,
      alpha: this._alpha,
      stress: this._lastStress
    });
    return false;
  };
  Layout.prototype.updateNodePositions = function () {
    var x = this._descent.x[0],
      y = this._descent.x[1];
    var o,
      i = this._nodes.length;
    while (i--) {
      o = this._nodes[i];
      o.x = x[i];
      o.y = y[i];
    }
  };
  Layout.prototype.nodes = function (v) {
    if (!v) {
      if (this._nodes.length === 0 && this._links.length > 0) {
        var n = 0;
        this._links.forEach(function (l) {
          n = Math.max(n, l.source, l.target);
        });
        this._nodes = new Array(++n);
        for (var i = 0; i < n; ++i) {
          this._nodes[i] = {};
        }
      }
      return this._nodes;
    }
    this._nodes = v;
    return this;
  };
  Layout.prototype.groups = function (x) {
    var _this = this;
    if (!x) return this._groups;
    this._groups = x;
    this._rootGroup = {};
    this._groups.forEach(function (g) {
      if (typeof g.padding === "undefined") g.padding = 1;
      if (typeof g.leaves !== "undefined") {
        g.leaves.forEach(function (v, i) {
          if (typeof v === 'number') (g.leaves[i] = _this._nodes[v]).parent = g;
        });
      }
      if (typeof g.groups !== "undefined") {
        g.groups.forEach(function (gi, i) {
          if (typeof gi === 'number') (g.groups[i] = _this._groups[gi]).parent = g;
        });
      }
    });
    this._rootGroup.leaves = this._nodes.filter(function (v) {
      return typeof v.parent === 'undefined';
    });
    this._rootGroup.groups = this._groups.filter(function (g) {
      return typeof g.parent === 'undefined';
    });
    return this;
  };
  Layout.prototype.powerGraphGroups = function (f) {
    var g = powergraph.getGroups(this._nodes, this._links, this.linkAccessor, this._rootGroup);
    this.groups(g.groups);
    f(g);
    return this;
  };
  Layout.prototype.avoidOverlaps = function (v) {
    if (!arguments.length) return this._avoidOverlaps;
    this._avoidOverlaps = v;
    return this;
  };
  Layout.prototype.handleDisconnected = function (v) {
    if (!arguments.length) return this._handleDisconnected;
    this._handleDisconnected = v;
    return this;
  };
  Layout.prototype.flowLayout = function (axis, minSeparation) {
    if (!arguments.length) axis = 'y';
    this._directedLinkConstraints = {
      axis: axis,
      getMinSeparation: typeof minSeparation === 'number' ? function () {
        return minSeparation;
      } : minSeparation
    };
    return this;
  };
  Layout.prototype.links = function (x) {
    if (!arguments.length) return this._links;
    this._links = x;
    return this;
  };
  Layout.prototype.constraints = function (c) {
    if (!arguments.length) return this._constraints;
    this._constraints = c;
    return this;
  };
  Layout.prototype.distanceMatrix = function (d) {
    if (!arguments.length) return this._distanceMatrix;
    this._distanceMatrix = d;
    return this;
  };
  Layout.prototype.size = function (x) {
    if (!x) return this._canvasSize;
    this._canvasSize = x;
    return this;
  };
  Layout.prototype.defaultNodeSize = function (x) {
    if (!x) return this._defaultNodeSize;
    this._defaultNodeSize = x;
    return this;
  };
  Layout.prototype.groupCompactness = function (x) {
    if (!x) return this._groupCompactness;
    this._groupCompactness = x;
    return this;
  };
  Layout.prototype.linkDistance = function (x) {
    if (!x) {
      return this._linkDistance;
    }
    this._linkDistance = typeof x === "function" ? x : +x;
    this._linkLengthCalculator = null;
    return this;
  };
  Layout.prototype.linkType = function (f) {
    this._linkType = f;
    return this;
  };
  Layout.prototype.convergenceThreshold = function (x) {
    if (!x) return this._threshold;
    this._threshold = typeof x === "function" ? x : +x;
    return this;
  };
  Layout.prototype.alpha = function (x) {
    if (!arguments.length) return this._alpha;else {
      x = +x;
      if (this._alpha) {
        if (x > 0) this._alpha = x;else this._alpha = 0;
      } else if (x > 0) {
        if (!this._running) {
          this._running = true;
          this.trigger({
            type: EventType.start,
            alpha: this._alpha = x
          });
          this.kick();
        }
      }
      return this;
    }
  };
  Layout.prototype.getLinkLength = function (link) {
    return typeof this._linkDistance === "function" ? +this._linkDistance(link) : this._linkDistance;
  };
  Layout.setLinkLength = function (link, length) {
    link.length = length;
  };
  Layout.prototype.getLinkType = function (link) {
    return typeof this._linkType === "function" ? this._linkType(link) : 0;
  };
  Layout.prototype.symmetricDiffLinkLengths = function (idealLength, w) {
    var _this = this;
    if (w === void 0) {
      w = 1;
    }
    this.linkDistance(function (l) {
      return idealLength * l.length;
    });
    this._linkLengthCalculator = function () {
      return linklengths_1.symmetricDiffLinkLengths(_this._links, _this.linkAccessor, w);
    };
    return this;
  };
  Layout.prototype.jaccardLinkLengths = function (idealLength, w) {
    var _this = this;
    if (w === void 0) {
      w = 1;
    }
    this.linkDistance(function (l) {
      return idealLength * l.length;
    });
    this._linkLengthCalculator = function () {
      return linklengths_1.jaccardLinkLengths(_this._links, _this.linkAccessor, w);
    };
    return this;
  };
  Layout.prototype.start = function (initialUnconstrainedIterations, initialUserConstraintIterations, initialAllConstraintsIterations, gridSnapIterations, keepRunning, centerGraph) {
    var _this = this;
    if (initialUnconstrainedIterations === void 0) {
      initialUnconstrainedIterations = 0;
    }
    if (initialUserConstraintIterations === void 0) {
      initialUserConstraintIterations = 0;
    }
    if (initialAllConstraintsIterations === void 0) {
      initialAllConstraintsIterations = 0;
    }
    if (gridSnapIterations === void 0) {
      gridSnapIterations = 0;
    }
    if (keepRunning === void 0) {
      keepRunning = true;
    }
    if (centerGraph === void 0) {
      centerGraph = true;
    }
    var i,
      j,
      n = this.nodes().length,
      N = n + 2 * this._groups.length,
      m = this._links.length,
      w = this._canvasSize[0],
      h = this._canvasSize[1];
    var x = new Array(N),
      y = new Array(N);
    var G = null;
    var ao = this._avoidOverlaps;
    this._nodes.forEach(function (v, i) {
      v.index = i;
      if (typeof v.x === 'undefined') {
        v.x = w / 2, v.y = h / 2;
      }
      x[i] = v.x, y[i] = v.y;
    });
    if (this._linkLengthCalculator) this._linkLengthCalculator();
    var distances;
    if (this._distanceMatrix) {
      distances = this._distanceMatrix;
    } else {
      distances = new shortestpaths_1.Calculator(N, this._links, Layout.getSourceIndex, Layout.getTargetIndex, function (l) {
        return _this.getLinkLength(l);
      }).DistanceMatrix();
      G = descent_1.Descent.createSquareMatrix(N, function () {
        return 2;
      });
      this._links.forEach(function (l) {
        if (typeof l.source == "number") l.source = _this._nodes[l.source];
        if (typeof l.target == "number") l.target = _this._nodes[l.target];
      });
      this._links.forEach(function (e) {
        var u = Layout.getSourceIndex(e),
          v = Layout.getTargetIndex(e);
        G[u][v] = G[v][u] = e.weight || 1;
      });
    }
    var D = descent_1.Descent.createSquareMatrix(N, function (i, j) {
      return distances[i][j];
    });
    if (this._rootGroup && typeof this._rootGroup.groups !== 'undefined') {
      var i = n;
      var addAttraction = function (i, j, strength, idealDistance) {
        G[i][j] = G[j][i] = strength;
        D[i][j] = D[j][i] = idealDistance;
      };
      this._groups.forEach(function (g) {
        addAttraction(i, i + 1, _this._groupCompactness, 0.1);
        x[i] = 0, y[i++] = 0;
        x[i] = 0, y[i++] = 0;
      });
    } else this._rootGroup = {
      leaves: this._nodes,
      groups: []
    };
    var curConstraints = this._constraints || [];
    if (this._directedLinkConstraints) {
      this.linkAccessor.getMinSeparation = this._directedLinkConstraints.getMinSeparation;
      curConstraints = curConstraints.concat(linklengths_1.generateDirectedEdgeConstraints(n, this._links, this._directedLinkConstraints.axis, this.linkAccessor));
    }
    this.avoidOverlaps(false);
    this._descent = new descent_1.Descent([x, y], D);
    this._descent.locks.clear();
    for (var i = 0; i < n; ++i) {
      var o = this._nodes[i];
      if (o.fixed) {
        o.px = o.x;
        o.py = o.y;
        var p = [o.x, o.y];
        this._descent.locks.add(i, p);
      }
    }
    this._descent.threshold = this._threshold;
    this.initialLayout(initialUnconstrainedIterations, x, y);
    if (curConstraints.length > 0) this._descent.project = new rectangle_1.Projection(this._nodes, this._groups, this._rootGroup, curConstraints).projectFunctions();
    this._descent.run(initialUserConstraintIterations);
    this.separateOverlappingComponents(w, h, centerGraph);
    this.avoidOverlaps(ao);
    if (ao) {
      this._nodes.forEach(function (v, i) {
        v.x = x[i], v.y = y[i];
      });
      this._descent.project = new rectangle_1.Projection(this._nodes, this._groups, this._rootGroup, curConstraints, true).projectFunctions();
      this._nodes.forEach(function (v, i) {
        x[i] = v.x, y[i] = v.y;
      });
    }
    this._descent.G = G;
    this._descent.run(initialAllConstraintsIterations);
    if (gridSnapIterations) {
      this._descent.snapStrength = 1000;
      this._descent.snapGridSize = this._nodes[0].width;
      this._descent.numGridSnapNodes = n;
      this._descent.scaleSnapByMaxH = n != N;
      var G0 = descent_1.Descent.createSquareMatrix(N, function (i, j) {
        if (i >= n || j >= n) return G[i][j];
        return 0;
      });
      this._descent.G = G0;
      this._descent.run(gridSnapIterations);
    }
    this.updateNodePositions();
    this.separateOverlappingComponents(w, h, centerGraph);
    return keepRunning ? this.resume() : this;
  };
  Layout.prototype.initialLayout = function (iterations, x, y) {
    if (this._groups.length > 0 && iterations > 0) {
      var n = this._nodes.length;
      var edges = this._links.map(function (e) {
        return {
          source: e.source.index,
          target: e.target.index
        };
      });
      var vs = this._nodes.map(function (v) {
        return {
          index: v.index
        };
      });
      this._groups.forEach(function (g, i) {
        vs.push({
          index: g.index = n + i
        });
      });
      this._groups.forEach(function (g, i) {
        if (typeof g.leaves !== 'undefined') g.leaves.forEach(function (v) {
          return edges.push({
            source: g.index,
            target: v.index
          });
        });
        if (typeof g.groups !== 'undefined') g.groups.forEach(function (gg) {
          return edges.push({
            source: g.index,
            target: gg.index
          });
        });
      });
      new Layout().size(this.size()).nodes(vs).links(edges).avoidOverlaps(false).linkDistance(this.linkDistance()).symmetricDiffLinkLengths(5).convergenceThreshold(1e-4).start(iterations, 0, 0, 0, false);
      this._nodes.forEach(function (v) {
        x[v.index] = vs[v.index].x;
        y[v.index] = vs[v.index].y;
      });
    } else {
      this._descent.run(iterations);
    }
  };
  Layout.prototype.separateOverlappingComponents = function (width, height, centerGraph) {
    var _this = this;
    if (centerGraph === void 0) {
      centerGraph = true;
    }
    if (!this._distanceMatrix && this._handleDisconnected) {
      var x_1 = this._descent.x[0],
        y_1 = this._descent.x[1];
      this._nodes.forEach(function (v, i) {
        v.x = x_1[i], v.y = y_1[i];
      });
      var graphs = handledisconnected_1.separateGraphs(this._nodes, this._links);
      handledisconnected_1.applyPacking(graphs, width, height, this._defaultNodeSize, 1, centerGraph);
      this._nodes.forEach(function (v, i) {
        _this._descent.x[0][i] = v.x, _this._descent.x[1][i] = v.y;
        if (v.bounds) {
          v.bounds.setXCentre(v.x);
          v.bounds.setYCentre(v.y);
        }
      });
    }
  };
  Layout.prototype.resume = function () {
    return this.alpha(0.1);
  };
  Layout.prototype.stop = function () {
    return this.alpha(0);
  };
  Layout.prototype.prepareEdgeRouting = function (nodeMargin) {
    if (nodeMargin === void 0) {
      nodeMargin = 0;
    }
    this._visibilityGraph = new geom_1.TangentVisibilityGraph(this._nodes.map(function (v) {
      return v.bounds.inflate(-nodeMargin).vertices();
    }));
  };
  Layout.prototype.routeEdge = function (edge, ah, draw) {
    if (ah === void 0) {
      ah = 5;
    }
    var lineData = [];
    var vg2 = new geom_1.TangentVisibilityGraph(this._visibilityGraph.P, {
        V: this._visibilityGraph.V,
        E: this._visibilityGraph.E
      }),
      port1 = {
        x: edge.source.x,
        y: edge.source.y
      },
      port2 = {
        x: edge.target.x,
        y: edge.target.y
      },
      start = vg2.addPoint(port1, edge.source.index),
      end = vg2.addPoint(port2, edge.target.index);
    vg2.addEdgeIfVisible(port1, port2, edge.source.index, edge.target.index);
    if (typeof draw !== 'undefined') {
      draw(vg2);
    }
    var sourceInd = function (e) {
        return e.source.id;
      },
      targetInd = function (e) {
        return e.target.id;
      },
      length = function (e) {
        return e.length();
      },
      spCalc = new shortestpaths_1.Calculator(vg2.V.length, vg2.E, sourceInd, targetInd, length),
      shortestPath = spCalc.PathFromNodeToNode(start.id, end.id);
    if (shortestPath.length === 1 || shortestPath.length === vg2.V.length) {
      var route = rectangle_1.makeEdgeBetween(edge.source.innerBounds, edge.target.innerBounds, ah);
      lineData = [route.sourceIntersection, route.arrowStart];
    } else {
      var n = shortestPath.length - 2,
        p = vg2.V[shortestPath[n]].p,
        q = vg2.V[shortestPath[0]].p,
        lineData = [edge.source.innerBounds.rayIntersection(p.x, p.y)];
      for (var i = n; i >= 0; --i) lineData.push(vg2.V[shortestPath[i]].p);
      lineData.push(rectangle_1.makeEdgeTo(q, edge.target.innerBounds, ah));
    }
    return lineData;
  };
  Layout.getSourceIndex = function (e) {
    return typeof e.source === 'number' ? e.source : e.source.index;
  };
  Layout.getTargetIndex = function (e) {
    return typeof e.target === 'number' ? e.target : e.target.index;
  };
  Layout.linkId = function (e) {
    return Layout.getSourceIndex(e) + "-" + Layout.getTargetIndex(e);
  };
  Layout.dragStart = function (d) {
    if (isGroup(d)) {
      Layout.storeOffset(d, Layout.dragOrigin(d));
    } else {
      Layout.stopNode(d);
      d.fixed |= 2;
    }
  };
  Layout.stopNode = function (v) {
    v.px = v.x;
    v.py = v.y;
  };
  Layout.storeOffset = function (d, origin) {
    if (typeof d.leaves !== 'undefined') {
      d.leaves.forEach(function (v) {
        v.fixed |= 2;
        Layout.stopNode(v);
        v._dragGroupOffsetX = v.x - origin.x;
        v._dragGroupOffsetY = v.y - origin.y;
      });
    }
    if (typeof d.groups !== 'undefined') {
      d.groups.forEach(function (g) {
        return Layout.storeOffset(g, origin);
      });
    }
  };
  Layout.dragOrigin = function (d) {
    if (isGroup(d)) {
      return {
        x: d.bounds.cx(),
        y: d.bounds.cy()
      };
    } else {
      return d;
    }
  };
  Layout.drag = function (d, position) {
    if (isGroup(d)) {
      if (typeof d.leaves !== 'undefined') {
        d.leaves.forEach(function (v) {
          d.bounds.setXCentre(position.x);
          d.bounds.setYCentre(position.y);
          v.px = v._dragGroupOffsetX + position.x;
          v.py = v._dragGroupOffsetY + position.y;
        });
      }
      if (typeof d.groups !== 'undefined') {
        d.groups.forEach(function (g) {
          return Layout.drag(g, position);
        });
      }
    } else {
      d.px = position.x;
      d.py = position.y;
    }
  };
  Layout.dragEnd = function (d) {
    if (isGroup(d)) {
      if (typeof d.leaves !== 'undefined') {
        d.leaves.forEach(function (v) {
          Layout.dragEnd(v);
          delete v._dragGroupOffsetX;
          delete v._dragGroupOffsetY;
        });
      }
      if (typeof d.groups !== 'undefined') {
        d.groups.forEach(Layout.dragEnd);
      }
    } else {
      d.fixed &= ~6;
    }
  };
  Layout.mouseOver = function (d) {
    d.fixed |= 4;
    d.px = d.x, d.py = d.y;
  };
  Layout.mouseOut = function (d) {
    d.fixed &= ~4;
  };
  return Layout;
}();
exports.Layout = Layout;
