import { Component, Inject, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleType } from "@common/ADAPT.Common.Model/organisation/role-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { Tier1ArchitectureService } from "app/features/architecture/tier1-architecture/tier1-architecture.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-edit-person-roles-dialog",
    templateUrl: "./edit-person-roles-dialog.component.html",
})
export class EditPersonRolesDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Connection, Person> implements OnInit {
    public readonly dialogName = "EditPersonRolesDialog";

    public get entitiesToConfirm() {
        if (!this.connection) {
            return [];
        }

        return this.connection.roleConnections.concat(this.deletedEntities);
    }

    public tier1RoleType?: RoleType;
    public culturalLeadershipConfiguration?: CulturalLeadership;

    public person?: Person;
    public connection!: Connection;

    public title?: string;

    private deletedEntities: RoleConnection[] = [];

    constructor(
        @Inject(ADAPT_DIALOG_DATA) private dialogData: Connection,
        protected commonDialogService: AdaptCommonDialogService,
        private tier1ArchService: Tier1ArchitectureService,
        private authorisationService: AuthorisationService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private directorySharedService: DirectorySharedService,
    ) {
        super();
    }

    public async ngOnInit() {
        try {
            await this.authorisationService.promiseToVerifyAccess(Tier1ArchitectureAuthService.ReadTier1);
            this.tier1RoleType = await lastValueFrom(this.tier1ArchService.getTier1RoleType());
        } catch {
            this.tier1RoleType = undefined;
        }

        this.culturalLeadershipConfiguration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();

        // fetch data accordinbg to what we are editing
        this.connection = this.dialogData;
        this.person = this.connection.person;
        await this.directorySharedService.promiseToGetRoleConnectionsForPersonId(this.dialogData.personId);
        this.title = `Edit roles for ${this.person!.fullName}`;
        this.autoResolveData = this.person;
    }

    /* Handler Methods */
    public onDeletion(roleConnection: RoleConnection) {
        // won't process incomplete role connection, e.g. new role connection without defining what role or connection is
        if (roleConnection.roleId && roleConnection.connectionId) {
            this.deletedEntities.push(roleConnection);
        }
    }
}
