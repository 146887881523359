import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CareerValuationDashboardPageComponent } from "./cvt-dashboard-page.component";

const CareerValuationDashboardPageTitle = "Career Valuation";

export const myCareerValuationDashboardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-career-valuation-dashboard-page", CareerValuationDashboardPageComponent)
    .atOrganisationUrl("/people/career-valuation/dashboard")
    .redirectToThisRouteFromOrganisationUrl("/people/careerValuation/dashboard")
    .verifyingFeatures(FeatureName.CulturalNetworkCareerValuation)
    .withTitle(CareerValuationDashboardPageTitle)
    .requiresLogin(true)
    .build();

export const careerValuationDashboardPageRoute = new OrganisationPageRouteBuilder<{ personId: number }>()
    .usingNgComponent("adapt-career-valuation-dashboard-page", CareerValuationDashboardPageComponent)
    .atOrganisationUrl("/people/career-valuation/:personId/dashboard")
    .redirectToThisRouteFromOrganisationUrl("/people/careerValuation/:personId/dashboard")
    .verifyingFeatures(FeatureName.CulturalNetworkCareerValuation)
    .withTitle(CareerValuationDashboardPageTitle)
    .requiresLogin(true)
    .build();
