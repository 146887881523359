import { Injectable, Injector } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { ProcessStep, ProcessStepBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { RoleLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-location";
import { RoleSupplementaryData, RoleSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-supplementary-data";
import { RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AfterOrganisationInitialisationObservable } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { lastValueFrom, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkAuthService } from "./integrated-architecture-framework-auth.service";

@Injectable({
    providedIn: "root",
})
export class IntegratedArchitectureFrameworkService extends BaseOrganisationService {
    public roleGuidance = {
        location: "The area(s) of the organisation in which this role is performed",
        people: "The people currently performing this role",
        keyAccountabilities: "The key tasks that this role is accountable for",
        relationships: "The people or teams that you will interact with in this role",
        attributes: "The less tangible attributes and behaviours that you will need in this role",
        capabilities: "The core skills that you will need (or be prepared to learn) in this role",
        tasks: "The tasks, grouped into areas of the organisation, that you will be accountable for performing in this role",
    };

    private data: IntegratedArchitectureFrameworkQueryUtilities;

    private _roleConnectionChange = new Subject<void>();

    public get roleConnectionChange$() {
        return this._roleConnectionChange.asObservable();
    }

    public constructor(
        injector: Injector,
        private authorisationFactory: AuthorisationService,
        private organisationService: OrganisationService,
    ) {
        super(injector);

        this.data = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);
    }

    public emitRoleConnectionChange() {
        this._roleConnectionChange.next();
    }

    protected organisationInitialisationActions() {
        return [
            this.getAllRoleTypes(),
            this.getAllRoleLocations(),
        ];
    }

    public getRoleById(roleId: number) {
        return this.commonDataService.getById(RoleBreezeModel, roleId);
    }

    public getRoleSupplementaryDataById(roleId: number) {
        return this.commonDataService.getById(RoleSupplementaryDataBreezeModel, roleId);
    }

    public getAllRoleSupplementaryData() {
        return this.commonDataService.getAll(RoleSupplementaryDataBreezeModel);
    }

    public getAllRoleTypes() {
        return this.commonDataService.getAll(RoleTypeBreezeModel);
    }

    public getAllRoleLocations() {
        return this.commonDataService.getAll(RoleLocationBreezeModel);
    }

    public getRoleConnectionsForRoleId(roleId: number, activeOnly?: boolean) {
        return this.data.getRoleConnectionsForRoleId(roleId, activeOnly);
    }

    public createRoleConnection(values: Partial<RoleConnection>) {
        return this.data.createRoleConnection(values);
    }

    public getActiveRolesByPredicate(predicate?: MethodologyPredicate<Role>) {
        return this.data.getActiveRolesByPredicate(predicate);
    }

    /**
     * Promise to create a new role for the existing organisation.
     * @param values The optional values to populate the role with
     * @returns A promise that resolves with the new role.
     */
    public createRole(values: Partial<Role> = {}) {
        const defaults: Partial<Role> = Object.assign({}, values, {
            organisationId: this.organisationService.getOrganisationId(),
            startDate: moment().toDate(),
        });

        if (!defaults.connectionType) {
            defaults.connectionType = ConnectionType.Employee;
        }

        return this.commonDataService.create(RoleBreezeModel, defaults);
    }

    public createRoleSupplementaryData(data?: Partial<RoleSupplementaryData>) {
        return this.commonDataService.create(RoleSupplementaryDataBreezeModel, data);
    }

    @AfterOrganisationInitialisationObservable
    public getRoleWithLayoutById(roleId: number) {
        const key = `roleLayoutSuppData${roleId}`;
        const predicate = new MethodologyPredicate<Role>("roleId", "==", roleId);
        return this.commonDataService.getWithOptions(RoleBreezeModel, key, {
            navProperty: "roleFeaturePermissions,supplementaryData,layout",
            predicate,
            matchKeyOnly: true,
        }).pipe(map(ArrayUtilities.getSingleFromArray));
    }

    public async promiseToCopyRole(role?: Role): Promise<{ role: Role, changedEntities: IBreezeEntity[] }> {
        if (!role) {
            throw new Error("Role not specified");
        }

        const newRole = await lastValueFrom(this.createRole({
            label: StringUtilities.shorten(`Copy of ${role.label}`, 255),
            team: role.team,
            connectionType: role.connectionType,
            roleTypeId: role.roleTypeId,
        }));

        const changedEntities: IBreezeEntity[] = [];

        await lastValueFrom(this.getRoleSupplementaryDataById(role.roleId));
        if (role.supplementaryData) {
            const newRoleSupplementaryData = await lastValueFrom(this.createRoleSupplementaryData({ roleId: newRole.roleId }));
            changedEntities.push(newRoleSupplementaryData);

            newRoleSupplementaryData.attributes = role.supplementaryData.attributes;
            newRoleSupplementaryData.capabilities = role.supplementaryData.capabilities;
            newRoleSupplementaryData.relationships = role.supplementaryData.relationships;
            newRoleSupplementaryData.keyAccountabilities = role.supplementaryData.keyAccountabilities;
        }

        if (role.extensions.hasAccessPermissions()) {
            // copy access permissions
            const newRoleFeaturePermissions = await Promise.all(role.roleFeaturePermissions.map((roleFeaturePermission) => {
                return lastValueFrom(this.commonDataService.create(RoleFeaturePermissionBreezeModel, {
                    role: newRole,
                    featurePermission: roleFeaturePermission.featurePermission,
                }));
            }));

            changedEntities.push(...newRoleFeaturePermissions);
        }

        return {
            role: newRole,
            changedEntities,
        };
    }

    public deleteRole(role: Role) {
        return this.commonDataService.remove(role).pipe(
            switchMap(() => this.commonDataService.saveEntities([role])),
        );
    }

    public promiseToVerifyTier2EditAccess(entity?: IBreezeEntity): Promise<boolean> {
        const promise = this.authorisationFactory.promiseToVerifyAccess(IntegratedArchitectureFrameworkAuthService.EditTier2, entity);

        return PromiseUtilities.promiseToValidatePromiseResolution(promise);
    }

    public getProcessStepsForRoleId(roleId: number) {
        const predicate = new MethodologyPredicate<ProcessStep>("roleId", "==", roleId);
        return this.commonDataService.getByPredicate(ProcessStepBreezeModel, predicate);
    }
}
