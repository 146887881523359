import { Component, Inject, Injector, OnInit } from "@angular/core";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PERSONAL_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { HelpTourService } from "@common/shell/help-tour/help-tour.service";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { BasicEmbedTour } from "app/services/basic-embed-tour";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

enum DashboardPage {
    Stewardship = "stewardship",
    CultureAndActivity = "culture-and-activity",
    CulturalLeadership = "cultural-leadership",
    Activity = "activity",
}

interface IDashboardTab {
    toggleValue: DashboardPage;
    icon: string;
    text: string;
    isDisplayed$: Observable<boolean>;
}

@Component({
    selector: "adapt-personal-dashboard-page",
    templateUrl: "./personal-dashboard-page.component.html",
})
export class PersonalDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly pageSearchParam = "page";

    public page = DashboardPage.Stewardship;
    public dashboardPageEnum = DashboardPage;

    public canEditPersonalStewardship = KanbanAuthService.EditPersonalStewardshipKanban;
    public canReadStewardship = KanbanAuthService.ViewAnyBoard;
    public readObjectives = ObjectivesAuthService.ReadAnyObjectives;
    public readCulturalLeadership = CulturalLeadershipFrameworkAuthService.ReadCohorts;
    public readMyCareerValuation = CareerValuationAuthService.ReadMyCareerValuation;
    public readMyCulturalIndex = CulturalIndexAuthService.ReadMyCulturalIndex;

    public canReadMeetings$: Observable<boolean>;
    public personalConfigLink$: Observable<string>;
    public person$ = this.userService.currentPerson$;
    public tabs: IDashboardTab[];

    constructor(
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private userService: UserService,
        private authorisationService: AuthorisationService,
        private tourService: HelpTourService,
        injector: Injector,
        @Inject(PERSONAL_CONFIGURATION_PAGE) personalConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        // check for features is already in the access verifier
        this.canReadMeetings$ = authorisationService.getHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting);
        this.personalConfigLink$ = personalConfigurationPageRoute.getRoute();
        this.tabs = this.buildTabs();
    }

    public async ngOnInit() {
        const page = this.getSearchParameterValue(PersonalDashboardPageComponent.pageSearchParam) as DashboardPage;

        forkJoin(this.tabs.map((t) => {
            return t.isDisplayed$.pipe(
                take(1),
                map((isDisplayed) => [t.toggleValue, isDisplayed] as const),
            );
        })).subscribe((tabDisplayStatus) => {
            const displayedTabs = tabDisplayStatus.filter((i) => i[1]).map((i) => i[0]);
            const defaultPage = displayedTabs[0] ?? DashboardPage.Activity;
            this.updateUrl(displayedTabs.includes(page) ? page : defaultPage);

            this.notifyActivated();

            // if not within a timeout the dialog will disappear by itself when navigating to this page
            setTimeout(() => {
                // run tour if first visit
                if (!this.tourService.tourHasRun(BasicEmbedTour)) {
                    this.runTour();
                }
            });
        });
    }

    public updateUrl(newValueIdentifier: DashboardPage): void {
        this.page = newValueIdentifier;
        if (newValueIdentifier === DashboardPage.Activity) {
            this.deleteSearchParameter(PersonalDashboardPageComponent.pageSearchParam);
        } else {
            this.setSearchParameterValue(PersonalDashboardPageComponent.pageSearchParam, this.page);
        }
    }

    public runTour() {
        this.tourService.runTour(BasicEmbedTour);
    }

    private buildTabs(): IDashboardTab[] {
        const cultureAndActivityTab: IDashboardTab = {
            toggleValue: DashboardPage.CultureAndActivity,
            icon: "fal fa-chart-pie",
            text: "Culture & activity",
            isDisplayed$: combineLatest([
                this.authorisationService.getHasAccess(CareerValuationAuthService.ReadMyCareerValuation),
                this.authorisationService.getHasAccess(CulturalIndexAuthService.ReadMyCulturalIndex),
            ]).pipe(
                map((checks) => checks.includes(true)),
            ),
        };

        return [
            {
                toggleValue: DashboardPage.Stewardship,
                icon: "fal fa-subway",
                text: "Stewardship",
                isDisplayed$: combineLatest([
                    this.canReadMeetings$,
                    this.authorisationService.getHasAccess(this.canReadStewardship),
                    this.authorisationService.getHasAccess(ObjectivesAuthService.ReadAnyObjectives),
                ]).pipe(
                    map((checks) => checks.includes(true)),
                ),
            },
            {
                toggleValue: DashboardPage.CulturalLeadership,
                icon: "fal fa-users",
                text: "Cultural leadership",
                isDisplayed$: this.authorisationService.getHasAccess(this.readCulturalLeadership).pipe(
                    switchMap((hasAccess) => {
                        if (!hasAccess) {
                            return of(false);
                        }

                        return this.person$.pipe(
                            emptyIfUndefinedOrNull(),
                            switchMap((person) => this.culturalLeadershipFrameworkService.getCulturalCohort(person.personId)),
                            map((cohort) => cohort.length > 0),
                        );
                    }),
                ),
            },
            cultureAndActivityTab,
            {
                toggleValue: DashboardPage.Activity,
                icon: "fal fa-rss",
                text: "Activity",
                isDisplayed$: cultureAndActivityTab.isDisplayed$.pipe(
                    map((check) => !check),
                ),
            },
        ];
    }
}
