<ng-container *ngIf="!isCreateAndSelecting; else createTemplate">
    <dx-select-box [dataSource]="allSystems$ | async"
                   [value]="system"
                   (valueChange)="systemChange.emit($event)"
                   displayExpr="name"
                   [searchEnabled]="true"
                   searchExpr="name"
                   data-test="select-system"></dx-select-box>
    <button *ngIf="allowCreate"
            class="mt-2"
            adaptButton="addLink"
            [adaptBlockingClick]="createNewSystem">Add a new system</button>
</ng-container>
<ng-template #createTemplate>
    <div *ngIf="newSystem"
         class="d-flex mb-4">
        <!-- bottom margin to allow gap for validation error -->
        <dx-text-box class="flex-grow-1 me-2"
                     [(value)]="newSystem!.name"
                     (onValueChanged)="onNewSystemNameChange($event.value)"
                     placeholder="Type a new system name"
                     [adaptValidateEntity]="newSystem"
                     adaptValidateEntityProperty="name"
                     (onInitialized)="focus($event)">
        </dx-text-box>
        <button class="me-2"
                adaptButton="add"
                [disabled]="newSystem!.entityAspect.hasValidationErrors"
                [adaptBlockingClick]="addAndSelectNewSystem"
                data-test="add-system-button"></button>
        <button adaptButton="cancel"
                [adaptBlockingClick]="cancelNewSystem"></button>
    </div>
</ng-template>
