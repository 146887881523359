<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Export Task Map : {{stepNames[currentStepIndex]}}</h3>

    <div adapt-dialog-content>
        <div class="d-flex flex-row justify-content-between mx-4">
            <ng-container *ngFor="let stepName of stepNames; let i = index; let last = last">
                <div class="align-self-center d-flex">
                    <span class="align-self-baseline"
                          [ngClass]="{
                              'step-index': i === currentStepIndex,
                              'step-index-disabled': i !== currentStepIndex
                            }">{{i + 1}}</span>
                    <span class="ms-2 align-self-baseline"
                          [ngClass]="i === currentStepIndex ? 'step-text': 'step-text-disabled'">{{stepName}}</span>
                </div>
                <div *ngIf="!last"
                     class="align-self-center d-flex flex-grow-1 step-separator">&nbsp;</div>
            </ng-container>
        </div>
        <div class="mt-4 p-3">
            <adapt-export-type-selector-step [exportType]="exportType"
                                             (exportTypeChange)="onExportTypeChanged($event)"
                                             *ngIf="isCurrentStep(StepName.SelectExportType)">
            </adapt-export-type-selector-step>
            <adapt-board-selection-step [(board)]="exporter!.board"
                                        *ngIf="isCurrentStep(StepName.SelectEmbedBoard)"></adapt-board-selection-step>
            <adapt-role-person-allocations-step [board]="exporter!.board"
                                                [processMap]="processMap"
                                                [(rolePersonAllocations)]="exporter!.rolePersonAllocations"
                                                (loadingDataChange)="exporter!.loadingData = $event"
                                                *ngIf="isCurrentStep(StepName.AllocateRoles)">
            </adapt-role-person-allocations-step>
            <adapt-prefix-set-step [prefix]="exporter!.prefix"
                                   (prefixChange)="onExportPrefixChanged($event)"
                                   [processMap]="processMap"
                                   *ngIf="isCurrentStep(StepName.SetPrefix)"></adapt-prefix-set-step>
            <adapt-export-confirmation-step [processMap]="processMap"
                                            [rolePersonAllocations]="exporter!.rolePersonAllocations"
                                            [exportDestination]="exportType"
                                            [board]="exporter!.board"
                                            [prefix]="exporter!.prefix"
                                            [started]="started"
                                            [completed]="exporter!.completed"
                                            [progressTotalCount]="exporter!.progressTotalCount"
                                            [progressCurrentCount]="exporter!.progressCurrentCount"
                                            *ngIf="isCurrentStep(StepName.ExportConfirmation)">
            </adapt-export-confirmation-step>
        </div>
    </div>

    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="ms-auto"
         *ngIf="exporter">
        <ng-container *ngIf="!exporter.completed">
            <button adaptButtonIcon="fal fa-fw fa-arrow-circle-left"
                    (click)="previous()"
                    [disabled]="currentStepIndex < 1">
                Previous
            </button>
            <button adaptButtonIcon="fal fa-fw fa-arrow-circle-right"
                    (click)="next()"
                    [disabled]="!canGoNext || currentStepIndex >= stepNames.length - 1">
                Next
            </button>
            <button adaptButtonIcon="fal fa-fw fa-download"
                    [adaptBlockingClick]="export"
                    data-test="confirm-export-process-map-button"
                    *ngIf="isCurrentStep(StepName.ExportConfirmation)">
                Export to {{exportType}}
            </button>
        </ng-container>
        <button #focus
                adaptButtonIcon="cancel"
                (click)="cancel()">
            {{exporter.completed ? "Close" : "Cancel"}}
        </button>
    </div>
</adapt-dialog>
