import { Component, OnInit } from "@angular/core";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ProcessStepType, ProcessStepTypeMetadata } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { IAdaptLinkObject } from "@common/route/route.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { ISearchResultMatch, ISystemProcessStepSearchResultExtra, ISystemSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowLabelledBaseComponent } from "@org-common/lib/search/search-row-labelled-base.component";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { lastValueFrom, Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-system",
    templateUrl: "./search-row-system.component.html",
    styleUrls: ["./search-row-system.component.scss"],
})
export class SearchRowSystemComponent extends SearchRowLabelledBaseComponent<ISystemSearchResult> implements OnInit {
    public readonly ProcessStepType = ProcessStepType;

    public href$?: Observable<IAdaptLinkObject>;
    public stepLocations: LabelLocation[] = [];

    public constructor(
        protected searchService: SearchService,
        protected labellingService: LabellingService,
    ) {
        super(searchService, labellingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.href$ = systemPageRoute.getRouteObject({ systemEntityId: this.result.systemEntityId });

        this.matches = this.excludeNameBreadcrumb(this.withoutLastBreadcrumb(this.result.results))
            .map((m) => ({
                ...m,
                // remove the last breadcrumb as it's the step name again
                field: this.isProcessStep(m) ? m.field.slice(0, -1) : m.field,
            }));

        const stepLabelLocations = this.matches.flatMap((match: ISearchResultMatch<ISystemProcessStepSearchResultExtra>) => match.extra?.labelLocationIds ?? []);
        if (stepLabelLocations.length > 0) {
            const allLabelLocations = await lastValueFrom(this.labellingService.getAllLabelLocations());
            this.stepLocations = allLabelLocations.filter((ll) => stepLabelLocations.includes(ll.labelLocationId));
        }
    }

    public asMatch(match: ISearchResultMatch) {
        return match as ISearchResultMatch;
    }

    public isProcessStep(match: ISearchResultMatch): match is ISearchResultMatch<ISystemProcessStepSearchResultExtra> {
        return "stepType" in (match.extra ?? {});
    }

    public asProcessStep(match: ISearchResultMatch) {
        if (this.isProcessStep(match)) {
            return match as ISearchResultMatch<ISystemProcessStepSearchResultExtra>;
        }

        return undefined;
    }

    public getStepClass(stepType: ProcessStepType) {
        return ProcessStepTypeMetadata.Label[stepType].split(" ").join("-");
    }

    public getStepLabelLocations(labelLocationIds: number[]) {
        return this.stepLocations.filter((ll) => labelLocationIds.includes(ll.labelLocationId));
    }

    public getStepIconClass(stepType: ProcessStepType) {
        return this.getStepIcon(stepType) + " " + this.getStepClass(stepType) + "-icon-sm";
    }

    private getStepIcon(stepType: ProcessStepType) {
        return ProcessStepTypeMetadata.IconClass[stepType];
    }
}
