<form role="form">
    <div class="form-group">
        <label for="ratingType">Network Graph</label>
        <dx-select-box [dataSource]="ratingTypeOptions"
                       displayExpr="label"
                       valueExpr="value"
                       [(value)]="currentOptions.ratingType"
                       (valueChange)="emitOptionsChanged()"></dx-select-box>
        <p *ngIf="ratingTypeDescription"
           class="description">{{ratingTypeDescription}}</p>
    </div>
    <fieldset>
        <legend>Filter by Rating</legend>
        <div class="form-group">
            <div class="form-check form-check-inline">
                <input class="form-check-input"
                       type="checkbox"
                       id="inputGood"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="currentOptions.showGood"
                       (ngModelChange)="emitOptionsChanged()">
                <label class="form-check-label"
                       for="inputGood">
                    Good
                </label>
            </div>

            <div class="form-check form-check-inline">
                <input class="form-check-input"
                       type="checkbox"
                       id="inputMinor"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="currentOptions.showMinor"
                       (ngModelChange)="emitOptionsChanged()">
                <label class="form-check-label"
                       for="inputMinor">
                    Minor
                </label>
            </div>

            <div class="form-check form-check-inline">
                <input class="form-check-input"
                       type="checkbox"
                       id="inputMajor"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="currentOptions.showMajor"
                       (ngModelChange)="emitOptionsChanged()">
                <label class="form-check-label"
                       for="inputMajor">
                    Major
                </label>
            </div>
        </div>
    </fieldset>
    <div class="form-group"
         *ngIf="allowTeamSelection">
        <label for="teamId">Team Network Health</label>
        <adapt-select-team id="teamId"
                           [teamId]="currentOptions.teamId"
                           (teamChange)="onTeamChanged($event)"></adapt-select-team>
    </div>
    <dx-accordion [collapsible]="true"
                  [dataSource]="[{}]"
                  [multiple]="true"
                  [selectedIndex]="-1"
                  [deferRendering]="false">
        <div *dxTemplate="let item of 'title'">
            Advanced options
        </div>
        <div *dxTemplate="let item of 'item'">
            <fieldset>
                <legend>Status</legend>
                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputOnTrack"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showOnTrack"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputOnTrack">
                            On schedule
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputOverdue"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showOverdue"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputOverdue">
                            Overdue
                        </label>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>People</legend>
                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputInactive"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showInactive"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputInactive">
                            Show inactive people
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputStakeholders"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showExternalStakeholders"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputStakeholders">
                            Show external stakeholders
                        </label>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Facilitation</legend>
                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputFacilitated"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showFacilitated"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputFacilitated">
                            Facilitated
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputUnfacilitated"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showUnfacilitated"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputUnfacilitated">
                            Unfacilitated
                        </label>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Team Peer Catch-ups</legend>
                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputTeamPCU"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.onlyShowTeamCatchups"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputTeamPCU">
                            Only show team catch-ups
                        </label>
                    </div>
                </div>
            </fieldset>
            <div class="form-group">
                <label for="toDate">Show network at</label>
                <dx-date-box id="toDate"
                             type="date"
                             [value]="currentOptions.displayDate"
                             (valueChange)="displayDateChanged($any($event))"
                             [displayFormat]="DateFormats.globalize.long"
                             [min]="MinDate"
                             width="100%"></dx-date-box>
            </div>
            <div class="form-group">
                <label for="expiryMonths">Overdue if older than</label>
                <dx-slider id="expiryMonths"
                           [min]="1"
                           [max]="12"
                           [(value)]="currentOptions.expiryMonths"
                           (valueChange)="emitOptionsChanged()"></dx-slider>
                <p class="slider-label">{{expiryMonthsDisplay}}</p>
            </div>
            <div class="form-group">
                <label for="excludeMonths">Exclude if older than</label>
                <dx-slider id="excludeMonths"
                           [min]="1"
                           [max]="25"
                           [(value)]="currentOptions.excludeMonths"
                           (valueChange)="emitOptionsChanged()"></dx-slider>
                <p class="slider-label">{{excludeMonthsDisplay}}</p>
            </div>
            <fieldset>
                <legend>View</legend>
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputShowName"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showName"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputShowName">
                            Name Labels
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputShowFacilitator"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showFacilitator"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputShowFacilitator">
                            Facilitator Icon
                        </label>
                    </div>

                    <div class="form-check"
                         *ngIf="isTeamView && canShowTeamRelationships">
                        <input class="form-check-input"
                               type="checkbox"
                               id="inputShowAllPossible"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="currentOptions.showTeamRelationships"
                               (ngModelChange)="emitOptionsChanged()">
                        <label class="form-check-label"
                               for="inputShowAllPossible">
                            All Possible Links
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
    </dx-accordion>
</form>
