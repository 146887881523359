import { Component, Input } from "@angular/core";
import { OrganisationSupplementaryData } from "@common/ADAPT.Common.Model/organisation/organisation-supplementary-data";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { PreviewEmailDialogComponent } from "@org-common/lib/email/preview-email-dialog-component/preview-email-dialog-component.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { lastValueFrom, switchMap } from "rxjs";

@Component({
    selector: "adapt-configure-custom-welcome-email",
    templateUrl: "./configure-custom-welcome-email.component.html",
})
export class ConfigureCustomWelcomeEmailComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public orgSupplementaryData?: OrganisationSupplementaryData;
    public useCustomWelcomeEmail = false;
    public isEditorValid = true;

    private removedCustomMessage = "";

    public constructor(
        configurationService: ConfigurationService,
        private orgService: OrganisationService,
        private userManagementService: UserManagementService,
        private userService: UserService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        const currentOrgId = this.orgService.getOrganisationId();
        if (currentOrgId > 0) {
            const currentOrg = await lastValueFrom(this.orgService.getOrganisationWithSupplementaryData(currentOrgId));
            this.orgSupplementaryData = currentOrg?.supplementaryData;
            this.useCustomWelcomeEmail = !!this.orgSupplementaryData?.customWelcomeEmail;
        }
    }

    public onCustomMessageChecked(checked: boolean) {
        this.useCustomWelcomeEmail = checked;
        if (checked && !this.orgSupplementaryData!.customWelcomeEmail) {
            if (this.removedCustomMessage) {
                this.orgSupplementaryData!.customWelcomeEmail = this.removedCustomMessage;
            }
        }

        if (!checked && !!this.orgSupplementaryData!.customWelcomeEmail) {
            this.removedCustomMessage = this.orgSupplementaryData!.customWelcomeEmail;
            this.orgSupplementaryData!.customWelcomeEmail = undefined;
        }
    }

    @Autobind
    public previewEmail() {
        return this.userManagementService.getWelcomeEmailTemplate(
            this.userService.getCurrentPersonId()!,
            this.orgSupplementaryData!.customWelcomeEmail).pipe(
                switchMap((emailTemplate) => this.dialogService.open(PreviewEmailDialogComponent, emailTemplate)),
            );
    }
}
