import { Component, Inject } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DateFormats } from "@common/ux/date-formats";
import { tap } from "rxjs/operators";
import { CareerValuationService } from "../career-valuation.service";

export interface IEditCareerValuationDialogData {
    careerValuation: CareerValuation;
    previousCareerValuation?: CareerValuation;
    showingIdealRating?: boolean;
}

@Component({
    selector: "adapt-edit-cvt-dialog",
    templateUrl: "./edit-cvt-dialog.component.html",
    styleUrls: ["./edit-cvt-dialog.component.scss", "../career-valuation.scss"],
})
@PersistableDialog("EditCareerValuationDialog", (injector) => injector.get(CareerValuationService).promiseToWaitUntilOrganisationInitialised())
export class EditCareerValuationDialogComponent extends BaseDialogWithDiscardConfirmationComponent<CareerValuation> {
    public readonly dialogName = "EditCareerValuationDialog";
    public title: string;

    public careerValuation: CareerValuation;

    public totalIdeal: number;
    private totalIdealUpdater = this.createThrottledUpdater<number>((totalIdeal) => this.totalIdeal = totalIdeal);

    public showingIdealRating: boolean;
    public allowCopyPrevious: boolean;

    private previousCareerValuation?: CareerValuation;

    public dateFormat = DateFormats.globalize.short;

    public showQuestionArray: boolean[] = [];

    public saveButtonDisabled = false;
    public actualButtonDisabled = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditCareerValuationDialogData,
        protected commonDataService: CommonDataService,
    ) {
        super();

        this.careerValuation = data.careerValuation;
        this.previousCareerValuation = data.previousCareerValuation;
        this.totalIdeal = 0;
        this.showingIdealRating = true;
        this.careerValuation.categoryValues.forEach((cv) => this.setQuestionsVisibility(cv));
        // categoryValues should be in order of the category ordinal but this is not guaranteed when restoring
        this.careerValuation.categoryValues.sort((a, b) => a.category.ordinal - b.category.ordinal);

        const addMode = this.careerValuation.entityAspect.entityState.isAdded();
        this.title = (addMode ? "Add " : "Edit ") + "Career Valuation";

        this.activate();

        this.allowCopyPrevious = addMode && !!this.previousCareerValuation;
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.save().pipe(
            tap(() => this.resolve(this.careerValuation)),
        );
    }

    public get entitiesToConfirm() {
        return [
            this.careerValuation,
            ...this.careerValuation.categoryValues,
        ];
    }

    @Autobind
    public setPageChange() {
        this.showingIdealRating = !this.showingIdealRating;
        this.performPostPageChanged();
    }

    @Autobind
    public handleIdealCategoryValueChange() {
        this.totalIdealUpdater.next(this.careerValuation.extensions.idealTotal);
        this.careerValuation.categoryValues.forEach((cv) => {
            if (cv.actualValue > cv.idealValue) {
                cv.actualValue = cv.idealValue;
            }
        });

        this.handleEntityChanges();
    }

    @Autobind
    public toggleAccordion(categoryValue: CareerValuationCategoryValue) {
        this.showQuestionArray[categoryValue.careerValuationCategoryId] = !this.showQuestionArray[categoryValue.careerValuationCategoryId];
    }

    @Autobind
    public onContentsChange(entity: CareerValuation | CareerValuationCategoryValue, content: string) {
        entity.notes = content;
        this.handleEntityChanges();
    }

    @Autobind
    public handleEntityChanges() {
        this.saveButtonDisabled = this.careerValuation.entityAspect.hasValidationErrors
            || !this.careerValuation.extensions.isValid()
            || this.careerValuation.categoryValues.some((cv) => cv.entityAspect.hasValidationErrors || !cv.extensions.isEditorValid);

        this.actualButtonDisabled = this.careerValuation.extensions.idealTotal !== 100;
    }

    public setDate(date: string | number | Date) {
        this.careerValuation.creationDate = date as Date;
    }

    @Autobind
    private activate() {
        if (this.data.showingIdealRating !== undefined) {
            this.showingIdealRating = this.data.showingIdealRating!;
        } else {
            this.showingIdealRating = true;
        }

        this.performPostPageChanged();
        this.handleIdealCategoryValueChange();
    }

    @Autobind
    private performPostPageChanged() {
        this.handleEntityChanges();

        // move the scroll back to the top (as the dialog is quite long)
        window.scrollTo(0, 0);

        // record this so that it can be persisted if entities changed
        this.data.showingIdealRating = this.showingIdealRating;
    }

    private setQuestionsVisibility(categoryValue: CareerValuationCategoryValue) {
        this.showQuestionArray[categoryValue.careerValuationCategoryId] = false;
    }

    public copyPreviousValuationIdealValues() {
        if (this.previousCareerValuation) {
            this.careerValuation.categoryValues.forEach((cv) => this.copyIdealValue(cv));
        }
    }

    private copyIdealValue(currentValue: CareerValuationCategoryValue) {
        if (this.previousCareerValuation) {
            // Copy from previous if category id matches and it's not null
            for (const previousValue of this.previousCareerValuation.categoryValues) {
                if (currentValue.careerValuationCategoryId === previousValue.careerValuationCategoryId && previousValue.idealValue !== null) {
                    currentValue.idealValue = previousValue.idealValue;
                    break;
                }
            }
        }
    }

}
