import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class CulturalIndexConfiguration extends BaseEntity<CulturalIndexConfiguration> {
    public culturalIndexConfigurationId!: number;
    public organisationId!: number;
    public category1Label!: string;
    public category2Label!: string;
    public category3Label!: string;
    public category4Label!: string;

    public organisation!: Organisation;
}

export const CulturalIndexConfigurationBreezeModel = new BreezeModelBuilder("CulturalIndexConfiguration", CulturalIndexConfiguration)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();
