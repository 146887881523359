import { Injectable } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepType } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CopyProcessStepDialogComponent } from "./copy-process-step-dialog/copy-process-step-dialog.component";
import { DeleteProcessMapDialogComponent } from "./delete-process-map-dialog/delete-process-map-dialog.component";
import { DeleteProcessStepDialogComponent } from "./delete-process-step-dialog/delete-process-step-dialog.component";
import { EditProcessMapDialogComponent, IEditProcessMapDialogData } from "./edit-process-map-dialog/edit-process-map-dialog.component";
import { EditProcessStepDialogComponent } from "./edit-process-step-dialog/edit-process-step-dialog.component";
import { ProcessMapExporterDialogComponent } from "./exporter/process-map-exporter-dialog/process-map-exporter-dialog.component";
import { MoveProcessStepDialogComponent } from "./move-process-step-dialog/move-process-step-dialog.component";
import { ProcessMapService } from "./process-map.service";

@Injectable({
    providedIn: "root",
})
export class ProcessMapUiService {
    public constructor(
        private processMapService: ProcessMapService,
        private dialogService: AdaptCommonDialogService,
        private systemisationService: SystemisationService,
    ) {
    }

    public addProcessMap(system: SystemEntity) {
        return this.processMapService.createProcessMap().pipe(
            switchMap((processMap) => this.systemisationService.createSystemProcessMapComponent(system, processMap)),
            switchMap((systemComponent) => this.editProcessMap(systemComponent.systemProcessMap!, systemComponent)),
        );
    }

    public editProcessMap(processMap: ProcessMap, systemComponent?: SystemComponent) {
        const processMapSystemComponent$ = systemComponent
            ? of(systemComponent)
            : this.systemisationService.getSystemComponentForProcessMap(processMap.processMapId);
        return processMapSystemComponent$.pipe(
            switchMap((component) => this.systemisationService.getOrCreateSystemComponentSupplementaryData(component).pipe(map(() => component))),
            switchMap((component) => {
                const dialogData: IEditProcessMapDialogData = {
                    processMap,
                    systemComponent: component,
                };

                return this.dialogService.open(EditProcessMapDialogComponent, dialogData);
            }),
        );
    }

    public addProcessStep(parent: ProcessMap | ProcessStep, type: ProcessStepType) {
        return this.processMapService.createProcessStep(parent, type).pipe(
            switchMap((processStep) => this.editProcessStep(processStep)),
        );
    }

    public editProcessStep(processStep: ProcessStep) {
        return this.processMapService.getOrCreateProcessStepSupplementaryData(processStep).pipe(
            switchMap(() => this.dialogService.open(EditProcessStepDialogComponent, processStep)),
        );
    }

    public deleteProcessMap(processMap: ProcessMap) {
        return this.dialogService.open(DeleteProcessMapDialogComponent, processMap);
    }

    public deleteProcessStep(processStep: ProcessStep) {
        return this.dialogService.open(DeleteProcessStepDialogComponent, processStep);
    }

    public moveProcessStep(processStep: ProcessStep) {
        return this.dialogService.open(MoveProcessStepDialogComponent, processStep);
    }

    public copyProcessStep(processStep: ProcessStep) {
        return this.dialogService.open(CopyProcessStepDialogComponent, processStep);
    }

    public showProcessMapExporter(processMap: ProcessMap) {
        return this.dialogService.open(ProcessMapExporterDialogComponent, processMap);
    }
}
