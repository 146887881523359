<adapt-feature-guidance [configItem]="configItem">
    <p>Having a framework in place for cultural leadership enables the leaders to manage and nurture the organisation’s culture.
        Being accountable for regular catch-ups ensures that the purpose, vision and values are embedded and embodied in the organisation
        while also providing a network for change management and open and honest communications.</p>
    <p>Enabling this feature will allow you to record the cultural leaders and their cohorts in your organisation.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div *ngIf="configuration">
        <div>
            <!-- Catchup Frequency -->
            <div class="form-group">
                <label for="catchupFrequency">Catch-up frequency <small>(in months)</small></label>
                <dx-number-box id="catchupFrequency"
                               [adaptValidateEntity]="configuration"
                               adaptValidateEntityProperty="catchupFrequency"
                               [(value)]="configuration!.catchupFrequency"
                               [showSpinButtons]="true"
                               width="200px"></dx-number-box>
            </div>
        </div>

        <div>
            <h3>Labelling</h3>
            <div class="d-flex">
                <div>
                    <!-- Primary Name -->
                    <div class="form-group">
                        <label for="primaryName">Primary name</label>
                        <dx-text-box id="primaryName"
                                     [adaptValidateEntity]="configuration"
                                     adaptValidateEntityProperty="primaryName"
                                     [(value)]="configuration!.primaryName"
                                     (valueChange)="onPrimaryNameChange($event)"
                                     width="200px"></dx-text-box>
                    </div>

                    <!-- Primary Collection Name -->
                    <div class="form-group">
                        <label for="primaryCollectionName">Primary collection name</label>
                        <dx-text-box id="primaryCollectionName"
                                     [adaptValidateEntity]="configuration"
                                     adaptValidateEntityProperty="primaryCollectionName"
                                     [(value)]="configuration!.primaryCollectionName"
                                     width="200px"></dx-text-box>
                    </div>
                </div>

                <div class="ps-3">
                    <!-- Secondary Name -->
                    <div class="form-group">
                        <label for="secondaryName">Secondary name</label>
                        <dx-text-box id="secondaryName"
                                     [adaptValidateEntity]="configuration"
                                     adaptValidateEntityProperty="secondaryName"
                                     [(value)]="configuration!.secondaryName"
                                     width="200px"></dx-text-box>
                    </div>

                    <!-- Secondary Collection Name -->
                    <div class="form-group">
                        <label for="secondaryCollectionName">Secondary collection name</label>
                        <dx-text-box id="secondaryCollectionName"
                                     [adaptValidateEntity]="configuration"
                                     adaptValidateEntityProperty="secondaryCollectionName"
                                     [(value)]="configuration!.secondaryCollectionName"
                                     width="200px"></dx-text-box>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <h3>Cultural Leader permissions</h3>
            In their own cohort, they can see all quantitative (numerical) and qualitative (written) data.
            <br />
            Configure the level of access a cultural leader can see in OTHER cohorts.

            <dx-radio-group class="pt-2"
                            [items]="culturalAccessSelections"
                            [(value)]="culturalAccess"
                            (valueChange)="accessChanged()"
                            valueExpr="value"
                            displayExpr="text"></dx-radio-group>
        </div>

        <div class="mt-3">
            <h3>Peer Catch-up permissions</h3>
            Configure the level of access a cultural leader has for the organisation.

            <dx-radio-group class="pt-2"
                            [items]="pcuAccessSelections"
                            [(value)]="pcuAccess"
                            (valueChange)="accessChanged()"
                            valueExpr="value"
                            displayExpr="text"></dx-radio-group>
        </div>
    </div>
</adapt-feature-configuration>
