<form>
    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="team">Team</label>
        <adapt-select-team id="team"
                           [team]="filterParameters.team"
                           (teamChange)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.Team, $event, $event?.teamId)"
                           [required]="false"
                           [activeOnly]="true"
                           data-test="team-filter"></adapt-select-team>
    </div>

    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="culturalLeader">Cultural Leader</label>
        <adapt-select-person id="culturalLeader"
                             [person]="filterParameters.culturalLeader"
                             (personChange)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.CulturalLeader, $event, $event?.personId)"
                             [activeOnly]="false"
                             [filter]="culturalLeaderFilter"
                             [userTypes]="allowedCLUserTypes"
                             data-test="cultural-leader-filter"></adapt-select-person>
    </div>

    <div class="form-group"
         *ngIf="hasAtLeastQuantitativePermissions">
        <label for="profileItem">Profile Item</label>
        <adapt-select-profile-item [profileItem]="filterParameters.profileItem"
                                   (profileItemChange)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.ProfileItem, $event, $event?.personProfileItemId)"></adapt-select-profile-item>
        <div class="profile-item-gap"></div>
        <adapt-select-profile-item-value *ngIf="filterParameters.profileItem"
                                         [profileItem]="filterParameters.profileItem"
                                         [value]="filterParameters.profileItemValue"
                                         (valueChange)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.ProfileItemValue, $event, $event?.personProfileItemValueId)"></adapt-select-profile-item-value>
    </div>

    <fieldset>
        <legend>Rating</legend>
        <dx-check-box data-test="very-happy-checkbox"
                      class="d-block"
                      [text]="culturalIndexConfiguration?.category1Label"
                      id="inputCategory1"
                      [value]="filterParameters.category1"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category1, $event.value, $event.value)">
        </dx-check-box>
        <dx-check-box data-test="happy-checkbox"
                      class="d-block"
                      [text]="culturalIndexConfiguration?.category2Label"
                      id="inputCategory2"
                      [value]="filterParameters.category2"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category2, $event.value, $event.value)">
        </dx-check-box>
        <dx-check-box class="d-block"
                      [text]="culturalIndexConfiguration?.category3Label"
                      id="inputCategory3"
                      [value]="filterParameters.category3"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category3, $event.value, $event.value)">
        </dx-check-box>
        <dx-check-box class="d-block"
                      [text]="culturalIndexConfiguration?.category4Label"
                      id="inputCategory4"
                      [value]="filterParameters.category4"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.Category4, $event.value, $event.value)">
        </dx-check-box>
    </fieldset>

    <fieldset *ngIf="hasAtLeastQuantitativePermissions">
        <legend>People</legend>
        <label for="connectionStatus"
               class="form-check-label">Connection Status</label>
        <dx-select-box [items]="connectionStatusOptions"
                       [value]="filterParameters.connectionStatus"
                       (onValueChanged)="onConnectionStatusChanged($event)"
                       displayExpr="label"
                       valueExpr="value"
                       data-test="connection-status-filter"></dx-select-box>
        <dx-check-box id="inputNoCL"
                      text="Show people with no Cultural Leader"
                      [value]="filterParameters.showPeopleWithoutCulturalLeader"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowPeopleWithoutCulturalLeader, $event.value, $event.value)"
                      data-test="cultural-leader-checkbox">
        </dx-check-box>
        <dx-check-box id="inputOverdueOnly"
                      text="Show overdue catch-ups only"
                      [value]="filterParameters.showOverdueOnly"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowOverdueOnly, $event.value, $event.value)">
        </dx-check-box>
        <dx-check-box id="inputTrendOutwardsOnly"
                      text="Show people trending outwards"
                      [value]="filterParameters.showTrendOutwardsOnly"
                      (onValueChanged)="culturalIndexFilterService.emitFilterChange(CareerValuationFilterParamKeys.ShowTrendOutwardsOnly, $event.value, $event.value)">
        </dx-check-box>
    </fieldset>
</form>
