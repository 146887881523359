<ng-container *ngIf="!!system; else noSystemContent">
    <ng-container *ngIf="isEditSystemPage; else notEditSystemPage">
        <adapt-toolbar-title>
            <div class="d-flex flex-row align-items-center">
                <h2>{{system!.name}}</h2>
                <adapt-display-labels *ngIf="system!.labelLocations?.length"
                                      class="ms-2"
                                      [labelLocations]="system!.labelLocations"></adapt-display-labels>
                <button *ngIf="isEditing"
                        class="ms-1"
                        [disabled]="layoutManager?.isEditing"
                        adaptButton="editBorderless"
                        (click)="editSystem().subscribe()"></button>
            </div>
        </adapt-toolbar-title>

        <div adaptToolbarContent
             [ordinal]="1000"
             *ngIf="canEdit$ | async">
            <adapt-menu [items]="configureSystemMenu"
                        [disabled]="layoutManager?.isEditing"></adapt-menu>
        </div>
        <div adaptToolbarContent
             [ordinal]="900"
             *ngIf="canEdit$ | async"
             class="d-flex align-items-center">
            <adapt-system-component-add-menu *ngIf="isEditing"
                                             [ngClass]="{'pulsing-icon': systemComponents.length < 1}"
                                             class="system-component-add-menu"
                                             [system]="system"
                                             [disabled]="layoutManager?.isEditing"
                                             (systemComponentAdded)="onSystemComponentAdded($event)">
            </adapt-system-component-add-menu>
        </div>
    </ng-container>
    <ng-template #notEditSystemPage>
        <h3 class="mb-3 mt-n3 d-flex align-items-center"
            *ngIf="showTitle">

            <adapt-link-system [system]="system"
                               [showNavIcon]="true"
                               [showLabels]="true"
                               data-test="system-link"></adapt-link-system>
        </h3>
    </ng-template>

    <ng-container *adaptLoading="!isInitialised">
        <adapt-layout-manager innerClass="h-100 m-0"
                              [showEditLayoutButton]="false"
                              [allowEditing]="isEditing && (canEdit$ | async) && systemComponents.length > 0"
                              [entity]="system"
                              (initialised)="onLayoutManagerInitialised()"
                              *adaptRerender="editingDetailsRerender">
            <adapt-layout-column class="col-md-12 bg-white">
                <adapt-layout-component *ngFor="let systemComponent of systemComponents"
                                        id="system-component-{{systemComponent.systemComponentId}}"
                                        class="mb-4">
                    <adapt-system-component [systemComponent]="systemComponent"
                                            [isEditing]="isEditing"
                                            [isNewlyAdded]="systemComponent.systemComponentId === addedComponentId"
                                            (newComponentAnimated)="onNewComponentAnimated($event)"
                                            [processMapState]="getProcessMapState(systemComponent)"
                                            (processMapStateChange)="onProcessMapStateChanged($event)"
                                            (systemComponentDeleted)="triggerComponentUpdate$.next()"
                                            (componentCopied)="onSystemComponentAdded($event)"></adapt-system-component>
                </adapt-layout-component>
                <adapt-layout-component id="system-owners"
                                        class="col-md-6">
                    <div *adaptLayoutComponentEnabled="system!.ownerId && systemComponents.length > 0"
                         class="d-flex flex-column mb-3">
                        <div class="d-flex flex-row align-items-baseline mb-2">
                            <h3 class="document-title">System owner</h3>
                            <button *ngIf="isEditing"
                                    class="ms-1"
                                    adaptButton="editBorderless"
                                    (click)="editSystem().subscribe()"
                                    data-test="system-edit"></button>
                        </div>
                        <adapt-link-person class="mb-2"
                                           [personId]="system!.ownerId"
                                           [showImage]="true">
                        </adapt-link-person>
                    </div>
                </adapt-layout-component>
                <adapt-layout-component id="system-knowledge-experts"
                                        class="col-md-6">
                    <div *adaptLayoutComponentEnabled="system!.systemKnowledgeExperts.length > 0 && systemComponents.length > 0"
                         class="d-flex flex-column mb-3">
                        <div class="d-flex flex-row align-items-baseline mb-2">
                            <h3 class="document-title">Knowledge experts</h3>
                            <button *ngIf="isEditing"
                                    class="ms-1"
                                    adaptButton="editBorderless"
                                    (click)="editSystem().subscribe()"></button>
                        </div>
                        <div class="grid-layout">
                            <adapt-link-person *ngFor="let systemKnowledgeExpert of system!.systemKnowledgeExperts"
                                               class="mb-2"
                                               [personId]="systemKnowledgeExpert.knowledgeExpertId"
                                               [showImage]="true"></adapt-link-person>
                        </div>
                    </div>
                </adapt-layout-component>
                <ng-container *ngIf="systemComponents.length < 1">
                    <adapt-layout-component id="call-to-action"
                                            class="h-100">
                        <ng-container *ngTemplateOutlet="noComponentCTA"></ng-container>
                    </adapt-layout-component>
                </ng-container>

                <adapt-layout-component id="system-locations"
                                        class="pt-3 mb-4">
                    <div *adaptLayoutComponentEnabled="hasLocations"
                         class="d-flex flex-column">

                        <ng-container *ngIf="otherKeyFunctionSystems.length > 0">
                            <div class="mb-3 d-flex flex-column"
                                 *adaptIfAuthorised="ReadTier1">
                                <h3 class="document-title">Related key functions</h3>
                                <div class="grid-layout">
                                    <adapt-link-key-function *ngFor="let systemKeyFunction of otherKeyFunctionSystems"
                                                             class="mb-2"
                                                             [keyFunction]="systemKeyFunction.keyFunction">
                                    </adapt-link-key-function>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="otherTeamSystems.length > 0">
                            <div class="mb-3 d-flex flex-column">
                                <h3 class="document-title">Related teams</h3>
                                <div class="grid-layout">
                                    <adapt-link-team *ngFor="let systemTeam of otherTeamSystems"
                                                     class="mb-2"
                                                     [team]="systemTeam.team"
                                                     [showIcon]="true">
                                    </adapt-link-team>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="otherRoleSystems.length > 0">
                            <div class="mb-3 d-flex flex-column">
                                <h3 class="document-title">Related roles</h3>
                                <div class="grid-layout">
                                    <adapt-link-role *ngFor="let systemRole of otherRoleSystems"
                                                     class="mb-2"
                                                     [role]="systemRole.role"
                                                     [showIcon]="true">
                                    </adapt-link-role>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="zoneSystems.length > 0">
                            <div class="mb-3 d-flex flex-column">

                                <h3 class="document-title">Related zones</h3>
                                <div class="grid-layout">
                                    <adapt-zone-label *ngFor="let zoneLocation of zoneSystems"
                                                      class="mb-2"
                                                      [zone]="zoneLocation.zone"></adapt-zone-label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </adapt-layout-component>
            </adapt-layout-column>
        </adapt-layout-manager>
    </ng-container>

    <ng-template #noComponentCTA>
        <div *ngIf="!isEditSystemPage; else showCTA">
            <ng-container [ngTemplateOutlet]="systemContentHelpText"></ng-container>
            <button adaptCallToActionButton
                    adaptButtonIcon="navigateTo"
                    [adaptBlockingClick]="gotoSystemPage"
                    [adaptBlockingClickParam]="system">
                Define the system
            </button>
        </div>
        <ng-template #showCTA>
            <adapt-call-to-action [fullPage]="true">
                <ng-container [ngTemplateOutlet]="systemContentHelpText"></ng-container>
            </adapt-call-to-action>
        </ng-template>

        <ng-template #systemContentHelpText>
            <div class="d-print-none">
                <h3 class="mt-2 mb-3">No elements have been defined for this system yet.</h3>
                <p>When describing your system, use elements that will make it easy for people to understand and implement the system.</p>
                <p>To do this, use any of the available system element types:</p>
                <ul class="list-content">
                    <li>Texts</li>
                    <li>Diagrams</li>
                    <li>Task Maps</li>
                    <li>Documents</li>
                    <li>Videos</li>
                </ul>
            </div>
        </ng-template>
    </ng-template>
</ng-container>
<ng-template #noSystemContent>
    <div class="alert alert-info">
        No system selected
    </div>
</ng-template>
