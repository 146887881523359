<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Deleting guidance material</h3>
    <div adapt-dialog-content>
        <adapt-display-guidance-material-usage [guidanceMaterial]="guidanceMaterial"
                                               (isUsed)="isUsed = $event"></adapt-display-guidance-material-usage>
        <ng-container *ngIf="isUsed; else notUsed">
            <p class="mt-3">Deleting "{{guidanceMaterial.name}}" will remove it from the above tasks.</p>
        </ng-container>
        <ng-template #notUsed>
            <p *ngIf="isUsed === false"
               class="mt-3">Deleting "{{guidanceMaterial.name}}" will not affect any tasks.</p>
        </ng-template>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="deleteAndClose"
                                   [saveIsDisabled]="isUsed === undefined"
                                   (saveClick)="resolve(guidanceMaterial)"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
