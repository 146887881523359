import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { lastValueFrom } from "rxjs";
import { ProcessMapService } from "../../process-map.service";

export interface IRolePersonAllocations {
    [roleId: number]: Person | undefined;
    roles: Role[];
}

@Component({
    selector: "adapt-role-person-allocations-step",
    templateUrl: "./role-person-allocations-step.component.html",
    styleUrls: ["../exporter-style.scss"],
})
export class RolePersonAllocationsStepComponent extends BaseComponent implements OnInit {
    @Input() public processMap!: ProcessMap;
    @Input() public board?: Board;
    @Input() public loadingData = false;
    @Input() public rolePersonAllocations: IRolePersonAllocations = {
        roles: [],
    };
    @Output() public rolePersonAllocationsChange = new EventEmitter<IRolePersonAllocations>();
    @Output() public loadingDataChange = new EventEmitter<boolean>();

    public allRoles: Role[] = [];

    constructor(
        private processMapService: ProcessMapService,
        private kanbanAuthService: KanbanAuthService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.loadingDataChange.emit(true);

        const childSteps = await lastValueFrom(this.processMapService.getProcessMapSteps(this.processMap));

        await this.processMapService.recurseForRoleTasks(
            childSteps,
            (pm) => this.processMapService.getProcessMapSteps(pm),
            (step) => step.role && this.addRoleIfNotExists(step.role),
        );

        this.loadingDataChange.emit(false);

        this.rolePersonAllocations.roles = this.allRoles;
        for (const role of this.rolePersonAllocations.roles) {
            const assignPerson = this.rolePersonAllocations[role.roleId];
            if (assignPerson && !this.filterPerson(assignPerson)) {
                this.rolePersonAllocations[role.roleId] = undefined;
            }
        }

        this.rolePersonAllocationsChange.emit(this.rolePersonAllocations);
    }

    public onRolePersonChanged(roleId: number, person?: Person) {
        this.rolePersonAllocations[roleId] = person;
        this.rolePersonAllocationsChange.emit(this.rolePersonAllocations);
    }

    @Autobind
    public filterPerson(person: Person) {
        if (this.board) {
            return this.kanbanAuthService.personCanEditBoard(person, this.board);
        } else {
            // no board specified -> not filtering out person
            return true;
        }
    }

    private addRoleIfNotExists(role: Role) {
        if (this.allRoles.indexOf(role) < 0) {
            this.allRoles.push(role);
        }
    }
}
