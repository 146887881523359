<div class="activity-feed-date-group"
     *ngIf="dateGroup">
    <span class="activity-group-date">{{dateGroup!.date | adaptMoment:dateFormat}}</span>

    <dx-tree-view [dataSource]="dateGroup!.activity"
                  keyExpr="id"
                  itemsExpr="children"
                  [hoverStateEnabled]="false"
                  [activeStateEnabled]="false"
                  [focusStateEnabled]="false">
        <div *dxTemplate="let activity of 'item'"
             class="activity-feed-instance-container">
            <div class="activity-feed-instance-indenter"></div>
            <adapt-activity-instance class="activity-item"
                                     [activityElement]="activity"></adapt-activity-instance>
        </div>
    </dx-tree-view>
</div>
