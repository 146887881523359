<adapt-dialog [maxWidth]="processStep.extensions.isProcessMapLink ? 400 : 800"
              [padding]="!tabPanelItems">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content
         class="h-100 d-flex"
         *ngIf="!processStep.extensions?.isProcessMapLink; else itemLinkDialogContentTemplate">
        <ng-container *ngIf="!tabPanelItems; else editRoleStep">
            <ng-container *ngTemplateOutlet="stepDetails"></ng-container>
        </ng-container>
        <ng-template #editRoleStep>
            <dx-tab-panel class="edit-process-step-tabs"
                          [items]="tabPanelItems"
                          tabsPosition="left"
                          [swipeEnabled]="false">
                <div *dxTemplate="let item of 'detailsTemplate'">
                    <ng-container *ngTemplateOutlet="stepDetails"></ng-container>
                </div>
                <div *dxTemplate="let item of 'guidanceQuestionsTemplate'">
                    <div class="alert alert-guidance">
                        <p>
                            Guidance questions are a mechanism for providing advice and inspiration on how a role task could be performed.
                            It is a way of capturing further detail for a role task without encroaching on the autonomy of the people
                            performing the role.
                        </p>
                        <p>
                            We recommend that you use a semantic framework of reflective guidance questions that a person performing a role
                            can use to
                            increase the likelihood of performing the role task well. You should begin each guidance question with "Did you
                            ..." to
                            specify possible actions that could be considered.
                        </p>
                    </div>
                    <adapt-html-editor *ngIf="processStep.supplementaryData"
                                       [(contents)]="processStep.supplementaryData!.guidanceQuestions"
                                       [minHeight]="120"
                                       (isValidChange)="isGuidanceQuestionsEditorValid = $event"
                                       data-test="process-step-textarea"></adapt-html-editor>
                </div>
                <div *dxTemplate="let item of 'guidanceMaterialsTemplate'">
                    <div class="alert alert-guidance">
                        <p>
                            Identify the guidance materials that may assist people to perform this task.
                        </p>
                        <p>
                            Guidance materials are documents, videos, wiki pages, etc that are stored externally
                            to embedADAPT but may be useful to guide people on how to perform tasks in the organisation.
                        </p>
                        <p>
                            Select from the list of existing guidance materials or add a new one.
                        </p>
                    </div>
                    <adapt-select-process-step-guidance-material [processStep]="processStep"
                                                                 (selectionsChange)="guidanceMaterialChanged($event)">
                    </adapt-select-process-step-guidance-material>
                </div>
            </dx-tab-panel>
        </ng-template>
        <ng-template #stepDetails>
            <div class="row">
                <div class="col-12 col-md-6 form-group">
                    <label>Name</label>
                    <dx-text-box #focus
                                 [(value)]="processStep.name"
                                 [adaptValidateEntity]="processStep"
                                 adaptValidateEntityProperty="name"
                                 data-test="process-step-name-input"></dx-text-box>
                </div>

                <div class="col-12 col-md-6 form-group"
                     *ngIf="processStep.extensions.isRoleTask">
                    <label>Allocated role</label>
                    <adapt-select-role [(role)]="processStep.role"
                                       [filter]="roleFilter"
                                       [required]="false"
                                       data-test="process-step-allocated-role-input"></adapt-select-role>
                </div>

                <div class="col-12 col-md-6 form-group">
                    <label>Labels</label>
                    <adapt-select-label-for-related-entity [processStep]="processStep"
                                                           (entitiesChange)="onLabelLocationsChanged($event)">
                    </adapt-select-label-for-related-entity>
                </div>

                <div class="col-12 form-group">
                    <label>Description</label>
                    <!-- ngIf so we don't get errors when cancelling new supp data -->
                    <adapt-html-editor *ngIf="processStep.supplementaryData"
                                       [(contents)]="processStep.supplementaryData.description"
                                       [minHeight]="120"
                                       (isValidChange)="isDescriptionEditorValid = $event"
                                       data-test="process-step-description"></adapt-html-editor>
                </div>
            </div>
        </ng-template>
    </div>

    <ng-template #itemLinkDialogContentTemplate
                 adapt-dialog-content>
        <div class="form-group">
            <label>Linked task map</label>
            <adapt-select-process-map [processMap]="processStep.linkedProcessMap"
                                      (processMapChange)="setLinkedProcessMap($event)"
                                      [filter]="isNotCurrentProcessMap"
                                      data-test="select-process-map-input"></adapt-select-process-map>
        </div>
    </ng-template>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isDescriptionEditorValid || !isGuidanceQuestionsEditorValid"
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
