import { Component, Injector, OnInit } from "@angular/core";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ResilientBusinessGoalsAuthService } from "../rb-goals-auth.service";
import { ResilientBusinessGoalsUiService } from "../rb-goals-ui.service";

@Component({
    selector: "adapt-resilient-business-goals-page",
    templateUrl: "./rb-goals-page.component.html",
})
export class ResilientBusinessGoalsPageComponent extends BaseRoutedComponent implements OnInit {
    public editRbGoalsAccessVerifier = ResilientBusinessGoalsAuthService.EditResilientBusinessGoals;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(ImplementationKitArticle.ResilientBusinessGoals);

    public constructor(
        private resilientBusinessGoalsUiService: ResilientBusinessGoalsUiService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public editResilientBusinessGoals() {
        return this.resilientBusinessGoalsUiService.editResilientBusinessGoals();
    }
}
