<dx-circular-gauge [value]="averageCareerValuation"
                   [rangeContainer]="rangeContainer"
                   (onInitialized)="onInitialized($event)">
    <dxo-scale [startValue]="0"
               [endValue]="100"
               [tickInterval]="10">
        <dxo-label [useRangeColors]="true"></dxo-label>
    </dxo-scale>
    <dxo-tooltip [enabled]="true">
        <dxo-format type="fixedPoint"
                    [precision]="1"></dxo-format>
    </dxo-tooltip>
</dx-circular-gauge>
