import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";
import { ActivityElement } from "app/general/activity-feed/activity-feed-manager/activity-element";
import { ActivityGroup } from "app/general/activity-feed/activity-feed-manager/activity-group";
import { ActivityInstance } from "app/general/activity-feed/activity-feed-manager/activity-instance";
import moment from "moment";
import { interval } from "rxjs";

interface IActivityInstanceChangesObj extends SimpleChanges {
    activityInstance: ITypedSimpleChange<ActivityInstance>;
}

@Component({
    selector: "adapt-activity-instance",
    templateUrl: `./activity-instance.component.html`,
    styleUrls: ["./activity-instance.component.scss"],
})
export class ActivityInstanceComponent extends BaseComponent implements AfterViewInit, OnChanges {
    @Input() public activityElement?: ActivityElement;

    public occurredLessThanAnHourAgo = false;

    public launchPathObject?: IAdaptLinkObject;

    // Removing this causes test failures, I think because it tries to resolve
    // the parameters in the base class with DI, which doesn't work
    public constructor(private routeService: RouteService) {
        super();
    }

    public ngAfterViewInit() {
        // Since we're only looking at a granularity of an hour, 5 minutes is a good coarse time period to check
        interval(300000).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(this.updateInstanceTimeDiff);
    }

    public ngOnChanges(changesObj: IActivityInstanceChangesObj) {
        if (changesObj.activityElement && changesObj.activityElement.currentValue) {
            this.updateInstanceTimeDiff();
            if (this.activityInstance?.launchPath) {
                this.launchPathObject = this.routeService.parseRouterObject(this.activityInstance.launchPath);
            } else {
                this.launchPathObject = undefined;
            }
        }
    }

    public get activityInstance() {
        return this.activityElement instanceof ActivityInstance
            ? this.activityElement
            : undefined;
    }

    public get activityGroup() {
        return this.activityElement instanceof ActivityGroup
            ? this.activityElement
            : undefined;
    }

    @Autobind
    private updateInstanceTimeDiff() {
        if (!this.activityElement) {
            return;
        }

        this.occurredLessThanAnHourAgo = moment().diff(this.activityElement.dateTime, "hours") < 1;
    }
}
