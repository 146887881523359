import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamBusinessModel } from "@common/ADAPT.Common.Model/organisation/value-stream-business-model";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { CallToActionText } from "@org-common/lib/call-to-action/call-to-action-text";
import { GuidingPhilosophyService } from "@org-common/lib/guiding-philosophy/guiding-philosophy.service";
import { organisationMapPageRoute } from "app/organisation/organisation-map-page/organisation-map-page.route";
import { lastValueFrom, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { DisplayRoleAllocationComponent } from "../../role/display-role-allocation/display-role-allocation.component";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { Tier1ArchitectureUiService } from "../../tier1-architecture/tier1-architecture-ui.service";
import { valueStreamBusinessModelPageRoute } from "../../tier1-architecture/value-stream-business-model-page/value-stream-business-model-page.route";

@Component({
    selector: "adapt-value-stream-dashboard-page",
    styleUrls: ["./value-stream-dashboard-page.component.scss", "../value-stream-common-styles.scss"],
    templateUrl: "./value-stream-dashboard-page.component.html",
})
export class ValueStreamDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public valueStream?: ValueStream;
    public businessModel$?: Observable<ValueStreamBusinessModel | undefined>;
    public keyFunctions$?: Observable<KeyFunction[]>;
    public primePurpose$?: Observable<Purpose | undefined>;
    public leaderRole$?: Observable<Role | undefined>;

    public commercialModelUrl?: string;
    public offeringPartColumnClasses = "offering-part col-lg-4";

    public readonly editValueStreamContents = Tier1ArchitectureAuthService.EditValueStreamContents;
    public readonly keyFunctionRouteUrl = organisationMapPageRoute.getRoute();
    public readonly CallToActionText = CallToActionText;

    @ViewChild(DisplayRoleAllocationComponent)
    private displayRoleAllocationComponent!: DisplayRoleAllocationComponent;

    public constructor(
        injector: Injector,
        private tier1Service: Tier1ArchitectureService,
        private tier1UiService: Tier1ArchitectureUiService,
        private guidingPhilosophyService: GuidingPhilosophyService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.navigationEnd.subscribe(() => this.updateData());
        await this.updateData();
    }

    private async updateData() {
        const valueStreamId = this.getRouteParamInt("valueStreamId");
        if (!valueStreamId) {
            this.log.warn("Invalid Value Stream");
            return this.tier1Service.gotoDefaultRoute();
        }

        this.valueStream = await lastValueFrom(this.tier1Service.getValueStreamById(valueStreamId));
        if (!this.valueStream) {
            this.log.warn("Invalid Value Stream");
            return this.tier1Service.gotoDefaultRoute();
        }

        // prime
        this.leaderRole$ = this.tier1Service.getLeaderRoleForValueStreamId(this.valueStream.valueStreamId);
        this.primePurpose$ = this.guidingPhilosophyService.getPurposeByValueStreamId(this.valueStream.valueStreamId);

        this.businessModel$ = this.tier1Service.getValueStreamBusinessModelForValueStreamId(valueStreamId);
        this.keyFunctions$ = this.tier1Service.getPrimedValueStreamKeyFunctions(this.valueStream);

        this.commercialModelUrl = await this.routeService.getControllerRoute(valueStreamBusinessModelPageRoute.id, this.valueStream);

        this.notifyActivated();
    }

    @Autobind
    public editValueStream() {
        return this.tier1UiService.editValueStream(this.valueStream!).pipe(
            finalize(() => this.displayRoleAllocationComponent.roleConnectionsUpdated()),
        );
    }
}
