import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { CareerValuation } from "./career-valuation";
import { CareerValuationCategory } from "./career-valuation-category";
import { CareerValuationCategoryValueExtensions } from "./career-valuation-category-value-extensions";

export class CareerValuationCategoryValue extends BaseEntity<CareerValuationCategoryValue> {
    public careerValuationCategoryValueId!: number;
    public careerValuationId!: number;
    public careerValuationCategoryId!: number;
    public idealValue!: number;
    public actualValue!: number;
    public notes!: string;
    public careerValuation!: CareerValuation;
    public category!: CareerValuationCategory;

    @EntityExtensions(CareerValuationCategoryValueExtensions)
    public extensions!: CareerValuationCategoryValueExtensions;
}

export const CareerValuationCategoryValueBreezeModel = new BreezeModelBuilder("CareerValuationCategoryValue", CareerValuationCategoryValue)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .withSortField("category.ordinal")
    .persistChangedEntity()
    .withActivePath("careerValuation.person.connections")
    .withGroupByField("careerValuation.personId", "careerValuationCategoryId")
    .withDateField("careerValuation.creationDate")
    .build();
