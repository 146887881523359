import { Component, OnDestroy, OnInit } from "@angular/core";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { organisationMapPageRoute } from "app/organisation/organisation-map-page/organisation-map-page.route";
import { Observable } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

@Component({
    selector: "adapt-display-value-stream-summaries-dashboard-element",
    templateUrl: "./display-vs-summaries-dashboard-element.component.html",
    styleUrls: ["./display-vs-summaries-dashboard-element.component.scss"],
})
export class DisplayValueStreamSummariesDashboardElementComponent extends BaseComponent implements OnInit, OnDestroy {
    public valueStreams$: Observable<ValueStream[]>;
    public organisationMapPageHref$: Observable<string>;

    public constructor(
        private tier1Service: Tier1ArchitectureService,
        private shellUiService: ShellUiService,
    ) {
        super();
        this.valueStreams$ = this.tier1Service.getActiveValueStreams();
        this.organisationMapPageHref$ = organisationMapPageRoute.getRoute();
    }

    public ngOnInit() {
        // remove padding as this is used on the org dashboard, where every page is a two-column without padding.
        // will stop the page jumping around when switching between tabs.
        this.shellUiService.removeDefaultShellPadding();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.shellUiService.resetToDefaultPadding();
    }
}
