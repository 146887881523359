import { Component, Input } from "@angular/core";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { PersonService } from "@org-common/lib/person/person.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-teams",
    templateUrl: "./person-profile-teams.component.html",
})
export class PersonProfileTeamsComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    public person$ = new BehaviorSubject<Person | undefined>(undefined);
    public teams$: Observable<Team[]>;
    public hasActiveConnection$: Observable<boolean>;

    constructor(
        private personService: PersonService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const entityChange$ = rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            startWith(undefined),
        );

        const setPerson$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
        );

        this.hasActiveConnection$ = combineLatest([entityChange$, setPerson$]).pipe(
            map(([_change, person]) => person.isActive()),
        );

        this.teams$ = combineLatest([entityChange$, setPerson$]).pipe(
            switchMap(([_change, person]) => this.personService.getActiveTeams(person.personId)),
        );
    }
}
