<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Delete Key Function</h3>

    <ng-container adapt-dialog-content
                  *adaptLoadingUntilFirstEmit="let teams of teams$">
        <div class="alert alert-info">
            By deleting the {{keyFunction.name}} key function you will:
            <ul>
                <ng-container *ngIf="(keyFunctionTeams$ | async) as keyFunctionTeams">
                    <li *ngIf="keyFunctionTeams.length > 0">
                        Remove the following teams from being associated with this key function
                        <ul class="mb-1">
                            <li *ngFor="let team of keyFunctionTeams">
                                <adapt-link-team [team]="team"></adapt-link-team>
                            </li>
                        </ul>
                    </li>
                </ng-container>
                <ng-container *ngIf="teams.length > 0">
                    <li>Move the following teams to the global teams section as they are only located in this key function
                        <ul>
                            <li *ngFor="let team of teams">
                                <adapt-link-team [team]="team"></adapt-link-team>
                            </li>
                        </ul>
                    </li>
                </ng-container>
                <ng-container *ngIf="(keyFunctionRoles$ | async) as keyFunctionRoles">
                    <li *ngIf="keyFunctionRoles.length > 0">
                        Remove the following roles from being associated with this key function
                        <ul class="mb-1">
                            <li *ngFor="let keyFunctionRole of keyFunctionRoles">
                                <adapt-link-role [role]="keyFunctionRole.role"></adapt-link-role>
                            </li>
                        </ul>
                    </li>
                </ng-container>
                <li>Delete the following leader role and any role history from past and present leaders
                    <div class="ms-3 mb-1">
                        <adapt-link-role [role]="keyFunction.leaderRole"></adapt-link-role>
                    </div>
                    <ng-container *ngIf="hasRelatedProcessMap">
                        The role tasks assigned to the leader role from the following task maps will no longer be assigned
                        <adapt-related-process-maps [role]="keyFunction.leaderRole"></adapt-related-process-maps>
                    </ng-container>
                </li>
            </ul>
        </div>
        <dx-check-box [value]="confirmed"
                      (valueChange)="confirmed = $any($event)"
                      text="Check to confirm that you understand that this action cannot be undone "></dx-check-box>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="deleteAndClose"
                                   [saveIsDisabled]="!confirmed"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
