import { IHelpTourConfig } from "@common/shell/help-tour/help-tour.service";

export const BasicEmbedTour: IHelpTourConfig = {
    name: "basicEmbedTour",
    iframe: {
        src: "https://www.youtube-nocookie.com/embed/7O2VJzGnPUc?autoplay=1&rel=0&modestbranding=1",
        width: 1920,
        height: 1080,
    },
    title: "Welcome to embedADAPT",
    content: `<p>The adapt platform is designed to capture the data needed to keep your organisation's strategy on track, aligning everyone to the purpose,
    vision and values, and enabling informed decisions to be made based on real data.</p>
        <p>The video below outlines some of the major components of the embedADAPT interface, to aid you in using the platform to its potential.</p>`,
    thumbnail: "/content/ux/content-with-splash-image/assets/splash-image-2.jpg",
};
