import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CareerValuationAuthService } from "../career-valuation-auth.service";

export enum CareerValuationViewType {
    Statistics,
    LatestChart,
    Grid,
}

@Component({
    selector: "adapt-analyse-cvt-organisation",
    templateUrl: "./analyse-cvt-organisation.component.html",
    styleUrls: ["./analyse-cvt-organisation.component.scss"],
})
export class AnalyseCareerValuationOrganisationComponent extends BaseComponent implements OnInit {
    public date = new Date();
    @Output() public dateChange = new EventEmitter<Date>();

    public viewType: CareerValuationViewType = CareerValuationViewType.Statistics;
    public hasAtLeastQuantitativePermissions: boolean;

    public careerValuationViewType = CareerValuationViewType;

    public today = new Date();

    public constructor(
        careerValuationAuthService: CareerValuationAuthService,
    ) {
        super();
        this.hasAtLeastQuantitativePermissions = careerValuationAuthService.currentPersonHasAtLeastQuantitativeAccess();
    }

    public ngOnInit() {
        this.setViewType(this.careerValuationViewType.Statistics);
    }

    public setViewType(viewSelection: CareerValuationViewType) {
        this.viewType = viewSelection;
    }

    @Autobind
    public onDateChanged(date: Date) {
        this.date = date;
        this.dateChange.next(this.date);
    }
}
