<adapt-feature-guidance [configItem]="configItem">
    <p>Employee engagement provides a way for leaders to anonymously capture the engagement of employees at a point in time.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem"
                             (featureChange)="currentStatus = $event"></adapt-feature-configuration>

<adapt-display-impact-surveys [hasChanges]="configurationService.hasChanges()"
                              [currentFeatureStatus]="currentStatus"
                              [surveyType]="surveyType"></adapt-display-impact-surveys>
