
import { Component, Input } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { culturalIndexDashboardRoute, myCulturalIndexDashboardRoute } from "../ci-dashboard-page/ci-dashboard-page.route";
import { CulturalIndexService } from "../cultural-index.service";

@Component({
    selector: "adapt-ci-personal-dashboard-element",
    templateUrl: "./ci-personal-dashboard-element.component.html",
})
export class CulturalIndexPersonalDashboardElementComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    private person$ = new ReplaySubject<Person | undefined>(1);

    public culturalIndex$: Observable<CulturalIndex | undefined>;
    public dashboardUrl$?: Observable<string>;

    public constructor(
        culturalIndexService: CulturalIndexService,
        userService: UserService,
    ) {
        super();

        this.dashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => person
                ? culturalIndexDashboardRoute.getRoute({ personId: person.personId })
                : myCulturalIndexDashboardRoute.getRoute()),
        );

        this.culturalIndex$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((person) => culturalIndexService.promiseToGetLatestCulturalIndexForPerson(person.personId) as Promise<CulturalIndex | undefined>),
        );
    }
}
