import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, ViewChild } from "@angular/core";
import { Diagram } from "@common/ADAPT.Common.Model/organisation/diagram";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocument } from "@common/ADAPT.Common.Model/organisation/system-document";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { EMPTY, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { SelectSystemComponent } from "../select-system/select-system.component";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-copy-system-component-dialog",
    templateUrl: "./copy-system-component-dialog.component.html",
})
export class CopySystemComponentDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SystemComponent> {
    public readonly dialogName = "CopySystemComponent";

    public entitiesToConfirm: IBreezeEntity[] = [];
    public isSaving = false;
    public canCreate = false;

    public newSystemComponent?: SystemComponent;
    private newSystemEntity?: SystemEntity;

    @ViewChild(SelectSystemComponent) private selectSystemComponent?: SelectSystemComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public systemComponent: SystemComponent,
        private systemisationService: SystemisationService,
        private commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
    ) {
        super();

        // only allow creation here if user has tier2 configure permission
        this.canCreate = this.commonArchAuthService.currentPersonCan(this.commonArchAuthService.personCanConfigureTier2);

        this.systemisationService.copySystemComponent(systemComponent).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((newComponent) => {
            this.newSystemComponent = newComponent;
            this.updateUniqueComponentName();

            this.entitiesToConfirm.push(newComponent);
            if (newComponent.supplementaryData) {
                this.entitiesToConfirm.push(newComponent.supplementaryData);
            }
        });
    }

    public get isCreatingSystem() {
        return !!this.selectSystemComponent?.isCreateAndSelecting;
    }

    public onSystemChange(system: SystemEntity) {
        if (this.newSystemComponent && system) { // system will be null after discarding newSystemComponent from cancelling dialog
            this.newSystemComponent!.system = system;
            // from CM-5395, emit from select system is not always commited entity anymore - can be new one as well, which needs to be saved
            if (system.entityAspect.entityState.isAdded() && !this.entitiesToConfirm.includes(system)) {
                this.entitiesToConfirm.push(system);
            }

            // resolve issue with duplicate component name caused by unprimed components in the destination system
            this.systemisationService.getSystemComponentsForSystem(system.systemEntityId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => this.updateUniqueComponentName());
        }
    }

    @Autobind
    public copyAndSave() {
        this.isSaving = true;
        let copy$: Observable<ProcessMap | SystemDocument | Diagram> = EMPTY;
        if (this.systemComponent?.systemProcessMapId) {
            copy$ = this.systemisationService.copyAndSaveProcessMap(this.systemComponent.systemProcessMapId).pipe(
                tap((processMap: ProcessMap) => { // needs type declaration here as vscode will flag this as error, resolving this as ProcessMap | SystemDocument | Diagram
                    this.newSystemComponent!.systemProcessMap = processMap;
                    processMap.name = this.newSystemComponent!.name;
                    this.entitiesToConfirm.push(processMap);
                }),
            );
        } else if (this.systemComponent?.systemDocument) {
            copy$ = this.systemisationService.copySystemDocument(this.systemComponent.systemDocument).pipe(
                tap((systemDocument: SystemDocument) => {
                    this.newSystemComponent!.systemDocument = systemDocument;
                    this.entitiesToConfirm.push(systemDocument);
                }),
            );
        } else if (this.systemComponent?.systemDiagram) {
            copy$ = this.systemisationService.copySystemDiagram(this.systemComponent.systemDiagram).pipe(
                tap((systemDiagram: Diagram) => {
                    this.newSystemComponent!.systemDiagram = systemDiagram;
                    this.entitiesToConfirm.push(systemDiagram);
                }),
            );
        }

        return copy$.pipe(
            switchMap(() => this.systemisationService.saveEntities(this.entitiesToConfirm)),
            tap(() => this.resolve(this.newSystemComponent!)),
            catchError((err: HttpErrorResponse) => {
                this.isSaving = false;
                this.setErrorMessage(err.message);
                return EMPTY;
            }),
        );
    }

    public onNewSystemChange(newSystem?: SystemEntity) {
        if (this.newSystemEntity) {
            ArrayUtilities.removeElementFromArray<IBreezeEntity>(this.newSystemEntity, this.entitiesToConfirm);
        }

        this.newSystemEntity = newSystem;
        if (newSystem) {
            this.entitiesToConfirm.push(newSystem);
        }
    }

    private updateUniqueComponentName() {
        this.newSystemComponent!.name = `Copy of ${this.systemComponent.name}`;
        let copyCount = 1;
        while (!this.newSystemComponent?.extensions.isComponentNameUnique()) {
            this.newSystemComponent!.name = `Copy(${++copyCount}) of ${this.systemComponent.name}`;
        }
    }
}
