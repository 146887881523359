import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartCustomizePoint, IDxChartTooltipPointInfo, IDxPieChartCustomizeLegendText } from "@common/ux/dx.types";
import { CulturalLeadershipFrameworkUiService } from "app/features/culture/cultural-leadership/cultural-leadership-framework-ui.service";
import dxPieChart, { InitializedEvent as PieChartInitializedEvent, piePointObject } from "devextreme/viz/pie_chart";
import { InitializedEvent as PolarChartInitializedEvent } from "devextreme/viz/polar_chart";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipConnectionStatusFilter } from "../../../culture/cultural-leadership/cultural-leadership-connection-status-filter.enum";
import { CareerValuationService, ICareerValuationStatistics } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { ICareerValuationChartTooltipOptions } from "../career-valuation-chart-tooltip-options.interface";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";

interface ICareerValuationOrbitData {
    index: number
    value: number
    careerValuation: CareerValuation
}

@Component({
    selector: "adapt-cvt-organisation-statistics",
    templateUrl: "./analyse-cvt-organisation-statistics.component.html",
    styleUrls: ["./analyse-cvt-organisation-statistics.component.scss"],
})
export class CareerValuationOrganisationStatisticsComponent extends BaseComponent implements OnChanges {
    @Input() public date?: Date;
    @Output() public dateChange = new EventEmitter<Date>();

    public idealsChart?: dxPieChart;

    public isLoading = true;

    public hasAtLeastQuantitativePermissions: boolean;

    public activePeopleCount?: number;
    public filterParameters?: ICareerValuationAnalysisFilter;
    public statisticsData?: ICareerValuationStatistics;
    public valuationOrbitData?: ICareerValuationOrbitData[];

    public idealsPalette = CareerValuationUiService.CareerValuationPaletteName;

    public overdueCatchupsPalette = CulturalLeadershipFrameworkUiService.CatchupStatusPaletteName;

    public constructor(
        elementRef: ElementRef,
        private commonDataService: CommonDataService,
        private careerValuationService: CareerValuationService,
        public careerValuationUiService: CareerValuationUiService,
        careerValuationAuthService: CareerValuationAuthService,
        public filterService: CareerValuationFilterService,
    ) {
        super(elementRef);

        this.hasAtLeastQuantitativePermissions = careerValuationAuthService.currentPersonHasAtLeastQuantitativeAccess();

        this.filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
            this.updateDataAndChart();
        });
    }

    public async ngOnChanges(changes: SimpleChanges) {
        await this.updateDataAndChart();
        this.dateChange.next(changes.date.currentValue);
    }

    public get careerValuationsHaveBeenRecorded() {
        return this.statisticsData && this.statisticsData.averageCareerValuation > 0;
    }

    public onIdealsChartInitialized(e: PieChartInitializedEvent) {
        this.idealsChart = e.component;
        this.onInitialized(e);
    }

    public onDistributionChartInitialized(e: PolarChartInitializedEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
        this.careerValuationUiService.setOrbitChartStrips(e.component!);
    }

    public onInitialized(e: PieChartInitializedEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customiseDistributionChartPoint(info: IDxChartCustomizePoint) {
        return this.careerValuationUiService.customiseChartPoint(info);
    }

    @Autobind
    public customiseDistributionTooltip(info: IDxChartTooltipPointInfo) {
        const tooltipOptions: ICareerValuationChartTooltipOptions = {
            showName: true,
        };

        return {
            html: this.careerValuationUiService.getChartDataTooltip(info.point.tag, tooltipOptions),
        };
    }

    @Autobind
    private async updateDataAndChart() {
        const filterParameters: ICareerValuationAnalysisFilter = { ...this.filterParameters!, date: this.date };
        const data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(filterParameters);
        this.statisticsData = this.careerValuationService.generateCareerValuationStatistics(data);
        this.activePeopleCount = await this.getActivePeopleCount(filterParameters);
        this.valuationOrbitData = data
            .filter((analysisData) => !!analysisData.careerValuation)
            .map((analysisData, index) => {
                return {
                    index,
                    value: 100 - analysisData.careerValuation.extensions.actualTotal, // as we are storing higher values closer to the center we must take the actual value away from the maximum possible value
                    careerValuation: analysisData.careerValuation,
                };
            });
        this.isLoading = false;
    }

    private async getActivePeopleCount(filter: ICareerValuationAnalysisFilter) {
        let connections = await lastValueFrom(this.commonDataService.getAll(ConnectionBreezeModel));

        // only employees count for totals
        connections = connections.filter((c) => c.connectionType === ConnectionType.Employee);

        // filter out inactive/active connections depending on the filter
        if (filter.connectionStatus === CulturalLeadershipConnectionStatusFilter.ActiveOnly) {
            connections = connections.filter((c) => c.isActiveAt(filter.date!));
        } else if (filter.connectionStatus === CulturalLeadershipConnectionStatusFilter.InactiveOnly) {
            connections = connections.filter((c) => !c.isActiveAt(filter.date!));
        }

        const people = connections.map((connection: Connection) => connection.personId);
        return ArrayUtilities.distinct(people).length;
    }

    @Autobind
    public customizeIdealsChartLegendText(info: IDxPieChartCustomizeLegendText) {
        if (this.idealsChart) {
            const series = this.idealsChart.instance().getSeriesByPos(0);
            const point = series.getPointByPos(info.pointIndex!) as piePointObject;

            return info.pointName + " (" + Math.floor(Number(point.percent) * 100) + "%)";
        }

        return "";
    }
}
