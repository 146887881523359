import { Component, Input, OnInit } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingType, CatchupRatingValue, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartCustomizePoint } from "@common/ux/dx.types";
import { dxChartSeriesTypesScatterSeries, PointClickEvent } from "devextreme/viz/chart";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { PeerCatchupService } from "../peer-catchup.service";
import { PeerCatchupUiService } from "../peer-catchup-ui.service";

interface IChartData {
    creationDate: Date;
    catchup: SpeedCatchup;
    valueEngagement?: number;
    valueEngagementRating?: SpeedCatchupRating;
    valueConnection?: number;
    valueConnectionRating?: SpeedCatchupRating;
    valueContribution?: number;
    valueContributionRating?: SpeedCatchupRating;
}

const RatingValueChartStates = [
    {
        lower: 0,
        upper: 1,
        state: CatchupRatingValue.Green,
        bgColor: "rgba(0, 128, 0, 0.8)",
        fgColor: "rgba(0, 128, 0, 0.8)",
        textColor: "rgba(235, 235, 235, 1)",
    },
    {
        lower: 1,
        upper: 2,
        state: CatchupRatingValue.Orange,
        bgColor: "rgba(255,165,0,0.8)",
        fgColor: "rgba(255,165,0,0.8)",
        textColor: "rgba(235, 235, 235, 1)",
    },
    {
        lower: 2,
        upper: 3,
        state: CatchupRatingValue.Red,
        bgColor: "rgba(255,0,0,0.8)",
        fgColor: "rgba(255,0,0,0.8)",
        textColor: "rgba(235, 235, 235, 1)",
    },
];

@Component({
    selector: "adapt-catchup-history-chart",
    templateUrl: "./catchup-history-chart.component.html",
    styleUrls: ["./catchup-history-chart.component.scss"],
})
export class CatchupHistoryChartComponent extends BaseComponent implements OnInit {
    @Input() public person1!: Person;
    @Input() public person2!: Person;
    @Input() public authorPerson!: Person;
    @Input() public hideDates = false;

    public readonly DateFormats = DateFormats;

    // we want to sort this in reverse due to the way the chart is rendered
    // without this, engagement would be at the bottom rather than top.
    public ratingTypes = [...CatchupRatingType.All].reverse();
    public chartData: IChartData[] = [];

    public series: dxChartSeriesTypesScatterSeries[] = [];
    public title = "";

    public constructor(
        private catchupService: PeerCatchupService,
        private catchupUiService: PeerCatchupUiService,
    ) {
        super();
    }

    public ngOnInit() {
        if (!this.person1 || !this.person2 || !this.authorPerson) {
            throw new Error("Not getting expected person1, person2 and authorPerson");
        }

        if (this.authorPerson) {
            this.title = `${this.authorPerson.firstName}'s Ratings`;
        }

        this.updateSeries();

        this.updateData().pipe(
            this.takeUntilDestroyed(),
        ).subscribe();

        this.catchupService.catchupChange$.pipe(
            // only emit if catchup corresponding to the same pair of people
            filter((catchup) => (catchup.person1Id === this.person1.personId && catchup.person2Id === this.person2.personId)
                || (catchup.person2Id === this.person1.personId && catchup.person1Id === this.person2.personId)),
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public customiseLabel(info: IDxChartCustomizePoint) {
        if (!this.series.length) {
            return "";
        }

        if (info.value < 0
            || info.value >= this.series.length
            || Math.floor(info.value) !== info.value) {
            return "";
        }

        return (this.series[info.value] as any).name;
    }

    public customiseChartPoint(point: IDxChartCustomizePoint) {
        if (point.tag instanceof SpeedCatchupRating) {
            const rating = point.tag as SpeedCatchupRating;
            const state = RatingValueChartStates.find((i) => i.state === rating.rating);
            if (state?.fgColor) {
                return {
                    color: state.fgColor,
                };
            }
        }

        return {};
    }

    public onPointClicked(e: PointClickEvent) {
        const rating = e.target?.tag;
        if (rating instanceof SpeedCatchupRating) {
            // show catchup dialog without showing history button as this is already from history
            const ratingType = rating.extensions.getRatingType();
            this.catchupUiService.showCatchup(rating.speedCatchup, true, false, false, ratingType)
                .subscribe();
        }
    }

    private updateSeries() {
        this.series = this.ratingTypes.map((type) => {
            return ({
                type: "scatter",
                valueField: `value${type.name}`,
                argumentField: "creationDate",
                tagField: `value${type.name}Rating`,
                name: this.getSeriesName(type),
            });
        });
    }

    private getSeriesName(ratingType: CatchupRatingType) {
        const subject = this.authorPerson === this.person1
            ? this.person2
            : this.person1;

        if (ratingType.authorIsSubject) {
            return this.authorPerson.firstName + "'s " + ratingType.name;
        } else if (ratingType.ratingIsMutual) {
            return ratingType.name + " with " + subject.firstName;
        } else {
            return subject.firstName + "'s " + ratingType.name;
        }
    }

    private updateData() {
        return this.catchupService.getAllCatchupsBetweenPeople(this.person1.personId, this.person2.personId).pipe(
            map((catchups) => catchups.map((catchup) => {
                const chartObject = {
                    creationDate: catchup.creationDate,
                    catchup,
                } as IChartData;
                this.ratingTypes.forEach((ratingType, index) => {
                    const valueName: keyof IChartData = `value${ratingType.name}`;
                    const ratingName: keyof IChartData = `value${ratingType.name}Rating`;

                    chartObject[valueName] = index;
                    chartObject[ratingName] = catchup.extensions.getRatingByTypePersonAndPerspective(
                        ratingType,
                        this.person1.personId,
                        this.person1.personId === this.authorPerson.personId);
                });

                return chartObject;
            })),
            tap((chartData) => this.chartData = chartData),
        );
    }
}
