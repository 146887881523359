import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IKeyFunctionLocation, IPrimedKeyFunctionLocation, KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { lastValueFrom } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

@Component({
    selector: "adapt-select-key-function-location",
    templateUrl: "./select-key-function-location.component.html",
    styleUrls: ["./select-key-function-location.component.scss"],
})
export class SelectKeyFunctionLocationComponent implements OnInit, OnChanges {
    @Input() public location?: IKeyFunctionLocation;
    @Output() public locationChange = new EventEmitter<IKeyFunctionLocation>();
    @Input() public showAsList = false;

    public allLocations: IPrimedKeyFunctionLocation[] = [];

    // dx list
    public selectedLocations: IPrimedKeyFunctionLocation[] = [];

    // dx-select-box
    public selectedLocation?: IPrimedKeyFunctionLocation;
    public validationError?: { message: string };
    public validationStatus: "valid" | "invalid" = "valid";

    public constructor(
        private tier1Service: Tier1ArchitectureService,
    ) { }

    public async ngOnInit() {
        const valueStreams = await lastValueFrom(this.tier1Service.getActiveValueStreams());
        for (const valueStream of valueStreams) {
            this.allLocations.push({
                zone: null,
                valueStream,
                valueStreamId: valueStream.valueStreamId,
            });
        }

        for (const zone of ZoneMetadata.InOrder) {
            this.allLocations.push({
                zone,
                valueStream: null,
                valueStreamId: null,
            });
        }

        this.updateSelectedZone();
    }

    public ngOnChanges() {
        this.updateSelectedZone();
    }

    private updateSelectedZone() {
        this.updateValidationStatus(!!this.location && KeyFunctionLocation.isValid(this.location));

        if (this.location) {
            const location = this.allLocations.find((i) => KeyFunctionLocation.areEqual(this.location!, i));

            this.selectedLocations = [];
            if (location) {
                this.selectedLocations.push(location);
            }

            this.selectedLocation = location;
        }
    }

    public async listLocationChange(selectedLocation: IDxListSelectionChangedEvent<IPrimedKeyFunctionLocation>) {
        this.onChange(selectedLocation.addedItems[0]);
    }

    public async selectBoxLocationChange(selectedLocation: IPrimedKeyFunctionLocation) {
        this.onChange(selectedLocation);
    }

    private onChange(selectedLocation: IPrimedKeyFunctionLocation) {
        const location = KeyFunctionLocation.fromLocation(selectedLocation);
        this.updateValidationStatus(location.isValid);
        this.locationChange.emit(location);
    }

    private updateValidationStatus(isValid: boolean) {
        if (isValid) {
            this.validationStatus = "valid";
            this.validationError = undefined;
        } else {
            this.validationStatus = "invalid";
            this.validationError = {
                message: "Location is required",
            };
        }
    }
}
