<adapt-dashboard-element *ngIf="hasActiveConnection$ | async"
                         name="Teams">
    <ng-container *adaptLoadingUntilFirstEmit="let teams of teams$">
        <ng-container *ngIf="teams.length > 0;else noTeamsTemplate">
            <div *ngFor="let team of teams">
                <adapt-link-team [team]="team"
                                 [focusPersonId]="(person$ | async)?.personId">
                </adapt-link-team>
            </div>
        </ng-container>
        <ng-template #noTeamsTemplate>
            This person is not a member of any team
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
