import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptCulturalLeadershipModule } from "app/features/culture/cultural-leadership/cultural-leadership.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxChartModule, DxCheckBoxModule, DxCircularGaugeModule, DxDataGridModule, DxDateBoxModule, DxLinearGaugeModule, DxListModule, DxPieChartModule, DxPolarChartModule, DxSelectBoxModule, DxSliderModule } from "devextreme-angular";
import { AdaptDirectoryModule } from "../directory/directory.module";
import { AdaptPersonModule } from "../person/person.module";
import { AdaptTeamsModule } from "../teams/teams.module";
import { AnalyseCareerValuationAverageGaugeComponent } from "./analyse-cvt-average-gauge/analyse-cvt-average-gauge.component";
import { CareerValuationDifferentialsChartComponent } from "./analyse-cvt-differentials-chart/analyse-cvt-differentials-chart.component";
import { AnalyseCareerValuationDifferentialsPageComponent } from "./analyse-cvt-differentials-page/analyse-cvt-differentials-page.component";
import { analyseCareerValuationDifferentialsPageRoute } from "./analyse-cvt-differentials-page/analyse-cvt-differentials-page.route";
import { AnalyseCareerValuationOrganisationComponent } from "./analyse-cvt-organisation/analyse-cvt-organisation.component";
import { CareerValuationOrganisationActualsChartComponent } from "./analyse-cvt-organisation-actuals-chart/analyse-cvt-organisation-actuals-chart.component";
import { CareerValuationOrganisationGridComponent } from "./analyse-cvt-organisation-grid/analyse-cvt-organisation-grid.component";
import { CareerValuationOrganisationLatestChartComponent } from "./analyse-cvt-organisation-latest-chart/analyse-cvt-organisation-latest-chart.component";
import { CareerValuationOrganisationStatisticsComponent } from "./analyse-cvt-organisation-statistics/analyse-cvt-organisation-statistics.component";
import { AnalyseCareerValuationPageComponent } from "./analyse-cvt-page/analyse-cvt-page.component";
import { analyseCareerValuationPageRoute } from "./analyse-cvt-page/analyse-cvt-page.route";
import { CareerValuationTimelineChartComponent } from "./analyse-cvt-timeline-chart/analyse-cvt-timeline-chart.component";
import { AnalyseCareerValuationTimelinePageComponent } from "./analyse-cvt-timeline-page/analyse-cvt-timeline-page.component";
import { analyseCareerValuationTimelinePageRoute } from "./analyse-cvt-timeline-page/analyse-cvt-timeline-page.route";
import { CareerValuationAuthService } from "./career-valuation-auth.service";
import { CareerValuationFilterComponent } from "./career-valuation-filter/career-valuation-filter.component";
import { CareerValuationFilterService } from "./career-valuation-filter/career-valuation-filter.service";
import { initialiseCareerValuation } from "./career-valuation-initialiser";
import { AdaptCareerValuationSharedModule } from "./career-valuation-shared.module";
import { CareerValuationCategoryValueComponent } from "./cvt-category-value/cvt-category-value.component";
import { CareerValuationDashboardPageComponent } from "./cvt-dashboard-page/cvt-dashboard-page.component";
import { careerValuationDashboardPageRoute, myCareerValuationDashboardPageRoute } from "./cvt-dashboard-page/cvt-dashboard-page.route";
import { CareerValuationHistoryDialogComponent } from "./cvt-history-dialog/cvt-history-dialog.component";
import { CareerValuationOrganisationDashboardElementComponent } from "./cvt-organisation-dashboard-element/cvt-organisation-dashboard-element.component";
import { CareerValuationPersonalSummaryComponent } from "./cvt-personal-summary/cvt-personal-summary.component";
import { EditCareerValuationDialogComponent } from "./edit-cvt-dialog/edit-cvt-dialog.component";
import { ViewCareerValuationDialogComponent } from "./view-cvt-dialog/view-cvt-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxCircularGaugeModule,
        AdaptEmbedShellModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        DxPolarChartModule,
        DxSelectBoxModule,
        FormsModule,
        DxCheckBoxModule,
        AdaptTeamsModule,
        AdaptDirectoryModule,
        AdaptLoadingSpinnerModule,
        DxChartModule,
        DxDataGridModule,
        DxAccordionModule,
        DxListModule,
        DxPieChartModule,
        AdaptCulturalLeadershipModule,
        AdaptCommonDialogModule,
        AdaptCallToActionModule,
        AdaptPersonModule,
        DxLinearGaugeModule,
        AdaptHtmlEditorModule,
        DxSliderModule,
        DxDateBoxModule,
        AdaptCareerValuationSharedModule,
        AdaptTwoColumnModule,
        AdaptOrganisationSharedModule,
        AdaptErrorPageModule,
        AdaptDashboardElementModule,
        AdaptTeamDashboardSharedModule,
        AdaptDateModule,
    ],
    declarations: [
        CareerValuationFilterComponent,
        AnalyseCareerValuationDifferentialsPageComponent,
        CareerValuationDifferentialsChartComponent,
        AnalyseCareerValuationTimelinePageComponent,
        CareerValuationTimelineChartComponent,
        CareerValuationOrganisationLatestChartComponent,
        CareerValuationOrganisationGridComponent,
        CareerValuationOrganisationStatisticsComponent,
        CareerValuationOrganisationActualsChartComponent,
        AnalyseCareerValuationOrganisationComponent,
        AnalyseCareerValuationPageComponent,
        CareerValuationPersonalSummaryComponent,
        CareerValuationDashboardPageComponent,
        CareerValuationHistoryDialogComponent,
        CareerValuationOrganisationDashboardElementComponent,
        AnalyseCareerValuationAverageGaugeComponent,
        ViewCareerValuationDialogComponent,
        EditCareerValuationDialogComponent,
        CareerValuationCategoryValueComponent,
    ],
    exports: [
        CareerValuationOrganisationDashboardElementComponent,
    ],
    providers: [
        CareerValuationFilterService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: () => initialiseCareerValuation,
        },
    ],
})
export class AdaptCareerValuationModule {
    public static readonly Routes = [
        ...analyseCareerValuationPageRoute.routes,
        ...analyseCareerValuationDifferentialsPageRoute.routes,
        ...analyseCareerValuationTimelinePageRoute.routes,
        ...careerValuationDashboardPageRoute.routes,
        ...myCareerValuationDashboardPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        CareerValuationAuthService.registerAccess(authorisationService);
    }
}
