/* eslint-disable max-classes-per-file */
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import moment from "moment";
import { IActivityDateGroup } from "./activity-date-group.interface";
import { ActivityGroup } from "./activity-group";
import { ActivityInstance } from "./activity-instance";

@Autobind
export class ActivityFeedManager {
    /** Activity grouped by date, _sorted reverse chronologically_ */
    private activityByDate: ActivityDateGroup[] = [];

    public constructor(
        rawActivity: ActivityInstance[] = [],
    ) {
        this.addActivity(rawActivity);
    }

    /**
     * Processes the activity present in this feed and returns them grouped by date and sorted reverse chronologically
     * ... according to the given constraints TODO: Enumerate these constraints
     * (e.g. Max number of items/days, when to start grouping etc)
     */
    public getActivity(): IActivityDateGroup[] {
        return this.activityByDate;
    }

    public clearActivity() {
        this.activityByDate.splice(0, this.activityByDate.length);
    }

    public get hasActivity() {
        return this.activityByDate.length > 0;
    }

    /** Get the date of the oldest activity in this feed. Assumes there is some activity present. */
    public getOldestActivityDate() {
        if (!this.hasActivity) {
            throw new Error("Expected some activity to be present");
        }

        const oldestDayGroup = this.activityByDate[this.activityByDate.length - 1];
        return oldestDayGroup.oldestActivityDate;
    }

    /** Get date of the newest activity associated with this feed. If there is none, then it will default to today. */
    public getNewestActivityDate() {
        if (!this.hasActivity) {
            return moment().startOf("day").toDate();
        }

        const newestDateGroup = this.activityByDate[0];
        return newestDateGroup.newestActivityDate;
    }

    public addActivity(activityInstance: ActivityInstance | ActivityInstance[]) {
        if (Array.isArray(activityInstance)) {
            for (const a of activityInstance) {
                this.addActivityInstance(a);
            }
        } else {
            this.addActivityInstance(activityInstance);
        }
    }

    private addActivityInstance(activityInstance: ActivityInstance) {
        const activityDateGroup = this.getActivityByDateGroup(activityInstance);
        activityDateGroup.addActivity(activityInstance);
    }

    private getActivityByDateGroup(activityInstance: ActivityInstance): ActivityDateGroup {
        const activityDateMoment = moment(activityInstance.dateTime).startOf("day");
        let dateGroupIndexToInsertAt = 0;

        for (let i = 0; i < this.activityByDate.length; i++) {
            const dateGroup = this.activityByDate[i];

            if (activityDateMoment.isSame(dateGroup.date, "day")) {
                return dateGroup;
            }

            if (activityDateMoment.isAfter(dateGroup.date, "day")) {
                break;
            }

            dateGroupIndexToInsertAt = i + 1;
        }

        const newGroup = new ActivityDateGroup(activityDateMoment.toDate());
        this.activityByDate.splice(dateGroupIndexToInsertAt, 0, newGroup);
        return newGroup;
    }
}

// This is a private class not to be used anywhere else
class ActivityDateGroup implements IActivityDateGroup {
    public readonly instance: ActivityGroup;

    public constructor(date: Date) {
        this.instance = new ActivityGroup(date);
    }

    public get id() {
        return this.date.toDateString();
    }

    public get date() {
        return this.instance.dateTime;
    }

    public get activity() {
        return this.instance.children;
    }

    public get newestActivityDate() {
        return this.instance.newestActivityDate;
    }

    public get oldestActivityDate() {
        return this.instance.oldestActivityDate;
    }

    public addActivity(instance: ActivityInstance) {
        this.instance.addChild(instance);
    }
}
