import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { provideManageAccessPageRoute, providePurposeVisionPageRoute } from "@common/page-route-providers";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptExternalDashboardModule } from "@org-common/lib/external-dashboard/external-dashboard.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { guidingPhilosophyRoute } from "@org-common/lib/guiding-philosophy/guiding-philosophy-page/guiding-philosophy-page.route";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrganisationModule } from "@org-common/lib/organisation/organisation.module";
import { AdaptCommonPurposeVisionModule } from "@org-common/lib/purpose-vision/common-purpose-vision.module";
import { AdaptResilientBusinessGoalsModule } from "@org-common/lib/rb-goals/rb-goals.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { AdaptStrategyDashboardPageModule } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptUserManagementSharedModule } from "@org-common/lib/user-management/user-management-shared.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptIntegratedArchitectureModule } from "app/features/architecture/integrated-architecture/integrated-architecture.module";
import { AdaptIntegratedArchitectureSharedModule } from "app/features/architecture/integrated-architecture/integrated-architecture-shared.module";
import { AdaptOrganisationMapModule } from "app/features/architecture/organisation-map/organisation-map.module";
import { AdaptProcessMapSharedModule } from "app/features/architecture/process-map/process-map-shared.module";
import { AdaptRoleModule } from "app/features/architecture/role/role.module";
import { AdaptValueStreamsModule } from "app/features/architecture/value-streams/value-streams.module";
import { AdaptCareerValuationModule } from "app/features/people/career-valuation/career-valuation.module";
import { AdaptCulturalIndexModule } from "app/features/people/cultural-index/cultural-index.module";
import { AdaptDirectoryModule } from "app/features/people/directory/directory.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptActivityFeedModule } from "app/general/activity-feed/activity-feed.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxCheckBoxModule, DxDataGridModule, DxListModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { ManageAccessPageComponent } from "./manage-access-page/manage-access-page.component";
import { organisationManageAccessPageRoute } from "./manage-access-page/manage-access-page.route";
import { OrganisationActivityFeedComponent } from "./organisation-activity-feed/organisation-activity-feed.component";
import { OrganisationActivityFeedDashboardComponent } from "./organisation-activity-feed-dashboard/organisation-activity-feed-dashboard.component";
import { BullseyeDashboardElementComponent } from "./organisation-dashboard-page/bullseye-dashboard-element/bullseye-dashboard-element.component";
import { OrganisationDashboardPageComponent } from "./organisation-dashboard-page/organisation-dashboard-page.component";
import { organisationDashboardPageRoute } from "./organisation-dashboard-page/organisation-dashboard-page.route";
import { OrganisationMapPageComponent } from "./organisation-map-page/organisation-map-page.component";
import { organisationMapPageRoute } from "./organisation-map-page/organisation-map-page.route";
import { subscribeOrganisationPageRoute } from "./subscribe-organisation/subscribe-organisation-page/subscribe-organisation-page.route";
import { AdaptUserManagementModule } from "./user-management/user-management.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptLoadingSpinnerModule,
        DxCheckBoxModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxListModule,
        AdaptOrganisationModule,
        AdaptEntityValidatorModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptUserManagementModule,
        AdaptUserManagementSharedModule,
        AdaptIntegratedArchitectureModule,
        AdaptValuesConstitutionModule,
        AdaptGuidingPhilosophyModule,
        AdaptObjectivesModule,
        AdaptSurveyModule,
        AdaptCareerValuationModule,
        AdaptCulturalIndexModule,
        AdaptResilientBusinessGoalsModule,
        AdaptValueStreamsModule,
        AdaptActivityFeedModule,
        AdaptTeamsModule,
        AdaptEmbedShellModule,
        AdaptKeyFunctionsCommonModule,
        AdaptProcessMapSharedModule,
        AdaptTooltipModule,
        AdaptOrganisationMapModule,
        AdaptTwoColumnModule,
        DxDataGridModule,
        AdaptDirectoryModule,
        AdaptRoleModule,
        AdaptCommonDialogModule,
        AdaptMenuModule,
        AdaptIntegratedArchitectureSharedModule,
        DxTabPanelModule,
        AdaptDashboardElementModule,
        AdaptAuthorisationModule,
        AdaptUserManagementModule,
        AdaptCommonPurposeVisionModule,
        AdaptBullseyeModule,
        AdaptFeaturesModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptHtmlEditorModule,
        AdaptStrategyBoardModule,
        AdaptStrategyDashboardPageModule,
    ],
    declarations: [
        OrganisationDashboardPageComponent,
        ManageAccessPageComponent,
        OrganisationActivityFeedComponent,
        OrganisationActivityFeedDashboardComponent,
        OrganisationMapPageComponent,
        BullseyeDashboardElementComponent,
    ],
    providers: [
        provideManageAccessPageRoute(organisationManageAccessPageRoute),
        providePurposeVisionPageRoute(guidingPhilosophyRoute),
    ],
})
export class AdaptAppOrganisationModule {
    public static readonly Routes = [
        ...AdaptUserManagementSharedModule.Routes,
        ...AdaptExternalDashboardModule.Routes,
        ...organisationManageAccessPageRoute.routes,
        ...organisationDashboardPageRoute.routes,
        ...organisationMapPageRoute.routes,
        ...subscribeOrganisationPageRoute.routes,
    ];
}
