import { Component, Inject } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { Observable, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";

@Component({
    selector: "adapt-delete-process-map-dialog",
    templateUrl: "./delete-process-map-dialog.component.html",
    styleUrls: ["./delete-process-map-dialog.component.scss"],
})
export class DeleteProcessMapDialogComponent extends BaseDialogComponent<ProcessMap> {
    public readonly dialogName = "DeleteProcessMap";

    public steps$: Observable<ProcessStep[]>;
    public confirmed = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public processMap: ProcessMap,
        private commonDataService: CommonDataService,
        processMapService: ProcessMapService,
    ) {
        super();
        this.steps$ = processMapService.getProcessMapSteps(processMap);
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.remove(this.processMap).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.processMap)),
            tap(() => this.resolve(this.processMap)),
        );
    }
}
