"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var d3v3 = require("./d3v3adaptor");
var d3v4 = require("./d3v4adaptor");
;
function d3adaptor(d3Context) {
  if (!d3Context || isD3V3(d3Context)) {
    return new d3v3.D3StyleLayoutAdaptor();
  }
  return new d3v4.D3StyleLayoutAdaptor(d3Context);
}
exports.d3adaptor = d3adaptor;
function isD3V3(d3Context) {
  var v3exp = /^3\./;
  return d3Context.version && d3Context.version.match(v3exp) !== null;
}
