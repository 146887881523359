<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            *adaptIfAuthorised="CulturalLeadershipFrameworkAuthService.ConfigureCohorts"
            data-test="create-cohort-button"
            (click)="addCohort()">
        Create new {{culturalLeadershipConfiguration?.secondaryCollectionName}}
    </button>
</div>

<dx-data-grid [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [wordWrapEnabled]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [dataSource]="gridData"
              (onCellPrepared)="onCellPrepared($event)"
              noDataText="No Catchup Activity found"
              data-test="clf-grid"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              (onInitialized)="onGridInitialized($event)">
    <dxo-column-fixing [enabled]="true">
    </dxo-column-fixing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-scrolling [useNative]="false">
    </dxo-scrolling>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-group-panel [visible]="true">
    </dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true">
    </dxo-grouping>
    <dxo-export [enabled]="true"
                fileName="CatchupActivity">
    </dxo-export>
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>

    <!-- Columns -->
    <dxi-column caption="Person"
                dataField="person.fullName"
                dataType="string"
                cellTemplate="personCellTemplate"
                [sortIndex]="2"
                sortOrder="asc"
                width="250"></dxi-column>

    <dxi-column [caption]="culturalLeadershipConfiguration?.primaryName"
                [calculateCellValue]="calculateCulturalLeadersCellValue"
                [calculateGroupValue]="calculateCulturalLeadersGroupValue"
                [customizeText]="culturalLeadersCustomizeText"
                dataType="string"
                dataField="dummy_culturalleader"
                cellTemplate="culturalLeaderCellTemplate"
                groupCellTemplate="culturalLeaderGroupCellTemplate"
                [groupIndex]="0"
                [allowSorting]="true"
                [allowGrouping]="true"
                [allowFiltering]="true">
        <dxo-header-filter [dataSource]="culturalLeadersHeaderItems"></dxo-header-filter>
    </dxi-column>

    <dxi-column caption="Supporting"
                dataField="isSupporting"
                dataType="boolean"
                falseText="False"
                trueText="True"
                sortOrder="asc"
                [sortIndex]="1"
                [visible]="false"></dxi-column>

    <dxi-column *ngIf="ciAccess || cvtAccess"
                caption="Status"
                [calculateCellValue]="culturalLeadershipUiService.calculateStatusCellValue"
                dataType="string"
                dataField="dummy_status"
                [cellTemplate]="culturalLeadershipUiService.getStatusCellTemplate"
                [allowSorting]="true"
                [allowGrouping]="true"
                [allowFiltering]="true">
        <dxo-header-filter [dataSource]="culturalLeadershipHeaderOptions">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="ciAccess || cvtAccess"
                caption="Next catch-up"
                dataField="nextCatchup"
                dataType="date"
                [format]="dateFormat">
    </dxi-column>

    <dxi-column *ngIf="ciAccess"
                caption="Cultural Index"
                dataField="culturalIndex.value"
                dataType="number"
                [customizeText]="culturalIndexUiService.customiseValueCellText"
                [cellTemplate]="culturalIndexUiService.getValueCellTemplate">
        <dxo-header-filter [dataSource]="culturalIndexHeaderOptions">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="ciAccess"
                caption="CI direction"
                dataField="culturalIndex.direction"
                dataType="string">
        <dxo-lookup [dataSource]="culturalIndexService.directions"
                    valueExpr="id"
                    displayExpr="label">
        </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="ciAccess"
                caption="CI date"
                dataField="culturalIndex.creationDate"
                dataType="date"
                [format]="dateFormat">
    </dxi-column>

    <dxi-column *ngIf="ciAccess && allowCulturalIndexNotes"
                caption="Notes"
                dataField="culturalIndex.notes"
                dataType="string"
                [encodeHtml]="false">
    </dxi-column>

    <dxi-column *ngIf="cvtAccess"
                caption="Career Valuation"
                dataField="careerValuation.extensions.actualTotal"
                dataType="number"
                cellTemplate="careerValuationCellTemplate">
        <dxo-header-filter [dataSource]="careerValuationHeaderOptions">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="cvtAccess"
                caption="CV date"
                dataField="careerValuation.creationDate"
                dataType="date"
                [format]="dateFormat">
    </dxi-column>

    <dxi-column *ngIf="cvtAccess && allowCareerValuationNotes"
                caption="Notes"
                dataField="careerValuation.notes"
                dataType="string"
                [encodeHtml]="false">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let item of 'personCellTemplate'"
         class="d-flex align-items-center">
        <adapt-link-person [person]="item.data.person"
                           [showImage]="true"
                           imageSize="sm"
                           [preventWrap]="true"></adapt-link-person>
        <span *ngIf="item.data.culturalLeader && item.data.isSupporting"
              [adaptTooltip]="SupportingMemberTooltip"
              class="fal fa-circle fa-xs supporting-icon p-2"></span>
    </div>

    <div *dxTemplate="let item of 'careerValuationCellTemplate'">
        <div *ngIf="item.data.careerValuation">
            {{item.data.careerValuation?.extensions.actualTotal}}%
            <i class="ms-2 career-valuation-launch fal fa-external-link-alt"
               (click)="launchPersonCareerValuation(item.data.careerValuation)"
               adaptTooltip="Click for more details"></i>
        </div>
    </div>

    <div *dxTemplate="let item of 'culturalLeaderCellTemplate'">
        <adapt-link-person [person]="item.data.culturalLeader"
                           [preventWrap]="true"
                           nullValueText="None allocated"></adapt-link-person>
    </div>
    <div *dxTemplate="let item of 'culturalLeaderGroupCellTemplate'">
        <div [ngSwitch]="!!item.value">
            <div *ngSwitchCase="true">
                {{culturalLeadershipConfiguration?.primaryName}}:
                <adapt-link-person [personId]="item.value"
                                   [preventWrap]="true">
                </adapt-link-person>
                <button class="ms-2"
                        adaptButton="editLink"
                        data-test="edit-cohort"
                        *adaptIfAuthorised="CulturalLeadershipFrameworkAuthService.ConfigureCohorts"
                        adaptTooltip="Edit {{culturalLeadershipConfiguration?.secondaryCollectionName}}"
                        (click)="editCohort(item.value)"></button>
            </div>

            <div *ngSwitchCase="false">
                No {{culturalLeadershipConfiguration?.primaryName}}
                <button class="ms-2"
                        adaptButton="addLink"
                        *adaptIfAuthorised="CulturalLeadershipFrameworkAuthService.ConfigureCohorts"
                        (click)="addCohort()">Create new {{culturalLeadershipConfiguration?.secondaryCollectionName}}</button>
            </div>
        </div>
    </div>
</dx-data-grid>
