<div *ngIf="(locationItems$ | async) as locationItems"
     class="items"
     data-test="location-entity-list">
    <div *ngFor="let item of locationItems"
         class="location-entity-list-item"
         [ngClass]="zone ? ZoneClass[zone] : 'zone-economic-engine-dashboard-element'">
        <ng-container *ngIf="getAsTeamLocation(item) as teamLocation"
                      [ngTemplateOutlet]="entityTemplates?.Team || defaultTemplate"
                      [ngTemplateOutletContext]="{$implicit: teamLocation.team}"></ng-container>
        <ng-container *ngIf="getAsKeyFunction(item) as kf"
                      [ngTemplateOutlet]="entityTemplates?.KeyFunction || defaultTemplate"
                      [ngTemplateOutletContext]="{$implicit: kf}"></ng-container>
    </div>
    <ng-template #defaultTemplate>
        <div class="pb-1"></div>
    </ng-template>
</div>
