import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { LinkSystemComponent } from "./link-system/link-system.component";
import { SelectSystemComponent } from "./select-system/select-system.component";

@NgModule({
    declarations: [
        SelectSystemComponent,
        LinkSystemComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        AdaptButtonModule,
        AdaptEntityValidatorModule,
        AdaptLabellingModule,
        AdaptTooltipModule,
    ],
    exports: [
        SelectSystemComponent,
        LinkSystemComponent,
    ],
})
export class AdaptSystemisationSharedModule { }
