<adapt-page-with-splash-image leftColumnWidthClasses="col-xl-5 col-lg-6">
    <dx-validation-group #validationGroup>
        <h2>Subscribe an organisation</h2>

        <label for="organisation-name-input">Company name</label>
        <dx-text-box id="organisation-name-input"
                     [(value)]="organisation.name"
                     [maxLength]="60"
                     (onValueChanged)="orgNameChanged($event)"
                     [disabled]="inProgress"
                     data-test="organisation-name-input">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="Organisation name is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <label for="logo">Company logo</label>
        <adapt-select-image id="logo"
                            class="upload-logo"
                            [(imageIdentifier)]="organisation.imageIdentifier"
                            defaultImageSrc="/content/images/default_organisation.png"
                            imageHeight="120px"
                            imageWidth="100%"
                            [resizeHeight]="120"></adapt-select-image>

        <label for="url-id-input">Portal URL</label>
        <div class="input-group gap-1 flex-nowrap">
            <div class="input-group-prepend">
                <span class="input-group-text">embed.adaptbydesign.com.au/</span>
            </div>
            <dx-text-box id="url-id-input"
                         class="flex-fill"
                         placeholder="yourOrganisation"
                         [(value)]="organisation.urlIdentifier"
                         [maxLength]="20"
                         (onValueChanged)="validateGroup()"
                         [disabled]="inProgress"
                         data-test="url-id-input">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Portal URL is required"></dxi-validation-rule>
                    <!-- regex only allows lowercase alphanumerics -->
                    <dxi-validation-rule type="pattern"
                                         pattern="^[a-z0-9-]+$"
                                         message="Must be lowercase letters, numbers or dashes"></dxi-validation-rule>
                    <dxi-validation-rule type="custom"
                                         [validationCallback]="validateDuplicates"
                                         message="Identifier already in use">
                    </dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <label>Leader details</label>
        <div class="d-flex gap-1 leader-name">
            <dx-text-box class="flex-fill"
                         [(value)]="leaderFirstName"
                         (valueChange)="validateGroup()"
                         placeholder="First name"
                         [disabled]="inProgress"
                         data-test="leader-first-name-input">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Leader first name is required"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>

            <dx-text-box class="flex-fill"
                         [(value)]="leaderLastName"
                         (valueChange)="validateGroup()"
                         placeholder="Last name"
                         [disabled]="inProgress"
                         data-test="leader-last-name-input">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Leader last name is required"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <dx-text-box class="flex-fill leader-email mt-2"
                     mode="email"
                     [(value)]="leaderEmail"
                     (valueChange)="validateGroup()"
                     placeholder="leader@email.com"
                     [disabled]="inProgress"
                     data-test="leader-email-input">
            <dx-validator>
                <dxi-validation-rule type="email"></dxi-validation-rule>
                <dxi-validation-rule type="required"
                                     message="Leader email is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <dx-check-box class="d-block mt-2"
                      text="Allow leader to login"
                      [(value)]="activateLeader"
                      [disabled]="inProgress"></dx-check-box>
        <dx-check-box class="d-block mt-2"
                      text="Send CLA to leader"
                      [(value)]="sendEULA"
                      [disabled]="inProgress"></dx-check-box>

        <button [adaptBlockingClick]="subscribe"
                [unsubscribeOnDestroy]="false"
                class="btn btn-primary subscribe-button"
                [disabled]="isDisabled || inProgress"
                adaptButtonIcon="fal fa-chevron-circle-right"
                data-test="subscribe-button"> Submit subscription</button>

        <div class="alert alert-info">
            The customer will have {{defaultPricingModel?.trialLength ?? 0}} days to activate the subscription before the trial period
            expires.
        </div>
        <div class="validation"
             *ngIf="error">
            <div role="alert"
                 class="alert alert-danger">{{error}}</div>
        </div>
    </dx-validation-group>
</adapt-page-with-splash-image>
