import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionRole } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleType } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { InitializedEvent } from "devextreme/ui/text_box";
import { EMPTY, lastValueFrom, ReplaySubject } from "rxjs";
import { first, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkService } from "../../integrated-architecture/integrated-architecture-framework.service";
import { RoleService } from "../../role/role.service";
import { SelectRoleComponent } from "../../role/select-role/select-role.component";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";

@Component({
    selector: "adapt-edit-key-function-roles",
    templateUrl: "./edit-key-function-roles.component.html",
})
export class EditKeyFunctionRolesComponent extends BaseComponent implements OnInit {
    @Input() public set keyFunction(value: KeyFunction | undefined) {
        if (value) {
            this.keyFunction$.next(value);
        }
    }
    private keyFunction$ = new ReplaySubject<KeyFunction>(1);

    @Output() public populated = new EventEmitter<KeyFunctionRole[]>();
    @Output() public added = new EventEmitter<KeyFunctionRole | Role>();

    @ViewChild(SelectRoleComponent) private selectRoleComponent?: SelectRoleComponent;

    public keyFunctionRoles?: KeyFunctionRole[];
    public addedRole?: Role;
    public isCreatingAndAdding = false;
    public newRole?: Role;

    private nonSpecialRoles: Role[] = [];
    private tier1RoleType?: RoleType;
    private keyFunctionLeaderRoleId?: number;

    public constructor(
        private commonDataService: CommonDataService,
        private keyFunctionsService: KeyFunctionsService,
        private roleService: RoleService,
        private tier1ArchitectureService: Tier1ArchitectureService,
        private architectureService: IntegratedArchitectureFrameworkService,
    ) {
        super();

        this.keyFunction$.pipe(
            tap((kf) => this.keyFunctionLeaderRoleId = kf.leaderRoleId),
            switchMap((kf) => this.keyFunctionsService.getKeyFunctionRoles(kf)),
            this.takeUntilDestroyed(),
        ).subscribe((keyFunctionRoles) => {
            this.keyFunctionRoles = keyFunctionRoles;
            this.populated.emit(keyFunctionRoles);
        });
    }

    public async ngOnInit() {
        this.nonSpecialRoles = await lastValueFrom(this.roleService.getAllNonSpecialRoles());
        this.tier1RoleType = await lastValueFrom(this.tier1ArchitectureService.getTier1RoleType());
    }

    public updateOrdinals(keyFunctionRoles: KeyFunctionRole[], e: IDxListItemReorderedEvent<KeyFunctionRole>) {
        SortUtilities.reorderItemInIntegerSortedArray(keyFunctionRoles, "ordinal", e.fromIndex, e.toIndex);
    }

    public async removeKeyFunctionRole(e: IDxListItemDeletedEvent<KeyFunctionRole>) {
        if (!this.keyFunctionRoles) {
            return;
        }

        const keyFunctionRole = e.itemData!;
        ArrayUtilities.removeElementFromArray(keyFunctionRole, this.keyFunctionRoles!);
        SortUtilities.updateIntegerSortedArrayAfterItemRemoval(this.keyFunctionRoles, "ordinal", keyFunctionRole.ordinal);

        if (keyFunctionRole.role?.entityAspect.entityState.isAdded()) {
            await lastValueFrom(this.commonDataService.remove(keyFunctionRole.role));
        }

        await lastValueFrom(this.commonDataService.remove(keyFunctionRole));
        this.selectRoleComponent?.reload();
    }

    @Autobind
    public async createNewRole() {
        this.newRole = await lastValueFrom(this.architectureService.createRole({}));
        this.isCreatingAndAdding = true;
        this.added.emit(this.newRole);
    }

    @Autobind
    public async addNewRole() {
        await this.addRole(this.newRole);

        this.newRole = undefined;
        this.isCreatingAndAdding = false;
    }

    @Autobind
    public async cancelNewRole() {
        if (!this.newRole) {
            return;
        }

        // Don't use promiseToDeleteRole as that also does a save which we don't want to do yet!
        await lastValueFrom(this.commonDataService.remove(this.newRole));
        this.newRole = undefined;
        this.isCreatingAndAdding = false;
    }

    public async addRole(role?: Role) {
        if (!role) {
            return;
        }

        this.addedRole = role;

        const kfr = await lastValueFrom(this.keyFunction$.pipe(
            first(),
            switchMap((kf) => {
                return this.addedRole
                    ? this.keyFunctionsService.addRoleToKeyFunction(this.addedRole, kf, this.keyFunctionRoles?.length || 0)
                    : EMPTY;
            }),
        ));

        this.keyFunctionRoles?.push(kfr);
        this.added.emit(kfr);
        await this.selectRoleComponent?.reload();
        // only clear this after reload to not leave behind the text of the previously selected role
        this.addedRole = undefined;
    }

    public onInitialized(e: InitializedEvent) {
        setTimeout(() => e.component?.focus());
    }

    @Autobind
    public roleFilter(r: Role) {
        return (this.nonSpecialRoles.some((i) => i.roleId === r.roleId)
            || r.roleTypeId === this.tier1RoleType?.roleTypeId // allow tier1 role
            || r.roleType?.code === RoleTypeCode.CulturalLeader) // allow cultural leader role
            && (!this.keyFunctionRoles || this.keyFunctionRoles.every((i) => i.roleId !== r.roleId))
            && r.roleId !== this.keyFunctionLeaderRoleId;
    }
}
