import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartCustomizePoint, IDxChartTooltipPointInfo, IDxGridColumnCellInfo, IDxGridColumnTemplateCellInfo } from "@common/ux/dx.types";
import { dxElement } from "devextreme/core/element";
import dxDataGrid, { dxDataGridColumn, InitializedEvent as GridInitializedEvent } from "devextreme/ui/data_grid";
import dxChart, { dxChartSeriesTypesCommonSeriesPoint, InitializedEvent as ChartInitializedEvent, PointClickEvent } from "devextreme/viz/chart";
import { switchMap } from "rxjs/operators";
import { CulturalIndexDialogComponent, IDisplayCulturalIndexDialogData } from "../ci-dialog/ci-dialog.component";
import { CulturalIndexService, ICulturalIndexChartData } from "../cultural-index.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";


export interface ICulturalIndexHistoryComponentOptions {
    refreshData?: (() => void);
    exportData?: (() => void);
}

@Component({
    selector: "adapt-ci-history",
    templateUrl: "./ci-history.component.html",
})
export class CulturalIndexHistoryComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public personId?: number;
    @Input() public options?: ICulturalIndexHistoryComponentOptions;

    public culturalIndices: CulturalIndex[] = [];

    public culturalIndicesChartData: ICulturalIndexChartData[] = [];

    public chart?: dxChart;

    public gridInstance?: dxDataGrid;
    public gridColumns?: dxDataGridColumn[];

    public DateFormats = DateFormats;

    public isBusy = true;

    public showNotes = false;

    constructor(
        public culturalIndexService: CulturalIndexService,
        public culturalIndexUiService: CulturalIndexUiService,
        private commonDialogService: AdaptCommonDialogService,
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChangedInSave(CulturalIndex)
            .pipe(
                switchMap(() => this.getDataThenUpdateChart()),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public async ngOnInit() {
        await this.culturalIndexUiService.requiredActions();
        this.chart?.refresh();
    }

    @Autobind
    public customiseChartPoint(info: IDxChartCustomizePoint): dxChartSeriesTypesCommonSeriesPoint {
        if (!info.tag) {
            return {};
        }

        for (const valueState of this.culturalIndexUiService.valueStates) {
            if (info.tag.value >= valueState.lower
                && info.tag.value <= valueState.upper) {
                return {
                    color: valueState.fgColor,
                };
            }
        }

        return {};
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.personId) {
            this.getDataThenUpdateChart();
        }
    }

    @Autobind
    public async onChartInitialized(e: ChartInitializedEvent) {
        this.chart = e.component!;
        this.chart.on("pointClick", this.onPointClick);

        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));

        // set the strips in the post since the culturalIndexService has to have finished initialising prior to settings the strip labels/colours
        await this.culturalIndexUiService.promiseToSetChartStrips(this.chart);
    }

    @Autobind
    private onPointClick(info: PointClickEvent) {
        info.component.hideTooltip();
        const data: IDisplayCulturalIndexDialogData = {
            culturalIndex: info.target!.tag,
            showName: false,
        };

        return this.commonDialogService.open(CulturalIndexDialogComponent, data).subscribe();
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        return {
            html: this.culturalIndexUiService.getChartDataTooltip(info.point.tag, false),
        };
    }

    @Autobind
    public onGridInitialized(e: GridInitializedEvent) {
        this.gridInstance = e.component;

        if (this.options) {
            this.options.exportData = this.exportData;
            this.options.refreshData = this.refreshData;
        }
    }

    @Autobind
    private refreshData() {
        if (this.gridInstance) {
            this.getDataThenUpdateChart();
        }
    }

    @Autobind
    private exportData() {
        if (this.gridInstance) {
            DxUtilities.exportGridToExcel("Cultural Index history", this.gridInstance);
        }
    }


    @Autobind
    public customiseColumnIndexGridText(cell: IDxGridColumnCellInfo<number>) {
        const states = this.culturalIndexUiService.valueStates.filter((state) => cell.value! >= state.lower && cell.value! <= state.upper);

        if (!states || !states[0]) {
            return "";
        }

        return cell.valueText + " (" + states[0].state + ")";
    }

    @Autobind
    public culturalIndexCellTemplate(element: dxElement, info: IDxGridColumnTemplateCellInfo) {
        // CM-5081 DX 21.1 has screwed up dxElement, remove the prefix once they fix it
        const states = this.culturalIndexUiService.valueStates.filter((state) => info.value! >= state.lower && info.value! <= state.upper);

        if (!states || !states[0]) {
            return;
        }

        element.addClass(states[0].class);
        element.html(info.text!);
    }

    @Autobind
    private async getDataThenUpdateChart() {
        this.isBusy = true;

        this.culturalIndices = (await this.promiseToGetData()) ?? [];
        this.culturalIndicesChartData = this.culturalIndices.map(this.culturalIndexUiService.createCulturalIndexChartObject);

        this.setColumnVisibility();

        this.isBusy = false;
    }

    private promiseToGetData() {
        if (!this.personId) {
            return Promise.resolve([]);
        }

        return this.culturalIndexService.promiseToGetAllCulturalIndexesForPerson(this.personId);
    }

    @Autobind
    private setColumnVisibility() {
        this.showNotes = false;

        for (const ci of this.culturalIndices) {
            if (ci.notes !== null) {
                this.showNotes = true;
                break;
            }
        }
    }
}
