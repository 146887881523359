import { Component, OnInit } from "@angular/core";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import dxAutocomplete, { InitializedEvent, ItemClickEvent, OpenedEvent } from "devextreme/ui/autocomplete";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { SearchType } from "../search.interface";
import { SearchService } from "../search.service";
import { ISearchResults } from "../search-results.interface";

@Component({
    selector: "adapt-application-bar-user-item",
    templateUrl: "./application-bar-search-item.component.html",
    styleUrls: ["./application-bar-search-item.component.scss"],
})
export class ApplicationBarSearchItemComponent extends BaseComponent implements OnInit {
    public readonly TypesToSearch = new Set<SearchType>([
        SearchType.Page,
        SearchType.PersonTeamRole,
    ]);

    // used in the template to show the required button/text
    public readonly LoadMoreResultsButtonType = -1;
    public readonly NoResultsFoundType = -2;

    public searchType = SearchType;
    public keyword?: string;
    public keywordUpdater = this.createThrottledUpdater((keyword?: string) => this.keyword = keyword);

    public dataSource?: DataSource;
    public throttledOpenedEvent = this.createThrottledUpdater((e: OpenedEvent) => this.onOpened(e));

    public searchElements = SearchService.ApplicationBarSearchElementRegistrar!;

    private readonly popupClass = "quick-search-popup";
    private readonly emptyPopupClass = "quick-search-popup-empty";

    private autocomplete?: dxAutocomplete;

    public constructor(
        private searchService: SearchService,
        orgService: OrganisationService,
        userService: UserService,
    ) {
        super();

        // make sure we don't have stale results in the popup when changing org/user
        orgService.organisationChanged$.subscribe(() => this.reset());
        userService.userChanged$.subscribe(() => this.reset());
    }

    public ngOnInit() {
        this.reset();
    }

    public reset() {
        this.keywordUpdater.next(undefined);

        const store = new CustomStore({
            load: (options) => {
                this.keywordUpdater.next(options.searchValue);

                // don't try to quick search if the searchValue is null/undefined
                if (!this.searchService.shouldPerformSearch(options.searchValue)) {
                    return Promise.resolve(undefined);
                }

                return lastValueFrom(this.searchService.search({
                    keyword: options.searchValue,
                    types: this.TypesToSearch,
                    activeOnly: true,
                }).pipe(
                    map((results) => this.processSearch(results)),
                )) as Promise<any>;
            },
        });

        this.dataSource = new DataSource({
            store,
        });
    }

    public onInitialised(event: InitializedEvent) {
        if (event.component) {
            // @ts-ignore disable dx default item click handler as it does a bunch of things we don't want
            event.component._listItemClickHandler = FunctionUtilities.noop;

            this.autocomplete = event.component;
        }
    }

    public onOpened(event: OpenedEvent) {
        // CM-5081 DX 21.1 has screwed up types, this should return a jQuery object too
        const content = jQuery(event.component.content());
        if (content) {
            content.addClass(this.popupClass);

            // hide the popup if there is no content
            const scrollView = content.find(".dx-scrollview-content");
            if (scrollView && scrollView.is(":empty")) {
                content.addClass(this.emptyPopupClass);
            } else {
                content.removeClass(this.emptyPopupClass);
            }

            // set the height to the scrollable content
            const scrollableContent = content.find(".dx-scrollable-content");
            if (scrollableContent.length === 1) {
                // only expect 1 scrollable content for dxAutocomplete dropdown - set the height to avoid scrollable
                const scrollableHeight = scrollableContent.height()!;
                content.height(`${scrollableHeight}px`);
                // there is already a max height set for the parent - so do not exceed that
                const parent = content.parent();
                content.css("max-height", parent.css("max-height"));
            }
        }
    }

    public onItemClick(event: ItemClickEvent) {
        // click the link within the item result (first link is the row itself)
        const link = event.event?.currentTarget.querySelector("a[href]");
        if (link) {
            (link as HTMLAnchorElement).click();
            this.exitPopup(event.component);
        } else {
            this.onEnterKey(this.keyword);
        }
    }

    public onEnterKey(keyword?: string) {
        if (keyword) {
            this.searchService.gotoSearchRoute({ ...this.searchService.getDefaultOptions(), keyword }).subscribe();
            if (this.autocomplete) {
                this.exitPopup(this.autocomplete);
            }
        }
    }

    private processSearch(results?: ISearchResults) {
        // force un-hide the popup as there should be some content now.
        if (this.autocomplete) {
            // CM-5081 DX 21.1 has screwed up types, this should return a jQuery object too
            jQuery(this.autocomplete.content())?.removeClass(this.emptyPopupClass);
        }

        if (results) {
            // flatten results into normal array
            const flatResults = Object.keys(results).flatMap((group: keyof ISearchResults) => {
                return results[group]?.map((result) => {
                    return { result, type: SearchType[group] };
                }).slice(0, 3);
            });

            if (flatResults.length === 0) {
                return [
                    { type: this.NoResultsFoundType },
                    { type: this.LoadMoreResultsButtonType },
                ];
            }

            return [
                ...flatResults,
                // load more results button
                { type: this.LoadMoreResultsButtonType },
            ];
        }

        return results;
    }

    private exitPopup(component: dxAutocomplete) {
        component.blur();
        component.close();

        // resetting the store will stop the result list from showing up when we don't want it to.
        this.reset();
    }
}
