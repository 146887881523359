<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div class="mb-4">
            <h3>Name</h3>
            <dx-text-box #focus
                         [value]="systemComponent.name"
                         (valueChange)="onNameChange($event)"
                         [adaptValidateEntity]="systemComponent"
                         adaptValidateEntityProperty="name">
            </dx-text-box>
        </div>
        <div class="mb-4">
            <h3>External page
                <i class="fal fa-fw fa-info-circle"
                   adaptTooltip="Add a link to display content from any embeddable, external web page.
                                These links will commonly be known as 'embed codes' or 'shareable links'.">
                </i>
            </h3>

            <dx-text-box *ngIf="systemComponent.systemDocument"
                         [(value)]="systemComponent.systemDocument.url"
                         (valueChange)="onUrlChanged($event)"
                         [isValid]="!!systemComponent.systemDocument.content"
                         [validationError]="{ message: 'Invalid link' }"
                         placeholder="Paste a URL"></dx-text-box>
        </div>
        <div class="mb-4"
             *ngIf="!!systemComponent.systemDocument?.content">
            <h3>Preview</h3>
            <adapt-system-external-link [component]="systemComponent"></adapt-system-external-link>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveIsDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
