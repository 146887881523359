<dx-chart [dataSource]="chartData"
          [pathModified]="true">

    <dxi-series name="Actual"
                valueField="averageActual">
        <dxo-label [visible]="false"></dxo-label>
    </dxi-series>
    <dxi-series name="Differential"
                valueField="averageDifferential">
        <dxo-label [visible]="false"></dxo-label>
    </dxi-series>

    <dxo-size [height]="150"></dxo-size>
    <dxo-common-series-settings argumentField="name"
                                type="stackedbar"
                                tagField="id">
        <dxo-label [visible]="true">
            <dxo-format type="fixedPoint"
                        [precision]="0"></dxo-format>
        </dxo-label>
    </dxo-common-series-settings>
    <dxo-argument-axis>
        <dxo-label overlappingBehavior="stagger"
                   wordWrap="none"></dxo-label>
    </dxo-argument-axis>
    <dxo-value-axis [visualRange]="[0]"
                    visualRangeUpdateMode="keep">
    </dxo-value-axis>
    <dxo-tooltip [enabled]="false"></dxo-tooltip>
    <dxo-legend [visible]="false"></dxo-legend>
</dx-chart>
