<div *adaptLoading="isLoading">
    <dx-data-grid noDataText="No Career Valuations"
                  [dataSource]="data"
                  [allowColumnResizing]="true"
                  [allowColumnReordering]="true"
                  [wordWrapEnabled]="true"
                  [rowAlternationEnabled]="true"
                  [hoverStateEnabled]="true"
                  [showRowLines]="true"
                  [showBorders]="true"
                  [stateStoring]="dxGridHelper.stateStoringOptions"
                  (onInitialized)="onGridInitialized($event)"
                  (onExporting)="onGridExporting($event)">
        <dxo-paging [pageSize]="25"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[15, 25, 50, 100, 250]"></dxo-pager>
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-scrolling mode="native"></dxo-scrolling>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
        <dxo-column-chooser [enabled]="true">
        </dxo-column-chooser>
        <dxo-export [enabled]="true"
                    fileName="Career Valuation Analysis">/</dxo-export>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxi-column caption="Person"
                    dataField="person.fullName"
                    dataType="string"
                    cellTemplate="personCellTemplate"
                    [width]="200"></dxi-column>

        <dxi-column [caption]="culturalLeadershipConfiguration.primaryName"
                    dataField="dummy_culturalleader"
                    dataType="string"
                    [calculateCellValue]="calculateCulturalLeadersCellValue"
                    [calculateGroupValue]="calculateCulturalLeadersGroupValue"
                    [customizeText]="culturalLeadersCustomizeText"
                    [groupIndex]="0"
                    [allowSorting]="true"
                    [allowGrouping]="true"
                    [allowFiltering]="true"
                    cellTemplate="culturalLeaderCellTemplate"
                    groupCellTemplate="culturalLeaderGroupCellTemplate"
                    [width]="200"></dxi-column>

        <dxi-column caption="Career Valuation"
                    dataField="careerValuation.extensions.actualTotal"
                    dataType="number"
                    [customizeText]="this.careerValuationUiService.customiseValueCellText"
                    [cellTemplate]="this.careerValuationUiService.getValueCellTemplate"
                    [headerFilter]="headerFilter"
                    [width]="150"></dxi-column>

        <dxi-column caption="Date"
                    dataField="careerValuation.creationDate"
                    dataType="date"
                    [format]="dateFormat"
                    [width]="100"></dxi-column>

        <dxi-column caption="Notes"
                    dataField="careerValuation.notes"
                    dataType="string"
                    [encodeHtml]="false"
                    cellTemplate="richTextTemplate"></dxi-column>

        <ng-container *ngFor="let careerValuationCategory of categories; index as i">

            <dxi-column caption="{{careerValuationCategory.name}} notes"
                        dataField="careerValuation.categoryValues[{{i}}].notes"
                        dataType="string"
                        [encodeHtml]="false"
                        cellTemplate="richTextTemplate"
                        [visible]="false"></dxi-column>

            <dxi-column caption="{{careerValuationCategory.name}} actual"
                        dataField="careerValuation.categoryValues[{{i}}].actualValue"
                        dataType="number"
                        [visible]="false"></dxi-column>

            <dxi-column caption="{{careerValuationCategory.name}} ideal"
                        dataField="careerValuation.categoryValues[{{i}}].idealValue"
                        dataType="number"
                        [visible]="false"></dxi-column>
        </ng-container>

        <!-- END COLUMNS -->

        <div *dxTemplate="let item of 'personCellTemplate'">
            <adapt-link-person [person]="item.data.person"
                               [preventWrap]="true"></adapt-link-person>
        </div>

        <div *dxTemplate="let item of 'culturalLeaderCellTemplate'">
            <adapt-link-person [person]="item.data.culturalLeader"
                               [preventWrap]="true"
                               null-value-text="None allocated"></adapt-link-person>
        </div>

        <div *dxTemplate="let item of 'culturalLeaderGroupCellTemplate'">
            <div [ngSwitch]="!!item.value">
                <div *ngSwitchCase="true">
                    <span>{{culturalLeadershipConfiguration.primaryName}}</span>:
                    <adapt-link-person [personId]="item.value"
                                       [preventWrap]="true"></adapt-link-person>
                </div>
                <div *ngSwitchCase="false">
                    No <span>{{culturalLeadershipConfiguration.primaryName}}</span>
                </div>
            </div>
        </div>

        <div *dxTemplate="let item of 'richTextTemplate'">
            <div [froalaView]="item.value"></div>
        </div>
    </dx-data-grid>
</div>
