import { Component, Input, OnInit } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { Observable, of } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { valueStreamDashboardPageRoute } from "../value-stream-dashboard-page/value-stream-dashboard-page.route";

@Component({
    selector: "adapt-display-value-stream-summary-dashboard-element",
    styleUrls: ["./display-vs-summary-dashboard-element.component.scss"],
    templateUrl: "./display-vs-summary-dashboard-element.component.html",
})
export class DisplayValueStreamSummaryDashboardElementComponent extends BaseComponent implements OnInit {

    @Input() public valueStream!: ValueStream;

    public keyFunctions$!: Observable<KeyFunction[]>;
    public leaderRole$: Observable<Role | undefined> = of(undefined);
    public url$?: Observable<string>;

    public constructor(
        private keyFunctionService: KeyFunctionsService,
        private tier1Service: Tier1ArchitectureService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.keyFunctions$ = this.keyFunctionService.getKeyFunctionsInLocation(KeyFunctionLocation.fromValueStream(this.valueStream));
        this.leaderRole$ = this.tier1Service.getLeaderRoleForValueStreamId(this.valueStream.valueStreamId);
        this.url$ = valueStreamDashboardPageRoute.getRoute(this.valueStream);
    }
}
