import { Injectable } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { organisationEditObjectivePageRoute } from "@org-common/lib/objectives/edit-objective-page/edit-objective-page.route";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { managePeoplePageRoute } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { valueStreamDashboardPageRoute } from "app/features/architecture/value-streams/value-stream-dashboard-page/value-stream-dashboard-page.route";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { systemsPageRoute } from "app/features/systemisation/systems-page/systems-page.route";
import { organisationManageAccessPageRoute } from "app/organisation/manage-access-page/manage-access-page.route";
import { organisationDashboardPageRoute } from "app/organisation/organisation-dashboard-page/organisation-dashboard-page.route";
import { lastValueFrom, Observable } from "rxjs";
import { organisationConfigurationPageRoute } from "../configuration/organisation/organisation-configuration-page/organisation-configuration-page.route";

@Injectable()
export class OrganisationDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown>;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
        private objectivesService: ObjectivesService,
        private systemisationService: SystemisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        this.activeNodeShouldBeRebuilt$ = rxjsBreezeService.entityTypeChangedInSave(Objective);
    }

    public buildDynamicNodes() {
        return [
            // this is for archived value stream
            this.buildDynamicValueStreamNode(),
            this.buildDynamicEditObjectiveNode(),
            this.buildDynamicConfigurationNode(),
            this.buildDynamicManagePeopleNode(),
            this.buildDynamicManageAccessNode(),
            this.buildDynamicSystemNode(),
        ];
    }

    private buildDynamicSystemNode() {
        const dynamicTitleCallback = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<SystemEntity>({
            entityIdParamName: "systemEntityId",
            getEntityByParamCallback: (id) => {
                return lastValueFrom(this.systemisationService.getSystemById(id));
            },
            getValue: (system) => system.name,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(systemPageRoute.id)
            .setDynamicParentController(systemsPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleCallback)
            .build();
    }

    private buildDynamicValueStreamNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions({
            entityIdParamName: "valueStreamId",
            getEntityByParamCallback: (id: number) => this.commonDataService.getById(ValueStreamBreezeModel, id),
            getValue: (valueStream) => valueStream.name,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(valueStreamDashboardPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }

    private buildDynamicEditObjectiveNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions({
            entityIdParamName: "objectiveId",
            getEntityByParamCallback: (id: number) => this.objectivesService.getObjectiveById(id),
            getValue: (objective) => objective.title,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(organisationEditObjectivePageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(organisationObjectivesPageRoute.id)
            .build();
    }

    private buildDynamicConfigurationNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(organisationConfigurationPageRoute.id)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }

    private buildDynamicManagePeopleNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(managePeoplePageRoute.id)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }

    private buildDynamicManageAccessNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(organisationManageAccessPageRoute.id)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }
}
