import { Component, Input, OnDestroy } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { forkJoin, ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-systems",
    templateUrl: "./person-profile-systems.component.html",
})
export class PersonProfileSystemsComponent extends BaseComponent implements OnDestroy {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    public systemsOwner?: number[];
    public systemsKnowledgeExpert?: number[];

    private person$ = new ReplaySubject<Person | undefined>();

    constructor(
        systemisationService: SystemisationService,
    ) {
        super();

        this.person$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((person) => forkJoin([
                systemisationService.GetSystemForOwner(person),
                systemisationService.GetSystemForKnowledgeExpert(person),
            ])),
            tap(([owner, knowledgeExpert]) => {
                this.systemsOwner = owner.map((x) => x.systemEntityId);
                this.systemsKnowledgeExpert = knowledgeExpert.map((x) => x.systemEntityId);
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get systems() {
        if (!this.systemsOwner || !this.systemsKnowledgeExpert) {
            return undefined;
        }
        return new Set<number>([...this.systemsOwner, ...this.systemsKnowledgeExpert]);
    }

    public accessoryText(systemEntityId: number) {
        const accessoryText: string[] = [];

        if (this.systemsOwner?.includes(systemEntityId)) {
            accessoryText.push("Owner");
        }

        if (this.systemsKnowledgeExpert?.includes(systemEntityId)) {
            accessoryText.push("Knowledge Expert");
        }

        if (accessoryText.length > 0) {
            return `(${accessoryText.join(", ")})`;
        }

        return undefined;
    }

    public ngOnDestroy() {
        this.person$.complete();
    }
}
