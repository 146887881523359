import { Component, Injector, OnInit } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IKeyFunctionLocation, KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { ItemRenderedEvent } from "devextreme/ui/list";
import { lastValueFrom } from "rxjs";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { LocationItem, OrganisationMapService } from "../organisation-map.service";

@Component({
    selector: "adapt-edit-organisation-map-dialog",
    templateUrl: "./edit-organisation-map-dialog.component.html",
    styleUrls: ["./edit-organisation-map-dialog.component.scss"],
})
export class EditOrganisationMapDialogComponent extends BaseDialogWithDiscardConfirmationComponent<void> implements OnInit {
    public readonly dialogName = "ReorderOrganisationMap";

    public getAsTeamLocation = OrganisationMapService.getAsTeamLocation;
    public getAsKeyFunction = OrganisationMapService.getAsKeyFunction;

    public locationItems?: LocationItem[] = [];
    private locationItemsUpdater = this.createThrottledUpdater<LocationItem[] | undefined>((locItems) => this.locationItems = locItems);

    public location?: IKeyFunctionLocation;
    private modifiedItems = new Set<LocationItem>();

    public constructor(
        injector: Injector,
        private orgMapService: OrganisationMapService,
        private tier1Service: Tier1ArchitectureService,
    ) {
        super(injector, DialogResolveData.NotRequired);
    }

    public async ngOnInit() {
        const valueStreams = await lastValueFrom(this.tier1Service.getActiveValueStreams());
        if (valueStreams.length > 0) {
            this.location = KeyFunctionLocation.fromValueStream(valueStreams[0]);
        } else {
            this.location = KeyFunctionLocation.fromZone(ZoneMetadata.InOrder[0]);
        }
    }

    public get noChanges() {
        return this.modifiedItems.size === 0;
    }

    public get entitiesToConfirm() {
        return Array.from(this.modifiedItems);
    }

    @Autobind
    public async saveAndClose() {
        await lastValueFrom(this.commonDataService.saveEntities(this.entitiesToConfirm));
        this.resolve();
    }

    public async updateLocationList(location: IKeyFunctionLocation) {
        this.location = location;
        this.locationItemsUpdater.next(undefined); // So we show the loading spinner
        this.locationItemsUpdater.next(await lastValueFrom(this.orgMapService.getLocationItems(location)));
    }

    public reorderItems(e: IDxListItemReorderedEvent<LocationItem>) {
        if (!this.locationItems) {
            return;
        }

        SortUtilities.reorderItemInIntegerSortedArray(this.locationItems, "ordinal", e.fromIndex, e.toIndex);

        // We could be smart and only add the key functions that actually changed, but they will be filtered out by
        // breeze on save anyway
        for (const item of this.locationItems) {
            this.modifiedItems.add(item);
        }
    }

    public onItemRendered(item: ItemRenderedEvent) {
        // don't allow deletion of key functions
        if (OrganisationMapService.getAsKeyFunction(item.itemData)) {
            jQuery(item.itemElement).addClass("disable-list-item");
        }
    }

    public async onItemDeleted({ itemData }: IDxListItemDeletedEvent<LocationItem>) {
        await lastValueFrom(this.commonDataService.remove(itemData));
        this.modifiedItems.add(itemData!);
        await this.updateLocationList(this.location!);
    }

    public async entityAdded(item: LocationItem) {
        this.modifiedItems.add(item);
        await this.updateLocationList(this.location!);
    }
}
