<adapt-dialog [maxWidth]="900"
              [padding]="false">
    <h3 adapt-dialog-title>{{title}}</h3>

    <dx-tab-panel adapt-dialog-content
                  class="configure-access-level-dialog"
                  tabsPosition="left"
                  [items]="items">
        <div *dxTemplate="let item of 'detailTemplate'">
            <div>
                <label>Name</label>
                <dx-text-box [(value)]="data.role.label"
                             (onValueChanged)="onRoleLabelChanged()"
                             placeholder="Enter a name"
                             [disabled]="isSystemAllocatedRole || isRoleAccessReadOnly"
                             data-test="role-name-input"
                             [adaptValidateEntity]="data.role"
                             adaptValidateEntityProperty="label"
                             #focus>
                </dx-text-box>
            </div>
        </div>

        <div *dxTemplate="let item of 'peopleTemplate'">
            <div *ngIf="isSystemAllocatedRole"
                 class="alert alert-info">{{data.role.label}} is a system role that cannot be allocated here.</div>
            <div *ngIf="isRoleAccessReadOnly"
                 class="alert alert-info">
                {{data.role.label}} is a system role that is set by the user type.
                <br /><br />
                Use the manage people page to configure users allocated to this role.
            </div>
            <div class="people-grid">
                <adapt-link-person *ngFor="let rc of activeRoleConnections"
                                   [person]="rc.connection.person"
                                   [showImage]="true"
                                   [adaptLinkDisabled]="true"></adapt-link-person>
            </div>
        </div>

        <div *dxTemplate="let item of 'editPeopleTemplate'">
            <table>
                <tr>
                    <th>Person</th>
                    <th>Start date</th>
                    <th></th>
                </tr>
                <tr *ngFor="let rc of activeRoleConnections">
                    <td class="p-2">
                        <adapt-link-person [person]="rc.connection.person"
                                           [showImage]="true"
                                           [adaptLinkDisabled]="true"></adapt-link-person>
                    </td>
                    <td>
                        {{rc.startDate | adaptDate}}
                    </td>
                    <td>
                        <button adaptButtonIcon="delete"
                                class="ms-3 btn btn-link"
                                (click)="permissionRemoved(rc)">Remove person
                        </button>
                    </td>
                </tr>
                <tr>
                    <adapt-select-person [filter]="personFilter"
                                         [(person)]="potentialPerson"
                                         (personChange)="newPersonChanged($event)"></adapt-select-person>
                </tr>
            </table>
        </div>

        <div *dxTemplate="let item of 'permissionsTemplate'">
            <div *ngIf="data.role.extensions.isCulturalLeaderRole()"
                 class="alert alert-info">Cultural Leader permissions can only be configured in Cultural Leadership
                Framework section of Configure Organisation page.
            </div>
            <adapt-configure-role-access [role]="data.role"
                                         [readOnly]="isRoleAccessReadOnly"
                                         [(addedPermissions)]="changes.addedPermissions"
                                         [(deletedPermissions)]="changes.deletedPermissions"></adapt-configure-role-access>
        </div>
    </dx-tab-panel>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!data.role.label"
                                   (saveClick)="ok()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
