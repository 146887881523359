import { CulturalIndex } from "./cultural-index";
import { ICulturalMeasurementEntityExtensions } from "./cultural-measurement-entity-extensions.interface";

export class CulturalIndexExtensions implements ICulturalMeasurementEntityExtensions {
    public constructor(private culturalIndex: CulturalIndex) { }

    public isCategory1() {
        return this.culturalIndex.value <= 0.5;
    }

    public isCategory2() {
        return this.culturalIndex.value > 0.5 && this.culturalIndex.value <= 1.0;
    }

    public isCategory3() {
        return this.culturalIndex.value > 1.0 && this.culturalIndex.value <= 1.5;
    }

    public isCategory4() {
        return this.culturalIndex.value > 1.5;
    }
}
