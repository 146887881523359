import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";

@Injectable({
    providedIn: "root",
})
export class CulturalIndexAuthService {
    public static readonly ReadMyCulturalIndex = "readMyCulturalIndex";
    public static readonly ReadAnyCulturalIndex = "readAnyCulturalIndex";
    public static readonly ReadQuantitativeCulturalIndex = "readQuantitativeCulturalIndex";
    public static readonly ReadAnonymousCulturalIndex = "readAnonymousCulturalIndex";
    public static readonly OpenCulturalIndexAnalysis = "openCulturalIndexAnalysis";
    public static readonly ReadCulturalIndexForPerson = "readCulturalIndexForPerson";
    public static readonly EditCulturalIndexForPerson = "editCulturalIndexForPerson";

    public constructor(
        private authorisationService: AuthorisationService,
        private clfAuthService: CulturalLeadershipFrameworkAuthService,
    ) {
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.ReadMyCulturalIndex,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalIndexPersonalUseAndRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.ReadAnyCulturalIndex,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalIndexPersonalUseAndRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexPersonalEdit,
                    FeaturePermissionName.CulturalNetworkCulturalIndexRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.ReadQuantitativeCulturalIndex,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalIndexRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.ReadAnonymousCulturalIndex,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalIndexRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.OpenCulturalIndexAnalysis,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.ReadCulturalIndexForPerson,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(CulturalIndexAuthService);
                    return authService.personCanReadForPerson;
                },
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalIndexAuthService.EditCulturalIndexForPerson,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(CulturalIndexAuthService);
                    return authService.personCanEditForPerson;
                },
            },
        );
    }

    @Autobind
    public currentPersonCanEditCulturalIndex() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.authorisationService.personHasPermission(this.authorisationService.currentPerson, FeaturePermissionName.CulturalNetworkCulturalIndexPersonalEdit);
    }

    @Autobind
    public currentPersonHasAtLeastQuantitativeAccess() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.authorisationService.personHasAtLeastOnePermission(this.authorisationService.currentPerson, [
            FeaturePermissionName.CulturalNetworkCulturalIndexRead,
            FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
        ]);
    }

    @Autobind
    public personCanReadForPerson(personToCheckPermission: Person, ciPerson: Person) {
        return this.clfAuthService.canReadCulturalEntity({
            culturalFeature: FeatureName.CulturalNetworkCulturalIndex,
            authorisingPerson: personToCheckPermission,
            culturalEntityPerson: ciPerson,
        });
    }

    @Autobind
    public personCanEditForPerson(personToCheckPermission: Person, ciPerson: Person) {
        return this.clfAuthService.canEditCulturalEntity({
            culturalFeature: FeatureName.CulturalNetworkCulturalIndex,
            authorisingPerson: personToCheckPermission,
            culturalEntityPerson: ciPerson,
        });
    }
}
