<div *adaptLoading="isLoading"
     class="display-cultural-index-organisation-orbit-statistics">
    <ng-container *ngIf="culturalIndexesHaveBeenRecorded; else noDataTemplate">
        <div class="card">
            <div class="card-body">
                <div class="active-people">
                    <span class="number"
                          data-test="active-people">{{statisticsData?.totalPeople}}</span>
                    people
                </div>
                <div class="missing-catchups-accordion">
                    <dx-accordion [dataSource]="[statisticsData?.missingCulturalIndexes]"
                                  [collapsible]="true"
                                  [selectedIndex]="-1"
                                  itemTemplate="itemTemplate"
                                  itemTitleTemplate="itemTitleTemplate"
                                  [hidden]="!(hasAtLeastQuantitativePermissions && statisticsData?.missingCulturalIndexes?.length)">
                        <div *dxTemplate="let item of 'itemTitleTemplate'">
                            {{statisticsData?.missingCulturalIndexes?.length}} people without a cultural index
                        </div>
                        <div *dxTemplate="let item of 'itemTemplate'">
                            <dx-list [height]="240"
                                     [dataSource]="statisticsData?.missingCulturalIndexes"
                                     itemTemplate="item">
                                <div *dxTemplate="let person of 'item'">
                                    <adapt-link-person [person]="person"
                                                       [showImage]="true"></adapt-link-person>
                                </div>
                            </dx-list>
                        </div>
                    </dx-accordion>
                </div>
            </div>
        </div>

        <div class="card"
             *ngIf="hasAtLeastQuantitativePermissions">
            <div class="card-header">
                <h2 class="card-title">Overdue catch-ups</h2>
            </div>

            <dx-pie-chart class="overdue-catchups-chart card-body"
                          [dataSource]="statisticsData?.overdueCatchups"
                          [palette]="CatchupStatusPaletteName">
                <dxo-margin [left]="60">
                </dxo-margin>
                <dxi-series type="pie"
                            argumentField="label"
                            valueField="value">
                    <dxo-label [visible]="true"
                               textOverflow="none">
                        <dxo-connector [visible]="true"
                                       [width]="1">
                        </dxo-connector>
                    </dxo-label>
                </dxi-series>
                <dxo-legend [visible]="true">
                </dxo-legend>
            </dx-pie-chart>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Average Cultural Index</h2>
            </div>
            <div class="card-body">
                <dx-circular-gauge [value]="statisticsData?.averageCulturalIndex">
                    <dxo-range-container>
                        <dxi-range *ngFor="let range of rangeContainer.ranges"
                                   [startValue]="range.startValue"
                                   [endValue]="range.endValue"
                                   [color]="range.color"></dxi-range>
                    </dxo-range-container>
                    <dxo-scale [startValue]="0"
                               [endValue]="2"
                               [tickInterval]="0.1">
                        <dxo-label [useRangeColors]="true">
                        </dxo-label>
                    </dxo-scale>
                    <dxo-tooltip [enabled]="true">
                        <dxo-format type="fixedPoint"
                                    [precision]="2">
                        </dxo-format>
                    </dxo-tooltip>
                </dx-circular-gauge>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Cultural Index totals</h2>
            </div>
            <div class="card-body">
                <dx-chart class="cultural-index-totals-chart"
                          [dataSource]="[statisticsData]"
                          [rotated]="true">
                    <dxo-common-series-settings argumentField="totalPeople"
                                                type="bar">
                        <dxo-label [visible]="true">
                        </dxo-label>
                    </dxo-common-series-settings>

                    <dxo-argument-axis>
                        <dxo-label [visible]="false">
                        </dxo-label>
                    </dxo-argument-axis>

                    <dxi-series *ngFor="let s of series"
                                [valueField]="s.valueField"
                                [name]="s.name"
                                [color]="s.color">
                    </dxi-series>
                </dx-chart>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <adapt-no-cultural-measurement label="Cultural Indexes"></adapt-no-cultural-measurement>
    </ng-template>
</div>
