<adapt-feature-guidance [configItem]="configItem">
    <p>The resilient business assessment collects quantitative data from everyone in the business. This survey will assist in understanding
        how resilient your organisation is.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem"
                             (featureChange)="onFeatureStatusChanged($event)"></adapt-feature-configuration>

<div *ngIf="resilientBusinessSurveyConfiguration && currentStatus"
     class="row">
    <div class="col-sm-12 col-md-6">
        <label>Select the team that will be classified as Leader in the resilient business assessments</label>
        <adapt-select-team [(team)]="resilientBusinessSurveyConfiguration!.leaderTeam"
                           [required]="true"></adapt-select-team>
    </div>
</div>

<adapt-display-impact-surveys [hasChanges]="configurationService.hasChanges()"
                              [currentFeatureStatus]="currentStatus"
                              [surveyType]="surveyType"></adapt-display-impact-surveys>
