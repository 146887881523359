import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { systemPageRoute } from "../system-page/system-page.route";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-link-system",
    templateUrl: "./link-system.component.html",
    styleUrls: ["./link-system.component.scss"],
})
export class LinkSystemComponent implements OnChanges {
    @Input() public system?: SystemEntity;
    @Input() public showIcon = true;
    @Input() public showNavIcon = false;
    @Input() public showLabels = false;
    @Input() public systemEntityId?: number;
    @Input() public accessoryText?: string;

    public icon = SystemEntity.IconClass;
    public href$: Observable<string | null> = of(null);

    public constructor(
        private systemisationService: SystemisationService,
    ) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.system && this.system) {
            this.href$ = this.system && !this.system.entityAspect.entityState.isAdded()
                ? systemPageRoute.getRoute(this.system)
                : of(null);
        } else if (changes.systemEntityId && this.systemEntityId) {
            this.href$ = this.systemisationService.getSystemById(this.systemEntityId).pipe(
                emptyIfUndefinedOrNull(),
                switchMap((system) => {
                    this.system = system;
                    if (!system.entityAspect.entityState.isAdded()) {
                        return systemPageRoute.getRoute(system);
                    } else {
                        return of(null);
                    }
                }),
            );
        }
    }
}
