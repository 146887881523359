import { Injectable } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { itemPageRoute } from "@org-common/lib/kanban/items/item-page/item-page.route";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { personalKanbanPageRoute, teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { EMPTY, lastValueFrom } from "rxjs";

@Injectable()
export class ItemsDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private kanbanService: KanbanService,
    ) {
    }

    public buildDynamicNodes() {
        return [
            this.buildItemsDynamicNode(),
        ];
    }

    private buildItemsDynamicNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(itemPageRoute.id)
            .setDynamicNodeCallback(this.dynamicItemNodeCallback)
            .build();
    }

    @Autobind
    private async dynamicItemNodeCallback(nodeTemplate: INavigationNode, namedParams: any) {
        const itemCodeOrId = namedParams.itemCodeOrId;

        if (!itemCodeOrId) {
            throw new Error("itemCodeOrId is a required parameter");
        }

        let builder = this.navUtilitiesFactory.nodeBuilderForControllerAndParams(nodeTemplate.controller!, namedParams)
            .setIsDynamic();

        const item: Item | undefined = await lastValueFrom(this.kanbanService.getItemByCodeOrId(itemCodeOrId));
        if (item && item.board) {
            builder = builder
                .promiseToAddParent(this.promiseToGetParentFromBoard(item.board))
                .setTitle(item.code);
        } else {
            builder = builder.setTitle("Kanban Item");
        }

        return builder
            .promiseToBuild();
    }

    private async promiseToGetParentFromBoard(board: Board) {
        const url = board.teamId
            ? await lastValueFrom(teamKanbanPageRoute.getRoute({ teamId: board.teamId }))
            : await lastValueFrom(personalKanbanPageRoute.getRoute({ personId: board.personId }));
        const parentNode = await this.navUtilitiesFactory.promiseToGetNodeByUrl(url);

        if (parentNode) {
            return parentNode;
        } else {
            return undefined;
        }
    }
}
