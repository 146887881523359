import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { TeamsService } from "app/features/people/teams/teams.service";

@Component({
    selector: "adapt-configure-team-peer-catch-up",
    templateUrl: "./configure-team-peer-catch-up.component.html",
})
export class ConfigureTeamPeerCatchUpComponent extends ConfigureFeatureBase {

    @Input() public team!: Team;
    @Input() public configItem!: IConfigItem;

    public viewAllSpeedCatchups = false;

    public readonly ReadAccessSelections = [
        {
            text: "All team members",
            value: true,
        },
        {
            text: "Only the team leader",
            value: false,
        },
    ];

    public constructor(
        configurationService: ConfigurationService,
        private teamsService: TeamsService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.viewAllSpeedCatchups = await this.teamsService.promiseToVerifyTeamMemberRoleHasSpeedCatchupReadPermission(this.team);
    }

    public async valueChanged() {
        await this.teamsService.promiseToSetTeamMemberRoleSpeedCatchupPermission(this.team, this.viewAllSpeedCatchups);
    }
}
