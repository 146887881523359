import { Component, Inject } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ICulturalIndexHistoryComponentOptions } from "../ci-history/ci-history.component";

@Component({
    selector: "adapt-ci-history-dialog",
    templateUrl: "./ci-history-dialog.component.html",
})
export class CulturalIndexHistoryDialogComponent extends BaseDialogComponent<CulturalIndex> {
    public readonly dialogName = "CulturalIndexHistory";
    public title = "Cultural Index history";

    public culturalIndexHistoryOptions: ICulturalIndexHistoryComponentOptions = {};

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
    ) {
        super();
    }

    @Autobind
    public exportGrid() {
        if (this.culturalIndexHistoryOptions.exportData) {
            this.culturalIndexHistoryOptions.exportData();
        }
    }
}
