import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CulturalIndexAnalysisFilter } from "../cultural-index-analysis-filter";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";

@Component({
    selector: "adapt-ci-orbit-page",
    templateUrl: "./ci-orbit-page.component.html",
})
export class CulturalIndexOrbitPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly CareerValuationFilterParamKeys = Object.values(CulturalDataFilterParamKeys);

    public hasAtLeastQuantitativePermissions: boolean;
    public showComparison = false;

    public filterParameters = CulturalIndexAnalysisFilter.getDefaultFilterParameters();

    public constructor(
        public filterService: CulturalIndexFilterService,
        culturalIndexAuthService: CulturalIndexAuthService,
        injector: Injector,
    ) {
        super(injector);

        this.hasAtLeastQuantitativePermissions = culturalIndexAuthService.currentPersonHasAtLeastQuantitativeAccess();

        this.filterService.reset();
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}
