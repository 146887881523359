import { Component, Inject, Injector, QueryList, ViewChild, ViewChildren, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { SpeedCatchup, SpeedCatchupBreezeModel } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingType } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DateFormats } from "@common/ux/date-formats";
import { AdaptHtmlEditorComponent } from "@common/ux/html-editor/html-editor.component";
import { EMPTY, Subject } from "rxjs";
import { debounceTime, filter, switchMap, take, tap } from "rxjs/operators";
import { EditCatchupRatingTypeComponent } from "../edit-catchup-rating-type/edit-catchup-rating-type.component";
import { PeerCatchupService } from "../peer-catchup.service";
import { PeerCatchupAuthService } from "../peer-catchup-auth.service";
import { PeerCatchupUiService } from "../peer-catchup-ui.service";

export interface IShowCatchupDialogData {
    catchup: SpeedCatchup;
    hideHistoryButton?: boolean;
    enterEditMode?: boolean;
    showFacilitatorSection?: boolean;
    focusRatingType?: CatchupRatingType;
}

interface IAccordionEntry {
    ratingType?: CatchupRatingType;
    template: string;
    titleTemplate: string;
}

@Component({
    selector: "adapt-show-catchup-dialog",
    templateUrl: "./show-catchup-dialog.component.html",
    styleUrls: ["./show-catchup-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
@PersistableDialog("ShowCatchupDialog")
export class ShowCatchupDialogComponent extends BaseDialogComponent<SpeedCatchup> {
    public readonly dialogName = "ShowCatchupDialog";
    public readonly DateFormats = DateFormats;

    public title = SpeedCatchupBreezeModel.singularName;
    public accordionEntries: IAccordionEntry[] = [];
    // cannot use accordionEntries what that's set as it will cause error from dxAccordion as dataSource is only processed later
    public selectedEntries: IAccordionEntry[] = [];

    public catchupRatingTypes = [...CatchupRatingType.All];
    public canEditCatchup = false;
    public showGuidance = false;
    public isEditing = false;
    public isEditingUpdater = this.createThrottledUpdater((isEditing: boolean) => {
        this.isEditing = isEditing;
        this.editingUpdated.next();
    });

    public catchup: SpeedCatchup;

    @ViewChild("catchupFacilitatorSection", { read: ViewContainerRef }) private facilitatorSection?: ViewContainerRef;
    private scrollFacilitatorIntoView = new Subject<void>();
    @ViewChild("catchupFacilitatorEditor") private facilitatorEditor?: AdaptHtmlEditorComponent;

    @ViewChildren(EditCatchupRatingTypeComponent) private ratingTypeComponents!: QueryList<EditCatchupRatingTypeComponent>;
    private scrollToRatingTypeSection = new Subject<void>();
    private catchupUiService: PeerCatchupUiService;

    private editingUpdated = new Subject<void>();

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IShowCatchupDialogData,
        private catchupService: PeerCatchupService,
        catchupAuthService: PeerCatchupAuthService,
        private dialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super();

        // cannot inject this in the constructor declaration once using PersistableDialog as that's adding this dialog Type which will attempt injection
        // which will also has dependency on this type
        this.catchupUiService = injector.get(PeerCatchupUiService);
        this.catchup = data.catchup;
        catchupAuthService.canEditCatchup(this.catchup).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canEditCatchup) => {
            this.canEditCatchup = canEditCatchup;

            this.initAccordionEntries();
            if (data.enterEditMode) {
                this.isEditingUpdater.next(true);
            }
        });

        this.scrollFacilitatorIntoView.pipe(
            filter(() => !!this.data.showFacilitatorSection), // not going to emit if not scrolling to view
            debounceTime(1500), // debounce 1.5 sec (dialog animation) as this till be triggerred from itemRendered from accordion -> only want to scroll once
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.facilitatorSection?.element.nativeElement.scrollIntoView();
            if (this.isEditing) {
                this.facilitatorEditor?.getElementToFocus();
            }
        });

        this.scrollToRatingTypeSection.pipe(
            filter(() => !!this.data.focusRatingType),
            debounceTime(1500),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            const ratingTypeComponent = this.ratingTypeComponents.find((i) => i.ratingType === this.data.focusRatingType);
            ratingTypeComponent?.scrollIntoView();
        });
    }

    public onAccordionItemRendered() {
        // this is called when accordion items are rendered - debounceTime from the followings will take care of duplicate triggers
        this.scrollFacilitatorIntoView.next();
        this.scrollToRatingTypeSection.next();
    }

    public get areBothPeopleActive() {
        return !!(this.catchup?.person1?.isActive() && this.catchup?.person2?.isActive());
    }

    public get isCatchupDetached() {
        return this.catchup?.entityAspect.entityState.isDetached() || this.catchup?.entityAspect.entityState.isDeleted();
    }

    public get catchupValid() {
        return this.catchup?.entityAspect.validateEntity() &&
            this.catchup.ratings.every((rating) => rating.entityAspect.validateEntity());
    }

    public get hasChanges() {
        return this.catchup?.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            this.catchup?.ratings.some((rating) => rating.entityAspect.entityState.isAddedModifiedOrDeleted());
    }

    public get canSave() {
        // catchup must be valid and either something has changed or enter from Save and review (hide history and enter edit mode)
        return (this.hasChanges || (this.data.hideHistoryButton && this.data.enterEditMode)) && this.catchupValid;
    }

    public deleteCatchup() {
        this.catchupUiService.deleteCatchup(this.catchup).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.cancel());
    }

    public showCatchupHistory() {
        this.catchupUiService.showCatchupHistoryChart(this.catchup.person1!, this.catchup.person2!).subscribe();
    }

    @Autobind
    public saveAndClose() {
        if (this.canSave) {
            return this.catchupService.saveEntities([this.catchup, ...this.catchup.ratings]).pipe(
                tap(() => {
                    this.catchupService.emitCatchupChange(this.catchup);
                    this.resolve(this.catchup);
                }),
            );
        } else {
            // shouldn't happen here as the button should be disabled -> won't emit
            // - this will make the return type correct
            return EMPTY;
        }
    }

    public cancel() {
        if (this.isEditing) {
            if (this.hasChanges) {
                this.dialogService.openConfirmationDialog({
                    title: "Cancel & Discard Changes",
                    message: "Are you sure you want to cancel editing this Peer Catch-up and discard the changes you have made?",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                }).pipe(
                    tap(() => this.isEditingUpdater.next(false)),
                    switchMap(() => this.editingUpdated),
                    take(1),
                    this.takeUntilDestroyed(),
                ).subscribe(() => {
                    // do this in the next digest cycle to avoid directly changing entity from button click
                    setTimeout(() => {
                        this.catchup.entityAspect.rejectChanges();
                        this.catchup.ratings.forEach((rating) => rating.entityAspect.rejectChanges());
                    });
                });
            } else {
                this.isEditingUpdater.next(false);
            }
        } else {
            super.cancel();
        }
    }

    public enterEditMode() {
        this.isEditingUpdater.next(true);
        this.data.enterEditMode = true;
        this.data.focusRatingType = undefined;
        this.data.showFacilitatorSection = undefined;
    }

    private initAccordionEntries() {
        this.accordionEntries = this.catchupRatingTypes.sort((a, b) => a.ordinal - b.ordinal)
            .map((ratingType) => ({
                ratingType,
                template: "ratingTypeTemplate",
                titleTemplate: "ratingTypeTitleTemplate",
            }));
        if (this.catchup.facilitatorPersonId) {
            this.accordionEntries.push({
                template: "facilitatorTemplate",
                titleTemplate: "facilitatorTitleTemplate",
            });
        }

        // next digest cycle to workaround error from dx
        setTimeout(() => this.selectedEntries = this.accordionEntries);
    }
}
