import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHideIfEmptyModule } from "@common/ux/hide-if-empty/hide-if-empty.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptKanbanSharedModule } from "@org-common/lib/kanban/kanban-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptDirectoryModule } from "app/features/people/directory/directory.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { AdaptSystemisationSharedModule } from "app/features/systemisation/systemisation-shared.module";
import { DxCheckBoxModule, DxListModule, DxProgressBarModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptIntegratedArchitectureModule } from "../integrated-architecture/integrated-architecture.module";
import { AdaptIntegratedArchitectureSharedModule } from "../integrated-architecture/integrated-architecture-shared.module";
import { AdaptRoleModule } from "../role/role.module";
import { CopyProcessStepDialogComponent } from "./copy-process-step-dialog/copy-process-step-dialog.component";
import { DeleteProcessMapDialogComponent } from "./delete-process-map-dialog/delete-process-map-dialog.component";
import { DeleteProcessStepDialogComponent } from "./delete-process-step-dialog/delete-process-step-dialog.component";
import { EditProcessMapDialogComponent } from "./edit-process-map-dialog/edit-process-map-dialog.component";
import { EditProcessStepDialogComponent } from "./edit-process-step-dialog/edit-process-step-dialog.component";
import { BoardSelectionStepComponent } from "./exporter/board-selection-step/board-selection-step.component";
import { ExportConfirmationStepComponent } from "./exporter/export-confirmation-step/export-confirmation-step.component";
import { ExportTypeSelectorStepComponent } from "./exporter/export-type-selector-step/export-type-selector-step.component";
import { PrefixSetStepComponent } from "./exporter/prefix-set-step/prefix-set-step.component";
import { ProcessMapExporterDialogComponent } from "./exporter/process-map-exporter-dialog/process-map-exporter-dialog.component";
import { RolePersonAllocationsStepComponent } from "./exporter/role-person-allocations-step/role-person-allocations-step.component";
import { ListProcessMapLinksComponent } from "./list-process-map-links/list-process-map-links.component";
import { MoveProcessStepDialogComponent } from "./move-process-step-dialog/move-process-step-dialog.component";
import { ProcessMapPageComponent } from "./process-map-page/process-map-page.component";
import { processMapPageRoute } from "./process-map-page/process-map-page.route";
import { AdaptProcessMapSharedModule } from "./process-map-shared.module";

@NgModule({
    imports: [
        CommonModule,
        DxTabPanelModule,
        DxTextBoxModule,
        DxListModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        AdaptShellModule,
        AdaptLoadingSpinnerModule,
        AdaptHtmlEditorModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptEntityValidatorModule,
        AdaptKeyFunctionsCommonModule,
        AdaptProcessMapSharedModule,
        AdaptTeamsModule,
        AdaptRoleModule,
        AdaptAuthorisationModule,
        AdaptHideIfEmptyModule,
        AdaptIntegratedArchitectureModule,
        AdaptIntegratedArchitectureSharedModule,
        AdaptSeeMoreModule,
        AdaptDirectoryModule,
        AdaptTeamDashboardSharedModule,
        AdaptKanbanSharedModule,
        AdaptSystemisationSharedModule,
        DxProgressBarModule,
        AdaptLabellingModule,
    ],
    declarations: [
        ListProcessMapLinksComponent,
        EditProcessStepDialogComponent,
        EditProcessMapDialogComponent,
        DeleteProcessMapDialogComponent,
        DeleteProcessStepDialogComponent,
        MoveProcessStepDialogComponent,
        ProcessMapExporterDialogComponent,
        ExportTypeSelectorStepComponent,
        RolePersonAllocationsStepComponent,
        ExportConfirmationStepComponent,
        BoardSelectionStepComponent,
        ProcessMapPageComponent,
        PrefixSetStepComponent,
        CopyProcessStepDialogComponent,
    ],
})
export class AdaptProcessMapModule {
    public static readonly Routes = [
        ...processMapPageRoute.routes,
    ];
}
