
import { Component } from "@angular/core";
import { from, Observable } from "rxjs";
import { culturalIndexOrbitPageRoute } from "../ci-orbit-page/ci-orbit-page.route";

@Component({
    selector: "adapt-cultural-index-organisation-dashboard-element",
    templateUrl: "./ci-organisation-dashboard-element.component.html",
})
export class CulturalIndexOrganisationDashboardElementComponent {

    public url$: Observable<string>;

    public constructor(
    ) {
        this.url$ = from(culturalIndexOrbitPageRoute.getRoute());
    }
}
