<div class="value-stream-dashboard-element-title">
    <span adaptTooltip="The customer roles and their stories or expectations for each role.">
        <i class="fal fa-fw fa-users"></i>
        Customer stories</span>
    <button *ngIf="canEditTier1Architecture$ | async"
            class="btn btn-link"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editCustomerStories"
            [unsubscribeOnDestroy]="false"></button>
</div>

<div *adaptLoading="!customerStories">
    <dx-accordion *ngIf="customerStories!.length > 0; else noCustomersTemplate"
                  [collapsible]="true"
                  [multiple]="true"
                  [selectedIndex]="-1"
                  [dataSource]="customerStories"
                  [deferRendering]="false">
        <div *dxTemplate="let customerStory of 'title'">
            {{customerStory?.stakeholder?.name}}
        </div>
        <div *dxTemplate="let customer of 'item'">
            <ng-container *ngIf="customer.stakeholderStories.length > 0; else noStoriesTemplate">
                <div *ngFor="let customerStory of customer.stakeholderStories; let first = first"
                     [ngClass]="{'pt-3': !first}">
                    <adapt-display-customer-story [customerStory]="customerStory.stakeholderStory">
                    </adapt-display-customer-story>
                </div>
            </ng-container>
            <ng-template #noStoriesTemplate>
                No customer stories defined.
            </ng-template>
        </div>
    </dx-accordion>
    <ng-template #noCustomersTemplate>
        <adapt-call-to-action-inline [featureUrl]="featureUrl$ | async">
            {{CallToActionText.valueStream.customerStories}}
            <button *ngIf="canEditTier1Architecture$ | async"
                    adaptCallToActionButton
                    adaptButtonIcon="edit"
                    [adaptBlockingClick]="editCustomerStories">
                Define a customer role or story
            </button>
        </adapt-call-to-action-inline>
    </ng-template>
</div>
