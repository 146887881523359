<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Merge roles</h3>

    <div adapt-dialog-content
         class="container-fluid merge-roles-dialog-controller">
        <div *ngIf="systemRoleSelectedMessage"
             role="alert"
             class="alert alert-info">
            {{systemRoleSelectedMessage}}
        </div>

        <div class="row center-content">
            <div class="col-6">
                <h3>Merge:</h3>

                <dx-accordion [collapsible]="true"
                              [multiple]="true"
                              [selectedIndex]="-1"
                              [deferRendering]="true"
                              [dataSource]="dialogData.inputRoles"
                              (onInitialized)="this.inputAccordionInstance = $event.component">
                    <div *dxTemplate="let inputRole of 'title'">
                        <adapt-link-role [role]="inputRole"></adapt-link-role>
                    </div>
                    <div *dxTemplate="let inputRole of 'item'">
                        <div *ngIf="getActiveRoleConnections(inputRole)"
                             [ngSwitch]="getActiveRoleConnections(inputRole).length > 10"
                             class="d-flex flex-row align-items-center gap-1 pb-2">
                            <ng-container *ngSwitchCase="true">
                                <adapt-link-person *ngFor="let roleConnection of getActiveRoleConnections(inputRole).slice(0, 10)"
                                                   [person]="roleConnection.connection.person"
                                                   [imageOnly]="true"></adapt-link-person>
                                <a [routerLink]="rolesUrl"
                                   [queryParams]="getParamsForRole(inputRole)">
                                    +{{getActiveRoleConnections(inputRole).length - 10}} more users</a>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <adapt-link-person *ngFor="let roleConnection of getActiveRoleConnections(inputRole)"
                                                   [person]="roleConnection.connection.person"
                                                   [imageOnly]="true"></adapt-link-person>
                            </ng-container>
                        </div>
                        <div *ngIf="inputRole.supplementaryData?.attributes"
                             class="pb-2">
                            <h4>Attributes</h4>
                            <span [froalaView]="inputRole.supplementaryData?.attributes"></span>
                        </div>
                        <div *ngIf="inputRole.supplementaryData?.capabilities"
                             class="pb-2">
                            <h4>Capabilities</h4>
                            <span [froalaView]="inputRole.supplementaryData?.capabilities"></span>
                        </div>
                        <div *ngIf="inputRole.supplementaryData?.relationships"
                             class="pb-2">
                            <h4>Relationships</h4>
                            <span [froalaView]="inputRole.supplementaryData?.relationships"></span>
                        </div>
                        <div class="metadata"
                             [ngSwitch]="roleTaskCounts[inputRole.roleId]">
                            <span *ngSwitchCase="0">
                                No role tasks.
                            </span>
                            <span *ngSwitchCase="1">
                                {{roleTaskCounts[inputRole.roleId]}} role task.
                            </span>
                            <span *ngSwitchDefault>
                                {{roleTaskCounts[inputRole.roleId]}} role tasks.
                            </span>
                        </div>
                    </div>
                </dx-accordion>

            </div>
            <div class="col-2 text-center">
                <h3>
                    INTO <i class="fal fa-fw fa-long-arrow-right"></i>
                </h3>
            </div>
            <div class="col-4">
                <adapt-select-role [role]="selectedOutputRole"
                                   (roleChange)="selectedOutputRole = $event; onOutputSelectionChanged()"
                                   [readonlyModeOnSelection]="readonlyModeOnSelection"
                                   [filter]="roleFilter"
                                   data-test="roles-merge"></adapt-select-role>
            </div>
        </div>
        <div *ngIf="!!selectedOutputRole">
            <br />
            <div>
                The impact of merging roles into <adapt-link-role [role]="selectedOutputRole"></adapt-link-role> is as follows:
            </div>
            <ul>
                <li>The following roles will be deleted (together with their Attributes, Capabilities, Relationships and Systems)
                    <ul>
                        <li *ngFor="let deletedRole of deletedRoles">
                            <adapt-link-role [role]="deletedRole"></adapt-link-role>
                            <div *ngIf="!!findDiscardedHistoricalConnections(deletedRole)"
                                 class="p-1">
                                Historical role allocation for the following people will be deleted as they are already allocated to the
                                destination role:
                                <div class="d-flex flex-row align-items-center gap-1">
                                    <adapt-link-person *ngFor="let connection of findDiscardedHistoricalConnections(deletedRole)?.connections"
                                                       [person]="connection?.person"
                                                       [imageOnly]="true"></adapt-link-person>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!mergingIntoSystemRole && additionalActivePeopleToDestinationRole.length > 0">The following
                    people will be added to <adapt-link-role [role]="selectedOutputRole"></adapt-link-role>
                    <br />
                    <div class="d-flex flex-row align-items-center gap-1 pb-2">
                        <adapt-link-person *ngFor="let person of additionalActivePeopleToDestinationRole"
                                           [person]="person"
                                           [imageOnly]="true"></adapt-link-person>
                    </div>
                </li>
                <li *ngIf="!mergingIntoSystemRole && additionalInactivePeopleToDestinationRole.length > 0">Role history
                    of the following people will be updated as they previously held one or more of the above deleted roles
                    <br />
                    <div class="d-flex flex-row align-items-center gap-1 pb-2">
                        <adapt-link-person *ngFor="let person of additionalInactivePeopleToDestinationRole"
                                           [person]="person"
                                           [imageOnly]="true"></adapt-link-person>
                    </div>
                </li>
                <li *ngIf="mergingIntoSystemRole && additionalActivePeopleToDestinationRole.length > 0">The above
                    deleted roles will be removed from the following people and will not be recorded historically as the destination is a
                    system role
                    <br />
                    <div class="d-flex flex-row align-items-center gap-1 pb-2">
                        <adapt-link-person *ngFor="let person of additionalActivePeopleToDestinationRole"
                                           [person]="person"
                                           [imageOnly]="true"></adapt-link-person>
                    </div>
                </li>
                <li *ngIf="mergingIntoSystemRole && additionalInactivePeopleToDestinationRole.length > 0">The above
                    deleted roles will be removed from the historical role allocation of the following people
                    <br />
                    <div class="d-flex flex-row align-items-center gap-1 pb-2">
                        <adapt-link-person *ngFor="let person of additionalInactivePeopleToDestinationRole"
                                           [person]="person"
                                           [imageOnly]="true"></adapt-link-person>
                    </div>
                </li>
                <li *ngIf="processStepsToBeAssignedToDestinationRole.length > 0">The following role tasks will be assigned to
                    <adapt-link-role [role]="selectedOutputRole"></adapt-link-role>
                    <ul>
                        <li *ngFor="let processStep of processStepsToBeAssignedToDestinationRole">
                            {{processStep.name}}
                        </li>
                    </ul>
                </li>
                <li *ngIf="additionalKeyFunctionRolesToDestination.length > 0">The following key functions will be assigned to
                    <adapt-link-role [role]="selectedOutputRole"></adapt-link-role>
                    <ul>
                        <li *ngFor="let keyFunctionRole of additionalKeyFunctionRolesToDestination">
                            {{keyFunctionRole.keyFunction?.name}}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <dx-check-box class="mt-3"
                      [value]="checkboxConfirmation"
                      (valueChange)="checkboxConfirmation = $any($event)"
                      text="Check to confirm that you understand that this action cannot be undone"
                      data-test="confirm-checkbox">
        </dx-check-box>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Merge"
                                   saveButtonPreset="default"
                                   [saveIsDisabled]="!selectedOutputRole || !checkboxConfirmation"
                                   (saveClick)="merge()"
                                   cancelText="Cancel"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
