<div *ngIf="systemComponent"
     class="system-component"
     [ngClass]="{'system-component-added': isNewlyAdded}">
    <ng-container *ngIf="systemComponent?.systemDocument">
        <div class="card card-borderless">
            <div class="card-header p-0">
                <h2 class="card-title d-flex align-items-center">{{systemComponent!.name}}
                </h2>
                <div *ngIf="isEditing"
                     class="card-header-buttons">
                    <ng-container *ngIf="isEditing"
                                  class="card-header-buttons">
                        <adapt-menu [items]="editDocumentMenu"></adapt-menu>
                    </ng-container>
                </div>
            </div>

            <div class="card-body p-0">
                <ng-container [ngSwitch]="systemComponent!.systemDocument!.type">
                    <adapt-system-external-link *ngSwitchCase="SystemDocumentType.ExternalLink"
                                                [component]="systemComponent"></adapt-system-external-link>
                    <adapt-select-document *ngSwitchCase="SystemDocumentType.Document"
                                           [url]="systemComponent!.systemDocument!.url"
                                           [readOnly]="true"></adapt-select-document>
                    <div *ngSwitchDefault
                         [froalaView]="systemComponent!.systemDocument!.content"></div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="systemComponent?.systemProcessMap">
        <adapt-process-steps-card [processMap]="systemComponent!.systemProcessMap"
                                  [title]="systemComponent!.name"
                                  [isEditing]="isEditing"
                                  [systemComponent]="systemComponent"
                                  [mapStackFrames]="processMapState"
                                  (mapStackFramesChange)="processMapStateChange.emit($event)"
                                  (processMapDeleted)="deleteProcessMap(systemComponent)"
                                  (moveComponentClick)="moveSystemComponent($event)"
                                  (copyComponentClick)="copySystemComponent($event)">
        </adapt-process-steps-card>
    </ng-container>
    <ng-container *ngIf="systemComponent?.systemDiagram">
        <adapt-system-diagram [component]="systemComponent!"
                              [isEditing]="isEditing"
                              (diagramDeleted)="deleteDiagram(systemComponent)"
                              (moveComponentClick)="moveSystemComponent($event)"
                              (copyComponentClick)="copySystemComponent($event)">
        </adapt-system-diagram>
    </ng-container>
</div>
