import { Component, Inject, OnInit } from "@angular/core";
import { Params } from "@angular/router";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { KeyFunctionRole } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { RouteService } from "@common/route/route.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import dxAccordion from "devextreme/ui/accordion";
import { lastValueFrom } from "rxjs";
import { ProcessMapService } from "../../process-map/process-map.service";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture-framework.service";
import { RolesPageComponent } from "../roles-page/roles-page.component";
import { rolesPageRoute } from "../roles-page/roles-page.route";

export interface IMergeRolesDialogComponentData {
    inputRoles: Role[];
}

interface IRoleConnections {
    role: Role;
    connections: Connection[];
}

@Component({
    selector: "adapt-merge-roles-dialog",
    templateUrl: "./merge-roles-dialog.component.html",
    styleUrls: ["./merge-roles-dialog.component.scss"],
})
export class MergeRolesDialogComponent extends BaseDialogComponent<IMergeRolesDialogComponentData, Role> implements OnInit {
    public readonly dialogName = "MergeRolesDialog";

    public isLoading = true;
    public inputAccordionInstance?: dxAccordion;
    public selectedOutputRole?: Role;
    public rolesUrl!: string;
    public systemRoleSelectedMessage: string = "";
    public deletedRoles: Role[] = [];
    public additionalRoleConnectionsToDestinationRole: RoleConnection[] = [];
    public additionalActivePeopleToDestinationRole: Person[] = [];
    public additionalInactivePeopleToDestinationRole: Person[] = [];
    public additionalKeyFunctionRolesToDestination: KeyFunctionRole[] = [];
    public discardHistoryFromSources: IRoleConnections[] = [];
    public processStepsToBeAssignedToDestinationRole: ProcessStep[] = [];
    public mergingIntoSystemRole = false;

    public readonlyModeOnSelection?: boolean;
    public roleFilter?: (role: Role) => boolean;

    public checkboxConfirmation = false;

    private activeRoleConnections: { [roleId: number]: RoleConnection[] } = {};

    public roleTaskCounts: { [roleId: number]: number } = {};

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IMergeRolesDialogComponentData,
        protected commonDataService: CommonDataService,
        private architectureService: IntegratedArchitectureFrameworkService,
        private routeService: RouteService,
        private processMapService: ProcessMapService,
        private keyFunctionsService: KeyFunctionsService,
        private systemisationService: SystemisationService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.rolesUrl = await this.routeService.getControllerRoute(rolesPageRoute.id);

        await this.setup();
    }

    public async merge() {
        this.processStepsToBeAssignedToDestinationRole.forEach((processStep) => processStep.role = this.selectedOutputRole!);
        let promisesToCreateRoleConnections: Promise<any>[];
        if (this.mergingIntoSystemRole) {
            // not going to bring forward any role connection into system roles (CL and tier1)
            promisesToCreateRoleConnections = [];
        } else {
            promisesToCreateRoleConnections = this.additionalRoleConnectionsToDestinationRole.map((roleConnection) => {
                const newRoleConnection = {
                    roleId: this.selectedOutputRole!.roleId,
                    connectionId: roleConnection.connection.connectionId,
                    startDate: roleConnection.startDate,
                    endDate: roleConnection.endDate,
                };
                return lastValueFrom(this.architectureService.createRoleConnection(newRoleConnection));
            });
        }

        const promiseToAssignRoleToKeyFunctions =
            this.additionalKeyFunctionRolesToDestination.map((kfr) =>
                lastValueFrom(this.keyFunctionsService.addRoleToKeyFunction(this.selectedOutputRole!, kfr.keyFunction!, kfr.ordinal)));

        // get role systems for the output role
        const outputRoleSystems = await lastValueFrom(this.systemisationService.getSystemLocationsForRole(this.selectedOutputRole!));

        for (const role of this.deletedRoles) {
            // get role systems for role to be deleted
            const roleSystems = await lastValueFrom(this.systemisationService.getSystemLocationsForRole(role));

            for (const roleSystem of roleSystems) {
                if (outputRoleSystems.some((rs) => rs.system === roleSystem.system)) {
                    // if the output role already has this role system, just delete it
                    await lastValueFrom(this.commonDataService.remove(roleSystem));
                } else {
                    // otherwise assign it to output role
                    roleSystem.roleId = this.selectedOutputRole!.roleId;
                }
            }
        }

        // fix role system ordinals after merging
        const roleSystemsToSort = await lastValueFrom(this.systemisationService.getSystemLocationsForRole(this.selectedOutputRole!));
        SortUtilities.sequenceNumberFieldInArray(roleSystemsToSort, "ordinal");

        await Promise.all(promisesToCreateRoleConnections);
        await Promise.all(promiseToAssignRoleToKeyFunctions);
        // saving other changes first or role deletion will change role assignment if done in a single transaction
        await lastValueFrom(this.commonDataService.save());

        const promisesToRemoveRoles = this.deletedRoles.map((role) => lastValueFrom(this.architectureService.deleteRole(role)));
        await Promise.all(promisesToRemoveRoles); // this somehow already has a save in there
        this.resolve(this.selectedOutputRole!);
    }

    public getParamsForRole(role: Role) {
        return {
            [RolesPageComponent.RoleLabelFilterSearchParam]: role.label,
        } as Params;
    }

    public getActiveRoleConnections(role: Role) {
        let cachedResult = this.activeRoleConnections[role.roleId];
        if (!cachedResult) {
            cachedResult = role.roleConnections.filter((roleConnection) => roleConnection.isActive());
            this.activeRoleConnections[role.roleId] = cachedResult;
        }

        return cachedResult;
    }

    public findDiscardedHistoricalConnections(role: Role) {
        return this.discardHistoryFromSources.find((roleConnections) => roleConnections.role === role);
    }

    private async setup() {
        // check for system role
        const hasSystemRole = this.dialogData.inputRoles.some((role) => !!role.roleType);
        if (hasSystemRole) {
            for (const role of this.dialogData.inputRoles) {
                if (role.roleType) {
                    this.selectedOutputRole = role;
                    break;
                }
            }

            this.readonlyModeOnSelection = true;
            this.systemRoleSelectedMessage = "A system role cannot be deleted and can only be merged into. The system role '"
                + this.selectedOutputRole!.label + "' is automatically selected.";
            this.roleFilter = (role) => role === this.selectedOutputRole;
            await this.onOutputSelectionChanged();
        } else {
            this.roleFilter = (role) => this.dialogData.inputRoles.includes(role);
        }

        // get all process steps for roles beforehand
        await Promise.all(this.dialogData.inputRoles.map((role) => this.getProcessStepsForRole(role)));

        if (this.inputAccordionInstance) {
            // this is to workaround dx-accordion-item set to height 0 if accordion instance is initialized before promises finish.
            setTimeout(() => this.inputAccordionInstance?.updateDimensions());
        }
    }


    public async onOutputSelectionChanged() {
        if (this.selectedOutputRole) {
            this.mergingIntoSystemRole = this.selectedOutputRole.extensions.isSystemAllocatedRole();
        } else {
            this.mergingIntoSystemRole = false;
        }

        this.deletedRoles = this.dialogData.inputRoles.filter((role) => role !== this.selectedOutputRole);
        this.processStepsToBeAssignedToDestinationRole = [];
        this.discardHistoryFromSources = [];
        this.updateAdditionalRoleConnectionsToDestinationRole();
        await this.updateAdditionalKeyFunctionRolesToDestination();
        for (const role of this.deletedRoles) {
            const processSteps = await this.getProcessStepsForRole(role);
            this.processStepsToBeAssignedToDestinationRole = this.processStepsToBeAssignedToDestinationRole.concat(processSteps);
        }

        // collapse all input source
        if (this.inputAccordionInstance) {
            for (let i = 0; i < this.dialogData.inputRoles.length; i++) {
                this.inputAccordionInstance.collapseItem(i);
            }
        }
    }

    private updateAdditionalRoleConnectionsToDestinationRole() {
        this.additionalRoleConnectionsToDestinationRole = [];
        for (const role of this.deletedRoles) {
            for (const roleConnection of role.roleConnections) {
                if (this.selectedOutputRole!.roleConnections.some(
                    (destRoleConnection) => destRoleConnection.connection === roleConnection.connection)) {
                    // connection already in destination role -> need to flag lost of historical information
                    const affectedConnections = this.findDiscardedHistoricalConnections(role);
                    if (!affectedConnections) {
                        this.discardHistoryFromSources.push({
                            role,
                            connections: [roleConnection.connection],
                        });
                    } else if (affectedConnections.connections.indexOf(roleConnection.connection) < 0) {
                        affectedConnections.connections.push(roleConnection.connection);
                    }
                } else {
                    const addedRoleConnection = this.additionalRoleConnectionsToDestinationRole.find(
                        (addRoleConnection) => addRoleConnection.connection === roleConnection.connection);
                    if (!addedRoleConnection) {
                        this.additionalRoleConnectionsToDestinationRole.push(roleConnection);
                    } else {
                        if (roleConnection.startDate < addedRoleConnection.startDate) {
                            addedRoleConnection.startDate = roleConnection.startDate; // keep the earliest start date for a role
                        }

                        // keep latest end date
                        if (!roleConnection.endDate && addedRoleConnection.endDate) {
                            addedRoleConnection.endDate = undefined; // not ended -> take this
                        } else if (roleConnection.endDate && addedRoleConnection.endDate && roleConnection.endDate > addedRoleConnection.endDate) {
                            addedRoleConnection.endDate = roleConnection.endDate;
                        }
                    }
                }
            }
        }

        this.additionalActivePeopleToDestinationRole = [];
        this.additionalInactivePeopleToDestinationRole = [];
        for (const roleConnection of this.additionalRoleConnectionsToDestinationRole) {
            if (roleConnection.isActive()) {
                this.additionalActivePeopleToDestinationRole.push(roleConnection.connection.person);
            } else {
                this.additionalInactivePeopleToDestinationRole.push(roleConnection.connection.person);
            }
        }
    }

    private async updateAdditionalKeyFunctionRolesToDestination() {
        this.additionalKeyFunctionRolesToDestination = [];
        if (this.selectedOutputRole) {
            const destKeyFunctionRoles = await lastValueFrom(this.keyFunctionsService.getKeyFunctionRolesByRoleId(this.selectedOutputRole.roleId));
            for (const role of this.deletedRoles) {
                const keyFunctionRoles = await lastValueFrom(this.keyFunctionsService.getKeyFunctionRolesByRoleId(role.roleId));
                for (const keyFunctionRole of keyFunctionRoles) {
                    // only want 1 pair of KF - destination role
                    if (!destKeyFunctionRoles.find((kfr) => kfr.keyFunctionId === keyFunctionRole.keyFunctionId) &&
                        !this.additionalKeyFunctionRolesToDestination.find((kfr) => kfr.keyFunctionId === keyFunctionRole.keyFunctionId)) {
                        // all these keyFunctionRoles will be deleted anyway - so use ordinal to temporarily store the ordinal at the destination
                        keyFunctionRole.ordinal = destKeyFunctionRoles.length + this.additionalKeyFunctionRolesToDestination.length;
                        this.additionalKeyFunctionRolesToDestination.push(keyFunctionRole);
                    }
                }
            }
        }
    }

    private async getProcessStepsForRole(role: Role) {
        const processSteps = await lastValueFrom(this.processMapService.getProcessStepByRoleId(role.roleId));
        this.roleTaskCounts[role.roleId] = processSteps?.length ?? 0;
        return processSteps;
    }
}
