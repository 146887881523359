<ng-container *adaptLoadingUntilFirstEmit="let allGuidanceMaterials of allGuidanceMaterials$">
    <dx-list *ngIf="processStepGuidanceMaterials.length > 0"
             [dataSource]="processStepGuidanceMaterials"
             [hoverStateEnabled]="false"
             [activeStateEnabled]="false"
             [focusStateEnabled]="false"
             [allowItemDeleting]="true"
             height="auto"
             (onItemReordered)="updateOrdinals($event)"
             (onItemDeleted)="removeProcessStepGuidanceMaterial($event)">
        <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
        <adapt-display-guidance-material *dxTemplate="let item of 'item'"
                                         [guidanceMaterial]="item.guidanceMaterial"
                                         [showDescription]="false"></adapt-display-guidance-material>
    </dx-list>
    <dx-select-box class="m-2"
                   [dataSource]="selectableGuidanceMaterials"
                   displayExpr="name"
                   [searchEnabled]="true"
                   (valueChange)="addGuidanceMaterial($event)"></dx-select-box>
    <button class="ms-2 btn btn-link"
            [adaptBlockingClick]="createAndAddGuidanceMaterial"
            adaptButtonIcon="add">Add new guidance material</button>
</ng-container>
