export class GraphConstants {
    public static readonly SelectedRadius = 40;
    public static readonly NodeRadius = 30;
    public static readonly LinkDistance = 210;
    public static readonly MinWidth = 240;
    public static readonly MinHeight = 260;
    public static readonly MinScaling = 0.8;
    public static readonly HiddenLinkCircleRadius = 6;
    public static readonly HiddenLinkArrowHeight = 12;
    public static readonly HiddenLinkArrowTiltAngle = 0.1; // in radian
    public static readonly LinkImageRadius = 15;

    public static DefaultLinkColor = "#888888";
    public static DefaultNodeColor = "#bbbbcc";
    public static DefaultSelectedNodeColor = "#444455";

    public static ColorMap: {[key: string]: string} = {
        Green: "#007700",
        Orange: "#ffa500",
        Red: "#ff0000",
    };
}
