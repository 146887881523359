import { Component, Inject } from "@angular/core";
import { Api } from "@common/ADAPT.Common.Model/organisation/api";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-api-token-dialog",
    templateUrl: "./api-token-dialog.component.html",
    styleUrls: ["./api-token-dialog.component.scss"],
})
export class ApiTokenDialogComponent extends BaseDialogComponent<Api> {
    public readonly dialogName = "ApiToken";

    public copiedToken = false;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public newApi: Api,
    ) {
        super();
    }

    public async copyToClipboard(text: string) {
        await navigator.clipboard.writeText(text);
        this.copiedToken = true;
        // reset back to uncopied so the button looks like it can be pressed again.
        setTimeout(() => this.copiedToken = false, 5000);
    }
}
