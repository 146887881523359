import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { CatchupRatingGuidance, CatchupRatingValue, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { BaseComponent } from "@common/ux/base.component/base.component";

interface IRatingOption {
    value: CatchupRatingValue;
    guidance: string;
    class: string;
}

@Component({
    selector: "adapt-catchup-traffic-light-rating",
    templateUrl: "./catchup-traffic-light-rating.component.html",
    styleUrls: ["./catchup-traffic-light-rating.component.scss"],
})
export class CatchupTrafficLightRatingComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public rating!: SpeedCatchupRating;
    @Output() public ratingChange = new EventEmitter<SpeedCatchupRating>();

    public ratingOptions: IRatingOption[] = [{
        value: CatchupRatingValue.Green,
        guidance: CatchupRatingGuidance.Green,
        class: "catchup-rating-unset-Green",
    }, {
        value: CatchupRatingValue.Orange,
        guidance: CatchupRatingGuidance.Orange,
        class: "catchup-rating-unset-Orange",
    }, {
        value: CatchupRatingValue.Red,
        guidance: CatchupRatingGuidance.Red,
        class: "catchup-rating-unset-Red",
    }];

    public trafficLightGroupName = "";
    public trafficLightClass = "";

    public constructor() {
        super();
    }

    public ngOnInit() {
        this.updateClasses();
        this.isInitialised = true;

        // whatever that was set to in old speed-catchup-traffic-light-rating.component
        this.trafficLightGroupName = `pcuRating${this.rating.speedCatchupRatingId}`;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.rating && this.rating && this.isInitialised) {
            this.updateClasses();
        }
    }

    public getTrafficLightIdName(ratingOption: IRatingOption) {
        return `pcuRating${this.rating.speedCatchupRatingId}${ratingOption.value}`;
    }

    public setRatingValue(ratingOption: IRatingOption) {
        this.rating.rating = ratingOption.value;
        this.updateClasses();
    }

    private updateClasses() {
        if (!this.rating) {
            return;
        }

        this.trafficLightClass = this.rating.rating
            ? ""
            : "notset";
        this.ratingOptions.forEach((ratingOption) => {
            ratingOption.class = ratingOption.value === this.rating.rating
                ? `catchup-rating-${ratingOption.value}`
                : `catchup-rating-unset-${ratingOption.value}`;
        });

        this.ratingChange.emit(this.rating);
    }
}
