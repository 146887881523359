import {Injectable, Injector} from "@angular/core";
import { CulturalDataFilter } from "../../cultural-data-filter";
import { CareerValuationAnalysisFilter, ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";

@Injectable({
    providedIn: "root",
})
export class CareerValuationFilterService extends CulturalDataFilter<ICareerValuationAnalysisFilter> {
    public constructor(injector: Injector) {
        super(CareerValuationAnalysisFilter.getDefaultFilterParameters(), injector);
    }
}
