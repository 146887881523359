import { Component, Inject, ViewChild } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { lastValueFrom, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";
import { SelectProcessMapComponent } from "../select-process-map/select-process-map.component";

enum PostMoveAction {
    GoToNewLocation,
    StayHere,
}

@Component({
    templateUrl: "./move-process-step-dialog.component.html",
    styleUrls: ["./move-process-step-dialog.component.scss"],
})
export class MoveProcessStepDialogComponent extends BaseDialogComponent<SystemComponent> {
    public readonly dialogName = "MoveProcessStep";

    public PostMoveAction = PostMoveAction;

    public selectedProcessMap?: ProcessMap;
    public isSelecting = true;
    public childSteps$: Observable<ProcessStep[]>;
    public changedEntities: IBreezeEntity[] = [];

    public existingSystemComponent?: SystemComponent;
    @ViewChild(SelectProcessMapComponent) private selectProcessMapComponent?: SelectProcessMapComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public processStep: ProcessStep,
        private commonDataService: CommonDataService,
        private navHierarchyService: NavigationHierarchyService,
        private systemisationService: SystemisationService,
        processMapService: ProcessMapService,
    ) {
        super();

        this.childSteps$ = processMapService.getChildSteps(processStep);
        this.systemisationService.getSystemComponentForProcessMap(processStep.processMapId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((component) => this.existingSystemComponent = component);
    }

    public get isCreatingProcessMap() {
        return this.selectProcessMapComponent?.isCreating;
    }

    private get definedProcessMap() {
        if (!this.selectedProcessMap) {
            throw new Error("selectedProcessMap should be defined");
        }

        return this.selectedProcessMap!;
    }

    @Autobind
    public isDifferentProcessMap(processMap: ProcessMap) {
        return processMap.processMapId !== this.processStep.processMapId;
    }

    @Autobind
    public async doMove() {
        const destinationSystemComponent = await lastValueFrom(this.systemisationService.getSystemComponentForProcessMap(this.definedProcessMap.processMapId));
        const sameSystemMove = this.existingSystemComponent?.systemEntityId === destinationSystemComponent?.systemEntityId;
        this.processStep.processMap = this.definedProcessMap;
        await lastValueFrom(this.commonDataService.saveEntities(this.processStep));
        this.isSelecting = false;
        // won't prompt to go to new page if is the same system
        if (sameSystemMove) {
            this.resolve(destinationSystemComponent);
        }
    }

    @Autobind
    public async resolveMove(action: PostMoveAction) {
        if (action === PostMoveAction.GoToNewLocation) {
            await lastValueFrom(this.systemisationService.getSystemComponentForProcessMap(this.definedProcessMap.processMapId).pipe(
                switchMap((systemComponent) => systemPageRoute.gotoRoute(
                    { systemEntityId: systemComponent.systemEntityId },
                    { addedComponentId: systemComponent.systemComponentId })),
            ));
        } else {
            // The process map will have changed so we will need to update the breadcrumbs
            // if we are currently on the step's page
            this.navHierarchyService.updateActiveNodeFromUrl();
            this.resolve(await lastValueFrom(this.systemisationService.getSystemComponentForProcessMap(this.definedProcessMap.processMapId)));
        }
    }

    public cancel() {
        if (this.changedEntities.length > 0) {
            this.commonDataService.rejectChanges(this.changedEntities)
                .subscribe(() => super.cancel());
        } else {
            super.cancel();
        }
    }
}
