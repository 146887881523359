import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class ResilientBusinessGoalsAuthService {
    public static readonly ReadResilientBusinessGoals = "readResilientBusinessGoals";
    public static readonly EditResilientBusinessGoals = "editResilientBusinessGoals";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            ResilientBusinessGoalsAuthService.ReadResilientBusinessGoals,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassResilientBusinessGoalsRead,
                    FeaturePermissionName.CompassResilientBusinessGoalsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ResilientBusinessGoalsAuthService.EditResilientBusinessGoals,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassResilientBusinessGoalsEdit,
                ],
            },
        );
    }
}
