import { Component, Injector, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { PersonService } from "@org-common/lib/person/person.service";
import { lastValueFrom } from "rxjs";
import { PeerCatchupAuthService } from "../peer-catchup-auth.service";

@Component({
    selector: "adapt-my-catchups-page",
    templateUrl: "./my-catchups-page.component.html",
})
export class MyCatchupsPageComponent extends BaseRoutedComponent implements OnInit {
    public person?: Person;

    public constructor(
        injector: Injector,
        private userService: UserService,
        private personService: PersonService,
        private catchupAuthService: PeerCatchupAuthService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        const personId = this.getRouteParamInt("personId");
        if (!personId) {
            this.person = await this.userService.getCurrentPerson();
        } else {
            this.person = await lastValueFrom(this.personService.getPerson(personId));
        }

        if (!this.person) {
            this.routeService.gotoHome();
        } else {
            this.verifyHasAccessToRoute(this.catchupAuthService.canReadCatchupsForPerson(this.person));
        }

        super.notifyActivated();
    }
}
