import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DashboardElementComponent } from "@common/ux/dashboard-element/dashboard-element.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CulturalIndexDialogComponent, IDisplayCulturalIndexDialogData } from "../ci-dialog/ci-dialog.component";
import { ICulturalIndexHistoryComponentOptions } from "../ci-history/ci-history.component";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";
import { EditCulturalIndexDialogComponent } from "../edit-ci-dialog/edit-ci-dialog.component";

@Component({
    selector: "adapt-ci-dashboard-page",
    templateUrl: "./ci-dashboard-page.component.html",
    styleUrls: ["./ci-dashboard-page.component.scss"],
})
export class CulturalIndexDashboardPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    @ViewChild("currentCIDashboardIndex") public currentCIDashboardIndex?: DashboardElementComponent;

    private headerClickSubscription?: Subscription;

    public currentIndex?: CulturalIndex;
    public person?: Person;
    public personId?: number;
    public selfMode: boolean = false;
    public culturalIndexHistoryOptions: ICulturalIndexHistoryComponentOptions = {};
    public getDirectionState = this.culturalIndexService.getDirectionState;

    public hasEditPermission?: boolean;
    public loading = true;

    public EditCulturalIndexForPerson = CulturalIndexAuthService.EditCulturalIndexForPerson;

    public constructor(
        injector: Injector,
        private userFactory: UserService,
        private culturalIndexService: CulturalIndexService,
        private culturalIndexUiService: CulturalIndexUiService,
        private commonDialogService: AdaptCommonDialogService,
        private culturalIndexAuthService: CulturalIndexAuthService,
        private directorySharedService: DirectorySharedService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        this.personId = this.getRouteParamInt("personId");

        rxjsBreezeService.entityTypeChangedInSave(CulturalIndex)
            .pipe(
                switchMap(() => this.promiseToGetPersonAndIndexes()),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public async ngOnInit() {
        this.notifyActivated();

        await this.promiseToGetPersonAndIndexes();
    }

    public ngOnDestroy() {
        if (this.headerClickSubscription) {
            this.headerClickSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public get fullName() {
        return this.selfMode
            ? "My"
            : this.person
                ? `${this.person.fullName}'s`
                : "";
    }

    public exportData() {
        if (this.culturalIndexHistoryOptions.exportData) {
            this.culturalIndexHistoryOptions.exportData();
        }
    }

    public async promiseToGetPersonAndIndexes() {
        let person: Person | undefined;

        const currentPerson = await this.userFactory.getCurrentPerson();

        if (this.personId) {
            person = await this.directorySharedService.promiseToGetPersonById(this.personId);
        }

        this.person = this.personId ? person : currentPerson;

        try {
            const hasReadPermission = currentPerson
                && this.person
                && await this.culturalIndexAuthService.personCanReadForPerson(currentPerson, this.person);
            if (!hasReadPermission) {
                return ErrorPageRoute.gotoRoute().subscribe();
            }
        } catch (error) {
            return ErrorPageRoute.gotoRoute().subscribe();
        }

        this.hasEditPermission = currentPerson
            && this.person
            && await this.culturalIndexAuthService.personCanEditForPerson(currentPerson, this.person);

        await this.setDataAndGetIndexes(currentPerson);
        this.loading = false;
    }

    public async setDataAndGetIndexes(currentPerson?: Person) {
        if (!this.person) {
            // person id not found, go home
            this.routeService.gotoHome();
            return;
        }

        this.selfMode = currentPerson?.personId === this.person!.personId;
        this.currentIndex = undefined;

        this.currentIndex = await this.culturalIndexService.promiseToGetLatestCulturalIndexForPerson(this.person!.personId);

        if (this.currentCIDashboardIndex) {
            if (this.headerClickSubscription) {
                this.headerClickSubscription.unsubscribe();
            }

            if (this.currentIndex) {
                this.headerClickSubscription = this.currentCIDashboardIndex!.headerClicked.subscribe({ next: this.viewCulturalIndex });
            }
        }
    }

    @Autobind
    public viewCulturalIndex() {
        if (!this.currentIndex) {
            return;
        }

        const data: IDisplayCulturalIndexDialogData = {
            culturalIndex: this.currentIndex,
            showName: false,
        };

        return this.commonDialogService.open(CulturalIndexDialogComponent, data).subscribe();
    }

    @Autobind
    public recordCulturalIndex() {
        return this.culturalIndexUiService.promiseToRecordCulturalIndex(this.person!);
    }

    @Autobind
    public editCulturalIndex() {
        this.commonDialogService.open(EditCulturalIndexDialogComponent, this.currentIndex!).subscribe();
    }
}
