import { Component, Inject } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocument, SystemDocumentType } from "@common/ADAPT.Common.Model/organisation/system-document";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IEditVideoDocumentDialogData } from "../edit-video-document-dialog/edit-video-document-dialog.component";

export interface IEditSystemDocumentDialogData {
    systemDocument: SystemDocument;
    systemComponent: SystemComponent;
}

@Component({
    selector: "adapt-edit-system-document-dialog",
    templateUrl: "./edit-system-document-dialog.component.html",
})
@PersistableDialog("EditSystemDocumentDialog")
export class EditSystemDocumentDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditSystemDocumentDialogData> {
    public readonly dialogName = "EditSystemDocument";

    public title: string;
    public entitiesToConfirm: IBreezeEntity[];

    public dialogWidth = window.innerWidth * 0.98;
    public isEditorValid = true;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditVideoDocumentDialogData,
    ) {
        super();
        this.autoResolveData = this.data;
        this.title = this.data.systemDocument.entityAspect.entityState.isAdded()
            ? "Add "
            : "Edit ";
        this.title += this.data.systemComponent.extensions.componentLabel;

        if (this.useDocumentSelector) {
            // will just be a document selector - don't need width to be closed to full width
            this.dialogWidth = 640;
        }

        this.entitiesToConfirm = [
            this.data.systemDocument,
            this.data.systemComponent,
        ];
    }

    public onNameChange(name: string) {
        this.data.systemComponent.name = name;
        this.data.systemComponent.extensions.validateComponentName();
    }

    public validateSystemDocument() {
        this.data.systemDocument.entityAspect.validateEntity();
    }

    public get useDocumentSelector() {
        return this.data.systemDocument.type === SystemDocumentType.Document;
    }
}
