<div class="card card-borderless"
     #cardRoot
     [style.min-height.px]="minHeight">
    <div class="card-header p-0"
         #processMapHeader>
        <h2 class="card-title d-flex align-items-center process-map-header">
            <adapt-display-process-step-hierarchy *ngIf="processStepStackFrames.length > 0; else showMapTitle"
                                                  [processStep]="parentStep || processMap"
                                                  [processStepStackFrames]="processStepStackFrames"
                                                  (processStepClick)="stackFrameChanged($event)"></adapt-display-process-step-hierarchy>
            <ng-template #showMapTitle>
                {{initialProcessMap!.name}}
            </ng-template>
        </h2>
        <div class="card-header-buttons">
            <button adaptButtonIcon="fal fa-fw fa-file-export"
                    *ngIf="processMap && hasChildSteps"
                    [adaptBlockingClick]="export"
                    data-test="export-process-map-button">Export</button>
            <adapt-menu *ngIf="isEditing && hasEditPermission"
                        [items]="editMenu"></adapt-menu>
        </div>
    </div>
    <div class="card-body p-0">
        <div *ngIf="parentStep?.labelLocations?.length"
             class="mb-2">
            <adapt-display-labels [labelLocations]="parentStep!.labelLocations"
                                  data-test="labels-display"></adapt-display-labels>
        </div>
        <div *ngIf="parentStep?.extensions?.isRoleTask; else showProcessMap">
            <adapt-role-task-content class="d-block p-3"
                                     [roleTask]="parentStep"
                                     [isEditing]="isEditing"></adapt-role-task-content>
        </div>
        <ng-template #showProcessMap>
            <div *ngIf="parentStep?.supplementaryData?.description"
                 class="mb-2">
                <div [froalaView]="parentStep!.supplementaryData?.description"></div>
            </div>
            <div *ngIf="processMap && systemComponent?.supplementaryData?.description"
                 class="mb-2">
                <div [froalaView]="systemComponent!.supplementaryData!.description"></div>
            </div>
            <adapt-render-process-steps class="d-block pattern overflow-auto rounded border"
                                        [processMap]="processMap"
                                        [parentStep]="parentStep"
                                        [isEditing]="isEditing && renderIsInitialised"
                                        (initialised)="renderIsInitialised = true"
                                        (stepClick)="processStepClicked($event)"></adapt-render-process-steps>
        </ng-template>
    </div>
</div>
