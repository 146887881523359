import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-clf-page",
    templateUrl: "./clf-page.component.html",
})
export class CulturalLeadershipFrameworkPageComponent extends BaseRoutedComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}
