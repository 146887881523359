<div class="edit-speed-catchup-rating"
     [ngClass]="{
        'edit-speed-catchup-person2-is-author': person2IsAuthor,
        'edit-speed-catchup-rating-is-editing': isEditing
     }"
     *adaptLoading="!rating || isChanging">
    <!-- rating -->
    <div class="edit-speed-catchup-container edit-speed-catchup-rating-container">
        <adapt-person-image [person]="rating!.authorPerson"
                            imageSize="md"></adapt-person-image>
        <div class="edit-speed-catchup-input edit-speed-catchup-rating-input">
            <div class="edit-speed-catchup-content">
                <div class="d-flex align-items-center">
                    <adapt-display-rating-value *ngIf="!isEditing"
                                                class="ms-2 mt-1"
                                                [ratingValue]="rating!.rating"
                                                [showText]="false"></adapt-display-rating-value>
                    <span class="edit-speed-catchup-helper-text"
                          [ngClass]="{'edit-speed-catchup-helper-text-no-content': !rating?.comment}">{{ratingCommentPlaceholder}}</span>
                </div>
                <adapt-html-editor class="edit-speed-catchup-text"
                                   [readonly]="!isEditing"
                                   [(contents)]="rating!.comment"
                                   [placeholderText]="ratingCommentPlaceholder"
                                   [required]="true"
                                   forceSize="compact"
                                   [onlyShowToolbarWhenFocussed]="true"
                                   (contentsChange)="validateEntity()"
                                   data-test="catch-up-comment"></adapt-html-editor>
            </div>
            <adapt-catchup-traffic-light-rating class="edit-speed-catchup-traffic-light"
                                                *ngIf="isEditing"
                                                [rating]="rating"
                                                (ratingChange)="validateEntity()"></adapt-catchup-traffic-light-rating>
        </div>
    </div>
    <!-- feedback person -->
    <div *ngIf="!ratingType?.ratingIsMutual"
         class="edit-speed-catchup-container edit-speed-catchup-feedback-container">
        <div class="edit-speed-catchup-input edit-speed-catchup-feedback-input">
            <div class="edit-speed-catchup-content edit-speed-catchup-feedback-content">
                <span class="edit-speed-catchup-helper-text"
                      [ngClass]="{'edit-speed-catchup-helper-text-no-content': !rating?.feedbackComment}">{{ratingFeedbackPlaceholder}}</span>
                <adapt-html-editor class="edit-speed-catchup-text"
                                   [readonly]="!isEditing"
                                   [(contents)]="rating!.feedbackComment"
                                   [placeholderText]="ratingFeedbackPlaceholder"
                                   [required]="true"
                                   forceSize="compact"
                                   [onlyShowToolbarWhenFocussed]="true"
                                   (contentsChange)="validateEntity()"
                                   data-test="catch-up-feedback"></adapt-html-editor>
            </div>
        </div>
        <adapt-person-image [person]="rating!.feedbackPerson"
                            image-size="md"></adapt-person-image>
    </div>
</div>
