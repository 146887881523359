import { RawClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { clientConfiguration as prodClientConfiguration } from "@common/configuration/environment.base.prod";
import { clientConfiguration } from "./environment.base";

export const environment = {
    production: true,
    clientConfiguration: Object.assign(
        {},
        clientConfiguration,
        prodClientConfiguration,
    ) as Partial<RawClientConfiguration>,
};
