import { Component, Inject } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessStepGuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { forkJoin, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ProcessMapService } from "../../process-map/process-map.service";
import { IntegratedArchitectureService } from "../integrated-architecture.service";

@Component({
    selector: "adapt-merge-guidance-materials-dialog",
    templateUrl: "./merge-guidance-materials-dialog.component.html",
})
export class MergeGuidanceMaterialsDialogComponent extends BaseDialogComponent<boolean> {
    public readonly dialogName = "MergeGuidanceMaterialsDialog";

    public guidanceMaterialUsage: { [id: number]: ProcessStepGuidanceMaterial[] } = [];
    public primeGuidanceMaterials$: Observable<GuidanceMaterial[]>;
    public destinationGuidanceMaterial?: GuidanceMaterial;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public guidanceMaterials: GuidanceMaterial[],
        private commonDataService: CommonDataService,
        private archService: IntegratedArchitectureService,
        processMapService: ProcessMapService,
    ) {
        super();

        this.primeGuidanceMaterials$ = forkJoin(guidanceMaterials.map((i) =>
            processMapService.getProcessStepGuidanceMaterialsByGuidanceMaterialId(i.guidanceMaterialId).pipe(
                tap((psGuidanceMaterials) => this.guidanceMaterialUsage[i.guidanceMaterialId] = psGuidanceMaterials),
                map(() => i),
            )));
    }

    @Autobind
    public merge() {
        const processStepGuidanceMaterials = Object.values(this.guidanceMaterialUsage)
            .reduce((flat, toFlatten) => flat.concat(toFlatten), []);

        return this.archService.mergeGuidanceMaterialsForProcessSteps(
            this.guidanceMaterials,
            this.destinationGuidanceMaterial!,
            processStepGuidanceMaterials,
        ).pipe(
            switchMap((entities) => this.commonDataService.saveEntities(entities)),
            tap(() => this.resolve(true)),
        );
    }
}
