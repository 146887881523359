<a *ngIf="processMap"
   [routerLink]="href$ | async">
    <span class="fa-stack fa-1x process-map-icon me-1">
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fa-stack-1x fa-inverse"
           [ngClass]="icon"
           aria-hidden="true"></i>
    </span>
    <span adaptTooltip="This is a new / unsaved task map"
          [tooltipEnabled]="processMap!.processMapId < 1">{{processMap!.name}}</span>
</a>
