<adapt-dashboard-element name="External links"
                         *ngIf="externalDashboards.length > 0">
    <div *ngFor="let dashboard of externalDashboards">
        <a *ngIf="dashboard.dashboard.openInNewTab; else internal"
           [attr.href]="dashboard.dashboard.url"
           target="_blank"
           rel="noreferrer noopener">
            <i class="fal fa-fw fa-sm fa-external-link-alt"></i>
            {{ dashboard.dashboard.name }}
        </a>
        <ng-template #internal>
            <a [routerLink]="dashboard.url.path"
               [queryParams]="dashboard.url.queryParams">
                <i class="fal fa-fw fa-sm fa-browsers"></i>
                {{ dashboard.dashboard.name }}
            </a>
        </ng-template>
    </div>
</adapt-dashboard-element>
