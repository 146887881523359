<adapt-feature-guidance [configItem]="configItem">
    <p>Stakeholders are people external to your organisation that you wish to be represented inside embedADAPT.</p>
    <p>These people could be investors, potential employees, partners or coaches. Adding these kinds of people as
        stakeholders allows you to give them access to parts of embedADAPT without giving them the same level of access as your
        employees.</p>
    <p> It also allows data to be recorded against them and not affect your organisation's statistical data (e.g. A potential employees poor
        Career Valuation at their existing organisation should not affect your organisation's score)</p>
    <p>Enabling this feature will allow you to manage people and roles for any external stakeholders within embedADAPT.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem"></adapt-feature-configuration>
