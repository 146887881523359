<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.BuildCulturalLeadership"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div class="row">
    <div class="col-12">
        <div class="card">
            <adapt-clf-grid></adapt-clf-grid>
        </div>
    </div>
</div>
