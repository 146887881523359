<div class="d-flex align-items-center">
    <i class="py-2 fal fa-fw fa-cube icon"></i>
    <a class="ms-1"
       [routerLink]="valueStreamPageUrl$ | async">
        {{valueStream.name}}
    </a>
    <adapt-link-person *ngIf="leaderPersonId"
                       class="mt-n1 ms-2"
                       [personId]="leaderPersonId"
                       [showImage]="true"
                       imageSize="sm"
                       [showText]="false"></adapt-link-person>
</div>
