<dx-autocomplete #autoComplete
                 placeholder="Search platform..."
                 data-test="quick-search-box"
                 [width]="250"
                 [value]="keyword"
                 [minSearchLength]="2"
                 [dataSource]="dataSource"
                 (onInitialized)="onInitialised($event)"
                 (onEnterKey)="onEnterKey(autoComplete.value)"
                 (onFocusOut)="autoComplete.openOnFieldClick = !!keyword"
                 (onContentReady)="throttledOpenedEvent.next($event)"
                 (onItemClick)="onItemClick($event)">
    <dxi-button name="search"
                location="after"
                [options]="{
                    icon: 'search',
                    stylingMode: 'text',
                    disabled: true
                }"></dxi-button>
    <div *dxTemplate="let item of 'item'">
        <div *ngIf="item"
             [ngSwitch]="item.type">
            <adapt-render-component [component]="searchElements!.component"
                                    [componentInputs]="{ type: item.type, searchResults: item.result, keyword: item.keyword }">
            </adapt-render-component>

            <div *ngSwitchCase="LoadMoreResultsButtonType"
                 class="more-results-btn">
                <a role="button"
                   class="text-body">Load more results...</a>
            </div>
            <div *ngSwitchCase="NoResultsFoundType"
                 class="no-results-btn"
                 role="button">
                No people, teams, roles or pages found. Click to search the rest of embedADAPT.
            </div>
        </div>
    </div>
</dx-autocomplete>
