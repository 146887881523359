<ng-container *adaptLoadingUntilFirstEmit="let processSteps of processSteps$">
    <adapt-display-guidance-material [guidanceMaterial]="guidanceMaterial"
                                     [showDescription]="false"></adapt-display-guidance-material>
    <ng-container *ngIf="processSteps.length > 0; else unused">
        is used in the following role tasks:
        <div class="process-step-list">
            <ul>
                <li *ngFor="let processStep of processSteps">
                    <adapt-link-process-step [processStep]="processStep"></adapt-link-process-step>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-template #unused>
        is not in use.
    </ng-template>
</ng-container>
