import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";

@Component({
    selector: "adapt-display-catchup-visibility",
    templateUrl: "./display-catchup-visibility.component.html",
    styleUrls: ["./display-catchup-visibility.component.scss"],
})
export class DisplayCatchupVisibilityComponent implements OnChanges {
    @Input() public catchup!: SpeedCatchup;

    public explanationText = "";

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.catchup) {
            this.refreshText();
        }
    }

    public async refreshText() {
        if (this.catchup) {
            this.explanationText = this.getCatchupVisibilityExplanationText(this.catchup);
        }
    }

    // moved from old SCU service (as this is only ever used here)
    private getCatchupVisibilityExplanationText(catchup: SpeedCatchup) {
        if (!catchup.team) {
            return `Catch-ups for 'all teams' are visible to people who are on common teams (respecting the team settings) and
                to people with the ability to read all Peer Catch-ups (e.g. Organisation Leaders)`;
        } else if (!catchup.team.isPrivate) {
            return `Catch-ups for public teams are only visible to members of the team (respecting the team settings)
                and to people with the ability to read all Peer Catch-ups (e.g. organisation leaders)`;
        } else {
            return `Catch-ups for private teams are ONLY visible to members of the private team and coaches`;
        }
    }
}
