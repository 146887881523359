import { Component, ElementRef, Input } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { InitializedEvent } from "devextreme/viz/circular_gauge";
import { BaseGaugeRangeContainer } from "devextreme/viz/gauges/base_gauge";
import { CareerValuationUiService } from "../career-valuation-ui.service";

@Component({
    selector: "adapt-analyse-cvt-average-gauge",
    templateUrl: "./analyse-cvt-average-gauge.component.html",
})
export class AnalyseCareerValuationAverageGaugeComponent extends BaseComponent {
    @Input("value") public averageCareerValuation = -1;
    public rangeContainer: BaseGaugeRangeContainer;

    constructor(
        elementRef: ElementRef,
        careerValuationUiService: CareerValuationUiService,
    ) {
        super(elementRef);

        this.rangeContainer = {};
        this.rangeContainer.ranges = careerValuationUiService.valueStates.map((valueState) => ({
            startValue: valueState.lower,
            endValue: valueState.upper,
            color: valueState.fgColor,
        }));
    }

    public onInitialized(e: InitializedEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }
}
