<adapt-button-toggle-group adaptToolbarContent
                           alignment="center"
                           [value]="page"
                           (valueChange)="updateUrl($event)">
    <button [hidden]="(hasGuidance$ | async) === false"
            [adaptToggleGroupButton]="dashboardPage.Guidance"
            adaptButtonIcon="fal fa-fw fa-cubes"
            data-test="organisation-guidance-button">Organisation guidance</button>
    <button [hidden]="(hasTier1$ | async) === false"
            [adaptToggleGroupButton]="dashboardPage.EconomicEngine"
            adaptButtonIcon="fal fa-fw fa-subway"
            data-test="economic-engine-button">Economic engine</button>
    <button [hidden]="(hasMetrics$ | async) === false"
            [adaptToggleGroupButton]="dashboardPage.Metrics"
            adaptButtonIcon="fal fa-fw fa-chart-pie"
            data-test="metrics-button">Metrics</button>
    <button [hidden]="(hasTier1$ | async) === false"
            [adaptToggleGroupButton]="dashboardPage.Activity"
            adaptButtonIcon="fal fa-fw fa-rss"
            data-test="activity-button">Activity
    </button>
</adapt-button-toggle-group>

<adapt-two-column-page *ngIf="page === dashboardPage.Guidance">
    <ng-container left-column>
        <adapt-purpose-vision-part-dashboard-element *adaptIfAuthorised="purposeVisionRead"
                                                     part="purpose"
                                                     data-test="purpose-dashboard-element">
        </adapt-purpose-vision-part-dashboard-element>

        <adapt-values-constitution-dashboard-element *adaptIfAuthorised="valuesRead">
        </adapt-values-constitution-dashboard-element>
    </ng-container>

    <ng-container right-column>
        <adapt-purpose-vision-part-dashboard-element *adaptIfAuthorised="purposeVisionRead"
                                                     part="vision"
                                                     data-test="vision-dashboard-element">
        </adapt-purpose-vision-part-dashboard-element>
        <adapt-bullseye-dashboard-element *adaptIfFeatureActive="FeatureName.Bullseye; else bullseyeNotActive">
        </adapt-bullseye-dashboard-element>
        <ng-template #bullseyeNotActive>
            <adapt-purpose-vision-part-dashboard-element *adaptIfAuthorised="purposeVisionRead"
                                                         part="bullseye"></adapt-purpose-vision-part-dashboard-element>
        </ng-template>
    </ng-container>
</adapt-two-column-page>

<ng-container *ngIf="page === dashboardPage.EconomicEngine">
    <adapt-display-value-stream-summaries-dashboard-element *adaptIfAuthorised="valueStreamRead"
                                                            class="d-block p-3">
    </adapt-display-value-stream-summaries-dashboard-element>
</ng-container>

<adapt-two-column-page *ngIf="page === dashboardPage.Metrics">
    <ng-container left-column>
        <adapt-employee-engagement-dashboard-element *adaptIfAuthorised="employeeEngagementRead">
        </adapt-employee-engagement-dashboard-element>
        <adapt-resilient-business-assessment-dashboard-element *adaptIfAuthorised="resilientBusinessAssessmentRead">
        </adapt-resilient-business-assessment-dashboard-element>
    </ng-container>

    <ng-container right-column>
        <adapt-cvt-organisation-dashboard-element *adaptIfAuthorised="careerValuationRead">
        </adapt-cvt-organisation-dashboard-element>

        <adapt-cultural-index-organisation-dashboard-element *adaptIfAuthorised="culturalIndexRead">
        </adapt-cultural-index-organisation-dashboard-element>
    </ng-container>
</adapt-two-column-page>

<ng-container *ngIf="page === dashboardPage.Activity">
    <adapt-organisation-activity-feed-dashboard *adaptIfAuthorised="tier1Read">
    </adapt-organisation-activity-feed-dashboard>
</ng-container>
