import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { DxDateBoxComponent } from "devextreme-angular";
import moment from "moment";
import { EMPTY, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-edit-role-connection",
    templateUrl: "./edit-role-connection.component.html",
    styleUrls: ["./edit-role-connection.component.scss"],
})
export class EditRoleConnectionComponent extends BaseComponent implements OnInit {
    @ViewChild("endDateBox", { static: false }) public endDateBox?: DxDateBoxComponent;

    @Output() public roleConnectionChanged = new EventEmitter<RoleConnection>();
    @Output() public roleConnectionDeleted = new EventEmitter<RoleConnection>();

    @Input() public roleConnection?: RoleConnection;
    @Input() public model = RoleBreezeModel;
    @Input() public minStartDate?: Date;

    public isEmployeeRole = false;
    public endConnectionDisableMessage = "";
    public canEndConnection = false;
    public canReactivate = false;
    public showEndDate = false;
    public endDate?: Date;
    public cannotReactivateMessage = "";
    public maxStartDate?: Date;
    public endDateRequired = false;
    public maxEndDate?: Date;
    public endDateOpened = false;

    private roleConnectionChange$ = new Subject<void>();
    private now = new Date();

    constructor(
        private directorySharedService: DirectorySharedService,
        private commonDialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setAdditionalSettings();
    }

    private setAdditionalSettings() {
        this.updateSettingsFromOtherRoleConnections(); // set initial value for canReactivate

        if (this.roleConnection?.connection) {
            this.minStartDate = this.roleConnection.connection.startDate;
        }

        if (this.minStartDate && this.roleConnection && moment(this.minStartDate).isAfter(this.roleConnection?.startDate)) {
            // startDate is before minStartDate (usually the connection startDate)
            this.roleConnection.startDate = this.minStartDate;
        }

        if (this.roleConnection!.endDate) {
            this.maxStartDate = this.roleConnection!.endDate;

            // trigger display of endDate separately from actual endDate value so that
            // dxDateBox is not unloaded before onValueChanged can be run.
            this.showEndDate = true;
        }

        // copy roleConnection endDate into settings so that the container will not
        // move this role connection immediately after 'endRoleNow' - at least let
        // user have the choice of cancelling the accidentally pressed 'End Role'
        this.endDate = this.roleConnection!.endDate;

        if (this.roleConnection!.isHistoric()) {
            this.endDateRequired = true;
        }

        this.roleConnectionChange$.pipe(
            this.takeUntilDestroyed(),
        )
            .subscribe(() => this.updateSettingsFromOtherRoleConnections());
    }

    private async updateSettingsFromOtherRoleConnections() {
        if (this.roleConnection!.connection) {
            const today = moment.utc();
            const matchingActiveRoleConnection = this.getMatchingActiveRoleConnection();

            if (this.isEmployeeRole) {
                this.endConnectionDisableMessage = "Employee role cannot be ended";
            } else {
                const canRemove = await this.directorySharedService.promiseToVerifyRoleRemoval(this.roleConnection!.role, this.roleConnection!.connection?.person);
                this.updateCanEndConnection(canRemove);
            }

            if (matchingActiveRoleConnection) {
                this.maxEndDate = moment.min(moment(matchingActiveRoleConnection.startDate), today).toDate();
                this.canReactivate = false;
                this.cannotReactivateMessage = "There is a currently an active role similar to this or start date is in the future or the role is no longer valid";
            } else if (this.roleConnection!.role && !this.roleConnection!.role.isActive()) {
                this.canReactivate = false;
                this.cannotReactivateMessage = "Cannot reactivate a role connection for an archived role";
            } else {
                this.cannotReactivateMessage = "";
                this.maxEndDate = today.toDate();
                this.canReactivate = this.roleFilter(this.roleConnection!.role) && this.connectionFilter(this.roleConnection!.connection?.person);
            }
        }
    }

    private updateCanEndConnection(canRemove: boolean) {
        this.canEndConnection = canRemove;
        if (canRemove) {
            this.endConnectionDisableMessage = "";
        } else {
            this.endConnectionDisableMessage = "Role to edit access management cannot be ended by the current person";
        }
    }

    public get minEndDate() {
        return this.roleConnection?.startDate;
    }

    public get startDateInFuture() {
        return this.roleConnection?.startDate && this.roleConnection.startDate > this.now;
    }

    public onChanged() {
        this.roleConnectionChanged.emit(this.roleConnection!);
        this.roleConnectionChange$.next();
    }

    private getMatchingActiveRoleConnection() {
        const matchingRoleConnections = this.roleConnection!.connection?.roleConnections
            .filter((i) => this.matchRoleConnection(i));

        return ArrayUtilities.getSingleFromArray(matchingRoleConnections);
    }

    private matchRoleConnection(roleConnection: RoleConnection) {
        return roleConnection.roleConnectionId !== this.roleConnection!.roleConnectionId
            && roleConnection.roleId === this.roleConnection!.roleId
            && roleConnection.connectionId === this.roleConnection!.connectionId
            && roleConnection.teamId === this.roleConnection!.teamId
            && roleConnection.isNotHistoric();
    }

    public onEndDateClosed() {
        this.roleConnection!.endDate = this.endDate;
        this.onChanged();
    }

    @Autobind
    public roleFilter(role?: Role) {
        const isTeamRole = () => {
            return role && role.teamId;
        };

        const isCurrentlyAllocatedRole = () => {
            return this.roleConnection!.connection?.roleConnections.some(isRoleAllocated);
        };

        const isAllowedSystemRole = () => {
            if (!role) {
                return false;
            }

            return !role.extensions.isSystemAllocatedRole() || allocatingExistingRoleToTeam();
        };

        const allocatingExistingRoleToTeam = () => {
            return this.roleConnection!.team
                && this.roleConnection!.connection?.roleConnections.some(isRoleAllocated);
        };

        const isRoleAllocated = (roleConnection: RoleConnection) => {
            return roleConnection.isNotHistoric()
                && roleConnection.role === role
                && roleConnection.teamId === this.roleConnection!.teamId;
        };

        return !isTeamRole()
            && !isCurrentlyAllocatedRole()
            && isAllowedSystemRole();
    }

    @Autobind
    public connectionFilter(person?: Person) {
        const isConnectionAssignedToRole = (roleConnection: RoleConnection) => {
            return roleConnection.connection
                && roleConnection.connection.person === person
                && roleConnection.teamId === this.roleConnection!.teamId
                && roleConnection.isNotHistoric();
        };

        return !this.roleConnection!.role?.roleConnections.some(isConnectionAssignedToRole);
    }

    public onStartDateChanged(value: Date) {
        this.roleConnection!.startDate = value;

        this.onChanged();
    }

    public onEndDateChanged(value: Date) {
        this.endDate = value;

        if (this.endDate) {
            // startDate cannot be after endDate
            this.maxStartDate = this.endDate;
        } else {
            // when the date has been cleared using the clear button, we must trigger an OnChanged() event
            this.roleConnection!.endDate = undefined;
            this.onChanged();
        }

        this.showEndDate = !!this.endDate;
    }

    public onPersonChanged(person?: Person) {
        if (!person) {
            return;
        }
        this.roleConnection!.connection = person.getLatestConnection()!;
        this.minStartDate = this.roleConnection!.connection.startDate;
        if (this.roleConnection!.startDate < this.minStartDate) {
            this.roleConnection!.startDate = this.minStartDate;
        }

        this.onChanged();
    }

    public promptToDeleteRoleConnection() {
        let confirmMessage;
        if (this.model === RoleBreezeModel) {
            confirmMessage = "Are you sure you wish to delete this role allocation? This will remove the role allocation from the history of this position.";
        } else {
            confirmMessage = "Are you sure you wish to delete this person? This will remove the role allocation from their position history.";
        }

        this.commonDialogService.openConfirmationDialogWithBoolean({ title: "Delete " + this.model.singularName + " Allocation", message: confirmMessage })
            .pipe(
                switchMap((value) => value ? of(value) : EMPTY),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.onDeletion());
    }

    public endRoleConnection() {
        this.endDate = moment.utc().toDate();

        if (this.endDate < this.roleConnection!.startDate) {
            this.endDate = this.roleConnection!.startDate;
        }

        this.showEndDate = true;
        this.endDateOpened = true;
    }

    public promiseToReactivate() {
        this.roleConnection!.endDate = undefined;
        this.onChanged();
    }

    public onDeletion() {
        this.roleConnectionDeleted.emit(this.roleConnection!);
        this.roleConnectionChange$.next();
    }
}
