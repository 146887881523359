<div class="cultural-index-container">
    <div class="cultural-index-1">
        <small>{{culturalIndexConfig?.category1Label}}</small>
    </div>
    <div class="cultural-index-2">
        <small>{{culturalIndexConfig?.category2Label}}</small>
    </div>
    <div class="cultural-index-3">
        <small>{{culturalIndexConfig?.category3Label}}</small>
    </div>
    <div class="cultural-index-4">
        <small>{{culturalIndexConfig?.category4Label}}</small>
    </div>

    <input type="range"
           class="cultural-index-slider"
           min="0"
           max="2"
           step="0.1"
           list="indexSettings"
           data-test="cultural-index-slider"
           [(ngModel)]="culturalIndex.value"
           [disabled]="isDisabled" />
    <datalist *ngIf="!isDisabled"
              id="indexSettings">
        <!--<option>0.0</option>-->
        <option>0.1</option>
        <option>0.2</option>
        <option>0.3</option>
        <option>0.4</option>
        <!--<option>0.5</option>-->
        <option>0.6</option>
        <option>0.7</option>
        <option>0.8</option>
        <option>0.9</option>
        <!--<option>1.0</option>-->
        <option>1.1</option>
        <option>1.2</option>
        <option>1.3</option>
        <option>1.4</option>
        <!--<option>1.5</option>-->
        <option>1.6</option>
        <option>1.7</option>
        <option>1.8</option>
        <option>1.9</option>
        <!--<option>2.0</option>-->
    </datalist>
</div>
