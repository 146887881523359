"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var layout_1 = require("./layout");
var gridrouter_1 = require("./gridrouter");
function gridify(pgLayout, nudgeGap, margin, groupMargin) {
  pgLayout.cola.start(0, 0, 0, 10, false);
  var gridrouter = route(pgLayout.cola.nodes(), pgLayout.cola.groups(), margin, groupMargin);
  return gridrouter.routeEdges(pgLayout.powerGraph.powerEdges, nudgeGap, function (e) {
    return e.source.routerNode.id;
  }, function (e) {
    return e.target.routerNode.id;
  });
}
exports.gridify = gridify;
function route(nodes, groups, margin, groupMargin) {
  nodes.forEach(function (d) {
    d.routerNode = {
      name: d.name,
      bounds: d.bounds.inflate(-margin)
    };
  });
  groups.forEach(function (d) {
    d.routerNode = {
      bounds: d.bounds.inflate(-groupMargin),
      children: (typeof d.groups !== 'undefined' ? d.groups.map(function (c) {
        return nodes.length + c.id;
      }) : []).concat(typeof d.leaves !== 'undefined' ? d.leaves.map(function (c) {
        return c.index;
      }) : [])
    };
  });
  var gridRouterNodes = nodes.concat(groups).map(function (d, i) {
    d.routerNode.id = i;
    return d.routerNode;
  });
  return new gridrouter_1.GridRouter(gridRouterNodes, {
    getChildren: function (v) {
      return v.children;
    },
    getBounds: function (v) {
      return v.bounds;
    }
  }, margin - groupMargin);
}
function powerGraphGridLayout(graph, size, grouppadding) {
  var powerGraph;
  graph.nodes.forEach(function (v, i) {
    return v.index = i;
  });
  new layout_1.Layout().avoidOverlaps(false).nodes(graph.nodes).links(graph.links).powerGraphGroups(function (d) {
    powerGraph = d;
    powerGraph.groups.forEach(function (v) {
      return v.padding = grouppadding;
    });
  });
  var n = graph.nodes.length;
  var edges = [];
  var vs = graph.nodes.slice(0);
  vs.forEach(function (v, i) {
    return v.index = i;
  });
  powerGraph.groups.forEach(function (g) {
    var sourceInd = g.index = g.id + n;
    vs.push(g);
    if (typeof g.leaves !== 'undefined') g.leaves.forEach(function (v) {
      return edges.push({
        source: sourceInd,
        target: v.index
      });
    });
    if (typeof g.groups !== 'undefined') g.groups.forEach(function (gg) {
      return edges.push({
        source: sourceInd,
        target: gg.id + n
      });
    });
  });
  powerGraph.powerEdges.forEach(function (e) {
    edges.push({
      source: e.source.index,
      target: e.target.index
    });
  });
  new layout_1.Layout().size(size).nodes(vs).links(edges).avoidOverlaps(false).linkDistance(30).symmetricDiffLinkLengths(5).convergenceThreshold(1e-4).start(100, 0, 0, 0, false);
  return {
    cola: new layout_1.Layout().convergenceThreshold(1e-3).size(size).avoidOverlaps(true).nodes(graph.nodes).links(graph.links).groupCompactness(1e-4).linkDistance(30).symmetricDiffLinkLengths(5).powerGraphGroups(function (d) {
      powerGraph = d;
      powerGraph.groups.forEach(function (v) {
        v.padding = grouppadding;
      });
    }).start(50, 0, 100, 0, false),
    powerGraph: powerGraph
  };
}
exports.powerGraphGridLayout = powerGraphGridLayout;
