import { Component, Input } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ExportType } from "../export-type-selector-step/export-type-selector-step.component";
import { IRolePersonAllocations } from "../role-person-allocations-step/role-person-allocations-step.component";

@Component({
    selector: "adapt-export-confirmation-step",
    templateUrl: "./export-confirmation-step.component.html",
})
export class ExportConfirmationStepComponent extends BaseComponent {
    @Input() public processMap!: ProcessMap;
    @Input() public rolePersonAllocations!: IRolePersonAllocations;

    @Input() public exportDestination!: ExportType;
    @Input() public board?: Board;
    @Input() public prefix?: string;
    @Input() public started = false;
    @Input() public completed = false;
    @Input() public progressTotalCount = 0;
    @Input() public progressCurrentCount = 0;

    constructor() {
        super();
    }
}
