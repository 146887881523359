<adapt-button-toggle-group adaptToolbarContent
                           alignment="center"
                           [value]="page"
                           (valueChange)="updateUrl($event)">
    <button [adaptToggleGroupButton]="dashboardPage.Guidance"
            adaptButtonIcon="fal fa-fw fa-cubes">Guidance
    </button>
    <button [hidden]="!hasTier2"
            [adaptToggleGroupButton]="dashboardPage.Systems"
            [adaptButtonIcon]="systemEntityIcon">Systems
    </button>
    <button [hidden]="!hasStewardshipPage"
            [adaptToggleGroupButton]="dashboardPage.Stewardship"
            adaptButtonIcon="fal fa-fw fa-subway">Stewardship
    </button>
    <button [hidden]="!hasPeopleAndCulturePage"
            [adaptToggleGroupButton]="dashboardPage.PeopleAndCulture"
            adaptButtonIcon="fal fa-fw fa-chart-pie">People and culture
    </button>
    <button [hidden]="!hasTier2"
            [adaptToggleGroupButton]="dashboardPage.Activity"
            adaptButtonIcon="fal fa-fw fa-rss">Activity
    </button>
</adapt-button-toggle-group>

<ng-container *adaptLoadingUntilFirstEmit="let team of team$">
    <ng-container *ngIf="!!team">
        <!-- so that we won't see the error toaster if accessing invalid/delete team before redirected away -->
        <adapt-toolbar-title>
            <div class="d-flex align-items-center">
                <h2>{{team.name}}
                    <adapt-team-private-icon [team]="team"></adapt-team-private-icon>
                </h2>
                <adapt-display-labels class="ms-2"
                                      [labelLocations]="team.labelLocations"></adapt-display-labels>
            </div>
        </adapt-toolbar-title>
        <div adaptToolbarContent>
            <ng-container *adaptIfAuthorised="configureTeam;Entity:team">
                <button *ngIf="!team.isActive()"
                        [adaptBlockingClick]="reactivateTeam"
                        adaptButtonIcon="fal fa-power-off"
                        [unsubscribeOnDestroy]="false">Reactivate team
                </button>
                <a [routerLink]="teamConfigureLink$ | async"
                   adaptButton="configureLink"
                   adaptTooltip="Go to team configuration"></a>
            </ng-container>
        </div>
        <adapt-toolbar-inactive [entity]="team"
                                type="team">
        </adapt-toolbar-inactive>

        <adapt-two-column-page *ngIf="page === dashboardPage.Guidance">
            <div left-column>
                <adapt-team-people-dashboard-element [team]="team"></adapt-team-people-dashboard-element>
                <adapt-team-location-dashboard-element *adaptIfAuthorised="tier1Read"
                                                       [team]="team"></adapt-team-location-dashboard-element>
                <adapt-team-systems-dashboard-element *ngIf="hasTier2"
                                                      [team]="team"></adapt-team-systems-dashboard-element>
                <adapt-external-dashboard-dashboard-element [team]="team"></adapt-external-dashboard-dashboard-element>
            </div>

            <div right-column
                 class="row">
                <adapt-values-constitution-dashboard-element *adaptIfAuthorised="valuesRead;Entity:team"
                                                             [team]="team"
                                                             class="col-12 col-md-6">
                </adapt-values-constitution-dashboard-element>
                <adapt-team-guidance-dashboard-element *adaptIfAuthorised="purposeVisionRead;Entity:team"
                                                       [team]="team"
                                                       data-test="purpose-dashboard-element">
                </adapt-team-guidance-dashboard-element>
            </div>
        </adapt-two-column-page>

        <div *ngIf="page === dashboardPage.Systems"
             class="h-100">
            <adapt-display-systems class="mt-3"
                                   [fullPageCTA]="true"
                                   [team]="team"></adapt-display-systems>
        </div>

        <ng-container *ngIf="page === dashboardPage.Stewardship">
            <adapt-two-column-page *adaptIfAuthorised="viewTeamMeetings;Entity:team;else meetingFeatureInactiveTemplate">
                <div left-column>
                    <adapt-team-meetings-dashboard-element [team]="team"></adapt-team-meetings-dashboard-element>
                    <adapt-team-meeting-guidance-dashboard-element [team]="team">
                    </adapt-team-meeting-guidance-dashboard-element>
                </div>
                <div right-column>
                    <adapt-objectives-tree-dashboard-element *adaptIfAuthorised="readObjectives;Entity:team"
                                                             [team]="team"
                                                             [isCompactView]="true"></adapt-objectives-tree-dashboard-element>
                    <adapt-team-work-dashboard-element [team]="team"></adapt-team-work-dashboard-element>
                </div>
            </adapt-two-column-page>

            <ng-template #meetingFeatureInactiveTemplate>
                <div class="p-3">
                    <adapt-objectives-tree-dashboard-element *adaptIfAuthorised="readObjectives;Entity:team"
                                                             [team]="team"
                                                             [isCompactView]="true"></adapt-objectives-tree-dashboard-element>
                    <adapt-team-work-dashboard-element *ngIf="hasStats"
                                                       [team]="team"></adapt-team-work-dashboard-element>

                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="page === dashboardPage.PeopleAndCulture">
            <adapt-two-column-page *adaptIfAuthorised="readAllPeerCatchUps;Entity:team;else pcuInactiveTemplate">
                <div left-column>
                    <adapt-team-people-dashboard-element [team]="team"></adapt-team-people-dashboard-element>
                    <adapt-peer-catch-up-dashboard-element *adaptIfAuthorised="readAllPeerCatchUps;Entity:team"
                                                           [team]="team"></adapt-peer-catch-up-dashboard-element>
                </div>
                <div right-column>
                    <adapt-team-assessment-dashboard-element *adaptIfAuthorised="readTeamAssessment;Entity:team"
                                                             [team]="team"></adapt-team-assessment-dashboard-element>
                </div>
            </adapt-two-column-page>

            <ng-template #pcuInactiveTemplate>
                <div *adaptIfAuthorised="readTeamAssessment;Entity:team"
                     class="p-3">
                    <adapt-team-assessment-dashboard-element [team]="team"></adapt-team-assessment-dashboard-element>
                </div>
            </ng-template>
        </ng-container>

        <div *ngIf="page === dashboardPage.Activity"
             class="p-3">
            <adapt-dashboard-element name="{{team?.name}} team activity">
                <adapt-team-activity-feed [team]="team"
                                          feedTitle="">
                </adapt-team-activity-feed>
            </adapt-dashboard-element>
        </div>
    </ng-container>
</ng-container>
