import { Component, Input, OnInit } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { IntegratedArchitectureFrameworkService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkUiService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-ui.service";
import { EMPTY, from } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ConfigureAccessLevelDialogComponent, IConfigureAccessLevelDialogData } from "../configure-access-level-dialog/configure-access-level-dialog.component";

enum AccessLevelActions {
    ConfigureAccessLevel = "Configure ",
    CopyAccessLevel = "Copy ",
    DeleteAccessLevel = "Delete ",
}

@Component({
    selector: "adapt-access-level-actions",
    template: `<adapt-menu [items]="actionMenu"></adapt-menu>`,
})
export class AccessLevelActionsComponent extends BaseComponent implements OnInit {
    @Input() public role!: Role;

    public actionMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];

    private readonly configureAccessLevelItem: IAdaptMenuItem;
    private readonly copyAccessLevelItem: IAdaptMenuItem;
    private readonly deleteAccessLevelItem: IAdaptMenuItem;

    public constructor(
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
        private commonDialogService: AdaptCommonDialogService,
        private integratedArchitectureFrameworkUiService: IntegratedArchitectureFrameworkUiService,
    ) {
        super();

        this.configureAccessLevelItem = {
            text: AccessLevelActions.ConfigureAccessLevel,
            icon: "fal fa-fw fa-cog",
            onClick: this.configureAction,
        };
        this.copyAccessLevelItem = {
            text: AccessLevelActions.CopyAccessLevel,
            icon: "fal fa-fw fa-copy",
            onClick: this.copyAction,
        };
        this.deleteAccessLevelItem = {
            text: AccessLevelActions.DeleteAccessLevel,
            icon: "fal fa-fw fa-trash-alt",
            onClick: this.deleteAction,
        };

        this.actionMenu[0].items = [
            this.configureAccessLevelItem,
            this.copyAccessLevelItem,
            this.deleteAccessLevelItem,
        ];
    }

    public async ngOnInit() {
        this.updateItemsState();
    }

    private updateItemsState() {
        // there is an odd occasion where the role has not been set (prob due a delete happening, so guard against this scenario)
        if (!this.role) {
            this.configureAccessLevelItem.visible = false;
            this.deleteAccessLevelItem.visible = false;
            this.copyAccessLevelItem.visible = false;
            return;
        }

        this.deleteAccessLevelItem.visible = this.role.extensions.isLeaderAccessRole()
            && !this.role.extensions.isCoachAccessRole()
            && !this.role.extensions.isSystemAllocatedRole();
        this.copyAccessLevelItem.visible = this.role.extensions.isLeaderAccessRole()
            && !this.role.extensions.isCoachAccessRole()
            && !this.role.extensions.isSystemAllocatedRole();
    }

    @Autobind
    private configureAction() {
        const data: IConfigureAccessLevelDialogData = {
            role: this.role,
        };

        this.commonDialogService.open(ConfigureAccessLevelDialogComponent, data).pipe(
            tap(() => {
                this.updateItemsState();
                this.broadcastRefetchDataEvent();
            }),
            catchError(() => {
                this.broadcastUpdateDimensionsEvent();
                return EMPTY;
            }),
        ).subscribe();
    }

    @Autobind
    private copyAction() {
        from(this.integratedArchitectureFrameworkService.promiseToCopyRole(this.role)).pipe(
            switchMap((copyResult) => this.editCopiedRole(copyResult)),
            tap(() => {
                this.updateItemsState();
                this.broadcastRefetchDataEvent();
            }),
            catchError(() => {
                this.broadcastUpdateDimensionsEvent();
                return EMPTY;
            }),
        ).subscribe();
    }

    @Autobind
    private deleteAction() {
        this.integratedArchitectureFrameworkUiService.promptToDeleteRole(this.role)
            .subscribe(() => this.broadcastRefetchDataEvent());
    }

    private editCopiedRole(copyResult: { role: Role, changedEntities: IBreezeEntity[] }) {
        const aldata: IConfigureAccessLevelDialogData = {
            role: copyResult.role,
            changedEntities: copyResult.changedEntities,
        };

        return this.commonDialogService.open(ConfigureAccessLevelDialogComponent, aldata);
    }

    private broadcastRefetchDataEvent() {
        this.integratedArchitectureFrameworkUiService.emitRefetchRequired();
    }

    private broadcastUpdateDimensionsEvent() {
        // need this to realign fixed and non-fixed columns (see user-role-actions.component.ts)
        // - especially when a dialog is popped up, the background curtain will remove the scrollbar
        //   causing the grid to not redrawn correctly.
        this.integratedArchitectureFrameworkUiService.emitGridDimensionsUpdated();
    }
}
