"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var pqueue_1 = require("./pqueue");
var Neighbour = function () {
  function Neighbour(id, distance) {
    this.id = id;
    this.distance = distance;
  }
  return Neighbour;
}();
var Node = function () {
  function Node(id) {
    this.id = id;
    this.neighbours = [];
  }
  return Node;
}();
var QueueEntry = function () {
  function QueueEntry(node, prev, d) {
    this.node = node;
    this.prev = prev;
    this.d = d;
  }
  return QueueEntry;
}();
var Calculator = function () {
  function Calculator(n, es, getSourceIndex, getTargetIndex, getLength) {
    this.n = n;
    this.es = es;
    this.neighbours = new Array(this.n);
    var i = this.n;
    while (i--) this.neighbours[i] = new Node(i);
    i = this.es.length;
    while (i--) {
      var e = this.es[i];
      var u = getSourceIndex(e),
        v = getTargetIndex(e);
      var d = getLength(e);
      this.neighbours[u].neighbours.push(new Neighbour(v, d));
      this.neighbours[v].neighbours.push(new Neighbour(u, d));
    }
  }
  Calculator.prototype.DistanceMatrix = function () {
    var D = new Array(this.n);
    for (var i = 0; i < this.n; ++i) {
      D[i] = this.dijkstraNeighbours(i);
    }
    return D;
  };
  Calculator.prototype.DistancesFromNode = function (start) {
    return this.dijkstraNeighbours(start);
  };
  Calculator.prototype.PathFromNodeToNode = function (start, end) {
    return this.dijkstraNeighbours(start, end);
  };
  Calculator.prototype.PathFromNodeToNodeWithPrevCost = function (start, end, prevCost) {
    var q = new pqueue_1.PriorityQueue(function (a, b) {
        return a.d <= b.d;
      }),
      u = this.neighbours[start],
      qu = new QueueEntry(u, null, 0),
      visitedFrom = {};
    q.push(qu);
    while (!q.empty()) {
      qu = q.pop();
      u = qu.node;
      if (u.id === end) {
        break;
      }
      var i = u.neighbours.length;
      while (i--) {
        var neighbour = u.neighbours[i],
          v = this.neighbours[neighbour.id];
        if (qu.prev && v.id === qu.prev.node.id) continue;
        var viduid = v.id + ',' + u.id;
        if (viduid in visitedFrom && visitedFrom[viduid] <= qu.d) continue;
        var cc = qu.prev ? prevCost(qu.prev.node.id, u.id, v.id) : 0,
          t = qu.d + neighbour.distance + cc;
        visitedFrom[viduid] = t;
        q.push(new QueueEntry(v, qu, t));
      }
    }
    var path = [];
    while (qu.prev) {
      qu = qu.prev;
      path.push(qu.node.id);
    }
    return path;
  };
  Calculator.prototype.dijkstraNeighbours = function (start, dest) {
    if (dest === void 0) {
      dest = -1;
    }
    var q = new pqueue_1.PriorityQueue(function (a, b) {
        return a.d <= b.d;
      }),
      i = this.neighbours.length,
      d = new Array(i);
    while (i--) {
      var node = this.neighbours[i];
      node.d = i === start ? 0 : Number.POSITIVE_INFINITY;
      node.q = q.push(node);
    }
    while (!q.empty()) {
      var u = q.pop();
      d[u.id] = u.d;
      if (u.id === dest) {
        var path = [];
        var v = u;
        while (typeof v.prev !== 'undefined') {
          path.push(v.prev.id);
          v = v.prev;
        }
        return path;
      }
      i = u.neighbours.length;
      while (i--) {
        var neighbour = u.neighbours[i];
        var v = this.neighbours[neighbour.id];
        var t = u.d + neighbour.distance;
        if (u.d !== Number.MAX_VALUE && v.d > t) {
          v.d = t;
          v.prev = u;
          q.reduceKey(v.q, v, function (e, q) {
            return e.q = q;
          });
        }
      }
    }
    return d;
  };
  return Calculator;
}();
exports.Calculator = Calculator;
