<adapt-dashboard-element name="Career Valuation"
                         [url]="url$ | async">
    <ng-container *adaptLoading="averageCareerValuation < 0">
        <div *ngIf="hasData; else noCvtsTemplate"
             class="chart-wrapper">
            <adapt-analyse-cvt-average-gauge [value]="averageCareerValuation"></adapt-analyse-cvt-average-gauge>
        </div>
    </ng-container>
    <ng-template #noCvtsTemplate>
        <adapt-call-to-action-inline>
            Adapt’s career valuation tool supports a structured conversation about what
            motivates each employee, and how the organisation is delivering on their needs.
            This is important data for the leadership team to use as inputs into their
            strategy.

            <a adaptCallToActionButton
               *ngIf="canViewClf$ | async"
               [routerLink]="clfHref$ | async">
                Get started <i class="fal fa-angle-right"></i>
            </a>
        </adapt-call-to-action-inline>
    </ng-template>
</adapt-dashboard-element>
