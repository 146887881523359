import { Component, Input } from "@angular/core";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { UserManagementUiService } from "app/organisation/user-management/user-management-ui.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-roles",
    templateUrl: "./person-profile-roles.component.html",
})
export class PersonProfileRolesComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;

    public person$ = new BehaviorSubject<Person | undefined>(undefined);
    public roleConnections$: Observable<RoleConnection[]>;
    public hasActiveConnection$: Observable<boolean>;
    public ManagePositionAndRoles = DirectoryAuthService.ManagePositionAndRoles;

    constructor(
        private personService: PersonService,
        rxjsBreezeService: RxjsBreezeService,
        private userManagementUiService: UserManagementUiService,
    ) {
        super();

        const entityChange$ = rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            startWith(undefined),
        );

        const setPerson$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
        );

        this.hasActiveConnection$ = combineLatest([entityChange$, setPerson$]).pipe(
            map(([_change, person]) => person.isActive()),
        );

        this.roleConnections$ = combineLatest([entityChange$, setPerson$]).pipe(
            switchMap(([_change, person]) => this.personService.getActiveRoleConnections(person.personId)),
            map((roleConnections) => roleConnections
                .filter(DirectorySharedService.isNotTeamBasedRoleConnection)
                .filter((rc) => DirectorySharedService.isNotAccessLevelRoleConnection(rc) || rc.role?.extensions.isCulturalLeaderRole())
                // role can be null when adding a role to a person
                // where the role is created within that dialog, and the role being created has been given another person
                .sort((a, b) => a.role && b.role ? a.role.label.localeCompare(b.role.label) : 0)),
        );
    }

    public editRoles() {
        const person = this.person$.value!;
        const connection = person.getLatestConnection()!;
        this.userManagementUiService.editPersonRoles(connection).subscribe();
    }
}
