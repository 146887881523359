import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSelectCountryModule } from "@common/ux/select-country/select-country.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { provideEmployeeDirectoryPageRoute, provideStakeholderDirectoryPageRoute } from "@org-common/lib/directory-shared/directory-shared.service";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptIntegratedArchitectureModule } from "app/features/architecture/integrated-architecture/integrated-architecture.module";
import { AdaptIntegratedArchitectureSharedModule } from "app/features/architecture/integrated-architecture/integrated-architecture-shared.module";
import { AdaptRoleModule } from "app/features/architecture/role/role.module";
import { AdaptRoleSharedModule } from "app/features/architecture/role/role-shared.module";
import { stakeholderDirectoryPageRoute } from "app/features/stakeholders/stakeholder-directory-page/stakeholder-directory-page.route";
import { DxAccordionModule, DxDataGridModule, DxDateBoxModule, DxSelectBoxModule } from "devextreme-angular";
import { AdaptTeamsModule } from "../teams/teams.module";
import { EditPersonRolesDialogComponent } from "./edit-person-roles-dialog/edit-person-roles-dialog.component";
import { EmployeeDirectoryPageComponent } from "./employee-directory-page/employee-directory-page.component";
import { employeeDirectoryPageRoute } from "./employee-directory-page/employee-directory-page.route";
import { SelectProfileItemComponent } from "./select-profile-item/select-profile-item.component";
import { SelectProfileItemValueComponent } from "./select-profile-item-value/select-profile-item-value.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptCommonUserModule,
        AdaptTeamsModule,
        AdaptIntegratedArchitectureModule,
        AdaptDirectorySharedModule,
        DxDataGridModule,
        AdaptSelectCountryModule,
        AdaptRoleModule,
        AdaptTeamDashboardSharedModule,
        AdaptAuthorisationModule,
        DxSelectBoxModule,
        AdaptCommonDialogModule,
        DxDateBoxModule,
        AdaptDateModule,
        AdaptIntegratedArchitectureSharedModule,
        AdaptRoleSharedModule,
        DxAccordionModule,
    ],
    exports: [
        AdaptDirectorySharedModule,
        SelectProfileItemComponent,
        SelectProfileItemValueComponent,
    ],
    declarations: [
        SelectProfileItemComponent,
        SelectProfileItemValueComponent,
        EmployeeDirectoryPageComponent,
        EditPersonRolesDialogComponent,
    ],
    providers: [
        provideEmployeeDirectoryPageRoute(employeeDirectoryPageRoute),
        provideStakeholderDirectoryPageRoute(stakeholderDirectoryPageRoute),
    ],
})
export class AdaptDirectoryModule {
    public static readonly Routes = [
        ...employeeDirectoryPageRoute.routes,
        ...stakeholderDirectoryPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        DirectoryAuthService.registerAccess(authorisationService);
    }
}
