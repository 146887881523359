import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PersonProfileCategory } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";

interface IDxGroupedDataSource<TData = any> {
    key: any,
    items: TData[]
}

@Component({
    selector: "adapt-select-profile-item",
    templateUrl: "./select-profile-item.component.html",
})
export class SelectProfileItemComponent implements OnInit {
    @Input() public profileItem?: PersonProfileItem;
    @Output() public profileItemChange = new EventEmitter<PersonProfileItem | undefined>();

    public profileItems: IDxGroupedDataSource<PersonProfileItem>[] = [];

    public constructor(
        private directorySharedService: DirectorySharedService,
    ) { }

    public async ngOnInit() {
        this.profileItems = await this.getGroupedCategories();
    }

    private async getGroupedCategories() {
        const categories: PersonProfileCategory[] = await this.directorySharedService.promiseToGetAllPersonProfileCategories();
        return categories.map((category) => ({
            key: category.label,
            items: category.personProfileItems
                .filter((item) => item.isText)
                .sort((a, b) => a.label.localeCompare(b.label)),
        }));
    }
}
