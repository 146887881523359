import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IAdaptLinkObject } from "@common/route/route.service";
import { Observable } from "rxjs";
import { teamKanbanPageRoute } from "../kanban-page/kanban-page.route";

@Component({
    selector: "adapt-link-team-kanban",
    templateUrl: "./link-team-kanban.component.html",
    styleUrls: ["./link-team-kanban.component.scss"],
})
export class LinkTeamKanbanComponent implements OnChanges {
    @Input() public buttonText = "View Work";
    @Input() public team!: Team;
    @Input() public board?: Board;
    @Input() public onlyShowOverdue = false;

    public url$?: Observable<IAdaptLinkObject>;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.team || changes.board || changes.onlyShowOverdue) {
            if (this.team) {
                const searchParams: { [key: string]: any } = {};
                if (this.board) {
                    searchParams.boardId = this.board.boardId;
                }

                searchParams.onlyShowOverdue = this.onlyShowOverdue;
                this.url$ = teamKanbanPageRoute.getRouteObject({ teamId: this.team.teamId }, searchParams);
            }
        }
    }
}
