import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-board-selection-step",
    templateUrl: "./board-selection-step.component.html",
    styleUrls: ["../exporter-style.scss"],
})
export class BoardSelectionStepComponent extends BaseComponent {
    @Input() public board?: Board;
    @Output() public boardChange = new EventEmitter<Board>();

    constructor() {
        super();
    }

    public onBoardsChange(boards: Board[]) {
        this.board = boards[0];
        this.boardChange.emit(this.board);
    }
}
