import { Injectable } from "@angular/core";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { forkJoin, switchMap } from "rxjs";
import { EditValueStreamDialogComponent } from "../value-streams/edit-value-stream-dialog/edit-value-stream-dialog.component";
import { ManageValueStreamsDialogComponent } from "../value-streams/manage-value-streams-dialog/manage-value-streams-dialog.component";
import { EditValueStreamProductsDialogComponent } from "./edit-value-stream-products-dialog/edit-value-stream-products-dialog.component";
import { EditValueStreamStakeholdersDialogComponent } from "./edit-value-stream-stakeholders-dialog/edit-value-stream-stakeholders-dialog.component";
import { Tier1ArchitectureService } from "./tier1-architecture.service";

@Injectable({
    providedIn: "root",
})
export class Tier1ArchitectureUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private tier1Factory: Tier1ArchitectureService,
    ) { }

    public editValueStream(valueStream: ValueStream) {
        return this.dialogService.open(EditValueStreamDialogComponent, valueStream);
    }

    @Autobind
    public manageValueStreams() {
        return this.dialogService.open(ManageValueStreamsDialogComponent);
    }

    public editValueStreamBusinessModelElement(valueStream: IEditSimpleValueBreezeEntityDialogData) {
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, valueStream);
    }

    public editValueStreamProducts(valueStream: ValueStream) {
        return this.dialogService.open(EditValueStreamProductsDialogComponent, valueStream);
    }

    public editValueStreamCustomers(valueStream: ValueStream) {
        return forkJoin([
            this.tier1Factory.getAllStakeholders(),
            this.tier1Factory.getAllStakeholderStories(),
        ]).pipe(
            switchMap(() => this.dialogService.open(EditValueStreamStakeholdersDialogComponent, valueStream)),
        );
    }
}
