import { Component } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SurveyService } from "../../survey.service";
import { SurveyUtils } from "../../survey-utils";

@Component({
    selector: "adapt-resilient-business-assessment-dashboard-element",
    styleUrls: ["./rb-assessment-dashboard-element.component.scss"],
    templateUrl: "./rb-assessment-dashboard-element.component.html",
})
export class ResilientBusinessAssessmentDashboardElementComponent extends BaseComponent {
    public survey$: Observable<Survey | undefined>;
    public analyseSurveyPageObject?: IAdaptLinkObject | null;
    public analyseAllSurveysPageUrl$?: Observable<string>;

    public constructor(
        private surveyService: SurveyService,
    ) {
        super();

        this.analyseAllSurveysPageUrl$ = SurveyUtils.forSurveyType(SurveyType.ResilientBusinessAssessment).getManageSurveyPageRoute$();

        this.survey$ = this.surveyService.getMostRecentEndedSurveys(1, SurveyType.ResilientBusinessAssessment)
            .pipe(
                map((surveys) => surveys.length ? surveys[0] : undefined),
            );

        this.survey$.pipe(
            switchMap((survey) => {
                return survey ? SurveyUtils.forSurveyType(SurveyType.ResilientBusinessAssessment).getAnalyseSurveyPageRoute$(survey.surveyId)
                    : of(null);
            }),
            this.takeUntilDestroyed(),
        ).subscribe((routerObject) => this.analyseSurveyPageObject = routerObject);
    }
}
