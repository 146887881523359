<adapt-dialog [maxWidth]="650"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Edit start date</h3>
    <ng-container adapt-dialog-content>
        <div class="pb-3">
            <label>Start date</label>
            <dx-date-box type="date"
                         data-test="start-date-box"
                         [useMaskBehavior]="true"
                         [value]="connection.startDate"
                         (valueChange)="dateChanged($any($event))">
                <dx-validator>
                    <dxi-validation-rule [adaptEntity]="connection"
                                         adaptEntityProperty="startDate"></dxi-validation-rule>
                    <dxi-validation-rule type="required"
                                         message="Date is required"></dxi-validation-rule>
                </dx-validator>
            </dx-date-box>
        </div>

        <div *ngIf="dateIsFuture"
             class="alert alert-warning"
             role="alert">
            <b>Note:</b> The start date being in the future means the user will not have access until that date.
        </div>

        <ng-container *ngIf="nonAccessRoleConnectionsChanged as roleConnections">
            <div *ngIf="roleConnections.length > 0"
                 class="alert alert-warning"
                 role="alert">
                <b>Note:</b> The end date of the following role connections will change due to them overlapping with the new start date:
                <ul>
                    <li *ngFor="let roleConnection of roleConnections">{{roleConnection.role.label}}</li>
                </ul>
            </div>
        </ng-container>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
