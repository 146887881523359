import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { StakeholderStory } from "./stakeholder-story";

export class Stakeholder extends BaseEntity<Stakeholder> {
    public stakeholderId!: number;
    public organisationId!: number;
    public name!: string;
    public description?: string;
    public organisation!: Organisation;
    public stakeholderStories!: StakeholderStory[];
}

export const StakeholderBreezeModel = new BreezeModelBuilder("Stakeholder", Stakeholder)
    .withIdField()
    .withSingularName("Customer Role")
    .withPluralName("Customer Roles")
    .hasSource("Stakeholders")
    .withLabelField("name")
    .withSortField("name")
    .isOrganisationEntity()
    .build();
