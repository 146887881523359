import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { IntegratedArchitectureFrameworkAuthService } from "../integrated-architecture-framework-auth.service";
import { GuidanceMaterialsPageComponent } from "./guidance-materials-page.component";

export const guidanceMaterialsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-guidance-materials-page", GuidanceMaterialsPageComponent)
    .atOrganisationUrl("/architecture/guidance-materials")
    .redirectToThisRouteFromOrganisationUrl("/architecture/guidanceMaterials")
    .verifyingFeatures(FeatureName.ArchitectureTier2)
    .verifyingAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2)
    .withTitle("Guidance Materials")
    .reloadOnSearch(false)
    .build();
