<adapt-dialog [maxWidth]="800"
              [minHeight]="400">
    <h3 adapt-dialog-title>Edit organisation map</h3>

    <ng-container adapt-dialog-content>
        <div class="content">
            <div>
                <h3>Select location</h3>
                <adapt-select-key-function-location [location]="location"
                                                    (locationChange)="updateLocationList($event)"
                                                    [showAsList]="true"
                                                    data-test="select-location-list"></adapt-select-key-function-location>
            </div>
            <div *adaptLoading="!locationItems">
                <h3>{{!location?.valueStreamId ? "Teams and k" : "K"}}ey functions within this location</h3>
                <ng-container *ngIf="locationItems!.length; else noEntities">
                    <dx-list [items]="locationItems"
                             [hoverStateEnabled]="false"
                             [activeStateEnabled]="false"
                             [focusStateEnabled]="false"
                             [allowItemDeleting]="true"
                             (onItemRendered)="onItemRendered($event)"
                             (onItemReordered)="reorderItems($event)"
                             (onItemDeleted)="onItemDeleted($event)"
                             height="auto"
                             data-test="reorder-locations-list">
                        <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>

                        <div *dxTemplate="let item of 'item'">
                            <adapt-team-card *ngIf="getAsTeamLocation(item) as teamLocation"
                                             [team]="teamLocation.team"></adapt-team-card>
                            <adapt-key-function-card *ngIf="getAsKeyFunction(item) as kf"
                                                     [keyFunction]="kf"></adapt-key-function-card>
                        </div>
                    </dx-list>
                </ng-container>
                <ng-template #noEntities>
                    <p>No teams or key functions are at this location</p>
                </ng-template>

                <adapt-select-map-entity-for-location *ngIf="location"
                                                      [location]="location"
                                                      (added)="entityAdded($event)"></adapt-select-map-entity-for-location>
            </div>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="noChanges"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
