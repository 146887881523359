import { Component, Input } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkService } from "../../integrated-architecture/integrated-architecture-framework.service";

@Component({
    selector: "adapt-display-role-allocation",
    templateUrl: "./display-role-allocation.component.html",
})
export class DisplayRoleAllocationComponent {
    @Input() public set role(value: Role | undefined) {
        this.role$.next(value);
    }

    @Input() public showLabel = true;
    @Input() public unallocatedLabel = "Unallocated";

    public role$ = new ReplaySubject<Role | undefined>(1);
    public activeNonTeamBasedRoleConnections$: Observable<RoleConnection[]>;
    private roleConnectionsUpdated$ = new BehaviorSubject<void>(undefined);

    public constructor(
        integratedArchitectureService: IntegratedArchitectureFrameworkService,
    ) {
        this.activeNonTeamBasedRoleConnections$ = combineLatest([
            this.role$,
            this.roleConnectionsUpdated$,
        ]).pipe(
            switchMap(([role]) => role
                ? integratedArchitectureService.getRoleConnectionsForRoleId(role.roleId)
                : EMPTY),
            map((roleConnections) => roleConnections
                .filter((rc) => !!rc.connectionId)
                .filter(DirectorySharedService.isNotTeamBasedRoleConnection)
                .filter(ActiveEntityUtilities.isActive)),
        );
    }

    public roleConnectionsUpdated() {
        this.roleConnectionsUpdated$.next();
    }
}
