<adapt-toolbar-title>
    <h2>
        Cultural Index orbit
        <i class="fal fa-fw fa-info-circle"
           adaptTooltip="This page shows various representations of cultural index data for all employees up to a specific date.">
        </i>
    </h2>
</adapt-toolbar-title>

<adapt-filter-content (reset)="filterService.reset()"
                      [isDefault]="filterService.isDefault"
                      [urlParamNames]="CareerValuationFilterParamKeys">
    <adapt-cultural-index-filter></adapt-cultural-index-filter>
</adapt-filter-content>


<div adaptToolbarContent>
    <button *ngIf="hasAtLeastQuantitativePermissions"
            [(adaptButtonToggle)]="showComparison"
            title="Toggle Comparison"
            class="btn btn-blank btn-force-text"
            adaptButtonIcon="fal fa-fw fa-balance-scale"
            data-test="compare-button">Compare</button>
</div>


<div class="row">
    <adapt-ci-orbit [ngClass]="{ 'col-md-6': showComparison, 'col-12': !showComparison }">
    </adapt-ci-orbit>

    <adapt-ci-orbit *ngIf="showComparison"
                    [ngClass]="{ 'col-md-6': showComparison }">
    </adapt-ci-orbit>
</div>
