import { Component, Input } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Api, ApiBreezeModel } from "@common/ADAPT.Common.Model/organisation/api";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DateFormats } from "@common/ux/date-formats";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { breeze } from "breeze-client";
import { ColumnCustomizeTextArg } from "devextreme/ui/data_grid";
import { EMPTY, lastValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { ApiTokenDialogComponent } from "../api-token-dialog/api-token-dialog.component";

@Component({
    selector: "adapt-configure-api",
    templateUrl: "./configure-api.component.html",
})
export class ConfigureApiComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public dateFormat = DateFormats.globalize.shortdatetime;
    public apiEntries: Api[] = [];
    public selectedApiEntries: Api[] = [];

    public newApi?: Api;
    public isCreating = false;

    public featureIsEnabledAndSaved = false;

    public constructor(
        configurationService: ConfigurationService,
        private featuresService: FeaturesService,
        private orgService: OrganisationService,
        private commonDataService: CommonDataService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    public changesAreValid() {
        return this.newApi?.entityAspect.validateEntity() ?? true;
    }

    @Autobind
    public async initialiseData() {
        this.apiEntries = await lastValueFrom(this.commonDataService.getAll(ApiBreezeModel));
        this.featureIsEnabledAndSaved = this.featuresService.checkIfFeatureActiveAndSaved(FeatureName.OrganisationApi);
    }

    @Autobind
    public filterExistingApiPeople(person: Person) {
        return this.apiEntries.find((api) => api.personId === person.personId) === undefined;
    }

    @Autobind
    public addNewApi() {
        return this.commonDataService.create(ApiBreezeModel, {
            organisationId: this.orgService.getOrganisationId(),
            token: breeze.core.getUuid(),
            createdDateTime: new Date(),
        }).pipe(
            tap((newApi) => {
                this.newApi = newApi;
                this.isCreating = true;
            }),
        );
    }

    // called when the config Save button is clicked
    public async onSave() {
        if (this.newApi) {
            await this.saveNewApi();
        }

        await this.initialiseData();
        return super.onSave();
    }

    // called when the config Cancel button is clicked
    public onCancel() {
        this.newApi = undefined;
        this.isCreating = false;
        return super.onCancel();
    }

    public async saveNewApi() {
        // promise because we can't call this using adaptBlockingClick.
        // isCreating = false causes the add button to disappear
        await lastValueFrom(this.commonDataService.saveEntities([this.newApi!]));
        // subscribe here instead of awaiting else the Save/Cancel buttons get stuck as enabled...
        this.dialogService.open(ApiTokenDialogComponent, this.newApi).pipe(
            tap(() => this.newApi = undefined),
            this.takeUntilDestroyed(),
        ).subscribe();
        this.isCreating = false;
        await this.initialiseData();
    }

    @Autobind
    public cancelNewApi() {
        if (this.newApi) {
            return this.commonDataService.remove(this.newApi).pipe(
                tap(() => {
                    this.newApi = undefined;
                    this.isCreating = false;
                }),
            );
        }

        return EMPTY;
    }

    public deleteApiToken(api: Api) {
        const dialogData = {
            title: "Delete API token",
            message: `<p>Are you sure you would like to delete the API token for ${api.person.fullName}?</p>
                <p>You can recreate a token for this person after deletion.</p>`,
        };

        this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            switchMap((dialogResult) => dialogResult ? of(dialogResult) : EMPTY),
            switchMap(() => this.commonDataService.remove(api)),
            switchMap(() => this.commonDataService.saveEntities([api])),
            switchMap(() => this.initialiseData()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public lastUsedDateTimeText(e: ColumnCustomizeTextArg) {
        if (e.value === null) {
            return "Never";
        }
        return e.valueText;
    }
}
