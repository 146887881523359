<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element name="Activity Areas">
            <dx-list [dataSource]="items"
                     [grouped]="true"
                     [collapsibleGroups]="false"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     (onSelectionChanged)="onSelectionChanged($event)"
                     (onContentReady)="onContentReady($event)">
                <div *dxTemplate="let data of 'group'">
                    <div class="list-group">{{data.key}}</div>
                </div>
                <div *dxTemplate="let data of 'item'">
                    <div class="list-item">{{data.name}}</div>
                </div>
            </dx-list>
        </adapt-dashboard-element>
    </div>

    <div right-column>
        <div *ngIf="team; else orgActivityFeed">
            <adapt-dashboard-element name="{{team.name}} team activity"
                                     [url]="teamUrl$ | async">
                <adapt-team-activity-feed [team]="team"
                                          feedTitle="">
                </adapt-team-activity-feed>
            </adapt-dashboard-element>
        </div>
        <ng-template #orgActivityFeed>
            <adapt-dashboard-element name="Organisation activity">
                <adapt-organisation-activity-feed></adapt-organisation-activity-feed>
            </adapt-dashboard-element>
        </ng-template>
    </div>
</adapt-two-column-page>
