import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseComponent } from "@common/ux/base.component/base.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { TeamsService } from "../teams.service";

@Component({
    selector: "adapt-select-common-team",
    templateUrl: "./select-common-team.component.html",
})
export class SelectCommonTeamComponent extends BaseComponent implements OnChanges {
    private static ALL_TEAMS_PLACEHOLDER = { teamId: undefined, name: "All teams" } as unknown as Team;

    @Output() public teamChange = new EventEmitter<Team | null>();
    @Output() public commonTeamsChange = new EventEmitter<Team[]>();

    @Input() public person1!: Person;
    @Input() public person2!: Person;

    @Input() public team: Team | null = SelectCommonTeamComponent.ALL_TEAMS_PLACEHOLDER;
    public dataSource?: DataSource;

    private teamUpdater = this.createThrottledUpdater((team: Team | null) => this.teamChange.emit(team));

    constructor(private teamsService: TeamsService) {
        super();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        const teams = await this.teamsService.promiseToGetIntersectingTeamsForPeopleWithPermissionToEditPcu(this.person1, this.person2);

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: [SelectCommonTeamComponent.ALL_TEAMS_PLACEHOLDER, ...teams],
            }),
            filter: (team: Team) => team === SelectCommonTeamComponent.ALL_TEAMS_PLACEHOLDER || teams?.includes(team),
        });

        if (changes.team) {
            if (!this.team || !teams.find((i) => i.teamId === this.team?.teamId)) {
                // team set to null/undefined or input team is not the intersecting team -> set to ALL TEAMS
                this.team = SelectCommonTeamComponent.ALL_TEAMS_PLACEHOLDER;
            }
        }

        this.commonTeamsChange.emit(teams);
    }


    public onTeamChange(team: Team) {
        this.team = team;
        const value = this.team === SelectCommonTeamComponent.ALL_TEAMS_PLACEHOLDER ? null : this.team;
        this.teamUpdater.next(value);
    }
}
