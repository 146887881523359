import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { TeamCatchupsPageComponent } from "./team-catchups-page.component";

export const teamCatchupsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-team-catchups-page", TeamCatchupsPageComponent)
    .atOrganisationUrl("/team/:teamId/network-health")
    .redirectToThisRouteFromOrganisationUrl("/team/:teamId/networkHealth")
    .withTitle("Network Health")
    .withSearchKeyword("Peer Catch-up")
    .withSearchKeyword("PCU")
    .build();
