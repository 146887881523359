import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { EmployeeDirectoryPageComponent } from "./employee-directory-page.component";

const employeeDirectoryPageTitle = "Employee Directory";

export const employeeDirectoryPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-employee-directory-page", EmployeeDirectoryPageComponent)
    .atOrganisationUrl("/people/directory")
    .withTitle(employeeDirectoryPageTitle)
    .withHelp(StandardIntercomSearchTerm.PersonalProfile)
    .validatesOrganisation()
    .build();
