<div [ngSwitch]="readonlyModeOnSelection && !!role"
     class="select-role-component">
    <adapt-link-role *ngSwitchCase="true"
                     [role]="role"></adapt-link-role>

    <dx-select-box *ngSwitchDefault
                   [showClearButton]="!required"
                   [dataSource]="dataSource"
                   [grouped]="true"
                   [isValid]="!required || !!role"
                   [(value)]="role"
                   (valueChange)="roleChange.emit($event)"
                   [searchEnabled]="true"
                   displayExpr="label">
        <div *dxTemplate="let item of 'item'">
            {{item.label}}
        </div>
    </dx-select-box>
</div>
