import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class CareerValuationCategory extends BaseEntity<CareerValuationCategory> {
    public careerValuationCategoryId!: number;
    public organisationId!: number;
    public name!: string;
    public idealQuestions?: string;
    public actualQuestions?: string;
    public ordinal!: number;

    public organisation!: Organisation;
}

export const CareerValuationCategoryBreezeModel = new BreezeModelBuilder("CareerValuationCategory", CareerValuationCategory)
    .hasSource()
    .withIdField()
    .withPluralName("Career Valuation Categories")
    .isOrganisationEntity()
    .orderBy("ordinal")
    .withSortField("ordinal")
    .build();
