<adapt-dashboard-element name="Objectives & key results"
                         [url]="objectivesHref$ | async"
                         data-test="objectives-dashboard-element">
    <ng-container *adaptLoadingUntilFirstEmit="let objectiveGroups of teamObjectivesGroups$">
        <dx-scroll-view *ngIf="objectiveGroups.length; else noObjectiveTemplate"
                        direction="horizontal"
                        [scrollByContent]="true">
            <adapt-objective-tree-view [objectiveGroups]="objectiveGroups"
                                       [isCompactView]="isCompactView"
                                       [currentTeamId]="(team$ | async)?.teamId"></adapt-objective-tree-view>
        </dx-scroll-view>
    </ng-container>
    <ng-template #noObjectiveTemplate>
        <adapt-call-to-action-inline [featureUrl]="objectivesHref$ | async">
            Objectives and Key Results are about getting the right tasks done.
            It's about setting and communicating the right goals and understanding
            how to achieve them.
        </adapt-call-to-action-inline>
    </ng-template>
</adapt-dashboard-element>
