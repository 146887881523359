<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <label>Name</label>
        <dx-text-box #focus
                     [(value)]="data.processMap.name"
                     (valueChange)="onNameChange($event)"
                     [width]="350"
                     data-test="process-map-name-input">
            <dx-validator>
                <dxi-validation-rule [adaptEntity]="data.processMap"
                                     adaptEntityProperty="name"></dxi-validation-rule>
                <dxi-validation-rule [adaptEntity]="data.systemComponent"
                                     adaptEntityProperty="name"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <label class="mt-3">Purpose</label>
        <!-- ngIf so we don't get errors when cancelling new supp data -->
        <adapt-html-editor *ngIf="data.systemComponent && data.systemComponent.supplementaryData"
                           [(contents)]="data.systemComponent.supplementaryData.description"
                           (isValidChange)="setIsDescriptionValid($event)"></adapt-html-editor>

        <div *ngIf="addMode"
             class="alert alert-info mt-3">
            <p>Note: The task map will be editable after saving.</p>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
