<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon">
                <i class="fa-inverse"
                   [ngStyle]="{background: ZoneMetadata.Colour[zone]}"
                   [ngClass]="ZoneMetadata.Icon[zone]"></i>
            </div>
            <span class="ms-2 me-2">{{ZoneMetadata.Name[zone]}}</span> &gt;
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword" [haystack]="result.name"></adapt-highlighted-text>
            <adapt-display-labels class="ms-2"
                                  [linkDisabled]="true"
                                  [labelLocations]="labelLocations"></adapt-display-labels>
        </div>
    </div>
</adapt-collapsible-search-row>
