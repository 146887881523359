import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { DxTreeViewComponent } from "devextreme-angular";
import { IActivityDateGroup } from "../activity-feed-manager/activity-date-group.interface";

@Component({
    selector: "adapt-activity-feed-date-group",
    templateUrl: `./activity-feed-date-group.component.html`,
    styleUrls: ["./activity-feed-date-group.component.scss"],
    encapsulation: ViewEncapsulation.None, // TODO Remove
})
export class ActivityFeedDateGroupComponent implements OnChanges {
    private static readonly longFormat = "DD MMM YYYY";
    private static readonly shortFormat = "DD MMM";

    private static readonly currentYear = new Date().getFullYear();

    @Input() public dateGroup?: IActivityDateGroup;

    @ViewChild(DxTreeViewComponent, { static: false })
    public treeView?: DxTreeViewComponent;

    public dateFormat = ActivityFeedDateGroupComponent.shortFormat;

    public reloadTreeView() {
        if (!this.treeView) {
            return;
        }

        const dataSource = this.treeView.instance.getDataSource();
        if (!dataSource) {
            return;
        }

        dataSource.reload();
    }

    public ngOnChanges() {
        this.dateFormat = this.dateGroup?.date.getFullYear() === ActivityFeedDateGroupComponent.currentYear
            ? ActivityFeedDateGroupComponent.shortFormat
            : ActivityFeedDateGroupComponent.longFormat;
    }
}
