import { Component, ElementRef, Input, OnChanges, OnInit } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import dxChart, { InitializedEvent, PointClickEvent } from "devextreme/viz/chart";
import { merge } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { ICulturalLeadershipPersonalAnalysisData } from "../../../culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { CareerValuationService } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { ICareerValuationChartTooltipOptions } from "../career-valuation-chart-tooltip-options.interface";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";
import { CareerValuationHistoryDialogComponent } from "../cvt-history-dialog/cvt-history-dialog.component";

interface IChartData {
    careerValuation: CareerValuation;
    argument: Date | number;
    personId?: number;
    score: number;
}

@Component({
    selector: "adapt-cvt-organisation-latest-chart",
    templateUrl: "./analyse-cvt-organisation-latest-chart.component.html",
})
export class CareerValuationOrganisationLatestChartComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;

    public chart?: dxChart;

    public isLoading = true;

    public data: IChartData[] = [];
    public filterParameters?: ICareerValuationAnalysisFilter;
    public showDates = false;

    public constructor(
        protected elementRef: ElementRef,
        private careerValuationService: CareerValuationService,
        public careerValuationUiService: CareerValuationUiService,
        private responsiveService: ResponsiveService,
        private dialogService: AdaptCommonDialogService,
        filterService: CareerValuationFilterService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
            this.updateDataAndChart();
        });

        merge(
            rxjsBreezeService.entityTypeChangedInSave(CareerValuation),
            rxjsBreezeService.entityTypeChangedInSave(CareerValuationCategoryValue),
        ).pipe(
            debounceTime(50),
            switchMap(() => this.updateDataAndChart()),
            this.takeUntilDestroyed(),
        )
            .subscribe();
    }

    public ngOnInit() {
        this.updateDataAndChart();
    }

    public ngOnChanges() {
        this.updateDataAndChart();
    }

    public onInitialized(e: InitializedEvent) {
        this.chart = e.component;

        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    public get height() {
        return this.responsiveService.currentBreakpoint.isMobileSize
            ? 300
            : 600;
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        const tooltipOptions: ICareerValuationChartTooltipOptions = {
            showName: true,
        };

        return {
            html: this.careerValuationUiService.getChartDataTooltip(info.point.tag, tooltipOptions),
        };
    }

    @Autobind
    public async onPointClick(info: PointClickEvent) {
        info.component.hideTooltip();
        const careerValuation: CareerValuation = info.target!.tag;

        if (!careerValuation.person) {
            return;
        }

        this.dialogService.open(CareerValuationHistoryDialogComponent, careerValuation.person).subscribe();
    }

    @Autobind
    private async updateDataAndChart() {
        if (!ChartUtils.isChartDisposed(this.chart)) {
            this.chart?.showLoadingIndicator();
        }

        const filterParameters = { ...this.filterParameters!, date: this.date };
        const data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(filterParameters);
        this.setData(data);
        this.isLoading = false;
    }

    private setData(data: ICulturalLeadershipPersonalAnalysisData[]) {
        this.showDates = !data.some((i: ICulturalLeadershipPersonalAnalysisData) => i.careerValuation && DateUtilities.isOutOfRange(i.careerValuation.creationDate));

        this.data = data
            .filter((e) => this.filterEmpty(e))
            .map((e) => this.createCareerValuationChartObject(e));
    }

    private filterEmpty(analysisData: ICulturalLeadershipPersonalAnalysisData) {
        return !!analysisData.careerValuation;
    }

    private createCareerValuationChartObject(analysisData: ICulturalLeadershipPersonalAnalysisData) {
        const chartObject = {
            careerValuation: analysisData.careerValuation,
            score: analysisData.careerValuation.extensions.actualTotal,
            argument: this.showDates
                ? analysisData.careerValuation.creationDate
                : NumberUtilities.generatePRNG(analysisData.careerValuation.careerValuationId),
            personId: analysisData.careerValuation.personId,
        };

        return chartObject;
    }
}
