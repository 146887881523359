import { Component, Inject, OnInit } from "@angular/core";
import { CulturalIndex, CulturalIndexBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";
import { EditCulturalIndexDialogComponent } from "../edit-ci-dialog/edit-ci-dialog.component";

export interface IDisplayCulturalIndexDialogData {
    culturalIndex: CulturalIndex;
    showName: boolean;
}

@Component({
    selector: "adapt-ci-dialog",
    templateUrl: "./ci-dialog.component.html",
    styleUrls: ["./ci-dialog.component.scss"],
})
export class CulturalIndexDialogComponent extends BaseDialogComponent<CulturalIndex> implements OnInit {
    public readonly dialogName = "CulturalIndexDialog";
    public title: string;

    public hasEditPermission = false;

    public culturalIndex?: CulturalIndex;
    public showName: boolean;

    public getDirectionState = this.culturalIndexService.getDirectionState;


    constructor(
        private culturalIndexService: CulturalIndexService,
        private culturalIndexUiService: CulturalIndexUiService,
        private authorisationService: AuthorisationService,
        private commonDialogService: AdaptCommonDialogService,
        @Inject(ADAPT_DIALOG_DATA) data: IDisplayCulturalIndexDialogData,
    ) {
        super();

        this.culturalIndex = data.culturalIndex;
        this.showName = data.showName;

        this.title = "View " + CulturalIndexBreezeModel.singularName;
        if (this.showName) {
            this.title += " for " + this.culturalIndex.person.fullName;
        }
    }

    public async ngOnInit() {
        this.hasEditPermission = await this.authorisationService.promiseToGetHasAccess(CulturalIndexAuthService.EditCulturalIndexForPerson, this.culturalIndex!.person);
    }

    @Autobind
    public editCulturalIndex() {
        this.commonDialogService.open(EditCulturalIndexDialogComponent, this.culturalIndex).subscribe();
    }

    @Autobind
    public async deleteCulturalIndex() {
        await this.culturalIndexUiService.promiseToDeleteCulturalIndex(this.culturalIndex!, true, true);

        this.resolve(this.culturalIndex!);
    }
}
