import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptRoleSharedModule } from "app/features/architecture/role/role-shared.module";
import { DxDiagramModule } from "devextreme-angular";
import { DiagramComponent } from "./diagram.component/diagram.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptCommonUserModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptDateModule,
        DxDiagramModule,
        AdaptTeamDashboardSharedModule,
        AdaptDirectorySharedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptRoleSharedModule,
        AdaptLoadingSpinnerModule,
        AdaptLinkDisabledModule,
    ],
    declarations: [
        DiagramComponent,
    ],
    exports: [
        DiagramComponent,
    ],
})
export class AdaptDiagramModule {
}
