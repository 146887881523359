<div *adaptLoading="isLoading"
     class="chart-wrapper">
    <dx-polar-chart #careerValuationsDifferentialsChart
                    [hidden]="data.length === 0"
                    title="Differences between ideal and actual values"
                    [dataSource]="data"
                    [pathModified]="true"
                    [palette]="palette"
                    [series]="series"
                    (onPointClick)="onPointClick($event)"
                    (onInitialized)="onInitialized($event)">
        <!-- Options -->
        <dxo-size [height]="height">
        </dxo-size>
        <dxo-argument-axis type="discrete">
            <dxo-minor-tick [visible]="false"></dxo-minor-tick>
            <dxo-tick [visible]="false"></dxo-tick>
            <dxo-minor-grid [visible]="false"></dxo-minor-grid>
            <dxo-grid [visible]="false"></dxo-grid>
            <dxo-label [visible]="false"></dxo-label>
        </dxo-argument-axis>
        <dxo-value-axis [maxValueMargin]="0.001"
                        [tickInterval]="5">
            <dxo-minor-tick [visible]="false"></dxo-minor-tick>
            <dxo-tick [visible]="false"></dxo-tick>
            <dxo-minor-grid [visible]="false"></dxo-minor-grid>
            <dxo-label [visible]="true"></dxo-label>
        </dxo-value-axis>
        <dxo-common-series-settings type="scatter"
                                    argumentField="index"></dxo-common-series-settings>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip"></dxo-tooltip>
        <dxo-margin [bottom]="50"
                    [left]="100"></dxo-margin>
        <dxo-legend [visible]="isLegendVisible"></dxo-legend>
        <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>

        <!-- Series -->
        <dxi-series *ngFor="let category of categories"
                    [name]="category.name"
                    [valueField]="'category' + category.ordinal + 'value'"
                    [tagField]="'category' + category.ordinal"></dxi-series>
    </dx-polar-chart>

    <div *ngIf="data.length === 0">
        <adapt-no-cultural-measurement label="Career Valuations"></adapt-no-cultural-measurement>
    </div>
</div>
