import { Injectable, Injector } from "@angular/core";
import { Product, ProductBreezeModel } from "@common/ADAPT.Common.Model/organisation/product";
import { ValueStreamProduct, ValueStreamProductBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-product";
import { BreezeEntityUtilities } from "@common/lib/data/breeze-entity-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ProductCatalogueService extends BaseOrganisationService {
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getProducts() {
        return this.commonDataService.getAll(ProductBreezeModel);
    }

    public getValueStreamProductsForProduct(product: Product) {
        const key = "valueStreamProductsForProduct" + product.productId;
        const options = {
            predicate: new MethodologyPredicate<ValueStreamProduct>("productId", "==", product.productId),
            navProperty: "valueStream",
        };

        return this.commonDataService.getWithOptions(ValueStreamProductBreezeModel, key, options);
    }

    public createProduct() {
        return this.currentOrganisation$.pipe(
            switchMap((organisation) => this.commonDataService.create(ProductBreezeModel, { organisation })),
        );
    }

    /**
     * Delete the given product and related ValueStreamProducts.
     * @param product Product to delete
     * @return list of entities to save
     */
    public deleteProduct(product: Product) {
        return this.getValueStreamProductsForProduct(product).pipe(
            switchMap((valueStreamProducts) => valueStreamProducts.length > 0
                ? forkJoin(valueStreamProducts.map((vsp) => this.commonDataService.remove(vsp)))
                : of([]),
            ),
            switchMap((valueStreamProducts: ValueStreamProduct[]) => this.commonDataService.remove(product).pipe(
                map(() => [product, ...valueStreamProducts])),
            ),
        );
    }

    public mergeProducts(
        productsToMerge: Product[],
        productToMergeInto: Product,
        affectedValueStreamProducts: ValueStreamProduct[],
    ) {
        return BreezeEntityUtilities.mergeEntities(
            this.commonDataService.remove.bind(this.commonDataService),
            productsToMerge,
            productToMergeInto,
            affectedValueStreamProducts,
            (i) => i.productId,
            (i) => i.valueStreamId,
            (i, value) => i.productId = value,
        );
    }
}
