<ng-container *adaptLoading="!isInitialised">
    <ng-container *ngIf="systems?.length; else showCTA">
        <div class="top-system-buttons d-flex flex-wrap justify-content-center"
             data-test="top-system-buttons">
            <button *ngFor="let system of systems"
                    class="flex-nowrap"
                    adaptButton="pill"
                    [adaptButtonToggle]="selectedSystem === system"
                    (adaptButtonToggleChange)="onToggleChanged($event, system)">
                <div *ngIf="selectedSystem === system; else notSelected"
                     class="d-inline-flex flex-nowrap">
                    <a class="text-white"
                       [routerLink]="getSystemPageRoute$(system) | async"
                       adaptTooltip="Navigate to the system page">{{system.name}}</a>
                    <i class="ms-1 me-n2 fal fa-fw fa-chevron-circle-right navigate-system-icon"
                       adaptTooltip="Navigate to the system page"
                       (click)="gotoSystemPage(system)"></i>
                </div>
                <ng-template #notSelected>
                    <span>{{system.name}}</span>
                </ng-template>
            </button>
            <div class="d-none d-print-block">
                <div *ngFor="let system of systems">
                    <div *ngIf="selectedSystem === system; else notSelected">
                        <h1>System: {{system.name}}</h1>
                    </div>
                    <ng-template #notSelected>
                    </ng-template>
                </div>
            </div>
            <button adaptButton="editBorderless"
                    *adaptIfAuthorised="ConfigureTier2; Entity: team"
                    adaptTooltip="Add/remove/reorder systems"
                    (click)="editSystemLocations()"
                    data-test="modify-systems-button"></button>
        </div>
        <div *ngIf="selectedSystem"
             class="mt-2">
            <adapt-system-content [showTitle]="false"
                                  [system]="selectedSystem"
                                  [displayContext]="keyFunction || team || role"
                                  (initialised)="onSystemContentInitialised()"></adapt-system-content>
        </div>
    </ng-container>
    <ng-template #showCTA>
        <adapt-call-to-action *ngIf="!hideCTA"
                              [learnMoreUrl]="learnMoreUrl"
                              [fullPage]="fullPageCTA">
            <adapt-call-to-action-title [iconClass]="systemsIcon">
                Systemisation
            </adapt-call-to-action-title>

            <p>
                Once your leadership team has understanding and agreement around the
                Key Functions and better role clarity, it's time to document the
                most important processes so those involved in executing can be more
                effective in their work and the business can be consistent in how it does
                things.
            </p>

            <button adaptButtonIcon="add"
                    adaptCallToActionButton
                    *adaptIfAuthorised="ConfigureTier2; Entity: team"
                    class="mt-1"
                    (click)="editSystemLocations()"
                    data-test="modify-systems-button">Add system
            </button>
        </adapt-call-to-action>
    </ng-template>
</ng-container>
