import { Injectable } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DeleteProductDialogComponent } from "app/features/product/product-catalogue/delete-product-dialog/delete-product-dialog.component";
import { EditProductDialogComponent } from "app/features/product/product-catalogue/edit-product-dialog/edit-product-dialog.component";
import { MergeProductsDialogComponent } from "app/features/product/product-catalogue/merge-products-dialog/merge-products-dialog.component";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { ShowProductUsageDialogComponent } from "app/features/product/product-catalogue/show-product-usage-dialog/show-product-usage-dialog.component";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ProductCatalogueUiService {
    constructor(
        private dialogService: AdaptCommonDialogService,
        private productCatalogueService: ProductCatalogueService,
    ) { }

    public createProduct() {
        return this.productCatalogueService.createProduct().pipe(
            switchMap((product) => this.editProduct(product)),
        );
    }

    public editProduct(product: Product) {
        return this.dialogService.open(EditProductDialogComponent, product);
    }

    public deleteProduct(product: Product) {
        return this.dialogService.open(DeleteProductDialogComponent, product);
    }

    public mergeProducts(products: Product[]) {
        return this.dialogService.open(MergeProductsDialogComponent, products);
    }

    public viewProductUsage(product: Product) {
        return this.dialogService.open(ShowProductUsageDialogComponent, product);
    }
}
