import { Component, Input } from "@angular/core";
import { ActiveState } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { EditStartDateDialogComponent } from "@org-common/lib/person-profile/edit-start-date-dialog/edit-start-date-dialog.component";
import { BehaviorSubject, Observable } from "rxjs";
import { map, startWith, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-connection",
    templateUrl: "./person-profile-connection.component.html",
})
export class PersonProfileConnectionComponent extends BaseComponent {
    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;

    public latestConnection$: Observable<Connection | undefined>;

    public person$ = new BehaviorSubject<Person | undefined>(undefined);
    public isPendingActive = false;

    public EditAllPersonalProfiles = DirectoryAuthService.EditAllPersonalProfiles;

    constructor(
        rxjsBreezeService: RxjsBreezeService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();

        const latestConnection$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person: Person) => person.getLatestConnection()),
        );

        this.latestConnection$ = rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            startWith(undefined),
            switchMap(() => latestConnection$),
            tap((connection) => this.isPendingActive = (connection?.activeState === ActiveState.PendingActive)),
        );
    }

    public connectionTypeLabel(connection: Connection) {
        // don't need to show Employee label since it's the default
        if (connection.isEmployeeConnection()) {
            return "";
        }

        return `as an ${ConnectionTypeLabel.singular(connection.connectionType)}`;
    }

    @Autobind
    public editStartDate(connection: Connection) {
        return this.dialogService.open(EditStartDateDialogComponent, connection);
    }
}
