import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ProductCataloguePageComponent } from "app/features/product/product-catalogue/product-catalogue-page/product-catalogue-page.component";

export const productCataloguePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-product-catalogue-page", ProductCataloguePageComponent)
    .atOrganisationUrl("/architecture/product-catalogue")
    .redirectToThisRouteFromOrganisationUrl("/product/productCatalogue")
    .verifyingFeatures(FeatureName.ArchitectureTier1)
    .verifyingAccess(Tier1ArchitectureAuthService.ReadTier1)
    .withTitle("Products & Services")
    .reloadOnSearch(false)
    .build();
