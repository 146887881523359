import { Component, Inject } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { lastValueFrom } from "rxjs";

@Component({
    templateUrl: "./edit-team-locations-dialog.component.html",
})
export class EditTeamLocationsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Team> {
    public readonly dialogName = "EditTeamLocations";
    public autoResolveData = this.team;
    public entitiesToConfirm: IBreezeEntity[];
    public locationEntities: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public team: Team,
    ) {
        super();

        this.entitiesToConfirm = [];
        this.locationEntities = [];
    }

    public async onLocationsPopulated(entities: IBreezeEntity[]) {
        await lastValueFrom(this.commonDataService.rejectChanges(this.locationEntities));
        this.locationEntities = [];
        this.addEntitiesToConfirm(entities);
    }

    public addEntitiesToConfirm(entities: IBreezeEntity[]) {
        for (const entity of entities) {
            this.locationEntities.push(entity);
            ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, entity);
        }
    }
}
