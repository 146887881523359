import { Injector } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { KANBAN_RANK_INCREMENT, KanbanService } from "@org-common/lib/kanban/kanban.service";
import { lastValueFrom } from "rxjs";
import { ProcessMapService } from "../process-map.service";
import { Exporter, StepName } from "./exporter";

export class BoardExporter extends Exporter {
    public steps = [StepName.SelectExportType, StepName.SelectEmbedBoard, StepName.AllocateRoles, StepName.SetPrefix, StepName.ExportConfirmation];

    public canGoNext(currentStep: StepName) {
        if (currentStep === StepName.SelectEmbedBoard) {
            return !!this.board;
        }

        if (currentStep === StepName.AllocateRoles) {
            return !this.loadingData;
        }

        return true;
    }

    public async export(
        injector: Injector,
        processMap: ProcessMap,
    ) {
        const commonDataService = injector.get(CommonDataService);
        const kanbanService = injector.get(KanbanService);
        const processMapService = injector.get(ProcessMapService);
        const roleSteps: ProcessStep[] = [];
        const orderedChildSteps = await lastValueFrom(processMapService.getTopProcessMapSteps(processMap));

        await processMapService.recurseForRoleTasks(
            orderedChildSteps,
            (pm) => processMapService.getTopProcessMapSteps(pm),
            (step) => !roleSteps.includes(step) && roleSteps.push(step),
        );

        // *3 for grabbing supp data, grabbing guidance materials, creating items
        // +1 for extra step when saving all the items
        this.progressTotalCount = (roleSteps.length * 3) + 1;

        // grab as much data as possible at once
        await Promise.all(roleSteps.map(async (step) => {
            await lastValueFrom(processMapService.getProcessStepSupplementaryData(step));
            this.progressCurrentCount++;
            await lastValueFrom(processMapService.getProcessStepGuidanceMaterialsByProcessStepId(step.processStepId));
            this.progressCurrentCount++;
        }));

        const newItems: Item[] = [];

        // get all the board items to calculate the next item rank
        await lastValueFrom(kanbanService.getItemsByBoardId(this.board!.boardId));
        let maxRank = this.board!.items.reduce((acc, val) => Math.max(val.rank, acc), 0);

        // need to do these in order, can't use Promise.all
        for (const roleStep of roleSteps) {
            maxRank += KANBAN_RANK_INCREMENT;

            // when exporting process map, they are meant to be done -> go to To Do rather than Backlog
            const item = await lastValueFrom(kanbanService.createItem(this.board, { status: ItemStatus.ToDo }));
            item.rank = maxRank;
            item.summary = this.prefix ? `${this.prefix} ${roleStep.name}` : roleStep.name;
            item.description = roleStep.supplementaryData?.description as string;
            if (!item.description) {
                item.description = "";
            }

            if (roleStep.supplementaryData?.guidanceQuestions) {
                item.description += "<h3>Guidance Questions</h3>";
                item.description += roleStep.supplementaryData?.guidanceQuestions;
            }

            // this should be instant since we already retrieved it
            const stepGuidanceMaterials = await lastValueFrom(processMapService.getProcessStepGuidanceMaterialsByProcessStepId(roleStep.processStepId));
            if (stepGuidanceMaterials.length) {
                item.description += "<h3>Guidance Materials</h3><ul>";
                for (const stepGuidanceMaterial of stepGuidanceMaterials) {
                    item.description += `<li><a target="_blank" rel="noreferrer noopener" href="${stepGuidanceMaterial.guidanceMaterial?.locationUrl}">${stepGuidanceMaterial.guidanceMaterial?.name}</a></li>`;
                }

                item.description += "</ul>";
            }

            if (roleStep.roleId) {
                item.assignee = this.rolePersonAllocations[roleStep.roleId];
            }

            newItems.push(item);
            this.progressCurrentCount++;

            // this makes sure the progress updates
            await new Promise(requestAnimationFrame);
        }

        if (newItems.length > 0) {
            // promiseToCreateItem will create both item and location -> so need to save location too
            await lastValueFrom(commonDataService.saveEntities(newItems));
        }

        // final tick for save
        this.progressCurrentCount++;
        this.completed = true;
    }
}
