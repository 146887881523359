import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EditOrganisationMapDialogComponent } from "./edit-organisation-map-dialog/edit-organisation-map-dialog.component";

@Injectable({
    providedIn: "root",
})
export class OrganisationMapUiService {

    constructor(
        private dialogService: AdaptCommonDialogService,
    ) { }

    @Autobind
    public editOrganisationMap() {
        return this.dialogService.open(EditOrganisationMapDialogComponent);
    }
}
