import { Component, Input, ViewEncapsulation } from "@angular/core";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-system-external-link",
    templateUrl: "./system-external-link.component.html",
    styleUrls: ["./system-external-link.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SystemExternalLinkComponent {
    @Input() public component?: SystemComponent;

    constructor(private systemisationService: SystemisationService) { }

    public get documentHeight() {
        return this.systemisationService.getComponentSize(this.component!);
    }
}
