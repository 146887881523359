<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>{{title}}</h3>
    <div adapt-dialog-content>
        <adapt-catchup-history-chart [person1]="data.person1"
                                     [person2]="data.person2"
                                     [authorPerson]="data.person1"
                                     [hideDates]="true"></adapt-catchup-history-chart>
        <adapt-catchup-history-chart [person1]="data.person1"
                                     [person2]="data.person2"
                                     [authorPerson]="data.person2"></adapt-catchup-history-chart>
    </div>
    <ng-container adapt-dialog-footer>
        <div adaptBlockingButtonGroup
             class="ms-auto">
            <button adaptButtonIcon="default"
                    class="btn btn-primary"
                    (click)="cancel()"
                    data-test="standard-dialog-cancel-button">Close</button>
        </div>
    </ng-container>
</adapt-dialog>
