import { Component, Input } from "@angular/core";
import { SpeedCatchupConfiguration } from "@common/ADAPT.Common.Model/organisation/speed-catchup-configuration";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { KeyOfType } from "@common/lib/utilities/type-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { PeerCatchupService } from "app/features/people/peer-catchup/peer-catchup.service";
import { ContentReadyEvent } from "devextreme/ui/list";
import { lastValueFrom } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";

interface IPeerCatchUpCategory {
    name: string;
    field: KeyOfType<SpeedCatchupConfiguration, string | undefined>;
    articleId: ImplementationKitArticle;
    isEditorValid: boolean,
}

@Component({
    selector: "adapt-configure-peer-catch-up",
    templateUrl: "./configure-peer-catch-up.component.html",
})
export class ConfigurePeerCatchUpComponent extends ConfigureFeatureBase {
    public readonly pcuCategories: IPeerCatchUpCategory[] =  [
        { name: "Overall", field: "guidanceOverall", articleId: ImplementationKitArticle.PeerCatchUpOverall, isEditorValid: true },
        { name: "Engagement", field: "guidanceEngagement", articleId: ImplementationKitArticle.PeerCatchUpEngagement, isEditorValid: true },
        { name: "Connection", field: "guidanceConnection", articleId: ImplementationKitArticle.PeerCatchUpConnection, isEditorValid: true },
        { name: "Contribution", field: "guidanceContribution", articleId: ImplementationKitArticle.PeerCatchUpContribution, isEditorValid: true },
    ];

    @Input() public configItem?: IConfigItem;

    public configuration?: SpeedCatchupConfiguration;
    public selectedCategory = this.pcuCategories[0];

    public restoringGuidance = false;
    public isEditorValid = true;

    public constructor(
        configurationService: ConfigurationService,
        private peerCatchUpService: PeerCatchupService,
        private implementationKitService: ImplementationKitService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.configuration = await lastValueFrom(this.peerCatchUpService.getCatchupConfiguration());
    }

    @Autobind
    public restoreDefaultGuidance(category: IPeerCatchUpCategory) {
        this.restoringGuidance = true;
        return this.implementationKitService.getArticle(category.articleId).pipe(
            tap((article) => this.configuration![category.field] = article.answer),
            catchError((err) => this.dialogService.showErrorDialog("Failed to restore default guidance", ErrorHandlingUtilities.getHttpResponseMessage(err))),
            finalize(() => this.restoringGuidance = false),
        );
    }

    @Autobind
    public changesAreValid() {
        super.changesAreValid();
        return this.isEditorValid;
    }

    public onContentReady(e: ContentReadyEvent) {
        e.component!.selectItem(this.selectedCategory);
    }

    public async onSelectionChanged(e: IDxListSelectionChangedEvent<IPeerCatchUpCategory>) {
        if (e.addedItems.length > 0) {
            this.selectedCategory = e.addedItems[0];
        }
    }

    public contentChanged(field: KeyOfType<SpeedCatchupConfiguration, string | undefined>, value: string) {
        this.configuration![field] = value;
    }
}
