<div adaptToolbarContent
     alignment="center">
    <adapt-button-toggle-group [(value)]="viewSelection">
        <button [adaptToggleGroupButton]="CatchupView.Grid"
                adaptButtonIcon="fal fa-fw fa-table"
                data-test="show-catch-up-grid">Grid view</button>
        <button [adaptToggleGroupButton]="CatchupView.Graph"
                adaptButtonIcon="fal fa-fw fa-users">Graph view</button>
    </adapt-button-toggle-group>
</div>

<ng-container *ngIf="person && canEditPerson">
    <button adaptToolbarContent
            alignment="right"
            adaptButtonIcon="add"
            [adaptBlockingClick]="recordPersonCatchup"
            data-test="new-catch-up-button">New catch-up</button>
</ng-container>
<ng-container *ngIf="team && canEditMyCatchup">
    <button adaptToolbarContent
            alignment="right"
            adaptButtonIcon="add"
            [adaptBlockingClick]="recordTeamCatchup"
            data-test="new-catch-up-button">New catch-up</button>
</ng-container>

<adapt-catchup-views [focusPerson]="person"
                     [teamId]="team?.teamId"
                     [viewSelection]="viewSelection"></adapt-catchup-views>
