import { Component, Injector, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PersonImageComponent } from "@common/user/person-image/person-image.component";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { myKanbanPageRoute, personalKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { PersonService } from "@org-common/lib/person/person.service";
import { ChangePasswordDialogComponent } from "@org-common/lib/person-profile/change-password-dialog/change-password-dialog.component";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { careerValuationDashboardPageRoute, myCareerValuationDashboardPageRoute } from "app/features/people/career-valuation/cvt-dashboard-page/cvt-dashboard-page.route";
import { culturalIndexDashboardRoute, myCulturalIndexDashboardRoute } from "app/features/people/cultural-index/ci-dashboard-page/ci-dashboard-page.route";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { PeerCatchupAuthService } from "app/features/people/peer-catchup/peer-catchup-auth.service";
import { PeerCatchupUiService } from "app/features/people/peer-catchup/peer-catchup-ui.service";
import { combineLatest, merge, Observable, ReplaySubject } from "rxjs";
import { catchError, debounceTime, filter, map, startWith, switchMap, take, tap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-page",
    styleUrls: ["./person-profile-page.component.scss"],
    templateUrl: "./person-profile-page.component.html",
})
export class PersonProfilePageComponent extends BaseRoutedComponent implements OnInit {

    public personId$ = new ReplaySubject<number | undefined>(1);
    public person$: Observable<Person | undefined>;
    public isEditing = false;
    public EditProfileForPerson = "editProfileForPerson";
    public ManagePositionAndRoles = DirectoryAuthService.ManagePositionAndRoles;
    public ReadCareerValuationForPerson = CareerValuationAuthService.ReadCareerValuationForPerson;
    public ReadCulturalIndexForPerson = CulturalIndexAuthService.ReadCulturalIndexForPerson;
    public ReadPeerCatchUpForPerson = PeerCatchupAuthService.ReadPeerCatchupsForPerson;
    public ViewBoardForPerson = KanbanAuthService.ViewBoardForPerson;
    public ChangePasswordForPerson = "changePasswordForPerson";
    public ReadTier2 = IntegratedArchitectureFrameworkAuthService.ReadTier2;
    public ReadCulturalLeadership = CulturalLeadershipFrameworkAuthService.ReadCohorts;

    public cvtDashboardUrl$: Observable<string>;
    public ciDashboardUrl$: Observable<string>;
    public pcuDashboardUrl$: Observable<string>;
    public kanbanDashboardUrl$: Observable<string>;

    public latestConnection$: Observable<Connection | undefined>;
    public canReadLinks$: Observable<boolean>;
    public canEditSomethingOnPage$: Observable<boolean>;

    public defaultImageSrc = PersonImageComponent.DefaultProfileImageUrl;

    constructor(
        injector: Injector,
        authService: AuthorisationService,
        ciAuthService: CulturalIndexAuthService,
        cvAuthService: CareerValuationAuthService,
        peerCatchUpUiService: PeerCatchupUiService,
        personService: PersonService,
        rxjsBreezeService: RxjsBreezeService,
        userService: UserService,
        private commonDataService: CommonDataService,
        private commonDialogService: AdaptCommonDialogService,
    ) {
        super(injector);

        this.person$ = this.personId$.pipe(
            switchMap((personId) => personId ? personService.getPerson(personId)
                : userService.currentPerson$),
            tap((person) => {
                if (!person) {
                    this.routeService.gotoHome();
                } else if (person.isStakeholderManager && userService.getCurrentPersonId() !== person.personId) {
                    ErrorPageRoute.gotoRoute().subscribe();
                }
            }),
        );

        this.person$.pipe(
            filter((person) => !!person),
            switchMap((person) => authService.getHasAccess("readProfileForPerson", person)),
            this.takeUntilDestroyed(),
        ).subscribe((canReadProfile) => {
            if (!canReadProfile) {
                ErrorPageRoute.gotoRoute().subscribe();
            }
        });

        const latestConnection$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person: Person) => person.getLatestConnection()),
        );

        this.latestConnection$ = merge(
            rxjsBreezeService.entityTypeChanged(RoleConnection),
            rxjsBreezeService.entityTypeChanged(Connection),
        ).pipe(
            debounceTime(100),
            startWith(undefined),
            switchMap(() => latestConnection$),
            this.takeUntilDestroyed(),
        );

        this.canEditSomethingOnPage$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((person) => {
                return authService.getHasAccess(this.EditProfileForPerson, person)
                    || authService.getHasAccess(this.ManagePositionAndRoles, person);
            }),
        );

        // The last lastConnection$ is just to trigger update when RoleConnection/Connection changes
        this.canReadLinks$ = combineLatest([this.person$, userService.currentPerson$, this.latestConnection$]).pipe(
            filter(([person, currentPerson]) => !!person && !!currentPerson),
            switchMap(([person, currentPerson]) => combineLatest([
                cvAuthService.personCanReadForPerson(currentPerson!, person!),
                ciAuthService.personCanReadForPerson(currentPerson!, person!),
                authService.getHasAccess(this.ReadPeerCatchUpForPerson, person),
                authService.getHasAccess(this.ViewBoardForPerson, person),
            ])),
            map((results) => results.includes(true)),
            catchError(() => ErrorPageRoute.gotoRoute().pipe(map(() => false))),
        );

        this.cvtDashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => person
                ? careerValuationDashboardPageRoute.getRoute({ personId: person.personId })
                : myCareerValuationDashboardPageRoute.getRoute()),
        );

        this.ciDashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => person
                ? culturalIndexDashboardRoute.getRoute({ personId: person.personId })
                : myCulturalIndexDashboardRoute.getRoute()),
        );

        this.pcuDashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => peerCatchUpUiService.getDashboardUrl(person)),
        );

        this.kanbanDashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => person
                ? personalKanbanPageRoute.getRoute({ personId: person.personId })
                : myKanbanPageRoute.getRoute()),
        );
    }

    public async ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined), // to get initial update when component first loaded
        ).subscribe(() => this.updateDataFromParam());
    }

    private updateDataFromParam() {
        const personId = this.getRouteParamInt("personId");
        this.personId$.next(personId);

        this.notifyActivated();
    }

    public setEditMode() {
        this.isEditing = true;
    }

    public setViewMode() {
        this.isEditing = false;
    }

    public editName() {
        this.person$.pipe(
            emptyIfUndefinedOrNull(),
            take(1),
            switchMap((person) => {
                const dlg: IEditSimpleValueBreezeEntityDialogData = {
                    title: "Edit Name",
                    entities: [{
                        label: "First Name",
                        entity: person,
                        fieldName: "firstName",
                        type: SimpleValueType.Text,
                    }, {
                        label: "Surname",
                        entity: person,
                        fieldName: "lastName",
                        type: SimpleValueType.Text,
                    }],
                    saveOnClose: true,
                };

                return this.commonDialogService.open(EditSimpleValueBreezeEntityDialogComponent, dlg);
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public updateImageIdentifier(person: Person, imageIdentifier: string) {
        person.imageIdentifier = imageIdentifier;
        this.commonDataService.saveEntities(person).pipe(
            catchError((e: any) => this.commonDataService.rejectChanges(person).pipe(
                switchMap(() => this.commonDialogService.showErrorDialog("Failed To Save", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            )),
        ).subscribe();
    }

    public changePassword(person: Person) {
        this.commonDialogService.open(ChangePasswordDialogComponent, person).subscribe();
    }

    public triggerConnectionUpdate(connection: Connection) {
        this.personId$.next(connection.personId);
    }
}
