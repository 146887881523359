import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { BehaviorSubject, filter } from "rxjs";
import { TeamsUiService } from "../teams-ui.service";

@Component({
    selector: "adapt-team-location-dashboard-element",
    templateUrl: "./team-location-dashboard-element.component.html",
    styleUrls: ["./team-location-dashboard-element.component.scss"],
})
export class TeamLocationDashboardElementComponent extends BaseComponent {
    @Input()
    public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public canEditTeam = false;

    public constructor(
        private teamsUiService: TeamsUiService,
        teamsAuthService: CommonTeamsAuthService,
    ) {
        super();

        this.team$.pipe(
            filter((team) => !!team),
            this.takeUntilDestroyed(),
        ).subscribe((team) => this.canEditTeam = teamsAuthService.currentPersonCanEditTeam(team!));
    }

    public editLocation(team?: Team) {
        if (team) {
            return this.teamsUiService.editTeamLocations(team).subscribe();
        }
    }
}
