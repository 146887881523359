import { Component, Input, OnChanges } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingValue } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IDxChartCustomizePoint, IDxChartTooltipPointInfo, IDxPieChartCustomizeLegendText } from "@common/ux/dx.types";

interface ICatchupRating {
    name: string;
    label: string;
    colour: string;
    count: number;
}

// these are previously from speed-catchup-rating.ts but those values are only used here
const RatingValueDisplay: {
    [key in CatchupRatingValue]: { label: string, colour: string };
} = {
    Green: {
        label: "All Good",
        colour: "#99b456",
    },
    Orange: {
        label: "Minor Issues",
        colour: "#ef8b2c",
    },
    Red: {
        label: "Major Issues",
        colour: "#c02e1d",
    },
};

@Component({
    selector: "adapt-catchup-ratings-graph",
    templateUrl: "./catchup-ratings-graph.component.html",
})
export class CatchupRatingsGraphComponent implements OnChanges {
    @Input() public catchups?: SpeedCatchup[];
    @Input() public totalRelationshipCount!: number;

    public catchupRatings: ICatchupRating[] = [];

    public ngOnChanges() {
        if (this.catchups?.length) {
            this.catchupRatings = [];
            const ratingCounts: { [key in CatchupRatingValue]: number } = {
                Green: 0,
                Orange: 0,
                Red: 0,
            };
            this.catchups.forEach((catchup) => ratingCounts[catchup.extensions.worstRatingValue]++);

            this.catchupRatings = Object.keys(ratingCounts)
                .map((ratingValue: CatchupRatingValue) => {
                    const ratingValueDisplay = RatingValueDisplay[ratingValue];
                    return {
                        name: ratingValue,
                        label: ratingValueDisplay.label,
                        colour: ratingValueDisplay.colour,
                        count: ratingCounts[ratingValue],
                    };
                });

            if (this.totalRelationshipCount) {
                const relationshipsWithoutCatchups = this.totalRelationshipCount - this.catchups.length;
                if (relationshipsWithoutCatchups > 0) {
                    this.catchupRatings.push({
                        name: "No catchup",
                        label: "No catchup",
                        colour: "grey",
                        count: relationshipsWithoutCatchups,
                    });
                }
            }
        } else {
            this.catchupRatings = [];
        }
    }

    public customizeTooltip(e: IDxChartTooltipPointInfo) {
        return {
            text: `${e.argumentText} (${e.value})`,
        };
    }

    @Autobind
    public customizeRatingsGraphPoint(e: IDxChartCustomizePoint) {
        // on rare occasions this function gets called before catchupRatings has been initialised, so protect it
        if (!this.catchupRatings || e.index < 0 || e.index >= this.catchupRatings.length) {
            return {};
        }

        return {
            color: this.catchupRatings[e.index].colour,
        };
    }

    @Autobind
    public customizeRatingsGraphLegend(e: IDxPieChartCustomizeLegendText) {
        // on rare occasions this function gets called before catchupRatings has been initialised, so protect it
        if (!this.catchupRatings || e.pointIndex === undefined || e.pointIndex < 0 || e.pointIndex >= this.catchupRatings.length) {
            return `${e.pointName}`;
        }

        return `${e.pointName} (${this.catchupRatings[e.pointIndex].count})`;
    }
}
