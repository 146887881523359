import { Injectable } from "@angular/core";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { TeamTabId } from "@org-common/lib/shell/team-tab-content/team-tab-id";
import { OrgCommonNavigationTab } from "@org-common/lib/sidebar/org-common-navigation-tab";
import { TeamHierarchyNavigationService } from "app/platform/navigation/team-hierarchy-navigation.service";
import { TeamTabContentComponent } from "./team-tab-content.component";


@Injectable()
export class TeamTab extends OrgCommonNavigationTab {
    public id = TeamTabId;
    public label = "Teams";
    public ordinal = 1;

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: TeamHierarchyNavigationService.IconClass,
            iconText: "Team",
        },
    };

    public content: IComponentRender<TeamTabContentComponent> = {
        component: TeamTabContentComponent,
    };

    protected getHierarchyId() {
        return TeamHierarchyNavigationService.HierarchyId;
    }
}
