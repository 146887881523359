import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptSignalRModule } from "@common/lib/signalr-provider/signalr.module";
import { SignalRService } from "@common/lib/signalr-provider/signalr.service";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxTreeViewModule } from "devextreme-angular";
import { ActivityFeedComponent } from "./activity-feed/activity-feed.component";
import { ActivityFeedDateGroupComponent } from "./activity-feed-date-group/activity-feed-date-group.component";
import { ActivityFeedSignalRHub } from "./activity-feed-signalr-hub";
import { ActivityInstanceComponent } from "./activity-instance/activity-instance.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptDateModule,
        AdaptTooltipModule,
        AdaptCommonUserModule,
        AdaptLoadingSpinnerModule,
        DxTreeViewModule,
        AdaptSignalRModule,
    ],
    exports: [
        ActivityFeedComponent,
    ],
    declarations: [
        ActivityInstanceComponent,
        ActivityFeedDateGroupComponent,
        ActivityFeedComponent,
    ],
})
export class AdaptActivityFeedModule {
    constructor() {
        SignalRService.registerHubImplementation(ActivityFeedSignalRHub.Name, ActivityFeedSignalRHub);
    }
}
