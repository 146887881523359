import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Stakeholder } from "./stakeholder";
import { ValueStream } from "./value-stream";
import { ValueStreamStakeholderStory } from "./value-stream-stakeholder-story";

export class ValueStreamStakeholder extends BaseEntity<ValueStreamStakeholder> {
    public valueStreamStakeholderId!: number;
    public valueStreamId!: number;
    public stakeholderId!: number;
    public ordinal!: number;
    public stakeholder!: Stakeholder;
    public stakeholderStories!: ValueStreamStakeholderStory[];
    public valueStream!: ValueStream;
}

export const ValueStreamStakeholderBreezeModel = new BreezeModelBuilder("ValueStreamStakeholder", ValueStreamStakeholder)
    .hasSource()
    .isOrganisationEntity()
    .withSingularName("Value Stream Customer Role")
    .withPluralNameAndNoSourceOverride("Value Stream Customer Roles")
    .withSortField("ordinal")
    .alwaysFetchingNavigationProperty("stakeholder")
    .alwaysFetchingNestedNavigationProperty("stakeholderStories.stakeholderStory")
    .build();
