<div class="intro">
    <p>
        You may optionally enter a prefix for the exported actions, where it will appear prefixed to each action summary.
    </p>
    <p>
        One potential use for a prefix is the situation where you are exporting induction processes for multiple different people.
        The prefix could be used to identify the person for each step, such as "<b>John Smith:</b> Perform Cultural Assessment".
    </p>
</div>

<div class="d-flex mt-3 row">
    <div class="align-self-center col-2">Action Prefix</div>
    <div class="col-4">
        <dx-text-box class="align-self-center"
                     [(value)]="prefix"
                     (onValueChanged)="onPrefixChanged($event.value)"
                     [showClearButton]="true"
                     placeholder="An optional action prefix..."
                     data-test="process-map-export-prefix"></dx-text-box>
    </div>
    <div *ngIf="processSteps.length > 0 && prefix"
         class="col-auto align-self-center">
        Example: <b>{{prefix}} </b>{{processSteps[0].name}}
    </div>
</div>
