<ng-container *ngIf="groupKey"
              [ngSwitch]="groupType">
    <adapt-link-key-function *ngSwitchCase="GroupType.KeyFunctionGroup"
                             [keyFunction]="$any(groupKey)"></adapt-link-key-function>
    <adapt-link-team *ngSwitchCase="GroupType.TeamGroup"
                     [team]="$any(groupKey)"
                     [showIcon]="true"></adapt-link-team>
    <adapt-link-role *ngSwitchCase="GroupType.RoleGroup"
                     [role]="$any(groupKey)"
                     [showIcon]="true"></adapt-link-role>
    <adapt-zone-label *ngSwitchCase="GroupType.ZoneGroup"
                      [zone]="$any(groupKey)"></adapt-zone-label>
    <span *ngSwitchDefault>Unknown Type</span>
</ng-container>
