<ng-container *adaptLoadingUntilFirstEmit="let locations of locations$">
    <div *adaptLoadingUntilFirstEmit="let zones of zones$"
         class="mb-3">
        <div *ngFor="let zone of zones"
             class="mb-1">
            <adapt-zone-label [zone]="zone"></adapt-zone-label>
        </div>
    </div>
    <div *adaptLoadingUntilFirstEmit="let keyFunctions of keyFunctions$">
        <div *ngFor="let kf of keyFunctions"
             class="mb-1">
            <adapt-link-key-function [keyFunction]="kf"></adapt-link-key-function>
        </div>
    </div>
    <div *ngIf="locations.length === 0">
        This team (or any of its parent teams) has not been placed into a Zone or Key Function.
    </div>
</ng-container>
