import { Component } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { PersonNavigationService } from "app/platform/navigation/person-navigation.service";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-personal-tab-content",
    templateUrl: "./personal-tab-content.component.html",
})
export class PersonalTabContentComponent {
    public personalHierarchy$: Observable<INavigationNode | undefined>;

    public constructor(
        navHierarchyFactory: NavigationHierarchyService,
    ) {
        this.personalHierarchy$ = navHierarchyFactory.hierarchyChanged(PersonNavigationService.HierarchyId);
    }
}
