import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-allocate-tier1-role",
    templateUrl: "./allocate-tier1-role.component.html",
})
export class AllocateTier1RoleComponent implements OnChanges {
    @Input() public role?: Role;
    @Output() public populated = new EventEmitter<RoleConnection>();
    @Output() public added = new EventEmitter<RoleConnection>();

    public allocatedConnection?: Connection;
    public existingAllocation?: RoleConnection;
    public newAllocation?: RoleConnection;
    public modifiedEntities: IBreezeEntity[] = [];
    public allowedUserTypes = [UserType.Leader]; // Steve said do not allow coaches - only Leader!

    public constructor(
        private commonDataService: CommonDataService,
        private authService: AuthorisationService,
    ) { }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.role) {
            if (this.newAllocation) {
                await lastValueFrom(this.commonDataService.remove(this.newAllocation));
            }

            this.existingAllocation = undefined;

            if (this.role) {
                // Assume Tier1 roles can only have a single person
                const active = this.role.roleConnections.filter((i) => i.isActive());
                this.existingAllocation = active[0];

                if (this.existingAllocation) {
                    this.modifiedEntities.push(this.existingAllocation);
                    this.populated.emit(this.existingAllocation);
                    this.allocatedConnection = this.existingAllocation.connection;
                }
            }
        }
    }

    @Autobind
    public hasTier1Edit(person: Person) {
        return this.authService.personHasPermission(person, FeaturePermissionName.ArchitectureTier1Edit);
    }

    public async updateAllocationPerson(person?: Person) {
        const newConnection = person?.getLatestConnection();

        if (!this.role) {
            return;
        }

        if (this.newAllocation) {
            await lastValueFrom(this.commonDataService.remove(this.newAllocation));
            this.newAllocation = undefined;
        }

        if (this.existingAllocation && newConnection === this.existingAllocation.connection) {
            this.existingAllocation.endDate = undefined;
            return;
        }

        if (newConnection) {
            this.newAllocation = await lastValueFrom(new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService).createRoleConnection({
                role: this.role,
                connection: newConnection,
            }));

            this.modifiedEntities.push(this.newAllocation!);
            this.added.emit(this.newAllocation);
        }

        if (this.existingAllocation) {
            this.existingAllocation.endDate = this.newAllocation?.startDate ?? new Date();
        }
    }
}
