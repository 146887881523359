<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Add team</h3>

    <div adapt-dialog-content>
        <!-- name -->
        <div class="mb-3">
            <label for="teamNameLabel">Team name</label>
            <dx-text-box id="teamNameLabel"
                         [adaptValidateEntity]="team"
                         adaptValidateEntityProperty="name"
                         [(value)]="team.name"
                         data-test="team-name-input"></dx-text-box>
        </div>

        <!-- leader -->
        <div class="mb-3">
            <label for="inputTeamLeader">{{teamLeaderLabel}}</label>
            <adapt-select-person id="inputTeamLeader"
                                 [required]="true"
                                 [disabled]="isPrivateNonStakeholderManager"
                                 [userTypes]="allowedUserTypes"
                                 [(person)]="team.teamLeaderPerson"></adapt-select-person>
            <div class="mt-2 alert alert-info"
                 *ngIf="isPrivateNonStakeholderManager">
                Only team members of a private team and coaches will have access to the team. When creating a private team, the person
                creating the team is automatically set to the team leader.
            </div>
        </div>

        <div>
            <label>Locations</label>
            <p class="mb-1">Select the locations that this team should reside within.</p>
            <div class="border-3 border-secondary border-start ps-2">
                <adapt-edit-team-locations [team]="team"
                                           (populated)="addEntitiesToConfirm($event)"
                                           (added)="entitiesToConfirm.push($event)"></adapt-edit-team-locations>

            </div>
        </div>

        <div>
            <label>Private Team</label>
            <p class="mb-1">A private team restricts access to only those who are members of the team and coaches.<br />
                This includes restricting access to organisation leaders if they are not part of the team.</p>
            <dx-check-box class="mt-2"
                          (valueChange)="onPrivateTeamChanged($any($event))"
                          [disabled]="!team.teamLeaderPerson"
                          text="Set as private team"></dx-check-box>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="add"
                                   saveText="Create team"
                                   [saveIsDisabled]="entitiesAreUnmodifiedOrInvalid"
                                   [saveBlockingClick]="saveAndClose"
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
