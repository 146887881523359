<dx-accordion *adaptLoadingUntilFirstEmit="let relatedSystems of relatedSystems$"
              [items]="relatedSystems"
              [collapsible]="true"
              [multiple]="true"
              [selectedIndex]="-1">
    <div *dxTemplate="let system of 'title'"
         class="d-flex align-items-center">
        <!-- Need this additional div to align the content of the title to the accordion chevron from dx -->
        <div class="d-flex align-items-center justify-content-start flex-wrap system-title">
            <adapt-link-system [system]="system"></adapt-link-system>
        </div>
    </div>
    <div *dxTemplate="let system of 'item'">
        <ng-container *ngFor="let processMap of getProcessMapsForSystem(system)">
            <adapt-link-process-map [processMap]="processMap"></adapt-link-process-map>
            <div *adaptLoadingUntilFirstEmit="let steps of assignedProcessStepsInMap(processMap)"
                 class="related-process-steps">
                <adapt-link-process-step *ngFor="let step of steps"
                                         [processStep]="step"
                                         [alignTextToRightOfIcon]="true"></adapt-link-process-step>
            </div>
        </ng-container>
    </div>
</dx-accordion>
