<adapt-dashboard-element name="Bullseye"
                         [url]="url$ | async">
    <div class="d-flex flex-column flex-lg-row flex-wrap"
         *ngIf="bullseye?.extensions?.areQuadrantsCompleted()">
        <div class="d-flex flex-column left-column mt-3 me-3">
            <adapt-bullseye-view class="bullseye-container"
                                 [showZoom]="true"></adapt-bullseye-view>
        </div>
        <div class="d-flex flex-column right-column">
            <adapt-collapsible-dashboard-element *ngIf="bullseye!.statement"
                                                 class="mt-4 bullseye-statement"
                                                 name="Bullseye statement"
                                                 [isCollapsible]="false">
                <div [froalaView]="bullseye!.statement"></div>
            </adapt-collapsible-dashboard-element>
        </div>
    </div>
</adapt-dashboard-element>
