<adapt-dashboard-element name="Systems">
    <ng-container *adaptLoading="!systems">
        <ng-container *ngIf="systems && systems.size > 0; else noSystems">
            <adapt-link-system *ngFor="let systemId of systems"
                               [systemEntityId]="systemId"
                               [accessoryText]="accessoryText(systemId)"
                               [showIcon]="false">
            </adapt-link-system>
        </ng-container>
        <ng-template #noSystems>
            No assigned Systems to display
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
