import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { DxAccordionModule, DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptIntegratedArchitectureModule } from "../integrated-architecture/integrated-architecture.module";
import { AdaptRoleModule } from "../role/role.module";
import { AdaptValueStreamsModule } from "../value-streams/value-streams.module";
import { EditValueStreamProductsDialogComponent } from "./edit-value-stream-products-dialog/edit-value-stream-products-dialog.component";
import { EditValueStreamStakeholdersDialogComponent } from "./edit-value-stream-stakeholders-dialog/edit-value-stream-stakeholders-dialog.component";
import { ValueStreamBusinessModelPageComponent } from "./value-stream-business-model-page/value-stream-business-model-page.component";
import { valueStreamBusinessModelPageRoute } from "./value-stream-business-model-page/value-stream-business-model-page.route";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptLoadingSpinnerModule,
        AdaptTooltipModule,
        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptEntityValidatorModule,
        AdaptTeamsModule,
        AdaptIntegratedArchitectureModule,
        AdaptRoleModule,
        DxAccordionModule,
        DxTextBoxModule,
        DxListModule,
        AdaptValueStreamsModule,
        DxSelectBoxModule,
        AdaptOrganisationSharedModule,
        AdaptAuthorisationModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
    ],
    declarations: [
        EditValueStreamProductsDialogComponent,
        EditValueStreamStakeholdersDialogComponent,
        ValueStreamBusinessModelPageComponent,
    ],
    exports: [],
})
export class AdaptTier1ArchitectureModule {
    public static readonly Routes = [
        ...valueStreamBusinessModelPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        Tier1ArchitectureAuthService.registerAccessVerifiers(authorisationService);
    }
}
