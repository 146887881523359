import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class SpeedCatchupConfiguration extends BaseEntity<SpeedCatchupConfiguration> {
    public speedCatchupConfigurationId!: number;
    public organisationId!: number;
    public catchupFrequency!: number;
    public guidanceConnection?: string;
    public guidanceContribution?: string;
    public guidanceEngagement?: string;
    public guidanceOverall?: string;
    public organisation!: Organisation;
}

export const SpeedCatchupConfigurationBreezeModel = new BreezeModelBuilder("SpeedCatchupConfiguration", SpeedCatchupConfiguration)
    .withSingularName("Peer Catch-up Configuration")
    .withPluralName("Peer Catch-up Configurations")
    .isOrganisationEntity()
    .hasSource("SpeedCatchupConfigurations")
    .build();
