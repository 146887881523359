import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import isEqual from "lodash.isequal";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";

@Component({
    selector: "adapt-analyse-career-valuation-differentials-page",
    templateUrl: "./analyse-cvt-differentials-page.component.html",
})
export class AnalyseCareerValuationDifferentialsPageComponent extends BaseRoutedComponent implements OnInit {
    private defaultFilter = CareerValuationAnalysisFilter.getDefaultDifferentialFilterParameters();

    public readonly CareerValuationFilterParamKeys = Object.values(CulturalDataFilterParamKeys);
    public constructor(
        private filterService: CareerValuationFilterService,
        injector: Injector,
    ) {
        super(injector);

        this.resetFilter();
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public get isDefault() {
        return isEqual(this.filterService.filter, this.defaultFilter);
    }

    public resetFilter() {
        this.filterService.reset(this.defaultFilter);
    }
}
