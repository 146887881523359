<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [showFirstCrumb]="false">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon"
                 adaptTooltip="Resilient Business Goal">
                <i [ngClass]="ZoneMetadata.Icon[result.zone]"
                   [style.background-color]="ZoneMetadata.Colour[result.zone]"
                   class="fa-inverse"></i>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">{{ZoneMetadata.Name[result.zone]}}</div>
    </div>
</adapt-collapsible-search-row>
