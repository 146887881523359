import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

export class StakeholderAuthService {
    public static readonly ReadStakeholders = "readStakeholders";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StakeholderAuthService.ReadStakeholders,
            {
                requirePermissions: [
                    FeaturePermissionName.StakeholdersPeopleRead,
                ],
            },
        );
    }
}
