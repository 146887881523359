"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var layout_1 = require("./layout");
var D3StyleLayoutAdaptor = function (_super) {
  __extends(D3StyleLayoutAdaptor, _super);
  function D3StyleLayoutAdaptor(d3Context) {
    var _this = _super.call(this) || this;
    _this.d3Context = d3Context;
    _this.event = d3Context.dispatch(layout_1.EventType[layout_1.EventType.start], layout_1.EventType[layout_1.EventType.tick], layout_1.EventType[layout_1.EventType.end]);
    var d3layout = _this;
    var drag;
    _this.drag = function () {
      if (!drag) {
        var drag = d3Context.drag().subject(layout_1.Layout.dragOrigin).on("start.d3adaptor", layout_1.Layout.dragStart).on("drag.d3adaptor", function (d) {
          layout_1.Layout.drag(d, d3Context.event);
          d3layout.resume();
        }).on("end.d3adaptor", layout_1.Layout.dragEnd);
      }
      if (!arguments.length) return drag;
      arguments[0].call(drag);
    };
    return _this;
  }
  D3StyleLayoutAdaptor.prototype.trigger = function (e) {
    var d3event = {
      type: layout_1.EventType[e.type],
      alpha: e.alpha,
      stress: e.stress
    };
    this.event.call(d3event.type, d3event);
  };
  D3StyleLayoutAdaptor.prototype.kick = function () {
    var _this = this;
    var t = this.d3Context.timer(function () {
      return _super.prototype.tick.call(_this) && t.stop();
    });
  };
  D3StyleLayoutAdaptor.prototype.on = function (eventType, listener) {
    if (typeof eventType === 'string') {
      this.event.on(eventType, listener);
    } else {
      this.event.on(layout_1.EventType[eventType], listener);
    }
    return this;
  };
  return D3StyleLayoutAdaptor;
}(layout_1.Layout);
exports.D3StyleLayoutAdaptor = D3StyleLayoutAdaptor;
