<adapt-dialog [maxWidth]="640">
    <h3 adapt-dialog-title>Move {{processStep.extensions.isRoleTask ? 'Role Task' : 'Process Step'}}</h3>

    <div adapt-dialog-content>
        <div class="mb-3">
            Move <adapt-link-process-step [processStep]="processStep"></adapt-link-process-step> to:
        </div>

        <adapt-select-process-map #focus
                                  [(processMap)]="selectedProcessMap"
                                  [filter]="isDifferentProcessMap"
                                  [disabled]="!isSelecting"
                                  [allowCreate]="true"
                                  [onlyEditableSystems]="true"
                                  (entitiesChange)="changedEntities = $event"
                                  [selectedSystem]="existingSystemComponent?.system"
                                  [defaultNewMapName]="processStep.name"
                                  data-test="select-process-map-input"></adapt-select-process-map>

        <div *ngIf="selectedProcessMap && !isCreatingProcessMap"
             class="mt-3">
            <ng-container *adaptLoadingUntilFirstEmit="let childSteps of childSteps$">
                <p>
                    <adapt-link-process-step [processStep]="processStep"></adapt-link-process-step> will now become
                    a child of <adapt-link-process-map [processMap]="selectedProcessMap"></adapt-link-process-map>.
                </p>

                <div *ngIf="childSteps.length > 0"
                     class="child-steps-list-container">
                    By moving this step, you will also be moving the following children (and any further descendants):
                    <ul>
                        <li *ngFor="let step of childSteps">
                            <adapt-link-process-step [processStep]="step"></adapt-link-process-step>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>

    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="ms-auto">
        <ng-container *ngIf="isSelecting; else postMoveTemplate">
            <button adaptButton="move"
                    [disabled]="!selectedProcessMap || isCreatingProcessMap"
                    [adaptBlockingClick]="doMove"
                    data-test="move-process-map-button"></button>
            <button adaptButton="cancel"
                    (click)="cancel()"></button>
        </ng-container>
        <ng-template #postMoveTemplate>
            <button adaptButtonIcon="fal fa-sign-out-alt"
                    class="btn btn-success"
                    [adaptBlockingClick]="resolveMove"
                    [adaptBlockingClickParam]="PostMoveAction.GoToNewLocation"
                    [unsubscribeOnDestroy]="false"
                    data-test="new-location-button">Go to new location</button>
            <button adaptButtonIcon="fal fa-door-closed"
                    [adaptBlockingClick]="resolveMove"
                    [adaptBlockingClickParam]="PostMoveAction.StayHere">Stay here</button>
        </ng-template>
    </div>
</adapt-dialog>
