import { Injectable } from "@angular/core";
import { IShellPopoverLinkItem } from "@common/shell/shell-popover-link-item/shell-popover-link-item";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApplicationBarSearchItemComponent } from "./application-bar-search-item.component";

@Injectable()
export class ApplicationBarSearchItem implements IShellPopoverLinkItem {
    public readonly linkContent = {
        component: ApplicationBarSearchItemComponent,
    };
    // just before the user item
    public readonly ordinal = 998;
    public readonly isShown$: Observable<boolean>;

    public constructor(
        responsiveService: ResponsiveService,
        orgService: OrganisationService,
    ) {
        this.isShown$ = combineLatest([
            responsiveService.currentBreakpoint$,
            orgService.currentOrganisation$,
        ])
            .pipe(
                map(([b, currentOrg]) => b.isDesktopSize && !!currentOrg),
            );
    }
}
