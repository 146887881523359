import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptPersonModule } from "app/features/people/person/person.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxListModule, DxSelectBoxModule, DxTabPanelModule, DxTagBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptProcessMapSharedModule } from "../process-map/process-map-shared.module";
import { AdaptRoleModule } from "../role/role.module";
import { ConfigureRoleDialogComponent } from "./configure-role-dialog/configure-role-dialog.component";
import { DeleteGuidanceMaterialDialogComponent } from "./delete-guidance-material-dialog/delete-guidance-material-dialog.component";
import { DisplayGuidanceMaterialUsageComponent } from "./display-guidance-material-usage/display-guidance-material-usage.component";
import { EditGuidanceMaterialDialogComponent } from "./edit-guidance-material-dialog/edit-guidance-material-dialog.component";
import { EditRoleConnectionComponent } from "./edit-role-connection/edit-role-connection.component";
import { EditRoleConnectionsComponent } from "./edit-role-connections/edit-role-connections.component";
import { GuidanceMaterialsPageComponent } from "./guidance-materials-page/guidance-materials-page.component";
import { guidanceMaterialsPageRoute } from "./guidance-materials-page/guidance-materials-page.route";
import { IntegratedArchitectureFrameworkAuthService } from "./integrated-architecture-framework-auth.service";
import { AdaptIntegratedArchitectureSharedModule } from "./integrated-architecture-shared.module";
import { MergeGuidanceMaterialsDialogComponent } from "./merge-guidance-materials-dialog/merge-guidance-materials-dialog.component";
import { MergeRolesDialogComponent } from "./merge-roles-dialog/merge-roles-dialog.component";
import { RolesPageComponent } from "./roles-page/roles-page.component";
import { rolesPageRoute } from "./roles-page/roles-page.route";
import { ShowGuidanceMaterialUsageDialogComponent } from "./show-guidance-material-usage-dialog/show-guidance-material-usage-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxTagBoxModule,
        DxDataGridModule,
        DxAccordionModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxListModule,
        AdaptTooltipModule,
        AdaptDirectorySharedModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptRoleModule,
        AdaptIntegratedArchitectureSharedModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptProcessMapSharedModule,
        AdaptDocumentSelectorModule,
        AdaptEntityValidatorModule,
        AdaptTwoColumnModule,
        AdaptEmbedShellModule,
        AdaptCommonDialogModule,
        DxTabPanelModule,
        AdaptPersonModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        AdaptAuthorisationModule,
        AdaptSystemisationModule,
        AdaptLabellingModule,
        AdaptDashboardElementModule,
    ],
    exports: [
        EditRoleConnectionsComponent,
    ],
    declarations: [
        ShowGuidanceMaterialUsageDialogComponent,
        DisplayGuidanceMaterialUsageComponent,
        EditGuidanceMaterialDialogComponent,
        DeleteGuidanceMaterialDialogComponent,
        MergeGuidanceMaterialsDialogComponent,
        GuidanceMaterialsPageComponent,
        ConfigureRoleDialogComponent,
        EditRoleConnectionsComponent,
        EditRoleConnectionComponent,
        RolesPageComponent,
        MergeRolesDialogComponent,
    ],
})
export class AdaptIntegratedArchitectureModule {
    public static readonly Routes = [
        ...guidanceMaterialsPageRoute.routes,
        ...rolesPageRoute.routes,

    ];

    constructor(authorisationService: AuthorisationService) {
        IntegratedArchitectureFrameworkAuthService.registerAccessVerifiers(authorisationService);
    }
}
