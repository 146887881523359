import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BaseService } from "@common/service/base.service";
import { CulturalLeadershipCatchupStatus } from "app/features/culture/cultural-leadership/cultural-leadership-catchup-status.enum";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { CulturalLeadershipConnectionStatusFilter } from "./cultural-leadership-connection-status-filter.enum";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "./cultural-leadership-measurement-analysis-filter.interface";

@Autobind
export abstract class CulturalLeadershipAnalysisDataBaseService extends BaseService {
    protected filterByCulturalMeasurement(
        culturalData: ICulturalLeadershipPersonalAnalysisData[],
        connections: Connection[],
        hasQuantitativeAccess: boolean,
        date: Date,
        filter: ICulturalLeadershipMeasurementAnalysisFilter,
        culturalRelationships: CulturalRelationship[],
    ) {
        culturalData = this.filterByEmployeesOnly(culturalData);
        culturalData = this.addMissingPeople(culturalData, connections, hasQuantitativeAccess, date);
        culturalData = this.filterByConnectionStatus(culturalData, filter, date);
        culturalData = this.filterByTeam(culturalData, filter, date);
        culturalData = this.filterByCategory(culturalData, filter);
        culturalData = this.filterByProfileItem(culturalData, filter);
        culturalData = this.filterByOverdue(culturalData, filter);
        culturalData = this.setCulturalLeaderData(culturalData, culturalRelationships, date);
        culturalData = this.filterByCulturalLeader(culturalData, filter, date, culturalRelationships);
        culturalData = this.filterByCulturalLeaderPresent(culturalData, filter);
        return culturalData;
    }

    private filterByEmployeesOnly(culturalData: ICulturalLeadershipPersonalAnalysisData[]) {
        return culturalData.filter((analysisData) => {
            // if the cultural measurement doesnt have a person record, then assume its an employee connection that
            // has been anonymised because the current user does not have permission to see the data
            if (!analysisData.person) {
                return true;
            }

            return analysisData.employeeConnections.some((connection) => connection.isActiveAt(analysisData.latestCatchup));
        });
    }

    private addMissingPeople(culturalData: ICulturalLeadershipPersonalAnalysisData[], connections: Connection[], hasQuantitativeAccess: boolean, date: Date) {
        if (hasQuantitativeAccess) {
            // add empty records for people who are employees at the specified time
            // this allows us to generate a 'missing cultural index' report
            for (const connection of connections) {
                this.addMissingPerson(culturalData, date, connection);
            }
        }

        return culturalData;
    }

    private addMissingPerson(culturalData: ICulturalLeadershipPersonalAnalysisData[], date: Date, connection: Connection) {
        if (connection.isEmployeeConnection()
            && connection.isActiveAt(date)) {
            if (!culturalData.some(({ person }) => person === connection.person)) {
                const data = {
                    person: connection.person,
                    employeeConnections: connection.person.connections.filter((c: Connection) => c.isEmployeeConnection()),
                    catchupStatus: CulturalLeadershipCatchupStatus.Overdue,
                } as ICulturalLeadershipPersonalAnalysisData;

                culturalData.push(data);
            }
        }
    }

    private filterByConnectionStatus(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter, date: Date) {
        // if we are showing inactive people, then dont filter by active people!
        if (filter.connectionStatus === CulturalLeadershipConnectionStatusFilter.ActiveOnly) {
            culturalData = culturalData.filter((data) => this.activeAtDate(data, date));
        } else if (filter.connectionStatus === CulturalLeadershipConnectionStatusFilter.InactiveOnly) {
            culturalData = culturalData.filter((data) => this.inactiveAtDate(data, date));
        }

        return culturalData;
    }


    private activeAtDate(analysisData: ICulturalLeadershipPersonalAnalysisData, date: Date) {
        // if the cultural measurement doesnt have a person record, then assume its an employee connection that
        // has been anonymised because the current user does not have permission to see the data
        if (!analysisData.person) {
            return true;
        }

        // ensure the person is active at the current date AND is an employee
        for (const connection of analysisData.employeeConnections) {
            if (connection.isActiveAt(date)) {
                return true;
            }
        }

        return false;
    }

    private inactiveAtDate(analysisData: ICulturalLeadershipPersonalAnalysisData, date: Date) {
        // if the cultural measurement doesnt have a person record, then assume its an employee connection that
        // has been anonymised because the current user does not have permission to see the data
        if (!analysisData.person) {
            return false;
        }

        // ensure the person is active at the current date AND is an employee
        let hasPreviousActiveConnection = false;
        for (const connection of analysisData.employeeConnections) {
            if (ActiveEntityUtilities.isInactiveAt(connection, date)) {
                hasPreviousActiveConnection = true;
            } else if (connection.isActiveAt(date)) {
                return false;
            }
        }

        return hasPreviousActiveConnection;
    }

    private filterByTeam(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter, date: Date) {
        // no filter or team specified; return all records
        if (!filter.team) {
            return culturalData;
        }

        // team not active at that date; return no records
        if (!filter.team.isActiveAt(date)) {
            return [];
        }

        // build a list of people active in the team at the specified date
        const activeTeamPeople: Person[] = [];
        for (const roleConnection of filter.team.roleConnections) {
            if (roleConnection.isActiveAt(date)) {
                activeTeamPeople.push(roleConnection.connection.person);
            }
        }

        return culturalData.filter((data) => !data.person || activeTeamPeople.some((person: Person) => data.person === person));
    }

    private filterByCategory(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        if (filter.category1 && filter.category2 && filter.category3 && filter.category4) {
            // nothing to do
            return culturalData;
        }

        return culturalData.filter((data) => this.categoryFilter(data, filter));
    }

    private categoryFilter(analysisData: ICulturalLeadershipPersonalAnalysisData, filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        if (!analysisData.culturalIndex && !analysisData.careerValuation) {
            return false;
        }

        const measurementEntity = analysisData.culturalIndex ? analysisData.culturalIndex.extensions : analysisData.careerValuation.extensions;

        if (!filter.category1 && measurementEntity.isCategory1()) {
            return false;
        }
        if (!filter.category2 && measurementEntity.isCategory2()) {
            return false;
        }
        if (!filter.category3 && measurementEntity.isCategory3()) {
            return false;
        }
        if (!filter.category4 && measurementEntity.isCategory4()) {
            return false;
        }

        return true;
    }

    private filterByProfileItem(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        if (filter.profileItem && filter.profileItemValue) {
            culturalData = culturalData.filter((data) => this.profileMatches(data, filter));
        }

        return culturalData;
    }

    private profileMatches(analysisData: ICulturalLeadershipPersonalAnalysisData, filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        if (!analysisData.person) {
            return true;
        }

        return analysisData.person.personProfileItemValues.some((value) => (value.personProfileItemId === filter.profileItem!.personProfileItemId)
            && (value.text === filter.profileItemValue!.text));
    }

    private filterByOverdue(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        // show overdue and on schedule; return all records
        if (!filter.showOverdueOnly) {
            return culturalData;
        }

        return culturalData.filter((data) => data.catchupStatus === CulturalLeadershipCatchupStatus.Overdue);
    }

    private setCulturalLeaderData(culturalData: ICulturalLeadershipPersonalAnalysisData[], culturalRelationships: CulturalRelationship[], date: Date) {
        if (culturalRelationships) {
            culturalData.forEach((data) => this.setCulturalLeader(data, culturalRelationships, date));
        }

        return culturalData;
    }

    private setCulturalLeader(analysisData: ICulturalLeadershipPersonalAnalysisData, culturalRelationships: CulturalRelationship[], date: Date) {
        if (!analysisData.person) {
            return;
        }

        for (const culturalRelationship of culturalRelationships) {
            if ((culturalRelationship.personId === analysisData.person.personId)
                && culturalRelationship.isActiveAt(date)) {
                analysisData.culturalLeader = culturalRelationship.culturalLeader;
                break;
            }
        }
    }

    private filterByCulturalLeader(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter, date: Date, culturalRelationships: CulturalRelationship[]) {
        // no filter or cl specified; return all records
        if (!filter.culturalLeader) {
            return culturalData;
        }

        // if CL is not active at the date; return no records
        const activeCulturalRelationships = culturalRelationships.filter((cr) => (cr.culturalLeaderId === filter.culturalLeader!.personId) && cr.isActiveAt(date));
        if (!activeCulturalRelationships.length) {
            return [];
        }

        return culturalData.filter((data) => !data.person || activeCulturalRelationships.some((relationship: CulturalRelationship) => relationship.personId === data.person.personId));
    }


    private filterByCulturalLeaderPresent(culturalData: ICulturalLeadershipPersonalAnalysisData[], filter: ICulturalLeadershipMeasurementAnalysisFilter) {
        // showing everyone (even if they dont have a CL); return all records
        if (!filter.showPeopleWithoutCulturalLeader) {
            culturalData = culturalData.filter((data) => !data.person || !!data.culturalLeader);
        }

        return culturalData;
    }
}
