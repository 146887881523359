import { Injector } from "@angular/core";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SearchParamValue } from "@common/route/route.service";
import { UrlFilterService } from "@common/shell/filter/url-filter.service";
import { UserService } from "@common/user/user.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import isEqual from "lodash.isequal";
import { asyncScheduler, BehaviorSubject, lastValueFrom } from "rxjs";
import { throttleTime } from "rxjs/operators";

export enum CulturalDataFilterParamKeys {
    Team = "team",
    CulturalLeader = "culturalLeader",
    ProfileItem = "profileItem",
    ProfileItemValue = "profileItemValue",
    MaximumActualPercentage = "maximumActualPercentage",
    MinimumIdealValue = "minimumIdealValue",
    Category1 = "category1",
    Category2 = "category2",
    Category3 = "category3",
    Category4 = "category4",
    ConnectionStatus = "connectionStatus",
    ShowPeopleWithoutCulturalLeader = "showPeopleWithoutCulturalLeader",
    ShowOverdueOnly = "showOverdueOnly",
    ShowTrendOutwardsOnly = "showTrendOutwardsOnly",
}

export class CulturalDataFilter<T extends { [key: string]: any }> {
    public isDefault = true;
    private readonly subject: BehaviorSubject<T>;
    private commonTeamService: CommonTeamsService;
    private personService: PersonService;
    private directorySharedService: DirectorySharedService;
    private userService: UserService;
    private urlFilterService: UrlFilterService;

    public constructor(
        private defaultValues: T,
        injector: Injector,
    ) {
        this.subject = new BehaviorSubject({ ...this.defaultValues });
        this.subject.pipe(
            throttleTime(10, asyncScheduler, { leading: false, trailing: true }),
        ).subscribe((filter) => this.isDefault = isEqual(filter, this.defaultValues));

        /* using injector here being used in both career-valuation-filter.service and cultural-index-filter.service,
        much cleaner just passing in injector rather than injecting the same classes twice and passing them through.*/
        this.commonTeamService = injector.get(CommonTeamsService);
        this.personService = injector.get(PersonService);
        this.directorySharedService = injector.get(DirectorySharedService);
        this.userService = injector.get(UserService);
        this.urlFilterService = injector.get(UrlFilterService);
    }

    public get filter() {
        return { ... this.subject.value };
    }

    public get filterListener() {
        return this.subject.asObservable().pipe(
            throttleTime(10, asyncScheduler, { leading: false, trailing: true }),
        );
    }

    public async emitFilterChange(key: keyof T, value: any, urlValue: SearchParamValue) {
        await this.urlFilterService.setFilter({ [key]: this.defaultValues[key] !== urlValue ? urlValue : undefined });
        const currentFilter = this.filter;
        (currentFilter as any)[key] = value;
        this.subject.next(ObjectUtilities.cleanNullAndUndefinedValues(currentFilter) as T);
    }

    public reset(defaultValues?: T) {
        if (defaultValues) {
            this.subject.next(defaultValues);
        } else {
            this.subject.next({ ...this.defaultValues });
        }
    }

    public async setFilterFromUrl() {
        const paramValues = this.urlFilterService.getFilters(Object.values(CulturalDataFilterParamKeys));
        for (const param of paramValues) {

            if (param.value) {
                switch (param.name) {
                    case CulturalDataFilterParamKeys.Team:
                        const teamId = NumberUtilities.parseNumber(param.value);
                        if (teamId) {
                            const team = await lastValueFrom(this.commonTeamService.getTeamById(teamId));
                            if (team) {
                                await this.emitFilterChange(param.name, team, team.teamId);
                            }
                        }
                        break;

                    case CulturalDataFilterParamKeys.CulturalLeader:
                        const personId = NumberUtilities.parseNumber(param.value);
                        if (personId) {
                            const p = await lastValueFrom(this.personService.getPerson(personId));
                            if (p) {
                                await this.emitFilterChange(param.name, p, p.personId);
                            }
                        }
                        break;

                    case CulturalDataFilterParamKeys.ProfileItem:
                        const profileItemId = NumberUtilities.parseNumber(param.value);
                        if (profileItemId) {
                            const profileItem = await this.directorySharedService.promiseToGetPersonProfileItemsById(profileItemId);
                            if (profileItem) {
                                await this.emitFilterChange(param.name, profileItem, profileItem.personProfileItemId);
                            }
                        }
                        break;

                    case CulturalDataFilterParamKeys.ProfileItemValue:
                        const person = await this.userService.getCurrentPerson();
                        if (person) {
                            const itemValue = person.personProfileItemValues.find((value) =>
                                value.personProfileItemValueId === NumberUtilities.parseNumber(param.value),
                            );
                            if (itemValue) {
                                await this.emitFilterChange(param.name, itemValue, itemValue.personProfileItemValueId);
                            }
                        }
                        break;

                    case CulturalDataFilterParamKeys.Category1:
                    case CulturalDataFilterParamKeys.Category2:
                    case CulturalDataFilterParamKeys.Category3:
                    case CulturalDataFilterParamKeys.Category4:
                    case CulturalDataFilterParamKeys.ShowPeopleWithoutCulturalLeader:
                    case CulturalDataFilterParamKeys.ShowOverdueOnly:
                    case CulturalDataFilterParamKeys.ShowTrendOutwardsOnly:
                        const booleanValue = param.value === "true";
                        await this.emitFilterChange(param.name, booleanValue, booleanValue);
                        break;

                    case CulturalDataFilterParamKeys.MaximumActualPercentage:
                    case CulturalDataFilterParamKeys.MinimumIdealValue:
                        await this.emitFilterChange(param.name, NumberUtilities.parseNumber(param.value), param.value);
                        break;

                    case CulturalDataFilterParamKeys.ConnectionStatus:
                        await this.emitFilterChange(param.name, param.value, param.value);
                        break;
                }
            }
        }
    }
}
