import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { SubscribeOrganisationPageComponent } from "./subscribe-organisation-page.component";

export const subscribeOrganisationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-subscribe-organisation-page", SubscribeOrganisationPageComponent)
    .atUrl("/subscribe")
    .withTitle("Subscribe Organisation")
    .requiresLogin(true)
    .requiresCoach(true)
    .build();
