import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ConnectionQueryUtilities } from "@org-common/lib/directory-shared/connection-query-utilities";
import dxDataGrid, { InitializedEvent } from "devextreme/ui/data_grid";
import { PointClickEvent } from "devextreme/viz/chart";
import { lastValueFrom, merge } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { CareerValuationService } from "../career-valuation.service";
import { ICareerValuationChartTooltipOptions } from "../career-valuation-chart-tooltip-options.interface";
import { CareerValuationUiService, ICareerValuationChartData } from "../career-valuation-ui.service";
import { ViewCareerValuationDialogComponent } from "../view-cvt-dialog/view-cvt-dialog.component";

interface ICareerValuationWithStakeholder extends CareerValuation {
    stakeholderConnectionId?: number
}

interface IDateRange {
    start: Date
    end: Date
}

@Component({
    selector: "adapt-cvt-history",
    templateUrl: "./cvt-history.component.html",
})
export class CareerValuationHistoryComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public personId?: number;
    @Input() public showGrid = true;

    public showName?: string;

    private showTooltipName = false;
    public careerValuations: ICareerValuationWithStakeholder[] = [];
    public careerValuationsChartData: ICareerValuationChartData<ICareerValuationWithStakeholder>[] = [];
    public stakeholderRange: IDateRange[] = [];

    private gridInstance?: dxDataGrid;

    public isBusy = true;

    public shortDateFormat = DateFormats.globalize.short;

    public palette = CareerValuationUiService.CareerValuationPaletteName;

    public notesColumnVisible = true;
    public allColumnsVisible = false;

    public categories?: CareerValuationCategory[];

    public constructor(
        public careerValuationUiService: CareerValuationUiService,
        public responsiveService: ResponsiveService,
        private careerValuationService: CareerValuationService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChangedInSave(CareerValuation),
            rxjsBreezeService.entityTypeChangedInSave(CareerValuationCategoryValue),
        ).pipe(
            debounceTime(50),
            switchMap(() => this.getDataThenUpdateChart()),
            this.takeUntilDestroyed(),
        )
            .subscribe();
    }

    public async ngOnInit() {
        this.categories = await this.careerValuationService.promiseToGetCareerValuationCategories();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.personId) {
            this.getDataThenUpdateChart();
        }
    }

    @Autobind
    public onPointClick(info: PointClickEvent) {
        const careerValuation: CareerValuation = info.target!.tag;
        info.component.hideTooltip();
        this.dialogService.open(ViewCareerValuationDialogComponent, careerValuation).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.gridInstance = e.component;
    }

    @Autobind
    public async exportData() {
        if (this.gridInstance) {
            // we want all columns in export but not to be displayed
            this.gridInstance.beginUpdate();
            this.allColumnsVisible = true;
            await this.gridInstance.refresh();

            await DxUtilities.exportGridToExcel("Career Valuation history", this.gridInstance);

            this.allColumnsVisible = false;
            this.gridInstance.endUpdate();
        }
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        const valuation = info.point.tag;
        const tooltipOptions: ICareerValuationChartTooltipOptions = {
            categoryName: info.seriesName,
            showName: this.showTooltipName,
            notesColumnVisible: true,
        };

        let html = this.careerValuationUiService.getChartDataTooltip(valuation, tooltipOptions);
        if (valuation.stakeholderConnectionId) {
            html = `${html}<br /><b>Recorded as Stakeholder</b>`;
        }

        return { html };
    }

    public calculateCellValue(valuation: ICareerValuationWithStakeholder) {
        if (valuation.stakeholderConnectionId) {
            return `<b>Recorded as Stakeholder</b><br>${valuation.notes}`;
        }

        return valuation.notes;
    }

    @Autobind
    private async getDataThenUpdateChart() {
        this.isBusy = true;
        this.careerValuations = await this.fetchData();
        this.careerValuationsChartData = this.careerValuations.map(this.careerValuationUiService.createCareerValuationChartObject);
        this.setColumnVisibility();
        this.isBusy = false;
    }

    private async fetchData() {
        if (!this.personId) {
            return [];
        }

        const valuations = await this.careerValuationService.promiseToGetAllCareerValuationsForPerson(this.personId);
        return this.mapStakeholderData(valuations);
    }

    private async mapStakeholderData(valuations: CareerValuation[]): Promise<ICareerValuationWithStakeholder[]> {
        const connections = await lastValueFrom(new ConnectionQueryUtilities(this.commonDataService).getConnectionsForPersonId(this.personId!));
        const stakeholderConnections = connections.filter((c) => c.isStakeholderConnection());

        // add stakeholder data to the valuation
        const valuationsWithStakeholderData = valuations.map((valuation) => {
            const connection = stakeholderConnections.find((c) => c.isActiveAt(valuation.creationDate));
            const withStakeholder = connection ? { stakeholderConnectionId: connection.connectionId } : {};
            return Object.assign(valuation, withStakeholder);
        }) as ICareerValuationWithStakeholder[];

        // group valuations by stakeholder active date
        this.stakeholderRange = this.generateStakeholderRange(valuationsWithStakeholderData);

        return valuationsWithStakeholderData;
    }

    private generateStakeholderRange(valuationsWithStakeholderData: ICareerValuationWithStakeholder[]) {
        const stakeholderValuations = valuationsWithStakeholderData
            .filter((valuation) => valuation.stakeholderConnectionId)
            .sort((a, b) => a.creationDate > b.creationDate ? 1 : -1);

        const groupedValuations = ArrayUtilities.groupArrayBy(stakeholderValuations, (v) => v.stakeholderConnectionId);
        return groupedValuations.map(({ items: dates }) => ({
            start: dates[0].creationDate,
            end: dates[dates.length - 1].creationDate,
        }));
    }

    @Autobind
    private setColumnVisibility() {
        let notesColumnVisible = false;

        for (const cvt of this.careerValuations) {
            if (cvt.notes !== null) {
                notesColumnVisible = true;
                break;
            }
        }

        this.notesColumnVisible = notesColumnVisible;
    }
}
