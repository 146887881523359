<adapt-feature-guidance [configItem]="configItem">
    <p>Tier-1 architecture provides a high-level view of the organisation and the accountabilities of its leaders.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div class="row"
         *ngIf="roleType">
        <div class="col-md-6 col-xl-4">
            <div class="form-group">
                <label for="roleTypeLabel">Role name for leaders
                    <span class="fal fa-info-circle"
                          title="Changing this role name will update the name of existing roles for Value Stream and Key Function leaders."></span>
                </label>
                <dx-text-box id="roleTypeLabel"
                             [adaptValidateEntity]="roleType"
                             adaptValidateEntityProperty="defaultLabel"
                             [(value)]="roleType!.defaultLabel"></dx-text-box>
                <small class="form-text text-muted">Examples: TSL(Culture), Leader(Culture).</small>
            </div>
        </div>
    </div>

    <div class="row"
         *ngIf="orgConfiguration">
        <div class="col-md-6 col-xl-4 d-flex flex-column">
            <label for="productZoneName">Product zone name
                <span class="fal fa-info-circle"
                      title="This zone name will be displayed on the Organisation Map."></span>
            </label>
            <dx-text-box id="productZoneName"
                         [adaptValidateEntity]="orgConfiguration"
                         adaptValidateEntityProperty="productZoneName"
                         [(value)]="orgConfiguration!.productZoneName"></dx-text-box>
            <small class="form-text text-muted">Examples: Research & Development, Product.</small>
        </div>
    </div>

    <div class="form-group"
         *ngIf="orgConfiguration">
        <div class="mt-3 d-flex flex-column">
            <label for="productZonePosition">Product zone position
                <span class="fal fa-info-circle"
                      title="This will set whether the product zone appears to the left of economic engine or below it when showing in our standard organisation map."></span>
            </label>
            <dx-check-box id="productZonePosition"
                          text="Show product zone to the left of economic engine"
                          [value]="productOnLeft"
                          (valueChange)="onZonePositionChange($any($event))"></dx-check-box>
        </div>
    </div>
</adapt-feature-configuration>
