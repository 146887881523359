import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Product } from "./product";
import { ValueStream } from "./value-stream";

export class ValueStreamProduct extends BaseEntity<ValueStreamProduct> {
    public valueStreamProductId!: number;
    public valueStreamId!: number;
    public productId!: number;
    public ordinal!: number;
    public product!: Product;
    public valueStream!: ValueStream;
}

export const ValueStreamProductBreezeModel = new BreezeModelBuilder("ValueStreamProduct", ValueStreamProduct)
    .hasSource()
    .isOrganisationEntity()
    .withSortField("ordinal")
    .withUniqueKeys("productId", "valueStreamId")
    .alwaysFetchingNavigationProperty("product")
    .build();
