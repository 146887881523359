import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StandardIntercomSearchTerm } from "app/standard-intercom-search-term";
import { IntegratedArchitectureFrameworkAuthService } from "../../integrated-architecture/integrated-architecture-framework-auth.service";
import { RoleDescriptionPageComponent } from "./role-description-page.component";

export const roleDescriptionRoute = new OrganisationPageRouteBuilder<{ roleId: number }>()
    .usingNgComponent("adapt-role-description-page", RoleDescriptionPageComponent)
    .atOrganisationUrl("/architecture/roles/:roleId")
    .withTitle("Role Description")
    .reloadOnSearch(false)
    .verifyingAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2)
    .verifyingFeatures(FeatureName.ArchitectureTier2)
    .withHelp(StandardIntercomSearchTerm.ManageRoles)
    .build();
