<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Merge products & services</h3>
    <div adapt-dialog-content>
        <div class="row center-content"
             *adaptLoadingUntilFirstEmit="let emit of primeValueStreamProducts$">
            <div class="col-5">
                <dx-accordion [items]="products"
                              [collapsible]="true"
                              [selectedIndex]="-1"
                              [deferRendering]="false"
                              [multiple]="true">
                    <div *dxTemplate="let product of 'title'">
                        <span>{{product.name}}</span>
                    </div>
                    <div *dxTemplate="let product of 'item'">
                        <span *ngIf="!product.valueStreamProducts?.length; else showUsageCount">
                            Not used in any value streams.
                        </span>
                        <ng-template #showUsageCount>
                            <span>Used in {{product.valueStreamProducts.length}} value streams.</span>
                        </ng-template>
                    </div>
                </dx-accordion>
            </div>
            <div class="col-2 text-center">
                <h3>INTO <i class="fal fa-fw fa-long-arrow-right"></i></h3>
            </div>
            <div class="col-5 text-end">
                <dx-select-box #focus
                               [dataSource]="products"
                               displayExpr="name"
                               (valueChange)="destinationProduct = $event"></dx-select-box>
            </div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveButtonPreset]="{buttonClass: 'btn btn-danger', iconClass: 'fal fa-compress', buttonText: 'Merge'}"
                                   [saveIsDisabled]="!destinationProduct"
                                   [saveBlockingClick]="merge"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
