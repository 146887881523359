import { Component, Input } from "@angular/core";
import { StakeholderStory } from "@common/ADAPT.Common.Model/organisation/stakeholder-story";

@Component({
    selector: "adapt-display-customer-story",
    templateUrl: "./display-customer-story.component.html",
})
export class DisplayCustomerStoryComponent {
    @Input() public customerStory?: StakeholderStory;
}
