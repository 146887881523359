import { Component, ElementRef } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import dxChart, { InitializedEvent } from "devextreme/viz/chart";
import moment from "moment";
import { CareerValuationService } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";

interface ICareerValuationTimelineData {
    date: Date;
    category1Count: number;
    category2Count: number;
    category3Count: number;
    category4Count: number;
}

@Component({
    selector: "adapt-cvt-timeline-chart",
    templateUrl: "./analyse-cvt-timeline-chart.component.html",
})
export class CareerValuationTimelineChartComponent extends BaseComponent {
    public chart?: dxChart;

    public data: ICareerValuationTimelineData[] = [];

    public filterParameters?: ICareerValuationAnalysisFilter;

    public isLoading = true;

    public constructor(
        public elementRef: ElementRef,
        private careerValuationService: CareerValuationService,
        public careerValuationUiService: CareerValuationUiService,
        filterService: CareerValuationFilterService,
        private responsiveService: ResponsiveService,
    ) {
        super(elementRef);

        filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
            this.updateDataAndChart();
        });
    }

    public get height() {
        return this.responsiveService.currentBreakpoint.isMobileSize ? 300 : 600;
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {

        let result = "";
        const data: ICareerValuationTimelineData = info.point.data;

        result += "<b>Date: </b>" + moment(data.date).format(DateFormats.moment.long);
        result += "<br /><br /><b>" + this.careerValuationUiService.valueStates[0].state + ": </b>" + data.category1Count;
        result += "<br /><b>" + this.careerValuationUiService.valueStates[1].state + ": </b>" + data.category2Count;
        result += "<br /><b>" + this.careerValuationUiService.valueStates[2].state + ": </b>" + data.category3Count;
        result += "<br /><b>" + this.careerValuationUiService.valueStates[3].state + ": </b>" + data.category4Count;

        return {
            html: result,
        };
    }

    @Autobind
    public onInitialized(e: InitializedEvent) {
        this.chart = e.component;

        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    private async updateDataAndChart() {
        if (!ChartUtils.isChartDisposed(this.chart)) {
            this.chart?.showLoadingIndicator();
        }

        const filterParameters = { ...this.filterParameters!, date: new Date() };
        this.data = await this.promiseToGetData([], filterParameters, true);

        this.isLoading = false;
    }

    private async promiseToGetData(timelineArray: ICareerValuationTimelineData[], filterParameters: ICareerValuationAnalysisFilter, prefetchAllData = false): Promise<ICareerValuationTimelineData[]> {
        const cvtData = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(filterParameters, prefetchAllData);

        const timelineData: ICareerValuationTimelineData = {
            date: filterParameters!.date!,
            category1Count: 0,
            category2Count: 0,
            category3Count: 0,
            category4Count: 0,
        };

        for (const entry of cvtData) {
            if (!entry.careerValuation) {
                continue;
            }

            if (entry.careerValuation.extensions.isCategory1()) {
                timelineData.category1Count++;
            } else if (entry.careerValuation.extensions.isCategory2()) {
                timelineData.category2Count++;
            } else if (entry.careerValuation.extensions.isCategory3()) {
                timelineData.category3Count++;
            } else {
                timelineData.category4Count++;
            }
        }

        // if no category has any count, then there is no more data to process
        if (!timelineData.category1Count && !timelineData.category2Count && !timelineData.category3Count && !timelineData.category4Count) {
            return timelineArray;
        }

        timelineArray.push(timelineData);

        filterParameters!.date = moment(filterParameters!.date)
            .add(-3, "months")
            .toDate();

        return await this.promiseToGetData(timelineArray, filterParameters);
    }
}
