import { Injectable } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { UserService } from "@common/user/user.service";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrgCommonNavigationTab } from "@org-common/lib/sidebar/org-common-navigation-tab";
import { PersonNavigationService } from "app/platform/navigation/person-navigation.service";
import { map, startWith, switchMap } from "rxjs/operators";
import { PersonalTabContentComponent } from "./personal-tab-content.component";

@Injectable()
export class PersonalTab extends OrgCommonNavigationTab {
    public id = "sidebar-personal";
    public label = "Personal";
    public ordinal = 0;

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: PersonNavigationService.IconClass,
            iconText: "Personal",
        },
    };

    public content: IComponentRender<PersonalTabContentComponent> = {
        component: PersonalTabContentComponent,
    };

    public constructor(
        userService: UserService,
        authorisationNotificationService: AuthorisationNotificationService,
        navigationHierarchyService: NavigationHierarchyService,
        organisationService: OrganisationService,
    ) {
        super(navigationHierarchyService, organisationService);

        this.isDisplayed$ = userService.currentPerson$.pipe(
            switchMap((p) => {
                // We need to check on this event as this is where the connections are
                // primed which person.isActive checks against
                return authorisationNotificationService.authorisationChanged$.pipe(
                    map(() => p),
                    // Ensure we check on load
                    startWith(p),
                );
            }),
            map((p) => !!p && p.isActive()),
        );
    }

    protected getHierarchyId() {
        return PersonNavigationService.HierarchyId;
    }
}
