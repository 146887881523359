<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Edit Team Location</h3>

    <div adapt-dialog-content>
        <adapt-edit-team-locations [team]="team"
                                   (populated)="onLocationsPopulated($event)"
                                   (added)="addEntitiesToConfirm([$event])"></adapt-edit-team-locations>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
