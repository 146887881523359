<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Copying {{systemComponent.extensions.componentLabel}}...</h3>

    <div adapt-dialog-content
         class="d-flex flex-column">
        <div class="alert alert-info">Please specify the destination system to copy this {{systemComponent.extensions.componentLabel}}
            '{{systemComponent.name}}' to.
        </div>
        <ng-container *adaptLoading="!newSystemComponent">
            <div class="mb-4"
                 *ngIf="!isSaving; else copyAndSaving">
                <h3>Destination system</h3>
                <adapt-select-system [system]="newSystemComponent!.system"
                                     [allowCreate]="canCreate"
                                     [onlyEditable]="true"
                                     (systemChange)="onSystemChange($event)"
                                     (newEntityChange)="onNewSystemChange($event)"></adapt-select-system>
            </div>
            <ng-template #copyAndSaving>
                <div class="mb-4">Copying. Please wait...</div>
                <div *adaptLoading="true"></div>
            </ng-template>
        </ng-container>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Copy & save"
                                   [saveIsDisabled]="isCreatingSystem"
                                   [saveBlockingClick]="copyAndSave"
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
