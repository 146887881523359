import { Component, Injector, OnInit } from "@angular/core";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { lastValueFrom } from "rxjs";
import { TeamsAuthService } from "../../teams/teams-auth.service";

@Component({
    selector: "adapt-team-catchups-page",
    templateUrl: "./team-catchups-page.component.html",
})
export class TeamCatchupsPageComponent extends BaseRoutedComponent implements OnInit {
    public team?: Team;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private authService: AuthorisationService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.updatePageParams();
        this.navigationEnd.subscribe(() => this.updatePageParams());
    }

    private async updatePageParams() {
        this.team = undefined;
        const teamId = this.getRouteParamInt("teamId");
        if (teamId) {
            this.team = await lastValueFrom(this.commonDataService.getById(TeamBreezeModel, teamId));
        }

        if (!this.team) {
            this.routeService.gotoHome();
        } else {
            this.verifyHasAccessToRoute(this.authService.getHasAccess(TeamsAuthService.ViewTeamNetworkHealth, this.team));
        }

        super.notifyActivated();
    }
}
