<ng-container *ngIf="!isCreating; else createTemplate">
    <dx-select-box #focus
                   [dataSource]="dataSource"
                   [grouped]="true"
                   [(value)]="processMap"
                   (valueChange)="processMapChange.emit($event)"
                   [disabled]="disabled"
                   displayExpr="name"
                   [searchEnabled]="true"
                   searchExpr="name">
        <div *dxTemplate="let group of 'group'">
            <i [ngClass]="systemIcon"></i><span class="ms-1">{{group.key}}</span>
        </div>
        <div *dxTemplate="let processMap of 'item'">
            <i class="ms-1"
               [ngClass]="processMapIcon"></i><span class="ms-1">{{processMap.name}}</span>
        </div>
    </dx-select-box>

    <button class="mt-2"
            *ngIf="allowCreate && !disabled"
            adaptButton="addLink"
            (click)="createNewMap()"
            data-test="create-new-process-map">
        Create a new task map
    </button>
</ng-container>

<ng-template #createTemplate>
    <div class="d-flex flex-column flex-md-row mb-4"
         *ngIf="newSystemComponent && newSystemComponent!.systemProcessMap">
        <div class="flex-grow-1">
            <div class="mb-1">System</div>
            <adapt-select-system [(system)]="newSystemComponent!.system"
                                 (systemChange)="validateComponentName()"
                                 [allowCreate]="false"
                                 [onlyEditable]="onlyEditableSystems"></adapt-select-system>
        </div>
        <div class="mt-3 mt-md-0 ms-0 ms-md-2 flex-grow-1">
            <div class="mb-1">New map name</div>
            <div class="d-flex align-items-center">
                <dx-text-box class="flex-grow-1"
                             data-test="new-process-map-name-input"
                             [(value)]="newSystemComponent!.systemProcessMap!.name"
                             (valueChange)="onNameChange($event)">
                    <dx-validator>
                        <dxi-validation-rule [adaptEntity]="newSystemComponent!.systemProcessMap"
                                             adaptEntityProperty="name"></dxi-validation-rule>
                        <dxi-validation-rule [adaptEntity]="newSystemComponent"
                                             adaptEntityProperty="name"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
                <div class="d-flex ms-2">
                    <button class="ms-auto"
                            adaptButton="add"
                            data-test="new-process-map-add-button"
                            [disabled]="!isMapCreationValid"
                            [adaptBlockingClick]="saveNewMapAndSelect"></button>
                    <button class="ms-2"
                            adaptButton="cancel"
                            (click)="cancelNewMap()"></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
