import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { cacheLatest } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { TeamsService } from "app/features/people/teams/teams.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { BehaviorSubject, combineLatest, merge, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { KeyFunctionsUiService } from "../../key-functions/key-functions-ui.service";
import { TeamCardComponent } from "../team-card/team-card.component";

export enum ViewOption {
    OrganisationMap = "organisation-map",
    OrganisationMapTeams = "organisation-map-teams",
    OrganisationMapSystems = "organisation-map-systems",
}

@Component({
    selector: "adapt-organisation-explorer",
    templateUrl: "./organisation-explorer.component.html",
    styleUrls: ["./organisation-explorer.component.scss"],
})
export class OrganisationExplorerComponent extends BaseComponent implements OnInit {
    public readonly ZoneMetadata = ZoneMetadata;

    @Input() public set selectedViewOption(value: ViewOption) {
        this.viewOption$.next(value);
    }

    @Input() public filterTeams?: (teams: Team[]) => Team[] | Observable<Team[]>;
    @Output() public loaded = new EventEmitter<void>();

    public keyFunctionIconClass = KeyFunction.IconClass;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.ImplementationKitArticle.ElaborateKeyFunctions);
    public canEditTier1 = false;

    public addKeyFunction = this.keyFunctionsUiService.addKeyFunction.bind(this.keyFunctionsUiService);

    private viewOption$ = new BehaviorSubject<ViewOption>(ViewOption.OrganisationMap);
    private allTeamLocations$: Observable<TeamLocation[]>;
    private keyFunctionTeams = new Map<KeyFunction, Observable<Team[]>>();
    private keyFunctionSystems = new Map<KeyFunction, Observable<SystemEntity[]>>();
    private teamSystems = new Map<Team, Observable<SystemEntity[]>>();
    public globalZoneTeams$: Observable<Team[]>;
    public showGlobalZone$: Observable<boolean>;

    public constructor(
        private keyFunctionsService: KeyFunctionsService,
        private keyFunctionsUiService: KeyFunctionsUiService,
        private authService: AuthorisationService,
        private teamsService: TeamsService,
        private rxjsBreezeService: RxjsBreezeService,
        private systemisationService: SystemisationService,
        labellingService: LabellingService,
    ) {
        super();
        // Prefetch and cache to prevent lots of hits when team locations are shown
        this.allTeamLocations$ = this.teamsService.getAllTeamLocations().pipe(
            cacheLatest(),
        );
        this.globalZoneTeams$ = merge(
            this.rxjsBreezeService.entityTypeChanged(Team),
            this.rxjsBreezeService.entityTypeChanged(TeamLocation),
        ).pipe(
            startWith(undefined),
            switchMap(() => this.teamsService.getActiveTeamsWithoutLocations()),
            switchMap((teams) => this.applyTeamFilter(teams)),
            cacheLatest(),
        );
        this.showGlobalZone$ = combineLatest([this.viewOption$, this.globalZoneTeams$]).pipe(
            map(([viewOption, teams]) => viewOption === ViewOption.OrganisationMapTeams && teams.length > 0),
        );

        labellingService.getLabelLocationsByPredicate(new MethodologyPredicate<LabelLocation>("systemId", "!=", null))
            .pipe(this.takeUntilDestroyed())
            .subscribe();
    }

    public async ngOnInit() {
        this.canEditTier1 = await this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.EditTier1);
    }

    public get showTeams() {
        return this.viewOption$.value === ViewOption.OrganisationMapTeams;
    }

    public get showSystems() {
        return this.viewOption$.value === ViewOption.OrganisationMapSystems;
    }

    public getKeyFunctionTeams(keyFunction: KeyFunction) {
        if (!this.keyFunctionTeams.has(keyFunction)) {
            const teamsInKeyFunction$ = this.allTeamLocations$.pipe(
                switchMap(() => this.rxjsBreezeService.entityTypeChanged(TeamLocation).pipe(
                    startWith(undefined),
                )),
                switchMap(() => this.keyFunctionsService.getKeyFunctionTeamLocations(keyFunction)),
                map((i) => i.map((tl) => tl.team)),
                switchMap((teams) => this.applyTeamFilter(teams)),
            );
            this.keyFunctionTeams.set(keyFunction, teamsInKeyFunction$);
        }

        return this.keyFunctionTeams.get(keyFunction)!;
    }

    public getKeyFunctionSystems(keyFunction: KeyFunction) {
        if (!this.keyFunctionSystems.has(keyFunction)) {
            const systems$ = this.rxjsBreezeService.entityTypeChanged(SystemLocation).pipe(
                startWith(undefined),
                switchMap(() => this.systemisationService.getSystemsForKeyFunction(keyFunction)),
            );
            this.keyFunctionSystems.set(keyFunction, systems$);
        }

        return this.keyFunctionSystems.get(keyFunction)!;
    }

    public getTeamSystems(team: Team) {
        if (!this.teamSystems.has(team)) {
            const systems$ = this.rxjsBreezeService.entityTypeChanged(SystemLocation).pipe(
                startWith(undefined),
                switchMap(() => this.systemisationService.getSystemsForTeam(team)),
            );
            this.teamSystems.set(team, systems$);
        }

        return this.teamSystems.get(team)!;
    }

    private applyTeamFilter(teams: Team[]) {
        return TeamCardComponent.applyTeamsFilter(teams, this.filterTeams);
    }

    public getZoneClass(zone?: Zone) {
        if (!zone) {
            return ZoneMetadata.BackgroundStyleClass[Zone.EconomicEngine];
        }

        return ZoneMetadata.BackgroundStyleClass[zone];
    }

}
