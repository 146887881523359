import { Injectable, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IKeyFunctionLocation, KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation, TeamLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-location";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { forkJoin, lastValueFrom, merge, of } from "rxjs";
import { debounceTime, filter, map, startWith, switchMap } from "rxjs/operators";
import { TeamsService } from "../../people/teams/teams.service";

export type LocationItem = KeyFunction | TeamLocation;

@Injectable({
    providedIn: "root",
})
export class OrganisationMapService extends BaseOrganisationService {
    constructor(
        injector: Injector,
        private keyFunctionsService: KeyFunctionsService,
        private teamsService: TeamsService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
    }

    public static getAsTeamLocation(item?: LocationItem) {
        // make sure team nav prop is not null (happens when deleting TeamLocation)
        if (item && item instanceof TeamLocation && item.team) {
            return item;
        }
        return undefined;
    }

    public static getAsKeyFunction(item?: LocationItem) {
        if (item && item instanceof KeyFunction && item.keyFunctionId) {
            return item;
        }
        return undefined;
    }

    public getLocationItems(location: IKeyFunctionLocation) {
        return forkJoin([
            this.keyFunctionsService.getKeyFunctionsInLocation(location),
            location.valueStreamId || (!location.valueStreamId && !location.zone)
                ? of([])
                : this.teamsService.getAllTeamLocations(),
        ]).pipe(
            map(([kf, teamLocations]) => {
                const teamsInLocation = teamLocations.filter((loc) => loc.zone === location.zone);
                return [...kf, ...teamsInLocation].sort(SortUtilities.getSortByFieldFunction<LocationItem>("ordinal")) as LocationItem[];
            }),
        );
    }

    public emitWhenLocationShouldBeFetched(location: IKeyFunctionLocation) {
        return merge(
            this.rxjsBreezeService.entityTypeChanged(KeyFunction),
            this.rxjsBreezeService.entityTypeChanged(TeamLocation),
        ).pipe(
            filter((loc) => loc.zone === location.zone
                || (loc instanceof KeyFunction && loc.valueStreamId === location.valueStreamId)),
            debounceTime(100),
            map(() => location),
            startWith(location),
        );
    }

    public addTeamToZone(team: Team, zone: Zone) {
        return this.getLocationItems(KeyFunctionLocation.fromZone(zone)).pipe(
            switchMap((locations) => {
                // -1 as fallback for when no other locations present
                return this.commonDataService.create(TeamLocationBreezeModel, {
                    zone,
                    team,
                    ordinal: this.getNextOrdinal(locations),
                });
            }),
        );
    }

    public async promiseToMoveKeyFunction(keyFunction: KeyFunction, newLocation: IKeyFunctionLocation) {
        if (keyFunction.isInLocation(newLocation)) {
            return [];
        }

        const modifiedEntities: LocationItem[] = [keyFunction];

        const itemsInOriginalLocation = await lastValueFrom(this.getLocationItems(keyFunction));
        modifiedEntities.push(...itemsInOriginalLocation.slice(keyFunction.ordinal + 1));
        SortUtilities.updateIntegerSortedArrayAfterItemRemoval(itemsInOriginalLocation, "ordinal", keyFunction.ordinal);

        const itemsInNewLocation = await lastValueFrom(this.getLocationItems(newLocation));
        // -1 as fallback for when no other locations present
        keyFunction.ordinal = this.getNextOrdinal(itemsInNewLocation);

        keyFunction.zone = newLocation.zone;
        keyFunction.valueStreamId = newLocation.valueStreamId;
        keyFunction.entityAspect.validateEntity();

        return modifiedEntities;
    }

    private getNextOrdinal(sortedLocations: LocationItem[]) {
        return Math.max(-1, ...sortedLocations.map((i) => i.ordinal)) + 1;
    }
}
