import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { CatchupRatingValue, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { StorageImageService } from "@common/storage/storage-image.service";
import { PersonImageComponent } from "@common/user/person-image/person-image.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { of, Subject, Subscription } from "rxjs";
import { debounceTime, delay, tap } from "rxjs/operators";
import { d3adaptor, ID3StyleLayoutAdaptor, Layout, Node } from "webcola";
import { CatchupRatingType } from "../catchup-relationships-filter/catchup-relationships-filter.component";
import { GraphConstants } from "./graph-constants";
import { SVGUtils } from "./svg-utils";

export interface ITooltipComponentDefinition {
    componentType: Type<{ [key: string]: any }>;
    linkInputMap?: { [key: string]: keyof IGraphLink };
    nodeInputMap?: { [key: string]: keyof IGraphNode };
}

export interface IGraphNode extends Node {
    id: number;
    fullName: string;
    personInitials: string;
    selected: boolean;
    opacity: number;
    x: number; // needed by force node (cola or d3 simulator)
    y: number;
    color?: string;
    imageId?: string;
    tooltipComponent?: ITooltipComponentDefinition;
    radius?: number;
    width?: number;
    height?: number;
    imageUrl?: string;
}

export interface IGraphLink {
    type: CatchupRatingType;
    catchupId?: number;
    rating?: SpeedCatchupRating;
    source: IGraphNode | number;
    target: IGraphNode | number;
    color?: CatchupRatingValue;
    opacity: number;
    recordPerson1Id?: number;
    recordPerson2Id?: number;
    teamId?: number; // wlll be used when recording new catchup when clicking on the link or from the tooltipComponent
    strokeWidth?: number;
    personId?: number;
    personImageId?: string;
    personInitials?: string;
    personImageUrl?: string;
    personTooltipComponent?: ITooltipComponentDefinition;
    ratingCreationDate?: Date;
    noEndMarker?: boolean;
    circleEnd?: boolean;
    arrowEnd?: boolean;
    noLine?: boolean;
    tooltipComponent?: ITooltipComponentDefinition;
    endpointTooltipComponent?: ITooltipComponentDefinition;
    pseudo?: boolean; // pseudo link indicates will exclude it from drawing link marker-end (arrows or balls)
    isMouseOver?: boolean;
}

// this will be with component with nasty integration with external none-type interface such as cola, d3 and svg
@Component({
    selector: "adapt-linked-nodes-graph",
    template:
        `<div class="tooltip pcu-tooltip pcu-tooltip-network card" #tooltipParent>
            <div class="card-body">
                <ng-container #tooltipContainer></ng-container>
            </div>
        </div>`,
    styleUrls: ["./linked-nodes-graph.component.scss"],
})
export class LinkedNodesGraphComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() public nodes: IGraphNode[] = [];
    @Input() public links: IGraphLink[] = [];
    @Input() public showName = false;
    @Input() public showArrowHead = false;
    @Input() public useCircleArrowHead = false;
    @Input() public hideLinks = false;
    @Input() public allowNodeSelection = false;
    @Input() public showRed = true;
    @Input() public showOrange = true;
    @Input() public showGreen = true;
    @Output() public nodeSelected = new EventEmitter<IGraphNode>();
    @Output() public linkSelected = new EventEmitter<IGraphLink>();

    @ViewChild("tooltipParent", { read: ViewContainerRef }) public tooltipParent!: ViewContainerRef;
    @ViewChild("tooltipContainer", { read: ViewContainerRef }) public tooltipContainer!: ViewContainerRef;

    public svg?: d3.Selection<SVGSVGElement, unknown, null, undefined>;
    public tooltip?: d3.Selection<HTMLDivElement, unknown, null, undefined>;
    public isTouch = this.isTouchDevice();
    public viewWidth = 0;
    public viewHeight = 0;
    public force?: Layout & ID3StyleLayoutAdaptor;
    public leftMost = 0;
    public rightMost = 0;
    public topMost = 0;
    public bottomMost = 0;
    public originalViewHeight = 0;
    public viewScaling = 1;
    public actualLeftMost = 0;
    public actualTopMost = 0;
    public actualWidth = 0;
    public actualHeight = 0;

    private arrowEndSelector?: d3.Selection<SVGPolygonElement, any, SVGSVGElement, unknown>;
    private circleEndSelector?: d3.Selection<SVGCircleElement, any, SVGSVGElement, unknown>;
    private hiddenLinkCircleSelector?: d3.Selection<SVGCircleElement, unknown, SVGSVGElement, unknown>;
    private hiddenLinkArrowSelector?: d3.Selection<SVGPolygonElement, unknown, SVGSVGElement, unknown>;
    private linkFacilitatorSelector?: d3.Selection<d3.BaseType, IGraphLink, SVGGElement, IGraphLink>;

    private firstFitToViewCalled = false;
    private callFitToView = new Subject<void>();
    private hidingTooltip?: Subscription;
    private showingTooltip?: Subscription;

    private svgMouseDownLocation?: { x: number, y: number };
    private isNodeMouseDown = false;

    public constructor(
        elementRef: ElementRef,
        private storageImageService: StorageImageService,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
        super(elementRef);

        this.callFitToView.pipe(
            tap(() => {
                if (!this.firstFitToViewCalled) {
                    // don't have to wait till it stabilize to see the full picture.
                    this.fitToView();
                }
            }),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            // this debounce pipe will ensure that fitToView is only called once after a redraw
            this.fitToView();
            this.firstFitToViewCalled = true;
        });
    }

    // this will be called externally by catchup-relationships-graph
    public invokeFitToView() {
        this.callFitToView.next();
    }

    public zoom(factor: number) {
        const widthBefore = this.actualWidth;
        const heightBefore = this.actualHeight;

        this.viewScaling *= factor;
        this.actualWidth *= factor;
        this.actualHeight *= factor;
        this.actualLeftMost -= (this.actualWidth - widthBefore) / 2;
        this.actualTopMost -= (this.actualHeight - heightBefore) / 2;

        this.svg?.attr("viewBox", `${this.actualLeftMost} ${this.actualTopMost} ${this.actualWidth} ${this.actualHeight}`);
    }

    public ngAfterViewInit() {
        if (this.tooltipParent) {
            this.tooltip = d3.select(this.tooltipParent.element.nativeElement)
                .style("min-width", "360px") // realised the buttons in the bottom panel wrapped in IE as Laura enlarged the font-size for <span>
                .style("max-width", "420px")
                .style("display", "none");
            this.tooltip.on("mouseout", () => this.hideTooltip(500));
            this.tooltip.on("mouseover", () => this.cancelHideTooltip());
            this.tooltip.on("click", () => this.hideTooltip());
        }
    }

    public ngOnInit() {
        this.initSvg();
        this.updateData();
        this.isInitialised = true;
    }

    public ngOnChanges() {
        if (this.isInitialised) {
            this.updateData();
        }
    }

    private updateData() {
        this.clearGraphData();
        this.updateViewDimensions();
        this.force?.stop();
        this.force = undefined;

        if (this.nodes.length < 1) {
            const textObj = this.svg!.append("text")
                .attr("dx", 20)
                .attr("dy", 30)
                .text("No data to display")
                .style("font-size", "16px")
                .style("pointer-events", "none") as any;
            const boundingRect = textObj[0][0].getBoundingClientRect();

            this.leftMost = 0;
            this.topMost = 0;
            this.rightMost = boundingRect.width + 20;
            this.bottomMost = boundingRect.height + 20;
            return;
        }

        // set node radius
        this.nodes.forEach((node) => {
            node.radius = node.selected ? GraphConstants.SelectedRadius : GraphConstants.NodeRadius;
            node.width = node.radius * 2;
            node.height = node.width;
        });
        // update node & link image urls
        this.nodes.forEach((node) => this.updateNodeImageUrl(node));
        this.links.forEach((link) => this.updateLinkPersonImageUrl(link));

        // perform draw
        this.firstFitToViewCalled = false;
        this.svg!.datum({ nodes: this.nodes, links: this.links }).each((data) => {
            let linkDistance = GraphConstants.LinkDistance;
            data.links = data.links.filter((link) => this.showLinkByRatingFilter(link));
            const linkCount = data.links.filter((link, i) => { // exclude all links within the same pair of nodes
                let hasSimilar = false;
                for (let j = i + 1; j < data.links.length; j++) {
                    const otherLink = data.links[j];
                    if ((link.source === otherLink.source && link.target === otherLink.target) ||
                        (link.source === otherLink.target && link.target === otherLink.source)) {
                        hasSimilar = true;
                        break;
                    }
                }

                return !hasSimilar;
            }).length;

            data.nodes = data.nodes.filter((node) => this.showNodeWithLinks(node, data.links));
            const nodeCount = data.nodes.length ?? 1;
            // clone links to replace source and target with nodes
            data.links = data.links.map((l) => {
                const clone = { ...l };
                clone.source = data.nodes.find((n) => n.id === l.source)!;
                clone.target = data.nodes.find((n) => n.id === l.target)!;
                return clone;
            });

            if (linkCount > nodeCount) {
                linkDistance += linkCount / nodeCount * GraphConstants.NodeRadius;
            }

            const linkGroup = this.svg!.selectAll(".link")
                .data(data.links)
                .enter()
                .append("g")
                .attr("class", SVGUtils.getLinkClass);
            const linkSelector = linkGroup
                .append(() => window.document.createElementNS("http://www.w3.org/2000/svg", "line"));
            const nodeSelector = this.svg!.selectAll(".node")
                .data(data.nodes)
                .enter()
                .append("g"); // node is a group with circle, text and image
            this.arrowEndSelector = this.svg!.selectAll(".arrowEnd")
                .append("polygon");
            this.circleEndSelector = this.svg!.selectAll(".circleEnd")
                .append("g")
                .append("circle");
            this.implementLinks(linkSelector);
            this.implementNodes(nodeSelector);
            this.implementLinkFacilitator(linkGroup);

            this.force = d3adaptor()
                .nodes(data.nodes)
                .links(data.links)
                .jaccardLinkLengths(linkDistance, 0.7)
                .size([this.viewWidth, this.viewHeight])
                .avoidOverlaps(true)
                .on("tick", () => this.tick(linkSelector, nodeSelector))
                .start(linkCount + nodeCount, 1, linkCount + nodeCount);
            nodeSelector.call(this.force.drag);
        });
    }

    private showLinkByRatingFilter(link: IGraphLink) {
        if (!this.showGreen && !this.showOrange && !this.showRed) {
            // if none of the check boxes are ticked -> no filter -> show all
            return true;
        }

        switch (link.color) {
            case CatchupRatingValue.Green:
                return this.showGreen;
            case CatchupRatingValue.Orange:
                return this.showOrange;
            case CatchupRatingValue.Red:
                return this.showRed;
            default:
                break;
        }

        return true;
    }

    private showNodeWithLinks(node: IGraphNode, links: IGraphLink[]) {
        const linkedNodeIds = links.map((l) => l.source as number)
            .concat(links.map((l) => l.target as number));
        return !!linkedNodeIds.find((id) => id === node.id);
    }

    // update whatever is going to be changed per tick - i.e. positions - do not include appending images etc.
    private tick(
        linkSelector: d3.Selection<SVGLineElement, IGraphLink, SVGSVGElement, unknown>,
        nodeSelector: d3.Selection<SVGGElement, IGraphNode, SVGSVGElement, unknown>,
    ) {
        this.leftMost = 0;
        this.rightMost = 0;
        this.topMost = 0;
        this.bottomMost = 0;

        const offset = this.getOffset();
        linkSelector.attr("x1", (link) => SVGUtils.getSourceCoordinates(link, offset).x);
        linkSelector.attr("y1", (link) => SVGUtils.getSourceCoordinates(link, offset).y);
        linkSelector.attr("x2", (link) => SVGUtils.getTargetCoordinates(link, offset).x);
        linkSelector.attr("y2", (link) => SVGUtils.getTargetCoordinates(link, offset).y);

        nodeSelector
            .attr("cx", (node) => this.getNodeX(node))
            .attr("cx", (node) => this.getNodeY(node))
            .attr("transform", SVGUtils.translateNode);

        // need to set the position of link facilitator in tick so that the image circle is following the links
        if (this.linkFacilitatorSelector?.size()) {
            this.linkFacilitatorSelector?.attr("transform", (link: IGraphLink) => {
                const x = ((link.source as IGraphNode).x + (link.target as IGraphNode).x) / 2;
                const y = ((link.source as IGraphNode).y + (link.target as IGraphNode).y) / 2;
                return `translate(${x},${y})`;
            });
        }

        if (this.hiddenLinkCircleSelector?.size()) {
            this.hiddenLinkCircleSelector
                .attr("cx", SVGUtils.getHiddenLinkCircleX)
                .attr("cy", SVGUtils.getHiddenLinkCircleY);
        }

        if (this.hiddenLinkArrowSelector?.size()) {
            this.hiddenLinkArrowSelector.attr("points", (link: IGraphLink) => SVGUtils.getArrowEndPointsWithOffset(link, 0));
        }

        if (this.circleEndSelector?.size()) {
            this.circleEndSelector
                .attr("cx", SVGUtils.getHiddenLinkCircleX)
                .attr("cy", SVGUtils.getHiddenLinkCircleY);
        }

        if (this.arrowEndSelector?.size()) {
            this.arrowEndSelector.attr("points", (link: IGraphLink) => SVGUtils.getArrowEndPointsWithOffset(link, GraphConstants.HiddenLinkCircleRadius * 2));
        }

        if (!this.firstFitToViewCalled) {
            this.callFitToView.next();
        }
    }

    private implementNodes(nodeSelector: d3.Selection<SVGGElement, IGraphNode, SVGSVGElement, unknown>) {
        nodeSelector.attr("class", "node");
        nodeSelector.append("circle")
            .attr("r", (node) => node.radius ?? GraphConstants.NodeRadius)
            .attr("cx", 0)
            .attr("cy", 0)
            .style("fill", SVGUtils.getNodeColor);
        nodeSelector.append("image")
            .attr("xlink:href", (node) => node.imageUrl ?? null)
            .attr("x", SVGUtils.getImageOffset)
            .attr("y", SVGUtils.getImageOffset)
            .attr("opacity", (node) => node.opacity ?? 1.0)
            .attr("height", (node) => SVGUtils.getNodeRadius(node) * 2)
            .attr("width", (node) => SVGUtils.getNodeRadius(node) * 2)
            .attr("clip-path", SVGUtils.getClipPath)
            .style("pointer-events", "none");

        // draw the person initials if we need to (for default profile image)
        nodeSelector.append("text")
            .text((node) => node.personInitials)
            .attr("text-anchor", "middle")
            .attr("dy", ".35em")
            .style("font-size", "24px")
            .style("fill", "white")
            .style("display", (node) => node.imageUrl === PersonImageComponent.DefaultProfileImageUrl ? null : "none")
            .style("pointer-events", "none");

        if (this.showName) {
            nodeSelector.append("text")
                .attr("dx", (node) => SVGUtils.getNodeRadius(node) + 2)
                .attr("dy", ".35em")
                .text((node) => node.fullName)
                .style("font-size", "10px")
                .style("pointer-events", "none");
        }

        if (this.isTouch) {
            nodeSelector.on("click", (node) => this.onNodeMouseOver(node));
        } else {
            nodeSelector.on("mouseover", (node) => this.onNodeMouseOver(node));
            nodeSelector.on("mousedown", (node) => {
                this.hideTooltip();
                this.isNodeMouseDown = true;
                node.mouseDownLocation = SVGUtils.getD3EventOffset();
            });
            nodeSelector.on("mouseup", () => this.isNodeMouseDown = false);
            nodeSelector.on("mouseout", () => {
                this.isNodeMouseDown = false;
                this.svg?.attr("cursor", "default");
                this.hideTooltip(2000);
            });
            if (this.allowNodeSelection) {
                nodeSelector.on("click", (node) => {
                    this.isNodeMouseDown = false;
                    const offset = SVGUtils.getD3EventOffset();
                    if (SVGUtils.distance(offset, node.mouseDownLocation) <= 1) {
                        this.nodeSelected.emit(node);
                    }
                });
            }
        }
    }

    private implementLinks(linkSelector: d3.Selection<SVGLineElement, IGraphLink, SVGSVGElement, unknown>) {
        linkSelector
            .style("fill", "none")
            .style("stroke-opacity", (link) => this.getLinkOpacity(link))
            .style("stroke-width", SVGUtils.getStrokeWidth);
        if (this.hideLinks) {
            linkSelector.style("stroke", GraphConstants.DefaultNodeColor);

            let arrowHead: d3.Selection<SVGElement, unknown, SVGSVGElement, unknown>;
            if (this.useCircleArrowHead) {
                this.hiddenLinkArrowSelector = undefined;
                this.hiddenLinkCircleSelector = this.svg!.selectAll(".actualLink")
                    .append("g")
                    .append("circle")
                    .attr("r", GraphConstants.HiddenLinkCircleRadius);
                arrowHead = this.hiddenLinkCircleSelector;
            } else {
                this.hiddenLinkCircleSelector = undefined;
                this.hiddenLinkArrowSelector = this.svg!.selectAll(".actualLink")
                    .append("polygon");
                arrowHead = this.hiddenLinkArrowSelector;
            }

            arrowHead
                .style("fill-opacity", SVGUtils.getLinkOpacity)
                .style("fill", SVGUtils.getStrokeColor);
            this.addLinkTipMouseEventHandler(arrowHead);

            // need to add tooltip for pseudoLink too - this won't be changing the original linkSelector; just create a new pseudoLinks selector
            const pseudoLinks = linkSelector.data(this.svg!.selectAll(".pseudoLink").data());
            this.addLinkTipMouseEventHandler(pseudoLinks);
        } else {
            this.hiddenLinkArrowSelector = undefined;
            this.hiddenLinkCircleSelector = undefined;
            linkSelector.style("stroke", SVGUtils.getStrokeColor);
            this.addLinkTipMouseEventHandler(linkSelector);
        }

        if (this.showArrowHead && !this.hideLinks) {
            linkSelector.attr("marker-end", (link) => this.getArrowMarker(link));
        }

        this.arrowEndSelector?.style("fill-opacity", (link) => link.opacity ?? 1.0)
            .style("fill", SVGUtils.getStrokeColor);
        this.circleEndSelector
            ?.attr("r", GraphConstants.HiddenLinkCircleRadius)
            ?.style("fill-opacity", SVGUtils.getLinkOpacity)
            ?.style("fill", SVGUtils.getStrokeColor);
        if (this.arrowEndSelector?.size()) {
            this.addLinkTipMouseEventHandler(this.arrowEndSelector);
        }

        if (this.circleEndSelector?.size()) {
            this.addLinkTipMouseEventHandler(this.circleEndSelector);
        }
    }

    private addLinkTipMouseEventHandler(tipSelector: d3.Selection<SVGElement, unknown, SVGSVGElement, unknown>) {
        tipSelector
            .on("mouseover", (link) => {
                this.svg?.attr("cursor", "pointer");
                SVGUtils.highlightLinkTipMouseOver(link, tipSelector);
                this.showRatingInTooltip(link);
            })
            .on("mouseout", (link) => {
                this.svg?.attr("cursor", "default");
                SVGUtils.resetLinkTipMouseOver(link, tipSelector);
                this.hideTooltip(2000);
            })
            .on("click", (link) => {
                this.svg?.attr("cursor", "default");
                this.hideTooltip();
                this.linkSelected.emit(link);
            });
    }

    private implementLinkFacilitator(linkGroup: d3.Selection<SVGGElement, IGraphLink, SVGSVGElement, unknown>) {
        // d3 selection won't let me selectively create circle only for link with personImageId
        // so create with different class name based on that condition and remove
        // the non-appropriate selection with the 'remove' class
        linkGroup.append("g")
            .attr("class", (link) => link.personImageUrl ? "linkFacilitator" : "removeLinkFacilitator");
        linkGroup.selectAll(".removeLinkFacilitator").remove();
        this.linkFacilitatorSelector = linkGroup.selectAll(".linkFacilitator");

        this.linkFacilitatorSelector
            .append("circle")
            .attr("r", GraphConstants.LinkImageRadius)
            .attr("cx", 0)
            .attr("cy", 0)
            .style("fill", GraphConstants.DefaultNodeColor);
        this.linkFacilitatorSelector
            .append("image")
            .attr("xlink:href", (link: IGraphLink) => link.personImageUrl!)
            .attr("x", GraphConstants.LinkImageRadius * -1)
            .attr("y", GraphConstants.LinkImageRadius * -1)
            .attr("height", GraphConstants.LinkImageRadius * 2)
            .attr("width", GraphConstants.LinkImageRadius * 2)
            .attr("clip-path", SVGUtils.getClipPathForRadius(GraphConstants.LinkImageRadius))
            .style("pointer-events", "none"); // image not going to hog pointer events

        // draw the person initials if we need to (for default profile image)
        this.linkFacilitatorSelector.append("text")
            .text((link) => link.personInitials ?? "")
            .attr("text-anchor", "middle")
            .attr("dy", ".35em")
            .style("font-size", "12px")
            .style("fill", "white")
            .style("display", (link) => link.personImageUrl === PersonImageComponent.DefaultProfileImageUrl ? null : "none")
            .style("pointer-events", "none");

        if (this.isTouch) {
            this.linkFacilitatorSelector.on("click", (link) => this.onFacilitatorMouseOver(link));
        } else {
            this.linkFacilitatorSelector.on("mouseover", (link) => this.onFacilitatorMouseOver(link));
            this.linkFacilitatorSelector.on("mouseout", () => this.hideTooltip(500));
        }
    }

    private showRatingInTooltip(link: IGraphLink) {
        if (!this.tooltip || !this.tooltipContainer || (!link.endpointTooltipComponent && !link.tooltipComponent)) {
            return;
        }

        if (link.endpointTooltipComponent) {
            this.createTooltipComponent(link.endpointTooltipComponent, link);
        } else {
            this.createTooltipComponent(link.tooltipComponent!, link);
        }

        this.showTooltip();
    }

    // private showCatchupInTooltip(link: IGraphLink) {
    //     if (!this.tooltip || !this.tooltipContainer || !link.catchupId || !link.tooltipComponent) {
    //         return;
    //     }

    //     this.createTooltipComponent(link.tooltipComponent, undefined, link.catchupId);
    //     this.showTooltip();
    // }

    private onFacilitatorMouseOver(link: IGraphLink) {
        if (!this.tooltip || !this.tooltipContainer || !link.personTooltipComponent || !link.personId) {
            return;
        }

        this.createTooltipComponent(link.personTooltipComponent, link);
        this.showTooltip();
    }

    private onNodeMouseOver(node: IGraphNode) {
        if (!this.tooltip || !this.tooltipContainer || !node.tooltipComponent) {
            return;
        }

        if (this.nodeSelected.observers.length > 0 && this.allowNodeSelection) {
            // someone is listening to the node selection -> show pointer cursor
            this.svg?.attr("cursor", "pointer");
        }

        this.createTooltipComponent(node.tooltipComponent, undefined, node);
        this.showTooltip();
    }

    // to be used for link, facilitator and node tooltip
    private createTooltipComponent(componentDef: ITooltipComponentDefinition, link?: IGraphLink, node?: IGraphNode) {
        this.hideTooltip();
        const factory = this.componentFactoryResolver.resolveComponentFactory(componentDef.componentType);
        const componentRef = this.tooltipContainer.createComponent(factory);
        if (componentDef.linkInputMap && link) {
            const keys = Object.keys(componentDef.linkInputMap);
            for (const key of keys) {
                componentRef.instance[key] = link[componentDef.linkInputMap[key]];
            }
        }

        if (componentDef.nodeInputMap && node) {
            const keys = Object.keys(componentDef.nodeInputMap);
            for (const key of keys) {
                componentRef.instance[key] = node[componentDef.nodeInputMap[key]];
            }
        }
    }

    private showTooltip() {
        if (!this.tooltip) {
            return;
        }

        // cancel the previous delay showing/hiding
        this.showingTooltip?.unsubscribe();
        this.showingTooltip = undefined;
        this.cancelHideTooltip();

        // this most probably won't work for d7 - needed for v3
        const event = (d3 as any).event as MouseEvent;
        const position = {
            x: event.pageX,
            y: event.pageY,
        };

        let tooltipTop = position.y - 100;
        const tooltipHeight = parseInt(this.tooltip.style("height"), 10);

        if (tooltipHeight && tooltipTop + tooltipHeight > this.viewHeight) {
            tooltipTop = this.viewHeight - tooltipHeight;
        }

        this.tooltip
            .style("position", "fixed")
            .style("left", position.x + 20 + "px")
            .style("top", tooltipTop + 20 + "px");

        this.showingTooltip = of(undefined).pipe(
            delay(1000), // only show tooltip 1 second later - just like title (which was 2 seconds?)
        ).subscribe(() => this.tooltip?.style("display", "block"));
    }

    private cancelHideTooltip() {
        this.hidingTooltip?.unsubscribe();
        this.hidingTooltip = undefined;
    }

    private hideTooltip(delayMsec = 0) {
        // if showing tooltip but not shown yet, don't show it anymore
        this.showingTooltip?.unsubscribe();
        this.showingTooltip = undefined;
        this.cancelHideTooltip();
        if (delayMsec) {
            this.hidingTooltip = of(undefined).pipe(
                delay(delayMsec),
            ).subscribe(() => {
                this.tooltip?.style("display", "none");
                this.tooltipContainer.clear();
            });
        } else {
            this.tooltip?.style("display", "none");
            this.tooltipContainer.clear();
        }
    }

    private getArrowMarker(linkData: IGraphLink) {
        if (linkData.pseudo || linkData.noEndMarker || linkData.arrowEnd || linkData.circleEnd) {
            return null; // won't be any marker-end
        } else {
            return `url(${window.location.href}${this.useCircleArrowHead ? "#straightMarkerCircle"
                : "#straightMarkerArrow"}${(linkData.target as IGraphNode).radius}${linkData.color})`;
        }
    }

    private getNodeX(node: IGraphNode) {
        // don't have to worry about repositioning overlapping nodes anymore
        if (!this.leftMost || this.leftMost > node.x - node.radius!) {
            this.leftMost = node.x - node.radius!;
        }

        if (!this.rightMost || this.rightMost < node.x + node.radius!) {
            this.rightMost = node.x + node.radius!;
        }

        return node.x;
    }

    private getNodeY(node: IGraphNode) {
        if (!this.topMost || this.topMost > node.y - node.radius!) {
            this.topMost = node.y - node.radius!;
        }

        if (!this.bottomMost || this.bottomMost < node.y + node.radius!) {
            this.bottomMost = node.y + node.radius!;
        }

        return node.y;
    }

    private getOffset() {
        let offset = 0;
        if (this.circleEndSelector?.size() || this.hiddenLinkCircleSelector?.size()) {
            offset += GraphConstants.HiddenLinkCircleRadius * 2;
        }

        if (this.arrowEndSelector?.size() || this.hiddenLinkArrowSelector?.size()) {
            offset += GraphConstants.HiddenLinkArrowHeight;
        }

        return offset;
    }

    private initSvg() {
        d3.select(this.elementRef!.nativeElement)
            .selectAll("svg")
            .remove();
        this.svg = d3.select(this.elementRef!.nativeElement)
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("width", "100%")
            .attr("height", window.innerHeight - 180)
            .style("display", "block"); // this appears to have fixed the viewBox refresh problem on safari
        this.svg.on("click", () => this.onSvgClick());
        this.svg.on("mousedown", () => this.startSvgDrag());
        this.svg.on("mousemove", () => this.performSvgDrag());
        this.svg.on("mouseup", () => this.cancelSvgDrag());
        this.svg.on("mouseout", () => this.cancelSvgDrag());

        const defaultDefs = this.svg.append("svg:defs");
        SVGUtils.initMarkerArrows(defaultDefs);
        SVGUtils.initClipPaths(defaultDefs);
    }

    private performSvgDrag() {
        if (this.svgMouseDownLocation) {
            const currentCursor = SVGUtils.getD3EventOffset();
            const offset = {
                x: (currentCursor.x - this.svgMouseDownLocation.x) * this.viewScaling,
                y: (currentCursor.y - this.svgMouseDownLocation.y) * this.viewScaling,
            };

            this.actualLeftMost -= offset.x;
            this.actualTopMost -= offset.y;
            this.svg?.attr("viewBox", `${this.actualLeftMost} ${this.actualTopMost} ${this.actualWidth} ${this.actualHeight}`);
            this.svgMouseDownLocation = currentCursor;
        }
    }

    private startSvgDrag() {
        const event = (d3 as any).event as MouseEvent;
        if (this.isNodeMouseDown || event.button !== 0) {
            // only drag with left button if not dragging node
            this.svgMouseDownLocation = undefined;
            return;
        }

        this.svgMouseDownLocation = SVGUtils.getD3EventOffset();
        this.svg?.attr("cursor", "move");
    }

    private cancelSvgDrag() {
        this.svg?.attr("cursor", "default");
        this.svgMouseDownLocation = undefined;
    }

    private fitToView() {
        // updating viewbox to focus on the non-empty part
        let leftMost = this.leftMost - GraphConstants.NodeRadius;
        let topMost = this.topMost - GraphConstants.NodeRadius;
        let width = this.rightMost - this.leftMost + 2 * GraphConstants.NodeRadius;
        let height = this.bottomMost - this.topMost + 2 * GraphConstants.NodeRadius;

        if (!this.viewHeight || !this.viewWidth) {
            return;
        }

        // set minimum size for the graph - make sure it is not too small
        if (width < GraphConstants.MinWidth) {
            leftMost -= (GraphConstants.MinWidth - width) / 2;
            width = GraphConstants.MinWidth;
        }

        if (height < GraphConstants.MinHeight) {
            topMost -= (GraphConstants.MinHeight - height) / 2;
            height = GraphConstants.MinHeight;
        }

        if (this.viewHeight < GraphConstants.MinHeight) {
            // svg height is less than min height, extend SVG height
            this.updateSvgHeight(GraphConstants.MinHeight);
        } else {
            // still need to set svg height
            this.updateSvgHeight(this.viewHeight);
        }

        if (this.originalViewHeight
            && this.viewHeight !== this.originalViewHeight) {
            // Previously this view has been reduced before
            // -> restore view size so that we can fully use back the size user defined for
            // the directive.
            this.updateSvgHeight(this.originalViewHeight);
        }

        const verticalScaling = height / this.viewHeight;
        const horizontalScaling = width / this.viewWidth;

        // svg maintain aspect ratio - using the larger of the 2 above.
        this.viewScaling = verticalScaling > horizontalScaling
            ? verticalScaling
            : horizontalScaling;

        // do not allow scaling of < 0.8 (still too much white spaces with limit of 1)
        if (this.viewScaling < GraphConstants.MinScaling) {
            const scaleBackUp = GraphConstants.MinScaling / this.viewScaling;

            width *= scaleBackUp;
            height *= scaleBackUp;
            this.viewScaling = GraphConstants.MinScaling;

            if (!this.originalViewHeight) {
                this.originalViewHeight = this.viewHeight;
            }

            // In this case, the actual height used is less than defined size
            // -> scale down to reduce white spaces
            this.updateSvgHeight(height / GraphConstants.MinScaling);
        }

        if (width < this.viewWidth) {
            // move leftMost to center horizontally
            const diff = ((this.viewWidth - width) / 2) - (GraphConstants.NodeRadius * 2); // eslint-disable-line no-extra-parens
            if (diff > 0) {
                leftMost -= diff;
            }
        }

        this.svg!.attr("viewBox", `${leftMost} ${topMost} ${width} ${height}`);
        this.actualLeftMost = leftMost;
        this.actualTopMost = topMost;
        this.actualWidth = width;
        this.actualHeight = height;
    }

    private updateSvgHeight(newHeight: number) {
        this.viewHeight = newHeight;
        this.svg!.style("height", newHeight);
        this.svg!.attr("height", newHeight);
    }

    private updateViewDimensions() {
        this.viewWidth = parseInt(this.svg!.style("width"), 10);
        this.viewHeight = parseInt(this.svg!.style("height"), 10);
    }

    private clearGraphData() {
        if (this.svg) {
            this.svg.selectAll("line").remove();
            this.svg.selectAll("g").remove();
        }
    }

    private onSvgClick() {
        if (!this.isTouch) {
            this.hideTooltip();
        }
    }

    private isTouchDevice() {
        return (!!window.ontouchstart // works on most browsers
            || !!window.navigator.maxTouchPoints)
            && window.navigator.userAgent.indexOf("Windows") < 0;
    }

    private updateNodeImageUrl(node: IGraphNode) {
        if (!node.imageUrl) {
            node.imageUrl = node.imageId
                ? this.storageImageService.getRetrieveImageUri(node.imageId)
                : PersonImageComponent.DefaultProfileImageUrl;
        }
    }

    private updateLinkPersonImageUrl(link: IGraphLink) {
        if (!link.personImageUrl && link.personId) {
            link.personImageUrl = link.personImageId
                ? this.storageImageService.getRetrieveImageUri(link.personImageId)
                : PersonImageComponent.DefaultProfileImageUrl;
        }
    }

    private getLinkOpacity(link: IGraphLink) {
        if (this.hideLinks) {
            return link.pseudo ? 0.2 : 0.4;
        } else if (link.opacity) {
            return link.opacity;
        } else {
            return 1.0;
        }
    }
}
