import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ValueStreamDashboardPageComponent } from "./value-stream-dashboard-page.component";

export const valueStreamDashboardPageRoute = new OrganisationPageRouteBuilder<{ valueStreamId: number }>()
    .usingNgComponent("adapt-value-stream-dashboard-page", ValueStreamDashboardPageComponent)
    .atOrganisationUrl("/architecture/valueStream/:valueStreamId")
    .verifyingAccess(Tier1ArchitectureAuthService.ReadValueStreamContents)
    .withTitle("Value Stream")
    .withSearchKeyword("Purpose")
    .withSearchKeyword("Market")
    .withSearchKeyword("Customer Stories")
    .withSearchKeyword("Offering")
    .withSearchKeyword("Products & Services")
    .withSearchKeyword("Point of Difference")
    .withSearchKeyword("Commercial Model")
    .build();
