import { Injectable, Injector } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { ResilientBusinessGoalBreezeModel } from "@common/ADAPT.Common.Model/organisation/resilient-business-goal";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ResilientBusinessGoalsService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getResilientBusinessGoals() {
        return this.commonDataService.getAll(ResilientBusinessGoalBreezeModel);
    }

    public getOrCreateResilientBusinessGoals() {
        return this.getResilientBusinessGoals().pipe(
            switchMap((goals) => goals && goals.length
                ? of(goals)
                : this.createResilientBusinessGoals()),
            map((goals) => goals.sort((a, b) => a.zone < b.zone ? -1 : 1)),
        );
    }

    private createResilientBusinessGoals() {
        return this.currentOrganisation$.pipe(
            switchMap((org) => forkJoin(ZoneMetadata.VisibleZones.map(
                (zone) => this.commonDataService.create(ResilientBusinessGoalBreezeModel, {
                    organisation: org,
                    zone,
                }),
            ))),
        );
    }
}
