import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";

@Component({
    selector: "adapt-select-connection-type",
    templateUrl: "./select-connection-type.component.html",
})
export class SelectConnectionTypeComponent {

    @Input() public connectionType?: ConnectionType;
    @Output() public connectionTypeChange = new EventEmitter<ConnectionType>();

    public connectionTypes = [ConnectionType.Employee, ConnectionType.Stakeholder];
}
