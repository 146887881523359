<div class="display-cultural-measurement-meta">
    <p>
        <adapt-entity-timestamp [timestamp]="culturalEntity.creationDate"
                                prefix="Entered on"
                                suffix="by"></adapt-entity-timestamp>
        <adapt-link-person [person]="culturalEntity.author"></adapt-link-person>
    </p>
    <div *ngIf="showCulturalLeaderData">
        <div [ngSwitch]="culturalLeaderRelationships.length">
            <p *ngSwitchCase="0">
                No {{configuration?.primaryName?.toLowerCase()}} was assigned at this time
            </p>
            <p *ngSwitchCase="1"
               data-test="cultural-leader">
                {{configuration?.primaryName}}:
                <adapt-link-person [person]="culturalLeaderRelationships[0].culturalLeader"></adapt-link-person>
            </p>
            <div *ngSwitchDefault>
                <p>
                    {{configuration?.primaryCollectionName}}:
                </p>
                <ul>
                    <li *ngFor="let relationship of culturalLeaderRelationships">
                        <adapt-link-person [person]="relationship.culturalLeader"></adapt-link-person>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
