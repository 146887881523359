<adapt-dashboard-element name="{{valueStream.name}}"
                         [url]="url$ | async">
    <div adapt-dashboard-title
         class="ms-auto">
        <adapt-display-role-allocation [role]="leaderRole$ | async"
                                       [showLabel]="false"
                                       unallocatedLabel="Leader is unallocated"></adapt-display-role-allocation>
    </div>

    <div class="value-stream-summary">
        <div class="market-offering">
            <div class="part">
                <h4>Market</h4>
                <div class="part-elements">
                    <adapt-display-market-description [valueStream]="valueStream"
                                                      [readOnly]="true"></adapt-display-market-description>
                    <adapt-display-customer-stories [valueStream]="valueStream"
                                                    [readOnly]="true"></adapt-display-customer-stories>
                </div>
            </div>
            <div class="part me-0">
                <h4>Offering</h4>
                <div class="part-elements">
                    <adapt-display-products-services [valueStream]="valueStream"
                                                     [readOnly]="true"></adapt-display-products-services>
                    <adapt-display-point-of-difference [valueStream]="valueStream"
                                                       [readOnly]="true"></adapt-display-point-of-difference>
                </div>
            </div>
        </div>

        <ng-container *ngIf="(keyFunctions$ | async) as keyFunctions">
            <ng-container *ngIf="keyFunctions.length > 0">
                <div class="key-functions-container">
                    <h4>Key Functions</h4>
                    <div class="key-functions">
                        <adapt-key-function-card *ngFor="let keyFunction of keyFunctions"
                                                 [keyFunction]="keyFunction"
                                                 class="bg-white"></adapt-key-function-card>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</adapt-dashboard-element>
