import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepGuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { DxSelectBoxComponent } from "devextreme-angular";
import { BehaviorSubject, lastValueFrom, Observable, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureService } from "../../integrated-architecture/integrated-architecture.service";
import { IntegratedArchitectureUiService } from "../../integrated-architecture/integrated-architecture-ui.service";
import { ProcessMapService } from "../process-map.service";

@Component({
    selector: "adapt-select-process-step-guidance-material",
    templateUrl: "./select-process-step-guidance-material.component.html",
})
export class SelectProcessStepGuidanceMaterialComponent extends BaseComponent {
    @Input()
    public set processStep(value: ProcessStep) {
        this.processStep$.next(value);
    }

    @Output() public selectionsChange = new EventEmitter<ProcessStepGuidanceMaterial[]>();

    public allGuidanceMaterials$: Observable<GuidanceMaterial[]>;
    public processStepGuidanceMaterials: ProcessStepGuidanceMaterial[] = [];
    public selectableGuidanceMaterials: GuidanceMaterial[] = [];

    @ViewChild(DxSelectBoxComponent) private guidanceMaterialSelector?: DxSelectBoxComponent;
    private processStep$ = new ReplaySubject<ProcessStep>(1);
    private lastProcessStep?: ProcessStep;
    private initialGuidanceMaterials?: GuidanceMaterial[];
    private deletedProcessStepGuidanceMaterials: ProcessStepGuidanceMaterial[] = [];
    private shouldPrimeGuidanceMaterials$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private commonDataService: CommonDataService,
        private processMapService: ProcessMapService,
        private integratedArchService: IntegratedArchitectureService,
        private integratedArchUiService: IntegratedArchitectureUiService,
    ) {
        super();

        this.allGuidanceMaterials$ = this.shouldPrimeGuidanceMaterials$.pipe(
            switchMap(() => this.integratedArchService.getGuidanceMaterials()),
            tap((allGuidanceMaterials) => this.updateSelectableGuidanceMaterials(allGuidanceMaterials)),
        );

        this.processStep$.pipe(
            tap((processStep) => this.lastProcessStep = processStep),
            switchMap((processStep) => this.processMapService.getProcessStepGuidanceMaterialsByProcessStepId(processStep.processStepId)),
            tap((processStepGuidanceMaterials) => {
                this.processStepGuidanceMaterials = processStepGuidanceMaterials;
                this.shouldPrimeGuidanceMaterials$.next();
            }),
            map((processStepGuidanceMaterials) => processStepGuidanceMaterials
                .filter((i) => !!i.guidanceMaterial)
                .map((i) => i.guidanceMaterial as GuidanceMaterial)), // safe to cast as previous filter got rid of null
            this.takeUntilDestroyed(),
        ).subscribe((guidanceMaterials) => this.initialGuidanceMaterials = guidanceMaterials);
    }

    public updateOrdinals(e: IDxListItemReorderedEvent<ProcessStepGuidanceMaterial>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.processStepGuidanceMaterials, "ordinal", e.fromIndex, e.toIndex);
        this.emitChanges();
    }

    public async removeProcessStepGuidanceMaterial(e: IDxListItemDeletedEvent<ProcessStepGuidanceMaterial>) {
        const processStepGuidanceMaterial = e.itemData!;
        if (this.initialGuidanceMaterials && this.initialGuidanceMaterials.indexOf(processStepGuidanceMaterial.guidanceMaterial!) >= 0) {
            await lastValueFrom(this.commonDataService.remove(processStepGuidanceMaterial));
            this.deletedProcessStepGuidanceMaterials.push(processStepGuidanceMaterial);
        } else {
            // newly added entity - just revert
            processStepGuidanceMaterial.entityAspect.rejectChanges();
        }

        ArrayUtilities.removeElementFromArray(processStepGuidanceMaterial, this.processStepGuidanceMaterials);
        SortUtilities.updateIntegerSortedArrayAfterItemRemoval(this.processStepGuidanceMaterials, "ordinal", processStepGuidanceMaterial.ordinal);
        this.shouldPrimeGuidanceMaterials$.next();
        this.emitChanges();
    }

    @Autobind
    public createAndAddGuidanceMaterial() {
        return this.integratedArchUiService.createGuidanceMaterial(true).pipe(
            tap((newGuidanceMaterial: GuidanceMaterial) => {
                this.addGuidanceMaterial(newGuidanceMaterial);
                this.shouldPrimeGuidanceMaterials$.next();
            }),
        );
    }

    public addGuidanceMaterial(guidanceMaterial?: GuidanceMaterial) {
        if (!guidanceMaterial || !this.lastProcessStep) {
            return;
        }

        const previouslyDeleted = this.deletedProcessStepGuidanceMaterials.find(
            (i) => i.guidanceMaterialId === guidanceMaterial.guidanceMaterialId);
        if (previouslyDeleted) {
            previouslyDeleted.entityAspect.rejectChanges();
            ArrayUtilities.removeElementFromArray(previouslyDeleted, this.deletedProcessStepGuidanceMaterials);
            this.processStepGuidanceMaterials.push(previouslyDeleted);
            this.emitChanges();
        } else {
            this.processMapService.createProcessStepGuidanceMaterial(
                this.lastProcessStep,
                guidanceMaterial,
                this.processStepGuidanceMaterials.length,
            ).subscribe((newProcessStepGuidanceMaterial) => {
                this.processStepGuidanceMaterials.push(newProcessStepGuidanceMaterial);
                this.emitChanges();
            });
        }

        this.guidanceMaterialSelector?.instance.reset();
        this.shouldPrimeGuidanceMaterials$.next();
    }

    private emitChanges() {
        this.selectionsChange.emit([...this.processStepGuidanceMaterials, ...this.deletedProcessStepGuidanceMaterials]);
    }

    private updateSelectableGuidanceMaterials(allGuidanceMaterials: GuidanceMaterial[]) {
        this.selectableGuidanceMaterials = allGuidanceMaterials.filter((i) => !this.processStepGuidanceMaterials?.find(
            (existing) => existing.guidanceMaterialId === i.guidanceMaterialId));
    }
}
