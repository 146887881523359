import { Component, Inject } from "@angular/core";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepTypeMetadata } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { Observable, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";

@Component({
    templateUrl: "./delete-process-step-dialog.component.html",
    styleUrls: ["./delete-process-step-dialog.component.scss"],
})
export class DeleteProcessStepDialogComponent extends BaseDialogComponent<ProcessStep> {
    public readonly dialogName = "DeleteProcessStep";

    public title: string;
    public childSteps$: Observable<ProcessStep[]>;
    public confirmed = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public processStep: ProcessStep,
        private commonDataService: CommonDataService,
        processMapService: ProcessMapService,
    ) {
        super();

        if (processStep.roleId && !processStep.role) {
            this.log.error("Allocated role should be primed before opening this dialog");
        }
        if (processStep.linkedProcessMapId && !processStep.linkedProcessMap) {
            this.log.error("Linked task map should be primed before opening this dialog");
        }

        this.title = `Delete ${ProcessStepTypeMetadata.Label[processStep.type]}`;
        this.childSteps$ = processMapService.getChildSteps(processStep);
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.remove(this.processStep).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.processStep)),
            tap(() => this.resolve(this.processStep)),
        );
    }
}
