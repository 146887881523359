import { Injectable } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap } from "rxjs/operators";
import { DeleteGuidanceMaterialDialogComponent } from "./delete-guidance-material-dialog/delete-guidance-material-dialog.component";
import { EditGuidanceMaterialDialogComponent } from "./edit-guidance-material-dialog/edit-guidance-material-dialog.component";
import { IntegratedArchitectureService } from "./integrated-architecture.service";
import { MergeGuidanceMaterialsDialogComponent } from "./merge-guidance-materials-dialog/merge-guidance-materials-dialog.component";
import { ShowGuidanceMaterialUsageDialogComponent } from "./show-guidance-material-usage-dialog/show-guidance-material-usage-dialog.component";

@Injectable({
    providedIn: "root",
})
export class IntegratedArchitectureUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private architectureService: IntegratedArchitectureService,
    ) { }

    public createGuidanceMaterial(returnWithoutSaving = false) {
        return this.architectureService.createGuidanceMaterial().pipe(
            switchMap((guidanceMaterial) => this.editGuidanceMaterial(guidanceMaterial, returnWithoutSaving)),
        );
    }

    public editGuidanceMaterial(guidanceMaterial: GuidanceMaterial, returnWithoutSaving = false) {
        return this.dialogService.open(EditGuidanceMaterialDialogComponent, { guidanceMaterial, returnWithoutSaving });
    }

    public mergeGuidanceMaterials(guidanceMaterials: GuidanceMaterial[]) {
        return this.dialogService.open(MergeGuidanceMaterialsDialogComponent, guidanceMaterials);
    }

    public viewGuidanceMaterialUsage(guidanceMaterial: GuidanceMaterial) {
        return this.dialogService.open(ShowGuidanceMaterialUsageDialogComponent, guidanceMaterial);
    }

    public showDeleteGuidanceMaterialDialog(guidanceMaterial: GuidanceMaterial) {
        return this.dialogService.open(DeleteGuidanceMaterialDialogComponent, guidanceMaterial);
    }
}
