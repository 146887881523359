import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { AdaptSystemisationModule } from "app/features/systemisation/systemisation.module";
import { DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptKeyFunctionsSharedModule } from "../key-functions/key-functions.shared.module";
import { AdaptProcessMapSharedModule } from "../process-map/process-map-shared.module";
import { AdaptValueStreamsSharedModule } from "../value-streams/value-streams-shared.module";
import { EditOrganisationMapDialogComponent } from "./edit-organisation-map-dialog/edit-organisation-map-dialog.component";
import { ListEntitiesInLocationComponent } from "./list-entities-in-location/list-entities-in-location.component";
import { OrganisationExplorerComponent } from "./organisation-explorer/organisation-explorer.component";
import { RenderOrganisationMapComponent } from "./render-organisation-map/render-organisation-map.component";
import { SelectMapEntityForLocationComponent } from "./select-map-entity-for-location/select-map-entity-for-location.component";
import { TeamCardComponent } from "./team-card/team-card.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptZoneMapModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        CommonModule,
        AdaptLoadingSpinnerModule,
        AdaptDirectorySharedModule,
        AdaptProcessMapSharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptSystemisationModule,
        AdaptLabellingModule,
        AdaptCallToActionModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        DxListModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        AdaptLinkDisabledModule,
        AdaptValueStreamsSharedModule,
        AdaptKeyFunctionsSharedModule,
    ],
    exports: [
        TeamCardComponent,
        RenderOrganisationMapComponent,
        ListEntitiesInLocationComponent,
        OrganisationExplorerComponent,
    ],
    declarations: [
        TeamCardComponent,
        RenderOrganisationMapComponent,
        ListEntitiesInLocationComponent,
        OrganisationExplorerComponent,
        EditOrganisationMapDialogComponent,
        SelectMapEntityForLocationComponent,
    ],
})
export class AdaptOrganisationMapModule { }
