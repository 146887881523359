import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IKeyFunctionLocation, IPrimedKeyFunctionLocation, KeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { lastValueFrom, ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { LocationItem, OrganisationMapService } from "../organisation-map.service";

@Component({
    selector: "adapt-select-map-entity-for-location",
    templateUrl: "./select-map-entity-for-location.component.html",
})
export class SelectMapEntityForLocationComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() public location?: IKeyFunctionLocation;
    @Output() public added = new EventEmitter<LocationItem>();

    public isKeyFunction = OrganisationMapService.getAsKeyFunction;
    public dataSource?: DataSource;

    private currentLocationItems: LocationItem[] = [];
    private location$ = new ReplaySubject<IKeyFunctionLocation>(1);

    public constructor(
        private keyFunctionsService: KeyFunctionsService,
        private teamsService: CommonTeamsService,
        private orgMapService: OrganisationMapService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();

        this.location$.pipe(
            switchMap((loc) => this.orgMapService.emitWhenLocationShouldBeFetched(loc)),
            switchMap((loc) => this.orgMapService.getLocationItems(loc)),
            tap((locItems) => this.currentLocationItems = locItems),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public ngOnChanges() {
        this.location$.next(this.location!);
    }

    public async ngOnInit() {
        await this.updateData();
    }

    @Autobind
    public filterItems(location: KeyFunction | Team) {
        if (this.location?.valueStreamId && location instanceof Team) {
            return false;
        }

        return !this.currentLocationItems.includes(location as LocationItem)
            && !this.currentLocationItems.find((loc) => loc instanceof TeamLocation && location instanceof Team && loc.teamId === location.teamId);
    }

    public async onSelectValueChange(event: ValueChangedEvent) {
        if (event.value && event.previousValue !== event.value && this.location) {
            console.log(event, this.location);

            if (event.value instanceof KeyFunction) {
                const currentLoc = KeyFunctionLocation.fromLocation(event.value);
                const newLoc = KeyFunctionLocation.fromLocation(this.location as IPrimedKeyFunctionLocation);

                const confirmed = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
                    title: "Confirm key function move",
                    message: `<p>Key functions may only be present within one organisation location.</p>
                        <p>The <b>"${event.value.name}"</b> key function will be moved from <b>${currentLoc.name}</b> to <b>${newLoc.name}</b>.</p>
                        <p>Are you sure you want to move the key function?</p>`,
                    confirmButtonPreset: "ok",
                }));

                if (confirmed) {
                    const locs = await this.orgMapService.promiseToMoveKeyFunction(event.value, this.location);
                    locs.forEach((loc) => this.added.emit(loc));
                }
            }

            if (event.value instanceof Team && this.location.zone) {
                const loc = await lastValueFrom(this.orgMapService.addTeamToZone(event.value, this.location.zone));
                this.added.emit(loc);
            }

            event.component.reset();
            await this.updateData();
        }
    }

    private async updateData() {
        const keyFunctions = await lastValueFrom(this.keyFunctionsService.getAllKeyFunctions());
        const teams = await this.teamsService.promiseToGetAllActiveTeams();

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: [...keyFunctions, ...teams],
                key: "",
            }),
            group: (item: LocationItem) => OrganisationMapService.getAsKeyFunction(item) ? "Key functions" : "Teams",
            filter: this.filterItems,
        });
    }
}
