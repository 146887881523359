import { Component } from "@angular/core";
import { OrganisationHierarchyNavigationService } from "app/platform/navigation/organisation-hierarchy-navigation.service";

@Component({
    selector: "adapt-organisation-tab-content",
    template: `
        <adapt-display-accordion-hierarchy [hierarchyId]="organisationHierarchyId"></adapt-display-accordion-hierarchy>
    `,
})
export class OrganisationTabContentComponent {
    public readonly organisationHierarchyId = OrganisationHierarchyNavigationService.HierarchyId;
}
