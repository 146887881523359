import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxSelectBoxModule } from "devextreme-angular";
import { SelectCommonTeamComponent } from "./select-common-team/select-common-team.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DxSelectBoxModule,
        AdaptTeamDashboardSharedModule,
    ],
    exports: [
        SelectCommonTeamComponent,
    ],
    declarations: [
        SelectCommonTeamComponent,
    ],
})
export class AdaptTeamsSharedModule { }
