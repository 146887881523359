<ng-container *ngIf="guidanceMaterial">
    <a [attr.href]="getAbsoluteUrl(guidanceMaterial.locationUrl)"
       target="_blank"
       rel="noreferrer noopener"
       *ngIf="guidanceMaterial.locationUrl; else showNameOnly">{{guidanceMaterial.name}}</a>
    <ng-template #showNameOnly>
        <span>{{guidanceMaterial.name}}</span>
    </ng-template>
    <div [froalaView]="guidanceMaterial.description"
         *ngIf="showDescription && guidanceMaterial.description"></div>
</ng-container>
