<div class="intro">
    <p>
        Export the contents of a task map as a series of tasks to be performed by individual people.
    </p>
    <p>
        When you export the task map, you are able to select the person who is fulfilling each role and is
        therefore accountable for performing the tasks associated with that role.
    </p>
    <p>
        This will assist people to connect HOW they do their tasks with actually DOing them.
    </p>
</div>

<div class="d-flex mt-3 row">
    <div class="align-self-center col-2">Export to</div>
    <div class="col-4">
        <dx-select-box class="align-self-center"
                       [dataSource]="exportSelections"
                       [(value)]="currentSelection"
                       (valueChange)="onSelectionChanged($any($event))"
                       data-test="process-map-export-type"></dx-select-box>
    </div>
</div>

<div class="mt-3 alert alert-info">
    <h3>{{currentSelection}}</h3>
    <p>{{selectionDescription}}</p>
</div>
