<div class="display-career-valuation-organisation-statistics"
     *adaptLoading="isLoading">
    <ng-container *ngIf="careerValuationsHaveBeenRecorded; else noDataTemplate">
        <div class="card">
            <div class="card-body">
                <div *ngIf="!filterService.isDefault"
                     class="float-end">
                    <i class="ms-auto align-self-start fal fa-fw fa-info-circle adapt-tooltip adapt-tooltip-enabled"
                       adaptTooltip="These numbers are based on the applied filters."></i>
                </div>

                <div class="active-people">
                    <span class="number"
                          data-test="active-people">{{statisticsData!.totalPeople}}</span>
                    people with career valuations
                </div>

                <div class="ps-2">
                    <span data-test="total-people">{{activePeopleCount}}</span> people in the organisation
                </div>

                <div class="missing-catchups-accordion pt-3">
                    <dx-accordion [dataSource]="[statisticsData!.missingCareerValuations]"
                                  *ngIf="hasAtLeastQuantitativePermissions && statisticsData?.missingCareerValuations?.length !== 0"
                                  [collapsible]="true"
                                  [selectedIndex]="-1"
                                  itemTitleTemplate="itemTitleTemplate"
                                  itemTemplate="itemTemplate">

                        <div *dxTemplate="let item of 'itemTitleTemplate'">
                            {{statisticsData?.missingCareerValuations!.length}} people without a career valuation
                        </div>

                        <div *dxTemplate="let item of 'itemTemplate'">
                            <dx-list [dataSource]="statisticsData!.missingCareerValuations"
                                     itemTemplate="item"
                                     [height]="240">
                                <div *dxTemplate="let person of 'item'">
                                    <adapt-link-person [person]="person"
                                                       [showImage]="true"></adapt-link-person>
                                </div>
                            </dx-list>
                        </div>
                    </dx-accordion>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Actual valuations</h2>
            </div>
            <div class="card-body">
                <adapt-cvt-organisation-actuals-chart [date]="date">
                </adapt-cvt-organisation-actuals-chart>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Average valuation</h2>
            </div>
            <div class="card-body">
                <adapt-analyse-cvt-average-gauge [value]="statisticsData!.averageCareerValuation">
                </adapt-analyse-cvt-average-gauge>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Ideal valuations</h2>
            </div>
            <div class="card-body">
                <dx-pie-chart (onInitialized)="onIdealsChartInitialized($event)"
                              [palette]="idealsPalette"
                              class="career-valuation-ideals-chart"
                              [dataSource]="statisticsData!.categoryStats"
                              [pathModified]="true"
                              type="doughnut">
                    <dxi-series argumentField="name"
                                valueField="averageIdeal"
                                tagField="id"></dxi-series>
                    <dxo-tooltip [enabled]="true">
                        <dxo-format type="fixedPoint"
                                    [precision]="1"></dxo-format>
                    </dxo-tooltip>
                    <dxo-legend [customizeText]="customizeIdealsChartLegendText"></dxo-legend>
                </dx-pie-chart>
            </div>
        </div>

        <div class="card"
             *ngIf="hasAtLeastQuantitativePermissions">
            <div class="card-header">
                <h2 class="card-title">Overdue catch-ups</h2>
            </div>
            <div class="card-body">
                <dx-pie-chart [dataSource]="statisticsData!.overdueCatchups"
                              [palette]="overdueCatchupsPalette"
                              (onInitialized)="onInitialized($event)">
                    <dxo-margin [left]="60"></dxo-margin>
                    <dxi-series argumentField="label"
                                valueField="value">
                        <dxo-label [visible]="true"
                                   textOverflow="none"
                                   position="outside">
                            <dxo-connector [visible]="true"
                                           [width]="1"></dxo-connector>
                        </dxo-label>
                    </dxi-series>
                    <dxo-legend [visible]="true"></dxo-legend>
                </dx-pie-chart>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Valuation distribution</h2>
            </div>
            <div class="card-body">
                <dx-polar-chart (onInitialized)="onDistributionChartInitialized($event)"
                                [customizePoint]="customiseDistributionChartPoint"
                                [dataSource]="valuationOrbitData"
                                class="career-valuation-totals-chart">
                    <dxo-common-series-settings type="scatter">
                    </dxo-common-series-settings>
                    <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                        <dxo-minor-tick [visible]="false"></dxo-minor-tick>
                        <dxo-tick [visible]="false"></dxo-tick>
                        <dxo-grid [visible]="false"></dxo-grid>
                        <dxo-minor-grid [visible]="false"></dxo-minor-grid>
                    </dxo-argument-axis>
                    <dxo-value-axis [visualRange]="{startValue: 0, endValue: 100}"
                                    [tickInterval]="20"
                                    [maxValueMargin]="0.001"
                                    visualRangeUpdateMode="keep">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxo-minor-tick [visible]="false"></dxo-minor-tick>
                        <dxo-tick [visible]="false"></dxo-tick>
                        <dxo-minor-grid [visible]="false"></dxo-minor-grid>
                    </dxo-value-axis>
                    <dxi-series argumentField="index"
                                valueField="value"
                                tagField="careerValuation">
                    </dxi-series>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-tooltip [enabled]="true"
                                 [customizeTooltip]="customiseDistributionTooltip"></dxo-tooltip>
                    <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>
                </dx-polar-chart>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <adapt-no-cultural-measurement label="Career Valuations"></adapt-no-cultural-measurement>
    </ng-template>
</div>
