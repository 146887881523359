import { Component, OnInit } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IAdaptLinkObject } from "@common/route/route.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { IKeyFunctionSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowLabelledBaseComponent } from "@org-common/lib/search/search-row-labelled-base.component";
import { keyFunctionPageRoute } from "app/features/architecture/key-functions/key-function-page/key-function-page.route";
import { EMPTY, Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-key-function",
    templateUrl: "./search-row-key-function.component.html",
})
export class SearchRowKeyFunctionComponent extends SearchRowLabelledBaseComponent<IKeyFunctionSearchResult> implements OnInit {
    public readonly ZoneMetadata = ZoneMetadata;

    public href$?: Observable<IAdaptLinkObject> = EMPTY;

    public constructor(
        protected searchService: SearchService,
        protected labellingService: LabellingService,
    ) {
        super(searchService, labellingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.href$ = keyFunctionPageRoute.getRouteObject({ keyFunctionId: this.result.keyFunctionId });
        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }

    public get zone() {
        return this.result.zone !== null ? this.result.zone : Zone.EconomicEngine;
    }

}
