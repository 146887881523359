import { Injectable, Injector } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { RouteService } from "@common/route/route.service";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { combineLatest, lastValueFrom, Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkService } from "./integrated-architecture-framework.service";
import { IMergeRolesDialogComponentData, MergeRolesDialogComponent } from "./merge-roles-dialog/merge-roles-dialog.component";
import { rolesPageRoute } from "./roles-page/roles-page.route";

@Injectable({
    providedIn: "root",
})
@Autobind
export class IntegratedArchitectureFrameworkUiService extends BaseService {
    private _refetchRequired = new Subject<void>();
    private _gridDimensionsUpdated = new Subject<void>();

    public get refetchRequired$() {
        return this._refetchRequired.asObservable();
    }

    public get gridDimensionsUpdated$() {
        return this._gridDimensionsUpdated.asObservable();
    }

    public constructor(
        injector: Injector,
        private commonDialogService: AdaptCommonDialogService,
        private routeService: RouteService,
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
        private keyFunctionsService: KeyFunctionsService,
    ) {
        super(injector);
    }

    public emitRefetchRequired() {
        this._refetchRequired.next();
    }

    public emitGridDimensionsUpdated() {
        this._gridDimensionsUpdated.next();
    }

    public async promiseToMergeRoles(roles: Role[]) {
        const dialogData: IMergeRolesDialogComponentData = {
            inputRoles: roles,
        };

        try {
            await lastValueFrom(this.integratedArchitectureFrameworkService.getAllRoleSupplementaryData());

            const role = await lastValueFrom(this.commonDialogService.open(MergeRolesDialogComponent, dialogData));
            // role will be undefined if merge has been cancelled
            if (role) {
                // this is modifying rows in the role people grid and broadcast of going to reload that grid
                // - do it in the next digest cycle to avoid reload while updating - causing dx graphically
                // glitches
                setTimeout(() => this.emitRefetchRequired());
            }
            return role;
        } catch {
            this.emitGridDimensionsUpdated();
        }
    }

    public promiseToGotoRoleExplorer() {
        return this.routeService.gotoControllerRoute(rolesPageRoute.id);
    }

    public promptToDeleteRole(role: Role) {
        return this.getRoleTasksDeletionMessage(role.roleId).pipe(
            switchMap((message) => {
                const confirmationDialogData: IConfirmationDialogData = {
                    title: `Delete ${role.extensions.roleLabel} : ${role.label}`,
                    message,
                    checkboxMessage: `Confirm that you wish to delete this ${role.extensions.roleLabel}`,
                    confirmButtonText: "Delete",
                };
                return this.commonDialogService.openConfirmationDialog(confirmationDialogData);
            }),
            switchMap(() => this.integratedArchitectureFrameworkService.deleteRole(role)),
        );
    }

    public promptToDeactivateRole(role: Role) {
        return this.getRoleTasksDeletionMessage(role.roleId).pipe(
            switchMap((message) => {
                const confirmationDialogData: IConfirmationDialogData = {
                    title: `Deactivate ${role.extensions.roleLabel} : ${role.label}`,
                    message,
                    checkboxMessage: `Confirm that you wish to deactivate this ${role.extensions.roleLabel}`,
                    confirmButtonText: "Deactivate",
                };

                return this.commonDialogService.openConfirmationDialog(confirmationDialogData);
            }),
            switchMap(() => {
                const changedEntities: IBreezeEntity[] = [role];
                role.endDate = new Date();
                return this.commonDataService.saveEntities(changedEntities);
            }),
            tap(() => this.integratedArchitectureFrameworkService.emitRoleConnectionChange()),
        );
    }

    public promptToReactivateRole(role: Role) {
        const confirmationDialogData: IConfirmationDialogData = {
            title: `Reactivate ${role.extensions.roleLabel} : ${role.label}`,
            message: `Are you sure you wish to reactivate this ${role.extensions.roleLabel}?`,
            confirmButtonText: "Reactivate",
        };

        return this.commonDialogService.openConfirmationDialog(confirmationDialogData).pipe(
            switchMap(() => {
                role.endDate = undefined;
                return this.commonDataService.saveEntities([role]);
            }),
            tap(() => this.integratedArchitectureFrameworkService.emitRoleConnectionChange()),
        );
    }

    private getRoleTasksDeletionMessage(roleId: number) {
        const keyFunctions$ = this.keyFunctionsService.getKeyFunctionRolesByRoleId(roleId).pipe(
            map((kfRoles) => kfRoles.map((kfr) => kfr.keyFunction!)),
        );

        return combineLatest([
            this.integratedArchitectureFrameworkService.getProcessStepsForRoleId(roleId),
            keyFunctions$,
        ]).pipe(
            map(([processSteps, keyFunctions]) => {
                let message = "";

                if (processSteps.length > 0 || keyFunctions.length > 0) {
                    if (processSteps.length > 0) {
                        message += "The following process steps will be unassigned after the operation: <ul>";
                        processSteps.forEach((processStep) => {
                            message += `<li>${processStep.name}</li>`;
                        });
                        message += "</ul>";
                    }

                    if (keyFunctions.length > 0) {
                        message += "The following key functions will no longer be assigned to this role: <ul>";
                        keyFunctions.forEach((keyFunction) => {
                            message += `<li>${keyFunction.name}</li>`;
                        });
                        message += "</ul>";
                    }

                    message += "<b>Note that this operation CANNOT be reverted!</b>";
                }

                return message;
            }),
        );
    }
}
