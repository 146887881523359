<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Guidance material usage</h3>
    <div adapt-dialog-content>
        <adapt-display-guidance-material-usage [guidanceMaterial]="guidanceMaterial"></adapt-display-guidance-material-usage>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                class="btn btn-default"
                (click)="cancel()">OK</button>
    </div>
</adapt-dialog>
