import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptKanbanSharedModule } from "@org-common/lib/kanban/kanban-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptDisplayTreeObjectiveModule } from "@org-common/lib/objectives/display-tree-objective.component/display-tree-objective.module";
import { AdaptObjectivesSharedModule } from "@org-common/lib/objectives/objectives-shared.module";
import { AdaptOrgCommonPagesModule } from "@org-common/lib/pages/pages.module";
import { provideThemeTestPage } from "@org-common/lib/pages/theme-test/theme-test-page";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { AdaptProcessMapSharedModule } from "app/features/architecture/process-map/process-map-shared.module";
import { AdaptRoleSharedModule } from "app/features/architecture/role/role-shared.module";
import { AdaptValueStreamsSharedModule } from "app/features/architecture/value-streams/value-streams-shared.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import { CumulusComponentsPageComponent } from "./theme-test/cumulus-components-page/cumulus-components-page.component";
import { cumulusComponentsPageRoute } from "./theme-test/cumulus-components-page/cumulus-components-page.route";

@NgModule({
    declarations: [
        CumulusComponentsPageComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AdaptCommonPagesModule,
        DxDataGridModule,
        AdaptTooltipModule,
        AdaptZoneMapModule,
        AdaptSurveyModule,
        AdaptDisplayTreeObjectiveModule,
        AdaptProcessMapSharedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptRoleSharedModule,
        AdaptDirectorySharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptEmbedShellModule,
        AdaptKanbanModule,
        AdaptKanbanSharedModule,
        AdaptObjectivesSharedModule,
        AdaptLabellingModule,
        AdaptCommonMeetingsModule,
        AdaptValueStreamsSharedModule,
        AdaptOrgCommonPagesModule,
    ],
    providers: [
        provideThemeTestPage({ route: cumulusComponentsPageRoute, title: "2c. Cumulus Components" }),
    ],
})
export class AdaptCumulusAboutModule {
    public static readonly Routes = [
        ...cumulusComponentsPageRoute.routes,
    ];
}
