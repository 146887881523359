import { Component, Input } from "@angular/core";
import { Position } from "@common/ADAPT.Common.Model/organisation/position";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-position",
    templateUrl: "./person-profile-position.component.html",
})
export class PersonProfilePositionComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;

    private person$ = new BehaviorSubject<Person | undefined>(undefined);
    public position$: Observable<Position | undefined>;
    public ManagePositionAndRoles = DirectoryAuthService.ManagePositionAndRoles;

    constructor(
        private dialogService: AdaptCommonDialogService,
    ) {
        super();

        this.position$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person) => {
                const employeeConnections = person.connections.filter((connection) => connection.isEmployeeConnection());
                if (!employeeConnections.length) {
                    return undefined;
                }

                let latestConnection = employeeConnections.find((connection) => connection.isActive());
                if (!latestConnection) {
                    latestConnection = employeeConnections
                        .filter((connection) => !connection.isActive())
                        .sort((a, b) => a.endDate! < b.endDate! ? 1 : -1)[0];
                }

                if (!latestConnection) {
                    return undefined;
                }

                const activePositions = latestConnection.positions.filter((position) => !position.endDate);
                if (activePositions.length) {
                    return activePositions[0];
                }

                const inactivePositions = latestConnection.positions.filter((position) => position.endDate);
                if (inactivePositions.length) {
                    return inactivePositions.sort((a, b) => a.endDate! < b.endDate! ? 1 : -1)[0];
                }

                return undefined;
            }),
        );
    }

    public editPosition(position: Position) {
        const positionDlg: IEditSimpleValueBreezeEntityDialogData = {
            title: "Edit Position Name",
            entities: [{
                label: "Position Name",
                entity: position,
                fieldName: "name",
                type: SimpleValueType.Text,
            }],
            saveOnClose: true,
        };

        this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, positionDlg).subscribe();
    }
}
