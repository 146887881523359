import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamProduct } from "@common/ADAPT.Common.Model/organisation/value-stream-product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CallToActionText } from "@org-common/lib/call-to-action/call-to-action-text";
import { from, lastValueFrom, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../../tier1-architecture/tier1-architecture.service";
import { Tier1ArchitectureUiService } from "../../tier1-architecture/tier1-architecture-ui.service";
import { valueStreamDashboardPageRoute } from "../value-stream-dashboard-page/value-stream-dashboard-page.route";

@Component({
    selector: "adapt-display-products-services",
    styleUrls: ["../value-stream-common-styles.scss"],
    templateUrl: "./display-products-services.component.html",
})
export class DisplayProductsServicesComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public valueStream!: ValueStream;
    @Input() public readOnly = false;

    public canEditTier1Architecture$?: Observable<boolean>;
    public featureUrl$ = of("");

    public valueStreamProducts?: ValueStreamProduct[];
    private valueStreamProductsUpdater = this.createThrottledUpdater<ValueStreamProduct[]>((products) => this.valueStreamProducts = products);

    public CallToActionText = CallToActionText;

    public constructor(
        private authService: AuthorisationService,
        private tier1Service: Tier1ArchitectureService,
        private tier1UiService: Tier1ArchitectureUiService,
    ) {
        super();
    }

    public ngOnInit(): void {
        if (!this.readOnly) {
            this.canEditTier1Architecture$ = from(this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.EditTier1));
        }
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.valueStream && this.valueStream) {
            const products = await lastValueFrom(this.tier1Service.getValueStreamProductsForValueStream(this.valueStream).pipe(
                map((valueStreamProducts) => valueStreamProducts.sort(SortUtilities.getSortByFieldFunction<ValueStreamProduct>((p) => p.ordinal))),
            ));
            this.valueStreamProductsUpdater.next(products);
        }

        this.featureUrl$ = this.readOnly && this.valueStream
            ? valueStreamDashboardPageRoute.getRoute(this.valueStream)
            : of("");
    }

    @Autobind
    public editValueStreamProducts() {
        return this.tier1UiService.editValueStreamProducts(this.valueStream).pipe(
            map((products) => products.sort(SortUtilities.getSortByFieldFunction<ValueStreamProduct>((p) => p.ordinal))),
            tap((products) => this.valueStreamProductsUpdater.next(products)),
        );
    }
}
