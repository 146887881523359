<ng-container *ngIf="(childTeams$ | async) as childTeams">
    <ng-container *ngIf="(team$ | async) as team">
        <div class="d-flex flex-grow-1">
            <div class="d-flex flex-grow-1 flex-column">
                <adapt-link-team [team]="team"
                                 [showIcon]="true"
                                 [showLabels]="false"
                                 [filterTeams]="filterTeams"
                                 [showChildCount]="true"></adapt-link-team>

                <adapt-display-labels *ngIf="team.labelLocations?.length"
                                      class="ms-1 mt-1"
                                      [labelLocations]="team.labelLocations"></adapt-display-labels>
            </div>
            <div class="d-flex flex-row align-items-start">
                <adapt-link-person [person]="team.teamLeaderPerson"
                                   [imageOnly]="true"
                                   imageSize="sm"></adapt-link-person>
                <span *ngIf="showChildren && childTeams.length > 0"
                      (click)="childrenExpanded = !childrenExpanded"
                      [adaptTooltip]="'Click to ' + (childrenExpanded ? 'hide' : 'show') + ' child teams'"
                      class="me-2 expand-children"
                      data-test="expand-child-teams">
                    <i class="fa"
                       [ngClass]="childrenExpanded ? 'fa-chevron-up': 'fa-chevron-down'"></i>
                </span>
            </div>
        </div>
        <div *ngIf="showChildren && childTeams.length > 0 && childrenExpanded"
             class="d-flex flex-column flex-grow-1 children mt-2">
            <adapt-link-team *ngFor="let team of childTeams"
                             [team]="team"
                             [showIcon]="true"
                             [showLabels]="true"
                             [showChildren]="true"
                             [showAllChildren]="true"
                             [filterTeams]="filterTeams"
                             class="my-1"></adapt-link-team>
        </div>
    </ng-container>
</ng-container>
