import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { provideNavigateTeamsPageRoute, provideOrganisationDashboardPageRoute, provideOrganisationPurposeVisionPageRoute, providePersonalDashboardPageRoute, provideTeamDashboardPageRoute, provideTeamGuidancePageRoute } from "@common/page-route-providers";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { provideApplicationBarItemType } from "@common/shell/application-bar/application-bar.component";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { AdaptRerenderModule } from "@common/ux/rerender/rerender.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { guidingPhilosophyRoute } from "@org-common/lib/guiding-philosophy/guiding-philosophy-page/guiding-philosophy-page.route";
import { AdaptKanbanSharedModule } from "@org-common/lib/kanban/kanban-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { AdaptObjectivesSharedModule } from "@org-common/lib/objectives/objectives-shared.module";
import { ApplicationBarSearchItem } from "@org-common/lib/search/application-bar-search-item/application-bar-search-item";
import { AdaptSearchCommonModule } from "@org-common/lib/search/search.module";
import { SearchService } from "@org-common/lib/search/search.service";
import { provideSearchProvider } from "@org-common/lib/search/search-provider";
import { RoleSearchProvider } from "@org-common/lib/search/search-providers/search-provider-role";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { navigateTeamsPageRoute } from "app/features/people/teams/navigate-teams-page/navigate-teams-page.route";
import { AdaptTeamsModule } from "app/features/people/teams/teams.module";
import { organisationDashboardPageRoute } from "app/organisation/organisation-dashboard-page/organisation-dashboard-page.route";
import { DxAccordionModule, DxAutocompleteModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxTooltipModule } from "devextreme-angular";
import { personalDashboardPageRoute } from "../personal-dashboard/personal-dashboard-page/personal-dashboard-page.route";
import { teamDashboardPageRoute } from "../team-dashboard/team-dashboard-page/team-dashboard-page.route";
import { ApplicationBarSearchRowElementsComponent } from "./application-bar-search-row-elements/application-bar-search-row-elements.component";
import { SearchRowElementsComponent } from "./search-row-elements/search-row-elements.component";
import { SearchRowKeyFunctionComponent } from "./search-row-key-function/search-row-key-function.component";
import { SearchRowProductComponent } from "./search-row-product/search-row-product.component";
import { SearchRowResilientBusinessGoalComponent } from "./search-row-resilient-business-goal/search-row-resilient-business-goal.component";
import { SearchRowRoleComponent } from "./search-row-role/search-row-role.component";
import { SearchRowSystemComponent } from "./search-row-system/search-row-system.component";
import { SearchRowValueStreamComponent } from "./search-row-value-stream/search-row-value-stream.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptDirectorySharedModule,
        DxAccordionModule,
        AdaptObjectivesSharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptShellModule,
        AdaptZoneMapModule,
        AdaptCommonRouteModule,
        AdaptButtonModule,
        DxAutocompleteModule,
        DxCheckBoxModule,
        DxTooltipModule,
        DxTextBoxModule,
        FormsModule,
        AdaptTeamsModule,
        DxSelectBoxModule,
        AdaptLabellingModule,
        AdaptRerenderModule,
        AdaptKanbanSharedModule,
        AdaptSearchCommonModule,
    ],
    declarations: [
        SearchRowSystemComponent,
        SearchRowValueStreamComponent,
        SearchRowKeyFunctionComponent,
        SearchRowProductComponent,
        SearchRowResilientBusinessGoalComponent,
        SearchRowRoleComponent,
        SearchRowElementsComponent,
        ApplicationBarSearchRowElementsComponent,
    ],
    providers: [
        provideSearchProvider(RoleSearchProvider),
        provideApplicationBarItemType(ApplicationBarSearchItem),
        provideTeamDashboardPageRoute(teamDashboardPageRoute),
        provideOrganisationDashboardPageRoute(organisationDashboardPageRoute),
        providePersonalDashboardPageRoute(personalDashboardPageRoute),
        provideNavigateTeamsPageRoute(navigateTeamsPageRoute),
        provideTeamGuidancePageRoute(teamDashboardPageRoute),
        provideOrganisationPurposeVisionPageRoute(guidingPhilosophyRoute),
    ],
})
export class AdaptSearchModule {
    public constructor(labellingService: LabellingService, searchService: SearchService) {
        labellingService.registerLabelLinkProvider((label) => searchService.getSearchUrl$({
            keyword: undefined,
            labelIds: new Set<number>([label.labelId]),
        }));

        searchService.registerSearchGroup(SearchService.PageSearchGroup);
        searchService.registerSearchGroup(SearchService.PeopleTeamsRolesSearchGroup);
        searchService.registerSearchGroup(SearchService.KanbanSearchGroup);
        searchService.registerSearchGroup(SearchService.MeetingSearchGroup);
        searchService.registerSearchGroup(SearchService.ObjectivesSearchGroup);
        searchService.registerSearchGroup(SearchService.SystemSearchGroup);
        searchService.registerSearchGroup(SearchService.GuidanceSearchGroup);
        searchService.registerSearchGroup(SearchService.Tier1SearchGroup);
        searchService.registerSearchGroup(SearchService.ImplementationKitSearchGroup);

        const searchElementComponent: IComponentRender<SearchRowElementsComponent> = {
            component: SearchRowElementsComponent,
        };

        SearchService.registerSearchElementComponent(searchElementComponent);

        const applicationBarSearchElementComponent: IComponentRender<ApplicationBarSearchRowElementsComponent> = {
            component: ApplicationBarSearchRowElementsComponent,
        };

        SearchService.registerApplicationBarSearchElementComponent(applicationBarSearchElementComponent);
    }
}
