<div class="form-group">
    <label>Key Functions and Zones</label>
    <div class="alert alert-info my-2"
         *ngIf="team.parentTeam; else locationEditor">
        Teams inherit their locations from the root parent team.
        Un-assign the parent team to assign this team to a Key Function or a Zone.
    </div>
    <ng-template #locationEditor>
        <dx-list *ngIf="teamLocations!.length > 0"
                 class="dx-list-boxed"
                 (onItemDeleted)="removeTeamLocation($event)"
                 [dataSource]="teamLocations"
                 [hoverStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [activeStateEnabled]="false"
                 [allowItemDeleting]="true">
            <div *dxTemplate="let teamLocation of 'item'">
                <ng-container [ngTemplateOutlet]="items"
                              [ngTemplateOutletContext]="{$implicit: teamLocation}"></ng-container>
            </div>
        </dx-list>

        <dx-select-box [dataSource]="dataSource"
                       (onValueChanged)="onSelectValueChange($event)"
                       [grouped]="true"
                       [searchEnabled]="true"
                       searchExpr="name"
                       fieldTemplate="field"
                       placeholder="Select location..."
               		   data-test="select-team-location">
        <span *dxTemplate="let area of 'item'">
            <ng-container [ngTemplateOutlet]="items"
                          [ngTemplateOutletContext]="{$implicit: area}"></ng-container>
        </span>
            <div *dxTemplate="let area of 'field'">
                <div *ngIf="!!area; else noValueTemplate">
                    <ng-container [ngTemplateOutlet]="items"
                                  [ngTemplateOutletContext]="{$implicit: area}"></ng-container>
                    <dx-text-box class="d-none"
                                 value="hidden element required by dxTemplate"
                                 [readOnly]="true"></dx-text-box>
                </div>
            </div>
            <ng-template #noValueTemplate>
                <dx-text-box value=""
                             placeholder="Select location..."></dx-text-box>
            </ng-template>
        </dx-select-box>
    </ng-template>
</div>

<div class="form-group">
    <label>Parent Team</label>
    <div class="alert alert-info my-2"
         *ngIf="!team.parentTeam && teamLocations!.length > 0">
        Teams inherit their locations from the root parent team.
        Assigning a parent team will remove this team from all of the above locations.
    </div>
    <adapt-select-team [team]="team.parentTeam"
                       (teamChange)="onParentTeamChanged($event)"
                       [activeOnly]="true"
                       [filter]="filterTeam"
                       data-test="select-parent-team"></adapt-select-team>
</div>

<ng-template #items let-item>
    <div *ngIf="getAsZoneLocation(item) as zone">
        <adapt-zone-label [zone]="zone"></adapt-zone-label>
    </div>
    <div *ngIf="getAsKeyFunctionLocation(item) as kf">
        <adapt-link-key-function [keyFunction]="kf"
                                 [adaptLinkDisabled]="true"></adapt-link-key-function>
    </div>
</ng-template>
