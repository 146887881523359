import { CareerValuationCategoryValue } from "./career-valuation-category-value";

export class CareerValuationCategoryValueExtensions {
    public isEditorValid = true;
    public constructor(private careerValuationCategoryValue: CareerValuationCategoryValue) { }

    public get score() {
        if (this.careerValuationCategoryValue.idealValue !== 0) {
            return this.careerValuationCategoryValue.actualValue + " / " + this.careerValuationCategoryValue.idealValue;
        } else {
            return "0 / 0";
        }
    }
}
