import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxSelectBoxComponent, DxTextBoxComponent } from "devextreme-angular";
import { EMPTY, forkJoin, lastValueFrom } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkAuthService } from "../../integrated-architecture/integrated-architecture-framework-auth.service";
import { ProcessMapService } from "../process-map.service";

@Component({
    selector: "adapt-select-process-map",
    templateUrl: "./select-process-map.component.html",
})
export class SelectProcessMapComponent implements OnInit, IFocusable {
    @ViewChild("focus") public elementToFocus?: DxSelectBoxComponent;

    @Input() public processMap?: ProcessMap;
    @Output() public processMapChange = new EventEmitter<ProcessMap>();

    @Input() public filter?: (processMap: ProcessMap) => boolean;
    @Input() public disabled = false;
    @Input() public allowCreate = false;
    @Input() public onlyEditableSystems = false;
    @Output() public entitiesChange = new EventEmitter<IBreezeEntity[]>();

    public readonly systemIcon = SystemEntity.IconClass;
    public readonly processMapIcon = ProcessMap.IconClass;

    public dataSource?: DataSource;

    @Input() public selectedSystem?: SystemEntity;
    @Input() public defaultNewMapName?: string;
    public isCreating = false;
    public newSystemComponent?: SystemComponent;

    @ViewChild(DxTextBoxComponent) private newProcessMapName?: DxTextBoxComponent;

    public constructor(
        private commonDataService: CommonDataService,
        private processMapService: ProcessMapService,
        private systemisationService: SystemisationService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        private authService: AuthorisationService,
    ) {
    }

    public getElementToFocus() {
        return this.elementToFocus;
    }

    public async ngOnInit() {
        let processMaps = await lastValueFrom(this.processMapService.getProcessMaps());

        if (this.filter) {
            processMaps = processMaps.filter(this.filter);
        }

        let mapComponents = await lastValueFrom(this.systemisationService.getAllSystemComponentsForProcessMaps());
        if (this.onlyEditableSystems) {
            const permissionObservables = mapComponents.map((comp) => this.archAuthService.personCanEditTier2(this.authService.currentPerson!, comp.system));
            mapComponents = await lastValueFrom(forkJoin(permissionObservables).pipe(
                map((results: boolean[]) => mapComponents.filter((_, idx) => results[idx])),
            ));
        }

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: processMaps,
                key: "processMapId",
            }),
            filter: (processMap: ProcessMap) => {
                if (!this.onlyEditableSystems) {
                    return true;
                }

                return !!mapComponents.find((i) => i.systemProcessMapId === processMap.processMapId);
            },
            group: (processMap: ProcessMap) =>
                mapComponents.find((i) => i.systemProcessMapId === processMap.processMapId)?.system.name ?? "Not in system",
        });
    }

    public async reload() {
        await this.ngOnInit();
    }

    @Autobind
    public createNewMap() {
        this.isCreating = true;
        this.processMapService.createProcessMap().pipe(
            switchMap((processMap) => {
                processMap.name = this.defaultNewMapName ?? "";
                return this.systemisationService.createSystemComponent(this.selectedSystem).pipe(
                    tap((systemComponent) => {
                        systemComponent.systemProcessMap = processMap;
                        systemComponent.name = processMap.name;
                        this.newSystemComponent = systemComponent;
                        this.validateComponentName();
                        this.entitiesChange.emit([systemComponent, processMap]);
                    }),
                );
            }),
        ).subscribe();
    }

    public onNameChange(name: string) {
        if (this.newSystemComponent) {
            this.newSystemComponent.name = name;
            this.validateComponentName();
        }
    }

    @Autobind
    public saveNewMapAndSelect() {
        if (this.newSystemComponent) {
            return this.commonDataService.saveEntities([this.newSystemComponent, this.newSystemComponent.systemProcessMap!]).pipe(
                switchMap(() => this.reload()),
                tap(() => {
                    this.processMap = this.newSystemComponent?.systemProcessMap;
                    this.processMapChange.emit(this.processMap);
                    this.newSystemComponent = undefined;
                    this.isCreating = false;
                    this.entitiesChange.emit([]);
                }),
            );
        } else {
            return EMPTY;
        }
    }

    @Autobind
    public cancelNewMap() {
        if (this.newSystemComponent) {
            this.commonDataService.rejectChanges([this.newSystemComponent.systemProcessMap!, this.newSystemComponent]).pipe(
                tap(() => {
                    this.newSystemComponent = undefined;
                    this.isCreating = false;
                    this.entitiesChange.emit([]);
                }),
            ).subscribe();
        }
    }

    public get isMapCreationValid() {
        return this.newSystemComponent?.systemProcessMap &&
            !this.newSystemComponent.entityAspect.hasValidationErrors &&
            !this.newSystemComponent.systemProcessMap.entityAspect.hasValidationErrors;
    }

    public validateComponentName() {
        this.newSystemComponent?.extensions.validateComponentName();
        this.validateTextBox();
    }

    // dxTextBox validator not picking up entity validation changes if text field not change (e.g. changing system)
    // even if the entity validation is already updated
    // TODO: figure out how to trigger dxValidationRule component to validate (we are currently just registering a validationCallback for it but
    //   no control of when it will be called)
    private validateTextBox() {
        if (this.newProcessMapName) {
            this.newProcessMapName?.instance.option("isValid", !this.newSystemComponent?.entityAspect.hasValidationErrors);
            if (this.newSystemComponent?.entityAspect.hasValidationErrors) {
                const errors = this.newSystemComponent.entityAspect.getValidationErrors();
                this.newProcessMapName?.instance.option("validationError", {
                    message: errors.map((e) => e.errorMessage).join("; "),
                });
            }
        } else {
            setTimeout(() => this.validateTextBox(), 100);
        }
    }
}
