import { APP_INITIALIZER, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { AdaptIdentityModule } from "@common/identity/identity.module";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonModule } from "@common/lib/common.module";
import { AdaptCommonDataModule } from "@common/lib/data/common-data.module";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { AdaptReleaseNotifierModule } from "@common/lib/release-notifier/release-notifier.module";
import { provideSubscribeOrganisationPageRoute } from "@common/page-route-providers";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptChangeManagerModule } from "@common/ux/change-manager/change-manager.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptCommonWorkflowModule } from "@common/workflow/workflow.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptEntitySyncModule } from "@org-common/lib/entity-sync/entity-sync.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptEventTrackingModule } from "@org-common/lib/event-tracking/event-tracking.module";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptMyOrganisationsModule } from "@org-common/lib/my-organisations/my-organisations.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptResilientBusinessGoalsModule } from "@org-common/lib/rb-goals/rb-goals.module";
import { AdaptOrgCommonShellModule } from "@org-common/lib/shell/org-common-shell.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { AdaptCumulusAboutModule } from "./about/about.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AdaptIntegratedArchitectureModule } from "./features/architecture/integrated-architecture/integrated-architecture.module";
import { AdaptKeyFunctionsModule } from "./features/architecture/key-functions/key-functions.module";
import { AdaptOrganisationMapModule } from "./features/architecture/organisation-map/organisation-map.module";
import { AdaptProcessMapModule } from "./features/architecture/process-map/process-map.module";
import { AdaptTier1ArchitectureModule } from "./features/architecture/tier1-architecture/tier1-architecture.module";
import { AdaptValueStreamsModule } from "./features/architecture/value-streams/value-streams.module";
import { AdaptCulturalLeadershipModule } from "./features/culture/cultural-leadership/cultural-leadership.module";
import { AdaptCareerValuationModule } from "./features/people/career-valuation/career-valuation.module";
import { AdaptCulturalIndexModule } from "./features/people/cultural-index/cultural-index.module";
import { AdaptPeerCatchupModule } from "./features/people/peer-catchup/peer-catchup.module";
import { AdaptPersonModule } from "./features/people/person/person.module";
import { AdaptTeamsModule } from "./features/people/teams/teams.module";
import { AdaptProductCatalogueModule } from "./features/product/product-catalogue/product-catalogue.module";
import { AdaptStakeholderModule } from "./features/stakeholders/stakeholder.module";
import { AdaptSystemisationModule } from "./features/systemisation/systemisation.module";
import { AdaptDiagramModule } from "./general/diagram/diagram.module";
import { AdaptAppOrganisationModule } from "./organisation/app.organisation.module";
import { subscribeOrganisationPageRoute } from "./organisation/subscribe-organisation/subscribe-organisation-page/subscribe-organisation-page.route";
import { AdaptUserManagementModule } from "./organisation/user-management/user-management.module";
import { AdaptConfigurationModule } from "./platform/configuration/configuration.module";
import { AdaptNavigationModule } from "./platform/navigation/navigation.module";
import { AdaptPersonalDashboardModule } from "./platform/personal-dashboard/personal-dashboard.module";
import { AdaptSearchModule } from "./platform/search/search.module";
import { AdaptTeamDashboardModule } from "./platform/team-dashboard/team-dashboard.module";
import { AdaptEmbedShellModule } from "./services/layout/embed-shell.module";
import { initialiseRouteMediator } from "./services/route-mediator";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        // AppId won't be accessible at this time due to async config fetching
        // (also can't use functions in decorators) so do in constructor instead.
        IntercomModule.forRoot({
            appId: "",
        }),
        AdaptCommonModule.forRoot(),
        AdaptCommonDataModule,
        AdaptDocumentSelectorModule.forRoot(),
        AdaptEventTrackingModule.forRoot(),
        AdaptOrgCommonShellModule,
        AdaptEmbedShellModule.forRoot(),
        AdaptNavigationModule.forRoot(),
        AdaptErrorPageModule,
        AdaptChangeManagerModule,
        AdaptEntitySyncModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptIdentityModule,
        AdaptObjectivesModule,
        AdaptTeamsModule,
        AdaptMyOrganisationsModule.forRoot(),
        AdaptAppOrganisationModule,
        AdaptIntegratedArchitectureModule,
        AdaptUserManagementModule,
        AdaptPersonModule,
        AdaptCareerValuationModule,
        AdaptCulturalIndexModule,
        AdaptReleaseNotifierModule,
        AdaptTier1ArchitectureModule,
        AdaptGuidingPhilosophyModule,
        AdaptValuesConstitutionModule,
        AdaptConfigurationModule,
        AdaptSurveyModule,
        AdaptKeyFunctionsModule,
        AdaptResilientBusinessGoalsModule,
        AdaptCommonPagesModule,
        AdaptSystemisationModule,
        AdaptProcessMapModule,
        AdaptValueStreamsModule,
        AdaptOrganisationMapModule,
        AdaptCulturalLeadershipModule,
        AdaptPeerCatchupModule,
        AdaptTeamDashboardModule,
        AdaptPersonalDashboardModule,
        AdaptProductCatalogueModule,
        AdaptDiagramModule,
        AdaptSearchModule,
        AdaptCommonWorkflowModule,
        AdaptStakeholderModule,
        AdaptCommonDialogModule,
        AdaptCumulusAboutModule,
        AdaptBullseyeModule,
        AdaptStrategicGoalsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [RouteEventsService, RouteService, AdaptCommonDialogService],
            useFactory: initialiseRouteMediator,
        },
        provideSubscribeOrganisationPageRoute(subscribeOrganisationPageRoute),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class AppModule implements DoBootstrap {
    public constructor(surveyService: SurveyService) {
        surveyService.registerLearnMoreUrlForSurveyType(SurveyType.TeamAssessment, ImplementationKitService.GetArticleLink(ImplementationKitArticle.UsingTheAdaptTeamModel));
        surveyService.registerLearnMoreUrlForSurveyType(SurveyType.EmployeeEngagement, ImplementationKitService.GetArticleLink(ImplementationKitArticle.EmployeeEngagementSurvey));
        surveyService.registerLearnMoreUrlForSurveyType(SurveyType.ResilientBusinessAssessment, ImplementationKitService.GetArticleLink(ImplementationKitArticle.ResilientBusinessAssessment));
    }

    public ngDoBootstrap(app: ApplicationRef) {
        app.bootstrap(AppComponent);
    }
}
