<ng-container *ngIf="roleTask">
    <div class="row">
        <div class="col-12 col-md-6 col-xl-7">
            <h3>Description</h3>
            <div *ngIf="roleTask.supplementaryData?.description; else noRoleDescription"
                 [froalaView]="roleTask.supplementaryData!.description"></div>
            <ng-template #noRoleDescription>
                <adapt-call-to-action-inline>
                    <p>(There are currently no description for this role task)</p>
                    <button *ngIf="isEditing"
                            adaptCallToActionButton
                            adaptButtonIcon="edit"
                            [adaptBlockingClick]="editRoleTask">
                        Describe your role task
                    </button>
                </adapt-call-to-action-inline>
            </ng-template>
        </div>
        <div class="col-12 col-md-6 col-xl-5">
            <div class="mb-3">
                <h3>Role</h3>
                <adapt-link-role [role]="roleTask.role"
                                 [showIcon]="true"></adapt-link-role>
            </div>
            <div *ngIf="roleTask.supplementaryData?.guidanceQuestions"
                 class="mb-3">
                <h3>Guidance Questions</h3>
                <div [froalaView]="roleTask.supplementaryData!.guidanceQuestions"></div>
            </div>
            <ng-container *ngIf="guidanceMaterials.length > 0">
                <h3>Guidance Materials</h3>
                <div class="guidance-material-list">
                    <ul>
                        <li *ngFor="let guidanceMaterial of guidanceMaterials">
                            <adapt-display-guidance-material [guidanceMaterial]="guidanceMaterial"></adapt-display-guidance-material>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
