<adapt-dashboard-element name="Career Valuation"
                         [url]="dashboardUrl$ | async">
    <div *adaptLoadingUntilFirstEmit="let careerValuation of careerValuation$">
        <div *ngIf="careerValuation; else NoCareerValuationTemplate">
            <p class="career-valuation-total">{{actualTotal}}%</p>

            <adapt-cvt-personal-differentials-chart [careerValuation]="careerValuation"></adapt-cvt-personal-differentials-chart>

            <p class="mt-3"><small>Recorded on {{careerValuation.creationDate | adaptDate}}</small></p>
        </div>

        <ng-template #NoCareerValuationTemplate>
            You have not yet recorded a career valuation
        </ng-template>
    </div>
</adapt-dashboard-element>
