/* eslint-disable max-classes-per-file */
import { Component, Inject, Injector, ViewEncapsulation } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { ResilientBusinessGoal } from "@common/ADAPT.Common.Model/organisation/resilient-business-goal";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

type IEditResilientBusinessGoalsDialogData = ResilientBusinessGoal[];

interface ITabItem {
    readonly title: string;
    content?: string;
    isEditorValid: boolean;
}

@Component({
    selector: "adapt-edit-rb-goals-dialog",
    templateUrl: "./edit-rb-goals-dialog.component.html",
    styleUrls: ["./edit-rb-goals-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
@PersistableDialog("EditResilientBusinessGoalsDialogComponent")
export class EditResilientBusinessGoalsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditResilientBusinessGoalsDialogData> {
    public readonly dialogName = "EditResilientBusinessGoals";

    public tabs: ITabItem[];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) private data: ResilientBusinessGoal[],
    ) {
        super(injector);
        this.autoResolveData = data;
        this.tabs = [
            ...data
                .map((i) => new ResilientBusinessGoalTabItem(i)),
        ];
    }

    public get isInvalid() {
        return this.entitiesToConfirm.every((i) => i.entityAspect.entityState.isUnchanged()) || this.tabs.some((tab) => !tab.isEditorValid);
    }

    public get entitiesToConfirm() {
        return [
            ...this.data,
        ];
    }
}

class ResilientBusinessGoalTabItem implements ITabItem {

    public title = ZoneMetadata.Name[this.rbGoal.zone];

    public get content() {
        return this.rbGoal.content;
    }
    public set content(value: string | undefined) {
        this.rbGoal.content = value;
    }

    public constructor(
        private rbGoal: ResilientBusinessGoal,
        public isEditorValid: boolean = true,
    ) { }
}
