<adapt-feature-guidance [configItem]="configItem">
    <p>The Career Valuation forms part of the adapt culture framework, and it allows employees to capture their ideal
        values for their role in the organisation as well as actual values for how their current role meets these expectations.</p>
    <p>Enabling this feature will allow you to record Career Valuation measurements for employees in your organisation.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <h3>Category</h3>
    <div class="row">
        <div class="col-3">
            <dx-list [dataSource]="careerValuationCategories"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     (onSelectionChanged)="onSelectionChanged($event)"
                     (onContentReady)="onContentReady($event)">
                <div *dxTemplate="let data of 'item'">
                    <div class="list-item">{{data.name}}</div>
                </div>
            </dx-list>
        </div>
        <div class="col-9"
             *ngIf="selectedCategory">
            <div class="col-sm-12 col-md-4 ps-0 pe-2 form-group">
                <label for="categoryName">Category name</label>
                <dx-text-box id="categoryName"
                             [adaptValidateEntity]="selectedCategory"
                             adaptValidateEntityProperty="name"
                             [(value)]="selectedCategory!.name"></dx-text-box>
            </div>

            <div class="form-group me-2">
                <label>
                    {{selectedCategory!.name}} ideal questions
                    <span class="fal fa-info-circle"
                          adaptTooltip="The questions used to rate this category in your ideal role."></span>
                </label>

                <adapt-html-editor [(contents)]="selectedCategory!.idealQuestions"
                                   [required]="true"></adapt-html-editor>
            </div>

            <div class="form-group me-2">
                <label>
                    {{selectedCategory!.name}} actual questions
                    <span class="fal fa-info-circle"
                          adaptTooltip="The questions used to rate this category for your actual role."></span>
                </label>
                <adapt-html-editor [(contents)]="selectedCategory!.actualQuestions"
                                   [required]="true"></adapt-html-editor>
            </div>
        </div>
    </div>
</adapt-feature-configuration>
