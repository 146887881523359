import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxChartModule, DxDataGridModule, DxDateBoxModule, DxPieChartModule, DxSelectBoxModule, DxSliderModule } from "devextreme-angular";
import { AdaptTeamsSharedModule } from "../teams/teams-shared.module";
import { AnalyseCatchupsPageComponent } from "./analyse-catchups-page/analyse-catchups-page.component";
import { analyseCatchupsPageRoute } from "./analyse-catchups-page/analyse-catchups-page.route";
import { CatchupGridComponent } from "./catchup-grid/catchup-grid.component";
import { CatchupHistoryChartComponent } from "./catchup-history-chart/catchup-history-chart.component";
import { CatchupHistoryChartDialogComponent } from "./catchup-history-chart-dialog/catchup-history-chart-dialog.component";
import { CatchupRatingCellComponent } from "./catchup-rating-cell/catchup-rating-cell.component";
import { CatchupRatingsGraphComponent } from "./catchup-ratings-graph/catchup-ratings-graph.component";
import { CatchupRelationshipsFilterComponent } from "./catchup-relationships-filter/catchup-relationships-filter.component";
import { CatchupRelationshipsGraphComponent } from "./catchup-relationships-graph/catchup-relationships-graph.component";
import { CatchupTrafficLightRatingComponent } from "./catchup-traffic-light-rating/catchup-traffic-light-rating.component";
import { CatchupViewsComponent } from "./catchup-views/catchup-views.component";
import { CatchupsPageContentComponent } from "./catchups-page-content/catchups-page-content.component";
import { DisplayCatchupRatingComponent } from "./display-catchup-rating/display-catchup-rating.component";
import { DisplayCatchupVisibilityComponent } from "./display-catchup-visibility/display-catchup-visibility.component";
import { DisplayFacilitatorCommentComponent } from "./display-facilitator-comment/display-facilitator-comment.component";
import { DisplayMissingCatchupComponent } from "./display-missing-catchup/display-missing-catchup.component";
import { DisplayRatingValueComponent } from "./display-rating-value/display-rating-value.component";
import { EditCatchupPeopleComponent } from "./edit-catchup-people/edit-catchup-people.component";
import { EditCatchupRatingComponent } from "./edit-catchup-rating/edit-catchup-rating.component";
import { EditCatchupRatingTypeComponent } from "./edit-catchup-rating-type/edit-catchup-rating-type.component";
import { LinkedNodesGraphComponent } from "./linked-nodes-graph/linked-nodes-graph.component";
import { MyCatchupsPageComponent } from "./my-catchups-page/my-catchups-page.component";
import { myCatchupsPageRoute, personalCatchupsPageRoute } from "./my-catchups-page/my-catchups-page.route";
import { PeerCatchUpDashboardElementComponent } from "./peer-catch-up-dashboard-element/peer-catch-up-dashboard-element.component";
import { PeerCatchupAuthService } from "./peer-catchup-auth.service";
import { RecordCatchupDialogComponent } from "./record-catchup-dialog/record-catchup-dialog.component";
import { SelectCatchupTeamComponent } from "./select-catchup-team/select-catchup-team.component";
import { ShowCatchupDialogComponent } from "./show-catchup-dialog/show-catchup-dialog.component";
import { TeamCatchupsPageComponent } from "./team-catchups-page/team-catchups-page.component";
import { teamCatchupsPageRoute } from "./team-catchups-page/team-catchups-page.route";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptFeatureModule,
        AdaptShellModule,
        AdaptEmbedShellModule,
        AdaptAuthorisationModule,
        AdaptTeamsSharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptDateModule,
        AdaptDirectorySharedModule,
        AdaptCommonDialogModule,
        AdaptHtmlEditorModule,
        AdaptCommonUserModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxAccordionModule,
        DxSliderModule,
        DxDataGridModule,
        DxChartModule,
        DxPieChartModule,
        AdaptDashboardElementModule,
        AdaptLinkDisabledModule,
        AdaptCollapsibleModule,
        AdaptImplementationKitModule,
    ],
    declarations: [
        CatchupRelationshipsGraphComponent,
        CatchupRelationshipsFilterComponent,
        CatchupViewsComponent,
        LinkedNodesGraphComponent,
        DisplayFacilitatorCommentComponent,
        DisplayCatchupRatingComponent,
        DisplayMissingCatchupComponent,
        CatchupGridComponent,
        CatchupRatingCellComponent,
        ShowCatchupDialogComponent,
        SelectCatchupTeamComponent,
        DisplayCatchupVisibilityComponent,
        EditCatchupRatingTypeComponent,
        EditCatchupRatingComponent,
        DisplayRatingValueComponent,
        CatchupTrafficLightRatingComponent,
        CatchupHistoryChartDialogComponent,
        CatchupHistoryChartComponent,
        RecordCatchupDialogComponent,
        EditCatchupPeopleComponent,
        TeamCatchupsPageComponent,
        MyCatchupsPageComponent,
        PeerCatchUpDashboardElementComponent,
        CatchupsPageContentComponent,
        AnalyseCatchupsPageComponent,
        CatchupRatingsGraphComponent,
    ],
    exports: [
        PeerCatchUpDashboardElementComponent,
    ],
})
export class AdaptPeerCatchupModule {
    public static readonly Routes = [
        ...analyseCatchupsPageRoute.routes,
        ...personalCatchupsPageRoute.routes,
        ...myCatchupsPageRoute.routes,
        ...teamCatchupsPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        PeerCatchupAuthService.registerAccess(authorisationService);
    }
}
