<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Delete task map</h3>

    <div adapt-dialog-content
         class="delete-process-map-dialog-content">
        By deleting the
        <adapt-link-process-map [processMap]="processMap"></adapt-link-process-map>
        task map you will:
        <ul>
            <li>
                Delete all {{(steps$ | async)?.length}} process steps and role tasks
            </li>
            <!-- TODO Roles -->
            <li *adaptHideIfEmpty>
                Remove links to it from the following places:
                <adapt-list-process-map-links [processMap]="processMap"></adapt-list-process-map-links>
            </li>
        </ul>
        <dx-check-box [value]="confirmed"
                      (valueChange)="confirmed = $any($event)"
                      text="Check to confirm that you understand that this action cannot be undone"></dx-check-box>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="deleteAndClose"
                                   [saveIsDisabled]="!confirmed"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
