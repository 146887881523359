import { Component, Input } from "@angular/core";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { teamDashboardPageRoute } from "app/platform/team-dashboard/team-dashboard-page/team-dashboard-page.route";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-team-systems-dashboard-element",
    templateUrl: "./team-systems-dashboard-element.component.html",
    styleUrls: ["./team-systems-dashboard-element.component.scss"],
})
export class TeamSystemsDashboardElementComponent {
    @Input() public set team(value: Team | undefined) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);

    public teamSystemsHref$: Observable<string>;
    public systems$: Observable<SystemEntity[]>;

    public readonly readTier2Architecture = IntegratedArchitectureFrameworkAuthService.ReadTier2;

    public constructor(
        systemisationService: SystemisationService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        this.teamSystemsHref$ = this.team$
            .pipe(
                switchMap((team) => team
                    ? teamDashboardPageRoute.getRoute(team, { page: "systems" })
                    : EMPTY),
            );

        this.systems$ = this.team$.pipe(
            filter((i): i is Team => !!i),
            switchMap(this.emitWhenSystemsShouldBeFetched),
            switchMap((team) => systemisationService.getSystemsForTeam(team)),
        );
    }

    @Autobind
    private emitWhenSystemsShouldBeFetched(team: Team) {
        return this.rxjsBreezeService.entityTypeChanged(SystemLocation).pipe(
            map(() => team),
            startWith(team),
        );
    }
}
