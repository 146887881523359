<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Move {{documentType}}</h3>

    <div adapt-dialog-content
         class="d-flex flex-column">
        <div class="alert alert-info">Please specify the destination system for this {{documentType}}</div>
        <div class="mb-4">
            <h3>Destination system</h3>
            <adapt-select-system [system]="systemComponent.system"
                                 (systemChange)="onSystemChange($event)"
                                 [filter]="filterSystems"
                                 [allowCreate]="canCreate"
                                 [onlyEditable]="true"
                                 (newEntityChange)="onNewSystemChange($event)"></adapt-select-system>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Move & save"
                                   [saveIsDisabled]="isCreatingSystem"
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
