import { Component, Input, ViewChild } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { DisplayImpactSurveysComponent } from "../display-impact-surveys/display-impact-surveys.component";

@Component({
    selector: "adapt-configure-engagement",
    templateUrl: "./configure-engagement.component.html",
})
export class ConfigureEngagementComponent extends ConfigureFeatureBase {
    public currentStatus?: boolean;
    public surveyType = SurveyType.EmployeeEngagement;
    @Input() public configItem?: IConfigItem;

    @ViewChild(DisplayImpactSurveysComponent) private impactedSurveyInstance?: DisplayImpactSurveysComponent;

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public initialiseData(): Promise<void> {
        return Promise.resolve();
    }

    public onSave() {
        if (this.impactedSurveyInstance) {
            this.impactedSurveyInstance.onSave();
        }

        return super.onSave();
    }
}
