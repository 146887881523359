import { Component, Input, ViewChild } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ActivityFeedService } from "app/general/activity-feed/activity-feed.service";
import { ActivityFeedComponent } from "app/general/activity-feed/activity-feed/activity-feed.component";
import { ActivityFeedSignalRHub } from "app/general/activity-feed/activity-feed-signalr-hub";
import { lastValueFrom, ReplaySubject } from "rxjs";
import { first, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-team-activity-feed",
    templateUrl: "./team-activity-feed.component.html",
})
export class TeamActivityFeedComponent extends BaseComponent {
    @Input() public feedTitle = "Activity Feed";
    @Input() public set team(value: Team) {
        this.team$.next(value);
    }
    private team$ = new ReplaySubject<Team>(1);

    @ViewChild(ActivityFeedComponent, { static: false })
    public activityFeedComponent!: ActivityFeedComponent;

    public constructor(
        private activityFeedService: ActivityFeedService,
        activityFeedHub: ActivityFeedSignalRHub,
    ) {
        super();

        this.team$.pipe(
            tap(() => this.reloadActivity()),
            switchMap((team) => activityFeedHub.onTeamActivity(team.teamId)),
            switchMap(() => this.activityFeedComponent.promiseToFetchLatestActivity()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public promiseToGetInitialActivity() {
        return lastValueFrom(this.team$.pipe(
            first(),
            switchMap((team) => this.activityFeedService.getInitialTeamActivity(team)),
        ));
    }

    @Autobind
    public promiseToGetOlderActivity(olderThan: Date) {
        return lastValueFrom(this.team$.pipe(
            first(),
            switchMap((team) => this.activityFeedService.getOlderTeamActivity(team, olderThan)),
        ));
    }

    @Autobind
    public promiseToGetNewerActivity(newerThan: Date) {
        return lastValueFrom(this.team$.pipe(
            first(),
            switchMap((team) => this.activityFeedService.getNewerTeamActivity(team, newerThan)),
        ));
    }

    private reloadActivity() {
        if (this.activityFeedComponent) {
            this.activityFeedComponent.reloadActivity();
        }
    }
}
