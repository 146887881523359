<dx-data-grid class="p-2"
              noDataText="No Cultural Indexes"
              [dataSource]="data"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [wordWrapEnabled]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [stateStoring]="dxGridHelper.stateStoringOptions"
              (onInitialized)="onGridInitialized($event)">
    <dxo-column-fixing [enabled]="true">
    </dxo-column-fixing>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-scrolling [useNative]="false">
    </dxo-scrolling>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-group-panel [visible]="true">
    </dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true">
    </dxo-grouping>
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>
    <dxo-export [enabled]="true"
                fileName="Cultural Index Analysis">
    </dxo-export>

    <dxi-column caption="Person"
                dataField="person.fullName"
                dataType="string"
                cellTemplate="personCellTemplate"
                [fixed]="true"
                [width]="200">
    </dxi-column>

    <dxi-column [caption]="culturalLeadershipConfiguration?.primaryName"
                [calculateCellValue]="calculateCulturalLeadersCellValue"
                [calculateGroupValue]="calculateCulturalLeadersGroupValue"
                [customizeText]="culturalLeadersCustomizeText"
                dataType="string"
                dataField="dummy_culturalleader"
                cellTemplate="culturalLeaderCellTemplate"
                groupCellTemplate="culturalLeaderGroupCellTemplate"
                [groupIndex]="0"
                [allowSorting]="true"
                [allowGrouping]="true"
                [allowFiltering]="true"
                [width]="200">
    </dxi-column>

    <dxi-column caption="Cultural Index"
                dataField="culturalIndex.value"
                dataType="number"
                [customizeText]="culturalIndexUiService.customiseValueCellText"
                [cellTemplate]="culturalIndexUiService.getValueCellTemplate"
                [width]="150">
    </dxi-column>

    <dxi-column caption="Direction"
                dataField="culturalIndex.direction"
                dataType="string"
                [width]="120">
        <dxo-lookup [dataSource]="culturalIndexService.directions"
                    valueExpr="id"
                    displayExpr="label">
        </dxo-lookup>

    </dxi-column>

    <dxi-column caption="Date"
                dataField="culturalIndex.creationDate"
                dataType="date"
                [format]="DateFormats.globalize.short"
                [width]="100">
    </dxi-column>

    <dxi-column caption="Notes"
                dataField="culturalIndex.notes"
                cellTemplate="richTextTemplate"
                dataType="string"
                [encodeHtml]="false">
    </dxi-column>

    <div *dxTemplate="let item of 'personCellTemplate'">
        <adapt-link-person [person]="item.data.person"
                           [preventWrap]="true"></adapt-link-person>
    </div>
    <div *dxTemplate="let item of 'culturalLeaderCellTemplate'">
        <adapt-link-person [person]="item.data.culturalLeader"
                           [preventWrap]="true"
                           nullValueText="None allocated"></adapt-link-person>
    </div>
    <div *dxTemplate="let item of 'culturalLeaderGroupCellTemplate'">
        <div [ngSwitch]="!!item.value">
            <div *ngSwitchCase="true">
                <span>{{culturalLeadershipConfiguration?.primaryName}}</span>:
                <adapt-link-person [personId]="item.value"
                                   [preventWrap]="true"></adapt-link-person>
            </div>
            <div *ngSwitchCase="false">
                No <span>{{culturalLeadershipConfiguration?.primaryName}}</span>
            </div>
        </div>
    </div>
    <div *dxTemplate="let item of 'richTextTemplate'">
        <div [froalaView]="item.value"></div>
    </div>
</dx-data-grid>
