import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { ViewOption } from "app/features/architecture/organisation-map/organisation-explorer/organisation-explorer.component";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { TeamsUiService } from "../teams-ui.service";

@Component({
    selector: "adapt-navigate-teams-page",
    templateUrl: "./navigate-teams-page.component.html",
})
export class NavigateTeamsPageComponent extends BaseRoutedComponent implements OnInit {
    public ViewOption = ViewOption;
    public showMyTeams$ = new BehaviorSubject(false);
    public includePrivateTeams$ = new BehaviorSubject(false);

    private includePrivateTeamsMenuItem: IAdaptMenuItem = {
        text: "Show private teams",
        isChecked: this.includePrivateTeams$.value,
        onIsCheckedChange: (isChecked: boolean) => this.includePrivateTeams$.next(isChecked),
    };
    private addTeamMenuItem: IAdaptMenuItem = {
        text: "Add team",
        icon: "fal fa-fw fa-plus-square",
        onClick: () => this.addTeam(),
    };
    private showOnlyMyTeamsItem: IAdaptMenuItem = {
        text: "Show only my teams",
        isChecked: this.showMyTeams$.value,
        separator: true,
        onIsCheckedChange: (isChecked: boolean) => this.showMyTeams$.next(isChecked),
    };
    public pageMenuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            this.addTeamMenuItem,
            this.showOnlyMyTeamsItem,
            this.includePrivateTeamsMenuItem,
        ],
    }];

    private myTeams$ = new BehaviorSubject<Team[]>([]);

    @ViewChild(MenuComponent) private menuComponent?: MenuComponent;

    public constructor(
        private teamsService: CommonTeamsService,
        private teamsUiService: TeamsUiService,
        private rxjsBreezeService: RxjsBreezeService,
        private authService: AuthorisationService,
        injector: Injector,
    ) {
        super(injector);

        this.includePrivateTeamsMenuItem.isChecked = this.teamsService.isShowingPrivateTeams;
        this.includePrivateTeams$.next(this.includePrivateTeamsMenuItem.isChecked);
        this.includePrivateTeams$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((showPrivateTeams) => this.teamsService.isShowingPrivateTeams = showPrivateTeams);

        this.authService.getHasAccess(CommonTeamsAuthService.ConfigureTeam)
            .subscribe((val) => {
                this.addTeamMenuItem.visible = val;
                this.showOnlyMyTeamsItem.separator = val;
            });
    }

    public async ngOnInit() {
        this.rxjsBreezeService.entityTypeChanged(Team).pipe(
            debounceTime(100),
            startWith(undefined), // initial get
            this.takeUntilDestroyed(),
        ).subscribe(async () => {
            const teams = await this.teamsService.promiseToGetActiveTeamsForCurrentPerson();
            this.myTeams$.next(teams);
        });
    }

    private async addTeam() {
        const newTeam = await this.teamsUiService.promiseToCreateTeam();
        this.myTeams$.next(await this.teamsService.promiseToGetActiveTeamsForCurrentPerson());
        this.showMyTeams$.next(this.showMyTeams$.value);
        if (newTeam?.isPrivate && !this.includePrivateTeams$.value) {
            this.includePrivateTeams$.next(true);
            this.includePrivateTeamsMenuItem.isChecked = true;
            this.menuComponent?.refresh();
        }
    }

    @Autobind
    public filterForMyTeams(allTeams: Team[]) {
        return combineLatest([this.myTeams$, this.showMyTeams$, this.includePrivateTeams$]).pipe(
            map(([myTeams, showMyTeams, includePrivateTeams]) => {
                const teams = allTeams.filter((team) => includePrivateTeams || !team.isPrivate);
                myTeams = myTeams.filter((team) => includePrivateTeams || !team.isPrivate);
                return showMyTeams
                    ? teams.filter((t) => myTeams.includes(t)
                        || myTeams.some((mt) => mt.rootTeam === t))
                    : teams;
            }),
        );
    }
}
