<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ResilientBusinessZones"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-button-toggle-group adaptToolbarContent
                           alignment="center"
                           [(value)]="selectedViewOption"
                           (valueChange)="updateUrl($event)">
    <button *ngFor="let option of availableViewOptions"
            [adaptToggleGroupButton]="option.option"
            [adaptButtonIcon]="option.iconClass">{{option.text}}</button>
</adapt-button-toggle-group>
<div adaptToolbarContent
     [ordinal]="2001"
     *ngIf="isActivated">
    <adapt-menu [items]="pageMenuItems"
                data-test="orgmap-page-actions-button"></adapt-menu>
</div>

<adapt-organisation-explorer [selectedViewOption]="selectedViewOption"
                             [filterTeams]="filterForTeams"
                             (loaded)="notifyActivated()"></adapt-organisation-explorer>
