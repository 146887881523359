import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { UserService } from "@common/user/user.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { lastValueFrom } from "rxjs";
import { switchMap, take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class PeerCatchupAuthService {
    public static readonly ReadAnyPeerCatchups = "readAnyPeerCatchups";
    public static readonly OpenPeerCatchupDashboard = "openPeerCatchupDashboard";
    public static readonly EditMyPeerCatchup = "editMyPeerCatchup";
    public static readonly ReadMyPeerCatchup = "readMyPeerCatchup";
    public static readonly EditAllPeerCatchups = "editAllPeerCatchups";
    public static readonly ReadAllPeerCatchups = "readAllPeerCatchups";
    public static readonly EditPeerCatchupsForPerson = "editPeerCatchupsForPerson";
    public static readonly ReadPeerCatchupsForPerson = "readPeerCatchupsForPerson";

    public constructor(
        private authService: AuthorisationService,
        private userService: UserService,
    ) { }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.ReadMyPeerCatchup,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead,
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.EditMyPeerCatchup,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.ReadAllPeerCatchups,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpRead,
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.EditAllPeerCatchups,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.OpenPeerCatchupDashboard,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead,
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                    FeaturePermissionName.PeoplePeerCatchUpRead,
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.ReadPeerCatchupsForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToReadPeerCatchUpsForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.EditPeerCatchupsForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToEditPeerCatchUpsForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            PeerCatchupAuthService.ReadAnyPeerCatchups,
            {
                invokeToGetAccessVerifier: (injector) => {
                    const commonDataService = injector.get(CommonDataService);
                    return getVerifyAccessToReadAnyPeerCatchUps(commonDataService);
                },
            },
        );

        function getVerifyAccessToReadPeerCatchUpsForPerson() {
            return verifyAccessToReadPeerCatchUpsForPersonFunction;

            function verifyAccessToReadPeerCatchUpsForPersonFunction(currentPerson: Person, destPerson: Person) {
                if (destPerson.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(PeerCatchupAuthService.ReadMyPeerCatchup);
                }

                // this verifier is only used in the personContent popup and personal dashboard
                return authorisationService.promiseToVerifyAccess(PeerCatchupAuthService.ReadAllPeerCatchups)
                    .then(verifyDestPersonCanReadPcu);

                function verifyDestPersonCanReadPcu() {

                    // if they don't have permission and it's an active connection, then disallow
                    // (if they don't have an active connection, we just let it through because we never know if they had a historical connection...
                    // ...because we delete access level history)
                    const hasPermission = authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalEdit)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpRead)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpEdit);
                    const hasActiveConnection = !!destPerson.getActiveConnections().length;
                    if (!hasPermission && hasActiveConnection) {
                        return Promise.reject();
                    }

                    return Promise.resolve();
                }
            }
        }

        function getVerifyAccessToEditPeerCatchUpsForPerson() {
            return verifyAccessToEditPeerCatchUpsForPersonFunction;

            function verifyAccessToEditPeerCatchUpsForPersonFunction(currentPerson: Person, destPerson: Person) {
                if (destPerson.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(PeerCatchupAuthService.EditMyPeerCatchup);
                }

                return authorisationService.promiseToVerifyAccess(PeerCatchupAuthService.EditAllPeerCatchups)
                    .then(verifyDestPersonCanEditPcu);

                function verifyDestPersonCanEditPcu() {
                    // if the person is not allowed to edit his own PCU, no one else should be
                    // - this includes person made inactive - TSL won't be able to edit PCU for person who left
                    if (authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalEdit) ||
                        authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpEdit)) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject();
                    }
                }
            }
        }

        function getVerifyAccessToReadAnyPeerCatchUps(commonDataService: CommonDataService) {
            return verifyAccessToReadAnyPeerCatchUpsFunction;

            function verifyAccessToReadAnyPeerCatchUpsFunction(currentPerson: Person) {
                return authorisationService.promiseToVerifyAccess(PeerCatchupAuthService.ReadMyPeerCatchup)
                    .catch(verifyAccessToTeamCatchUp);

                async function verifyAccessToTeamCatchUp() {
                    const roleConnections = await lastValueFrom(new IntegratedArchitectureFrameworkQueryUtilities(commonDataService).getRoleConnectionsForPersonId(currentPerson.personId));
                    if (roleConnections.some((roleConnection: RoleConnection) =>
                        authorisationService.roleConnectionHasPermission(roleConnection, FeaturePermissionName.PeoplePeerCatchUpRead))) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject();
                    }
                }
            }
        }
    }

    public get canReadAnyPeerCatchups() {
        return this.authService.getHasAccess(PeerCatchupAuthService.ReadAnyPeerCatchups).pipe(
            take(1),
        );
    }

    public get canEditPersonalCatchups() {
        return this.authService.getHasAccess(PeerCatchupAuthService.EditMyPeerCatchup).pipe(
            take(1), // not using $ suffix if observable is not long-live
        );
    }


    public get canEditAllCatchups() {
        return this.authService.getHasAccess(PeerCatchupAuthService.EditAllPeerCatchups).pipe(
            take(1),
        );
    }

    public get canReadAllCatchups() {
        return this.authService.getHasAccess(PeerCatchupAuthService.ReadAllPeerCatchups).pipe(
            take(1),
        );
    }

    public canEditCatchup(catchup: SpeedCatchup) {
        return this.userService.currentPerson$.pipe(
            switchMap((currentPerson) => {
                if (currentPerson === catchup.person1 || currentPerson === catchup.person2 ||
                    currentPerson === catchup.facilitatorPerson) {
                    return this.canEditPersonalCatchups;
                } else {
                    return this.canEditAllCatchups;
                }
            }),
        );
    }

    public canEditCatchupsForPerson(person: Person) {
        return this.authService.getHasAccess(PeerCatchupAuthService.EditPeerCatchupsForPerson, person).pipe(
            take(1),
        );
    }

    public canReadCatchupsForPerson(person: Person) {
        return this.authService.getHasAccess(PeerCatchupAuthService.ReadPeerCatchupsForPerson, person).pipe(
            take(1),
        );
    }
}
