<dx-select-box #focus
               [value]="keyFunction"
               (valueChange)="keyFunctionChange.emit($event)"
               [dataSource]="dataSource"
               displayExpr="name"
               [searchEnabled]="true"
               [searchExpr]="$any('name')"
               [grouped]="true"
               [disabled]="disabled"
               placeholder="Select key function..."
               data-test="select-key-function">
    <div *dxTemplate="let item of 'group'">
        <adapt-zone-label [zone]="item.key.zone"
                          [valueStream]="item.key.valueStream"></adapt-zone-label>
    </div>
</dx-select-box>