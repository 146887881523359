<adapt-dashboard-element *ngIf="hasActiveConnection$ | async"
                         name="Roles"
                         data-test="person-profile-roles">
    <div adapt-dashboard-title-buttons
         *adaptIfAuthorised="ManagePositionAndRoles;Entity:(person$ | async)">
        <button adaptButton="editBorderless"
                *ngIf="editMode"
                (click)="editRoles()"></button>
    </div>

    <ng-container *adaptLoadingUntilFirstEmit="let roleConnections of roleConnections$">
        <ng-container *ngIf="roleConnections.length > 0;else noRolesTemplate">
            <div *ngFor="let roleConnections of roleConnections">
                <adapt-link-role [role]="roleConnections.role"></adapt-link-role>
                <small class="ps-1">Started {{roleConnections.startDate | adaptDate}}</small>
            </div>
        </ng-container>

        <ng-template #noRolesTemplate>
            This person does not have any active roles in the organisation
        </ng-template>
    </ng-container>
</adapt-dashboard-element>
