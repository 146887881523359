import { Component, Input } from "@angular/core";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-person-profile-cultural-leader",
    templateUrl: "./person-profile-cultural-leader.component.html",
})
export class PersonProfileCulturalLeaderComponent extends BaseComponent {
    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    private person$ = new BehaviorSubject<Person | undefined>(undefined);
    public culturalRelationship$: Observable<CulturalRelationship | undefined>;
    public culturalLeaderName$: Observable<string>;
    public hasActiveEmployeeConnection$: Observable<boolean>;

    constructor(
        clfService: CulturalLeadershipFrameworkService,
        commonDataService: CommonDataService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const entityChange$ = rxjsBreezeService.entityTypeChangedInSave(RoleConnection).pipe(
            startWith(undefined),
        );

        const setPerson$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
        );

        this.hasActiveEmployeeConnection$ = combineLatest([entityChange$, setPerson$]).pipe(
            map(([_change, person]) => {
                const connection = person.getLatestEmployeeConnection();
                if (!connection) {
                    return false;
                }

                return connection.isActive();
            }),
        );

        this.culturalRelationship$ = setPerson$.pipe(
            switchMap(async (person) => await new CulturalLeadershipQueryUtilities(commonDataService).promiseToGetCulturalRelationshipsForPersonId(person.personId)),
            map((relationships) => relationships.filter((r) => !r.isSupporting)),
            switchMap((relationships) => of(ArrayUtilities.getSingleFromArray(relationships))),
        );

        this.culturalLeaderName$ = from(clfService.promiseToGetCulturalLeadershipConfiguration() as Promise<CulturalLeadership>)
            .pipe(
                map((config) => config!.primaryName),
            );
    }
}
