import { AfterViewInit, Component, Inject } from "@angular/core";
import { IHelpTourConfig } from "@common/shell/help-tour/help-tour.service";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ADAPT_DIALOG_DATA } from "../adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "../adapt-common-dialog.service";

export interface ITutorialDialogData extends IHelpTourConfig {
    result?: boolean;
}

@Component({
    selector: "adapt-tutorial-dialog",
    templateUrl: "./tutorial-dialog.component.html",
})
export class TutorialDialogComponent extends BaseDialogComponent<ITutorialDialogData> implements AfterViewInit {
    public readonly dialogName = "TutorialDialog";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: ITutorialDialogData,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();

        if (this.popupInstance) {
            // center the popup
            this.popupInstance.position = {
                my: "center",
                at: "center",
                of: window,
                offset: { x: 0, y: 0 },
            };
        }
    }

    public get imageStyle() {
        const thumb = this.dialogData.thumbnail || "/content/ux/content-with-splash-image/assets/splash-image-1.jpg";
        return { backgroundImage: `url(${thumb})`, backgroundSize: "cover" };
    }

    public watchTutorial() {
        this.dialogService.openFullscreenDialog(this.dialogData);
        this.resolve(this.dialogData);
    }

    public close() {
        this.resolve(this.dialogData);
    }
}
