<ng-container *adaptLoadingUntilFirstEmit="let team of team$">
    <ng-container *adaptLoadingUntilFirstEmit="let teams of childTeams$">
        <ng-container *ngIf="teams.length > 0 || team?.parentTeam">
            <h3 class="mb-3">Team Hierarchy</h3>

            <ng-container *ngIf="team?.parentTeam">
                <adapt-link-team class="mb-2"
                                 [team]="team!.parentTeam"
                                 [showIcon]="true"
                                 [showChildCount]="false"></adapt-link-team>
                <small class="ms-1">(Parent team)</small>
            </ng-container>
            <div [ngClass]="{'child': !!team?.parentTeam}">
                <adapt-link-team class="mb-2"
                                 [team]="team"
                                 [adaptLinkDisabled]="true"
                                 [showIcon]="true"
                                 [showIconBorder]="true"
                                 [showChildCount]="false"></adapt-link-team>
                <small class="ms-1">(Current team)</small>

                <ng-container *ngIf="teams.length > 0">
                    <div *ngFor="let team of teams"
                         class="mb-2 child">
                        <adapt-link-team [team]="team"
                                         [showIcon]="true"
                                         [showChildCount]="true"></adapt-link-team>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
