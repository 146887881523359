import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { culturalLeadershipFrameworkPageRoute } from "app/features/culture/cultural-leadership/clf-page/clf-page.route";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "app/features/culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import dxPolarChart, { InitializedEvent, PointClickEvent } from "devextreme/viz/polar_chart";
import { switchMap } from "rxjs/operators";
import { CulturalIndexHistoryDialogComponent } from "../ci-history-dialog/ci-history-dialog.component";
import { CulturalIndexService, ICulturalIndexChartData } from "../cultural-index.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";

@Component({
    selector: "adapt-ci-orbit-chart",
    templateUrl: "./ci-orbit-chart.component.html",
})
export class CulturalIndexOrbitChartComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;
    @Input() public compact = false;

    public data: ICulturalIndexChartData[] = [];
    public filterParameters?: ICulturalLeadershipMeasurementAnalysisFilter;
    public canViewCulturalLeadershipFramework = false;
    public culturalLeadershipFrameworkUrl = "";

    public isLoading = true;

    private chart?: dxPolarChart;

    public title?: string = "Cultural orbit";
    public height = this.responsiveService.currentBreakpoint.isMobileSize ? 300 : 600;
    public marginBottom = 50;

    public constructor(
        public responsiveService: ResponsiveService,
        private culturalIndexService: CulturalIndexService,
        public culturalIndexUiService: CulturalIndexUiService,
        private authorisationService: AuthorisationService,
        private routeService: RouteService,
        private commonDialogService: AdaptCommonDialogService,
        private culturalIndexFilterService: CulturalIndexFilterService,
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChangedInSave(CulturalIndex)
            .pipe(
                switchMap(() => this.getDataAndRefreshChart()),
                this.takeUntilDestroyed(),
            )
            .subscribe();

        this.culturalIndexFilterService.filterListener
            .pipe(
                switchMap(() => this.getDataAndRefreshChart()),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public async ngOnInit() {
        if (this.compact) {
            this.title = undefined;
            this.height = 300;
            this.marginBottom = 0;
        }

        [this.canViewCulturalLeadershipFramework, this.culturalLeadershipFrameworkUrl] = await Promise.all([
            this.authorisationService.promiseToGetHasAccess(CulturalLeadershipFrameworkAuthService.ReadCohorts),
            this.routeService.getControllerRoute(culturalLeadershipFrameworkPageRoute.id),
        ]);

        await this.getDataAndRefreshChart();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.date) {
            this.getDataAndRefreshChart();
        }
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        return {
            html: this.culturalIndexUiService.getChartDataTooltip(info.point.tag, true),
        };
    }

    @Autobind
    public onInitialized(e: InitializedEvent) {
        this.chart = e.component!;
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));

        // set the strips in the post since the culturalIndexService has to have finished initialising prior to settings the strip labels/colours
        this.culturalIndexUiService.promiseToSetChartStrips(this.chart);
    }

    @Autobind
    public onPointClick(info: PointClickEvent) {
        info.component.hideTooltip();
        const culturalIndex: CulturalIndex = info.target!.tag;

        if (!culturalIndex.person) {
            return;
        }

        return this.commonDialogService.open(CulturalIndexHistoryDialogComponent, culturalIndex.person).subscribe();
    }

    @Autobind
    private async getDataAndRefreshChart() {
        const filterParameters = { ...this.culturalIndexFilterService.filter, date: this.date };
        const data = await this.culturalIndexService.promiseToGetCulturalIndexAnalysisData(filterParameters);
        this.setData(data);
        this.isLoading = false;
    }

    private setData(data: ICulturalLeadershipPersonalAnalysisData[]) {
        this.data = data
            .filter((x) => !!x.culturalIndex)
            .map((x) => x.culturalIndex)
            .map((x, i) => this.culturalIndexUiService.createCulturalIndexChartObject(x, i));
    }
}
