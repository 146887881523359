import { Component, Input } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleFeaturePermission } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { FeaturePermissionTranslatorService } from "@common/feature/feature-permission-translator.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { lastValueFrom } from "rxjs";

enum CulturalAccess {
    All,
    Quantitative,
    Anonymous,
}

enum PeerCatchUpAccess {
    Edit,
    Read,
    None,
}

@Component({
    selector: "adapt-configure-cultural-leadership",
    templateUrl: "./configure-cultural-leadership.component.html",
})
export class ConfigureCulturalLeadershipComponent extends ConfigureFeatureBase {
    public configuration?: CulturalLeadership;
    @Input() public configItem?: IConfigItem;

    public culturalAccess: CulturalAccess = CulturalAccess.All;
    public readonly culturalAccessSelections = [
        {
            text: "View and edit all quantitative (numerical) and qualitative (written) data",
            value: CulturalAccess.All,
        },
        {
            text: "View only quantitative (numerical) data",
            value: CulturalAccess.Quantitative,
        },
        {
            text: "View only anonymous data",
            value: CulturalAccess.Anonymous,
        },
    ];

    public pcuAccess: PeerCatchUpAccess = PeerCatchUpAccess.Edit;
    public readonly pcuAccessSelections = [
        {
            text: this.featureTranslationService.translatePermissionLiteral(FeaturePermissionName.PeoplePeerCatchUpEdit),
            value: PeerCatchUpAccess.Edit,
        },
        {
            text: this.featureTranslationService.translatePermissionLiteral(FeaturePermissionName.PeoplePeerCatchUpRead),
            value: PeerCatchUpAccess.Read,
        },
        {
            text: "No access",
            value: PeerCatchUpAccess.None,
        },
    ];

    constructor(
        configurationService: ConfigurationService,
        private clFrameworkService: CulturalLeadershipFrameworkService,
        private commonDataService: CommonDataService,
        private featureTranslationService: FeaturePermissionTranslatorService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.configuration = await this.clFrameworkService.promiseToGetCulturalLeadershipConfiguration();

        const clRole = await this.getCulturalLeaderRole();
        const existingPermissions = this.getCulturalLeaderRolePermissions(clRole);
        if (existingPermissions.find((i) => i.featurePermission.name === FeaturePermissionName.CulturalNetworkCareerValuationRead)) {
            this.culturalAccess = CulturalAccess.All;
        } else if (existingPermissions.find((i) => i.featurePermission.name === FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead)) {
            this.culturalAccess = CulturalAccess.Quantitative;
        } else {
            this.culturalAccess = CulturalAccess.Anonymous;
        }

        if (existingPermissions.find((i) => i.featurePermission.name === FeaturePermissionName.PeoplePeerCatchUpEdit)) {
            this.pcuAccess = PeerCatchUpAccess.Edit;
        } else if (existingPermissions.find((i) => i.featurePermission.name === FeaturePermissionName.PeoplePeerCatchUpRead)) {
            this.pcuAccess = PeerCatchUpAccess.Read;
        } else {
            this.pcuAccess = PeerCatchUpAccess.None;
        }
    }

    private async getCulturalLeaderRole() {
        const predicate = new MethodologyPredicate<Role>("roleType.code", "==", RoleTypeCode.CulturalLeader);
        const clRoles = await lastValueFrom(this.commonDataService.getByPredicate(RoleBreezeModel, predicate));
        const clRole = ArrayUtilities.getSingleFromArray(clRoles);
        if (!clRole) {
            throw new Error("Invalid CL configuration");
        }

        return clRole;
    }

    private getCulturalLeaderRolePermissions(clRole: Role) {
        const existingPermissions: RoleFeaturePermission[] = [];
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCareerValuationRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.CulturalNetworkCulturalIndexRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.PeoplePeerCatchUpEdit));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.PeoplePeerCatchUpRead));
        ArrayUtilities.addElementIfNotAlreadyExists(existingPermissions, clRole.extensions.getPermission(FeaturePermissionName.PeoplePeerCatchUpAnonymousRead));

        return existingPermissions
            .filter((p) => !!p);
    }

    public async onPrimaryNameChange(value: string) {
        if (value) {
            const clRole = await this.getCulturalLeaderRole();
            clRole.label = value;
        }
    }

    public async accessChanged() {
        const clRole = await this.getCulturalLeaderRole();
        const existingPermissions = this.getCulturalLeaderRolePermissions(clRole);

        if (this.culturalAccess === CulturalAccess.All) {
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCareerValuationRead);
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCulturalIndexRead);
        } else if (this.culturalAccess === CulturalAccess.Quantitative) {
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead);
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead);
        } else {
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead);
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead);
        }

        if (this.pcuAccess === PeerCatchUpAccess.Edit) {
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.PeoplePeerCatchUpEdit);
        } else if (this.pcuAccess === PeerCatchUpAccess.Read) {
            await this.restoreOrCreateRolePermission(existingPermissions, clRole, FeaturePermissionName.PeoplePeerCatchUpRead);
        }

        // only delete after all the above so that featurePermission nav property is still available in the checks above
        // - once deleted, nav property will become null
        if (existingPermissions.length > 0) {
            await Promise.all(existingPermissions.map((permission) => lastValueFrom(this.commonDataService.remove(permission))));
        }
    }

    private async restoreOrCreateRolePermission(existingRolePermissions: RoleFeaturePermission[], role: Role, permissionName: FeaturePermissionName) {
        const matchedRolePermission = existingRolePermissions.find((i) => i.roleId === role.roleId && i.featurePermission.name === permissionName);
        if (!matchedRolePermission) {
            await lastValueFrom(new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService).createRolePermission(role, permissionName));
        } else {
            // so that it won't be deleted later -> so make sure the same feature permission is not added more than once to the same role
            ArrayUtilities.removeElementFromArray(matchedRolePermission, existingRolePermissions);
        }
    }
}
