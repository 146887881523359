<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Merge guidance materials</h3>
    <div adapt-dialog-content>
        <div class="row center-content"
             *adaptLoadingUntilFirstEmit="let emit of primeGuidanceMaterials$">
            <div class="col-5">
                <dx-accordion [items]="guidanceMaterials"
                              [collapsible]="true"
                              [selectedIndex]="-1"
                              [deferRendering]="false"
                              [multiple]="true">
                    <div *dxTemplate="let guidanceMaterial of 'title'">
                        <span>{{guidanceMaterial.name}}</span>
                    </div>
                    <div *dxTemplate="let guidanceMaterial of 'item'">
                        <adapt-select-document *ngIf="guidanceMaterial.locationUrl"
                                               [url]="guidanceMaterial.locationUrl"
                                               [readOnly]="true"></adapt-select-document>
                        <div class="metadata">
                            <span *ngIf="!guidanceMaterialUsage[guidanceMaterial.guidanceMaterialId]?.length; else showUsageCount">
                                Not used.
                            </span>
                            <ng-template #showUsageCount>
                                <span>Used in {{guidanceMaterialUsage[guidanceMaterial.guidanceMaterialId].length}} role tasks.</span>
                            </ng-template>
                        </div>
                    </div>
                </dx-accordion>
            </div>
            <div class="col-2 text-center">
                <h3>INTO <i class="fal fa-fw fa-long-arrow-right"></i></h3>
            </div>
            <div class="col-5 text-end">
                <dx-select-box #focus
                               [dataSource]="guidanceMaterials"
                               displayExpr="name"
                               (valueChange)="destinationGuidanceMaterial = $event"
                               data-test="merge-into-button"></dx-select-box>
            </div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveButtonPreset]="{buttonClass: 'btn btn-danger', iconClass: 'fal fa-compress', buttonText: 'Merge'}"
                                   [saveIsDisabled]="!destinationGuidanceMaterial"
                                   [saveBlockingClick]="merge"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
