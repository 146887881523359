import { Component, ElementRef, Input, OnChanges, OnInit } from "@angular/core";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { IDxGridColumnCellInfo } from "@common/ux/dx.types";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { ExportingEvent, InitializedEvent } from "devextreme/ui/data_grid";
import { CareerValuationService } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";

interface ICulturalLeaderNameMap { [key: number]: string; }

@Component({
    selector: "adapt-cvt-organisation-grid",
    templateUrl: "./analyse-cvt-organisation-grid.component.html",
})
export class CareerValuationOrganisationGridComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;

    public isLoading = true;

    public culturalLeadershipConfiguration!: CulturalLeadership;
    public culturalLeaderNameMap: ICulturalLeaderNameMap = {}; // to lookup customizeText as suggested by https://www.devexpress.com/Support/Center/Question/Details/T403719/dxdatagrid-how-to-enable-custom-group-order
    public data: ICulturalLeadershipPersonalAnalysisData[] = [];
    public categories: CareerValuationCategory[] = [];
    public filterParameters?: ICareerValuationAnalysisFilter;
    public dxGridHelper: DxGridWrapperHelper;

    public headerFilter = { dataSource: this.careerValuationUiService.getValueHeaderOptions() };
    public dateFormat = DateFormats.globalize.short;

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private careerValuationService: CareerValuationService,
        public careerValuationUiService: CareerValuationUiService,
        private filterService: CareerValuationFilterService,
        private orgService: OrganisationService,
    ) {
        super();

        this.filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
            this.updateData();
        });

        this.dxGridHelper = new DxGridWrapperHelper("adapt-cvt-organisation-grid", jQuery(elementRef.nativeElement));
        this.dxGridHelper.saveGridState(`${this.orgService.getOrganisationId()}`);
        this.dxGridHelper.callGrid((grid) => grid.setColumnsReady());
    }

    public async ngOnInit() {
        this.culturalLeadershipConfiguration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
        await this.updateData();
        this.isLoading = false;
    }

    public ngOnChanges() {
        this.updateData();
    }

    @Autobind
    private async updateData() {
        const filterParameters = { ...this.filterParameters!, date: this.date };
        this.data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(filterParameters);
        this.categories = await this.careerValuationService.promiseToGetCareerValuationCategories();
    }

    public calculateCulturalLeadersCellValue(data: ICulturalLeadershipPersonalAnalysisData) {
        if (data.culturalLeader) {
            return data.culturalLeader.fullName;
        }

        return undefined;
    }

    @Autobind
    public calculateCulturalLeadersGroupValue(data: ICulturalLeadershipPersonalAnalysisData) {
        if (data.culturalLeader) {
            this.culturalLeaderNameMap[data.culturalLeader.personId] = data.culturalLeader.fullName;

            return data.culturalLeader.personId;
        }

        return undefined;
    }

    @Autobind
    public culturalLeadersCustomizeText(e: IDxGridColumnCellInfo) {
        if (e.valueText) {
            return this.culturalLeaderNameMap[Number(e.valueText)] || e.valueText;
        }

        return "None allocated";
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.dxGridHelper.initialiseGrid(e);

        e.component!.option().onToolbarPreparing = this.dxGridHelper.addResetToolbarButton;
    }

    public async onGridExporting(event: ExportingEvent) {
        const columnCount = event.component.columnCount();
        const orginalVisiblity: boolean[] = [];

        event.component.beginUpdate();

        for (let index = 0; index < columnCount; index++) {
            orginalVisiblity.push(
                event.component.columnOption(index, "visible"),
            );

            event.component.columnOption(index, "visible", true);
        }

        await DxUtilities.exportGridToExcel("Career Valuation Analysis", event.component);

        for (let index = 0; index < columnCount; index++) {

            event.component.columnOption(index, "visible", orginalVisiblity[index]);
        }

        event.component.endUpdate();
    }
}
