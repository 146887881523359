import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation } from "@common/ADAPT.Common.Model/organisation/team-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IDxListItemDeletedEvent } from "@common/ux/dx.types";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrganisationMapService } from "app/features/architecture/organisation-map/organisation-map.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { lastValueFrom } from "rxjs";
import { TeamsService } from "../teams.service";

@Component({
    selector: "adapt-edit-team-locations",
    templateUrl: "./edit-team-locations.component.html",
})
export class EditTeamLocationsComponent implements OnInit {
    @Input() public team!: Team;
    @Output() public populated = new EventEmitter<IBreezeEntity[]>();
    @Output() public added = new EventEmitter<IBreezeEntity>();

    public teamLocations: TeamLocation[] = [];
    public allLocations: (Partial<TeamLocation> & { name: string })[] = [];
    public allChildrenTeams: Team[] = [];

    public dataSource?: DataSource;

    public getAsZoneLocation = TeamLocation.getAsZoneLocation;
    public getAsKeyFunctionLocation = TeamLocation.getAsKeyFunctionLocation;

    public constructor(
        private commonDataService: CommonDataService,
        private orgService: OrganisationService,
        private orgMapService: OrganisationMapService,
        private teamsService: TeamsService,
        private keyFunctionService: KeyFunctionsService,
    ) {
    }

    public async ngOnInit() {
        await this.refreshData();
        this.teamLocations.sort(TeamLocation.compare);
        this.populated.emit(this.teamLocations);
    }

    public async refreshData() {
        this.allChildrenTeams = this.getAllChildTeams(this.team);

        this.allLocations = [];

        for (const zone of ZoneMetadata.InOrder) {
            let name = ZoneMetadata.Name[zone];

            if (zone === Zone.ResearchAndDevelopment) {
                const config = await lastValueFrom(this.orgService.getOrganisationConfiguration());
                if (config && config.productZoneName) {
                    name = config.productZoneName;
                }
            }

            this.allLocations.push({ zone, name });
        }

        const keyFunctions = await lastValueFrom(this.keyFunctionService.getAllKeyFunctions());
        for (const kf of keyFunctions) {
            this.allLocations.push({ keyFunction: kf, keyFunctionId: kf.keyFunctionId, name: kf.name });
        }

        this.teamLocations = this.team
            ? await lastValueFrom(this.teamsService.getTeamLocations(this.team))
            : [];

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.allLocations,
            }),
            group: TeamLocation.group,
            filter: this.filterOutExistingLocations,
        } as DataSourceOptions<TeamLocation>);
    }

    @Autobind
    public filterOutExistingLocations(location: Partial<TeamLocation>) {
        return !this.teamLocations.find((i) => i.keyFunctionId === location.keyFunctionId
            || i.zone === location.zone);
    }

    public async onSelectValueChange(event: ValueChangedEvent) {
        if (event.value && event.previousValue !== event.value) {
            event.component.reset();

            let teamLocation: TeamLocation | undefined;

            const zone = TeamLocation.getAsZoneLocation(event.value);
            if (zone) {
                teamLocation = await lastValueFrom(this.orgMapService.addTeamToZone(this.team, zone));
            }

            const kf = TeamLocation.getAsKeyFunctionLocation(event.value);
            if (kf) {
                teamLocation = await lastValueFrom(this.keyFunctionService.addTeamToKeyFunction(this.team, kf));
            }

            if (teamLocation) {
                this.added.emit(teamLocation);
            }

            await this.refreshData();
        }
    }

    public async removeTeamLocation(e: IDxListItemDeletedEvent<TeamLocation>) {
        await lastValueFrom(this.keyFunctionService.remove(e.itemData));
        ArrayUtilities.removeElementFromArray(e.itemData, this.teamLocations);
        await this.refreshData();
    }

    @Autobind
    public filterTeam(team: Team) {
        return team !== this.team
            && team.parentTeam !== this.team
            && !this.allChildrenTeams.includes(team);
    }

    public async onParentTeamChanged(team?: Team) {
        this.team.parentTeam = team;
        this.added.emit(this.team);

        await Promise.all(this.teamLocations.map((loc): Promise<any> => {
            this.added.emit(loc);

            return this.team.parentTeam
                ? lastValueFrom(this.commonDataService.remove(loc))
                : lastValueFrom(this.commonDataService.rejectChanges(loc));
        }));
    }

    private getAllChildTeams(team: Team, teams?: Team[]) {
        teams = teams ?? [];

        for (const childTeam of team.childTeams) {
            this.getAllChildTeams(childTeam, teams);
            teams.push(childTeam);
        }

        return teams;
    }
}
