<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div class="mb-4">
            <h3>Video name</h3>
            <dx-text-box #focus
                         [value]="data.systemComponent.name"
                         (valueChange)="onNameChange($event)"
                         [adaptValidateEntity]="data.systemComponent"
                         adaptValidateEntityProperty="name">
            </dx-text-box>
        </div>
        <div class="mb-4">
            <h3>Video link</h3>
            <dx-text-box [(value)]="data.systemDocument.url"
                         (valueChange)="onUrlChanged($event)"
                         [isValid]="!!data.systemDocument.content"
                         [validationError]="{ message: 'Invalid video link' }"
                         placeholder="Paste a URL from YouTube, Vimeo or other video hosting service"></dx-text-box>
        </div>
        <div class="mb-4"
             *ngIf="!!data.systemDocument.content">
            <h3>Preview</h3>
            <div [froalaView]="data.systemDocument.content"></div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveIsDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
