<dx-select-box [dataSource]="dataSource"
               [grouped]="true"
               (onValueChanged)="onSelectValueChange($event)"
               fieldTemplate="field"
               placeholder="Select item...">
        <span *dxTemplate="let area of 'item'">
            <ng-container [ngTemplateOutlet]="items"
                          [ngTemplateOutletContext]="{$implicit: area}"></ng-container>
        </span>
    <div *dxTemplate="let area of 'field'">
        <div *ngIf="!!area; else noValueTemplate">
            <ng-container [ngTemplateOutlet]="items"
                          [ngTemplateOutletContext]="{$implicit: area}"></ng-container>
            <dx-text-box class="d-none"
                         value="hidden element required by dxTemplate"
                         [readOnly]="true"></dx-text-box>
        </div>
    </div>
    <ng-template #noValueTemplate>
        <dx-text-box value=""
                     placeholder="Select item..."
                     [readOnly]="true"></dx-text-box>
    </ng-template>
</dx-select-box>

<ng-template #items let-item>
    <adapt-link-key-function *ngIf="isKeyFunction(item) as kf; else isTeam"
                             [keyFunction]="kf"
                             [adaptLinkDisabled]="true"></adapt-link-key-function>
    <ng-template #isTeam>
        <adapt-link-team [team]="item"
                         [showIcon]="true"
                         [adaptLinkDisabled]="true"></adapt-link-team>
    </ng-template>
</ng-template>