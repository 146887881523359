import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ProcessMapService } from "../../process-map.service";

@Component({
    selector: "adapt-prefix-set-step",
    templateUrl: "./prefix-set-step.component.html",
    styleUrls: ["../exporter-style.scss"],
})
export class PrefixSetStepComponent extends BaseComponent implements OnInit {
    @Input() public prefix?: string;
    @Output() public prefixChange = new EventEmitter<string>();

    @Input() public processMap!: ProcessMap;

    public processSteps: ProcessStep[] = [];

    constructor(private processMapService: ProcessMapService) {
        super();
    }

    public ngOnInit() {
        this.processMapService.getTopProcessMapSteps(this.processMap).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((processSteps) => this.processSteps = processSteps);
    }

    public onPrefixChanged(prefix?: string) {
        this.prefixChange.emit(prefix);
    }
}
