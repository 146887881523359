import { CulturalLeadershipConnectionStatusFilter } from "../../culture/cultural-leadership/cultural-leadership-connection-status-filter.enum";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "../../culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";

export interface ICareerValuationAnalysisFilter extends ICulturalLeadershipMeasurementAnalysisFilter {
    minimumIdealValue?: number;
    maximumActualPercentage?: number;
}

export class CareerValuationAnalysisFilter {
    public static getDefaultFilterParameters() {
        return {
            category1: true,
            category2: true,
            category3: true,
            category4: true,
            showPeopleWithoutCulturalLeader: true,
            connectionStatus: CulturalLeadershipConnectionStatusFilter.ActiveOnly,
            showOverdueOnly: false,
            showTrendOutwardsOnly: false,
            minimumIdealValue: 20,
        } as ICareerValuationAnalysisFilter;
    }

    public static getDefaultDifferentialFilterParameters() {
        return {
            category1: true,
            category2: true,
            category3: true,
            category4: true,
            showPeopleWithoutCulturalLeader: true,
            connectionStatus: CulturalLeadershipConnectionStatusFilter.ActiveOnly,
            showOverdueOnly: false,
            showTrendOutwardsOnly: false,
            minimumIdealValue: 20,
            maximumActualPercentage: 70,
        } as ICareerValuationAnalysisFilter;
    }
}
