import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class GuidanceMaterial extends BaseEntity<GuidanceMaterial> {
    public guidanceMaterialId!: number;
    public organisationId!: number;
    public name!: string;
    public description?: string;
    public locationUrl!: string;
    public organisation!: Organisation;
}

export const GuidanceMaterialBreezeModel = new BreezeModelBuilder("GuidanceMaterial", GuidanceMaterial)
    .hasSource()
    .withIdField("guidanceMaterialId")
    .withLabelField("name")
    .orderBy("name")
    .isOrganisationEntity()
    .build();
