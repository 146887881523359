<div *ngIf="processStep && processStepStackFrames?.length"
     class="d-inline-flex flex-row align-items-center flex-wrap lh-base">
    <ng-container *ngFor="let stepFrame of processStepStackFrames; first as isFirst">
        <span *ngIf="!isFirst"
              class="mx-2"> / </span>
        <a class="clickable-step"
           (click)="processStepClick.emit(stepFrame)">
            <i *ngIf="isFirst"
               class="fal fa-fw fa-home"></i>
            {{stepFrame.step.name}}</a>
    </ng-container>
    <span class="mx-2"> / </span>
    <span class="non-clickable-step">{{processStep!.name}}</span>
</div>
