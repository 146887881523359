<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.SystemiseYourBusiness"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<ng-container *ngIf="systemNotFound; else showSystemContent">
    <div class="alert alert-info">
        Unable to find that system. Has it been deleted?
    </div>
</ng-container>
<ng-template #showSystemContent>
    <adapt-system-content [system]="system$ | async"
                          [isEditSystemPage]="true"
                          [processMapStates]="processMapStates"
                          [addedComponentId]="addedComponentId"
                          (processMapStatesChange)="onProcessMapStatesChanged($event)"></adapt-system-content>
</ng-template>
