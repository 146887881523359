import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";

@Injectable({
    providedIn: "root",
})
export class CareerValuationAuthService {
    public static readonly ReadMyCareerValuation = "readMyCareerValuation";
    public static readonly ReadQuantitativeCareerValuation = "readQuantitativeCareerValuation";
    public static readonly ReadAnonymousCareerValuation = "readAnonymousCareerValuation";
    public static readonly OpenCareerValuationAnalysis = "openCareerValuationAnalysis";
    public static readonly ReadCareerValuationForPerson = "readCareerValuationForPerson";
    public static readonly EditCareerValuationForPerson = "editCareerValuationForPerson";

    public constructor(
        private authorisationService: AuthorisationService,
        private clfAuthService: CulturalLeadershipFrameworkAuthService,
    ) {
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.ReadMyCareerValuation,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCareerValuationPersonalUseAndRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.ReadQuantitativeCareerValuation,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCareerValuationRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.ReadAnonymousCareerValuation,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCareerValuationRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.OpenCareerValuationAnalysis,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.ReadCareerValuationForPerson,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(CareerValuationAuthService);
                    return authService.personCanReadForPerson;
                },
            },
        );
        authorisationService.registerAccessVerifier(
            CareerValuationAuthService.EditCareerValuationForPerson,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(CareerValuationAuthService);
                    return authService.personCanEditForPerson;
                },
            },
        );
    }

    @Autobind
    public currentPersonCanEditCareerValuation() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.authorisationService.personHasPermission(this.authorisationService.currentPerson, FeaturePermissionName.CulturalNetworkCareerValuationPersonalEdit);
    }

    @Autobind
    public currentPersonHasAtLeastQuantitativeAccess() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.authorisationService.personHasAtLeastOnePermission(this.authorisationService.currentPerson, [
            FeaturePermissionName.CulturalNetworkCareerValuationRead,
            FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead,
        ]);
    }

    @Autobind
    public personCanReadForPerson(personToCheckPermission: Person, cvtPerson: Person) {
        return this.clfAuthService.canReadCulturalEntity({
            culturalFeature: FeatureName.CulturalNetworkCareerValuation,
            authorisingPerson: personToCheckPermission,
            culturalEntityPerson: cvtPerson,
        });
    }

    @Autobind
    public personCanEditForPerson(personToCheckPermission: Person, cvtPerson: Person) {
        return this.clfAuthService.canEditCulturalEntity({
            culturalFeature: FeatureName.CulturalNetworkCareerValuation,
            authorisingPerson: personToCheckPermission,
            culturalEntityPerson: cvtPerson,
        });
    }
}
