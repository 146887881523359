import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ResilientBusinessGoalsAuthService } from "../rb-goals-auth.service";
import { ResilientBusinessGoalsPageComponent } from "./rb-goals-page.component";

export const rbGoalsRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-resilient-business-goals-page", ResilientBusinessGoalsPageComponent)
    .atOrganisationUrl("/resilient-business-goals")
    .verifyingFeatures(FeatureName.CompassResilientBusinessGoals)
    .verifyingAccess(ResilientBusinessGoalsAuthService.ReadResilientBusinessGoals)
    .withTitle("Resilient business goals")
    .withSearchKeyword("Economic Engine")
    .withSearchKeyword("Organisation design")
    .withSearchKeyword("Leadership & Culture")
    .withSearchKeyword("Financial Security")
    .build();
