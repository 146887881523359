<div *ngIf="(href$ | async) as href"
     class="d-flex align-items-center flex-wrap gap-2"
     [ngClass]="{'standard-font-weight': !showIcon}">
    <a [routerLink]="href"
       class="me-1">
        <span *ngIf="showIcon"
              class="fa-stack fa-1x system-icon">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fa-stack-1x fa-inverse"
               [ngClass]="icon"
               aria-hidden="true"></i>
        </span>
        <span adaptTooltip="This is a new / unsaved task map"
              [tooltipEnabled]="system!.systemEntityId < 1">{{system!.name}}</span>

        <i class="ms-2 fal fa-chevron-right"
           *ngIf="showNavIcon"></i>
    </a>
    <small *ngIf="accessoryText">{{accessoryText}}</small>
    <adapt-display-labels *ngIf="showLabels"
                          [labelLocations]="system!.labelLocations">
    </adapt-display-labels>
</div>
