import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepType } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { forkJoin } from "rxjs";
import { filter, map } from "rxjs/operators";
import { IntegratedArchitectureFrameworkAuthService } from "../../integrated-architecture/integrated-architecture-framework-auth.service";
import { IProcessStepStackFrame } from "../display-process-step-hierarchy/display-process-step-hierarchy.component";
import { ProcessMapService } from "../process-map.service";
import { ProcessMapUiService } from "../process-map-ui.service";
import { RenderProcessStepsComponent } from "../render-process-steps/render-process-steps.component";

export interface IMapStackFramesChangedEvent {
    rootProcessMapId: number; // initial root process map for the card
    frames: { processMapId?: number, processStepId?: number }[]; // each frame can be a step or map (linked)
}

@Component({
    selector: "adapt-process-steps-card",
    templateUrl: "./process-steps-card.component.html",
    styleUrls: ["./process-steps-card.component.scss"],
})
export class ProcessStepsCardComponent extends BaseComponent implements OnChanges, AfterViewChecked {
    @Input() public isEditing = false;
    @Input() public processMap?: ProcessMap;
    @Input() public parentStep?: ProcessStep;
    @Input() public systemComponent?: SystemComponent; // this is to show the purpose of process map in the header just like description for step

    @Input() public title = "Process steps";

    @Output() public processMapDeleted = new EventEmitter<ProcessMap>();
    @Output() public moveComponentClick = new EventEmitter<SystemComponent>();
    @Output() public copyComponentClick = new EventEmitter<SystemComponent>();

    @Input() public mapStackFrames?: IMapStackFramesChangedEvent;
    @Output() public mapStackFramesChange = new EventEmitter<IMapStackFramesChangedEvent>();

    @ViewChild("cardRoot") public cardRoot?: ElementRef;
    @ViewChild("processMapHeader") public processMapHeader?: ElementRef<HTMLDivElement>;
    @ViewChild(RenderProcessStepsComponent) private renderProcessStepsComponent!: RenderProcessStepsComponent;
    public minHeight = 0;

    public initialProcessMap?: ProcessMap;
    public renderIsInitialised = false;
    public processStepStackFrames: IProcessStepStackFrame[] = [];
    public hasEditPermission = true;

    private readonly editMenuItem: IAdaptMenuItem = {
        text: "Edit process step",
        icon: "fal fa-fw fa-edit",
        onClick: () => this.editProcessStep((this.parentStep || this.processMap)!),
    };
    private readonly copyMenuItem: IAdaptMenuItem = {
        text: "Copy task map...",
        icon: "fal fa-fw fa-copy",
        onClick: () => this.copyComponentClick.emit(this.systemComponent),
        separator: true,
    };
    private readonly moveMenuItem: IAdaptMenuItem = {
        text: "Move task map to another system",
        icon: "fal fa-fw fa-arrows-alt",
        onClick: () => this.moveComponentClick.emit(this.systemComponent),
    };
    private readonly linkMenuItem: IAdaptMenuItem = {
        text: "Add link to a task map",
        icon: "fal fa-fw fa-link",
        onClick: () => this.addLink(),
    };
    private readonly deleteMenuItem: IAdaptMenuItem = {
        text: "Delete task map",
        icon: "fal fa-fw fa-trash-alt",
        onClick: () => this.processMapDeleted.emit(this.initialProcessMap),
        separator: true,
    };

    public editMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [this.editMenuItem, this.linkMenuItem, this.copyMenuItem, this.moveMenuItem, this.deleteMenuItem],
    }];

    private currentMapStackFramesEvent?: IMapStackFramesChangedEvent;
    private minHeightUpdater = this.createThrottledUpdater((minHeight: number) => this.minHeight = minHeight);

    public constructor(
        private processMapService: ProcessMapService,
        private processMapUiService: ProcessMapUiService,
        private labellingService: LabellingService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
    ) {
        super();
    }

    public get hasChildSteps() {
        return (this.renderProcessStepsComponent?.childSteps?.length ?? 0) > 0;
    }

    public ngAfterViewChecked() {
        if (this.cardRoot?.nativeElement.scrollHeight > this.minHeight) {
            this.minHeightUpdater.next(this.cardRoot?.nativeElement.scrollHeight ?? 0);
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.parentStep || changes.processMap) {
            // changes through @Input -> clear stack frames
            this.processStepStackFrames = [];

            if (this.processMap) {
                this.initialProcessMap = this.processMap;
                this.currentMapStackFramesEvent = {
                    rootProcessMapId: this.processMap.processMapId,
                    frames: [],
                };
            }

            this.updateProcessStepMapMenuItem();
        }

        if (changes.mapStackFrames && this.mapStackFrames) {
            if (this.requireStackFramesUpdate) {
                forkJoin(this.mapStackFrames.frames.map((frame) => {
                    return frame.processMapId
                        ? this.processMapService.getProcessMap(frame.processMapId)
                        : this.processMapService.getProcessStep(frame.processStepId!);
                })).pipe(
                    // frame can corresponds to deleted step which get by Id will return falsy.
                    map((mapSteps) => mapSteps.filter((i) => !!i)),
                ).subscribe((mapSteps) => {
                    this.restoreFrames(mapSteps as (ProcessStep | ProcessMap)[]);
                    if (mapSteps.length !== this.mapStackFrames?.frames.length) {
                        // certain frame invalid -> raise this to update query param
                        this.raiseMapStackFramesChangedEvent();
                    }
                });
            }
        }

        if (changes.isEditing && !this.isEditing) {
            // editing status changed -> reset the high water mark if finished editing to address case where height is reduced
            this.minHeightUpdater.next(0);
        }
    }

    public editProcessStep(processStep: ProcessStep | ProcessMap) {
        if (processStep instanceof ProcessStep) {
            this.processMapUiService.editProcessStep(processStep).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        } else if (processStep instanceof ProcessMap) {
            this.processMapUiService.editProcessMap(processStep, this.systemComponent).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    public addLink() {
        if (!this.parentStep && !this.processMap) {
            return;
        }

        return this.processMapUiService.addProcessStep(this.parentStep ?? this.processMap!, ProcessStepType.ProcessMapLink)
            .subscribe(() => this.renderProcessStepsComponent.scrollViewToRight());
    }

    @Autobind
    public export() {
        return this.processMapUiService
            .showProcessMapExporter(this.processMap!);
    }

    public stackFrameChanged(stepFrame: IProcessStepStackFrame) {
        this.processStepStackFrames.splice(stepFrame.stackFrame, this.processStepStackFrames.length - stepFrame.stackFrame);
        this.setCurrentStep(stepFrame.step);
        this.raiseMapStackFramesChangedEvent();
    }

    public processStepClicked(processStep: ProcessStep) {
        // need to add hierarchy to join to current view
        this.pushStack((this.processMap || this.parentStep)!);
        const missingHops = this.findMissingHopsToCurrent(processStep);
        for (const step of missingHops) {
            this.pushStack(step);
        }

        if (processStep.extensions.isProcessMapLink && processStep.linkedProcessMap) {
            this.processMap = processStep.linkedProcessMap;
            this.parentStep = undefined;
        } else {
            this.processMap = undefined;
            this.parentStep = processStep;
            this.primeSupplementaryData(processStep);
        }

        this.updateProcessStepMapMenuItem();
        this.raiseMapStackFramesChangedEvent();
    }

    private primeSupplementaryData(processStep: ProcessStep) {
        if (!processStep.supplementaryData && !this.parentStep!.extensions.isRoleTask) {
            // prime supplementary data for the currently showing process step if not role task (role task content will do its own priming)
            this.processMapService.getProcessStepSupplementaryData(processStep).pipe(
                filter((suppData) => !suppData && this.isEditing),
                this.takeUntilDestroyed(),
            ).subscribe();
        }

        if (!processStep.labelLocations?.length) {
            this.labellingService.getLabelLocationsForProcessStep(processStep.processStepId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    private pushStack(step: ProcessMap | ProcessStep) {
        // only push to the stack if it is not on the stack before
        if (!this.processStepStackFrames.find((i) => i.step === step)) {
            this.processStepStackFrames.push({
                stackFrame: this.processStepStackFrames.length,
                step,
            });
        }
    }

    private findMissingHopsToCurrent(processStep: ProcessStep) {
        const missingHops: ProcessStep[] = [];
        if (this.processMap) {
            const hopsCandidate: ProcessStep[] = [];
            let i = processStep.parentStep;
            while (i) {
                hopsCandidate.splice(0, 0, i);
                i = i.parentStep;
            }

            if (hopsCandidate.length > 0 && hopsCandidate[0].processMap === this.processMap) {
                missingHops.push(...hopsCandidate);
            }
        } else if (this.parentStep && processStep !== this.parentStep) {
            const hopsCandidate: ProcessStep[] = [];
            let i = processStep.parentStep;
            while (i && i !== this.parentStep) {
                hopsCandidate.splice(0, 0, i);
                i = i.parentStep;
            }

            if (i === this.parentStep && hopsCandidate.length > 0) {
                missingHops.push(...hopsCandidate);
            }
        }

        return missingHops;
    }

    private raiseMapStackFramesChangedEvent() {
        if (this.currentMapStackFramesEvent) {
            setTimeout(() => {
                this.processMapHeader?.nativeElement.scrollIntoView({ behavior: "smooth" });
            });

            this.currentMapStackFramesEvent.frames = this.processStepStackFrames.map((frame) => frame.step instanceof ProcessMap
                ? { processMapId: frame.step.processMapId }
                : { processStepId: frame.step.processStepId });

            // add current display frame to the stack
            if (this.processMap) {
                this.currentMapStackFramesEvent.frames.push({ processMapId: this.processMap.processMapId });
            } else if (this.parentStep) {
                this.currentMapStackFramesEvent.frames.push({ processStepId: this.parentStep.processStepId });
            }

            this.mapStackFramesChange.emit(this.currentMapStackFramesEvent);
        }
    }

    private get requireStackFramesUpdate() {
        let requireUpdate = false;
        if (this.currentMapStackFramesEvent?.frames.length !== this.processStepStackFrames.length + 1) {
            requireUpdate = true;
        } else {
            // check current frame
            const currentFrame = this.currentMapStackFramesEvent.frames[this.currentMapStackFramesEvent.frames.length - 1];
            if (currentFrame.processMapId !== this.processMap?.processMapId || currentFrame.processStepId !== this.parentStep?.processStepId) {
                requireUpdate = true;
            } else {
                for (let i = 0; i < this.processStepStackFrames.length; i++) {
                    const checkFrame = this.processStepStackFrames[i];
                    const incomingFrame = this.currentMapStackFramesEvent.frames[i];
                    if (checkFrame.step instanceof ProcessStep) {
                        if (checkFrame.step.processStepId !== incomingFrame.processStepId) {
                            requireUpdate = true;
                            break;
                        }
                    } else {
                        if (checkFrame.step.processMapId !== incomingFrame.processMapId) {
                            requireUpdate = true;
                            break;
                        }
                    }
                }
            }
        }

        return requireUpdate;
    }

    private restoreFrames(steps: (ProcessMap | ProcessStep)[]) {
        const parentFrames = steps.slice(0, steps.length - 1);
        this.processStepStackFrames = parentFrames.map((value, index) => ({
            stackFrame: index,
            step: value,
        } as IProcessStepStackFrame));

        const currentStep = steps[steps.length - 1];
        this.setCurrentStep(currentStep);
    }

    private setCurrentStep(currentStep: ProcessStep | ProcessMap) {
        if (currentStep instanceof ProcessMap) {
            this.processMap = currentStep;
            this.parentStep = undefined;
        } else {
            this.parentStep = currentStep;
            this.processMap = undefined;
            this.primeSupplementaryData(currentStep);
        }

        this.updateProcessStepMapMenuItem();
    }

    private updateProcessStepMapMenuItem() {
        if (this.processMap) {
            this.editMenuItem.text = "Edit task map";
            this.linkMenuItem.visible = true;
        } else if (this.parentStep?.type === ProcessStepType.RoleTask) {
            this.editMenuItem.text = "Edit role task";
            this.linkMenuItem.visible = false;
        } else {
            this.editMenuItem.text = "Edit process step";
            this.linkMenuItem.visible = true;
        }

        const showSystemComponentItems = (this.processMap === this.initialProcessMap);
        this.copyMenuItem.visible = showSystemComponentItems;
        this.moveMenuItem.visible = showSystemComponentItems;
        this.deleteMenuItem.visible = showSystemComponentItems;

        const processMap = this.processMap || this.parentStep?.processMap;
        if (processMap) {
            this.archAuthService.personCanEditProcessMap(processMap).then((hasPermission) => {
                this.hasEditPermission = hasPermission;
            });
        }
    }
}
