import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { Params } from "@angular/router";
import { ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermission } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { ManagePeopleGridComponent } from "@org-common/lib/user-management/manage-people-grid/manage-people-grid.component";
import { managePeoplePageRoute } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import dxCheckBox, { Properties as CheckBoxProperties } from "devextreme/ui/check_box";
import dxDataGrid, { InitializedEvent } from "devextreme/ui/data_grid";
import { merge } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { RoleUiService } from "../../role/role-ui.service";
import { IntegratedArchitectureFrameworkUiService } from "../integrated-architecture-framework-ui.service";

export interface IRolePeopleGridRow {
    selectRowOptions?: CheckBoxProperties;
    role: Role;
    people: Person[];
    managePeopleParams: Params;
    checkboxInstance?: dxCheckBox;
    disableSelectionText?: string;
}

interface IHeaderItem {
    text: string,
    value: (string | ((row: IRolePeopleGridRow) => string))[],
}

@Component({
    selector: "adapt-roles-page",
    templateUrl: "./roles-page.component.html",
})
export class RolesPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly RoleLabelFilterSearchParam = "role";

    public canMerge = false;
    public hasTier2Edit = false;
    public hasManageAccess = false;

    public dxGridWrapperHelper: DxGridWrapperHelper;

    public onRowSelectionChanged?: (rowSelectionCount: number) => void;

    public roleLabelFilter?: string;
    public showActions?: boolean;
    public allowMerging?: boolean;

    // use cell template for the row selection rather than default boolean cell editor as that's resulting in the
    // refresh of table after each selection change which is intolerable
    // - separate checkbox options for each role to maintain selection for the page when table refresh due to added role
    //   or actions performed on role (e.g. add connection, archive)
    public selectRowOptions: { [roleId: number]: CheckBoxProperties } = {};

    private gridInstance?: dxDataGrid;

    private roles: Role[] = [];
    private roleConnections: RoleConnection[] = [];

    public gridData: IRolePeopleGridRow[] = [];
    public roleHeaderItems: IHeaderItem[] = [];
    public peopleHeaderItems: IHeaderItem[] = [];
    public managePeopleUrl: string = "";

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        private directorySharedService: DirectorySharedService,
        private authorisationService: AuthorisationService,
        private roleUiService: RoleUiService,
        private integratedArchitectureFrameworkUiService: IntegratedArchitectureFrameworkUiService,
        private userManagementService: UserManagementService,
        private orgService: OrganisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        this.onRowSelectionChanged = (rowSelectionCount) => this.canMerge = rowSelectionCount > 1;

        merge([
            rxjsBreezeService.entityTypeChanged(Role),
            rxjsBreezeService.entityTypeChanged(RoleConnection),
            rxjsBreezeService.entityTypeChanged(RoleFeaturePermission),
        ]).pipe(
            debounceTime(1000),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.integratedArchitectureFrameworkUiService.emitRefetchRequired());

        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-roles-page", jQuery(elementRef.nativeElement));
    }

    public async ngOnInit() {
        this.roleLabelFilter = this.getSearchParameterValue(RolesPageComponent.RoleLabelFilterSearchParam);
        if (this.roleLabelFilter) {
            this.deleteSearchParameter(RolesPageComponent.RoleLabelFilterSearchParam);
        }

        if (!this.roleLabelFilter) {
            this.dxGridWrapperHelper.saveGridState(String(this.orgService.getOrganisationId()));
        }

        this.hasTier2Edit = await this.authorisationService.promiseToGetHasAccess(IntegratedArchitectureFrameworkAuthService.EditAnyTier2);
        this.hasManageAccess = await this.directorySharedService.promiseToVerifyAccessToManageAccess();

        this.managePeopleUrl = await this.routeService.getControllerRoute(managePeoplePageRoute.id);

        await this.authorisationService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
        await this.promiseToFetchData();

        this.integratedArchitectureFrameworkUiService.gridDimensionsUpdated$
            .pipe(this.takeUntilDestroyed())
            .subscribe(() => this.gridInstance?.updateDimensions());

        this.integratedArchitectureFrameworkUiService.refetchRequired$
            .subscribe(async () => {
                await this.promiseToFetchData();
                this.gridInstance?.updateDimensions();
            });

        this.setupColumns(this.hasManageAccess, this.hasTier2Edit);

        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());

        this.notifyActivated();
    }

    public showColumnChooser() {
        this.gridInstance?.showColumnChooser();
    }

    public exportAllData() {
        if (this.gridInstance) {
            DxUtilities.exportGridToExcel("Role & People", this.gridInstance);
        }
    }

    public resetColumns() {
        this.roleLabelFilter = undefined;
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    @Autobind
    public addRole() {
        return this.roleUiService.addRole().pipe(
            tap(() => this.integratedArchitectureFrameworkUiService.emitRefetchRequired()),
        );
    }

    public onInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
        this.gridInstance = e.component;
    }

    private setupColumns(hasManageAccess: boolean, hasTier2Edit: boolean) {
        this.showActions = hasTier2Edit || hasManageAccess;
        this.allowMerging = hasManageAccess && hasTier2Edit;
    }

    public calculateStatusCellValue(row: IRolePeopleGridRow) {
        return row.role && row.role.isActive();
    }

    public calculateConnectionTypeCellValue(row: IRolePeopleGridRow) {
        return row.role && `${ConnectionTypeLabel.singular(row.role.connectionType)} roles`;
    }

    private async promiseToFetchData() {
        await this.promiseToGetRoles();
        this.updateHeaderItems();
        await this.directorySharedService.promiseToGetAllPeople();
        const roleConnections = await this.userManagementService.promiseToGetActiveRoleConnections();
        this.updateGridData(roleConnections);
    }

    private async promiseToGetRoles() {
        const roles = await this.directorySharedService.promiseToGetAllRoles();

        this.roles = roles
            .filter(DirectorySharedService.isNotTeamBasedRole)
            .filter((role) => role.extensions.isCulturalLeaderRole() || DirectorySharedService.isNotAccessLevelRole(role));
    }

    private updateHeaderItems() {
        this.roleHeaderItems = this.roles.map((role) => ({
            text: role.label,
            value: [this.calculateRoleCellValue, "=", role.label],
        }));
    }

    private updateGridData(roleConnections: RoleConnection[]) {
        this.roleConnections = roleConnections;
        const allPeople: Person[] = [];

        this.gridData = [];
        for (const role of this.roles) {
            const gridRow: IRolePeopleGridRow = {
                selectRowOptions: this.getSelectRowOptions(role),
                role,
                people: this.getPeopleWithRole(role),
                managePeopleParams: {
                    [ManagePeopleGridComponent.RoleLabelFilterSearchParam]: role.label,
                },
            };
            for (const person of gridRow.people) {
                if (allPeople.indexOf(person) < 0) {
                    allPeople.push(person);
                }
            }

            if (gridRow.selectRowOptions) { // only defined if showMergeSelection option is set to true
                gridRow.selectRowOptions.onInitialized = (e) => gridRow.checkboxInstance = e.component;
                gridRow.selectRowOptions.disabled = !role.isActive();
                if (gridRow.selectRowOptions.disabled) {
                    gridRow.disableSelectionText = "This role is already archived and cannot be merged";
                }
            }

            this.gridData.push(gridRow);
        }

        this.peopleHeaderItems = allPeople.map((person) => ({
            text: person.fullName,
            value: [this.calculatePeopleCellValue, "contains", person.fullName],
        }));

        this.peopleHeaderItems.unshift({
            text: "(Blanks)",
            value: [this.calculatePeopleCellValue, "=", ""],
        });

        // check if there is any selection and update the selection status
        const mergingRoles: Role[] = [];
        this.gridData.forEach((row) => {
            if (row.selectRowOptions && row.selectRowOptions.value) {
                mergingRoles.push(row.role);
            }
        });
        const mergingSystemRole = mergingRoles.find((role) => !!role.roleType);
        if (mergingSystemRole) {
            // system role in merge -> disable all other system role
            this.setDisableOtherSystemRoles(mergingSystemRole, true);
        }
    }

    private getPeopleWithRole(role: Role): Person[] {
        const peopleWithRole = this.roleConnections.filter((roleConnection) => roleConnection.roleId === role.roleId)
            .map((roleConnection) => roleConnection.connection.person);

        // with distinct against resultant people, it will make sure the grid won't break
        // even if the same person holds either multiple connections and/or duplicate role connections
        return ArrayUtilities.distinct(peopleWithRole);
    }

    public calculatePeopleCellValue(row: IRolePeopleGridRow) {
        return row.people
            .map((person) => person.fullName)
            .join(",");
    }

    public calculateRoleCellValue(row: IRolePeopleGridRow) {
        return row.role && row.role.label;
    }

    public mergeSelectedRows() {
        const mergingRoles: Role[] = [];
        this.gridData.forEach((row) => {
            if (row.selectRowOptions && row.selectRowOptions.value) {
                mergingRoles.push(row.role);
            }
        });

        return this.integratedArchitectureFrameworkUiService.promiseToMergeRoles(mergingRoles)
            .then((mergedRole: Role) => {
                if (mergedRole) { // only reset all selections if merged
                    for (const role of mergingRoles) {
                        const selectionOptions = this.getSelectRowOptions(role);
                        if (selectionOptions) {
                            selectionOptions.value = false;
                            this.onMergeRowSelectionChanged(role, false);
                        }
                    }
                }
            });
    }

    private setDisableOtherSystemRoles(currentSystemRole: Role, disabled: boolean) {
        this.gridData.forEach((row) => {
            if (row.role.roleType && row.role !== currentSystemRole) {
                if (row.checkboxInstance) {
                    row.checkboxInstance.option("disabled", disabled);
                } else if (row.selectRowOptions) {
                    row.selectRowOptions.disabled = disabled;
                }
                row.disableSelectionText = disabled
                    ? "Cannot select multiple system roles for merging. There is already a system role selected: " + currentSystemRole.label
                    : undefined;
            }
        });
    }

    private onMergeRowSelectionChanged(role: Role, isSelected: boolean) {
        if (role.roleType) {
            // system role selection changed - need to disable/enable all other system role selection
            this.setDisableOtherSystemRoles(role, isSelected);
        }

        if (this.onRowSelectionChanged) {
            let rowSelectionCount = 0;
            this.gridData.forEach((row) => {
                if (row.selectRowOptions && row.selectRowOptions.value) {
                    rowSelectionCount++;
                }
            });

            this.onRowSelectionChanged(rowSelectionCount);
        }
    }

    private getSelectRowOptions(role: Role) {
        let checkboxOptions = this.selectRowOptions[role.roleId];
        if (!checkboxOptions) {
            checkboxOptions = {
                value: false,
                onValueChanged: (e) => {
                    checkboxOptions.value = e.value;
                    this.onMergeRowSelectionChanged(role, e.value);
                },
            };
            this.selectRowOptions[role.roleId] = checkboxOptions;
        } else if (checkboxOptions.value) {
            // grid refresh and previously selected row is reselected, need to update
            // but do it in the next digest cycle as the other rows may not yet initialised
            // - need to have the selectRowOptions for the other rows to disable.
            setTimeout(() => this.onMergeRowSelectionChanged(role, true));
        }

        return checkboxOptions;
    }
}
