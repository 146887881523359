import { Component, Input } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CareerValuationService } from "../career-valuation.service";
import { careerValuationDashboardPageRoute, myCareerValuationDashboardPageRoute } from "../cvt-dashboard-page/cvt-dashboard-page.route";

@Component({
    selector: "adapt-cvt-personal-dashboard-element",
    templateUrl: "./cvt-personal-dashboard-element.component.html",
    styleUrls: ["../career-valuation.scss"],
})
export class CareerValuationPersonalDashboardElementComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    private person$ = new ReplaySubject<Person | undefined>(1);

    public careerValuation$: Observable<CareerValuation | undefined>;
    public dashboardUrl$?: Observable<string>;
    public actualTotal?: number;

    public constructor(
        private careerValuationService: CareerValuationService,
        userService: UserService,
    ) {
        super();

        this.dashboardUrl$ = combineLatest([this.person$, userService.currentPerson$]).pipe(
            map(([person, currentPerson]) => {
                return person !== currentPerson && person !== undefined
                    ? person
                    : undefined;
            }),
            switchMap((person) => person
                ? careerValuationDashboardPageRoute.getRoute({ personId: person.personId })
                : myCareerValuationDashboardPageRoute.getRoute()),
        );

        this.careerValuation$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap(async (person) => {
                const careerValuation = await this.careerValuationService.promiseToGetLatestCareerValuationForPerson(person.personId);
                if (careerValuation) {
                    this.actualTotal = careerValuation.extensions.actualTotal;
                }

                return careerValuation;
            }),
        );
    }
}
