<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ResilientBusinessGoals"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent
     adaptBlockingButtonGroup>
    <button *adaptIfAuthorised="editRbGoalsAccessVerifier"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editResilientBusinessGoals"
            data-test="edit-rb-goals-button">Edit resilient business goals</button>
</div>

<adapt-resilient-business-goals-map [noGoalsTemplate]="rbGoalsCta"></adapt-resilient-business-goals-map>

<ng-template #rbGoalsCta>
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Resilient business goals</adapt-call-to-action-title>

        <p>
            Identifying and agreeing upon your organisation's resilient business goals is a
            key element to the overall Resilient Business Strategy.
        </p>
        <p>
            The number one reason for failure to implement is a failure to communicate
            effectively. Communicating your strategy to the organisation is critical.
            The resilient business goals tool allows you to store your organisations goals
            within each of the four zones:
        </p>
        <ul class="list-content">
            <li>Organisation design</li>
            <li>Economic engine</li>
            <li>Leadership &amp; culture</li>
            <li>Financial security</li>
        </ul>

        <button adaptCallToActionButton
                *adaptIfAuthorised="editRbGoalsAccessVerifier"
                adaptButtonIcon="edit"
                [adaptBlockingClick]="editResilientBusinessGoals">
            Edit resilient business goals
        </button>
    </adapt-call-to-action>
</ng-template>
