import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptArchitectureModule } from "@org-common/lib/architecture/architecture.module";
import { LinkRoleComponent } from "@org-common/lib/architecture/link-role/link-role.component";
import { DxSelectBoxModule } from "devextreme-angular";
import { SelectRoleComponent } from "./select-role/select-role.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        DxSelectBoxModule,
        AdaptArchitectureModule,
    ],

    declarations: [
        SelectRoleComponent,
    ],
    exports: [
        LinkRoleComponent,
        SelectRoleComponent,
    ],
})
export class AdaptRoleSharedModule { }
