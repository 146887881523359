import { AfterViewInit, Directive, EmbeddedViewRef, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: "[adaptHideIfEmpty]",
})
export class HideIfEmptyDirective implements AfterViewInit {
    private embeddedViewRef?: EmbeddedViewRef<unknown>;

    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private renderer: Renderer2,
    ) {
    }


    public ngAfterViewInit() {
        // creating embeded view here will cause ExpressionChangedAfterItHasBeenCheckedError
        // - still the same issue even if it is created in ngAfterContentInit()
        // Move this to the next digest cycle to have the content created before checking
        // from the component life cycle.
        setTimeout(() => {
            this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
            this.setIsDisplayed(false);
        });
    }

    public setHasContent() {
        this.setIsDisplayed(true);
    }

    public setIsDisplayedFromArray(data: unknown[]) {
        this.setIsDisplayed(data.length > 0);
    }

    public setIsDisplayed(isDisplayed: boolean) {
        if (!this.embeddedViewRef) {
            return;
        }

        // A possible enhancement is to take in a dom element as an input to show a
        // loading spinner on and show that instead of hiding the whole element
        if (isDisplayed) {
            for (const node of this.embeddedViewRef.rootNodes) {
                this.renderer.removeStyle(node, "display");
            }
        } else {
            for (const node of this.embeddedViewRef.rootNodes) {
                this.renderer.setStyle(node, "display", "none");
            }
        }

    }
}
