import { Component, Inject, ViewChild } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemDeletedEvent } from "@common/ux/dx.types";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { lastValueFrom } from "rxjs";
import { CulturalCohortTooltip, CulturalLeadershipFrameworkService, SupportingMemberTooltip } from "../cultural-leadership-framework.service";

export interface IEditCulturalCohortDialogDialogData {
    culturalLeader?: Person;
    configuration: CulturalLeadership;
}

@Component({
    selector: "adapt-edit-cultural-cohort-dialog",
    templateUrl: "./edit-cultural-cohort-dialog.component.html",
    styleUrls: ["./edit-cultural-cohort-dialog.component.scss"],
})
export class EditCulturalCohortDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditCulturalCohortDialogDialogData> {
    public readonly dialogName = "EditCulturalCohortDialog";
    public readonly SupportingMemberTooltip = SupportingMemberTooltip;
    public readonly CulturalCohortTooltip = CulturalCohortTooltip;
    public readonly allowedUserTypes = [UserType.Leader, UserType.Collaborator];
    public readonly isNewCohort: boolean;
    public readonly configuration: CulturalLeadership;

    public title: string;

    public culturalLeader?: Person;
    public culturalLeaderConnection?: Connection;
    public culturalLeaderRoleConnection?: RoleConnection;

    public culturalRelationships: CulturalRelationship[] = [];
    public supportingCulturalRelationships: CulturalRelationship[] = [];
    private removedCulturalRelationships: CulturalRelationship[] = [];

    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);

    @ViewChild("selectCohortPersonComponent") public selectCohortPersonComponent?: SelectPersonComponent;
    @ViewChild("selectSupportingPersonComponent") public selectSupportingPersonComponent?: SelectPersonComponent;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) data: IEditCulturalCohortDialogDialogData,
        protected commonDataService: CommonDataService,
        private clfService: CulturalLeadershipFrameworkService,
    ) {
        super();

        this.culturalLeader = data.culturalLeader;
        this.configuration = data.configuration;

        this.culturalRelationships = this.culturalLeader?.activeCulturalCohort.filter((cr) => !cr.isSupporting) ?? [];
        this.supportingCulturalRelationships = this.culturalLeader?.activeCulturalCohort.filter((cr) => cr.isSupporting) ?? [];

        this.isNewCohort = !this.culturalLeader;
        this.title = `${this.isNewCohort ? "Add" : "Edit"} ${this.configuration.secondaryCollectionName}`;
    }

    public get entitiesToConfirm() {

        return [
            ...this.culturalRelationships,
            ...this.supportingCulturalRelationships,
            ...this.removedCulturalRelationships,
            ...(this.culturalLeaderRoleConnection ? [this.culturalLeaderRoleConnection] : []),
        ];
    }

    public async onPersonSelected(person?: Person, isSupporting = false) {
        if (!person) {
            return;
        }

        // this relationship was already deleted, just revert those changes
        let culturalRelationship = this.removedCulturalRelationships.find((cr) => cr.personId === person.personId);
        if (culturalRelationship) {
            culturalRelationship.entityAspect.rejectChanges();
            ArrayUtilities.removeElementFromArray(culturalRelationship, this.removedCulturalRelationships);
        } else {
            culturalRelationship = await this.clfService.promiseToAddCulturalRelationship(this.culturalLeader!);
        }

        culturalRelationship.person = person;
        culturalRelationship.isSupporting = isSupporting;
        culturalRelationship.entityAspect.validateEntity();

        if (!this.personExistsInPrimary(culturalRelationship.personId) && !this.personExistsInSecondary(culturalRelationship.personId)) {
            ArrayUtilities.addElementIfNotAlreadyExists(isSupporting ? this.supportingCulturalRelationships : this.culturalRelationships, culturalRelationship);
        }

        this.reloadLists();
        this.resetPersonSelector(isSupporting);
    }

    @Autobind
    public isPossibleCulturalLeader(person: Person) {
        // don't allow existing cultural leaders
        return !person.isCulturalLeader;
    }

    public async culturalLeaderSelected(person?: Person) {
        const connection = person?.getLatestConnection();

        if (connection) {
            this.culturalLeaderConnection = connection;
            this.culturalLeader = connection.person;
        }
    }

    @Autobind
    public filterPossiblePrimaryCohortMember(person: Person) {
        // don't allow people already in a cohort to be a member of the cohort
        return this.filterCohortMember(person) && !person.isInCulturalCohort;
    }

    @Autobind
    public filterPossibleCohortMember(person: Person) {
        return this.filterCohortMember(person);
    }

    private filterCohortMember(person: Person) {
        // don't allow the cultural leader to be a member of their own cohort
        if (person.personId === this.culturalLeader!.personId) {
            return false;
        }

        // don't allow people that are already in this cohort to be a member of the cohort
        if (this.personExistsInPrimary(person.personId) || this.personExistsInSecondary(person.personId)) {
            return false;
        }


        // don't allow inactive people to be a member of the cohort
        if (!person.getLatestEmployeeConnection()?.isActive()) {
            return false;
        }

        return true;
    }

    public async removeCulturalRelationship(e: IDxListItemDeletedEvent<CulturalRelationship>, isSupporting = false) {
        const isAdded = e.itemData?.entityAspect.entityState.isAdded();

        await this.clfService.promiseToRemoveCulturalRelationship(e.itemData!);

        if (!isAdded && !this.personExistsInPrimary(e.itemData!.personId) && !this.personExistsInSecondary(e.itemData!.personId)) {
            ArrayUtilities.addElementIfNotAlreadyExists(this.removedCulturalRelationships, e.itemData!);
        }

        ArrayUtilities.removeElementFromArray(e.itemData!, isSupporting ? this.supportingCulturalRelationships : this.culturalRelationships);

        this.reloadLists();
        this.resetPersonSelector(isSupporting);
    }

    public async saveAndClose() {
        this.setErrorMessage(undefined);

        const shouldContinue = await this.updateCulturalLeaderRoleConnection();
        if (shouldContinue) {
            try {
                await lastValueFrom(this.commonDataService.saveEntities(this.entitiesToConfirm));
            } catch (e) {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
            }

            this.resolve({ culturalLeader: this.culturalLeader, configuration: this.configuration });
        }
    }

    private async updateCulturalLeaderRoleConnection() {
        const cohort = this.culturalLeader!.activeCulturalCohort;

        // add the connection if not already there
        if (this.isNewCohort && this.culturalLeaderConnection) {
            const existingActiveLeaderRoleConnection = this.culturalLeaderConnection.roleConnections
                .find((rc) => rc.isActive() && rc.roleId === this.configuration.primaryRoleId);
            if (!existingActiveLeaderRoleConnection) {
                // create CL role connection
                this.culturalLeaderRoleConnection = await lastValueFrom(this.archData.createRoleConnection({
                    connection: this.culturalLeaderConnection,
                    roleId: this.configuration.primaryRoleId,
                }));
                return true;
            }
        } else if (!cohort.length) {
            const shouldEndCohort = await this.promptToEndCohort();
            if (shouldEndCohort) {
                this.culturalLeaderRoleConnection = await this.endCulturalLeaderRoleConnection();
                return true;
            } else {
                // restore the removed relationships
                this.removedCulturalRelationships.forEach((relationship) => relationship.entityAspect.rejectChanges());
                this.culturalRelationships = this.culturalRelationships.concat(this.removedCulturalRelationships.filter((r) => !r.isSupporting));
                this.supportingCulturalRelationships = this.supportingCulturalRelationships.concat(this.removedCulturalRelationships.filter((r) => r.isSupporting));
                this.removedCulturalRelationships = [];
                return false;
            }
        }

        return true;
    }

    private async promptToEndCohort() {
        const title = `End this ${this.configuration.secondaryCollectionName}`;
        const message = `<p>
                    Removing all ${this.configuration.secondaryName}s from this ${this.configuration.secondaryCollectionName}
                    removes ${this.culturalLeader?.fullName} from being a ${this.configuration.primaryName}.
                </p>
                <p>Do you wish to proceed?</p>`;

        return lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean({ title, message }));
    }

    private async endCulturalLeaderRoleConnection() {
        const leaderRoleConnection = await lastValueFrom(this.archData.getActiveRoleConnectionForRoleIdAndPersonId(
            this.configuration.primaryRoleId,
            this.culturalLeader?.personId ?? 0,
        ));

        // promiseToGetActiveRoleConnectionForRoleIdAndPersonId has activeOnly true
        // - so if CL connection is ended, roleConnection will be null
        if (leaderRoleConnection) {
            leaderRoleConnection.endDate = new Date();
        }

        return leaderRoleConnection;
    }

    private resetPersonSelector(isSupporting: boolean) {
        isSupporting
            ? this.selectSupportingPersonComponent?.reset()
            : this.selectCohortPersonComponent?.reset();
    }

    private personExistsInPrimary(personId: number) {
        return this.culturalRelationships
            .map((c) => c.personId)
            .includes(personId);
    }

    private personExistsInSecondary(personId: number) {
        return this.supportingCulturalRelationships
            .map((c) => c.personId)
            .includes(personId);
    }

    private reloadLists() {
        this.selectSupportingPersonComponent?.reload();
        this.selectCohortPersonComponent?.reload();
    }
}
