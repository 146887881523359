import { Component, Injector, OnInit } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AddPeopleDialogComponent } from "app/organisation/user-management/add-people-dialog/add-people-dialog.component";

@Component({
    selector: "adapt-stakeholder-directory-page",
    templateUrl: "./stakeholder-directory-page.component.html",
})
export class StakeholderDirectoryPageComponent extends BaseRoutedComponent implements OnInit {
    public filterByConnectionTypes = [
        ConnectionType.Stakeholder,
        ConnectionType.Coach,
    ];

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public addStakeholder = () => this.dialogService.open(AddPeopleDialogComponent, { connectionType: ConnectionType.Stakeholder });

    public ngOnInit() {
        this.notifyActivated();
    }
}
