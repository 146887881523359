import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import dxChart, { InitializedEvent, PointClickEvent } from "devextreme/viz/chart";
import { switchMap } from "rxjs/operators";
import { CulturalIndexHistoryDialogComponent } from "../ci-history-dialog/ci-history-dialog.component";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexFilterService } from "../cultural-index-filter/cultural-index-filter.service";
import { CulturalIndexUiService } from "../cultural-index-ui.service";

interface IChartData {
    culturalIndex: CulturalIndex;
    argument: Date | number;
    personId?: number;
    value: number;
}

@Component({
    selector: "adapt-ci-latest-chart",
    templateUrl: "./ci-latest-chart.component.html",
})
export class CulturalIndexLatestChartComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public date?: Date;

    public data: IChartData[] = [];
    public showDates = false;

    public chart?: dxChart;

    public isLoading = false;

    public constructor(
        public responsiveService: ResponsiveService,
        public culturalIndexUiService: CulturalIndexUiService,
        private culturalIndexService: CulturalIndexService,
        private culturalIndexFilterService: CulturalIndexFilterService,
        private commonDialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxjsBreezeService.entityTypeChangedInSave(CulturalIndex)
            .pipe(
                switchMap(() => this.getDataAndRefreshChart()),
                this.takeUntilDestroyed(),
            )
            .subscribe();

        this.culturalIndexFilterService.filterListener.pipe(
            switchMap(() => this.getDataAndRefreshChart()),
            this.takeUntilDestroyed(),
        )
            .subscribe();
    }

    public async ngOnInit() {
        await this.getDataAndRefreshChart();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.date) {
            this.getDataAndRefreshChart();
        }
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        return {
            html: this.culturalIndexUiService.getChartDataTooltip(info.point.tag, true),
        };
    }

    @Autobind
    public onInitialized(e: InitializedEvent) {
        this.chart = e.component!;
        // set the strips in the post since the culturalIndexService has to have finished initialising prior to settings the strip labels/colours
        this.culturalIndexUiService.promiseToSetChartStrips(this.chart);
    }

    @Autobind
    public onPointClick(info: PointClickEvent) {
        info.component.hideTooltip();
        const culturalIndex: CulturalIndex = info.target!.tag;

        if (!culturalIndex.person) {
            return;
        }

        return this.commonDialogService.open(CulturalIndexHistoryDialogComponent, culturalIndex.person).subscribe();
    }

    @Autobind
    private async getDataAndRefreshChart() {
        if (!ChartUtils.isChartDisposed(this.chart)) {
            this.chart?.showLoadingIndicator();
        }

        const filterParameters = { ...this.culturalIndexFilterService.filter, date: this.date };
        const data: ICulturalLeadershipPersonalAnalysisData[] = await this.culturalIndexService.promiseToGetCulturalIndexAnalysisData(filterParameters);
        this.showDates = !data.some((i) => i.culturalIndex && DateUtilities.isOutOfRange(i.culturalIndex.creationDate));
        this.data = data
            .filter((x) => !!x.culturalIndex)
            .map((x) => ({
                culturalIndex: x.culturalIndex,
                value: x.culturalIndex.value,
                argument: this.showDates
                    ? x.culturalIndex.creationDate
                    : NumberUtilities.generatePRNG(x.culturalIndex.culturalIndexId),
                personId: x.culturalIndex.personId,
            }));

        this.isLoading = false;
    }
}
