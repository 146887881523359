<div class="value-stream-dashboard-element-title">
    <span
          adaptTooltip="Define things such as your market type (business to business or business to consumer), industry, size or geography.">
        <i class="fal fa-fw fa-tasks"></i>
        Market description
    </span>
    <button *ngIf="canEditTier1Architecture$ | async"
            class="btn btn-link"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editMarketDescription"></button>
</div>

<div *adaptLoading="!businessModel">
    <div *ngIf="businessModel!.marketDescription else noMarketDescriptionTemplate"
         [froalaView]="businessModel!.marketDescription"></div>
    <ng-template #noMarketDescriptionTemplate>
        <adapt-call-to-action-inline [featureUrl]="featureUrl$ | async">
            {{CallToActionText.valueStream.marketDescription}}
            <button *ngIf="canEditTier1Architecture$ | async"
                    adaptCallToActionButton
                    adaptButtonIcon="edit"
                    [adaptBlockingClick]="editMarketDescription">
                Define the market description
            </button>
        </adapt-call-to-action-inline>
    </ng-template>
</div>
