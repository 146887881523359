import { Injectable } from "@angular/core";
import { SupportUrlHome } from "@common/implementation-kit/implementation-kit.service";
import { IShellPopoverLink, IShellPopoverLinkItem } from "@common/shell/shell-popover-link-item/shell-popover-link-item";
import { SupportApplicationBarItemComponent } from "@common/shell/support-application-bar-item/support-application-bar-item.component";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { forkJoin, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

@Injectable()
export class SupportApplicationBarItem extends BaseComponent implements IShellPopoverLinkItem {
    public id = "support-application-bar-item";

    private readonly canLoginToSupport$: Observable<boolean>;
    public readonly link: IShellPopoverLink;
    public readonly linkContent: IComponentRender<SupportApplicationBarItemComponent>;
    public readonly tooltip = "Support for the ADAPT Way Foundations & Platform";
    public readonly ordinal = 1;
    public readonly isShown$: Observable<boolean>;

    public constructor(
        private userService: UserService,
        private authorisationService: AuthorisationService,
        authorisationNotificationService: AuthorisationNotificationService,
        responsiveService: ResponsiveService,
    ) {
        super();

        // make sure we check isStakeholderManager again if authorisation has changed
        this.canLoginToSupport$ = authorisationNotificationService.authorisationChanged$.pipe(
            startWith(undefined),
            switchMap(() => forkJoin([
                this.userService.getCurrentPerson(),
                this.authorisationService.promiseToCheckIsStakeholderManager(),
            ])),
            this.takeUntilDestroyed(),
        ).pipe(
            map(([currentPerson, isStakeholderManager]) => !!currentPerson?.isCoach() || isStakeholderManager),
        );

        this.link = {
            href$: this.canLoginToSupport$.pipe(
                // have default link if not coach/stakeholderManager
                map((canLogin) => canLogin ? "" : SupportUrlHome),
            ),
            openInNewTab: true,
        };

        this.linkContent = {
            component: SupportApplicationBarItemComponent,
            params: {
                canLoginToSupport$: this.canLoginToSupport$,
                supportUrl: SupportUrlHome,
            },
        };

        this.isShown$ = responsiveService.isMobileSize$.pipe(
            map((isMobile) => !isMobile),
        );
    }
}
