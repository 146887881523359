<div class="process-step-link-outer-container"
     [ngClass]="processStepLinkClass$ | async">
    <div class="process-step-link-container">
        <adapt-link-process-step [processStep]="processStep$ | async"
                                 [alignTextToRightOfIcon]="true"
                                 (stepClick)="stepClick.emit($event)"></adapt-link-process-step>
        <div class="link-container-padding"></div>
        <ng-container *ngIf="isEditing">
            <adapt-menu *ngIf="(isLink$ | async) === false; else linkProcessStep"
                        [items]="editMenu"></adapt-menu>
            <ng-template #linkProcessStep>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete process step"
                        (click)="delete()"></button>
            </ng-template>
        </ng-container>
    </div>

    <ng-container *ngIf="(processStep$ | async) as processStep">
        <adapt-link-role *ngIf="processStep!.extensions.isRoleTask && (isEditing || processStep!.role)"
                         [role]="processStep!.role"
                         [showIcon]="true"></adapt-link-role>
    </ng-container>
</div>
