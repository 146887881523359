import { Component, Inject } from "@angular/core";
import { INavigationHierarchy, NAVIGATION_HIERARCHIES } from "@common/route/navigation-hierarchy";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { TeamHierarchyNavigationService } from "app/platform/navigation/team-hierarchy-navigation.service";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-team-tab-content",
    templateUrl: "./team-tab-content.component.html",
})
export class TeamTabContentComponent extends BaseComponent {
    public teamsHierarchy$: Observable<INavigationNode | undefined>;

    public constructor(
        navHierarchyService: NavigationHierarchyService,
        @Inject(NAVIGATION_HIERARCHIES) navHierarchies: INavigationHierarchy[],
        public responsiveService: ResponsiveService,
    ) {
        super();

        this.teamsHierarchy$ = navHierarchyService.hierarchyChanged(TeamHierarchyNavigationService.HierarchyId);
        const teamHierarchyNavService = navHierarchies.find((i) => i instanceof TeamHierarchyNavigationService) as TeamHierarchyNavigationService;
        if (teamHierarchyNavService) {
            // this is to add new tree hierarchy
            navHierarchyService.newDynamicNode$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((newDynamicNode) => teamHierarchyNavService.onDynamicNodeCreated(newDynamicNode));

            // this is to remove team tree hierarchy if move away from current active team - does nothing if there is no current active team
            navHierarchyService.activeNode$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((activeNode) => teamHierarchyNavService.onNodeActivated(activeNode));

            // teamHierarchyNavService knows about teams stuff but cannot access navHierarchyService
            // - need to register/deregister newly created/deleted team hierarchy from navHierarchyService
            //   and the follow 2 subscriptions are handling them.
            teamHierarchyNavService.dynamicTeamAdded$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((teamNode) => {
                navHierarchyService.registerNodeAndChildren(teamNode);
                navHierarchyService.updateActiveNodeFromUrl();
            });

            teamHierarchyNavService.dynamicTeamRemoved$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((teamNode) => navHierarchyService.deregisterNodeAndChildren(teamNode));
        }
    }
}
