import { Component, OnInit } from "@angular/core";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { IProductServiceSearchResult } from "@org-common/lib/search/search-results.interface";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { productCataloguePageRoute } from "app/features/product/product-catalogue/product-catalogue-page/product-catalogue-page.route";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-product",
    templateUrl: "./search-row-product.component.html",
})
export class SearchRowProductComponent extends SearchRowBaseComponent<IProductServiceSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = productCataloguePageRoute.getRouteObject();
        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }
}
