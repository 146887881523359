import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { InitializedEvent } from "devextreme/ui/list";
import { lastValueFrom, Observable, Subject } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { SelectSystemComponent } from "../select-system/select-system.component";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-edit-system-locations",
    templateUrl: "./edit-system-locations.component.html",
})
export class EditSystemLocationsComponent extends BaseComponent implements OnChanges {
    @Input() public team?: Team;
    @Input() public keyFunction?: KeyFunction;
    @Input() public role?: Role;
    @Output() public entitiesChange = new EventEmitter<IBreezeEntity[]>();
    // TODO: temporary here so that old angularJS dialog (configure-role) can be notified changes
    // - Remove this and use @ViewChild after merge in the porting ticket for configure-role
    @Output() public creatingAndAddingChange = new EventEmitter<boolean>();

    public existingSystemLocations: SystemLocation[] = [];
    public selectedSystem?: SystemEntity;

    @ViewChild(SelectSystemComponent) private selectSystemComponent?: SelectSystemComponent;
    private getExistingSystemLocations?: () => Observable<SystemLocation[]>;
    private triggerUpdate$ = new Subject<void>();
    private changedEntities: IBreezeEntity[] = [];

    public constructor(
        private commonDataService: CommonDataService,
        private systemisationService: SystemisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.triggerUpdate$.pipe(
            filter(() => !!this.getExistingSystemLocations),
            switchMap(() => this.getExistingSystemLocations!()),
            this.takeUntilDestroyed(),
        ).subscribe((existingSystemLocations) => {
            this.existingSystemLocations = existingSystemLocations;
            this.selectSystemComponent?.refresh();
        });


        // this component is working with SystemLocation - detecting location detach is good enough
        // for the case where system is deleted from another session (don't have to monitor system entity).
        // System entity detach will result in SystemLocation entity being discarded which will cause the emit here.
        rxjsBreezeService.entityTypeDetached(SystemLocation).pipe(
            filter((systemLocation) => this.existingSystemLocations.includes(systemLocation)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public get isCreatingAndAdding() {
        return this.selectSystemComponent?.isCreateAndSelecting;
    }

    @Autobind
    public excludeSelectedSystemsFilter(s: SystemEntity) {
        return !this.existingSystemLocations.find((l) => l.system?.systemEntityId === s.systemEntityId);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.team && this.team) {
            this.getExistingSystemLocations = () => this.systemisationService.getSystemLocationsForTeam(this.team!);
        } else if (changes.keyFunction && this.keyFunction) {
            this.getExistingSystemLocations = () => this.systemisationService.getSystemLocationsForKeyFunction(this.keyFunction!);
        } else if (changes.role && this.role) {
            this.getExistingSystemLocations = () => this.systemisationService.getSystemLocationsForRole(this.role!);
        }

        if (this.getExistingSystemLocations) {
            this.triggerUpdate$.next();
        }
    }

    public addSystemLocation(system: SystemEntity) {
        const existingOrdinals = this.existingSystemLocations.length > 0
            ? this.existingSystemLocations.map((i) => i.ordinal)
            : [0];
        const nextOrdinal = Math.max(...existingOrdinals) + 1;
        const createEntity$ = this.systemisationService.createSystemLocation(system, nextOrdinal).pipe(
            tap((systemLocation) => {
                if (this.team) {
                    systemLocation.team = this.team;
                } else if (this.keyFunction) {
                    systemLocation.keyFunction = this.keyFunction;
                } else if (this.role) {
                    systemLocation.role = this.role;
                }
            }),
        );

        return createEntity$.pipe(
            tap((entity) => {
                this.changedEntities.push(entity);
                this.entitiesChange.emit(this.changedEntities);
                this.selectedSystem = undefined;
                this.triggerUpdate$.next();
            }),
        );
    }

    public focus(e: InitializedEvent) {
        setTimeout(() => e.component?.focus());
    }

    public updateOrdinals(e: IDxListItemReorderedEvent<SystemLocation>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.existingSystemLocations, "ordinal", e.fromIndex, e.toIndex);
        // iterate through all existing system locations and add changed entities to emit
        for (const systemLocation of this.existingSystemLocations) {
            if (!systemLocation.entityAspect.entityState.isUnchanged()) {
                ArrayUtilities.addElementIfNotAlreadyExists(this.changedEntities, systemLocation);
            }
        }

        this.entitiesChange.emit(this.changedEntities);
    }

    public async removeSystemLocation(e: IDxListItemDeletedEvent<SystemLocation>) {
        const systemLocation = e.itemData!;
        if (systemLocation.system.entityAspect.entityState.isAdded()) {
            ArrayUtilities.removeElementFromArray<IBreezeEntity>(systemLocation.system, this.changedEntities);
            await lastValueFrom(this.commonDataService.remove(systemLocation.system));
        }

        ArrayUtilities.removeElementFromArray(systemLocation, this.existingSystemLocations);
        if (systemLocation.entityAspect.entityState.isAdded()) {
            ArrayUtilities.removeElementFromArray<IBreezeEntity>(systemLocation, this.changedEntities);
        } else {
            ArrayUtilities.addElementIfNotAlreadyExists(this.changedEntities, systemLocation);
        }
        await lastValueFrom(this.commonDataService.remove(systemLocation));

        SortUtilities.updateIntegerSortedArrayAfterItemRemoval(this.existingSystemLocations, "ordinal", systemLocation.ordinal);
        for (const location of this.existingSystemLocations) {
            if (!location.entityAspect.entityState.isUnchanged()) {
                ArrayUtilities.addElementIfNotAlreadyExists(this.changedEntities, location);
            }
        }

        this.entitiesChange.emit(this.changedEntities);
        this.triggerUpdate$.next();
    }

    public onNewSystemChange(system?: SystemEntity) {
        if (system) {
            this.changedEntities.push(system);
        }

        this.entitiesChange.emit(this.changedEntities);
    }
}
