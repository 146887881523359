import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CulturalIndex, CulturalIndexBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseService } from "@common/service/base.service";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { CulturalLeadershipAccessLevels } from "@org-common/lib/cultural-leadership/cultural-leadership-access-levels.enum";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
@Autobind
export class CulturalIndexDataService extends BaseService {
    private clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private clfAuthService: CulturalLeadershipFrameworkAuthService,
    ) {
        super(injector);
    }

    public promiseToCheckCulturalIndexAccessLevel(personId?: number) {
        return this.clfAuthService.checkCulturalAccessLevel(FeatureName.CulturalNetworkCulturalIndex, personId);
    }

    /**
     * Promise to get the latest cultural indexes for everyone in the organisation
     * @returns {promise} A promise that resolves with an array of cultural indexes
     */
    public async promiseToGetLatestCulturalIndexes(date?: Date) {
        // TODO: enable inclusion of inactive people as an option
        const key = "latestCulturalIndexes";
        const options = {
            namedParams: {
                latestOnly: true,
                activeOnly: true,
            } as any,
        };

        if (date) {
            options.namedParams.date = date;
        }

        const accessLevel = await this.promiseToCheckCulturalIndexAccessLevel();

        try {
            this.clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            await new PeopleQueryUtilities(this.commonDataService).promiseToGetActiveConnections(); // need this to prime positions to pass the active check
            const results = await lastValueFrom(this.commonDataService.getWithOptions(CulturalIndexBreezeModel, key, options));
            const culturalIndexes = results.filter((ci) => !ci.person || !!ci.person.getLatestEmployeeConnection());

            if (accessLevel !== CulturalLeadershipAccessLevels.AnonymousAccess) {
                await this.clQueryUtilities.promiseToGetActiveCulturalLeadershipRelationships();
            }

            return culturalIndexes;
        } catch (e) {
            await this.logError(e);
        }
    }

    /**
     * Promise to get the latest cultural index for a specified person
     * @param {int} personId : The Id of the specified person
     * @returns {promise} A promise that resolves with a single cultural index
     */
    public async promiseToGetLatestCulturalIndexForPerson(personId: number) {
        const key = "latestCulturalIndexForPerson" + personId;
        const options = {
            namedParams: {
                latestOnly: true,
            },
            predicate: new MethodologyPredicate<CulturalIndex>("personId", "==", personId),
        };

        try {
            const accessLevel = await this.promiseToCheckCulturalIndexAccessLevel(personId);
            this.clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            const culturalIndexes = await lastValueFrom(this.commonDataService.getWithOptions(CulturalIndexBreezeModel, key, options));
            const latestCulturalIndex = ArrayUtilities.getSingleFromArray(culturalIndexes);
            return latestCulturalIndex;
        } catch (e) {
            await this.logError(e);
        }
    }

    /**
     * Promise to get all cultural indexes for a specified person
     * @param {int} personId : The Id of the specified person
     * @returns {promise} A promise that resolves with an array of cultural indexes
     */
    public async promiseToGetAllCulturalIndexesForPerson(personId: number) {
        const key = "allCulturalIndexesForPerson" + personId;
        const options = {
            predicate: new MethodologyPredicate<CulturalIndex>("personId", "==", personId),
        };

        try {
            const accessLevel = await this.promiseToCheckCulturalIndexAccessLevel(personId);
            this.clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            const culturalIndexes = await lastValueFrom(this.commonDataService.getWithOptions(CulturalIndexBreezeModel, key, options));
            return culturalIndexes;
        } catch (e) {
            await this.logError(e);
        }
    }

    @Autobind
    private logError(error: any) {
        this.log.warn(error);

        return Promise.reject(error);
    }
}
