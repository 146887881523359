<adapt-search-row-page *ngIf="type === SearchType.Page && isPage(searchResults)"
                       [showDetails]="false"
                       [keyword]="keyword"
                       [result]="searchResults"></adapt-search-row-page>

<ng-container *ngIf="type === SearchType.PersonTeamRole && !isPage(searchResults)">
    <adapt-search-row-person *ngIf="SearchService.isPerson(searchResults) as person"
                             [keyword]="keyword"
                             [result]="person"></adapt-search-row-person>
    <adapt-search-row-role *ngIf="SearchService.isRole(searchResults) as role"
                           [showLabels]="false"
                           [showMatches]="false"
                           [keyword]="keyword"
                           [result]="role"></adapt-search-row-role>
    <adapt-search-row-team *ngIf="SearchService.isTeam(searchResults) as team"
                           [showLabels]="false"
                           [keyword]="keyword"
                           [result]="team"></adapt-search-row-team>
</ng-container>
