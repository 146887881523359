import { Component, Input } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-no-cultural-measurement",
    templateUrl: "./no-cultural-measurement.component.html",
})
export class NoCulturalMeasurementComponent extends BaseComponent {
    @Input() public label!: string;

    public constructor() {
        super();
    }
}
