import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptSystemisationSharedModule } from "app/features/systemisation/systemisation-shared.module";
import { DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { SortablejsModule } from "nxt-sortablejs";
import { AdaptIntegratedArchitectureSharedModule } from "../integrated-architecture/integrated-architecture-shared.module";
import { AdaptRoleSharedModule } from "../role/role-shared.module";
import { AddChildStepComponent } from "./add-child-step/add-child-step.component";
import { DisplayProcessStepHierarchyComponent } from "./display-process-step-hierarchy/display-process-step-hierarchy.component";
import { LinkProcessMapComponent } from "./link-process-map/link-process-map.component";
import { LinkProcessStepComponent } from "./link-process-step/link-process-step.component";
import { ProcessMapCardComponent } from "./process-map-card/process-map-card.component";
import { ProcessStepCardComponent } from "./process-step-card/process-step-card.component";
import { ProcessStepsCardComponent } from "./process-steps-card/process-steps-card.component";
import { RenderProcessStepsComponent } from "./render-process-steps/render-process-steps.component";
import { RoleTaskContentComponent } from "./role-task-content/role-task-content.component";
import { SelectProcessMapComponent } from "./select-process-map/select-process-map.component";
import { SelectProcessStepGuidanceMaterialComponent } from "./select-process-step-guidance-material/select-process-step-guidance-material.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxSelectBoxModule,
        DxListModule,
        DxTextBoxModule,
        SortablejsModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptCallToActionModule,
        AdaptRoleSharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptKeyFunctionsCommonModule,
        AdaptAuthorisationModule,
        AdaptEntityValidatorModule,
        AdaptTwoColumnModule,
        AdaptHtmlEditorModule,
        AdaptIntegratedArchitectureSharedModule,
        AdaptMenuModule,
        AdaptSystemisationSharedModule,
        AdaptLabellingModule,
    ],
    exports: [
        LinkProcessMapComponent,
        LinkProcessStepComponent,
        SelectProcessMapComponent,
        ProcessStepCardComponent,
        ProcessStepsCardComponent,
        RenderProcessStepsComponent,
        ProcessMapCardComponent,
        DisplayProcessStepHierarchyComponent,
        RoleTaskContentComponent,
        SelectProcessStepGuidanceMaterialComponent,
    ],
    declarations: [
        LinkProcessMapComponent,
        LinkProcessStepComponent,
        SelectProcessMapComponent,
        ProcessStepCardComponent,
        ProcessStepsCardComponent,
        RenderProcessStepsComponent,
        AddChildStepComponent,
        ProcessMapCardComponent,
        DisplayProcessStepHierarchyComponent,
        RoleTaskContentComponent,
        SelectProcessStepGuidanceMaterialComponent,
    ],
})
export class AdaptProcessMapSharedModule { }
