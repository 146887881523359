<adapt-button-toggle-group adaptToolbarContent
                           alignment="center"
                           [value]="page"
                           (valueChange)="updateUrl($event)">
    <button *ngFor="let tab of tabs"
            [hidden]="!(tab.isDisplayed$ | async)"
            [adaptToggleGroupButton]="tab.toggleValue"
            [adaptButtonIcon]="tab.icon">
        {{tab.text}}
    </button>
</adapt-button-toggle-group>

<div adaptToolbarContent>
    <button adaptButton="tour"
            data-test="tour-run"
            (click)="runTour()"></button>

    <a *adaptIfAuthorised="canEditPersonalStewardship"
       [routerLink]="personalConfigLink$ | async"
       adaptButton="configureLink"
       adaptTooltip="Go to personal configuration"></a>
</div>

<adapt-two-column-page *ngIf="page === dashboardPageEnum.Stewardship">
    <div left-column
         class="stewardship-left-column">
        <adapt-display-ongoing-survey-personal-dashboard-element [person]="person$ | async">
        </adapt-display-ongoing-survey-personal-dashboard-element>

        <adapt-team-meetings-dashboard-element *ngIf="canReadMeetings$ | async"
                                               [person]="person$ | async">
        </adapt-team-meetings-dashboard-element>

        <adapt-personal-objectives-dashboard-element *adaptIfAuthorised="readObjectives"
                                                     [person]="person$ | async"></adapt-personal-objectives-dashboard-element>
    </div>
    <ng-container right-column>
        <adapt-personal-work-dashboard-element *adaptIfAuthorised="canReadStewardship"
                                               [person]="person$ | async">
        </adapt-personal-work-dashboard-element>
    </ng-container>
</adapt-two-column-page>

<adapt-cultural-cohort-personal-dashboard-element *ngIf="page === dashboardPageEnum.CulturalLeadership">
</adapt-cultural-cohort-personal-dashboard-element>

<adapt-two-column-page *ngIf="page === dashboardPageEnum.CultureAndActivity">
    <ng-container left-column>
        <adapt-cvt-personal-dashboard-element *adaptIfAuthorised="readMyCareerValuation"
                                              [person]="person$ | async"></adapt-cvt-personal-dashboard-element>
        <adapt-ci-personal-dashboard-element *adaptIfAuthorised="readMyCulturalIndex"
                                             [person]="person$ | async"></adapt-ci-personal-dashboard-element>
    </ng-container>
    <ng-container right-column>
        <adapt-dashboard-element name="Activity relevant to me">
            <adapt-personal-activity-feed [person]="person$ | async"></adapt-personal-activity-feed>
        </adapt-dashboard-element>
    </ng-container>
</adapt-two-column-page>

<adapt-two-column-page *ngIf="page === dashboardPageEnum.Activity">
    <ng-container left-column>
        <adapt-dashboard-element name="Activity relevant to me">
            <adapt-personal-activity-feed [person]="person$ | async"></adapt-personal-activity-feed>
        </adapt-dashboard-element>
    </ng-container>

    <ng-container right-column></ng-container>
</adapt-two-column-page>
