<ng-container *adaptLoading="!goals">
    <ng-container *ngIf="hasGoals; then zoneMapTemplate; else noGoalsTemplate"></ng-container>
</ng-container>

<ng-template #zoneMapTemplate>
    <adapt-render-zone-map [verticalLayout]="verticalLayout"
                           [zoneContentTemplate]="zoneContentTemplate"></adapt-render-zone-map>
</ng-template>
<ng-template #zoneContentTemplate
             let-zone>
    <div [froalaView]="contentByZone(zone)"></div>
</ng-template>
