<div class="configure-role-access">
    <dx-accordion [dataSource]="accordionData"
                  [selectedIndex]="selectedIndex"
                  [collapsible]="true"
                  itemTitleTemplate="itemTitleTemplate"
                  itemTemplate="itemTemplate">
        <div *dxTemplate="let item of 'itemTitleTemplate'">
            <div class="title-content">
                <span>{{translatorService.translateFeatureModule(item.module)}}</span>
                <div title="Inherited permissions from employee role are not counted"
                     class="count-summary">{{item.grantedPermissionCount + '/' + item.totalPermissionCount}} permissions</div>
            </div>
        </div>

        <div *dxTemplate="let item of 'itemTemplate'"
             class="module-body">
            <div *ngFor="let group of item.featureGroups"
                 class="feature-group">
                <div class="feature-group-header">
                    {{group.name}}
                </div>
                <div *ngFor="let featurePermission of group.featurePermissions"
                     title="{{getFeaturePermissionTooltip(featurePermission)}}">
                    <dx-check-box [value]="hasPermission(featurePermission) "
                                  [disabled]="isCheckBoxDisabled(featurePermission)"
                                  [text]="translatorService.translateFeaturePermission(featurePermission)"
                                  (onValueChanged)="onValueChanged($event, featurePermission)">
                    </dx-check-box>
                </div>
            </div>
        </div>
    </dx-accordion>
</div>
