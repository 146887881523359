import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamProduct, ValueStreamProductBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemDeletedEvent, IDxListItemReorderedEvent, IDxSelectBoxSelectionChangedEvent } from "@common/ux/dx.types";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { ProductCatalogueUiService } from "app/features/product/product-catalogue/product-catalogue-ui.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxSelectBoxComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../tier1-architecture.service";

@Component({
    selector: "adapt-edit-value-stream-products-dialog",
    templateUrl: "./edit-value-stream-products-dialog.component.html",
})
export class EditValueStreamProductsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ValueStream, ValueStreamProduct[]> implements OnInit {
    @ViewChild("selectBoxAddition") public selectBox?: DxSelectBoxComponent;

    public readonly dialogName = "EditValueStreamProductsDialog";

    public products?: Product[];

    public availableProductsDataSource?: DataSource;
    public valueStreamProducts?: DataSource;

    public temporaryProduct?: Product;

    public cancelled = false;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public valueStream: ValueStream,
        private tier1ArchitectureService: Tier1ArchitectureService,
        private productCatalogueUiService: ProductCatalogueUiService,
        private productCatalogueService: ProductCatalogueService,
    ) {
        super();

        this.valueStream.products = this.valueStream.products.sort(SortUtilities.getSortByFieldFunction<ValueStreamProduct>("ordinal"));
    }

    public get entitiesToConfirm() {
        return [this.valueStream];
    }

    public async ngOnInit() {
        this.products = await lastValueFrom(this.productCatalogueService.getProducts());

        this.availableProductsDataSource = new DataSource({
            store: new ArrayStore({
                data: this.products,
                key: "productId",
            }),
            filter: this.filterOutExistingValueStreamProducts,
        });
    }

    /* Dialog Methods */
    public async ok() {
        const promises = [];
        for (const product of this.valueStream.products) {
            promises.push(this.removeProductIfEmpty(product));
        }
        await Promise.all(promises);

        this.commonDataService.optimiseAssignmentChanges(ValueStreamProductBreezeModel);
        await lastValueFrom(this.commonDataService.save());

        super.resolve(this.valueStream.products);
    }

    public async cancel() {
        this.cancelled = true;
        await lastValueFrom(this.commonDataService.cancel());

        super.resolve(this.valueStream.products);
    }

    /* Button Methods */
    @Autobind
    public newProduct() {
        return this.productCatalogueUiService.createProduct().pipe(
            switchMap((product) =>
                this.tier1ArchitectureService.createValueStreamProductForValueStream(this.valueStream).pipe(
                    map((vsp) => [product, vsp]),
                ),
            ),
            tap(([product, valueStreamProduct]: [Product, ValueStreamProduct]) => {
                valueStreamProduct.valueStream = this.valueStream;
                valueStreamProduct.product = product;
                this.products?.push(product);
                this.valueStream.products.push(valueStreamProduct);
                this.availableProductsDataSource?.reload();
            }),
        );

    }

    /* Dx List Events */
    public onItemReordered(e: IDxListItemReorderedEvent<ValueStreamProduct>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.valueStream.products, "ordinal", e.fromIndex, e.toIndex);
    }

    public async onItemDeleted(e: IDxListItemDeletedEvent<ValueStreamProduct>) {
        const product = e.itemData!;
        product.valueStream = this.valueStream;
        await lastValueFrom(this.tier1ArchitectureService.removeValueStreamProduct(product));
        this.availableProductsDataSource?.reload();
    }

    /* Dx Select Box Events */
    public onSelectionChanged(e: IDxSelectBoxSelectionChangedEvent<Product>, previousValue: Product) {
        if (this.cancelled) {
            return;
        }

        const index = this.valueStream.products.findIndex((i) => i.product === previousValue);
        this.valueStream.products[index].product = e.selectedItem;
        this.availableProductsDataSource?.reload();
    }

    public async onItemAdded(e: IDxSelectBoxSelectionChangedEvent<Product>) {
        if (!e.selectedItem) {
            return;
        }

        const valueStreamProduct = await lastValueFrom(this.tier1ArchitectureService.createValueStreamProductForValueStream(this.valueStream));
        valueStreamProduct.product = e.selectedItem;
        this.selectBox?.instance.reset();

        this.availableProductsDataSource?.reload();
    }

    /* Private Methods */
    @Autobind
    private filterOutExistingValueStreamProducts(product: Product) {
        return this.valueStream.products?.every((i) => i.productId !== product.productId);
    }

    private removeProductIfEmpty(valueStreamProduct: ValueStreamProduct) {
        if (!valueStreamProduct.product) {
            return lastValueFrom(this.commonDataService.remove(valueStreamProduct));
        }
    }
}
