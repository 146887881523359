<dx-list [dataSource]="systemsDataSource"
         [searchEnabled]="true"
         [searchExpr]="$any(['system.name', 'key.label'])"
         [grouped]="true"
         [collapsibleGroups]="true"
         displayExpr="system.name"
         selectionMode="single"
         height="100%"
         [disabled]="disabled"
         [selectedItems]="selectedSystems"
         (onSelectionChanged)="onSelectionChanged($event)">
    <div *dxTemplate="let group of 'group'">
        <div *ngIf="(group.key.entity || group.key.zone); else unallocatedGroup">
            <adapt-link-system-group [groupKey]="group.key.entity || group.key.zone"></adapt-link-system-group>
        </div>
        <ng-template #unallocatedGroup>
            <span>{{group.key.label}}</span>
        </ng-template>
    </div>
</dx-list>
