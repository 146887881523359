<div class="speed-catchup-traffic-light-rating"
     [ngClass]="trafficLightClass">
    <div class="traffic-light">
        <div *ngFor="let ratingOption of ratingOptions"
             class="traffic-light-container">
            <input class="traffic-light-input"
                   type="radio"
                   id="{{getTrafficLightIdName(ratingOption)}}"
                   name="{{trafficLightGroupName}}"
                   (ngModelChange)="setRatingValue(ratingOption)"
                   (keyup.Space)="setRatingValue(ratingOption)" />
            <label class="fas fa-circle traffic-light-label"
                   for="{{getTrafficLightIdName(ratingOption)}}"
                   [ngClass]="ratingOption.class"
                   [adaptTooltip]="ratingOption.guidance"
                   (click)="setRatingValue(ratingOption)">
            </label>
        </div>
    </div>
</div>
