import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";

@Component({
    selector: "adapt-analyse-career-valuation-timeline-page",
    templateUrl: "./analyse-cvt-timeline-page.component.html",
})
export class AnalyseCareerValuationTimelinePageComponent extends BaseRoutedComponent implements OnInit {
    public readonly CareerValuationFilterParamKeys: string[] = Object.values(CulturalDataFilterParamKeys);

    public constructor(
        public filterService: CareerValuationFilterService,
        injector: Injector,
    ) {
        super(injector);

        this.filterService.reset();
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}
