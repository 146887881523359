import { Component, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { teamDashboardPageRoute } from "app/platform/team-dashboard/team-dashboard-page/team-dashboard-page.route";
import { ContentReadyEvent } from "devextreme/ui/list";
import { Observable } from "rxjs";

interface IItem {
    name: string;
    team?: Team;
}

interface IGroup {
    key: string;
    items: IItem[];
}

@Component({
    selector: "adapt-organisation-activity-feed-dashboard",
    templateUrl: "./organisation-activity-feed-dashboard.component.html",
})
export class OrganisationActivityFeedDashboardComponent extends BaseComponent implements OnInit {
    public items: IGroup[] = [];
    public team?: Team;
    public teamUrl$?: Observable<string>;

    public constructor(
        private teamsService: CommonTeamsService,
    ) {
        super();
    }

    public async ngOnInit() {

        this.items.push({
            key: "Organisation",
            items: [
                {
                    name: "Organisation",
                },
            ],
        });

        const teamItem: IGroup = {
            key: "Teams",
            items: [],
        };
        const teams: Team[] = await this.teamsService.promiseToGetAllActiveTeams();
        teams.forEach((team) => teamItem.items.push({
            name: team.name,
            team,
        }));
        this.items.push(teamItem);
    }

    public async onSelectionChanged(e: IDxListSelectionChangedEvent<IItem>) {
        this.team = e.addedItems.length
            ? e.addedItems[0].team
            : undefined;

        this.teamUrl$ = this.team
            ? teamDashboardPageRoute.getRoute({ teamId: this.team.teamId })
            : undefined;
    }

    public onContentReady(e: ContentReadyEvent) {
        if (this.items.length) {
            e.component!.selectItem(this.items[0].items[0]);
        }
    }
}
