<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <adapt-edit-system-locations [team]="data.team"
                                     [keyFunction]="data.keyFunction"
                                     [role]="data.role"
                                     (entitiesChange)="setEntitiesToConfirm($event)"></adapt-edit-system-locations>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveIsDisabled"
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
