import { CommonRouteConstants } from "@common/route/common-route.constants";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { OrganisationDashboardPageComponent } from "./organisation-dashboard-page.component";

export const organisationDashboardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-organisation-dashboard-page", OrganisationDashboardPageComponent)
    .atOrganisationUrl(CommonRouteConstants.OrganisationDashboard)
    .withTitle("Organisation Dashboard")
    .reloadOnSearch(false)
    .build();
