<div class="card card-activity-feed">
    <div class="card-header"
         *ngIf="feedTitle">
        <h2 class="card-title">{{feedTitle}}</h2>
    </div>
    <div class="card-body">
        <div *adaptLoading="!initialDataLoaded"
             class="display-activity-feed-component">
            <div *ngIf="noActivity; else activityTemplate"
                 [ngClass]="errorMessage ? 'alert alert-danger' : ''">
                {{errorMessage || emptyFeedText}}
            </div>
            <ng-template #activityTemplate>
                <ol>
                    <li *ngFor="let activityGroup of activityByDate">
                        <adapt-activity-feed-date-group [dateGroup]="activityGroup"></adapt-activity-feed-date-group>
                    </li>
                </ol>
            </ng-template>
        </div>
        <ng-container *adaptLoading="loadingMoreActivity">
            <div *ngIf="!noActivity"
                 class="loading-more-activity-date">
                Showing activity from {{oldestActivityDate | adaptMoment:'DD MMM'}}
            </div>
        </ng-container>
    </div>
    <div class="card-footer"
         *ngIf="!noActivity">
        <button *ngIf="noMoreActivityToLoad; else loadMoreActivityTemplate"
                disabled>No more activity to load</button>
        <ng-template #loadMoreActivityTemplate>
            <button [disabled]="!initialDataLoaded"
                    (click)="promiseToLoadMoreActivity()">Load more</button>
        </ng-template>
    </div>
</div>