<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.AnalyseCvt"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title>
    <h2>
        Career Valuation
        <i class="fal fa-fw fa-info-circle"
           adaptTooltip="This page shows various representations of career valuation data for all employees up to a specific date.">
        </i>
    </h2>
</adapt-toolbar-title>

<div adaptToolbarContent>
    <button *ngIf="hasAtLeastQuantitativePermissions"
            [(adaptButtonToggle)]="showComparison"
            title="Toggle Comparison"
            class="btn btn-blank btn-force-text"
            adaptButtonIcon="fal fa-fw fa-balance-scale"
            data-test="compare-button">
        Compare
    </button>
</div>

<adapt-filter-content (reset)="filterService.reset()"
                      [isDefault]="filterService.isDefault"
                      [urlParamNames]="CareerValuationFilterParamKeys">
    <adapt-career-valuation-filter>
    </adapt-career-valuation-filter>
</adapt-filter-content>

<div class="row">
    <adapt-analyse-cvt-organisation [ngClass]="{ 'col-md-6': showComparison, 'col-12': !showComparison }">
    </adapt-analyse-cvt-organisation>

    <adapt-analyse-cvt-organisation *ngIf="showComparison"
                                    [ngClass]="{ 'col-md-6': showComparison }">
    </adapt-analyse-cvt-organisation>
</div>
