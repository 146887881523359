import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptCulturalLeadershipModule } from "app/features/culture/cultural-leadership/cultural-leadership.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxAccordionModule, DxChartModule, DxCheckBoxModule, DxCircularGaugeModule, DxDataGridModule, DxDateBoxModule, DxListModule, DxPieChartModule, DxPolarChartModule, DxSelectBoxModule } from "devextreme-angular";
import { AdaptDirectoryModule } from "../directory/directory.module";
import { AdaptTeamsModule } from "../teams/teams.module";
import { CulturalIndexDashboardPageComponent } from "./ci-dashboard-page/ci-dashboard-page.component";
import { culturalIndexDashboardRoute, myCulturalIndexDashboardRoute } from "./ci-dashboard-page/ci-dashboard-page.route";
import { CulturalIndexDialogComponent } from "./ci-dialog/ci-dialog.component";
import { CulturalIndexHistoryComponent } from "./ci-history/ci-history.component";
import { CulturalIndexHistoryDialogComponent } from "./ci-history-dialog/ci-history-dialog.component";
import { CulturalIndexLatestChartComponent } from "./ci-latest-chart/ci-latest-chart.component";
import { CulturalIndexOrbitComponent } from "./ci-orbit/ci-orbit.component";
import { CulturalIndexOrbitChartComponent } from "./ci-orbit-chart/ci-orbit-chart.component";
import { CulturalIndexOrbitGridComponent } from "./ci-orbit-grid/ci-orbit-grid.component";
import { CulturalIndexOrbitPageComponent } from "./ci-orbit-page/ci-orbit-page.component";
import { culturalIndexOrbitPageRoute } from "./ci-orbit-page/ci-orbit-page.route";
import { CulturalIndexOrbitStatisticsComponent } from "./ci-orbit-statistics/ci-orbit-statistics.component";
import { CulturalIndexOrganisationDashboardElementComponent } from "./ci-organisation-dashboard-element/ci-organisation-dashboard-element.component";
import { CulturalIndexPersonalDashboardElementComponent } from "./ci-personal-dashboard-element/ci-personal-dashboard-element.component";
import { CulturalIndexRangeComponent } from "./ci-range/ci-range.component";
import { CulturalIndexTimelineComponent } from "./ci-timeline/ci-timeline.component";
import { CulturalIndexTimelinePageComponent } from "./ci-timeline-page/ci-timeline-page.component";
import { culturalIndexTimelinePageRoute } from "./ci-timeline-page/ci-timeline-page.route";
import { CulturalIndexAuthService } from "./cultural-index-auth.service";
import { CulturalIndexFilterComponent } from "./cultural-index-filter/cultural-index-filter.component";
import { CulturalIndexFilterService } from "./cultural-index-filter/cultural-index-filter.service";
import { EditCulturalIndexDialogComponent } from "./edit-ci-dialog/edit-ci-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptEmbedShellModule,
        AdaptLoadingSpinnerModule,
        AdaptDateModule,
        AdaptShellModule,
        DxChartModule,
        AdaptCulturalLeadershipModule,
        AdaptTeamsModule,
        AdaptDirectoryModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        AdaptButtonModule,
        DxDateBoxModule,
        DxPolarChartModule,
        DxDataGridModule,
        DxChartModule,
        DxCircularGaugeModule,
        DxAccordionModule,
        DxPieChartModule,
        DxListModule,
        AdaptOrganisationSharedModule,
        AdaptTwoColumnModule,
        AdaptAuthorisationModule,
        AdaptCommonDialogModule,
        AdaptHtmlEditorModule,
        DxSelectBoxModule,
        AdaptTooltipModule,
        AdaptErrorPageModule,
        AdaptDashboardElementModule,
        AdaptTeamDashboardSharedModule,
    ],
    declarations: [
        CulturalIndexOrganisationDashboardElementComponent,
        CulturalIndexPersonalDashboardElementComponent,
        CulturalIndexRangeComponent,
        CulturalIndexTimelinePageComponent,
        CulturalIndexFilterComponent,
        CulturalIndexTimelineComponent,
        CulturalIndexOrbitPageComponent,
        CulturalIndexOrbitComponent,
        CulturalIndexOrbitChartComponent,
        CulturalIndexOrbitGridComponent,
        CulturalIndexLatestChartComponent,
        CulturalIndexOrbitStatisticsComponent,
        CulturalIndexDashboardPageComponent,
        CulturalIndexHistoryComponent,
        CulturalIndexDialogComponent,
        EditCulturalIndexDialogComponent,
        CulturalIndexHistoryDialogComponent,
    ],
    exports: [
        CulturalIndexOrganisationDashboardElementComponent,
        CulturalIndexPersonalDashboardElementComponent,
    ],
    providers: [
        CulturalIndexFilterService,
    ],
})
export class AdaptCulturalIndexModule {
    public static readonly Routes = [
        ...culturalIndexDashboardRoute.routes,
        ...myCulturalIndexDashboardRoute.routes,
        ...culturalIndexOrbitPageRoute.routes,
        ...culturalIndexTimelinePageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        CulturalIndexAuthService.registerAccess(authorisationService);
    }
}
