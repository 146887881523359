import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { KeyFunctionsService } from "@org-common/lib/architecture/key-functions/key-functions.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { IntegratedArchitectureFrameworkService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { IMapStackFramesChangedEvent } from "app/features/architecture/process-map/process-steps-card/process-steps-card.component";
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { ISystemGroupItem, ListSystemsComponent } from "../list-systems/list-systems.component";
import { ProcessMapStatesKey, SystemPageComponent } from "../system-page/system-page.component";
import { SystemisationService } from "../systemisation.service";
import { SystemisationUiService } from "../systemisation-ui.service";

export const SystemsPageSelector = "adapt-systems-page";

@Component({
    selector: SystemsPageSelector,
    templateUrl: "./systems-page.component.html",
    styleUrls: ["./systems-page.component.scss"],
})
export class SystemsPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditTier2 = IntegratedArchitectureFrameworkAuthService.ConfigureAnyTier2;
    @ViewChild(ListSystemsComponent) private systemListInstance?: ListSystemsComponent;

    public selectedSystem?: ISystemGroupItem;
    public selectedKeyEntity?: KeyFunction | Team | Role;
    public isEditing = false;
    public processMapStates: IMapStackFramesChangedEvent[] = [];
    public hasSystem = false;

    public readonly supportUrl = ImplementationKitService.GetArticleLink(this.ImplementationKitArticle.SystemiseYourBusiness);

    private readonly SelectedSystemKey = "selectedSystemId";
    private readonly KeyFunctionGroupKey = "keyFunctionId";
    private readonly TeamGroupKey = "teamId";
    private readonly RoleGroupKey = "roleId";
    private readonly ZoneGroupKey = "zone";

    public constructor(
        injector: Injector,
        private systemisationService: SystemisationService,
        private systemisationUiService: SystemisationUiService,
        private keyFunctionsService: KeyFunctionsService,
        private teamsService: CommonTeamsService,
        private archService: IntegratedArchitectureFrameworkService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        const triggerSystemCountUpdate$ = new BehaviorSubject<void>(undefined);
        triggerSystemCountUpdate$.pipe(
            switchMap(() => this.systemisationService.getAllSystems()),
            this.takeUntilDestroyed(),
        ).subscribe((systems) => {
            this.isInitialised = true;
            this.hasSystem = systems.length > 0;
            if (!this.hasSystem) {
                this.notifyActivated(); // show the CTA as list-systems component won't be shown at all to trigger this
            }
        });

        rxjsBreezeService.entityTypeChanged(SystemEntity).pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => triggerSystemCountUpdate$.next());
    }

    public ngOnInit() {
        this.selectedSystem = undefined;
        const selectedSystemString = this.getSearchParameterValue(this.SelectedSystemKey);
        if (selectedSystemString) {
            this.systemisationService.getSystemByIdWithLayout(Number(selectedSystemString)).pipe(
                switchMap((system) => {
                    const keyFunctionGroupString = this.getSearchParameterValue(this.KeyFunctionGroupKey);
                    const getKeyFunction = keyFunctionGroupString
                        ? this.keyFunctionsService.getKeyFunctionById(Number(keyFunctionGroupString))
                        : of(undefined);

                    const teamGroupString = this.getSearchParameterValue(this.TeamGroupKey);
                    const getTeam = teamGroupString
                        ? this.teamsService.getTeamById(Number(teamGroupString))
                        : of(undefined);

                    const roleGroupString = this.getSearchParameterValue(this.RoleGroupKey);
                    const getRole = roleGroupString
                        ? this.archService.getRoleById(Number(roleGroupString))
                        : of(undefined);

                    const zoneString = this.getSearchParameterValue(this.ZoneGroupKey);
                    const zone = zoneString ? zoneString as Zone : undefined;

                    return forkJoin([getKeyFunction, getTeam, getRole, of(zone), of(system)]);
                }),
                this.takeUntilDestroyed(),
            ).subscribe(([keyFunctionGroup, teamGroup, roleGroup, zone, system]) => {
                this.selectedSystem = {
                    system: system!,
                    key: {
                        entity: keyFunctionGroup || teamGroup || roleGroup,
                        zone,
                        label: "", // not used for matching selection
                    },
                };
            });
        }

        const savedStates = this.getSearchParameterValue(ProcessMapStatesKey);
        if (savedStates) {
            this.processMapStates = JSON.parse(decodeURI(savedStates));
        }

        this.removeDefaultShellPadding();
    }

    public onProcessMapStatesChanged(processMapStates: IMapStackFramesChangedEvent[]) {
        this.processMapStates = processMapStates;
        this.deleteSearchParameter(ProcessMapStatesKey);
        if (this.processMapStates.length > 0) {
            this.setSearchParameterValue(ProcessMapStatesKey, SystemPageComponent.encodeProcessMapStates(this.processMapStates));
        }
    }

    @Autobind
    public addSystem() {
        return this.systemisationUiService.openCreateSystemDialog().pipe(
            tap((newSystem) => {
                this.systemListInstance?.refresh();
                // if selectedSystem not yet in the data source, it will ended up selecting the first item
                // - so wait for a digest cycle
                setTimeout(() => {
                    this.selectedSystem = {
                        system: newSystem,
                        key: { label: "" },
                    };
                });
            }),
        );
    }

    public onSystemDeleted() {
        this.systemListInstance?.refresh();
    }

    public async onSelectedSystemChanged(selectedGroupItem?: ISystemGroupItem) {
        if (!selectedGroupItem) {
            return;
        }

        if (!this.selectedSystem ||
            this.selectedSystem.system !== selectedGroupItem?.system ||
            this.selectedSystem.key.entity !== selectedGroupItem?.key.entity ||
            this.selectedSystem.key.zone !== selectedGroupItem?.key.zone ||
            this.selectedSystem.key.label !== selectedGroupItem?.key.label) {
            if (this.selectedSystem && this.selectedSystem.system !== selectedGroupItem?.system) {
                // system selection changed - previous states invalid -> clear
                this.onProcessMapStatesChanged([]);
            }

            this.selectedSystem = selectedGroupItem;
            await this.deleteSearchParameter(this.SelectedSystemKey);
            await this.deleteSearchParameter(this.KeyFunctionGroupKey);
            await this.deleteSearchParameter(this.TeamGroupKey);
            await this.deleteSearchParameter(this.RoleGroupKey);
            await this.deleteSearchParameter(this.ZoneGroupKey);
            if (selectedGroupItem) {
                await this.setSearchParameterValue(this.SelectedSystemKey, selectedGroupItem.system.systemEntityId);
                if (selectedGroupItem.key.entity instanceof KeyFunction) {
                    this.setSearchParameterValue(this.KeyFunctionGroupKey, selectedGroupItem.key.entity.keyFunctionId);
                } else if (selectedGroupItem.key.entity instanceof Team) {
                    this.setSearchParameterValue(this.TeamGroupKey, selectedGroupItem.key.entity.teamId);
                } else if (selectedGroupItem.key.entity instanceof Role) {
                    this.setSearchParameterValue(this.RoleGroupKey, selectedGroupItem.key.entity.roleId);
                } else if (selectedGroupItem.key.zone) {
                    this.setSearchParameterValue(this.ZoneGroupKey, selectedGroupItem.key.zone);
                }
            }
        }
    }
}
