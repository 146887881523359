import { Component, Input, OnChanges } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { RatingValues } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { Observable } from "rxjs";
import { PeerCatchupService } from "../peer-catchup.service";

@Component({
    selector: "adapt-catchup-grid",
    templateUrl: "./catchup-grid.component.html",
    styleUrls: ["./catchup-grid.component.scss"],
})
export class CatchupGridComponent extends BaseComponent implements OnChanges {
    @Input() public filterForPersonId?: number;
    @Input() public filterForTeamId?: number;
    @Input() public activeOnly = true;

    public catchups?: SpeedCatchup[];

    public constructor(
        private catchupService: PeerCatchupService,
    ) {
        super();
    }

    public ngOnChanges() {
        let getCatchups$: Observable<SpeedCatchup[]>;
        if (this.filterForPersonId) {
            getCatchups$ = this.catchupService.getLatestCatchupsForPerson(this.filterForPersonId, {}, this.activeOnly);
        } else if (this.filterForTeamId) {
            getCatchups$ = this.catchupService.getLatestCatchupsForTeam(this.filterForTeamId);
        } else {
            getCatchups$ = this.catchupService.getLatestCatchups({}, this.activeOnly);
        }

        // reset catchups to trigger spinner before querying
        this.catchups = undefined;
        getCatchups$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((catchups) => this.catchups = catchups);
    }

    @Autobind
    public getStatusClass(creationDate: Date) {
        const today = moment().startOf("day");
        const nextCatchup = this.getNextCatchupDate(creationDate);
        const monthFromToday = moment()
            .startOf("day")
            .add(1, "month");

        if (today.isAfter(nextCatchup)) {
            return "status-overdue";
        } else if (monthFromToday.isAfter(nextCatchup)) {
            return "status-due";
        } else {
            return "status-onschedule";
        }
    }

    @Autobind
    public calculateStatusCellValue(catchup: SpeedCatchup) {
        const today = moment().startOf("day");
        const nextCatchup = this.getNextCatchupDate(catchup.creationDate);
        const monthFromToday = moment()
            .startOf("day")
            .add(1, "month");

        if (today.isAfter(nextCatchup)) {
            // overdue
            let diff = today.diff(nextCatchup, "years");
            if (diff >= 1) {
                return `Overdue by ${diff} years`;
            }

            diff = today.diff(nextCatchup, "months");
            if (diff >= 1) {
                return `Overdue by ${diff} months`;
            }

            diff = today.diff(nextCatchup, "days");
            return `Overdue by ${diff} days`;
        } else if (monthFromToday.isAfter(nextCatchup)) {
            return "Due this month";
        } else {
            return "On schedule";
        }
    }

    @Autobind
    public calculateParticipantsCellValue(catchup: SpeedCatchup) {
        if (this.filterForPersonId) {
            return this.otherPerson(catchup)?.fullName;
        } else {
            return `${catchup.person1?.fullName} & ${catchup.person2?.fullName}`;
        }
    }

    public calculateCreationDateDisplayValue(catchup: SpeedCatchup) {
        return moment(catchup.creationDate).format(DateFormats.moment.short);
    }

    public calculateCreationDateCellValue(catchup: SpeedCatchup) {
        return catchup.creationDate;
    }

    @Autobind
    public calculateNextCatchupCellValue(catchup: SpeedCatchup) {
        return this.getNextCatchupDate(catchup.creationDate).toDate();
    }

    @Autobind
    public calculateNextCatchupDisplayValue(catchup: SpeedCatchup) {
        return this.getNextCatchupDate(catchup.creationDate).format(DateFormats.moment.short);
    }

    public calculateLatestCombinedRatingCellValue(catchup: SpeedCatchup) {
        return RatingValues[catchup.extensions.worstRatingValue];
    }

    public calculateLatestCombinedRatingDisplayValue(catchup: SpeedCatchup) {
        return catchup.extensions.worstRatingValue;
    }

    public calculateTeamCellValue(catchup: SpeedCatchup) {
        return catchup.team?.name ?? "All teams";
    }

    public calculateTeamGroupValue(catchup: SpeedCatchup) {
        return catchup.teamId;
    }

    public otherPerson(catchup: SpeedCatchup) {
        return catchup.person1Id !== this.filterForPersonId
            ? catchup.person1
            : catchup.person2;
    }

    private getNextCatchupDate(creationDate: Date) {
        return this.catchupService.calculateNextCatchupDate(creationDate);
    }
}
