<adapt-feature-guidance>
    <p>We expose an API that developers can use to retrieve data from embedADAPT - allowing them to create custom integrations,
        visualisations or analysis.</p>
    <p>General information about the API is available
        <a href="https://support.adaptbydesign.com.au/developer-api">here</a>,
        and technical documentation for the API is available
        <a href="https://api.adaptbydesign.com.au/swagger/">here</a>.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <ng-container *ngIf="featureIsEnabledAndSaved">
        <ng-container *ngIf="apiEntries.length < 1; else existingTokens">
            <div>
                There are no existing API tokens registered for this organisation.
            </div>
        </ng-container>

        <div class="d-flex mt-3">
            <button *ngIf="!isCreating"
                    adaptButton="add"
                    class="me-2"
                    [adaptBlockingClick]="addNewApi"
                    data-test="api-add">API token
            </button>

            <div *ngIf="isCreating && newApi"
                 class="d-flex align-items-center">
                <div class="form-group">
                    <label for="selectPerson">Person</label>
                    <adapt-select-person id="selectPerson"
                                         [(person)]="newApi.person"
                                         [required]="true"
                                         [filter]="filterExistingApiPeople"></adapt-select-person>
                </div>

                <button class="ms-2"
                        adaptButton="add"
                        [disabled]="newApi.entityAspect.hasValidationErrors"
                        (click)="saveNewApi()"
                        data-test="api-add"></button>
                <button adaptButton="cancel"
                        [adaptBlockingClick]="cancelNewApi"></button>
            </div>
        </div>
    </ng-container>

    <ng-template #existingTokens>
        <label>Existing API tokens</label>

        <dx-data-grid [dataSource]="apiEntries"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true">

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>

            <dxi-column dataField="person.fullName"
                        dataType="string"
                        caption="Person"
                        cellTemplate="personNameLinkCellTemplate"></dxi-column>
            <dxi-column dataField="createdDateTime"
                        dataType="date"
                        caption="Created"
                        [format]="dateFormat"></dxi-column>
            <dxi-column dataField="lastUsedDateTime"
                        dataType="date"
                        caption="Last used"
                        [format]="dateFormat"
                        [customizeText]="lastUsedDateTimeText"></dxi-column>
            <dxi-column caption="Actions"
                        cellTemplate="actionsCellTemplate"></dxi-column>

            <div *dxTemplate="let data of 'personNameLinkCellTemplate'">
                <adapt-link-person [person]="data.data.person"></adapt-link-person>
            </div>

            <div *dxTemplate="let data of 'actionsCellTemplate'">
                <button adaptButton="delete"
                        (click)="deleteApiToken(data.data)"></button>
            </div>
        </dx-data-grid>
    </ng-template>
</adapt-feature-configuration>
