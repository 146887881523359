import { Injectable, Injector } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep, ProcessStepBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RoleService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private commonIntArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
    ) {
        super(injector);
    }

    /**
     * Get the roles which have been defined by the user
     */
    public getAllNonSpecialRoles() {
        const predicate = new MethodologyPredicate<Role>("roleTypeId", "==", null);
        return new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService).getActiveRolesByPredicate(predicate);
    }

    public getRelatedProcessMaps(role: Role) {
        if (!this.commonIntArchAuthService.currentPersonCanReadTier2()) {
            return of([]);
        }

        // TODO Exclude process maps with a location == this role when we implement that?
        // This only works if the required process steps are in the cache :/
        // Which we would prefer to do to keep the query tight
        // let predicate = new MethodologyPredicate("roleId", "==", role.roleId);
        // predicate = new MethodologyPredicate("processSteps", "any", predicate);
        // return this.commonDataService.getByPredicate(ProcessMapBreezeModel, predicate);

        const key = `assignedProcessSteps${role.roleId}`;
        const predicate = new MethodologyPredicate<ProcessStep>("roleId", "==", role.roleId);
        return this.commonDataService.getWithOptions(ProcessStepBreezeModel, key, {
            predicate,
            navProperty: "processMap",
        }).pipe(
            map((steps) => {
                // TODO sort by what?
                return ArrayUtilities.distinct(steps
                    // filter out steps without processMap or map which is already detached
                    .filter((step) => step.processMap && !step.processMap.entityAspect.entityState.isDetached())
                    .map((i) => i.processMap!));
            }),
        );
    }

    public getAssignedProcessStepsInMap(role: Role, processMap: ProcessMap) {
        // TODO sort by order in map? How to handle recursive nature easily
        const predicate = new MethodologyPredicate<ProcessStep>("roleId", "==", role.roleId)
            .and(new MethodologyPredicate<ProcessStep>("processMapId", "==", processMap.processMapId));
        return this.commonDataService.getByPredicate(ProcessStepBreezeModel, predicate);
    }
}
