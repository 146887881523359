import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CareerValuation, CareerValuationBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseService } from "@common/service/base.service";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { CulturalLeadershipAccessLevels } from "@org-common/lib/cultural-leadership/cultural-leadership-access-levels.enum";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
@Autobind
export class CareerValuationDataService extends BaseService {
    private clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private clfAuthService: CulturalLeadershipFrameworkAuthService,
    ) {
        super(injector);
    }

    public promiseToCheckCareerValuationAccessLevel(personId?: number) {
        return this.clfAuthService.checkCulturalAccessLevel(FeatureName.CulturalNetworkCareerValuation, personId);
    }

    /**
     * Promise to get the latest career valuations for everyone in the organisation
     * @returns {promise} A promise that resolves with an array of career valuations
     */
    public async promiseToGetLatestCareerValuations() {
        let accessLevel;

        try {
            await new PeopleQueryUtilities(this.commonDataService).promiseToGetActiveConnections();
            accessLevel = await this.promiseToCheckCareerValuationAccessLevel();
        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }

        if (accessLevel) {
            const options = {
                namedParams: {
                    latestOnly: true,
                    activeOnly: true,
                },
            };


            const careerValuations = (await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationBreezeModel, "latestCareerValuations", options)))
                .filter((results) => !results.person || !!results.person.getLatestEmployeeConnection());

            this.clQueryUtilities.setCulturalEntityOptions(options, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationCategoryValueBreezeModel, "latestCareerValuationValues", options));

            this.clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            if (accessLevel !== CulturalLeadershipAccessLevels.AnonymousAccess) {
                await this.clQueryUtilities.promiseToGetActiveCulturalLeadershipRelationships();
            }
            return careerValuations;
        }
    }

    /**
     * Promise to get the latest career valuation for a specified person
     * @param {int} personId : The ID of the specified person
     * @returns {promise} A promise that resolves with a single career valuation
     */
    public async promiseToGetLatestCareerValuationForPerson(personId: number) {
        const clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

        const key = "latestCareerValuationForPerson" + personId;
        const options = {
            namedParams: {
                latestOnly: true,
            },
            predicate: new MethodologyPredicate<CareerValuation>("personId", "==", personId),
        };


        try {
            const accessLevel = await this.promiseToCheckCareerValuationAccessLevel(personId);
            clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            const latestCareerValuations = await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationBreezeModel, key, options));
            const careerValuation = ArrayUtilities.getSingleFromArray(latestCareerValuations);

            if (!careerValuation) {
                return undefined;
            }

            const valuesKey = "latestCareerValuationValuesForPerson" + personId;
            const valuesOptions = {
                predicate: new MethodologyPredicate<CareerValuationCategoryValue>("careerValuationId", "==", careerValuation.careerValuationId),
            };

            clQueryUtilities.setCulturalEntityOptions(valuesOptions, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationCategoryValueBreezeModel, valuesKey, valuesOptions));
            return careerValuation;

        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }
    }
}
