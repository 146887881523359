import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { StakeholderStory } from "./stakeholder-story";
import { ValueStreamStakeholder } from "./value-stream-stakeholder";

export class ValueStreamStakeholderStory extends BaseEntity<ValueStreamStakeholderStory> {
    public valueStreamStakeholderStoryId!: number;
    public valueStreamStakeholderId!: number;
    public stakeholderStoryId!: number;
    public ordinal!: number;
    public stakeholderStory!: StakeholderStory;
    public valueStreamStakeholder!: ValueStreamStakeholder;
}

export const ValueStreamStakeholderStoryBreezeModel = new BreezeModelBuilder("ValueStreamStakeholderStory", ValueStreamStakeholderStory)
    .hasSource()
    .isOrganisationEntity()
    .withSingularName("Value Stream Customer Story")
    .withPluralNameAndNoSourceOverride("Value Stream Customer Stories")
    .withSortField("ordinal")
    .alwaysFetchingNavigationProperty("valueStreamStakeholder")
    .build();
