import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { SpeedCatchupConfiguration } from "@common/ADAPT.Common.Model/organisation/speed-catchup-configuration";
import { CatchupRatingType, CatchupRatingTypeName, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { forkJoin, lastValueFrom, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { PeerCatchupService } from "../peer-catchup.service";
import { PeerCatchupUiService } from "../peer-catchup-ui.service";

const CatchupOverallGuidance = "Overall";
export type CatchupGuidanceType = CatchupRatingTypeName | typeof CatchupOverallGuidance;

// need this instead of just passing in SpeedCatchup entity as it can be restored to the last step
// persisted (usually rating type - identified by rating type name)
export interface IRecordCatchupDialogData {
    catchup: SpeedCatchup;
    currentRatingType?: CatchupRatingType; // optional as first page will be selecting people without this
}

interface IProgressStep {
    stepClass: string;
    label: string;
    activate?: () => void;
    deactivate?: () => Observable<unknown>; // may need to wait for previous step to finish deactivating before activating next
}

@Component({
    selector: "adapt-record-catchup-dialog",
    templateUrl: "./record-catchup-dialog.component.html",
    styleUrls: ["./record-catchup-dialog.component.scss"],
})
@PersistableDialog("RecordCatchupDialog")
export class RecordCatchupDialogComponent extends BaseDialogWithDiscardConfirmationComponent<SpeedCatchup> implements OnInit {
    public readonly dialogName = "RecordCatchupDialog";

    public catchup: SpeedCatchup;
    public ratingTypes = CatchupRatingType.All;
    public progressSteps: IProgressStep[] = [];

    public previousButtonDisabled = false;
    public nextButtonDisabled = false;
    public currentRatingType?: CatchupRatingType;
    public isLastStep = false;

    public guidanceText?: string;

    protected entitiesToConfirm: IBreezeEntity<any>[];

    private currentStepIndex = 0;
    private catchupUiService: PeerCatchupUiService;
    private configuration?: SpeedCatchupConfiguration;
    private guidanceContent?: Record<CatchupGuidanceType, string | undefined>;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) private data: IRecordCatchupDialogData,
        private catchupService: PeerCatchupService,
        private changeDetectorRef: ChangeDetectorRef,
        injector: Injector,
    ) {
        super();

        // cannot inject PeerCatchupUiService in the constructor declaration as it will result in circular dependency with PersistableDialog
        this.catchupUiService = injector.get(PeerCatchupUiService);
        this.catchup = this.data.catchup;
        this.entitiesToConfirm = [this.catchup, ...this.catchup.ratings];

        this.currentRatingType = this.ratingTypes.find((ratingType) => ratingType === data.currentRatingType);
        this.setupProgressSteps();

        if (!this.currentRatingType) {
            this.currentStepIndex = 0;
        } else {
            this.currentStepIndex = this.ratingTypes.indexOf(this.currentRatingType) + 1;
        }
    }

    public get guidanceType(): CatchupGuidanceType {
        return this.currentRatingType?.name ?? CatchupOverallGuidance;
    }

    public async ngOnInit() {
        this.configuration = await lastValueFrom(this.catchupService.getCatchupConfiguration());
        this.guidanceContent = {
            [CatchupRatingTypeName.Engagement]: this.configuration?.guidanceEngagement,
            [CatchupRatingTypeName.Connection]: this.configuration?.guidanceConnection,
            [CatchupRatingTypeName.Contribution]: this.configuration?.guidanceContribution,
            [CatchupOverallGuidance]: this.configuration?.guidanceOverall,
        };

        this.activateCurrentStep();
    }

    @Autobind
    public activateNext() {
        return this.deactivateCurrentStep().pipe(
            tap(() => {
                this.currentStepIndex++;
                // activating next or previous will change the ...ButtonDisable of the button, which is still
                // within the blocking click here. Upon finishing blocking click, it will re-enable the button
                // - so have to do this in the next digest cycle after the blocking click subscription is finished.
                setTimeout(() => this.activateCurrentStep());
            }),
        );
    }

    @Autobind
    public activatePrevious() {
        return this.deactivateCurrentStep().pipe(
            tap(() => {
                this.currentStepIndex--;
                setTimeout(() => this.activateCurrentStep());
            }),
        );
    }

    @Autobind
    public saveAndReview() {
        if (this.catchup.facilitatorPersonId) {
            // adding temporary comment to allow save (from old code)
            this.catchup.facilitatorComment = "No comment";
        }

        return this.deactivateCurrentStep().pipe(
            // TODO: facilitator stuffs here
            switchMap(() => this.catchupService.saveEntities(this.entitiesToConfirm)),
            tap(() => setTimeout(() => {
                // do this in the next digest cycle
                this.resolve(this.catchup);
                this.catchupService.emitCatchupChange(this.catchup);
                if (this.catchup.facilitatorPersonId) {
                    // remove temporary comment
                    this.catchup.facilitatorComment = "";
                }

                // this will scroll to facilitator section if there is a facilitator person selected
                this.catchupUiService.showCatchup(this.catchup, true, true, !!this.catchup.facilitatorPersonId)
                    .subscribe(() => this.catchupService.emitCatchupChange(this.catchup));
            })),
        );
    }

    public validateCurrentRatingType() {
        this.nextButtonDisabled = !this.currentRatingTypeValid;
        // for some unknown reason, there was occassional huge delay before the next/save button pick this up and
        // update [disabled] (sometimes 2 seconds!) - this will speed it up - button changed when content change callback
        // is triggered.
        this.changeDetectorRef.detectChanges();
    }

    private activateCurrentStep() {
        if (this.currentProgressStep.activate) {
            this.currentProgressStep.activate();
        }

        // record this with incoming data so that can be restored from unsaved entity
        this.data.currentRatingType = this.currentStepIndex > 0
            ? this.ratingTypes[this.currentStepIndex - 1]
            : undefined;

        this.guidanceText = this.guidanceContent?.[this.guidanceType];
    }

    private deactivateCurrentStep() {
        if (this.currentProgressStep.deactivate) {
            return this.currentProgressStep.deactivate();
        } else {
            // note that of() won't emit anything - so emitting undefined here to trigger subsequent observable hop
            return of(undefined);
        }
    }

    private get currentProgressStep() {
        return this.progressSteps[this.currentStepIndex];
    }

    private get currentRatingTypeValid() {
        if (this.currentRatingType) {
            const ratings = this.catchup.ratings.filter((rating) => rating.ratingType === this.currentRatingType?.name);
            return ratings.every((rating) => !rating.entityAspect.hasValidationErrors);
        } else {
            return false;
        }
    }

    private setupProgressSteps() {
        this.progressSteps = [];
        this.progressSteps.push(this.createProgressStepForParticipants());
        this.ratingTypes.forEach((ratingType) => this.progressSteps.push(this.createProgressStepForRatingType(ratingType)));
    }

    private createProgressStepForRatingType(ratingType: CatchupRatingType) {
        const progressStep = {
            label: `Measure ${ratingType.name}`,
            stepClass: "",
        } as IProgressStep;

        progressStep.activate = () => {
            this.previousButtonDisabled = !ratingType.ordinal; // won't allow going back from 1st rating type
            this.nextButtonDisabled = true; // only enable after validate
            progressStep.stepClass = "current";
            this.currentRatingType = ratingType;
            this.data.currentRatingType = ratingType;
            this.validateCurrentRatingType();

            this.isLastStep = ratingType === this.ratingTypes[this.ratingTypes.length - 1];
        };

        progressStep.deactivate = () => {
            progressStep.stepClass = this.currentRatingTypeValid
                ? "completed"
                : "";
            return of(undefined);
        };

        return progressStep;
    }

    private createProgressStepForParticipants() {
        const progressStep = {
            label: "Select participants",
            stepClass: "",
        } as IProgressStep;

        progressStep.activate = () => {
            this.previousButtonDisabled = true;
            this.nextButtonDisabled = true;
            progressStep.stepClass = "current";
        };

        progressStep.deactivate = () => {
            progressStep.stepClass = "completed";
            return this.createRatingsForCatchup();
        };

        return progressStep;
    }

    private createRatingsForCatchup() {
        // create rating entities for the catchup - if the rating types is not already in the catchup entities
        if (this.catchup.ratings.length === 0) {
            // 2 rating values for each rating type
            const createRatingObservables: Observable<SpeedCatchupRating>[] = [];
            this.ratingTypes.forEach((ratingType) => {
                createRatingObservables.push(this.catchupService.createRating({
                    speedCatchup: this.catchup,
                    ratingType: ratingType.name,
                    authorPersonId: this.catchup.person1Id,
                    subjectPersonId: ratingType.authorIsSubject
                        ? this.catchup.person1Id
                        : this.catchup.person2Id,
                    feedbackPersonId: !ratingType.ratingIsMutual
                        ? this.catchup.person2Id
                        : undefined,
                } as Partial<SpeedCatchupRating>));
                createRatingObservables.push(this.catchupService.createRating({
                    speedCatchup: this.catchup,
                    ratingType: ratingType.name,
                    authorPersonId: this.catchup.person2Id,
                    subjectPersonId: ratingType.authorIsSubject
                        ? this.catchup.person2Id
                        : this.catchup.person1Id,
                    feedbackPersonId: !ratingType.ratingIsMutual
                        ? this.catchup.person1Id
                        : undefined,
                } as Partial<SpeedCatchupRating>));
            });

            return forkJoin(createRatingObservables).pipe(
                tap((ratings) => this.entitiesToConfirm = [this.catchup, ...ratings]),
            );
        } else {
            return of(this.catchup.ratings);
        }
    }
}
