import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ValueStreamBusinessModelPageComponent } from "./value-stream-business-model-page.component";

export const valueStreamBusinessModelPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-value-stream-business-model-page", ValueStreamBusinessModelPageComponent)
    .atOrganisationUrl("/architecture/valueStream/:valueStreamId/commercial-model")
    .redirectToThisRouteFromOrganisationUrl("/architecture/valueStream/:valueStreamId/commercialModel")
    .verifyingAccess(Tier1ArchitectureAuthService.ReadTier1)
    .withTitle("Commercial Model")
    .build();
