<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [crumbTemplate]="crumbs"
                              [snippetTemplate]="snippet">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2">
            <div class="d-flex align-items-center result-icon system-icon">
                <i class="fal fa-solar-system fa-inverse"></i>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.name"></adapt-highlighted-text>
            <adapt-display-labels class="ms-2"
                                  [linkDisabled]="true"
                                  [labelLocations]="labelLocations"></adapt-display-labels>
        </div>
    </div>
</adapt-collapsible-search-row>

<ng-template #snippet
             let-match="match">
    <!-- custom styling for key result previews -->
    <!-- can't just use ngIf as the match context needs to be passed to the default template -->
    <ng-container *ngTemplateOutlet="isProcessStep(match) ? processStepTemplate : row.defaultSnippetTemplate; context: {match: match}">
    </ng-container>
</ng-template>

<ng-template #processStepTemplate
             let-matched="match">
    <ng-container *ngIf="asProcessStep(matched) as match">
        <div class="flex-basis-100 d-flex align-items-center">
            <ng-container *ngIf="getStepLabelLocations(match.extra?.labelLocationIds ?? []) as locations">
                <adapt-display-labels class="ms-2"
                                      [linkDisabled]="true"
                                      [labelLocations]="locations"></adapt-display-labels>
            </ng-container>
            <div class="d-inline-block"
                 [ngClass]="getStepClass(match.extra?.stepType ?? ProcessStepType.ProcessStep) + '-bg'">
                <adapt-highlighted-text [needle]="keyword"
                                        [haystack]="match.snippet"></adapt-highlighted-text>
                <i class="ms-1"
                   [ngClass]="getStepIconClass(match.extra?.stepType ?? ProcessStepType.ProcessStep)"></i>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #crumbs
             let-matched="match">
    <ng-container *ngIf="asMatch(matched) as match">
        <ng-container *ngFor="let crumb of match.field; last as last; index as i">
            <!-- i >= 2 means we're within the process map -->
            <div *ngIf="isProcessStep(match) && i >= 2; else crumbText"
                 class="process-step-bg d-inline-block">
                {{crumb}} <i class="ms-1"
                             [ngClass]="getStepIconClass(match.extra?.stepType ?? ProcessStepType.ProcessStep)"></i>
            </div>
            <ng-template #crumbText><span>{{crumb}}</span></ng-template>
            <span *ngIf="!last"
                  class="px-1">&gt;</span>
        </ng-container>
    </ng-container>
</ng-template>
