import { Injectable, Injector } from "@angular/core";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalIndex, CulturalIndexBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { PersonProfileItemValue, PersonProfileItemValueBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { CulturalLeadershipAccessLevels } from "@org-common/lib/cultural-leadership/cultural-leadership-access-levels.enum";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { CulturalLeadershipFrameworkService } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipAnalysisDataBaseService } from "../../culture/cultural-leadership/cultural-leadership-analysis-data-base.service";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "../../culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import { CulturalIndexAnalysisFilter } from "./cultural-index-analysis-filter";
import { CulturalIndexDataService } from "./cultural-index-data.service";

@Injectable({
    providedIn: "root",
})
@Autobind
export class CulturalIndexAnalysisDataService extends CulturalLeadershipAnalysisDataBaseService {
    public constructor(
        injector: Injector,
        private culturalIndexDataService: CulturalIndexDataService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
    ) {
        super(injector);
    }

    public async promiseToGetCulturalIndexAnalysisData(
        initialFilter?: ICulturalLeadershipMeasurementAnalysisFilter,
        prefetchAllData = false,
    ) {
        const queryOptions = {
            namedParams: {
                latestOnly: true,
            },
        };

        const filter = initialFilter ? initialFilter : CulturalIndexAnalysisFilter.getDefaultFilterParameters();
        const date = filter.date ? filter.date : new Date(); // data to filter/limit all records is now or the passed in date

        try {
            const accessLevel = await this.culturalIndexDataService.promiseToCheckCulturalIndexAccessLevel();
            const hasQuantitativeAccess = accessLevel === CulturalLeadershipAccessLevels.QuantitativeAccess || accessLevel === CulturalLeadershipAccessLevels.FullAccess;
            const [connections, culturalRelationships] = await this.getPrimeData(filter, queryOptions, accessLevel, prefetchAllData);
            const culturalIndexes = await this.getCulturalIndexData(queryOptions, filter, hasQuantitativeAccess);
            const culturalData = this.convertCulturalIndexDataToAnalysisData(culturalIndexes, date);
            return this.processCulturalIndexAnalysisData(culturalData, connections, hasQuantitativeAccess, date, filter, culturalRelationships);
        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }
    }

    private async getPrimeData(filter: ICulturalLeadershipMeasurementAnalysisFilter, queryOptions: any, accessLevel: CulturalLeadershipAccessLevels, prefetchAllData: boolean) {
        const clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);
        clQueryUtilities.setCulturalEntityOptions(queryOptions, accessLevel);

        // fetching all connections here since it is likely to be more efficient that getting connections piecemeal for every single/date/combination of filter
        const connections = await lastValueFrom(this.commonDataService.getAll(ConnectionBreezeModel));

        let culturalRelationships: CulturalRelationship[] = [];

        if (filter.team) {
            await lastValueFrom(this.commonDataService.getAll(TeamBreezeModel));
        }

        if (filter.profileItem && filter.profileItemValue) {
            const predicate = new MethodologyPredicate<PersonProfileItemValue>("personProfileItemId", "==", filter.profileItem.personProfileItemId);
            await lastValueFrom(this.commonDataService.getByPredicate(PersonProfileItemValueBreezeModel, predicate));
        }

        if (prefetchAllData || filter.showTrendOutwardsOnly) {
            await lastValueFrom(this.commonDataService.getAll(CulturalIndexBreezeModel));
        }

        if (accessLevel !== CulturalLeadershipAccessLevels.AnonymousAccess) {
            culturalRelationships = await clQueryUtilities.promiseToGetAllCulturalRelationships();
        }

        return [connections, culturalRelationships] as [Connection[], CulturalRelationship[]];
    }

    private async getCulturalIndexData(queryOptions: any, filter: ICulturalLeadershipMeasurementAnalysisFilter, hasQuantitativeAccess: boolean) {
        let key = "latestCulturalIndexesByDate";

        if (!hasQuantitativeAccess) {
            queryOptions.namedParams.activeOnly = true;
        }

        if (filter.date) {
            queryOptions.namedParams.date = filter.date;
            key += filter.date.toDateString();
        } else {
            key += "Today";
        }

        const culturalIndexes = await lastValueFrom(this.commonDataService.getWithOptions(CulturalIndexBreezeModel, key, queryOptions));
        return culturalIndexes as CulturalIndex[];
    }

    private convertCulturalIndexDataToAnalysisData(culturalIndexes: CulturalIndex[], date: Date) {
        return culturalIndexes.map((ci) => this.mapCulturalIndex(ci, date));
    }

    private mapCulturalIndex(culturalIndex: CulturalIndex, date: Date) {
        const data = {
            person: culturalIndex.person,
            culturalIndex,
            latestCatchup: culturalIndex.creationDate,
            nextCatchup: this.culturalLeadershipFrameworkService.getNextCatchupDate(culturalIndex.creationDate),
        } as ICulturalLeadershipPersonalAnalysisData;

        data.employeeConnections = data.person
            ? data.person.connections.filter((connection: Connection) => connection.isEmployeeConnection())
            : [];
        data.catchupStatus = this.culturalLeadershipFrameworkService.getCatchupStatusFromDate(date, data.nextCatchup);

        return data;
    }

    private processCulturalIndexAnalysisData(culturalData: ICulturalLeadershipPersonalAnalysisData[], connections: Connection[], hasQuantitativeAccess: boolean, date: Date, filter: any, culturalRelationships: CulturalRelationship[]) {
        culturalData = this.filterByCulturalMeasurement(culturalData, connections, hasQuantitativeAccess, date, filter, culturalRelationships);

        // show all trends; return all records
        if (filter.showTrendOutwardsOnly) {
            culturalData = culturalData.filter((data) => this.trendFilter(data, date));
        }

        return culturalData;
    }

    private trendFilter(analysisData: ICulturalLeadershipPersonalAnalysisData, date: Date) {
        if (!analysisData.culturalIndex || !analysisData.person) {
            return false;
        }

        // not enough data to work this out
        if (analysisData.person.culturalIndexes.length < 3) {
            return false;
        }

        const valueCulturalIndexes = analysisData.person.culturalIndexes
            .filter((ci: CulturalIndex) => moment(ci.creationDate).isBefore(date))
            .sort((a: CulturalIndex, b: CulturalIndex) => moment(a.creationDate) < moment(b.creationDate) ? 1 : -1);

        // not enough data to work this out
        if (valueCulturalIndexes.length < 3) {
            return false;
        }

        return (valueCulturalIndexes[0].value - valueCulturalIndexes[2].value) > 0.25;
    }
}
