import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepType, ProcessStepTypeMetadata } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Logger } from "@common/lib/logger/logger";
import { tap } from "rxjs/operators";
import { ProcessMapUiService } from "../process-map-ui.service";

interface IAddChildAction {
    type: ProcessStepType;
    iconClass: string;
    text: string;
}

@Component({
    selector: "adapt-add-child-step",
    templateUrl: "./add-child-step.component.html",
    styleUrls: ["./add-child-step.component.scss"],
})
export class AddChildStepComponent implements OnChanges {
    @Input() public processMap!: ProcessMap;
    @Input() public parentStep!: ProcessStep;
    @Output() public stepAdded = new EventEmitter<ElementRef>();

    public actions: IAddChildAction[];

    private log = Logger.getLogger("AddChildStep");

    public constructor(
        private processMapUiService: ProcessMapUiService,
        private elementRef: ElementRef,
    ) {
        this.actions = [
            {
                type: ProcessStepType.RoleTask,
                iconClass: ProcessStepTypeMetadata.IconClass[ProcessStepType.RoleTask],
                text: `Add a ${ProcessStepTypeMetadata.Label[ProcessStepType.RoleTask]}`,
            },
            {
                type: ProcessStepType.ProcessStep,
                iconClass: ProcessStepTypeMetadata.IconClass[ProcessStepType.ProcessStep],
                text: `Add a ${ProcessStepTypeMetadata.Label[ProcessStepType.ProcessStep]}`,
            },
        ];
    }

    public ngOnChanges() {
        if (!this.processMap) {
            this.log.error("ProcessMap should be defined");
        }
        if (this.parentStep && !this.parentStep.extensions.isProcessStep) {
            this.log.error("ParentStep should be a process step", this.parentStep);
        }
    }

    @Autobind
    public addProcessStep(type: ProcessStepType) {
        return this.processMapUiService.addProcessStep(this.parentStep ?? this.processMap, type).pipe(
            tap(() => this.stepAdded.emit(this.elementRef)),
        );
    }
}
