import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DocumentSize, SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemDocumentType } from "@common/ADAPT.Common.Model/organisation/system-document";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { ProcessMapUiService } from "app/features/architecture/process-map/process-map-ui.service";
import { IMapStackFramesChangedEvent } from "app/features/architecture/process-map/process-steps-card/process-steps-card.component";
import { EMPTY, forkJoin, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { systemPageRoute } from "../system-page/system-page.route";
import { SystemisationService } from "../systemisation.service";
import { SystemisationUiService } from "../systemisation-ui.service";

@Component({
    selector: "adapt-system-component",
    templateUrl: "./system-component.component.html",
    styleUrls: ["./system-component.component.scss"],
})
export class SystemComponentComponent extends BaseComponent implements OnChanges {
    public readonly SystemDocumentType = SystemDocumentType;

    @Input() public systemComponent?: SystemComponent;
    @Input() public isEditing = false;
    @Input() public processMapState?: IMapStackFramesChangedEvent;
    @Input() public isNewlyAdded = false;
    @Output() public processMapStateChange = new EventEmitter<IMapStackFramesChangedEvent>();
    @Output() public systemComponentDeleted = new EventEmitter<SystemComponent>();
    @Output() public newComponentAnimated = new EventEmitter<SystemComponent>();

    // this will be emitted after copy -> the newly copied component will be emitted
    @Output() public componentCopied = new EventEmitter<SystemComponent>();

    private readonly editDocumentMenuItem: IAdaptMenuItem = {
        text: "Edit",
        icon: "fal fa-fw fa-edit",
        onClick: this.editDocument,
    };

    private readonly copyDocumentMenuItem: IAdaptMenuItem = {
        text: "Copy document",
        icon: "fal fa-fw fa-copy",
        onClick: () => this.copySystemComponent(this.systemComponent!),
        separator: true,
    };

    private readonly moveDocumentMenuItem: IAdaptMenuItem = {
        text: "Move to another system",
        icon: "fal fa-fw fa-arrows-alt",
        onClick: () => this.moveSystemComponent(this.systemComponent!),
    };

    private readonly setDisplayHeightMenuItem: IAdaptMenuItem = {
        text: "Set display height",
        icon: "fal fa-fw fa-line-height",
        separator: true,
        items: [],
        visible: false,
    };

    private readonly deleteDocumentMenuItem: IAdaptMenuItem = {
        text: "Delete",
        icon: "fal fa-fw fa-trash-alt",
        onClick: () => this.deleteSystemDocument(this.systemComponent!),
        separator: true,
    };

    public editDocumentMenu: IAdaptMenuItem[] = [];

    public constructor(
        private systemisationService: SystemisationService,
        private systemisationUiService: SystemisationUiService,
        private dialogService: AdaptCommonDialogService,
        private processMapUiService: ProcessMapUiService,
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.systemComponent && this.systemComponent) {
            // no need to prime supplementary data for the system component as this is only used in system-content.component
            // and this is from systemisation.service getSystemComponentsForSystem where supp data is already in navProperty
            this.updateMenu();
        }

        if (changes.isNewlyAdded && this.isNewlyAdded) {
            // not just from after view init but can also for an already initialised view where this is changed due to a move or copy
            setTimeout(() => this.scrollIntoViewAfterAddedToParent(), 100);
        }
    }

    private updateMenu() {
        // menu needs to be re-assigned as angular is not detecting deep changes (e.g. display height)
        this.editDocumentMenu = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [this.editDocumentMenuItem, this.copyDocumentMenuItem, this.moveDocumentMenuItem, this.setDisplayHeightMenuItem, this.deleteDocumentMenuItem],
        }];

        if (this.systemComponent) {
            const documentType = this.systemComponent.extensions.componentLabel;
            this.editDocumentMenuItem.text = `Edit ${documentType}`;
            this.copyDocumentMenuItem.text = `Copy ${documentType}...`;
            this.moveDocumentMenuItem.text = `Move ${documentType} to another system`;
            this.deleteDocumentMenuItem.text = `Delete ${documentType}`;

            // allow setting the size of iframes
            this.setDisplayHeightMenuItem.visible = this.systemComponent.systemDocument?.type === SystemDocumentType.ExternalLink;
            if (this.setDisplayHeightMenuItem.visible) {
                this.setDisplayHeightMenuItem.items = Object.keys(DocumentSize).map((key) => ({
                    text: StringUtilities.camelToSentenceCase(key),
                    onClick: () => this.setSize(key as DocumentSize),
                    isChecked: this.systemComponent?.size === key,
                }));
            }
        }
    }

    private scrollIntoViewAfterAddedToParent() {
        // wait till offsetParent is set (element added to parent)
        if (this.elementRef?.nativeElement.offsetParent) {
            this.elementRef?.nativeElement.scrollIntoView(true); // align top
            setTimeout(() => this.newComponentAnimated.emit(this.systemComponent), 2200);
        } else {
            setTimeout(() => this.scrollIntoViewAfterAddedToParent(), 100);
        }
    }

    @Autobind
    public editDocument() {
        if (this.systemComponent?.systemDocument?.type === SystemDocumentType.Video) {
            this.editVideoDocument(this.systemComponent);
        } else if (this.systemComponent?.systemDocument?.type === SystemDocumentType.ExternalLink) {
            this.editExternalLink(this.systemComponent);
        } else if (this.systemComponent?.systemDocument) {
            this.editSystemDocumentComponent(this.systemComponent);
        }
    }

    @Autobind
    public moveSystemComponent(systemComponent: SystemComponent) {
        const initialSystemId = systemComponent.systemEntityId;
        return this.systemisationUiService.moveSystemComponent(systemComponent).pipe(
            switchMap((result) => {
                if (result.systemEntityId !== initialSystemId) {
                    const dialogData: IConfirmationDialogData = {
                        cancelButtonText: "Stay here",
                        confirmButtonText: "Go to destination system",
                        title: `${StringUtilities.upperCaseFirstCharacter(systemComponent.extensions.componentLabel)} moved`,
                        message: `The ${systemComponent.extensions.componentLabel} has been successfully moved. Do you want to go to the destination system?`,
                    };
                    return this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
                        switchMap((move) => move
                            ? systemPageRoute.gotoRoute(
                                { systemEntityId: systemComponent.systemEntityId },
                                { addedComponentId: systemComponent.systemComponentId })
                            : of(result)),
                    );
                }

                return of(result);
            }),
            tap(() => this.systemComponentDeleted.emit(this.systemComponent)),
        ).subscribe();
    }

    public copySystemComponent(systemComponent: SystemComponent) {
        const initialSystemId = systemComponent.systemEntityId;
        return this.systemisationUiService.copySystemComponent(systemComponent).pipe(
            switchMap((destinationSystemComponent) => {
                if (destinationSystemComponent.systemEntityId !== initialSystemId) {
                    const dialogData: IConfirmationDialogData = {
                        cancelButtonText: "Stay here",
                        confirmButtonText: "Go to destination system",
                        title: `${StringUtilities.upperCaseFirstCharacter(systemComponent.extensions.componentLabel)} copied`,
                        message: `The ${systemComponent.extensions.componentLabel} has been successfully copied. Do you want to go to the destination system?`,
                    };
                    return this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
                        switchMap((go) => go
                            ? systemPageRoute.gotoRoute(
                                { systemEntityId: destinationSystemComponent.systemEntityId },
                                { addedComponentId: destinationSystemComponent.systemComponentId })
                            : of(destinationSystemComponent)),
                    );
                } else {
                    // copied to the same system
                    this.componentCopied.emit(destinationSystemComponent);
                }

                return of(destinationSystemComponent);
            }),
        ).subscribe();
    }

    public setSize(newSize: DocumentSize) {
        const originalSize = this.systemComponent!.size;

        this.systemComponent!.size = newSize;
        this.updateMenu();

        this.systemisationService.saveEntities([this.systemComponent!]).pipe(
            catchError(() => {
                this.systemComponent!.size = originalSize;
                this.updateMenu();
                return EMPTY;
            }),
        ).subscribe();
    }

    public deleteSystemDocument(systemComponent: SystemComponent) {
        const documentType = systemComponent.extensions.componentLabel;
        const dialogData: IConfirmationDialogData = {
            title: `Remove ${documentType} from system?`,
            message: `<p>Are you sure you want to remove this ${systemComponent.extensions.componentLabel} (<i>${systemComponent.name}</i>) from the system?</p>
                <p>Once this is removed, it can no longer be retrieved.</p>`,
            checkboxMessage: `Confirm that you really want to delete the ${systemComponent.extensions.componentLabel}`,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => {
                const deleteEntities: IBreezeEntity[] = [systemComponent];
                if (systemComponent.systemDocument) {
                    deleteEntities.push(systemComponent.systemDocument);
                }

                return forkJoin(deleteEntities.map((i) => this.systemisationService.remove(i))).pipe(
                    map(() => deleteEntities),
                );
            }),
            switchMap((deletedEntities) => this.systemisationService.saveEntities(deletedEntities)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.systemComponentDeleted.emit(this.systemComponent));
    }

    public deleteProcessMap(processMapComponent: SystemComponent) {
        if (processMapComponent.systemProcessMap) {
            this.processMapUiService.deleteProcessMap(processMapComponent.systemProcessMap).pipe(
                // don't have to delete SystemComponent as that's handled by cascade delete of process map
                // -> doing additional delete here will throw exception
                this.takeUntilDestroyed(),
            ).subscribe(() => this.systemComponentDeleted.emit(this.systemComponent));
        }
    }

    public deleteDiagram(component: SystemComponent) {
        const data: IConfirmationDialogData = {
            title: "Delete diagram",
            message: "Are you sure you wish to delete this diagram from this system?",
            checkboxMessage: "Confirm that you really want to delete the diagram",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        return this.dialogService.openConfirmationDialog(data).pipe(
            switchMap(() => this.systemisationService.remove(component.systemDiagram!)),
            switchMap(() => this.systemisationService.remove(component!)),
            switchMap(() => this.systemisationService.save()),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.systemComponentDeleted.emit(this.systemComponent));
    }

    @Autobind
    public editSystemDocumentComponent(systemComponent: SystemComponent) {
        return this.systemisationUiService.openSystemDocumentDialog(systemComponent).subscribe();
    }

    @Autobind
    public editVideoDocument(systemComponent: SystemComponent) {
        if (systemComponent.systemDocument && systemComponent.systemDocument.type === SystemDocumentType.Video) {
            return this.systemisationUiService.openEditVideoDialog(systemComponent.systemDocument, systemComponent).subscribe();
        }
    }

    @Autobind
    public editExternalLink(systemComponent: SystemComponent) {
        if (systemComponent.systemDocument && systemComponent.systemDocument.type === SystemDocumentType.ExternalLink) {
            return this.systemisationUiService.openEditExternalLinkDialog(systemComponent).subscribe();
        }
    }
}
