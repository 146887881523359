<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.AnalyseCvtDifferentials"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title>
    <h2>
        Career Valuation Differentials
        <i class="fal fa-fw fa-info-circle"
           adaptTooltip="This chart shows people who have a large differential between their ideal and actual scores.">
        </i>
    </h2>
</adapt-toolbar-title>

<adapt-filter-content (reset)="resetFilter()"
                      [isDefault]="isDefault"
                      [urlParamNames]="CareerValuationFilterParamKeys">
    <adapt-career-valuation-filter>
    </adapt-career-valuation-filter>
</adapt-filter-content>

<div class="row">
    <adapt-cvt-differentials-chart class="col-12">
    </adapt-cvt-differentials-chart>
</div>
