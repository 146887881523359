<adapt-toolbar-title>
    <h2>
        Cultural Index timeline
        <i class="fal fa-fw fa-info-circle"
           adaptTooltip="This page shows a chart of the aggregated cultural index values (broken down by category) over time.">
        </i>
    </h2>
</adapt-toolbar-title>

<adapt-filter-content (reset)="filterService.reset()"
                      [isDefault]="filterService.isDefault"
                      [urlParamNames]="CareerValuationFilterParamKeys">
    <adapt-cultural-index-filter></adapt-cultural-index-filter>
</adapt-filter-content>

<div class="row">
    <adapt-ci-timeline class="col-12">
    </adapt-ci-timeline>
</div>
