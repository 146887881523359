import { Component, Input, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ActivityFeedService } from "app/general/activity-feed/activity-feed.service";
import { ActivityFeedComponent } from "app/general/activity-feed/activity-feed/activity-feed.component";
import { ActivityFeedSignalRHub } from "app/general/activity-feed/activity-feed-signalr-hub";
import { lastValueFrom, ReplaySubject } from "rxjs";
import { first, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-personal-activity-feed",
    templateUrl: "./personal-activity-feed.component.html",
})
export class PersonalActivityFeedComponent extends BaseComponent {
    @Input() public set person(value: Person) {
        if (value) {
            this.person$.next(value);
        }
    }
    private person$ = new ReplaySubject<Person>(1);

    @ViewChild(ActivityFeedComponent)
    public activityFeedComponent!: ActivityFeedComponent;

    public constructor(
        private activityFeedService: ActivityFeedService,
        activityFeedHub: ActivityFeedSignalRHub,
    ) {
        super();

        this.person$.pipe(
            switchMap((person) => activityFeedHub.onPersonalActivity(person)),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.activityFeedComponent.promiseToFetchLatestActivity();
        });
    }

    @Autobind
    public promiseToGetInitialActivity() {
        return lastValueFrom(this.person$.pipe(
            first(),
            switchMap((person) => this.activityFeedService.getInitialPersonalActivity(person)),
        ));
    }

    @Autobind
    public promiseToGetOlderActivity(olderThan: Date) {
        return lastValueFrom(this.person$.pipe(
            first(),
            switchMap((person) => this.activityFeedService.getOlderPersonalActivity(person, olderThan)),
        ));
    }

    @Autobind
    public promiseToGetNewerActivity(newerThan: Date) {
        return lastValueFrom(this.person$.pipe(
            first(),
            switchMap((person) => this.activityFeedService.getNewerPersonalActivity(person, newerThan)),
        ));
    }
}
