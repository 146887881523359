import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { AnalyseCareerValuationTimelinePageComponent } from "./analyse-cvt-timeline-page.component";

const AnalyseCareerValuationTimelinePageTitle = "Analyse Timeline";

export const analyseCareerValuationTimelinePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-career-valuation-timeline-page", AnalyseCareerValuationTimelinePageComponent)
    .atOrganisationUrl("/people/career-valuation/analyse-timeline")
    .redirectToThisRouteFromOrganisationUrl("/people/careerValuation/analyseTimline")
    .verifyingAccess(CareerValuationAuthService.ReadQuantitativeCareerValuation)
    .verifyingFeatures(FeatureName.CulturalNetworkCareerValuation)
    .withTitle(AnalyseCareerValuationTimelinePageTitle)
    .requiresLogin(true)
    .build();
