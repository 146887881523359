import { Component, OnInit } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { CulturalIndexConfiguration } from "@common/ADAPT.Common.Model/organisation/cultural-index-configuration";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { ISelectPersonFilter } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { CulturalLeadershipConnectionStatusFilter } from "app/features/culture/cultural-leadership/cultural-leadership-connection-status-filter.enum";
import { ICulturalLeadershipMeasurementAnalysisFilter } from "app/features/culture/cultural-leadership/cultural-leadership-measurement-analysis-filter.interface";
import { CulturalDataFilterParamKeys } from "../../cultural-data-filter";
import { CulturalIndexService } from "../cultural-index.service";
import { CulturalIndexAuthService } from "../cultural-index-auth.service";
import { CulturalIndexFilterService } from "./cultural-index-filter.service";

@Component({
    selector: "adapt-cultural-index-filter",
    templateUrl: "./cultural-index-filter.component.html",
    styleUrls: ["./cultural-index-filter.component.scss"],
})
export class CulturalIndexFilterComponent extends BaseComponent implements OnInit {
    public readonly allowedCLUserTypes = [UserType.Leader, UserType.Collaborator];
    public readonly CareerValuationFilterParamKeys = CulturalDataFilterParamKeys;

    public culturalIndexConfiguration?: CulturalIndexConfiguration;

    public culturalLeaderFilter?: ISelectPersonFilter;
    public filterParameters: ICulturalLeadershipMeasurementAnalysisFilter;

    public hasAtLeastQuantitativePermissions = this.culturalIndexAuthService.currentPersonHasAtLeastQuantitativeAccess();

    public connectionStatusOptions = [
        {
            label: "Inactive only",
            value: CulturalLeadershipConnectionStatusFilter.InactiveOnly,
        },
        {
            label: "Active only",
            value: CulturalLeadershipConnectionStatusFilter.ActiveOnly,
        },
        {
            label: "All",
            value: CulturalLeadershipConnectionStatusFilter.All,
        },
    ];

    public constructor(
        private culturalIndexService: CulturalIndexService,
        private culturalIndexAuthService: CulturalIndexAuthService,
        public culturalIndexFilterService: CulturalIndexFilterService,
    ) {
        super();

        this.filterParameters = this.culturalIndexFilterService.filter;

        this.culturalIndexFilterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
        });
    }

    public async ngOnInit() {
        this.culturalIndexConfiguration = await this.culturalIndexService.promiseToGetCulturalIndexConfiguration();
        await this.culturalIndexFilterService.setFilterFromUrl();
    }

    public async onConnectionStatusChanged(e: IDxSelectBoxValueChangedEvent<CulturalLeadershipConnectionStatusFilter>) {
        const value = e.value ?? this.filterParameters.connectionStatus;
        await this.culturalIndexFilterService.emitFilterChange("connectionStatus", value, value);
    }


}
