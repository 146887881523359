import { Component, Input, Optional } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { HideIfEmptyDirective } from "@common/ux/hide-if-empty/hide-if-empty.directive";
import { Observable, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";

interface IProcessMapLinkLocation {
    processMap: ProcessMap;
    parentProcessStep: ProcessStep | null;
}

@Component({
    selector: "adapt-list-process-map-links",
    templateUrl: "./list-process-map-links.component.html",
    styleUrls: ["./list-process-map-links.component.scss"],
})
export class ListProcessMapLinksComponent {
    @Input() public set processMap(value: ProcessMap | undefined) {
        if (value) {
            this.processMap$.next(value);
        }
    }

    public parentsOfLinks$: Observable<IProcessMapLinkLocation[]>;

    private processMap$ = new ReplaySubject<ProcessMap>(1);

    public constructor(
        processMapService: ProcessMapService,
        @Optional() hideIfEmpty?: HideIfEmptyDirective,
    ) {
        this.parentsOfLinks$ = this.processMap$.pipe(
            switchMap((processMap) => processMapService.getPrimedProcessMapLinks(processMap)),
            map((links) => links.map((l) => ({
                processMap: l.processMap!,
                parentProcessStep: l.parentStep,
            }))),
            tap((links) => hideIfEmpty?.setIsDisplayedFromArray(links)),
        );
    }
}
