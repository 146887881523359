import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { EditPersonAccessDialogComponent } from "@org-common/lib/user-management/edit-person-access-dialog/edit-person-access-dialog.component";
import { provideConfigureAccessLevelAction } from "@org-common/lib/user-management/link-access-level/link-access-level.component";
import { provideAddPeopleAction } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { AdaptUserManagementSharedModule } from "@org-common/lib/user-management/user-management-shared.module";
import { UserManagementCommonUiService } from "@org-common/lib/user-management/user-management-ui.service";
import { provideUserRoleActions } from "@org-common/lib/user-management/user-role-actions/user-role-actions.component";
import { AdaptIntegratedArchitectureModule } from "app/features/architecture/integrated-architecture/integrated-architecture.module";
import { AdaptRoleModule } from "app/features/architecture/role/role.module";
import { DxAccordionModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { AccessLevelActionsComponent } from "./access-level-actions/access-level-actions.component";
import { AddPeopleDialogComponent } from "./add-people-dialog/add-people-dialog.component";
import { ConfigureAccessLevelDialogComponent } from "./configure-access-level-dialog/configure-access-level-dialog.component";
import { ConfigureRoleAccessComponent } from "./configure-role-access/configure-role-access.component";
import { UserManagementUiService } from "./user-management-ui.service";

@NgModule({
    imports: [
        CommonModule,
        DxDataGridModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxAccordionModule,
        DxTextBoxModule,
        DxTabPanelModule,
        DxValidationGroupModule,
        DxValidatorModule,
        DxSelectBoxModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptIntegratedArchitectureModule,
        AdaptCommonDialogModule,
        AdaptRoleModule,
        AdaptUserManagementSharedModule,
        AdaptDateModule,
        AdaptEntityValidatorModule,
        AdaptMenuModule,
        AdaptDirectorySharedModule,
        AdaptLinkDisabledModule,
    ],
    exports: [
        AccessLevelActionsComponent,
    ],
    declarations: [
        AddPeopleDialogComponent,
        AccessLevelActionsComponent,
        ConfigureAccessLevelDialogComponent,
        ConfigureRoleAccessComponent,
    ],
    providers: [
        provideAddPeopleAction((injector, config) => {
            const commonDialogService = injector.get(AdaptCommonDialogService);
            return lastValueFrom(commonDialogService.open(AddPeopleDialogComponent, config), { defaultValue: undefined });
        }),
        provideConfigureAccessLevelAction(async (injector, role) => {
            const commonDialogService = injector.get(AdaptCommonDialogService);
            await lastValueFrom(commonDialogService.open(ConfigureAccessLevelDialogComponent, { role }), { defaultValue: undefined });
        }),
        provideUserRoleActions({
            viewAccess: async (injector, connection) => {
                const userManagementUiService = injector.get(UserManagementCommonUiService);
                await lastValueFrom(userManagementUiService.showUserAccessSummaryDialogForConnection(connection), { defaultValue: undefined });
            },
            configureAccess: async (injector, connection) => {
                const commonDialogService = injector.get(AdaptCommonDialogService);
                await lastValueFrom(commonDialogService.open(EditPersonAccessDialogComponent, connection), { defaultValue: undefined });
            },
            configureRoles: async (injector, connection) => {
                const userManagementUiService = injector.get(UserManagementUiService);
                await lastValueFrom(userManagementUiService.editPersonRoles(connection), { defaultValue: undefined });
            },
        }),
    ],
})
export class AdaptUserManagementModule { }
