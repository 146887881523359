import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import { lastValueFrom } from "rxjs";
import { IntegratedArchitectureFrameworkService } from "../../integrated-architecture/integrated-architecture-framework.service";

/**
 * Selects an organisation role (NOT an access level).
 */
@Component({
    selector: "adapt-select-role",
    templateUrl: "./select-role.component.html",
})
export class SelectRoleComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public role?: Role;
    @Input() public readonlyModeOnSelection = false;
    @Input() public required = true;
    @Input() public filter?: (role: Role) => boolean;

    @Output() public roleChange = new EventEmitter<Role>();

    public dataSource?: DataSource;

    private roles?: Role[];

    constructor(
        private integratedArchitectureFrameworkService: IntegratedArchitectureFrameworkService,
    ) {
        super();
    }

    public async ngOnInit() {
        const activeRoles = await lastValueFrom(this.integratedArchitectureFrameworkService.getActiveRolesByPredicate(undefined));
        this.roles = activeRoles
            .filter(DirectorySharedService.isNotTeamBasedRole)
            .filter((role) => role.extensions.isCulturalLeaderRole() || DirectorySharedService.isNotAccessLevelRole(role));
        this.updateDataSource();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.filter) {
            // re-create data source when filter is changed
            this.updateDataSource();
        }
    }

    public reload() {
        return this.dataSource?.reload();
    }

    private updateDataSource() {
        this.dataSource = new DataSource({
            store: new ArrayStore({ data: this.roles }),
            filter: this.filter,
            group: (role: Role) => role.connectionType
                ? `${ConnectionTypeLabel.singular(role.connectionType)} Roles`
                : undefined,
        } as DataSourceOptions<Role>);
    }
}
