import { Component, Input, OnChanges } from "@angular/core";
import { CatchupRatingValue } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";

@Component({
    selector: "adapt-display-rating-value",
    templateUrl: "./display-rating-value.component.html",
    styleUrls: ["../peer-catchup-styles.scss"],
})
export class DisplayRatingValueComponent implements OnChanges {
    @Input() public ratingValue!: CatchupRatingValue;
    @Input() public showText = true;

    public ratingClass = "";
    public ratingText = "Loading...";

    public ngOnChanges() {
        this.ratingClass = `catchup-rating-${this.ratingValue}`;
        switch (this.ratingValue) {
            case CatchupRatingValue.Green:
                this.ratingText = "No issues flagged";
                break;
            case CatchupRatingValue.Orange:
                this.ratingText = "Minor issues flagged";
                break;
            case CatchupRatingValue.Red:
                this.ratingText = "Major issues flagged";
                break;
            default:
                this.ratingText = "Loading...";
                break;
        }
    }
}
