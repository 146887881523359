<ng-container *ngIf="hasStepClickSubscribers && (processStep$ | async) as processStep; else showUrlLink">
    <div class="step-link"
         (click)="stepClick.emit(processStep)">
        <div *ngTemplateOutlet="linkContent"></div>
    </div>
</ng-container>
<ng-template #showUrlLink>
    <ng-container *ngIf="openOnNewPage; else showRouterLink">
        <a *ngIf="(stepHref$ | async) as stepHref"
           [href]="stepHref.url"
           target="_blank"
           class="step-link">
            <div *ngTemplateOutlet="linkContent"></div>
        </a>
    </ng-container>
    <ng-template #showRouterLink>
        <a *ngIf="(stepHref$ | async) as stepHref"
           [routerLink]="stepHref.path"
           [queryParams]="stepHref.queryParams"
           class="step-link">
            <div *ngTemplateOutlet="linkContent"></div>
        </a>
    </ng-template>
</ng-template>
<ng-template #linkContent>
    <span class="fa-stack me-1">
        <i class="fas fa-circle fa-stack-2x"
           [ngClass]="typeClass$ | async"></i>
        <i *ngIf="name$ | async; else spinnerTemplate"
           class="fal fa-stack-1x fa-inverse"
           [ngClass]="iconClass$ | async"
           aria-hidden="true"></i>
        <ng-template #spinnerTemplate>
            <i class="fal fa-stack-1x fa-spinner fa-spin fa-inverse"
               aria-hidden="true"></i>
        </ng-template>
    </span>
    <span class="step-name">{{name$ | async}}</span>
</ng-template>
