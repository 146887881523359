<ng-container *adaptLoadingUntilFirstEmit="let valueStreams of valueStreams$">
    "{{product!.name}}"
    <ng-container *ngIf="valueStreams.length > 0; else unused">
        is used in the following value streams:
        <div class="value-stream-list">
            <ul>
                <li *ngFor="let valueStream of valueStreams">
                    <adapt-link-value-stream [valueStream]="valueStream"></adapt-link-value-stream>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-template #unused>
        is not in use.
    </ng-template>
</ng-container>
