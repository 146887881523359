<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.RoleClarity"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-inactive [entity]="role"
                        type="role">
</adapt-toolbar-inactive>
<h2 adaptToolbarContent
    alignment="left"
    class="d-flex flex-row align-items-center"
    [ordinal]="1">{{role?.label}}</h2>
<adapt-display-labels *ngIf="role?.labelLocations?.length"
                      adaptToolbarContent
                      alignment="left"
                      [ordinal]="2"
                      class="ms-1"
                      [labelLocations]="role!.labelLocations"></adapt-display-labels>
<div adaptToolbarContent
     *ngIf="role">
    <button *ngIf="canEdit"
            [disabled]="lm?.isEditing"
            adaptButtonIcon="edit"
            (click)="editRole()"
            data-test="edit-role-button">{{roleActions.ConfigureRole}}</button>
    <button *ngIf="canDelete"
            [disabled]="lm?.isEditing"
            adaptButtonIcon="delete"
            [adaptBlockingClick]="deleteRole"
            [unsubscribeOnDestroy]="false"
            data-test="delete-role-button">{{roleActions.DeleteRole}}</button>
    <button *ngIf="canDeactivate"
            [disabled]="lm?.isEditing"
            adaptButtonIcon="fal fa-archive"
            [adaptBlockingClick]="deactivateRole">{{roleActions.DeactivateRole}}</button>
    <button *ngIf="canReactivate"
            [disabled]="lm?.isEditing"
            adaptButtonIcon="cancel"
            [adaptBlockingClick]="reactivateRole">{{roleActions.ReactivateRole}}</button>
</div>

<ng-container *ngIf="role">
    <adapt-layout-manager innerClass="h-100 m-0"
                          [fullPage]="true"
                          [allowEditing]="canEdit"
                          [entity]="role"
                          *adaptRerender="layoutRefresh; delay: 250">
        <adapt-layout-column class="col-md-8 bg-white">
            <adapt-layout-component id="role-key-accountabilities"
                                    class="mb-4">
                <div class="role-content">
                    <h2>Key Accountabilities
                        <sup class="fal fa-info-circle"
                             [adaptTooltip]="guidance.keyAccountabilities"></sup>
                    </h2>
                    <div [froalaView]="role.supplementaryData?.keyAccountabilities || '<p>No key accountabilities have been defined.</p>'">
                    </div>
                </div>
            </adapt-layout-component>

            <adapt-layout-component id="role-capabilities"
                                    class="mb-4">
                <div *adaptLayoutComponentEnabled="role.supplementaryData?.capabilities">
                    <h2>Skills
                        <sup class="fal fa-info-circle"
                             [adaptTooltip]="guidance.capabilities"></sup>
                    </h2>

                    <div [froalaView]="role!.supplementaryData?.capabilities"></div>
                </div>
            </adapt-layout-component>

            <adapt-layout-component id="role-attributes"
                                    class="mb-4">
                <div *adaptLayoutComponentEnabled="role.supplementaryData?.attributes">
                    <h2>Attributes
                        <sup class="fal fa-info-circle"
                             [adaptTooltip]="guidance.attributes"></sup>
                    </h2>

                    <div [froalaView]="role!.supplementaryData?.attributes"></div>
                </div>
            </adapt-layout-component>

            <adapt-layout-component id="role-relationships"
                                    class="mb-4">
                <div *adaptLayoutComponentEnabled="role.supplementaryData?.relationships">
                    <h2>Primary relationships
                        <sup class="fal fa-info-circle"
                             [adaptTooltip]="guidance.relationships"></sup>
                    </h2>
                    <div [froalaView]="role!.supplementaryData?.relationships"></div>
                </div>
            </adapt-layout-component>

            <adapt-layout-component *adaptHideIfEmpty
                                    id="role-systems"
                                    class="mb-4">
                <adapt-display-systems [role]="role"
                                       [fullPageCTA]="false"
                                       [hideCTA]="true"
                                       (initialised)="onSystemsInitialised()"></adapt-display-systems>
            </adapt-layout-component>
        </adapt-layout-column>
        <adapt-layout-column class="col-md-4">
            <adapt-layout-component id="role-people"
                                    class="mb-4">
                <div>
                    <adapt-role-people-panel [role]="role"></adapt-role-people-panel>
                </div>
            </adapt-layout-component>

            <adapt-layout-component id="role-related-process-maps"
                                    class="mb-4">
                <div>
                    <h2>Related Systems &amp; Assigned Process Steps</h2>
                    <adapt-related-process-maps [role]="$any(role)"></adapt-related-process-maps>
                </div>
            </adapt-layout-component>
        </adapt-layout-column>
    </adapt-layout-manager>
</ng-container>
