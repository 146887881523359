import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ServiceUri } from "@common/configuration/service-uri";
import { Logger } from "@common/lib/logger/logger";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { throwError } from "rxjs";
import { catchError, filter, first, map, mergeAll, mergeMap, switchMap, toArray } from "rxjs/operators";
import { ActivityFeedFetchError } from "./activity-feed-fetch-error";
import { ActivityInstance } from "./activity-feed-manager/activity-instance";
import { IActivityItem } from "./activity-item.interface";

interface IActivityQueryParameters {
    newerThan?: Date;
    olderThan?: Date;
}

interface IOrganisationActivityQueryParameters extends IActivityQueryParameters {
    organisationId: number;
}

interface ITeamActivityQueryParameters extends IActivityQueryParameters {
    teamId: number;
}

interface IPersonalActivityQueryParameters extends IActivityQueryParameters {
    organisationId: number;
    personId: number;
}

type ActivityQueryParameters = IOrganisationActivityQueryParameters | ITeamActivityQueryParameters | IPersonalActivityQueryParameters;

@Injectable({
    providedIn: "root",
})
export class ActivityFeedService {
    private log = Logger.getLogger("ActivityFeedService");

    public constructor(
        private httpClient: HttpClient,
        private organisationService: OrganisationService,
        private directorySharedService: DirectorySharedService,
    ) {
    }

    public getInitialOrganisationActivity() {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/InitialOrganisationActivity`;
        return this.getOrganisationActivity(endpoint, {});
    }

    public getOlderOrganisationActivity(olderThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/OlderOrganisationActivity`;
        return this.getOrganisationActivity(endpoint, {
            olderThan,
        });
    }

    public getNewerOrganisationActivity(newerThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/NewerOrganisationActivity`;
        return this.getOrganisationActivity(endpoint, {
            newerThan,
        });
    }

    public getInitialTeamActivity(team: Team) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/InitialTeamActivity`;
        return this.getActivity(endpoint, {
            teamId: team.teamId,
        });
    }

    public getOlderTeamActivity(team: Team, olderThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/OlderTeamActivity`;
        return this.getActivity(endpoint, {
            teamId: team.teamId,
            olderThan,
        });
    }

    public getNewerTeamActivity(team: Team, newerThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/NewerTeamActivity`;
        return this.getActivity(endpoint, {
            teamId: team.teamId,
            newerThan,
        });
    }

    public getInitialPersonalActivity(person: Person) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/InitialPersonalActivity`;
        return this.getPersonalActivity(endpoint, person);
    }

    public getOlderPersonalActivity(person: Person, olderThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/OlderPersonalActivity`;
        return this.getPersonalActivity(endpoint, person, {
            olderThan,
        });
    }

    public getNewerPersonalActivity(person: Person, newerThan: Date) {
        const endpoint = `${ServiceUri.ActivityFeedServiceBaseUri}/NewerPersonalActivity`;
        return this.getPersonalActivity(endpoint, person, {
            newerThan,
        });
    }

    private getPersonalActivity(endpoint: string, person: Person, queryParameters?: IActivityQueryParameters) {
        return this.organisationService.organisation$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Organisation)),
            first(),
            map((organisation) => ({
                ...queryParameters,
                organisationId: organisation.organisationId,
                personId: person.personId,
            })),
            switchMap((params) => this.getActivity(endpoint, params)),
        );
    }

    private getOrganisationActivity(endpoint: string, queryParameters?: IActivityQueryParameters) {
        return this.organisationService.organisation$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Organisation)),
            first(),
            map((organisation) => ({
                ...queryParameters,
                organisationId: organisation.organisationId,
            })),
            switchMap((params) => this.getActivity(endpoint, params)),
        );
    }

    private getActivity(endpoint: string, queryParameters: ActivityQueryParameters) {
        const params = this.generateHttpParams(queryParameters);
        return this.httpClient.get<IActivityItem[]>(endpoint, { params }).pipe(
            mergeAll(),
            mergeMap(async (i) => {
                const person = i.relatedPersonId
                    ? await this.directorySharedService.promiseToGetPersonById(i.relatedPersonId)
                    : undefined;
                return new ActivityInstance(i, person);
            }),
            toArray(),
            catchError((e: HttpErrorResponse) => {
                const wrappedError = new ActivityFeedFetchError(e);
                this.log.error("Unable to fetch activity", wrappedError);
                return throwError(() => wrappedError);
            }),
        );
    }

    private generateHttpParams<T extends object>(queryParameters: T) {
        // HttpParams is immutable
        let params = new HttpParams();

        for (const key of Object.keys(queryParameters) as (keyof T)[]) {
            const value = queryParameters[key];
            if (value) {
                const queryValue = value instanceof Date
                    ? value.toISOString()
                    : String(value);
                params = params.set(String(key), queryValue);
            }
        }

        return params;
    }
}
