import { Component, Input } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SurveyService } from "../../survey.service";
import { ISurveyQuestions } from "../../survey-questions.interface";
import { SurveyUtils } from "../../survey-utils";

@Component({
    selector: "adapt-team-assessment-dashboard-element",
    templateUrl: "./team-assessment-dashboard-element.component.html",
})
export class TeamAssessmentDashboardElementComponent extends BaseComponent {
    @Input() public set team(value: Team) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);

    public url$: Observable<string>;
    public latestSurvey$: Observable<Survey | undefined>;

    public surveyQuestions?: ISurveyQuestions;

    public constructor(
        private surveyService: SurveyService,
    ) {
        super();

        this.url$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => SurveyUtils.forSurveyType(SurveyType.TeamAssessment).getManageSurveyPageRoute$(team.teamId)),
        );

        this.latestSurvey$ = this.team$.pipe(
            switchMap((team) => team
                ? this.surveyService.getMostRecentEndedSurveys(1, SurveyType.TeamAssessment, team)
                : EMPTY),
            map((surveys) => {
                if (surveys.length > 0) {
                    const latestSurvey = surveys[0];
                    this.surveyQuestions = this.surveyService.getQuestionsForSurvey(latestSurvey);
                    return latestSurvey;
                }

                return undefined;
            }),
        );
    }
}
