import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import moment from "moment";
import { ActivityElement } from "./activity-element";
import { ActivityInstance } from "./activity-instance";

export class ActivityGroup extends ActivityElement {
    public static readonly groupingTimeThresholdSec = 60;
    public static readonly groupingCountThreshold = 3;

    public constructor(public dateTime: Date) {
        super();
    }

    public get relatedPerson() {
        // Groups should be all of the same person, so just get the first activity
        return this.children.length > 0
            ? this.children[0].relatedPerson
            : undefined;
    }

    public get oldestActivityDate() {
        const oldestActivity = this.locateDescendentActivity((activity) => activity[activity.length - 1]);
        return oldestActivity.dateTime;
    }

    public get newestActivityDate() {
        const newestActivity = this.locateDescendentActivity((activity) => activity[0]);
        return newestActivity.dateTime;
    }

    public addChild(child: ActivityInstance) {
        // If we are not the root group, then don't allow more layers of grouping
        if (this.depth > 0) {
            this.insertIntoChildren(child);
            return;
        }

        const activityGroup = this.findExistingApplicableGroup(child);
        if (activityGroup) {
            if (child.dateTime > activityGroup.dateTime) {
                activityGroup.dateTime = child.dateTime;
            }

            activityGroup.addChild(child);

            const envelopedSiblings = this.findMatchingGroupableChildren(activityGroup);
            for (const envelopedSibling of envelopedSiblings) {
                ArrayUtilities.removeElementFromArray(envelopedSibling, this.children);
                activityGroup.insertAndFlatten(envelopedSibling);
            }
            return;
        }

        const groupableInstances = this.findMatchingGroupableChildren(child);
        const childToInsert = groupableInstances.length >= ActivityGroup.groupingCountThreshold
            ? this.createNewGroup(child, groupableInstances)
            : child;
        this.insertIntoChildren(childToInsert);
    }

    private createNewGroup(child: ActivityInstance, groupableInstances: ActivityElement[]) {
        ArrayUtilities.insertIntoReverseChronologicalArray(child, groupableInstances, (i) => i.dateTime);

        const latestInstance = groupableInstances[0];
        const newGroup = new ActivityGroup(latestInstance.dateTime);

        for (const instance of groupableInstances) {
            ArrayUtilities.removeElementFromArray(instance, this.children);
            newGroup.insertIntoChildren(instance);
        }

        return newGroup;
    }

    private insertAndFlatten(child: ActivityElement) {
        if (child instanceof ActivityGroup) {
            child.children.forEach((c) => this.insertAndFlatten(c));
            child.children.splice(0, child.children.length);
        } else {
            this.insertIntoChildren(child);
        }
    }

    private insertIntoChildren(child: ActivityElement) {
        ArrayUtilities.insertIntoReverseChronologicalArray(child, this.children, (i) => i.dateTime);
        child.parent = this;

        if (child.dateTime > this.dateTime) {
            this.dateTime = child.dateTime;
        }
    }

    private findExistingApplicableGroup(activity: ActivityInstance): ActivityGroup | undefined {
        const activityMoment = moment(activity.dateTime);
        const thresholdSec = ActivityGroup.groupingTimeThresholdSec;

        return this.children.filter((c): c is ActivityGroup => c instanceof ActivityGroup)
            .filter((g) => g.relatedPersonId === activity.relatedPersonId)
            .find((g) => {
                const groupOlderThreshold = moment(g.oldestActivityDate).subtract(thresholdSec, "seconds");
                const groupNewerThreshold = moment(g.newestActivityDate).add(thresholdSec, "seconds");
                return activityMoment.isBetween(groupOlderThreshold, groupNewerThreshold, "seconds", "[]");
            });
    }

    /** Returns an reverse chronologically sorted array of the child instances of this group
     * that are candidates for grouping according to the time thresholds
     */
    private findMatchingGroupableChildren(activity: ActivityElement): ActivityElement[] {
        const oldestThreshold = moment(activity.oldestActivityDate).subtract(ActivityGroup.groupingTimeThresholdSec, "seconds");
        const youngestThreshold = moment(activity.newestActivityDate).add(ActivityGroup.groupingTimeThresholdSec, "seconds");

        const matchingActivity = this.children
            .filter((a) => a !== activity)
            .filter((a) => a.relatedPersonId === activity.relatedPersonId);

        const closestSibling = matchingActivity.find((c) => {
            return moment(c.newestActivityDate).isBetween(oldestThreshold, youngestThreshold, "second", "[]")
                || moment(c.oldestActivityDate).isBetween(oldestThreshold, youngestThreshold, "second", "[]");
        });
        if (!closestSibling) {
            return [];
        }

        const siblings = [closestSibling];
        const closestSiblingIndex = matchingActivity.indexOf(closestSibling);

        for (let i = closestSiblingIndex + 1; i < matchingActivity.length; i++) {
            const olderSibling = siblings[siblings.length - 1];
            const olderThreshold = moment(olderSibling.oldestActivityDate)
                .subtract(ActivityGroup.groupingTimeThresholdSec, "seconds");

            const nextSibling = matchingActivity[i];
            if (moment(nextSibling.newestActivityDate).isBefore(olderThreshold)) {
                break;
            }

            siblings.push(nextSibling);
        }

        for (let i = closestSiblingIndex - 1; i >= 0; i--) {
            const youngerSibling = siblings[0];
            const youngerThreshold = moment(youngerSibling.newestActivityDate)
                .add(ActivityGroup.groupingTimeThresholdSec, "seconds");

            const nextSibling = matchingActivity[i];
            if (moment(nextSibling.oldestActivityDate).isAfter(youngerThreshold)) {
                break;
            }

            siblings.unshift(nextSibling);
        }

        return siblings;
    }
}
