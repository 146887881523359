<div *adaptLoading="isLoading">
    <dx-polar-chart *ngIf="data.length"
                    [pathModified]="true"
                    [dataSource]="data"
                    [title]="title"
                    [customizePoint]="culturalIndexUiService.customiseChartPoint"
                    (onInitialized)="onInitialized($event)"
                    (onPointClick)="onPointClick($event)">
        <dxo-common-series-settings type="scatter">
        </dxo-common-series-settings>
        <dxo-legend [visible]="false">
        </dxo-legend>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseTooltip">
        </dxo-tooltip>
        <dxo-loading-indicator>
            <dxo-font [size]="30">
            </dxo-font>
        </dxo-loading-indicator>
        <dxo-argument-axis type="discrete">
            <dxo-label [visible]="false">
            </dxo-label>
            <dxo-minor-tick [visible]="false">
            </dxo-minor-tick>
            <dxo-tick [visible]="false">
            </dxo-tick>
            <dxo-grid [visible]="false">
            </dxo-grid>
            <dxo-minor-grid [visible]="false">
            </dxo-minor-grid>
        </dxo-argument-axis>
        <dxo-value-axis [maxValueMargin]="0.001"
                        visualRangeUpdateMode="keep"
                        [tickInterval]="0.5"
                        [visualRange]="[0, 2]">
            <dxo-minor-tick [visible]="false">
            </dxo-minor-tick>
            <dxo-tick [visible]="false">
            </dxo-tick>
            <dxo-minor-grid [visible]="false">
            </dxo-minor-grid>
            <dxo-label [visible]="false">
            </dxo-label>
        </dxo-value-axis>
        <dxi-series valueField="value"
                    argumentField="index"
                    tagField="culturalIndex">
        </dxi-series>
        <dxi-series valueField="max"
                    argumentField="index">
            <dxo-point [visible]="false">
            </dxo-point>
        </dxi-series>
        <dxi-series valueField="min"
                    argumentField="index">
            <dxo-point [visible]="false">
            </dxo-point>
        </dxi-series>
        <dxo-margin [bottom]="marginBottom"
                    [left]="0">
        </dxo-margin>
        <dxo-size [height]="height">
        </dxo-size>
    </dx-polar-chart>

    <div *ngIf="!data.length">
        <adapt-no-cultural-measurement label="Cultural Indexes"></adapt-no-cultural-measurement>
    </div>
</div>
