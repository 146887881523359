import { Component, OnInit } from "@angular/core";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { BullseyePageRoute } from "@org-common/lib/bullseye/bullseye-page/bullseye-page.component";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-bullseye-dashboard-element",
    templateUrl: "./bullseye-dashboard-element.component.html",
    styleUrls: ["./bullseye-dashboard-element.component.scss"],
})
export class BullseyeDashboardElementComponent implements OnInit {
    public bullseye?: Bullseye;
    public url$: Observable<string>;

    constructor(
        private bullseyeService: BullseyeService,
    ) {
        this.url$ = BullseyePageRoute.getRoute();
    }

    public async ngOnInit() {
        this.bullseyeService.getBullseye().subscribe((bullseye) => {
            this.bullseye = bullseye;
        });
    }
}
