<ng-container *adaptLoadingUntilFirstEmit="let valueStreams of valueStreams$">
    <ng-container *ngIf="valueStreams && valueStreams.length > 0; else noValueStreams">
        <adapt-display-value-stream-summary-dashboard-element *ngFor="let valueStream of valueStreams"
                                                              [valueStream]="valueStream">
        </adapt-display-value-stream-summary-dashboard-element>
    </ng-container>
    <ng-template #noValueStreams>
        <adapt-call-to-action-inline [featureUrl]="organisationMapPageHref$ | async">
            No value streams have been defined for this organisation.
        </adapt-call-to-action-inline>
    </ng-template>
</ng-container>
