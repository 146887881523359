<div *adaptLoading="!rating1 || !rating2 || !catchup || !ratingType">
    <!-- We could do the switching of the rating order (so that person 1 is first all the time)
            using CSS/Flex, however this would mean the tabbing order would not be consistent (since the
            browser follows the HTML source order).-->
    <div [ngSwitch]="rating1!.authorPersonId === catchup!.person1Id">
        <h2 *ngIf="showTitle">Measure <span>{{ratingType!.name.toLowerCase()}}</span></h2>

        <adapt-edit-catchup-rating *ngSwitchCase="true"
                                   (ratingChange)="ratingChangeUpdater.next($event)"
                                   [isEditing]="isEditing"
                                   [rating]="rating1"></adapt-edit-catchup-rating>
        <adapt-edit-catchup-rating (ratingChange)="ratingChangeUpdater.next($event)"
                                   [isEditing]="isEditing"
                                   [rating]="rating2"></adapt-edit-catchup-rating>
        <adapt-edit-catchup-rating *ngSwitchDefault
                                   (ratingChange)="ratingChangeUpdater.next($event)"
                                   [isEditing]="isEditing"
                                   [rating]="rating1"></adapt-edit-catchup-rating>
    </div>
</div>
