import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepType, ProcessStepTypeMetadata } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { cacheLatest, emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SystemPageComponent } from "app/features/systemisation/system-page/system-page.component";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { EMPTY, Observable, of, ReplaySubject } from "rxjs";
import { filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";
import { IMapStackFramesChangedEvent } from "../process-steps-card/process-steps-card.component";

const typeClass: { [type in ProcessStepType]: string } = {
    [ProcessStepType.ProcessStep]: "step-type-process-step",
    [ProcessStepType.RoleTask]: "step-type-role-task",
    [ProcessStepType.ProcessMapLink]: "step-type-process-map-link",
};

@Component({
    selector: "adapt-link-process-step",
    templateUrl: "./link-process-step.component.html",
    styleUrls: ["./link-process-step.component.scss"],
})
export class LinkProcessStepComponent extends BaseComponent {
    @Input()
    public set processStep(value: ProcessStep | undefined) {
        if (value) {
            this.processStep$.next(value);
        }
    }

    @Input()
    @HostBinding("class.align-text-to-right-of-icon")
    public alignTextToRightOfIcon = false;

    @Output() public stepClick = new EventEmitter<ProcessStep>();

    public processStep$ = new ReplaySubject<ProcessStep>(1);
    public stepHref$: Observable<IAdaptLinkObject>;
    public typeClass$: Observable<string>;
    public iconClass$: Observable<string>;
    public name$: Observable<string>;

    @Input() public openOnNewPage = false;

    public constructor(
        private processMapService: ProcessMapService,
        private systemisationService: SystemisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.stepHref$ = this.processStep$.pipe(
            switchMap((processStep) => {
                const processMapId = processStep.linkedProcessMapId ? processStep.linkedProcessMapId : processStep.processMapId;
                const step = processStep.linkedProcessMapId ? processStep.linkedProcessMap! : processStep;
                return this.systemisationService.getSystemComponentForProcessMap(processMapId).pipe(
                    map((systemComponent) => ({ systemComponent, step })),
                );
            }),
            switchMap(({ systemComponent, step }) => systemPageRoute.getRouteObject(
                { systemEntityId: systemComponent.systemEntityId },
                { ProcessMapStates: this.formProcessMapStatesValue(step) })),
        );
        this.name$ = this.processStep$.pipe(
            switchMap((processStep) => processStep.type === ProcessStepType.ProcessMapLink
                ? this.getProcessMapName(processStep.linkedProcessMapId)
                : of(processStep.name)),
            cacheLatest(),
        );

        const type$ = this.processStep$.pipe(
            map((i) => i.type),
        );
        this.typeClass$ = type$.pipe(
            map((i) => typeClass[i]),
        );
        this.iconClass$ = type$.pipe(
            map((i) => ProcessStepTypeMetadata.IconClass[i]),
        );

        rxjsBreezeService.entityTypeChanged(ProcessStep).pipe(
            withLatestFrom(this.processStep$),
            filter(([step, thisStep]) => step.processStepId === thisStep.processStepId),
            map(([step]) => step),
            this.takeUntilDestroyed(),
        ).subscribe(this.processStep$);
    }

    public get hasStepClickSubscribers() {
        return this.stepClick.observers.length > 0;
    }

    private getProcessMapName(processMapId: number | null) {
        if (!processMapId) {
            return EMPTY;
        }

        return this.processMapService.getProcessMap(processMapId).pipe(
            emptyIfUndefinedOrNull(),
            map((e) => e.name),
        );
    }

    private formProcessMapStatesValue(step: ProcessMap | ProcessStep) {
        if (step instanceof ProcessMap) {
            return undefined;
        } else {
            const result: IMapStackFramesChangedEvent = {
                rootProcessMapId: step.processMapId,
                frames: [],
            };
            let i = step.parentStep;
            while (i) {
                result.frames.splice(0, 0, { processStepId: i.processStepId });
                i = i.parentStep;
            }

            result.frames.splice(0, 0, { processMapId: step.processMapId });
            result.frames.push({ processStepId: step.processStepId });

            return SystemPageComponent.encodeProcessMapStates([result]);
        }
    }
}
