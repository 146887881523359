<div class="header">
    <h2>People</h2>

    <div class="buttons"
         *ngIf="uniqueInactiveConnections.size > 0">
        <button [adaptButtonIcon]="showInactiveConnections ? 'fal fa-eye-slash' : 'fal fa-eye'"
                (click)="showInactiveConnections = !showInactiveConnections">
            {{showInactiveConnections ? 'Hide' : 'Show'}} previous people</button>
    </div>
</div>

<div class="role-people-panel">
    <p *ngIf="uniqueActiveConnections.size === 0 && !showInactiveConnections">
        No people are currently allocated to this role.
    </p>
    <div class="role-people">
        <adapt-link-person *ngFor="let connection of uniqueActiveConnections"
                           [person]="connection.person"
                           [showImage]="true"
                           class="role-people-position"></adapt-link-person>
    </div>

    <h3 class="mt-3"
        *ngIf="showInactiveConnections">Previous People</h3>
    <div class="role-people"
         *ngIf="showInactiveConnections">
        <adapt-link-person *ngFor="let connection of uniqueInactiveConnections"
                           [person]="connection.person"
                           [showImage]="true"
                           class="role-people-position"></adapt-link-person>
    </div>
</div>
