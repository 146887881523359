import { Component, Inject, OnInit } from "@angular/core";
import { Stakeholder } from "@common/ADAPT.Common.Model/organisation/stakeholder";
import { StakeholderStory } from "@common/ADAPT.Common.Model/organisation/stakeholder-story";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ValueStreamStakeholder } from "@common/ADAPT.Common.Model/organisation/value-stream-stakeholder";
import { ValueStreamStakeholderStory, ValueStreamStakeholderStoryBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream-stakeholder-story";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent, IDxListItemDeletedEvent, IDxListItemDeletingEvent, IDxListItemReorderedEvent, IDxListSelectionChangedEvent, IDxSelectBoxSelectionChangedEvent } from "@common/ux/dx.types";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleEntity, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { InitializedEventInfo } from "devextreme/events";
import dxList, { InitializedEvent } from "devextreme/ui/list";
import dxSelectBox from "devextreme/ui/select_box";
import { lastValueFrom } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Tier1ArchitectureService } from "../tier1-architecture.service";

@Component({
    selector: "adapt-edit-value-stream-stakeholders-dialog",
    templateUrl: "./edit-value-stream-stakeholders-dialog.component.html",
})
export class EditValueStreamStakeholdersDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ValueStream, ValueStreamStakeholder[]> implements OnInit {
    public readonly dialogName = "EditValueStreamStakeholdersDialog";

    public selectedStakeholder?: ValueStreamStakeholder;
    private selectedStakeholderUpdater = this.createThrottledUpdater<ValueStreamStakeholder>((stakeholder) => {
        this.selectedStakeholder = stakeholder;
        this.updateStakeholderStories();
    });

    public valueStreamStakeholderStories: ValueStreamStakeholderStory[] = [];

    public stakeholderList?: dxList;
    public storiesList?: dxList;
    public stakeholderSelectBox?: dxSelectBox;

    public dataSource?: DataSource;

    public stakeholderStories?: StakeholderStory[];

    public stakeholderStorySelectBox?: dxSelectBox;
    private stakeholders: Stakeholder[] = [];
    private removedValueStreamStakeholders: ValueStreamStakeholder[] = [];
    private removedValueStreamStakeholderStories: ValueStreamStakeholderStory[] = [];

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public valueStream: ValueStream,
        protected commonDialogService: AdaptCommonDialogService,
        private tier1ArchitectureService: Tier1ArchitectureService,
    ) {
        super();

        this.valueStream.stakeholders.sort(SortUtilities.getSortByFieldFunction<ValueStreamStakeholder>((i) => i.ordinal));
    }

    public get entitiesToConfirm() {
        const stakeholderStories = this.valueStreamStakeholderStories.map((i) => i.stakeholderStory);
        return [...this.valueStream.stakeholders, ...this.valueStreamStakeholderStories, ...stakeholderStories, ...this.removedValueStreamStakeholders, ...this.removedValueStreamStakeholderStories];
    }

    public async ngOnInit() {
        this.stakeholders = await lastValueFrom(this.tier1ArchitectureService.getAllStakeholders());

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.stakeholders,
                key: "stakeholderId",
            }),
            filter: (i: Stakeholder) => this.stakeholderIsNotAlreadySelected(i),
        });
    }

    public onStakeholderSelectionChanged(e: IDxListSelectionChangedEvent<ValueStreamStakeholder>) {
        const item = e.addedItems[0];
        this.updateSelectedStakeholder(item);
    }

    private updateSelectedStakeholder(item: ValueStreamStakeholder) {
        if (item) {
            // copy array so that deleting items does not remove breeze references
            this.valueStreamStakeholderStories = item.stakeholderStories;
            this.valueStreamStakeholderStories.sort(SortUtilities.getSortByFieldFunction(ValueStreamStakeholderStoryBreezeModel.sortField!));
        } else {
            this.valueStreamStakeholderStories = [];
        }

        this.selectedStakeholderUpdater.next(item);
    }

    public onStakeholderStorySelectInitialised(e: InitializedEventInfo<dxSelectBox>) {
        this.stakeholderStorySelectBox = e.component;
    }

    /* Button Methods */
    @Autobind
    public newStakeholder() {
        return this.tier1ArchitectureService.createStakeholder().pipe(
            switchMap((stakeholder: Stakeholder) => this.commonDialogService.open(EditSimpleValueBreezeEntityDialogComponent, this.createEditStakeholderDialogData(stakeholder)).pipe(
                map(() => stakeholder),
            )),
            tap((stakeholder) => this.stakeholders.push(stakeholder)),
            switchMap((stakeholder) => this.addStakeholder(stakeholder)),
            this.takeUntilDestroyed(),
        );
    }

    private createEditStakeholderDialogData(stakeholder: Stakeholder) {
        const nameField: IEditSimpleEntity = {
            label: "Name",
            entity: stakeholder,
            fieldName: "name",
            type: SimpleValueType.Text,
            maxLength: 255,
            usePropertyValidator: true,
            customValidators: [{
                validator: ({ value }: IDxCustomRuleValidationCallbackEvent) => {
                    if (typeof value === "string") {
                        return !this.stakeholders.find((i) => i.name.trim().toLowerCase() === value.trim().toLowerCase());
                    }
                    return false;
                },
                message: "A customer role with that name already exists",
            }],
        };

        const descriptionField: IEditSimpleEntity = {
            label: "Description",
            entity: stakeholder,
            fieldName: "description",
            type: SimpleValueType.Text,
            maxLength: 255,
        };

        const entities = [nameField, descriptionField];

        const data: IEditSimpleValueBreezeEntityDialogData = {
            title: "Add Customer Role",
            entities,
            saveOnClose: true,
        };

        return data;
    }

    @Autobind
    public async addStakeholderStory() {
        if (this.selectedStakeholder) {
            let newValueStreamStakeholderStory: ValueStreamStakeholderStory | undefined;
            this.tier1ArchitectureService.createValueStreamStakeholderStoryForValueStreamStakeholder(this.selectedStakeholder)
                .pipe(
                    tap((valueStreamStakeholderStory) => newValueStreamStakeholderStory = valueStreamStakeholderStory),
                    switchMap((valueStreamStakeholderStory) => this.tier1ArchitectureService.createStakeholderStoryForStakeholder(valueStreamStakeholderStory.valueStreamStakeholder)),
                    switchMap((stakeholderStory) => {
                        newValueStreamStakeholderStory!.stakeholderStory = stakeholderStory;
                        const dialogData = this.createEditStakeholderStoryDialogData(stakeholderStory);
                        return this.commonDialogService.open(EditSimpleValueBreezeEntityDialogComponent, dialogData);
                    }),
                    this.takeUntilDestroyed(),
                ).subscribe({
                    next: () => {
                        this.valueStreamStakeholderStories.push(newValueStreamStakeholderStory!);
                        newValueStreamStakeholderStory = undefined;
                    },
                    complete: () => {
                        if (newValueStreamStakeholderStory) {
                            // add story has been discarded - cleanup
                            this.tier1ArchitectureService.remove(newValueStreamStakeholderStory).subscribe();
                        }
                    },
                });
        }
    }

    private createEditStakeholderStoryDialogData(stakeholderStory: StakeholderStory) {
        const nameField: IEditSimpleEntity = {
            label: "I want to",
            entity: stakeholderStory,
            fieldName: "want",
            type: SimpleValueType.TextArea,
            usePropertyValidator: true,
        };


        const descriptionField: IEditSimpleEntity = {
            label: "so that",
            entity: stakeholderStory,
            fieldName: "reason",
            type: SimpleValueType.TextArea,
            usePropertyValidator: true,
        };


        const entities = [nameField, descriptionField];

        const data: IEditSimpleValueBreezeEntityDialogData = {
            title: "Add Customer Story",
            entities,
            saveOnClose: true,
        };

        return data;
    }

    public async ok() {
        const promises = [];
        for (const valueStreamStakeholder of this.valueStream.stakeholders) {
            if (!valueStreamStakeholder.stakeholder) {
                promises.push(lastValueFrom(this.tier1ArchitectureService.remove(valueStreamStakeholder)));
            }
        }
        await Promise.all(promises);

        await lastValueFrom(this.tier1ArchitectureService.save());

        this.valueStream.stakeholders.sort(SortUtilities.getSortByFieldFunction<ValueStreamStakeholder>((i) => i.ordinal));
        for (const vsStakeholder of this.valueStream.stakeholders as ValueStreamStakeholder[]) {
            vsStakeholder.stakeholderStories.sort(SortUtilities.getSortByFieldFunction<ValueStreamStakeholderStory>((i) => i.ordinal));
        }

        super.resolve(this.valueStream.stakeholders);
    }

    /* Stakeholder List Methods */
    public onStakeholderItemReordered(e: IDxListItemReorderedEvent<ValueStreamStakeholder>) {
        const valueStreamStakeholders = this.valueStream.stakeholders;
        SortUtilities.reorderItemInIntegerSortedArray(valueStreamStakeholders, "ordinal", e.fromIndex, e.toIndex);
    }

    public async onStakeholderItemDeleting(e: IDxListItemDeletingEvent<ValueStreamStakeholder>) {
        if (!e.itemData) {
            return;
        }

        if (!e.itemData.stakeholder) {
            e.cancel = true;
            return;
        }

        const itemName = e.itemData.stakeholder.name;
        const promise = lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean({
            title: "Remove customer role from value stream",
            message: "Are you sure you wish to remove " + itemName + ", including all related stories, from this value stream?",
        }));
        e.cancel = DxUtilities.isCancelledFromPromise(promise);
    }

    public async onStakeholderItemDeleted(e: IDxListItemDeletedEvent<ValueStreamStakeholder>) {
        if (!e.itemData) {
            return;
        }

        e.itemData.valueStream = this.valueStream;
        const removeEntities = await lastValueFrom(this.tier1ArchitectureService.removeValueStreamStakeholder(e.itemData));
        removeEntities.forEach((entity) => {
            if (entity instanceof ValueStreamStakeholder) {
                this.removedValueStreamStakeholders.push(entity);
            } else if (entity instanceof ValueStreamStakeholderStory) {
                this.removedValueStreamStakeholderStories.push(entity);
            }
        });
        this.selectFirstStakeholder();
        this.dataSource?.reload();
    }

    public onStakeholderListInitialised(e: InitializedEvent) {
        this.stakeholderList = e.component;
        this.selectFirstStakeholder();
    }

    /* Story List Methods */
    public onStoriesListInitialised(e: InitializedEvent) {
        this.storiesList = e.component;
    }

    public onStakeholderStoryItemReordered(e: IDxListItemReorderedEvent<ValueStreamStakeholderStory>) {
        const valueStreamStakeholderStories = this.valueStreamStakeholderStories;
        SortUtilities.reorderItemInIntegerSortedArray(valueStreamStakeholderStories, "ordinal", e.fromIndex, e.toIndex);
    }

    public async onStakeholderStoryItemDeleting(e: IDxListItemDeletingEvent<ValueStreamStakeholderStory>) {
        if (!e.itemData) {
            return;
        }

        if (!e.itemData.stakeholderStory) {
            e.cancel = true;
            return;
        }

        const promise = lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean({
            title: "Remove customer story from value stream?",
            message: "Are you sure you wish to remove this customer story from this value stream?",
        }));
        e.cancel = DxUtilities.isCancelledFromPromise(promise);
    }

    public async onStakeholderStoryItemDeleted(e: IDxListItemDeletedEvent<ValueStreamStakeholderStory>) {
        const story = e.itemData!;

        story.valueStreamStakeholder = this.selectedStakeholder!;
        const changedEntities = await lastValueFrom(this.tier1ArchitectureService.removeValueStreamStakeholderStory(story));
        this.removedValueStreamStakeholderStories.push(story);
        changedEntities.forEach((entity) => {
            if (this.valueStreamStakeholderStories.indexOf(entity) < 0 && entity !== story) {
                this.valueStreamStakeholderStories.push(entity);
            }
        });
        this.storiesList?.instance().repaint();
        this.updateStakeholderStories();
    }

    /* Stakeholder Select Box Methods */
    public onStakeholderSelectInitialised(e: InitializedEventInfo<dxSelectBox>) {
        this.stakeholderSelectBox = e.component;
    }

    public async onStakeholderSelected(e: IDxSelectBoxSelectionChangedEvent<Stakeholder>) {
        if (!e.selectedItem) {
            return;
        }

        await this.addStakeholder(e.selectedItem);
    }

    private updateStakeholderStories() {
        this.stakeholderStories = this.selectedStakeholder?.stakeholder.stakeholderStories
            .filter((stakeholderStory) => !this.selectedStakeholder!.stakeholderStories
                .find((story) => story.stakeholderStoryId === stakeholderStory.stakeholderStoryId));
    }

    private async addStakeholder(stakeholder: Stakeholder) {
        this.stakeholderList?.instance().beginUpdate();
        const valueStreamStakeholder = (await lastValueFrom(this.tier1ArchitectureService.createValueStreamStakeholderForValueStream(this.valueStream))) as ValueStreamStakeholder;
        valueStreamStakeholder.stakeholder = stakeholder;
        this.stakeholderList?.instance().endUpdate();

        this.stakeholderSelectBox?.instance().reset();

        this.stakeholderList?.instance().selectItem(valueStreamStakeholder);

        this.dataSource?.reload();
    }

    public async onStakeholderStorySelected(e: IDxSelectBoxSelectionChangedEvent<StakeholderStory>) {
        if (!e.selectedItem || !this.selectedStakeholder) {
            return;
        }

        const valueStreamStakeholderStory = await lastValueFrom(this.tier1ArchitectureService.createValueStreamStakeholderStoryForValueStreamStakeholder(this.selectedStakeholder));
        valueStreamStakeholderStory.stakeholderStory = e.selectedItem;

        this.selectedStakeholder?.stakeholderStories.push(valueStreamStakeholderStory);

        this.updateStakeholderStories();
        this.stakeholderStorySelectBox?.reset();
    }

    /* Private Methods */
    private selectFirstStakeholder() {
        if (this.valueStream.stakeholders.length > 0
            && this.selectedStakeholders.length === 0) {
            this.stakeholderList?.instance().selectItem(0);
            const selectedItems = this.selectedStakeholders;
            if (selectedItems) {
                const item = selectedItems[0];
                this.updateSelectedStakeholder(item);
                this.stakeholderList?.instance().focus();
            }
        }
    }

    private get selectedStakeholders() {
        return this.stakeholderList?.instance().option("selectedItems") ?? [];
    }

    private stakeholderIsNotAlreadySelected(stakeholder: Stakeholder) {
        return this.valueStream.stakeholders.every((i) => stakeholder.stakeholderId !== i.stakeholderId);
    }
}
