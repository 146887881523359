import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Stakeholder } from "./stakeholder";

export class StakeholderStory extends BaseEntity<StakeholderStory> {
    public stakeholderStoryId!: number;
    public stakeholderId!: number;
    public want!: string;
    public reason!: string;
    public stakeholder!: Stakeholder;
}

export const StakeholderStoryBreezeModel = new BreezeModelBuilder("StakeholderStory", StakeholderStory)
    .withIdField()
    .withSingularName("Customer Story")
    .withPluralName("Customer Stories")
    .hasSource("StakeholderStories")
    .alwaysFetchingNavigationProperty("stakeholder")
    .isOrganisationEntity()
    .build();
