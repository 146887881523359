import { Injector } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { Observable } from "rxjs";
import { IRolePersonAllocations } from "./role-person-allocations-step/role-person-allocations-step.component";

// These names will be shown at the top of the dialog indicating the current progress
export enum StepName {
    SelectExportType = "Select Export Type",
    AllocateRoles = "Allocate Roles",
    SelectEmbedBoard = "Select Board",
    SetPrefix = "Set Prefix",
    ExportConfirmation = "Export Confirmation",
}

export abstract class Exporter {
    public board?: Board;
    public prefix?: string;
    public completed = false;
    public rolePersonAllocations: IRolePersonAllocations = {
        roles: [],
    };

    public progressTotalCount = 0;
    public progressCurrentCount = 0;

    public loadingData = false;

    public abstract get steps(): StepName[];

    public abstract export(
        injector: Injector,
        processMap: ProcessMap,
    ): Observable<unknown> | Promise<unknown>;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public canGoNext(_currentStep: StepName) {
        return true;
    }

    public clean() {
        this.board = undefined;
        this.completed = false;
        this.progressTotalCount = 0;
        this.progressCurrentCount = 0;
    }
}
