<div class="select-catchup-team-context"
     *adaptLoading="!catchup">
    <label class="context-selector-header"
           *ngIf="isSelectTeamVisible && showSelectionLabel"
           for="selectTeam">
        Team
        <span class="fal fa-info-circle"
              adaptTooltip="The team selection determines if this Peer Catch-up is limited to a specific team or across all teams. Selecting a specific team may also further limit who can view the Peer Catch-up."></span>
    </label>

    <div class="context-selector-row"
         [ngSwitch]="!!catchup!.team">
        <i class="fal fa-fw fa-2x fa-users"
           aria-hidden="true"
           *ngSwitchCase="true"></i>
        <i class="fal fa-fw fa-2x fa-cubes"
           aria-hidden="true"
           *ngSwitchDefault></i>
        <adapt-select-common-team id="selectTeam"
                                  *ngIf="isEditing"
                                  class="context-selector"
                                  [person1]="catchup!.person1"
                                  [person2]="catchup!.person2"
                                  [team]="catchup!.team"
                                  (teamChange)="onTeamSelection($event!)"
                                  (commonTeamsChange)="setHasCommonTeams($event)"></adapt-select-common-team>

        <div class="alert alert-warning"
             *ngIf="isEditing && !validationStates.hasCommonTeams">
            This Peer Catch-up is for all teams.
            <span class="fal fa-info-circle"
                  adaptTooltip="{{catchup!.person1?.fullName}} and {{catchup!.person2?.fullName}} do not share membership of any teams, so this Peer Catch-up must be across all teams."></span>
        </div>

        <p *ngIf="!isEditing && catchup!.team">
            This Peer Catch-up is limited to the
            <adapt-link-team [team]="catchup!.team"></adapt-link-team>
            Team.
        </p>
        <p *ngIf="!isEditing && !catchup?.team">
            This Peer Catch-up is for all teams.
        </p>

        <adapt-display-catchup-visibility *ngIf="isValidSelection"
                                          class="ms-2"
                                          [catchup]="catchup"></adapt-display-catchup-visibility>
    </div>
    <div class="alert alert-danger"
         *ngIf="invalidTeamMessage">
        {{invalidTeamMessage}}
    </div>
</div>
