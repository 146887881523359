<div class="intro">
    <p>
        Select the people that will be performing the roles in the exported "{{processMap.name}}" task map.
    </p>
    <p *ngIf="!!board">
        The "{{board!.name}}" board has been selected from the previous step and hence only people who can edit actions in the board can be
        assigned to perform the roles.
    </p>
    <p *ngIf="loadingData">
        <i class="fal fa-spinner fa-spin me-2"
           aria-hidden="true"></i> Loading roles...
    </p>
</div>

<div class="mt-3">
    <ng-container *ngIf="allRoles.length > 0; else noRole">
        <div *ngFor="let role of allRoles"
             class="row mb-2">
            <div class="col-12 col-md-4 align-self-center">{{role.label}}</div>
            <adapt-select-person class="col-12 col-md-8 align-self-center"
                                 [filter]="filterPerson"
                                 [teamGroup]="board?.team"
                                 [person]="rolePersonAllocations[role.roleId]"
                                 (personChange)="onRolePersonChanged(role.roleId, $event)"></adapt-select-person>
        </div>
    </ng-container>
    <ng-template #noRole>
        <span>There are no role tasks with assigned roles to be exported.</span>
    </ng-template>
</div>
