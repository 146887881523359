import { Component, Inject } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { switchMap } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
    selector: "adapt-delete-product-dialog",
    templateUrl: "./delete-product-dialog.component.html",
})
export class DeleteProductDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Product> {
    public readonly dialogName = "DeleteProductDialog";
    public readonly entitiesToConfirm = [this.product];

    public isUsed?: boolean;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public product: Product,
        private productCatalogueService: ProductCatalogueService,
    ) {
        super();
    }

    @Autobind
    public saveAndClose() {
        return this.productCatalogueService.deleteProduct(this.product).pipe(
            switchMap((entities) => this.productCatalogueService.saveEntities(entities)),
            tap(() => this.resolve(this.product)),
        );
    }
}
