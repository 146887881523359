import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ActivityFeedDateGroupComponent } from "../activity-feed-date-group/activity-feed-date-group.component";
import { IActivityDateGroup } from "../activity-feed-manager/activity-date-group.interface";
import { ActivityFeedManager } from "../activity-feed-manager/activity-feed-manager";
import { ActivityInstance } from "../activity-feed-manager/activity-instance";

@Component({
    selector: "adapt-activity-feed",
    templateUrl: "./activity-feed.component.html",
    styleUrls: ["./activity-feed.component.scss"],
})
export class ActivityFeedComponent extends BaseComponent implements OnInit {
    @Input() public emptyFeedText = "This feed has no activity.";
    @Input() public feedTitle = "Activity Feed";

    /** Fetches some activity to display at load time */
    @Input() public promiseToGetInitialActivity!: () => PromiseLike<ActivityInstance[]>;

    /**
     * Fetch activity older than some specified date. If this function resolves with an
     * empty array then it will be assumed that there is no more data left to load
     * in this feed.
     * @param olderThan Fetch activity older than this date
     */
    @Input() public promiseToGetOlderActivity!: (olderThan: Date) => PromiseLike<ActivityInstance[]>;

    /**
     * Fetch activity newer than some specified date.
     * @param newerThan Fetch activity newer than this date.
     */
    @Input() public promiseToGetNewerActivity!: (newerThan: Date) => PromiseLike<ActivityInstance[]>;

    public initialDataLoaded = false;
    public loadingMoreActivity = false;
    public activityByDate: IActivityDateGroup[];
    public oldestActivityDate?: Date;
    public noMoreActivityToLoad = false;
    public noActivity = true;
    public errorMessage = "";

    @ViewChildren(ActivityFeedDateGroupComponent)
    public dateGroupComponents!: QueryList<ActivityFeedDateGroupComponent>;

    private activityFeedManager: ActivityFeedManager;
    private previousLatestActivity?: ActivityInstance[];
    public reloadActivity = this.loadActivity;

    public constructor() {
        super();
        this.activityFeedManager = new ActivityFeedManager();
        this.activityByDate = this.activityFeedManager.getActivity();
    }

    public async ngOnInit() {
        // TODO Check defined callbacks
        this.loadActivity();
    }

    private async loadActivity() {
        try {
            this.initialDataLoaded = false;
            this.activityFeedManager.clearActivity();
            const activity = await this.promiseToGetInitialActivity();
            this.addNewActivity(activity);
        } catch {
            this.errorMessage = "Unknown error when loading this activity feed.";
        } finally {
            this.initialDataLoaded = true;
        }
    }

    public async promiseToLoadMoreActivity() {
        this.loadingMoreActivity = true;

        try {
            const activity = await this.promiseToGetOlderActivity(this.activityFeedManager.getOldestActivityDate());
            this.addNewActivity(activity);
            this.noMoreActivityToLoad = activity.length === 0;
        } catch {
            this.errorMessage = "Unknown error when loading more activity.";
        } finally {
            this.loadingMoreActivity = false;
        }
    }

    @Autobind
    private addNewActivity(activity: ActivityInstance[]) {
        this.activityFeedManager.addActivity(activity);
        this.noActivity = !this.activityFeedManager.hasActivity;

        if (this.activityFeedManager.hasActivity) {
            this.oldestActivityDate = this.activityFeedManager.getOldestActivityDate();
        }
    }

    public async promiseToFetchLatestActivity() {
        try {
            const activity = await this.promiseToGetNewerActivity(this.activityFeedManager.getNewestActivityDate());
            this.integrateNewActivity(activity);
        } catch {
            this.errorMessage = "Unknown error when loading latest activity.";
        }
    }

    @Autobind
    private integrateNewActivity(newActivity: ActivityInstance[]) {
        if (this.previousLatestActivity) {
            this.previousLatestActivity.forEach((a) => a.isNewActivity = false);
        }

        this.addNewActivity(newActivity);

        newActivity.forEach((a) => a.isNewActivity = true);
        this.previousLatestActivity = newActivity;

        if (newActivity.length > 0) {
            this.dateGroupComponents.forEach((c) => {
                c.reloadTreeView();
            });
        }
    }
}
