<div class="card card-borderless">
    <div class="card-header p-0">
        <h2 class="card-title d-flex align-items-center">{{component!.name}}</h2>

        <div *ngIf="isEditing"
             class="card-header-buttons">
            <adapt-menu [items]="editMenu"></adapt-menu>
        </div>
    </div>
    <div class="card-body p-0">
        <adapt-diagram [isEditing]="false"
                       [height]="getComponentSize(component!)"
                       [diagramEntity]="component!.systemDiagram"></adapt-diagram>
    </div>
</div>
