import { Component, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { ConnectionType, ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { IAddPeopleConfig } from "@common/identity/add-people-binding-model.interface";
import { IUserDetailBindingModel } from "@common/identity/user-detail-binding-model.interface";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { SelectAccessLevelComponent } from "@org-common/lib/user-management/select-access-level/select-access-level.component";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { CellPreparedEvent, InitializedEvent } from "devextreme/ui/data_grid";
import { dxToolbarOptions } from "devextreme/ui/toolbar";
import { DxDataGridComponent, DxSelectBoxComponent } from "devextreme-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface IAddPeopleGridRow {
    addedTime: Date;
    firstName: string;
    lastName: string;
    email: string;
}

@Component({
    selector: "adapt-add-people-dialog",
    templateUrl: "./add-people-dialog.component.html",
    styleUrls: ["./add-people-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AddPeopleDialogComponent extends BaseDialogComponent<IAddPeopleConfig, void> {
    public readonly dialogName = "AddPeople";
    public readonly UserType = UserType;

    public dialogTitleSuffix = "People";
    public addPeopleGridData: IAddPeopleGridRow[] = [];
    public selectedAccessLevel?: Role;
    public activateAccounts = false;
    public pasteCommand: string;
    public eulaUnaccepted$: Observable<boolean>;
    public connectionType?: ConnectionType;
    public userType = UserType.Viewer;

    @ViewChild(DxDataGridComponent)
    public peopleGrid?: DxDataGridComponent;

    @ViewChild(DxSelectBoxComponent)
    public userTypeSelectBox?: DxSelectBoxComponent;

    @ViewChild(SelectAccessLevelComponent)
    public selectAccessLevelComponent?: SelectAccessLevelComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public config: IAddPeopleConfig | undefined,
        private userManagementService: UserManagementService,
        eulaService: EulaService,
        orgService: OrganisationService,
    ) {
        super(DialogResolveData.NotRequired);

        this.connectionType = this.config?.connectionType;
        if (this.connectionType) {
            this.connectionTypeChange();
            this.dialogTitleSuffix = ConnectionTypeLabel.singular(this.connectionType);
        }

        this.userTypeChange();

        this.pasteCommand = navigator.platform.includes("Mac")
            ? "(using ⌘ + V)"
            : navigator.platform.includes("Win")
                ? "(using Ctrl + V)"
                : "";

        // don't want the 10 seconds delay from eulaHasBeenAccepted$
        this.eulaUnaccepted$ = eulaService.getEulaIsAcceptedForOrgId(orgService.getOrganisationId()).pipe(
            map((i) => !i),
        );
    }

    public onInitialised(e: InitializedEvent) {
        const component = e.component;

        component?.addRow();
    }

    public onShown() {
        this.focusFirstCell();
    }

    public connectionTypeChange() {
        // employee connection types don't allow user type = none, so reset it for this case only
        if ((this.userType === UserType.None) && (this.connectionType === ConnectionType.Employee)) {
            this.userType = UserType.Viewer;
            this.selectedAccessLevel = undefined;
        }

        if (this.connectionType === ConnectionType.Stakeholder) {
            this.userType = UserType.None;
            this.selectedAccessLevel = undefined;
        }
    }

    public async userTypeChange() {
        this.selectedAccessLevel = undefined;

        if (this.userType !== UserType.None) {
            this.selectedAccessLevel = await this.userManagementService.promiseToGetDefaultAccessLevel(this.userType);
        } else {
            this.activateAccounts = false;
        }
    }

    @Autobind
    public async addAndSave() {
        this.setErrorMessage(undefined);

        await this.peopleGrid!.instance.saveEditData();

        const nonEmptyGridData = this.addPeopleGridData.filter(rowNotEmpty);
        if (nonEmptyGridData.length < 1) {
            this.setErrorMessage("Please add some people to the grid");
            return;
        }

        if (this.addPeopleGridData.find((row) => rowNotEmpty(row) && !row.firstName)) {
            this.setErrorMessage("First name is required");
            return;
        }

        if (this.addPeopleGridData.find((row) => rowNotEmpty(row) && !row.lastName)) {
            this.setErrorMessage("Last name is required");
            return;
        }

        if (this.addPeopleGridData.find((row) => rowNotEmpty(row) && this.emailInvalid(row.email))) {
            this.setErrorMessage("Please enter a valid email");
            return;
        }

        if (!this.connectionType) {
            this.setErrorMessage("Please select a connection type");
            return;
        }

        if (!this.selectedAccessLevel && this.userType !== UserType.None) {
            this.setErrorMessage("Please select an access level");
            return;
        }

        try {
            const roleIds: number[] = [];
            if (this.selectedAccessLevel) {
                roleIds.push(this.selectedAccessLevel!.roleId);
            }

            const userDetailsBindingModels = nonEmptyGridData.map((row: IAddPeopleGridRow) => {
                const userDetail: IUserDetailBindingModel = {
                    firstName: row.firstName,
                    lastName: row.lastName,
                    email: row.email,
                    roleIds,
                    hasAccess: this.activateAccounts,
                    connectionType: this.connectionType!,
                    userType: this.userType,
                };

                return userDetail;
            });

            await this.userManagementService.promiseToAddPeople(userDetailsBindingModels);

            this.resolve();
        } catch (e) {
            this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
        }

        function rowNotEmpty(row: IAddPeopleGridRow) {
            return row.firstName || row.lastName || row.email;
        }
    }

    @Autobind
    public onCellPrepared(e: CellPreparedEvent) {
        if (e.rowType !== "data") {
            return;
        }

        const className = "invalid-cell";
        switch (e.column.dataField) {
            case "firstName":
            case "lastName":
                (e.cellElement as unknown as JQuery).addClass(!e.value ? className : "");
                break;

            case "email":
                (e.cellElement as unknown as JQuery).addClass(this.emailInvalid(e.value) ? className : "");
                break;

            default:
                // do nothing
                break;
        }
    }

    public onInitNewRow(e: { data: IAddPeopleGridRow }) {
        e.data.addedTime = new Date();
        setTimeout(() => this.focusFirstCell(), 10);
    }

    public customiseToolbar(toolbarOptions: dxToolbarOptions) {
        if (!toolbarOptions.items) {
            return toolbarOptions;
        }

        toolbarOptions.items.forEach((i) => {
            if (i.name === "saveButton" || i.name === "revertButton") {
                i.visible = false;
            }
        });

        return toolbarOptions;
    }

    public addAnotherPerson() {
        this.peopleGrid?.instance.addRow();
    }

    @Autobind
    public async handlePaste(event: ClipboardEvent) {
        let addedGridData = false;
        const data = event.clipboardData?.getData("Text");
        if (data) {
            const lines = data?.split("\n");
            lines.forEach(async (line) => {
                // only process lines with text
                if (line.length && line.trim().length) {

                    // split by tab delimiter, if no tabs, then split by comma delimiter
                    let values = line.split("\t");
                    if (values.length === 1) {
                        values = line.split(",");
                    }

                    // only add to the grid if we actually have delimited data
                    if (values.length > 1) {
                        addedGridData = true;
                        const lineData: IAddPeopleGridRow = {
                            addedTime: new Date(),
                            firstName: values.length > 0 ? values[0] : "",
                            lastName: values.length > 1 ? values[1] : "",
                            email: values.length > 2 ? values[2] : "",
                        };

                        this.addPeopleGridData.push(lineData);
                    }
                }
            });
        }

        if (addedGridData) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private focusFirstCell() {
        const cell = this.peopleGrid?.instance.getCellElement(0, 0);
        if (cell) {
            cell.focus();

            const input = jQuery(cell).find("input");
            input?.focus();
        }
    }

    private emailInvalid(email: string) {
        // this RegExp simply ensures its in the format of something@something.domain
        const simpleEmailValidateRegExp = /\S+@\S+\.\S+/;
        return !email || !simpleEmailValidateRegExp.test(email);
    }
}
