import { Person } from "@common/ADAPT.Common.Model/person/person";

export abstract class ActivityElement {
    private static nextId = 1;

    public readonly id: number;
    public parent?: ActivityElement;
    public readonly children: ActivityElement[] = [];

    public abstract readonly dateTime: Date;
    public abstract readonly relatedPerson?: Person;

    private _isNewActivity = false;

    protected constructor() {
        this.id = ActivityElement.nextId;
        ActivityElement.nextId++;
    }

    public get relatedPersonId() {
        return this.relatedPerson && this.relatedPerson.personId;
    }

    /** Describes if this is the top level activity in a hierarchy of grouped activity */
    public get isTopLevelActivity() {
        // Root or first layer (root won't be displayed in UI)
        return this.depth <= 1;
    }

    /** Describes if this is activity which has just been added */
    public get isNewActivity() {
        return this._isNewActivity;
    }

    public set isNewActivity(isNew: boolean) {
        this._isNewActivity = isNew;
        if (this.parent) {
            this.parent.isNewActivity = isNew;
        }
    }

    public get oldestActivityDate() {
        return this.dateTime;
    }

    public get newestActivityDate() {
        return this.dateTime;
    }

    protected get depth() {
        let depth = 0;
        let parent = this.parent;
        while (parent) {
            depth++;
            parent = parent.parent;
        }
        return depth;
    }

    protected locateDescendentActivity(chooser: (activity: ActivityElement[]) => ActivityElement): ActivityElement {
        if (this.children.length === 0) {
            return this;
        }
        const chosenActivity = chooser(this.children);
        return chosenActivity.locateDescendentActivity(chooser);
    }
}
