import { BreezeModelBuilder } from "@common/ADAPT.Common.Model/breeze-model-builder";
import { BaseEntity } from "../base-entity";
import { Organisation } from "./organisation";
import { ValueStreamProduct } from "./value-stream-product";

export class Product extends BaseEntity<Product> {
    public productId!: number;
    public organisationId!: number;
    public name!: string;
    public description?: string;
    public organisation!: Organisation;
    public valueStreamProducts!: ValueStreamProduct[];
}

export const ProductBreezeModel = new BreezeModelBuilder("Product", Product)
    .hasSource()
    .withIdField("productId")
    .withLabelField("name")
    .orderBy("name")
    .withSortField("name")
    .withSingularName("Product or Service")
    .withPluralNameAndNoSourceOverride("Products or Services")
    .isOrganisationEntity()
    .build();
