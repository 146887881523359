import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingType, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-edit-catchup-rating-type",
    templateUrl: "./edit-catchup-rating-type.component.html",
})
export class EditCatchupRatingTypeComponent extends BaseComponent implements OnChanges {
    @Input() public catchup?: SpeedCatchup;
    @Input() public ratingType?: CatchupRatingType;
    @Input() public isEditing = false;
    @Input() public showTitle = true;
    @Input() public showGuidance = false;
    @Output() public ratingChange = new EventEmitter<SpeedCatchupRating>();

    public rating1?: SpeedCatchupRating;
    public rating2?: SpeedCatchupRating;
    public ratingChangeUpdater = this.createThrottledUpdater((rating: SpeedCatchupRating) => this.ratingChange.emit(rating));

    // needed for the elementRef injection to scroll to view
    public constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    // this is only called from showCatchupDialog where this component is residing within an accordion item
    public scrollIntoView() {
        // first parent is the template, the next parent will be the dx-item and that's the item we want to scroll into view (including the title)
        if (this.elementRef?.nativeElement.parentElement?.parentElement) {
            this.elementRef.nativeElement.parentElement.parentElement.scrollIntoView();
        } else {
            // simply bring this into view without worrying about accordion title
            this.elementRef?.nativeElement.scrollIntoView();
        }
    }

    public ngOnChanges() {
        if (this.catchup && this.ratingType) {
            this.setRatings();
        }
    }

    private setRatings() {
        this.rating1 = ArrayUtilities.getSingleFromArray(this.catchup!.ratings.filter(this.getFilterForPerson(1)));
        this.rating2 = ArrayUtilities.getSingleFromArray(this.catchup!.ratings.filter(this.getFilterForPerson(2)));
    }

    private getFilterForPerson(index: 1 | 2): any {
        const idField: keyof SpeedCatchup = `person${index}Id`;

        return (rating: SpeedCatchupRating) => {
            const ratingType = rating.extensions.getRatingType();
            if (ratingType !== this.ratingType) {
                return false;
            }

            return ratingType.ratingIsMutual
                ? rating.authorPersonId === this.catchup![idField]
                : rating.subjectPersonId === this.catchup![idField];
        };
    }
}
