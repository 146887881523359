<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Deleting Product</h3>
    <div adapt-dialog-content>
        <adapt-display-product-usage [product]="product"
                                     (isUsed)="isUsed = $event"></adapt-display-product-usage>
        <ng-container *ngIf="isUsed; else notUsed">
            <p class="mt-3">Deleting "{{product.name}}" will remove it from the above value streams.</p>
        </ng-container>
        <ng-template #notUsed>
            <p *ngIf="isUsed === false"
               class="mt-3">Deleting "{{product.name}}" will not affect any value stream.</p>
        </ng-template>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="deleteAndClose"
                                   [saveIsDisabled]="isUsed === undefined"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
