
import { Component, Input, OnInit } from "@angular/core";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { CulturalIndexConfiguration } from "@common/ADAPT.Common.Model/organisation/cultural-index-configuration";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CulturalIndexService } from "../cultural-index.service";

@Component({
    selector: "adapt-ci-range",
    templateUrl: "./ci-range.component.html",
    styleUrls: ["./ci-range.component.scss"],
})
export class CulturalIndexRangeComponent extends BaseComponent implements OnInit {
    @Input() public culturalIndex!: CulturalIndex;
    @Input() public isDisabled = true;

    public culturalIndexConfig?: CulturalIndexConfiguration;

    public constructor(
        private culturalIndexService: CulturalIndexService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.culturalIndexConfig = await this.culturalIndexService.promiseToGetCulturalIndexConfiguration();
    }
}
