import { Component, Input, OnInit } from "@angular/core";
import { Configuration } from "@common/ADAPT.Common.Model/organisation/configuration";
import { RoleType } from "@common/ADAPT.Common.Model/organisation/role-type";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { Tier1ArchitectureService } from "app/features/architecture/tier1-architecture/tier1-architecture.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-configure-tier1",
    templateUrl: "./configure-tier1.component.html",
})
export class ConfigureTier1Component extends ConfigureFeatureBase implements OnInit {
    @Input() public configItem?: IConfigItem;
    public roleType?: RoleType;
    public orgConfiguration?: Configuration;

    public productOnLeft!: boolean;

    constructor(
        configurationService: ConfigurationService,
        private tier1Service: Tier1ArchitectureService,
        private orgService: OrganisationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        this.roleType = await lastValueFrom(this.tier1Service.getTier1RoleType());
        this.orgConfiguration = await lastValueFrom(this.orgService.getOrganisationConfiguration());
        this.productOnLeft = this.orgConfiguration.productZonePosition === 0;
    }

    public onZonePositionChange(value: boolean) {
        this.orgConfiguration!.productZonePosition = value ? 0 : 1;
    }
}
