<adapt-toolbar-title>
    <h2>Roles</h2>
</adapt-toolbar-title>

<adapt-toolbar-content>
    <div class="d-flex">
        <button adaptButtonIcon="fal fa-user-plus"
                title="Create new role"
                [adaptBlockingClick]="addRole"
                [hidden]="!(hasTier2Edit || hasManageAccess)"
                data-test="create-role-button">Create new role</button>
        <button adaptButtonIcon="fal fa-fw fa-compress"
                title="Merge selected rows"
                (click)="mergeSelectedRows()"
                [disabled]="!canMerge"
                [hidden]="!(hasTier2Edit && hasManageAccess)"
                data-test="merge-roles">Merge roles</button>

        <!--Must be wrapped to correctly get DX styling -->
        <div class="dx-datagrid-export-button ">
            <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                    adaptTooltip="Export all data"
                    (click)="exportAllData()"></button>
        </div>
        <div>
            <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
                    adaptTooltip="Column chooser"
                    (click)="showColumnChooser()"></button>
        </div>
        <div>
            <button adaptButtonIcon="dx-icon dx-icon-revert"
                    adaptTooltip="Reset columns"
                    (click)="resetColumns()"></button>
        </div>
    </div>
</adapt-toolbar-content>

<dx-data-grid [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              [dataSource]="gridData"
              (onInitialized)="onInitialized($event)">
    <dxo-column-fixing [enabled]="true">
    </dxo-column-fixing>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-scrolling>
    </dxo-scrolling>
    <dxo-column-chooser [enabled]="false">
    </dxo-column-chooser>

    <!-- Columns -->
    <dxi-column caption="Role"
                dataType="string"
                dataField="role"
                sortOrder="asc"
                [allowSorting]="true"
                [allowFiltering]="true"
                [visible]="true"
                cellTemplate="roleCellTemplate"
                [calculateCellValue]="calculateRoleCellValue"
                data-test="roles-name-filter"
                [filterValue]="roleLabelFilter">
        <dxo-header-filter [dataSource]="roleHeaderItems">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column caption="People"
                dataType="string"
                dataField="people"
                [allowSorting]="false"
                [allowFiltering]="true"
                [visible]="true"
                cellTemplate="peopleCellTemplate"
                [calculateCellValue]="calculatePeopleCellValue">
        <dxo-header-filter [dataSource]="peopleHeaderItems">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="showActions"
                caption=""
                dataType="string"
                dataField="actions"
                cellTemplate="actionCellTemplate"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [fixed]="true"
                fixedPosition="right"
                [width]="50">
    </dxi-column>

    <dxi-column *ngIf="allowMerging"
                caption=""
                dataType="boolean"
                dataField="selected"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [fixed]="true"
                fixedPosition="left"
                [width]="50"
                cellTemplate="selectRowTemplate">
    </dxi-column>

    <dxi-column caption="Status"
                dataType="boolean"
                dataField="status"
                trueText="Active"
                falseText="Inactive"
                [allowSorting]="true"
                [allowFiltering]="true"
                [filterValue]="true"
                [allowHeaderFiltering]="false"
                [width]="140"
                [visible]="true"
                [showEditorAlways]="false"
                [calculateCellValue]="calculateStatusCellValue">
    </dxi-column>

    <dxi-column caption="Type"
                dataField="connectionType"
                [calculateCellValue]="calculateConnectionTypeCellValue"
                cellTemplate="textCellTemplate"
                groupCellTemplate="textCellTemplate"
                dataType="string"
                [groupIndex]="0">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let item of 'roleCellTemplate'">
        <adapt-link-role [role]="item.data.role"></adapt-link-role>
    </div>

    <div *dxTemplate="let item of 'peopleCellTemplate'"
         class="d-flex flex-row align-items-center gap-1"
         [ngSwitch]="item.data.people.length > 10">
        <ng-container *ngSwitchCase="false">
            <adapt-link-person *ngFor="let person of item.data.people"
                               [person]="person"
                               [imageOnly]="true"></adapt-link-person>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <adapt-link-person *ngFor="let person of item.data.people.slice(0, 10)"
                               [person]="person"
                               [imageOnly]="true"></adapt-link-person>
            <a [routerLink]="managePeopleUrl"
               [queryParams]="item.data.managePeopleParams"> +{{item.data.people.length - 10}} more users</a>
        </ng-container>
    </div>

    <div *dxTemplate="let item of 'actionCellTemplate'">
        <adapt-role-actions [role]="item.data.role"
                            [hasTier2Edit]="showActions"></adapt-role-actions>
    </div>

    <div *dxTemplate="let item of 'selectRowTemplate'"
         [adaptTooltip]="item.data.disableSelectionText">
        <dx-check-box [disabled]="item.data.selectRowOptions.disabled"
                      [value]="item.data.selectRowOptions.value"
                      (onInitialized)="item.data.selectRowOptions.onInitialized($event)"
                      (onValueChanged)="item.data.selectRowOptions.onValueChanged($event)"></dx-check-box>
    </div>

    <div *dxTemplate="let item of 'textCellTemplate'">
        <div>{{item.value}}</div>
    </div>
</dx-data-grid>
