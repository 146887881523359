import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Diagram, DiagramBreezeModel } from "@common/ADAPT.Common.Model/organisation/diagram";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { ProcessMap, ProcessMapBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStepBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { DocumentSize, SystemComponent, SystemComponentBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemComponentSupplementaryData, SystemComponentSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-component-supplementary-data";
import { SystemDocument, SystemDocumentBreezeModel, SystemDocumentType } from "@common/ADAPT.Common.Model/organisation/system-document";
import { SystemEntity, SystemEntityBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemKnowledgeExpert, SystemKnowledgeExpertBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-knowledge-expert";
import { SystemLocation, SystemLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ServiceUri } from "@common/configuration/service-uri";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { defer, forkJoin, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

const SystemLocationsForZonesKey = "getSystemLocationsForZones";

@Injectable({
    providedIn: "root",
})
export class SystemisationService extends BaseOrganisationService {
    public readonly CopyProcessMapPath = "/CopyProcessMap";
    public readonly CopyProcessStepPath = "/CopyProcessStep";

    public systemContentIsEditing = false;

    public constructor(
        injector: Injector,
        private organisationService: OrganisationService,
        private httpClient: HttpClient,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [defer(() => {
            this.systemContentIsEditing = false;
            return of(undefined);
        })];
    }

    public getAllSystems() {
        return this.commonDataService.getAll(SystemEntityBreezeModel);
    }

    public getSystemById(systemEntityId: number) {
        return this.commonDataService.getById(SystemEntityBreezeModel, systemEntityId);
    }

    public getSystemComponentSupplementaryDataById(systemComponentId: number) {
        return this.commonDataService.getById(SystemComponentSupplementaryDataBreezeModel, systemComponentId);
    }

    public copyAndSaveProcessMap(processMapId: number) {
        const url = `${ServiceUri.MethodologyServicesServiceBaseUri}${this.CopyProcessMapPath}`;
        return this.httpClient.post<number>(url, processMapId).pipe(
            switchMap((newProcessMapId) => this.commonDataService.getById(ProcessMapBreezeModel, newProcessMapId)),
        );
    }

    public copyAndSaveProcessStep(processStepId: number, destinationProcessMapId: number) {
        const url = `${ServiceUri.MethodologyServicesServiceBaseUri}${this.CopyProcessStepPath}`;
        const body = {
            processStepId,
            destinationProcessMapId,
        };
        return this.httpClient.post<number>(url, body).pipe(
            switchMap((newProcessStepId) => this.commonDataService.getById(ProcessStepBreezeModel, newProcessStepId)),
        );
    }

    public copySystemDocument(document: SystemDocument) {
        const createData: Partial<SystemDocument> = {
            organisationId: document.organisationId,
            url: document.url,
            content: document.content,
            type: document.type,
        };

        return this.commonDataService.create(SystemDocumentBreezeModel, createData);
    }

    public getSystemByIdWithLayout(systemId: number) {
        const key = `systemByIdWithLayout${systemId}`;
        const predicate = new MethodologyPredicate<SystemEntity>("systemEntityId", "==", systemId);

        return this.commonDataService.getWithOptions(SystemEntityBreezeModel, key, {
            navProperty: "layout,owner,systemKnowledgeExperts",
            predicate,
            matchKeyOnly: true,
        }).pipe(map(ArrayUtilities.getSingleFromArray));
    }

    public createSystem() {
        const systemDefaults = {
            organisationId: this.organisationService.getOrganisationId(),
            name: "",
        };

        return this.commonDataService.create(SystemEntityBreezeModel, systemDefaults);
    }

    public createSystemKnowledgeExpert(system: SystemEntity, knowledgeExpert: Person) {
        const initialData = {
            system,
            knowledgeExpert,
        };

        return this.commonDataService.create(SystemKnowledgeExpertBreezeModel, initialData);
    }

    public getSystemLocationsForKeyFunction(keyFunction: KeyFunction) {
        const predicate = new MethodologyPredicate<SystemLocation>("keyFunctionId", "==", keyFunction.keyFunctionId);
        const key = `getSystemLocationsForKeyFunctions${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, key, {
            predicate,
            navProperty: "system",
            orderBy: SystemLocationBreezeModel.orderBy,
        });
    }

    public getSystemsForKeyFunction(keyFunction: KeyFunction) {
        return this.getSystemLocationsForKeyFunction(keyFunction).pipe(
            map((keyFunctionSystems) => keyFunctionSystems.map((i) => i.system)),
        );
    }

    // this is called from select-process-map component where we want to group maps under systems
    public getAllSystemComponentsForProcessMaps() {
        const predicate = new MethodologyPredicate<SystemComponent>("systemProcessMapId", "!=", null);
        const key = `getAllSystemComponentsForProcessMaps`;

        return this.commonDataService.getWithOptions(SystemComponentBreezeModel, key, {
            predicate,
            navProperty: "system",
        });
    }

    public primeSystemComponentsForProcessMapIds(processMapIds: number[], encompassingKey: string) {
        if (processMapIds.length > 0) {
            //This number is tested to be the higher to not hit 'The node count limit of '100' has been exceeded' error
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(processMapIds, 16); // constant only in this 1 place - no point extracting constant
            return forkJoin(chunksOfIds.map((ids) => {
                const predicate = new MethodologyPredicate<SystemComponent>("systemProcessMapId", "in", ids);
                // use encompassing as key for the 1st chunk
                const key = (ids[0] === processMapIds[0]) ? encompassingKey : predicate.getKey(SystemComponentBreezeModel.identifier);
                return this.commonDataService.getWithOptions(SystemComponentBreezeModel, key, {
                    predicate,
                });
            })).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            );
        } else {
            return of([] as SystemComponent[]);
        }
    }

    // encompassing key only passed in when evaluating permission from GMs page where all GM related entities are
    // already primed
    // it won't be defined when looking at system separately.
    public getSystemComponentForProcessMap(processMapId: number, encompassingKey?: string) {
        const predicate = new MethodologyPredicate<SystemComponent>("systemProcessMapId", "==", processMapId);
        const key = `getSystemComponentForProcessMap${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemComponentBreezeModel, key, {
            predicate,
            encompassingKey,
            navProperty: "system",
        }).pipe(
            filter((systemComponents) => systemComponents.length === 1),
            map((systemComponents) => systemComponents[0]),
        );
    }

    public getSystemComponentForProcessMapRedirect(processMapId: number): Observable<SystemComponent | undefined> {
        const predicate = new MethodologyPredicate<SystemComponent>("systemProcessMapId", "==", processMapId);

        return this.commonDataService.getByPredicate(SystemComponentBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }


    // only called from list-systems.component, which needs all nav properties to be primed
    public getAllSystemLocations() {
        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, SystemLocationBreezeModel.identifier, {
            navProperty: "team,role,keyFunction",
        });
    }

    public primeSystemLocationsForZones() {
        const predicate = new MethodologyPredicate<SystemLocation>("zone", "!=", null);
        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, SystemLocationsForZonesKey, {
            predicate,
            navProperty: "system",
            orderBy: SystemLocationBreezeModel.orderBy,
        });
    }

    public getSystemLocationsForZone(zone: Zone) {
        const predicate = new MethodologyPredicate<SystemLocation>("zone", "==", zone);
        const key = `getSystemLocationsForZone${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, key, {
            predicate,
            encompassingKey: SystemLocationsForZonesKey,
            navProperty: "system",
            orderBy: SystemLocationBreezeModel.orderBy,
        });
    }

    public getSystemLocationsForTeam(team: Team) {
        const predicate = new MethodologyPredicate<SystemLocation>("teamId", "==", team.teamId);
        const key = `getSystemLocationsForTeam${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, key, {
            predicate,
            navProperty: "system",
            orderBy: SystemLocationBreezeModel.orderBy,
        });
    }

    public getSystemsForTeam(team: Team) {
        return this.getSystemLocationsForTeam(team).pipe(
            map((teamSystems) => teamSystems.map((i) => i.system)),
        );
    }

    public getSystemLocationsForRole(role: Role) {
        const predicate = new MethodologyPredicate<SystemLocation>("roleId", "==", role.roleId);
        const key = `getSystemLocationsForRole${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, key, {
            predicate,
            navProperty: "system",
            orderBy: SystemLocationBreezeModel.orderBy,
        });
    }

    public getSystemsForRole(role: Role) {
        return this.getSystemLocationsForRole(role).pipe(
            map((roleSystems) => roleSystems.map((i) => i.system)),
        );
    }

    public GetSystemForKnowledgeExpert(person: Person) {
        const predicate = new MethodologyPredicate<SystemKnowledgeExpert>("knowledgeExpertId", "==", person.personId);
        const key = `GetSystemfromKnowledgeExpert${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemKnowledgeExpertBreezeModel, key, {
            predicate,
        });
    }

    public GetSystemForOwner(person: Person) {
        const predicate = new MethodologyPredicate<SystemEntity>("ownerId", "==", person.personId);
        const key = `GetSystemfromOwner${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemEntityBreezeModel, key, {
            predicate,
        });
    }

    public primeLocationsForSystem(systemEntityId: number) {
        const predicate = new MethodologyPredicate<SystemLocation>("systemEntityId", "==", systemEntityId);

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, `SystemLocationsForSystem${predicate.getKey()}`, {
            predicate,
            navProperty: "role,team,keyFunction",
        });
    }

    public getSystemComponentsForSystem(systemEntityId: number) {
        const predicate = new MethodologyPredicate<SystemComponent>("systemEntityId", "==", systemEntityId);
        const key = `systemComponentForSystem${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(SystemComponentBreezeModel, key, {
            predicate,
            navProperty: "systemProcessMap,systemDocument,systemDiagram,supplementaryData",
            // this is to make order of documents in system-component.component deterministic before saving anything to layout entity
            // - without it will be random as you have already already observed.
            // - without ordinal, order by entity id so newest one at the bottom
            orderBy: "systemComponentId",
        });
    }

    public createSystemLocation(system: SystemEntity, ordinal: number) {
        const systemLocation: Partial<SystemLocation> = {
            system,
            ordinal,
        };

        return this.commonDataService.create(SystemLocationBreezeModel, systemLocation);
    }

    public createSystemProcessMapComponent(system: SystemEntity, processMap: ProcessMap) {
        const systemComponent: Partial<SystemComponent> = {
            name: processMap.name, // TODO: move process map name to system component - remove process map name field from process map entity
            system,
            systemProcessMap: processMap,
            size: DocumentSize.ExtraLarge,
        };

        return this.commonDataService.create(SystemComponentBreezeModel, systemComponent);
    }

    public createSystemDocument(documentType?: SystemDocumentType) {
        const defaults: Partial<SystemDocument> = {
            organisationId: this.organisationService.getOrganisationId(),
            url: "",
            content: "",
            type: SystemDocumentType.RichText,
        };

        if (documentType) {
            defaults.type = documentType;
        }

        return this.commonDataService.create(SystemDocumentBreezeModel, defaults);
    }

    public createSystemDiagram() {
        const defaults: Partial<Diagram> = {
            organisationId: this.organisationService.getOrganisationId(),
        };

        return this.commonDataService.create(DiagramBreezeModel, defaults);
    }

    public copySystemDiagram(diagram: Diagram) {
        const initialData: Partial<Diagram> = {
            organisationId: diagram.organisationId,
            content: diagram.content,
        };

        return this.commonDataService.create(DiagramBreezeModel, initialData);
    }

    public copySystemComponent(systemComponent: SystemComponent) {
        const initialData: Partial<SystemComponent> = {
            name: systemComponent.name,
            system: systemComponent.system,
            size: systemComponent.size,
        };
        return this.commonDataService.create(SystemComponentBreezeModel, initialData).pipe(
            switchMap((newComponent) => {
                if (systemComponent.supplementaryData) {
                    return this.createSystemComponentSupplementaryData(newComponent).pipe(
                        map((suppData) => {
                            suppData.description = systemComponent.supplementaryData!.description;
                            return newComponent;
                        }),
                    );
                } else {
                    return of(newComponent);
                }
            }),
        );
    }

    public createSystemComponent(system?: SystemEntity) {
        const defaults: Partial<SystemComponent> = {
            name: "",
            system,
            size: DocumentSize.Medium,
        };

        return this.commonDataService.create(SystemComponentBreezeModel, defaults);
    }

    public getOrCreateSystemComponentSupplementaryData(systemComponent: SystemComponent) {
        return this.getSystemComponentSupplementaryDataById(systemComponent.systemComponentId).pipe(
            switchMap((supplementaryData) => supplementaryData
                ? of(supplementaryData)
                : this.createSystemComponentSupplementaryData(systemComponent)),
        );
    }

    public createSystemComponentSupplementaryData(systemComponent: SystemComponent) {
        const defaults: Partial<SystemComponentSupplementaryData> = {
            description: "",
            systemComponent,
        };

        return this.commonDataService.create(SystemComponentSupplementaryDataBreezeModel, defaults);
    }

    public getComponentSize(systemComponent: SystemComponent) {
        switch (systemComponent.size) {
            case DocumentSize.Small:
                return 200;
            case DocumentSize.Medium:
                return 400;
            case DocumentSize.Large:
                return 600;
            case DocumentSize.ExtraLarge:
                return 800;
            default:
                throw new Error("Component size not set");
        }
    }
}
