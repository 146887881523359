<ng-container *adaptLoading="!rating || !catchup">
    <h2 class="mb-0">{{person1}} & {{person2}}</h2>
    <p><small>Entered on {{catchup!.creationDate | timeAgo}} ({{catchupDate}})</small></p>
    <p class="d-flex align-items-center">Team:
        <ng-container *ngIf="catchup!.teamId">
            <adapt-link-team [teamId]="catchup!.teamId"
                             [showIcon]="true"></adapt-link-team>
        </ng-container>
        <ng-container *ngIf="!catchup?.teamId">
            <i aria-hidden="true"
               class="ps-1 fal fa-cubes"></i><span class="ps-1">All teams</span>
        </ng-container>
    </p>
    <p class="mb-1">
        {{ratingType?.name}}:
        <adapt-display-rating-value [ratingValue]="rating!.rating"></adapt-display-rating-value>
    </p>
    <!-- If not showing buttons, this will be showing this catchup history where it shouldn't be showing next catchup due
        (historical catchup instances are always due for next catchup!) -->
    <p *ngIf="areBothPeopleActive && showButtons"
       class="mb-1">
        Next catchup due: <span [ngClass]="dueDateClass">{{formattedNextCatchupDate}}</span>
    </p>
    <ng-container *ngIf="showCommentDetails">
        <h3 class="mt-4">How {{author}} rated {{ratingSubjectText}}</h3>
        <p class="mb-2"><strong>{{author}}'s Comment:</strong></p>
        <div [froalaView]="rating!.comment"></div>
        <ng-container *ngIf="rating!.feedbackComment">
            <p class="mt-4 mb-2"><strong>{{feedbackPerson}}'s Feedback:</strong></p>
            <div [froalaView]="rating!.feedbackComment"></div>
        </ng-container>
    </ng-container>

    <div class="mt-3 d-flex"
         *ngIf="showButtons">
        <button adaptButton="pill"
                (click)="showCatchup()">More Detail</button>
        <button *ngIf="canEditPersonalCatchups && areBothPeopleActive"
                adaptButtonIcon="add"
                class="ms-2 btn btn-primary btn-pill"
                (click)="recordNewCatchup()">New catch-up</button>
    </div>
</ng-container>
