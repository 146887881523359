import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DashboardElementComponent } from "@common/ux/dashboard-element/dashboard-element.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { merge, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { CareerValuationService } from "../career-valuation.service";
import { CareerValuationAuthService } from "../career-valuation-auth.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";
import { CareerValuationHistoryComponent } from "../cvt-history/cvt-history.component";
import { ViewCareerValuationDialogComponent } from "../view-cvt-dialog/view-cvt-dialog.component";

@Component({
    selector: "adapt-career-valuation-dashboard-page",
    templateUrl: "./cvt-dashboard-page.component.html",
})
export class CareerValuationDashboardPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    @ViewChild(CareerValuationHistoryComponent) public cvtHistoryComponent?: CareerValuationHistoryComponent;
    @ViewChild("currentCVTDashboardElement") public currentCVTDashboardElement?: DashboardElementComponent;

    public careerValuations: CareerValuation[] = [];
    public currentValuation?: CareerValuation;
    public person?: Person;
    private currentPerson?: Person;
    public hasEditPermission?: boolean;
    public selfMode: boolean = false;
    public latestConnection?: Connection;

    // does not update properly when fetching data
    public isLoading = false;
    private isLoadingUpdater = this.createThrottledUpdater((isLoading: boolean) => this.isLoading = isLoading);

    private headerClickSubscription?: Subscription;

    public constructor(
        injector: Injector,
        private careerValuationService: CareerValuationService,
        private careerValuationAuthService: CareerValuationAuthService,
        private careerValuationUiService: CareerValuationUiService,
        private userService: UserService,
        private dialogService: AdaptCommonDialogService,
        private directorySharedService: DirectorySharedService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        await this.careerValuationService.promiseToGetCareerValuationCategories();

        const personId = this.getRouteParamInt("personId");

        merge(
            this.rxjsBreezeService.entityTypeChangedInSave(CareerValuation),
            this.rxjsBreezeService.entityTypeChangedInSave(CareerValuationCategoryValue),
        ).pipe(
            debounceTime(50),
            switchMap(() => this.fetchCareerValuation(personId)),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.fetchCareerValuation(personId);
        this.notifyActivated();
    }

    public ngOnDestroy() {
        if (this.headerClickSubscription) {
            this.headerClickSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public get fullName() {
        return this.selfMode
            ? "My"
            : this.person
                ? `${this.person.fullName}'s`
                : "";
    }

    public onClickExport() {
        if (this.cvtHistoryComponent) {
            this.cvtHistoryComponent.exportData();
        }
    }

    @Autobind
    public viewCareerValuation() {
        return this.dialogService.open(ViewCareerValuationDialogComponent, this.currentValuation).subscribe();
    }

    @Autobind
    public recordCareerValuation() {
        return this.careerValuationUiService.promiseToRecordCareerValuation(this.person!);
    }

    @Autobind
    public editCareerValuation() {
        return this.careerValuationUiService.editCareerValuation(this.currentValuation!);
    }

    private async fetchCareerValuation(personId?: number) {
        this.isLoadingUpdater.next(true);

        this.currentPerson = await this.userService.getCurrentPerson();

        if (personId) {
            this.person = await this.directorySharedService.promiseToGetPersonById(personId);
        } else {
            this.person = this.currentPerson;
        }

        try {
            const hasReadPermission = this.currentPerson
                && this.person
                && await this.careerValuationAuthService.personCanReadForPerson(this.currentPerson, this.person);
            if (!hasReadPermission) {
                return ErrorPageRoute.gotoRoute().subscribe();
            }
        } catch (error) {
            return ErrorPageRoute.gotoRoute().subscribe();
        }

        this.hasEditPermission = this.currentPerson
            && this.person
            && await this.careerValuationAuthService.personCanEditForPerson(this.currentPerson, this.person);

        if (!this.person) {
            return this.routeService.gotoHome();
        }

        this.selfMode = this.person.personId === this.currentPerson?.personId;

        this.latestConnection = this.person.getLatestConnection();

        this.currentValuation = await this.careerValuationService.promiseToGetLatestCareerValuationForPerson(this.person.personId);

        if (this.currentCVTDashboardElement) {
            if (this.headerClickSubscription) {
                this.headerClickSubscription.unsubscribe();
            }

            if (this.currentValuation) {
                this.headerClickSubscription = this.currentCVTDashboardElement!.headerClicked.subscribe({ next: this.viewCareerValuation });
            }
        }

        this.isLoadingUpdater.next(false);
    }
}
