<div class="configure-system-locations">
    <div class="alert alert-info">Define the locations of the system to be used</div>
    <div class="mb-3 row">
        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h3>Roles</h3>
            <div class="d-flex flex-row justify-content-between align-items-center"
                 *ngFor="let roleLocation of roleLocations">
                <adapt-link-role [role]="roleLocation.role"></adapt-link-role>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete role link"
                        *ngIf="hasGlobalEdit"
                        (click)="deleteRoleLocation(roleLocation)"></button>
            </div>
            <ng-container *ngIf="!hasGlobalEdit && roleLocations.length === 0">
                There are no roles assigned to this system.
            </ng-container>
            <adapt-select-role *ngIf="hasGlobalEdit"
                               [required]="false"
                               [filter]="roleFilter"
                               [(role)]="selectedRole"
                               (roleChange)="onRoleSelected($event)"></adapt-select-role>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h3>Teams</h3>
            <div class="d-flex flex-row justify-content-between align-items-center"
                 *ngFor="let teamLocation of teamLocations">
                <adapt-link-team [team]="teamLocation.team"></adapt-link-team>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete team link"
                        *ngIf="this.configurableTeams.includes(teamLocation.team!)"
                        (click)="deleteTeamLocation(teamLocation)"></button>
            </div>
            <ng-container *ngIf="!hasGlobalEdit && teamLocations.length === 0">
                There are no teams assigned to this system.
            </ng-container>
            <adapt-select-team *ngIf="this.configurableTeams.length > 0"
                               [required]="false"
                               [filter]="teamFilter"
                               [(team)]="selectedTeam"
                               (teamChange)="onTeamSelected($event)"></adapt-select-team>
        </div>
    </div>
    <div class="mb-3 row">
        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h3>Key functions</h3>
            <div class="d-flex flex-row justify-content-between align-items-center"
                 *ngFor="let kfLocation of keyFunctionLocations">
                <adapt-link-key-function [showIcon]="false"
                                         [keyFunction]="kfLocation.keyFunction"></adapt-link-key-function>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete key function link"
                        *ngIf="hasGlobalEdit"
                        (click)="deleteKeyFunctionLocation(kfLocation)"></button>
            </div>
            <ng-container *ngIf="!hasGlobalEdit && keyFunctionLocations.length === 0">
                There are no key functions assigned to this system.
            </ng-container>
            <adapt-select-key-function *ngIf="hasGlobalEdit"
                                       [filter]="keyFunctionFilter"
                                       [(keyFunction)]="selectedKeyFunction"
                                       (keyFunctionChange)="onKeyFunctionSelected($event)"></adapt-select-key-function>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h3>Zones</h3>
            <div class="d-flex flex-row justify-content-between align-items-center mb-1"
                 *ngFor="let zoneLocation of zoneLocations">
                <adapt-zone-label [zone]="zoneLocation.zone"></adapt-zone-label>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete zone link"
                        *ngIf="hasGlobalEdit"
                        (click)="deleteZoneLocation(zoneLocation)"></button>
            </div>
            <ng-container *ngIf="!hasGlobalEdit && zoneLocations.length === 0">
                There are no zones assigned to this system.
            </ng-container>
            <adapt-select-zone *ngIf="hasGlobalEdit"
                               [filter]="zoneFilter"
                               [(zone)]="selectedZone"
                               (zoneChange)="onZoneSelected($event)"></adapt-select-zone>
        </div>
    </div>
</div>
